import React, { useState, useContext, useEffect } from "react";
import "../../styles/pages/Banners/BannerManager.scss";
import "../../styles/components/kababMenu.scss";
import kababButton from "../../images/kababButton.svg";
import Approved from "../../images/status_approved.svg";
import lockbanner from "../../images/locbanner.svg";
import ArrowWithCircle from "../../images/Arrowwithcircle.svg";
import {
  getSaveAssets,
  searchModules,
  unlockBanner,
  lockBanners,
  fileSizeValidation,
  getMLRstatus,
  getbannerandTemplateDetails,
} from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import useVisible from "./useVisible";
import BasedOnModalNew from "./BasedOnModalNew.js";
import BannerDeleteModal from "./BannerDeleteModal.js";
import { withRouter } from "react-router-dom";
import ValueContext from "../../ContextAPi/appContext";
import ExportModal from "./ExportModal.js";
import ExportCenterContainer from "./ExportCenterContainer";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import ClonetemplateModal from "../../Pages/TemplateManager/ClonetemplateModal.js";
import TemplateDeleteModal from "./TemplateDeleteModal";
import { SettingsInputSvideo } from "@material-ui/icons";

import checkmarkGreen from "../../images/TopRightCardIcons/checkmarkGreen.svg";
import EditIconOnCard from "../../images/TopRightCardIcons/EditIconOnCard.svg";
import kababBlue from "../../images/TopRightCardIcons/kababBlue.svg";
import lockedOnCards from "../../images/TopRightCardIcons/lockedOnCards.svg";
import refreshMLR from "../../images/TopRightCardIcons/refreshMLR.svg";
import TemplateDetailsModal from "./TemplatesDetailModal";

function TemplatesContainer(props) {
  const appData = useContext(ValueContext);
  const {
    // generateRVCV,
    // disableProceedbut,
    // setDisableProceedbut,
    // selectMultipleBanner,
    // setSelectMultipleBanner,
    // arrcheckboxSelection,
    // setArrcheckboxSelection,
    // indexResponseData,
    preDefined,
    setPredefined,
    customTemp,
    setCustomBannerSize,
    hide3dotIcon,
    getBrandsToPopulate,
  } = props;
  const { t } = useTranslation();
  let url =
    "https://imc-preview.s3.ap-south-1.amazonaws.com/ContentStudio/Banners/dummy-post-horisontal.jpg";

  const { ref, visible, setVisible } = useVisible(false);
  const [showBasedOn, setShowBasedOn] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [bannerDetails, setBannerDetails] = useState({
    assetName: "",
    assetType: "",
    brandName: "",
    bannerLink: "",
    marketName: "",
    width: "",
    height: "",
    campaign: "",
    veevaDocNumber: "",
    reviewStatus: "Not submitted",
    createdBy: "",
    createdOn: "",
    updatedBy: "",
    updatedDateTime: "",
    isAnimated: false,
    createdByName: "",
    updatedByName: "",
    createdDateTime: "",
    sourceTemplateInfo: {
      assetName: "",
      brandName: "",
      campaign: "",
      marketName: "",
      createdBy: "",
      createdDateTime: "",
      updatedDateTime: "",
    },
    isBannerCreated: false,
    bannerCount: "",
  });
  const [showDetails, setShowDetails] = useState(false);

  // const [showTemplateDetails, setShowTemplateDetails] = useState(false);

  //const [showExport, setExport] = useState(false);
  //const [type, setType] = useState(false);
  const [showCloneTemplate, setShowCloneTemplate] = useState(false);
  const [iscustomtempSize, setIscustomtempSize] = useState(false);

  // const [showExport, setExport] = useState(false);
  // const [type, setType] = useState(false);
  // const [indexValueforlock, setIndexValueforlock] = useState(undefined);
  // const [refDocuments, setRefDocuments] = useState([]);
  // const [exportDOMData, setExportDOMData] = useState({
  //   cards: "",
  // });
  // const handleDeleteModal = () => {
  // setShowDelete(true);
  // };
  // const [checkExportType, setCheckExportType] = React.useState({
  //   PDF: false,
  //   PNG: false,
  //   JPG: false,
  //   HTML: false,
  //   RVCVFILE: false,
  // });

  // const [rvcvTypes, setRvcvTypes] = React.useState({
  //   value2X: false,
  //   value3X: false,
  // });

  //{[props.responseData[i].id]: false})

  // const checkMultipleBanner = (event, id) => {
  //   let maxCheckboxSelection = [...selectMultipleBanner];
  //   let checkboxSelection = maxCheckboxSelection.filter(
  //     (element) => element.toggleRVCV === true
  //   );

  //   if (checkboxSelection?.length >= 5 && event.target.checked) {
  //     return toast.error(
  //       <div className="notificationText">
  //         {t("Selection of more than 5 banners is not allowed")}
  //       </div>
  //     );
  //   }
  //   const updatedCheckedState = selectMultipleBanner.map((item, index) => {
  //     return item.id === id ? { ...item, toggleRVCV: !item.toggleRVCV } : item;
  //   });
  //   setSelectMultipleBanner(updatedCheckedState);
  // };

  // useEffect(() => {
  //   let newArrayList = props.responseData.map((recentlyViewData) => ({
  //     id: recentlyViewData.id,
  //     data: recentlyViewData,
  //     toggleRVCV: false,
  //   }));
  //   setSelectMultipleBanner(newArrayList);
  //   // console.log(selectMultipleBanner, "updatedCheckedState");
  // }, [props.responseData]);

  // useEffect(() => {
  //   let obj = selectMultipleBanner.find((o) => o.toggleRVCV === true) || [];
  //   if (obj.toggleRVCV) {
  //     setDisableProceedbut(true);
  //   } else {
  //     setDisableProceedbut(false);
  //   }
  // }, [selectMultipleBanner]);
  // const exportType = (type) => {
  //   getSaveAssets(
  //     props.recentlyViewedData.id,
  //     async (response, err, errorCode) => {
  //       const data = await response.data;

  //       if (err) {
  //         //  const errCoponent = ErrComponentToRender(errorCode);
  //         props.setLoading(false);
  //       } else {
  //         appData.setAssetInfo({
  //           assetId: data.id,
  //           assetName: data.assetName,
  //         });

  //         setExportDOMData({
  //           cards: response.data,
  //         });
  //         setType(type);
  //       }
  //     }
  //   );
  // };
  const [latestMLRStatus, setLatestMLRStatus] = useState(
    props.recentlyViewedData?.assetMlr?.filter(
      (assetObj) => assetObj.fileType === "Pdf"
    )[0]?.reviewStatus || "Not Submitted"
  );

  // const handleOpenDetailsModal = () => {
  //   setShowTemplateDetails(true);
  //   setVisible(!visible);
  //   gettempDetails();
  // };
  // const gettempDetails = () => {
  //   getTemplateDetails(
  //     localStorage.assetId,
  //     async (response, err, errorCode) => {
  //       const data = await response.data;

  //       if (err) {
  //         //  const errCoponent = ErrComponentToRender(errorCode);
  //         props.setLoading(false);
  //       } else {
  //         props.setLoading(false);
  //         setTemplateDetails(response?.data);
  //       }
  //     }
  //   );
  // };
  // const handleOpenDetailsModal = () => {
  //   setShowTemplateDetails(true);
  //   setVisible(!visible);
  //    getDetails();
  // };
  useEffect(() => {
    setLatestMLRStatus(
      props.recentlyViewedData?.assetMlr?.filter(
        (assetObj) => assetObj.fileType === "Pdf"
      )[0]?.reviewStatus || "Not Submitted"
    );
  }, [
    props.recentlyViewedData?.assetMlr?.filter(
      (assetObj) => assetObj.fileType === "Pdf"
    )[0]?.reviewStatus,
  ]);

  const getLatestMLRStatus = (id) => {
    props.setLoading(true);
    getMLRstatus(id, async (response, err, errorCode) => {
      if (err) {
        // const errCoponent = errComponentToRender(errorCode);
        console.log(err);
        props.setLoading(false);
      } else {
        const data = await response.data;
        setLatestMLRStatus(data?.reviewStatus || "Not Submitted");
        props.setLoading(false);
      }
    });
  };
  const handleKababMenu = (e) => {
    setVisible(!visible);
  };

  const sendSaveBannerHistory = (recentlyViewedData) => {
    localStorage.setItem("assetId", recentlyViewedData.id);
  };

  function getEditSaveAssets(id, cloneStatus, title) {
    getSaveAssets(id, async (response, err, errorCode) => {
      const data = await response.data;
      //  var cardAry = [];
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
      } else {
        appData.setBannersEditCardData({
          cards: data,
        });

        appData.setAssetInfo({
          assetId: data.id,
          assetName: data.assetName,
        });

        let obj = {};
        //    let typeOftempbnr = data.isAnimated === true ? "Animated" : "Static";
        obj.brandName = data.brandName;
        obj.campaign = data.campaign;
        obj.market = data.marketName;
        obj.width = data.width;
        obj.height = data.height;
        obj.isAnimated = data.isAnimated;

        //  obj.tagName = data.tagName;

        appData.updateGenericInfo({
          ...appData?.genericInfo,
          brand: data.brandName,
          campaign: data.campaign,
          market: data.marketName,
          width: data.width,
          height: data.height,
          isAnimated: data.isAnimated,
          bannerNameInput: "",
          //tagName: data.tagName,
        });
        setIscustomtempSize(data.attributes?.customTemp);
        appData.setMarketCode(data.marketCode);

        appData.setBrandId(data.brandId);
        if (title === "cloneTitle") {
          //setShowBasedOn(true);
          setShowCloneTemplate(true);
          setVisible(!visible);
        } else {
          setShowBasedOn(true);
        }
      }
    });
  }
  const handleDeleteTemplate = () => {
    setShowDelete(true);
    setVisible(!visible);
  };
  const handleCloneTemplate = () => {
    setShowCloneTemplate(true);
    setVisible(!visible);
  };
  const bannerImages = {
    bannerName: "Banner A",
    img: (
      <img
        src={props.recentlyViewedData.thumbNail}
        alt="Banner "
        className="img-fluid"
      />
    ),
    status: "Approved",
  };

  // const assetUnlock = (id) => {
  //   unlockBanner(id, (response, err, errorCode) => {
  //     if (err) {
  //       toast.error(
  //         <div className="notificationText">
  //           {t("Failed to send the unlock request")}
  //         </div>
  //       );
  //     } else {
  //       setVisible(false);
  //       toast.success(
  //         <div className="notificationText">
  //           {t("Unlock request has been successfully sent")}
  //         </div>
  //       );
  //     }
  //     // props.getRecentlyViewedBanner();
  //   });
  // };
  // const assetLock = (id) => {
  //   lockBanners(id, (response, err, errorCode) => {
  //     if (err) {
  //       toast.error(
  //         <div className="notificationText">
  //           {t("Failed to lock the banner")}
  //         </div>
  //       );
  //     } else {
  //       setVisible(!visible);
  //       toast.success(
  //         <div className="notificationText">
  //           {t("Successfully locked the banner")}
  //         </div>
  //       );
  //     }
  //     // props.getRecentlyViewedBanner();
  //   });
  // };

  // const fileValidation = () => {
  //   getSaveAssets(
  //     props.recentlyViewedData.id,
  //     async (response, err, errorCode) => {
  //       const data = await response.data;

  //       if (err) {
  //         //  const errCoponent = ErrComponentToRender(errorCode);
  //         props.setLoading(false);
  //       } else {
  //         if (data?.attributes?.bannerSize === 200) {
  //           //File Size Exceed More than 200 KB
  //           toast.error(
  //             <div className="notificationText">
  //               {t("Warning - Banner size exceeds 200 KB!")}
  //             </div>
  //             // { hideProgressBar: true, closeButton: false }
  //           );
  //           // setLoading(false);
  //           // return;
  //         }
  //       }
  //     }
  //   );
  // };
  const handleOpenDetailsModal = () => {
    setShowDetails(true);
    //  setVisible(!visible);
    getDetails();
  };
  const getDetails = () => {
    getbannerandTemplateDetails(
      props?.recentlyViewedData?.id,
      async (response, err, errorCode) => {
        const data = await response.data;

        if (err) {
          //  const errCoponent = ErrComponentToRender(errorCode);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          setBannerDetails(response?.data);
        }
      }
    );
  };
  return (
    <>
      <div className="container recentlyViewCardTemplates">
        {/* <div className="rvcvfileSelction">
          {generateRVCV && (
            <FormControl component="fieldset">
              <FormGroup
                aria-label="format"
                name="format"
                className="selectMultiplecheckbox"
              >
                <FormControlLabel
                  value="selectRVCV"
                  id="checkForRvcv"
                  control={
                    <Checkbox
                      id={`custom-checkbox-${indexResponseData}`}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={
                        selectMultipleBanner[indexResponseData]?.toggleRVCV
                      }
                      onChange={(e) =>
                        checkMultipleBanner(e, props.recentlyViewedData.id)
                      }
                      name="selectRVCV"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          )}
        </div> */}
        <div className="bannerRow topMargin">
          <Tooltip
            title={
              props.recentlyViewedData.assetName.length >= 15
                ? props.recentlyViewedData.assetName
                : ""
            }
          >
            <h6 className="bannerName">{props.recentlyViewedData.assetName}</h6>
          </Tooltip>

          <div className="bannerTopRight">
            <div className="locked"></div>
            {/* <div className="locked">
              {" "}
              {props.recentlyViewedData.lockStatus === "Locked" ? (
                <img
                  src={lockedOnCards}
                  alt="lockbanner"
                  className="img-fluid"
                  onClick={() => {
                    assetUnlock(props.recentlyViewedData.id);
                  }}
                />
              ) : (
                // </button>
                ""
              )}
              </div> */}
            <div className="mlrStatus">
              <Tooltip title={latestMLRStatus}>
                <img
                  src={
                    latestMLRStatus === "Approved for Production"
                      ? checkmarkGreen
                      : EditIconOnCard
                  }
                  alt="mlrStatus"
                />
              </Tooltip>
            </div>
            <div className="refreshMLR">
              <img
                src={refreshMLR}
                alt="refreshMLR"
                onClick={() => getLatestMLRStatus(props.recentlyViewedData.id)}
              />
            </div>
            {hide3dotIcon ? (
              <div className="kababIcon">
                <img
                  src={kababBlue}
                  alt="Kabab"
                  onClick={(e) => handleKababMenu(e)}
                />
                {visible ? (
                  <div className="kababMenu" ref={ref}>
                    {
                      <>
                        <div
                          className={`kababMenu--option`}
                          onClick={async () => {
                            // await sendSaveBannerHistory(
                            //   props.recentlyViewedData
                            // );
                            appData.setFlagEditFlow(true);
                            props.history.push({
                              pathname: `/banner-editor/${props.recentlyViewedData?.id}`,
                              state: { editClicked: true },
                            });
                          }}
                        >
                          {t("bannerManager.kababMenu.edit")}
                        </div>
                      </>
                    }

                    <>
                      <div
                        className="kababMenu--option"
                        onClick={() => handleDeleteTemplate()}
                      >
                        {t("bannerManager.kababMenu.delete")}
                      </div>
                    </>
                    {
                      <>
                        <div
                          className="kababMenu--option"
                          //onClick={() => handleCloneTemplate()}
                          onClick={async () => {
                            await getEditSaveAssets(
                              props.recentlyViewedData.id,
                              true,
                              "cloneTitle"
                            );
                          }}
                        >
                          {t("Clone")}
                        </div>
                        {/* <div className="kababMenu--separator" />{" "} */}
                      </>
                    }
                    <>
                      <div
                        className="kababMenu--option"
                        onClick={() => handleOpenDetailsModal("Template")}
                      >
                        {t("Details")}
                      </div>
                    </>
                  </div>
                ) : null}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="text-center">
          <div className="bannerImagesDiv ">
            <div
              // src={props.recentlyViewedData.thumbNail || url}
              style={{
                height: "208px",
                //props.recentlyViewedData.height >= 50 ? "208px" : "auto",
                backgroundImage: props?.recentlyViewedData?.thumbNail
                  ? `url(${props.recentlyViewedData.thumbNail})`
                  : `url(${url})`,
              }}
              alt="Banner"
              className="bannerImages img-fluid"
            />
            <div className="middle">
              <div
                className="overlaytext overlaytextTemp"
                onClick={async () => {
                  await getEditSaveAssets(
                    props.recentlyViewedData.id,
                    true,
                    "basedOntitle"
                  );
                }}
              >
                {t("bannerManager.basedOnTitle")}
                <img
                  src={ArrowWithCircle}
                  alt="arrow"
                  className="arrowwithcircle"
                />
              </div>
            </div>
            {/* <div className="export-container" style={{ display: "none" }}>
              {type && (
                <ExportCenterContainer
                  exportDOMData={exportDOMData}
                  setType={setType}
                  type={type}
                  setLoading={props.setLoading}
                  checkExportType={checkExportType}
                  rvcvTypes={rvcvTypes}
                  setRvcvTypes={setRvcvTypes}
                  setCheckExportType={setCheckExportType}
                ></ExportCenterContainer>
              )}
            </div> */}
          </div>
          {showBasedOn && (
            <BasedOnModalNew
              showBasedOn={showBasedOn}
              recentlyViewedData={props.recentlyViewedData}
              setShowBasedOn={setShowBasedOn}
              getBrandsToPopulate={getBrandsToPopulate}
            />
          )}
          {/* {showExport && (
            <ExportModal
              showBasedOn={showExport}
              setExport={setExport}
              setLoading={props.setLoading}
              exportType={exportType}
              checkExportType={checkExportType}
              setCheckExportType={setCheckExportType}
              rvcvTypes={rvcvTypes}
              setRvcvTypes={setRvcvTypes}
              genericInfo={props.genericInfo}
              setGenericInfo={props.setGenericInfo}
              toggleAnimatedBanner={props.recentlyViewedData.isAnimated}
            />
          )} */}
          {showDelete && (
            <TemplateDeleteModal
              card={"TEMPLATE"}
              responseData={props.responseData}
              setResponseData={props.setResponseData}
              showDelete={showDelete}
              setShowDelete={setShowDelete}
              searchType={props.search}
              recentlyViewedData={props.recentlyViewedData}
              getRecentlyViewedBanner={props.getRecentlyViewedBanner}
              removeSearchresultCard={props.removeSearchresultCard}
            />
          )}
          {showCloneTemplate && (
            <ClonetemplateModal
              handleCloneTemplate={handleCloneTemplate}
              showCloneTemplate={showCloneTemplate}
              setShowCloneTemplate={setShowCloneTemplate}
              recentlyViewedData={props.recentlyViewedData}
              genericInfoNew={appData.genericInfo}
              preDefined={preDefined}
              setPredefined={setPredefined}
              customTemp={customTemp}
              setCustomBannerSize={setCustomBannerSize}
              iscustomtempSize={iscustomtempSize}
            />
          )}
        </div>
        {showDetails && (
          <div className="details-mod">
            <TemplateDetailsModal
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              setLoading={props.setLoading}
              loading={props.loading}
              lockStatus={props.recentlyViewedData.lockStatus}
              bannerDetails={bannerDetails}
              setBannerDetails={setBannerDetails}
              recentlyViewedData={props.recentlyViewedData}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(TemplatesContainer);
