let CssCollect = "";
let DomObj = "";
let atomId = "";
//let assetId = "";
let img = [];
let atomUrl = "";
let trackHeight = [];
let thumbHeight = 0;
export function downLoadPdf(
  layerCompData,
  assetId,
  canvasDimension,
  bgColorCanvas,
  animationObject,
  popUpOptions,
  flag
) {
  CssCollect = "";
  img = [];
  DomObj = "";
  atomId = "";
  atomUrl = "";
  trackHeight = [];

  layerCompData.forEach((data) => {
    let getDom = getDOM(
      data,
      animationObject[data.id]?.animation[0],
      popUpOptions,
      flag,
      canvasDimension
    );

    DomObj = DomObj + getDom.DOMConstruct;
    CssCollect = CssCollect + getDom.CssCollect;
    trackHeight = getDom.trackHeight;
    thumbHeight = getDom.thumbHeight;
  });
  let getLayerData;
  for (let i = 0; i < layerCompData.length; i++) {
    getLayerData = i;
  }
  CssCollect =
    CssCollect +
    `\n#canvas-page{position:absolute; width:${canvasDimension?.width}px; height:${canvasDimension?.height}px;background:rgba(${bgColorCanvas?.r}, ${bgColorCanvas?.g}, ${bgColorCanvas?.b}, ${bgColorCanvas?.a});font-family: arial;font-size:10px; line-height:1.5;overflow:hidden} .button-div {
    word-break: break-word;
    text-align: center;  
  }
  .button-style {
    background-color: rgb(65, 65, 65);
    border: none;
    border-radius: 3px;
    color: rgb(255, 255, 255);
    display: inline-block;
    /*   padding: 20px 20px; */
    padding:5px 10px;
    line-height: 0;
    outline:none;
    position: absolute;
    cursor:pointer;
    overflow:hidden;
  }
  #track{
    overflow-x: hidden;
  }
  .button-style p{
    padding:0;
    margin:0;
  }
  .triangleImage {
    //transform: rotate(180deg);
  }
  .pentagonImage {
    transform: rotate(0deg);
  }
 
.skew1 { 
  background-color: transparent;
  width: 677px;
  height: 378px;
  position: absolute;
  top: -1px;
  left: -189px;
  overflow: hidden;
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.8);
  -ms-transform: skewX(-45deg);
  -webkit-transform: skewX(-45deg);
  transform: skewX(-45deg);
}
.skew1imgbg {
  background: url(${layerCompData[getLayerData]?.componentStyles?.background})
  no-repeat;
  width: 300px;
  height: 493px;
  position: absolute;
  top: 0px;
  left: 245px;
  -ms-transform: skewX(+45deg);
  -webkit-transform: skewX(+45deg);
  transform: skewX(+45deg);
}
.textProps ol{
  margin-top:0 !important;
}
.textProps ul {
  margin-top:0 !important;
}
  `;

  return {
    DomObj,
    CssCollect,
    assetId,
    atomId,
    img,
    atomUrl,
    trackHeight,
    thumbHeight,
  };
}

export function getDOM(
  layerData,
  set = {},
  popUpOptions,
  flag,
  canvasDimension
) {
  let DOMConstruct = "";
  let CssCollect = "";

  let styles = layerData.componentStyles;

  let newLeft,
    newTop,
    newOpacity,
    newRotation,
    newTransform,
    newWidth,
    newHeight;
  let cursorPointer =
    styles.targetLink &&
    styles.targetLink !== "http://" &&
    styles.targetLink !== "https://" &&
    styles.targetLink.includes("http://" || "https://")
      ? "pointer"
      : "default";
  if (layerData.componentStyles.hideBoolean)
    return { DOMConstruct, CssCollect, trackHeight, thumbHeight };
  switch (layerData.componentType) {
    case "Text":
      if (true) {
        if (Object.keys(set).length) {
          newLeft = set?.x;
          newTop = set?.y;
          newOpacity = set?.opacity;
          newRotation = set?.rotation;
          newTransform = set?.transform;
        } else {
          newLeft = styles.left;
          newTop = styles.top;
          newOpacity = styles?.opacity || 1;
          newRotation = styles?.rotation;
          newTransform = styles?.transform;
        }
        if (!layerData.componentStyles.toggleScroll) {
          // if (Object.keys(set).length) {
          //   newLeft = set?.x;
          //   newTop = set?.y;
          //   newOpacity = set?.opacity;
          //   newRotation = set?.rotation;
          //   newTransform = set?.transform;
          // } else {
          //   newLeft = styles.left;
          //   newTop = styles.top;
          //   newOpacity = styles?.opacity || 1;
          //   newRotation = styles?.rotation;
          //   newTransform = styles?.transform;
          // }

          // let cursorPointer = styles.targetLink ? "pointer" : "auto";

          let textDecorationvalue = styles?.underlineClick
            ? styles?.strikeThroughClicked
              ? styles?.textDecUnderline + " " + styles?.textDecoration
              : styles?.textDecUnderline
            : styles?.textDecoration;
          // DOMConstruct = `<div id='${layerData.id}' data-url='${styles.targetLink}' onClick="enableTxt(this)" >${layerData.froalaText} </div>`;
          DOMConstruct = `<a id='${
            layerData.id
          }' style="text-decoration:none;" href='${
            canvasDimension.checkGloballink === true &&
            canvasDimension.checkGloballink !== undefined
              ? canvasDimension.globalLink
              : styles.targetLink
          }' onClick="enableTxt(this)" class="textProps">${
            layerData.froalaText
          } </a>`;
          CssCollect += `\n#${layerData.id}{position:absolute;width:${styles.width}px; height:${styles.height}px;top:${newTop}px;
    left:${newLeft}px; font-weight:${styles.fontWeight}; font-family:${styles.fontFamily};color:${styles.color};line-height:${styles.lineHeight};
    z-index:${styles.zIndex};letter-spacing:${styles.letterSpacing};font-size:${styles.fontSize}px; word-wrap: break-word;
    transform: scale(${newTransform});
    transform:rotate(${newRotation}deg);
    opacity:${newOpacity};
    cursor:${cursorPointer};
    background-color:${styles.textBgColor};
    overflow:hidden;
    font-style:${styles?.fontStyle};
    text-transform:${styles?.textTransform};
     text-decoration:${textDecorationvalue};
     text-align:${styles?.textAlign};
     
    }`;
        } else {
          let textDecorationvalue = styles?.underlineClick
            ? styles?.strikeThroughClicked
              ? styles?.textDecUnderline + " " + styles?.textDecoration
              : styles?.textDecUnderline
            : styles?.textDecoration;
          let completeObj = {};
          completeObj.id = layerData.id;
          completeObj.thumbHeight = styles.thumbHeight;
          completeObj.trackHeight =
            styles.height -
            styles.arrowHeight * 2 -
            (styles.arrowHeight === 0 ? 0 : 2) -
            (styles.expandAndClose ? styles.expandClose?.expandHeight : 0); // 4px for matching rc scollbar
          completeObj.autoScroll = styles.autoScroll;
          completeObj.arrowHeight = styles.arrowHeight;
          completeObj.compHeight = styles.height;
          completeObj.compTop = newTop;
          completeObj.expandHeight = styles.expandClose?.expandHeight;
          completeObj.compLeft = newLeft;
          completeObj.compWidth = styles.width;
          trackHeight.push(completeObj);

          let textDecvalue = styles?.expandClose?.underlineClick
            ? styles?.expandClose?.strikeThroughClicked
              ? styles?.expandClose?.textDecUnderline +
                " " +
                styles?.expandClose?.textDecoration
              : styles?.expandClose?.textDecUnderline
            : styles?.expandClose?.textDecoration;
          let expanCloseDIV = styles?.expandAndClose
            ? ` 
                 <div id="expand${layerData.id}" onClick="expandBanner('${layerData.id}')">+ ${styles.expandText}</div>
                  <div id="close${layerData.id}" onClick="closeBanner('${layerData.id}')">- ${styles.closeText}</div>`
            : "";

          let expanCloseCSS = styles.expandAndClose
            ? `\n  #expand${layerData.id}{      
                height:${styles.expandClose?.expandHeight};
                font-weight:${styles.expandClose?.fontWeight}; 
                font-family:${styles.expandClose?.fontFamily};
                color:${styles.expandClose?.fontColorISI};        
                line-height:${styles.expandClose?.lineHeight};  
                letter-spacing:${styles.expandClose?.letterSpacing};
                font-size:${styles.expandClose?.fontSize}px; 
                background-color:${styles.expandClose?.textBgColorISI};
                text-align:right;
                font-style:${styles.expandClose?.fontStyle};
                text-transform:${styles.expandClose?.textTransform};
                 text-decoration:${textDecvalue};
                 
              }
              \n #close${layerData.id}{
                display:none;
                height:${styles.expandClose?.expandHeight};
                font-weight:${styles.expandClose?.fontWeight}; 
                font-family:${styles.expandClose?.fontFamily};
                color:${styles.expandClose?.fontColorISI};        
                line-height:${styles.expandClose?.lineHeight};  
                letter-spacing:${styles.expandClose?.letterSpacing};
                font-size:${styles.expandClose?.fontSize}px; 
                background-color:${styles.expandClose?.textBgColorISI};
                text-align:right;
                font-style:${styles.expandClose?.fontStyle};
                text-transform:${styles.expandClose?.textTransform};
                 text-decoration:${textDecvalue}
                
              }
              `
            : "";

          let tempExpandClose = `
            <div id=${layerData.id}>
            ${expanCloseDIV}
            <div id='${layerData.id}ISIInfo' >
            <div id="${layerData.id}arrowTop" class="top_arrow"> </div>
            <div id="scrollbar${layerData.id}" class="scrollbar">
               <div id="${layerData.id}track" class="track">
                  <div id="${layerData.id}thumb" class="thumb"></div>
               </div>
            </div>
            <div id="${layerData.id}viewport" class="viewport" >
               <div id="${layerData.id}overview" class="overview textProps">
               ${layerData.froalaText}
               </div>
            </div>
            <div id="${layerData.id}arrowBottom" class="bottom_arrow"> </div>
         </div>
         </div>
         `;
          let tempScroll = `
         <div id=${layerData.id}>
         <div id='${layerData.id}ISIInfo' >
         <div id="${layerData.id}arrowTop" class="top_arrow"> </div>
         <div id="scrollbar${layerData.id}" class="scrollbar">
            <div id="${layerData.id}track" class="track">
               <div id="${layerData.id}thumb" class="thumb"></div>
            </div>
         </div>
         <div id="${layerData.id}viewport" class="viewport" >
            <div id="${layerData.id}overview" class="overview textProps">
            ${layerData.froalaText}
            </div>
         </div>
         <div id="${layerData.id}arrowBottom" class="bottom_arrow"> </div>
      </div>
      </div>
      `;

          if (styles?.expandAndClose) {
            DOMConstruct += tempExpandClose;
          } else {
            DOMConstruct += tempScroll;
          }

          CssCollect += `\n#${
            layerData.id
          }{position:absolute;top:${newTop}px;width:${styles.width}px; height:${
            styles.height
          }px;
            top:${newTop}px;
            left:${newLeft}px; 
            z-index:${styles.zIndex};
            transform: scale(${newTransform});
            transform:rotate(${newRotation}deg);
            opacity:${newOpacity};
            cursor:${cursorPointer};
            background-color:${styles.textBgColor};
            font-style:${styles?.fontStyle};
            text-transform:${styles?.textTransform}; 
            text-decoration:  ${textDecorationvalue}; 
             text-align:${styles?.textAlign}
            }
             #${layerData.id}ISIInfo{position:relative;
                                      width:${styles.width}px; 
                                     height:${
                                       styles.height +
                                       (styles.arrowHeight === 0 ? 0 : 2) -
                                       (styles.expandAndClose
                                         ? styles.expandClose?.expandHeight
                                         : 0)
                                     }px; 
                                     font-weight:${styles.fontWeight}; 
                                     font-family:${styles.fontFamily};
                                     color:${styles.color};
                                    line-height:${styles.lineHeight};
                                    z-index:${styles.zIndex};letter-spacing:${
            styles.letterSpacing
          };font-size:${styles.fontSize}px; 
                                    overflow: hidden;
                                    background-color:${styles.textBgColor}

          }
            ${expanCloseCSS}
          
               \n #${
                 layerData.id
               }viewport{position:relative;width:100%;height:${
            styles.height
          }px; overflow:hidden;top:0;left:0;word-break:break-word;}
               \n#${
                 layerData.id
               }overview{position:relative; left:0;top:0;padding-right: ${
            styles.trackWidth + 2 + styles.thumbRight * 2
          }px; padding-bottom: ${styles.fontFamily === "Arial" ? "3px" : "10px"}
              }
               \n #scrollbar${layerData.id}{background: rgba(255,255,255,0.00);
               position: absolute;
               right: ${styles.trackRight ? styles.trackRight + 0.2 : 0}px;
               top: ${styles.arrowHeight}px;
               margin-top:${styles.arrowHeight > 0 ? "2px" : "0px"};
               cursor: pointer;
               z-index:10;
               height:${
                 styles.height -
                 styles.arrowHeight * 2 -
                 (styles.arrowHeight == 0 ? 0 : 2) -
                 (styles.expandAndClose ? styles.expandClose?.expandHeight : 0)
               }px;
             }
               \n #${layerData.id}track{  
                                        top:${styles.arrowHeight}px;
                                        width:${styles.trackWidth}px;
                                        background-color: ${styles.thumbColor};
                                        border-radius:${styles.trackRadius}px;
                                        }
    
               \n #${layerData.id}thumb{ right: -${
            styles.thumbRight || ""
          }px; border-radius: inherit;cursor: pointer;  background-color:black; background-position: center; background-image:url("${
            styles.scrollThumbBg
          }" ); background-repeat: no-repeat;
             height:${
               styles.textHeight > styles.height ? styles.thumbHeight : 0
             }px; width:${
            styles.thumbWidth
          }px; cursor: pointer; overflow: hidden; position: absolute; top:0px;
        
          }
               \n #${layerData.id}arrowTop{width:${
            styles.arrowWidth
          }px; height:${styles.arrowHeight}px; background-image:url("${
            styles.scrollUpArrowbg
          }") ;background-repeat: no-repeat;right: 0px; background-color: lightblue; position: absolute; z-index: 20;  background-position: center;
               }
    
               \n #${layerData.id}arrowBottom{width:${
            styles.arrowWidth
          }px; height:${
            styles.arrowHeight
          }px; right: 0px; background-image:url("${
            styles.scrollUpArrowbg
          }" ); background-repeat: no-repeat;background-color: lightblue;position: absolute;transform: scaleY(-1);bottom:0px;z-index: 20;  background-position: center;
            }
               `;
        }
      } else {
        if (Object.keys(set).length) {
          newLeft = set?.x;
          newTop = set?.y;
          newOpacity = set?.opacity;
          newRotation = set?.rotation;
          newTransform = set?.transform;
        } else {
          newLeft = styles.left;
          newTop = styles.top;
          newOpacity = styles?.opacity || 1;
          newRotation = styles?.rotation;
          newTransform = styles?.transform;
        }
        if (!layerData.componentStyles.toggleScroll) {
          // if (Object.keys(set).length) {
          //   newLeft = set?.x;
          //   newTop = set?.y;
          //   newOpacity = set?.opacity;
          //   newRotation = set?.rotation;
          //   newTransform = set?.transform;
          // } else {
          //   newLeft = styles.left;
          //   newTop = styles.top;
          //   newOpacity = styles?.opacity || 1;
          //   newRotation = styles?.rotation;
          //   newTransform = styles?.transform;
          // }

          // let cursorPointer = styles.targetLink ? "pointer" : "auto";

          DOMConstruct = `<div id='${layerData.id}' data-url='${styles.targetLink}' onClick="enableTxt(this)" >${layerData.froalaText} </div>`;
          CssCollect += `\n#${layerData.id}{position:absolute;width:${styles.width}px; height:${styles.height}px;top:${newTop}px;
    left:${newLeft}px; font-weight:${styles.fontWeight}; font-family:${styles.fontFamily};color:${styles.color};line-height:${styles.lineHeight};
    z-index:${styles.zIndex};letter-spacing:${styles.letterSpacing};font-size:${styles.fontSize}px; word-wrap: break-word;
    transform: scale(${newTransform});
    transform:rotate(${newRotation}deg);
    opacity:${newOpacity};
    cursor:${cursorPointer};
    background-color:${styles.textBgColor};
    overflow:hidden;
    }`;
        } else {
          let completeObj = {};
          completeObj.id = layerData.id;
          completeObj.thumbHeight = styles.thumbHeight;
          completeObj.trackHeight =
            styles.height -
            styles.arrowHeight * 2 -
            (styles.arrowHeight === 0 ? 0 : 2); // 4px for matching rc scollbar
          trackHeight.push(completeObj);
          DOMConstruct += `
        <div id=${layerData.id}>
        <div id='${layerData.id}ISIInfo' >
        <div id="${layerData.id}arrowTop" class="top_arrow"> </div>
        <div id="${layerData.id}scrollbar" class="scrollbar">
           <div id="${layerData.id}track" class="track">
              <div id="${layerData.id}thumb" class="thumb"></div>
           </div>
        </div>
        <div id="${layerData.id}viewport" class="viewport" >
           <div id="${layerData.id}overview" class="overview textProps">
           ${layerData.froalaText}
           </div>
        </div>
        <div id="${layerData.id}arrowBottom" class="bottom_arrow"> </div>
     </div>
     </div>
     `;

          CssCollect += `\n#${
            layerData.id
          }{position:absolute;top:${newTop}px;width:${styles.width}px; height:${
            styles.height
          }px;
        top:${newTop}px;
        left:${newLeft}px; 
        z-index:${styles.zIndex};
        transform: scale(${newTransform});
        transform:rotate(${newRotation}deg);
        opacity:${newOpacity};
        cursor:${cursorPointer};
        background-color:${styles.textBgColor};
        }
         #${layerData.id}ISIInfo{position:absolute;
          width:${styles.width}px; 
          height:${styles.height + (styles.arrowHeight === 0 ? 0 : 2)}px; 
          font-weight:${styles.fontWeight}; 
          font-family:${styles.fontFamily};
          color:${styles.color};
          line-height:normal;
                    z-index:${styles.zIndex};letter-spacing:${
            styles.letterSpacing
          };font-size:${styles.fontSize}px; 
        overflow: hidden;
        background-color:${styles.textBgColor}
      }

           \n #${layerData.id}viewport{position:relative;width:100%;height:${
            styles.height
          }px; overflow:hidden;top:0;left:0;word-break:break-word;}
           \n#${
             layerData.id
           }overview{position:absolute; left:0;top:0;padding-right: ${
            styles.trackWidth + 2 + styles.thumbRight * 2
          }px; padding-bottom: ${styles.fontFamily === "Arial" ? "3px" : "10px"}
          }

           \n #${layerData.id}scrollbar{background: rgba(255,255,255,0.00);
           position: absolute;
           right: ${styles.thumbRight}px;
           top: ${styles.arrowHeight}px;
           margin-top:${styles.arrowHeight > 0 ? "2px" : "0px"};
           cursor: pointer;
           z-index:10;
           height:${
             styles.height -
             styles.arrowHeight * 2 -
             (styles.arrowHeight == 0 ? 0 : 2)
           }px;
         }
           \n #${layerData.id}track{  
            top:${styles.arrowHeight}px; width:${
            styles.trackWidth
          }px; background-color: ${styles.thumbColor};border-radius:${
            styles.trackRadius
          }px; }
           \n #${layerData.id}thumb{right: -${
            styles.thumbRight
          }px; border-radius: inherit;cursor: pointer;  background-color:black; background-position: center; background-image:url("${
            styles.scrollThumbBg
          }" ); background-repeat: no-repeat; height:${
            styles.thumbHeight
          }px; width:${
            styles.thumbWidth
          }px; cursor: pointer; overflow: hidden; position: absolute; top:0px; }
           \n #${layerData.id}arrowTop{width:${styles.arrowWidth}px; height:${
            styles.arrowHeight
          }px; background-image:url("${
            styles.scrollUpArrowbg
          }") ;background-repeat: no-repeat;right: 0px; background-color: lightblue; position: absolute; z-index: 20;  background-position: center;

           }
           \n #${layerData.id}arrowBottom{width:${
            styles.arrowWidth
          }px; height:${
            styles.arrowHeight
          }px; right: 0px; background-image:url("${
            styles.scrollUpArrowbg
          }" ); background-repeat: no-repeat;background-color: lightblue;position: absolute;transform: scaleY(-1);bottom:0px;z-index: 20;  background-position: center;
        }p {line-height:normal}
           `;
        }
      }
      break;

    case "Image":
      if (true) {
        if (Object.keys(set).length) {
          newLeft = set.x;
          newTop = set.y;
          // newWidth = set.width;
          // newHeight = set.height;
          newOpacity =
            styles.compOpacity === 100
              ? set?.opacity
              : styles.compOpacity / 100;
          newRotation = set?.rotation;
          newTransform = set?.transform;
        } else {
          newLeft = styles.left;
          newTop = styles.top;
          // newWidth = styles.width;
          // newHeight = styles.height;
          newOpacity =
            styles.compOpacity === 100
              ? set?.opacity
              : styles.compOpacity / 100;
          newRotation = set?.rotation;
          newTransform = set?.transform;
        }

        let imageLink = styles.background.includes("mm_assets")
          ? styles.background
          : ".".concat(
              styles.background.substr(styles.background.lastIndexOf("/"))
            );
        DOMConstruct =
          popUpOptions?.skewAnimation === true
            ? `<div class="skew1" id="${layerData.id}" >
        <div class="skew1imgbg"  data-url='${styles.targetLink}'  onClick="enableTxt(this)"></div>
      </div>`
            : `<a id='${layerData.id}' style="text-decoration:none;" href='${
                canvasDimension.checkGloballink === true &&
                canvasDimension.checkGloballink !== undefined
                  ? canvasDimension.globalLink
                  : styles.targetLink
              }' onClick="enableTxt(this)"></a>`;

        CssCollect +=
          popUpOptions?.skewAnimation === true
            ? `\n#${layerData.id} {         
              background-image:url(${styles.background})
  }`
            : `\n#${layerData.id}{position:absolute;width:${styles.width}px; height:${styles.height}px;top:${newTop}px;
          left:${newLeft}px;
          z-index:${styles.zIndex};
          background-color:${styles.backgroundColor};
          border:${styles.border};
          border-radius:${styles.borderTopLeftRadius}px ${styles.borderTopRightRadius}px ${styles.borderBottomRightRadius}px ${styles.borderBottomLeftRadius}px ;
          background-size:${styles.width}px ${styles.height}px;
          background-image:url(${styles.background});
          background-repeat: ${styles.backgroundRepeat};
          transform: scale(${newTransform});
          transform:rotate(${newRotation}deg);
          opacity:${newOpacity};
          cursor:${cursorPointer};
        }
          `;
      } else {
        if (Object.keys(set).length) {
          newLeft = set.x;
          newTop = set.y;
          newOpacity =
            styles.compOpacity === 100
              ? set?.opacity
              : styles.compOpacity / 100;
          newRotation = set?.rotation;
          newTransform = set?.transform;
        } else {
          newLeft = styles.left;
          newTop = styles.top;
          newOpacity =
            styles.compOpacity === 100
              ? set?.opacity
              : styles.compOpacity / 100;
          newRotation = set?.rotation;
          newTransform = set?.transform;
        }

        let imageLink = styles.background.includes("mm_assets")
          ? styles.background
          : ".".concat(
              styles.background.substr(styles.background.lastIndexOf("/"))
            );
        DOMConstruct =
          popUpOptions?.skewAnimation === true
            ? `<div class="skew1" id="${layerData.id}" >
      <div class="skew1imgbg"  data-url='${styles.targetLink}'  onClick="enableTxt(this)"></div>
    </div>`
            : `<div id='${layerData.id}' data-url='${styles.targetLink}' onClick="enableTxt(this)"></div>`;

        CssCollect +=
          popUpOptions?.skewAnimation === true
            ? `\n#${layerData.id} {         
            background-image:url(${imageLink})
}`
            : `\n#${layerData.id}{position:absolute;width:${styles.width}px; height:${styles.height}px;top:${newTop}px;
        left:${newLeft}px;
        z-index:${styles.zIndex};
        background-color:${styles.backgroundColor};
        border:${styles.border};
        border-radius:${styles.borderTopLeftRadius}px ${styles.borderTopRightRadius}px ${styles.borderBottomRightRadius}px ${styles.borderBottomLeftRadius}px ;
        background-size:${styles.backgroundSize};
        background-image:url(${imageLink});
        background-position:${styles.backgroundPosition};
        background-repeat: ${styles.backgroundRepeat};
        transform: scale(${newTransform});
        transform:rotate(${newRotation}deg);
        opacity:${newOpacity};
        cursor:${cursorPointer};
      }
        `;
        img.push(styles.background);
      }
      break;
    case "Button":
      if (Object.keys(set).length) {
        newLeft = set.x;
        newTop = set.y;
        newOpacity = set?.opacity;
        newRotation = set?.rotation;
        newTransform = set?.transform;
      } else {
        newLeft = styles.left;
        newTop = styles.top;
        newOpacity = set?.opacity;
        newRotation = set?.rotation;
        newTransform = set?.transform;
      }
      let textDecorationvalue = styles?.underlineClick
        ? styles?.strikeThroughClicked
          ? styles?.textDecUnderline + " " + styles?.textDecoration
          : styles?.textDecUnderline
        : styles?.textDecoration;
      DOMConstruct = `<a id='${layerData.id}' data-url='${
        styles.targetLink
      }' style="text-decoration:none;" href='${
        canvasDimension.checkGloballink === true &&
        canvasDimension.checkGloballink !== undefined
          ? canvasDimension.globalLink
          : styles.targetLink
      }'>${layerData.froalaText} </a>`;
      CssCollect += `\n#${layerData.id}{position:absolute;width:${
        styles.width
      }px; height:${styles.height}px;top:${newTop}px;
    left:${newLeft}px; font-weight:${styles.fontWeight}; 
    font-family:${styles.fontFamily};
    color:${styles.color};
    line-height:${styles.lineHeight};
    z-index:${styles.zIndex};
    letter-spacing:${styles.letterSpacing};
    font-size:${styles.fontSize};
    background-color:${styles.backgroundColor};
    border:${styles.border};
    border-radius:${styles.borderTopLeftRadius}px ${
        styles.borderTopRightRadius
      }px ${styles.borderBottomRightRadius}px ${
        styles.borderBottomLeftRadius
      }px ;
    text-align:${styles.textAlign};
    padding:${styles.paddingTop}px ${styles.paddingRight}px ${
        styles.paddingBottom
      }px ${styles.paddingLeft}px;
    word-wrap: break-word;
overflow:hidden;
transform: scale(${newTransform});

transform:rotate(${newRotation}deg);
opacity:${newOpacity};
cursor: '${
        styles.targetLink !== "http://" && styles.targetLink !== ""
          ? "pointer"
          : "default"
      }';
      font-style:${styles?.fontStyle};
    text-transform:${styles?.textTransform};
     text-decoration:${textDecorationvalue};
     text-align:${styles?.textAlign}

    }p {line-height:normal}`;
      break;
    case "Shape":
      if (Object.keys(set).length) {
        newLeft = set.x;
        newTop = set.y;
        // newOpacity = set?.opacity;
        newOpacity =
          styles.compOpacity === 100 ? set?.opacity : styles.compOpacity / 100;
        newRotation = set?.rotation;
        newTransform = set?.transform;
      } else {
        newLeft = styles.left;
        newTop = styles.top;
        //newOpacity = set?.opacity;
        newOpacity =
          styles.compOpacity === 100 ? set?.opacity : styles.compOpacity / 100;
        newRotation = set?.rotation;
        newTransform = set?.transform;
      }

      if (layerData.textComponentType?.addContent === "triangleShape") {
        DOMConstruct = `<div id='${layerData.id}' data-url='${styles.targetLink}' ><img src="${layerData.triImage}" class="triangleImage"  style="width:${styles.width}px; height:${styles.height}px"width:${styles.width}px; height:${styles.height}px"/></div>`;
        CssCollect += `\n#${layerData.id}{position:absolute;width:${styles.width}px; height:${styles.height}px;
        top:${newTop}px;
        left:${newLeft}px;   
      z-index:${styles.zIndex};
      transform: scale(${newTransform});

      transform:rotate(${newRotation}deg);
      opacity:${newOpacity};
     
      }`;
      } else if (layerData.textComponentType?.addContent === "polygonShape") {
        DOMConstruct = `<div id='${layerData.id}' data-url='${styles.targetLink}' ><img src="${layerData.pentImage}" class="pentagonImage" style="width:${styles.width}px; height:${styles.height}"/></div>`;
        CssCollect += `\n#${layerData.id}{position:absolute;width:${styles.width}px; height:${styles.height}px;
        top:${newTop}px;
        left:${newLeft}px; 
      z-index:${styles.zIndex};
      transform: scale(${newTransform});

      transform:rotate(${newRotation}deg);
      opacity:${newOpacity};
      }`;
      } else if (layerData.textComponentType?.addContent === "hexagonShape") {
        DOMConstruct = `<div id='${layerData.id}' data-url='${styles.targetLink}' ><img src="${layerData.hexImage}"  style="width:${styles.width}px; height:${styles.height}"/></div>`;
        CssCollect += `\n#${layerData.id}{position:absolute;width:${styles.width}px; height:${styles.height}px;
        top:${newTop}px;
        left:${newLeft}px; 
      z-index:${styles.zIndex};
      transform: scale(${newTransform});

      transform:rotate(${newRotation}deg);
      opacity:${newOpacity};
      }`;
      } else if (layerData.textComponentType?.addContent === "rectangleShape") {
        DOMConstruct = `<div id='${layerData.id}' data-url='${styles.targetLink}' ></div>`;
        CssCollect += `\n#${layerData.id}{position:absolute;width:${styles.width}px; height:${styles.height}px;
        top:${newTop}px;
        left:${newLeft}px; 
      z-index:${styles.zIndex};
      background-color:${styles.backgroundColor};
      border:${styles.border};
      border-radius:${styles.borderTopLeftRadius}px ${styles.borderTopRightRadius}px ${styles.borderBottomRightRadius}px ${styles.borderBottomLeftRadius}px ;
      padding:${styles.paddingTop}px ${styles.paddingRight}px ${styles.paddingBottom}px ${styles.paddingLeft}px;
      transform: scale(${newTransform});

      transform:rotate(${newRotation}deg);
      opacity:${newOpacity};
  }`;
      } else if (layerData.textComponentType?.addContent === "circleShape") {
        DOMConstruct = `<div id='${layerData.id}' data-url='${styles.targetLink}' ></div>`;
        CssCollect += `\n#${layerData.id}{position:absolute;width:${styles.width}px; height:${styles.height}px;
        top:${newTop}px;
        left:${newLeft}px; 
      z-index:${styles.zIndex};
      background-color:${styles.backgroundColor};
      border:${styles.border};
      border-radius:${styles.borderRadius} ;
      paddingTop: ${styles.paddingTop}px;
      transform: scale(${newTransform});

      transform:rotate(${newRotation}deg);
      opacity:${newOpacity};
  }`;
      } else {
      }
      break;
    // case "TextWithScroll":
    //   for (let i = 0; i < getFroalaInstance["current"].length; i++) {
    //     if (getFroalaInstance["current"][i]?.props?.id == childId) {
    //       // let url = document.getElementById(parentId).dataset.url;
    //       editorHtml = getFroalaInstance["current"][i].editor.html.get();

    //       DOMConstruct += `;
    //       <div id='ISIInfo' >
    //       <div id="arrowTop"> </div>
    //       <div id="scrollbar" class="scrollbar">
    //          <div id="track" class="track">
    //             <div id="thumb" class="thumb"></div>
    //          </div>
    //       </div>
    //       <div id="viewport" class="viewport" >
    //          <div id="overview" class="overview">
    //             ${editorHtml}
    //          </div>
    //       </div>
    //       <div id="arrowBottom"> </div>
    //    </div>`;

    //       let trackStyle = document
    //         .querySelector(".rc-scrollbars-track")
    //         .getAttribute("style");
    //       //document.querySelector('.rc-scrollbars-track-v').style;
    //       let thumb = document.querySelector(".rc-scrollbars-thumb-v");
    //       let thumbStyle = thumb.getAttribute("style");
    //       let topArrow = document.getElementById("arrowTop");
    //       let topArrowStyle = topArrow.getAttribute("style");
    //       let bottomArrow = document.getElementById("arrowBottom");
    //       let bottomArrowStyle = bottomArrow.getAttribute("style");
    //       trackHeight = thumb.offsetHeight - topArrow.offsetHeight * 2;
    //       console.log(topArrowStyle, "trackStyle");

    //       let childstyle = document.querySelector("#" + childId);
    //       CssCollect += `\n #ISIInfo{position:absolute;${parentDOMStyle};  }
    //       \n #viewport{position:relative;width:100%;height:${
    //         childstyle.offsetHeight - 10
    //       }px; overflow:hidden;top:0;left:0; }
    //       \n#overview{position:absolute; left:0; top:0;padding-right: 10px;}

    //       \n #scrollbar{background: rgba(255,255,255,0.00);
    //       position: absolute;
    //       right: 2px;
    //       top: 0px;
    //       width: 7px;
    //       cursor: pointer;
    //       z-index:10;
    //       height:${childstyle.offsetHeight}px;
    //     }

    //       \n #track{ background-color: #ada9a8;cursor: pointer;${trackStyle};top:${
    //         topArrow.offsetHeight
    //       } }

    //       \n #thumb{ cursor: pointer; ${thumbStyle}; }
    //       \n #arrowTop{width:8px; height:8px;  right: 3px; background-color: lightblue; position: absolute;  ${topArrowStyle}}
    //       \n #arrowBottom{width:8px; height:8px;  right: 2px;  background-color: lightblue;position: absolute;transform: scaleY(-1);bottom:0px;${bottomArrowStyle}}
    //       `;
    //     }
    //   }
    //   break;

    default:
      break;
  }
  return { DOMConstruct, CssCollect, trackHeight, thumbHeight };
}
