import React from "react";

import UnAuthorizeModal from "../components/unAuthorizeModal";
import SomeServerError from "../components/someServerErrorModal";
import NotFoundError from "../components/notFoundErrorModal";
import DefaultApiErrorModal from "../components/defaultApiErrorModal";
import AuthenticateErr from "../components/authenticateErrModal";
import CoreClaimError from "../components/CoreClaimErrorModal";
import EmptyFieldSearchErr from '../components/EmptyFieldSearchErrModal'


const errComponentToRender = (errorCode) => {
  switch (errorCode) {
    case 401:
      return <AuthenticateErr />;
    case 500:
      return <SomeServerError />;
    case 404:
      return <NotFoundError />;
    case 403:
      return <UnAuthorizeModal />;
    case 601:
      return <CoreClaimError />
    case 602:
      return <EmptyFieldSearchErr/>
    default:
      return <DefaultApiErrorModal />;
  }
};

export default errComponentToRender;
