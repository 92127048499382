import React, { useState, useContext } from "react";
import "../../styles/pages/Banners/BannerManager.scss";
import { useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";
import ValueContext from "../../ContextAPi/appContext";
import { useTranslation } from "react-i18next";
import TemplateForms from "./TemplateForms";
import CreateTemplateModal from "./CreateTemplateModal";

import "./TemplateM.scss";
import NewBannerModal from "../../components/BannerManager/NewBannerModal";

export default function TemplateLandingPage(props) {
  const appData = useContext(ValueContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [showNewTemplate, setShowNewTemplate] = useState(false);
  const [preDefined, setPredefined] = useState(true);
  //  const [bannerNameInput,setBannerNameInput]=useState('');
  //   const [tags,setTags]=useState('');

  const [customTemp, setCustomBannerSize] = React.useState(false);
  const handleCreateTempModal = () => {
    setShowNewTemplate(!showNewTemplate);
  };
  const [showNewBanner, setShowNewBanner] = useState(false);

  const openNewBannerModal = (isRedirectFlag = false, genericInfo = {}) => {
    setShowNewBanner(!showNewBanner);
    if (isRedirectFlag === true) {
      appData.setFlagEditBanner(false);
      appData.setBannersEditCardData({
        cards: "",
      });
      // history.push("/banner-editor");
    }
  };
  return (
    <>
      <div className="row align-items-center TemplateMangerHeaderRow pt-4 pb-4">
        <div className="col-md-6 bannerManagerTitle">
          {props.banner ? "Banner Manager" : t("bannerManager.templateManager")}
        </div>
        <div className="col-md-6 text-right">
          <button
            type="button"
            className="newTemplateBut"
            //   onClick={handleCreateTempModal}
            onClick={props.banner ? openNewBannerModal : handleCreateTempModal}
          >
            <span>
              {props.banner
                ? "CREATE NEW BANNER"
                : t("bannerManager.newTemplate")}
            </span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 template-managerM">
          <TemplateForms
            banner={props.banner}
            preDefined={preDefined}
            setPredefined={setPredefined}
            customTemp={customTemp}
            setCustomBannerSize={setCustomBannerSize}
          />
        </div>
      </div>
      {showNewTemplate && (
        <CreateTemplateModal
          showNewTemplate={showNewTemplate}
          setShowNewTemplate={setShowNewTemplate}
          assetType={props.banner}
        />
      )}
      {showNewBanner && (
        <NewBannerModal
          showNewBanner={showNewBanner}
          setShowNewBanner={setShowNewBanner}
          assetType={props.banner}
          preDefined={preDefined}
          setPredefined={setPredefined}
          customTemp={customTemp}
          setCustomBannerSize={setCustomBannerSize}
        />
      )}
    </>
  );
}
