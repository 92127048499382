import React, { useEffect, useState } from "react";
import profileIcon from "../images/profileIcon.svg";
import "../styles/pages/Logout.scss";
import { logout } from "../Uitls";
import { useHistory } from "react-router-dom";
import UserInfo from "./Dashboard/userDetails";

const Logout = () => {
  const history = useHistory();

  const onLogout = (saveSuccess) => {
    logout();
    history.push("/");
  };

  return (
    <>
      <div className="templateScreenLogout">
        <div className="d-flex templateLogoutContainer">
          <img
            src={profileIcon}
            alt=""
            className="profile-Icon img-fluid mr-2"
          />
          <div className="templateLogoutAccout">
            <span>
              <UserInfo />
            </span>
            <button className="btn btnl-link" onClick={() => onLogout()}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Logout;
