import React, { useRef, useEffect, useState } from "react";
import "../../styles/components/AnimationTimeLine.scss";

function Canvas(props) {
  let canvasRef = useRef(null);

  // const [draggerWidth, setDraggerWidth] = useState(675);
  // const [isResizing, setIsResizing] = useState(false);
  const [startX, setStartX] = useState(0);

  const [cursorOffset, setCursorOffset] = useState(0);

  useEffect(() => {
    props.setDraggerWidth(props.appData?.genericInfo?.draggerWidth || 675);
  }, []);

  useEffect(() => {
    let canvas = canvasRef.current;

    let propObj = {};
    propObj.AXIS_ORIGIN = { x: 0, y: canvas.height - 0 };
    propObj.TEXT_FONTSIZE = 10;
    propObj.TOTAL_DURATION = props.totalDuration.divisions;
    propObj.SECONDS_DIVISION = 5;
    propObj.HORIZONTAL_TICK_SPACING =
      (canvas.width - propObj.TEXT_FONTSIZE) /
      propObj.TOTAL_DURATION /
      propObj.SECONDS_DIVISION;
    propObj.NUM_HORIZONTAL_TICKS =
      propObj.TOTAL_DURATION * propObj.SECONDS_DIVISION;
    propObj.TICKS_LINEWIDTH = 0.5;

    const context = canvasRef.current.getContext("2d");
    drawHorizontalAxisTicks();

    function drawHorizontalAxisTicks() {
      let j = 1;
      let moveText = 0;

      //const canvasHeight = canvas.height;
      const context = canvas.getContext("2d");
      //const dpi = window.devicePixelRatio || 1;
      //  const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      // canvas.width = canvasWidth * dpi;
      // canvas.height = canvasHeight * dpi;
      //context.scale(dpi, dpi);

      //      context.lineWidth = 0.7 * dpi;
      // container.style.width = `${canvas.width}px`;

      // context.fillStyle = "#dae5f2";
      // context.fillRect(
      //   0,
      //   25 - 25,
      //   propObj.HORIZONTAL_TICK_SPACING * 75,
      //   canvasHeight
      // );

      context.strokeStyle = "#000000";
      context.fillStyle = "#231f20";

      for (var i = 0; i <= propObj.NUM_HORIZONTAL_TICKS; ++i) {
        context.beginPath();

        if (i !== 0 && i % 5 === 0) {
          const tickY = 28;
          const lineY = 50;

          context.moveTo(0 + propObj.HORIZONTAL_TICK_SPACING * i, lineY);
          context.lineTo(propObj.HORIZONTAL_TICK_SPACING * i, tickY);
          context.textAlign = "left";
          if (j > 9) moveText = 5;
          else moveText = 2;
          context.fillText(
            j++,
            propObj.HORIZONTAL_TICK_SPACING * i - moveText,
            tickY - 4
          );
        } else {
          const tickY = 38;

          context.moveTo(
            propObj.AXIS_ORIGIN.x + i * propObj.HORIZONTAL_TICK_SPACING,
            45
          );
          context.lineTo(
            propObj.AXIS_ORIGIN.x + i * propObj.HORIZONTAL_TICK_SPACING,
            tickY
          );
        }
        context.stroke();
      }

      const borderY = 28;
      context.beginPath();
      context.moveTo(propObj.AXIS_ORIGIN.x, borderY);
      context.lineTo(
        propObj.AXIS_ORIGIN.x +
          propObj.NUM_HORIZONTAL_TICKS * propObj.HORIZONTAL_TICK_SPACING,
        borderY
      );
      context.stroke();
    }
  }, [props.totalDuration.divisions]);

  const handleMouseDown = (event) => {
    if (
      event.clientX >
      canvasRef.current.getBoundingClientRect().left + props.draggerWidth - 10
    ) {
      setStartX(event.clientX);
      props.setIsResizing(true);
      props.setMousUpOnTimeline(false);
    }
    const divRect = event.currentTarget.getBoundingClientRect();
    const offset = divRect.right - event.clientX;
    setCursorOffset(offset);
  };

  const handleMouseUp = (event) => {
    props.setIsResizing(false);
    props.setMousUpOnTimeline(true);
    props.appData.updateGenericInfo({
      ...props.appData.genericInfo,
      draggerWidth: props.draggerWidth,
    });
  };

  const handleMouseMove = (event) => {
    const resizableDiv = document.getElementById("resizableDiv");

    const cursorPosition =
      event.clientX - resizableDiv.getBoundingClientRect().left;
    if (props.isResizing || props.movedOnMarker) {
      const newWidth = props.draggerWidth + (event.clientX - startX);
      let oneCM = props.totalDuration.divisions < 60 ? 45 : 44.97;
      if (newWidth < oneCM || newWidth > props.totalDuration.divisions * oneCM)
        return;
      props.setDraggerWidth(newWidth);
      setStartX(event.clientX);
      // props.setMovedOnMarker(false);
    }
    if (cursorPosition >= props.draggerWidth - 8) {
      resizableDiv.style.cursor = "e-resize";
    } else {
      resizableDiv.style.cursor = "default";
    }
  };

  const handleMouseOut = (event) => {
    props.setIsResizing(false);
  };
  console.log(
    "props.playPause?.animationCompleted",
    props.playPause?.animationCompleted
  );
  return (
    <div className="canvas-division" onMouseLeave={handleMouseOut}>
      <canvas
        width={props.totalDuration.width + "px"}
        position="absolute"
        height="49px"
        margin-top="100px"
        ref={canvasRef}
        className="ruler"
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      ></canvas>
      <div
        id="resizableDiv"
        style={{
          width: `${props.draggerWidth}px`,
          height: "50px",
          backgroundColor: "rgba(9, 88, 181, 0.1)", // "#dae5f2",
          position: "absolute",
          top: 4,
          left: 0,
          resize: "horizontal",
          cursor: props?.playPause?.animationCompleted ? "w-resize" : "default",
          marginLeft: "6px",
        }}
        onMouseDown={(event) =>
          props?.playPause?.animationCompleted &&
          handleMouseDown(event, "right")
        }
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      ></div>
    </div>
  );
}

export default Canvas;
