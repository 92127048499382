import React, { useEffect, useState } from 'react';
import '../styles/components/ModuleSpecificInfo.scss';
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const BusinessRulesSpecificInfo = (props) => {
    const { t } = useTranslation();
    const [module, setModule] = useState(undefined)
    const [bModules, setBModules] = useState(undefined)
    const [pageJsx, setPageJsx] = useState(undefined)

    useEffect(() => {
        if (props.module && props.bModules) {
            if(module && module.id && module.id !== props.module.id){
                setPageJsx(undefined)
            }
            setModule(props.module)
            setBModules(props.bModules)
        }
        // if (props.bModules) {
        //     setBModules(props.bModules)
        // }
    }, [props])

    useEffect(() => {
        if(props.setJsx){
            props.setJsx(pageJsx)
        }
    }, [pageJsx])

    const filterRules = (filterParam) => {
        if(!module || !bModules){
            return []
        }
        const businessRules = module.moduleBusinessRules
        if(!businessRules){
            return []
        }
        const rules =  businessRules.filter((rule) => {
            return rule.ruleCondition === filterParam
        })
        let finalRules = []
        for(const rule of rules){
            finalRules = finalRules.concat(bModules.find((item) => {
                return rule.targetModuleId === item.id
              }))
        }
        return finalRules
    }
    const renderMustExistsWith = () => {
        return (
            <tr>
                <td className='tbl-row-txt-bold'>
                    <ul className = "list-item-margin-0">
                    <li>
                    {t("bussinessRulesSpecificInfo.mustExistWith")}
                    </li>
                    </ul>
                </td>
                <td>
                <ol>
                    {
                       filterRules('MustExistWith').map((rule) => {
                           return (rule && rule.id && rule.name) && (
                                   <li className = 'text-blue' key = {rule ? rule.id : ''}>
                                   {rule ? rule.name : ''}
                               </li>
                               
                           )
                       })
                    }
                    </ol>
                </td>
            </tr>
        )
    }
    const renderCannotExistWith = () => {
        return (
            <tr>
                <td className='tbl-row-txt-bold'>
                    <ul className = "list-item-margin-0">
                    <li>
                    {t("bussinessRulesSpecificInfo.cannotExistWith")}
                    </li>
                    </ul>
                </td>
                <td>
                <ol>
                    {
                       filterRules('InCompatibleWith').map((rule) => {
                           return (rule && rule.id && rule.name) && (
                                   <li className = 'text-blue' key = {rule ? rule.id : ''}>
                                   {rule ? rule.name : ''}
                               </li>
                               
                           )
                       })
                    }
                    </ol>
                </td>
            </tr>
        )
    }
    const renderPrecedes = () => {
        return (
            <tr>
                <td className='tbl-row-txt-bold'>
                    <ul className = "list-item-margin-0">
                    <li>
                    {t("bussinessRulesSpecificInfo.precedes")}
                    </li>
                    </ul>
                </td>
                <td>
                <ol>
                    {
                       filterRules('Precedes').map((rule) => {
                           return (rule && rule.id && rule.name) && (
                                   <li className = 'text-blue' key = {rule ? rule.id : ''}>
                                   {rule ? rule.name : ''}
                               </li>
                               
                           )
                       })
                    }
                    </ol>
                </td>
            </tr>
        )
    }

    const renderOtherBusinessRules = () => {
        if(!module){
            return
        }
        let othRule = ''
        const addInfo = module.additionalInformations
        if(addInfo && addInfo.length > 0){
            const info = addInfo[0]
            if(info.information){
                othRule = info.information.otherBusinessRules
                // if(othRule){
                //     othRule = othRule.replace(/(<([^>]+)>)/ig,"").replace(/[[\]]/g,'')
                // }
                
            }
        }
        return (
            <tr>
            <td className='tbl-row-txt-bold'>{t("bussinessRulesSpecificInfo.otherBussinessrule")}</td>
            <td className = 'text-blue'>
                {othRule && parse(othRule)}
                </td>
        </tr>
        )
    } 
    const renderSucceeds = () => {
        return (
            <tr>
                <td className='tbl-row-txt-bold'>
                    <ul className = "list-item-margin-0">
                    <li>
                    {t("bussinessRulesSpecificInfo.succeeds")}
                    </li>
                    </ul>
                </td>
                <td>
                <ol>
                    {
                       filterRules('Succeeds').map((rule) => {
                           return (rule && rule.id && rule.name) && (
                                   <li className = 'text-blue' key = {rule ? rule.id : ''}>
                                   {rule ? rule.name : ''}
                               </li>
                               
                           )
                       })
                    }
                    </ol>
                </td>
            </tr>
        )
    }

    const renderRelatedModules = () => {
        return (
            <tr>
                <td className='tbl-row-txt-bold'>
                    
                {t("bussinessRulesSpecificInfo.relatedModules")}
                    
                </td>
                <td>
                <ol>
                    {
                       filterRules('RelatedTo').map((rule) => {
                           return (
                                   <li className = 'text-blue' key = {rule ? rule.id : ''}>
                                   {rule ? rule.name : ''}
                               </li>
                               
                           )
                       })
                    }
                    </ol>
                </td>
            </tr>
        )
    } 


    const renderData = () => {

        if(!module){
            return (
                <div></div>
            )
        }
        const jsx =  (
            <div>
                <table className='module-specific table business-rules-specific'>
                    <tbody>
                        <tr className = 'header'>
                            <td > {t("bussinessRulesSpecificInfo.businessRules")}</td>
                            <td></td>
                        </tr>
                        {renderMustExistsWith()}
                        {renderCannotExistWith()}
                        {renderPrecedes()}
                        {renderSucceeds()}
                        {renderOtherBusinessRules()}
                        {renderRelatedModules()}
                        {/* <tr>
                            <td className = 'tbl-row-txt-bold'>ID</td>
                            <td>{module.id}</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        )

        if(!pageJsx){
            setPageJsx(jsx)
        }
        return jsx
    }


    return (
        <div>
            {renderData()}
        </div>
    )
}

export default BusinessRulesSpecificInfo