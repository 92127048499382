import React, { useState, useContext } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import manPicture from "../../images/email01.png";
import "../../styles/components/Editor.scss";
import "../../styles/components/EditorModal.scss";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ExportCenterContainer from "../BannerManager/ExportCenterContainer";
import ValueContext from "../../ContextAPi/appContext";
import { RVCVBulk } from "./RVCVApi";
import { downLoad } from "../Editor/DownloadHTML";
import { toast } from "react-toastify";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormDOMObj from "./FormDOMObj";
import Button from "@material-ui/core/Button";
import { RenderDOMData } from "./RenderDOMData";

export default function MultipleRvcvModal(props) {
  const { t } = useTranslation();

  const [rvcvBannerList, setRVCVBannerList] = useState([]);
  const [type, setType] = useState(false);
  // const [exportDOMData, setExportDOMData] = useState({
  //   cards: "",
  // });
  // props.responseData.map((recentlyViewData) => ({
  //   id: recentlyViewData.id,
  //   data: recentlyViewData,
  //   toggleRVCV: false,
  // }))
  // const [rvcvFileTypes, setRvcvFileTypes] = useState({
  //   value1X: false,
  //   value2X: false,
  //   value3X: false,
  // });

  React.useEffect(() => {
    let selectedData = [];
    props.selectMultipleBanner.forEach((recentlyViewData) => {
      if (recentlyViewData.toggleRVCV)
        selectedData.push({
          data: recentlyViewData,
          fileTypes: { value1X: true, value2X: false, value3X: false },
        });
    });

    setRVCVBannerList(selectedData);
  }, [props.selectMultipleBanner]);
  const rvcvImagesArray = [
    {
      rvcvImg: manPicture,
    },
    {
      rvcvImg: manPicture,
    },
  ];
  //console.log(props, rvcvBannerList);

  const handleChangeRvcvfiletype = (e, id) => {
    let rvcvBanner = [...rvcvBannerList];

    rvcvBanner[id].fileTypes[e.target.name] = e.target.checked;

    let name = e.target.name;
    if (name === "value2X") {
      rvcvBanner[id].fileTypes.value3X = false;
      rvcvBanner[id].fileTypes.value1X = false;
    } else if (name === "value3X") {
      rvcvBanner[id].fileTypes.value2X = false;
      rvcvBanner[id].fileTypes.value1X = false;
    } else if (name === "value1X") {
      rvcvBanner[id].fileTypes.value2X = false;
      rvcvBanner[id].fileTypes.value3X = false;
    }
    setRVCVBannerList([...rvcvBannerList]);
  };
  // const appData = useContext(ValueContext);

  // const [checkExportType, setCheckExportType] = React.useState({
  //   PDF: false,
  //   PNG: false,
  //   JPG: false,
  //   HTML: false,
  //   RVCVFILE: false,
  // });
  const generateRVCV = async () => {
    let wholeObject = [];
    let set = false;
    let bannerName = [];
    rvcvBannerList.forEach(async (rvcvSelected, rvcvSelectedIndex) => {
      let layerData = [];
      if (
        rvcvSelected.data?.data?.assetpages[0]?.assetpagecomponent.length > 0
      ) {
        layerData = RenderDOMData(
          rvcvSelected.data?.data?.assetpages[0]?.assetpagecomponent
        );

        let canvasDimension = {
          width: rvcvSelected.data?.data.width,
          height: rvcvSelected.data?.data.height,
          bgColorCanvas: rvcvSelected.data?.data?.attributes?.backgroundColor,
          fps: rvcvSelected.data?.data?.attributes?.fps,
          globalLink: rvcvSelected.data?.data?.globalLink,
          checkGloballink: rvcvSelected.data?.data?.checkGloballink,
          checkLoops: rvcvSelected.data?.data?.checkLoops,
          loopAnimation: rvcvSelected.data?.data?.loopAnimation,
          assetName: rvcvSelected.data?.data?.assetName,
          custom: rvcvSelected.data?.data?.custom,
        };

        let finalDOMObj = downLoad({
          layerCompData: layerData.layerCompData,
          assetId: rvcvSelected.data?.data?.id,
          canvasDimension: canvasDimension,
          bgColorCanvas: canvasDimension.bgColorCanvas,
          animationObject: layerData.animationObj,
          flag: true,
        });

        wholeObject.push({
          page: FormDOMObj({
            finalDOMObj,
            type: "rvcv",
            animationObj: layerData.animationObj,
            checkExportType: false,
            canvasDimension: canvasDimension,
          }).html,
          rvcv:
            rvcvSelected.fileTypes.value2X === true
              ? 2
              : rvcvSelected.fileTypes.value3X === true
              ? 3
              : 1,
          name: canvasDimension.assetName,
          emailID: localStorage.getItem("RVCV-email"),
          currentUserName: localStorage.getItem("currentUserName"),
        });

        // if (rvcvSelectedIndex === 0) {
        //   toast.success(
        //     <div className="notificationText">
        //       Please click on the link sent to you via email to download the
        //       RVCV images.
        //     </div>
        //   );
        // }
        // if (
        //   (await rvcvApiCall(
        //     DOMObj,
        //     rvcvSelected.fileTypes,
        //     canvasDimension
        //   )) === "err"
        // )
        //   if (rvcvSelectedIndex === rvcvBannerList.length - 1)
        //     toast.error(<div className="notificationText">No Animations</div>);
      } else {
        bannerName.push(rvcvSelected.data?.data?.assetName);
        // toast.success(
        //   <div className="notificationText">
        //     Banner is empty! {rvcvSelected.data?.data?.assetName}
        //   </div>
        // );
      }
    });

    props.setOpenRVCvmodal(false);
    RVCVBulk(wholeObject);
    if (bannerName.length === 0) {
      toast.success(
        <div className="notificationText">
          Please click on the link sent to you via email to download the RVCV
          images.
        </div>
      );
    } else if (
      bannerName.length > 0 &&
      bannerName.length < rvcvBannerList.length
    ) {
      // let noComponents = bannerName.join(", ");
      // console.log("noComponents", noComponents);
      bannerName.forEach((name) => {
        toast.success(
          <div className="notificationText">{name} Banner are empty!</div>
        );
      });

      toast.success(
        <div className="notificationText">
          Please click on the link sent to you via email to download the RVCV
          images.
        </div>
      );
    } else if (bannerName.length === rvcvBannerList.length) {
      toast.success(
        <div className="notificationText">
          Please click on the link sent to you via email to download the RVCV
          images.
        </div>
      );
    }
  };
  return (
    <>
      <Dialog
        disableBackdropClick
        open={props.openRVCvmodal}
        onClose={() => props.setOpenRVCvmodal(false)}
        className="multipleRvcvModal"
        aria-labelledby="form-dialog-title"
        overlaystyle={{ backgroundColor: "transparent" }}
      >
        <DialogTitle id="form-dialog-title">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-10 generateRvcvHeading">
              {t("bannerManager.generateRVCVfile")}
            </div>
            <div className="col-md-2 selctedFilecount">
              {rvcvBannerList.length} {t("File(s) Selected")}
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="scrollRvcv customScrollBar">
          <div className="subtitleRvcv">{t("bannerManager.selectSize")}</div>
          <div className="rvcvScroll">
            {rvcvBannerList.map((rvcvBanner, indexrvcvBanner) => (
              <div>
                <div className="row rvcvBannername">
                  <div className="col-md-12">
                    {rvcvBanner.data.data.assetName}
                  </div>
                </div>
                <div className="row rvcvimagesRow">
                  <div className="col-md-1"></div>
                  <div className="col-md-3">
                    <img
                      src={rvcvBanner.data.data.thumbNail}
                      alt="BannerSS"
                      className="img-fluid rvcvImgArray"
                      //style={rvcvBanner.data.data.height<}
                    />
                  </div>
                  <div className="col-md-8">
                    <FormControl id="rvcvMultiplefile">
                      <RadioGroup
                        aria-label="rvcv"
                        // defaultValue={rvcvTypes}
                        name="radio-buttons-group"
                        className="rvcvRadiobuttonChecked rvcvMultipleradio"
                        //   value={rvcvTypes}
                        row
                      >
                        <FormControlLabel
                          name="value1X"
                          control={
                            <Radio
                              checked={rvcvBanner.fileTypes.value1X}
                              onChange={(e) => {
                                handleChangeRvcvfiletype(e, indexrvcvBanner);
                              }}
                            />
                          }
                          label="1X"
                        />
                        <FormControlLabel
                          name="value2X"
                          control={
                            <Radio
                              checked={rvcvBanner.fileTypes.value2X}
                              onChange={(e) => {
                                handleChangeRvcvfiletype(e, indexrvcvBanner);
                              }}
                            />
                          }
                          label="2X"
                        />
                        <FormControlLabel
                          name="value3X"
                          control={
                            <Radio
                              checked={rvcvBanner.fileTypes.value3X}
                              onChange={(e) => {
                                handleChangeRvcvfiletype(e, indexrvcvBanner);
                              }}
                            />
                          }
                          label="3X"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions className="modalActions">
          <Button
            onClick={() => {
              props.setOpenRVCvmodal(false);
            }}
            color="primary"
            className="cancelBtn"
          >
            {t("bannerManager.cancel")}
          </Button>
          {type &&
            rvcvBannerList.map((rvcvBannerList) => {
              return <ExportCenterContainer></ExportCenterContainer>;
            })}
          <Button
            onClick={generateRVCV}
            type="submit"
            className="btn btn-primary"
            //disabled={!validateForm()}
          >
            {t("bannerManager.generateRVFile")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
