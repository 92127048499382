import React, { useState, useEffect } from "react";
import { InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "../styles/pages/TemplateManager.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CheckIcon from "@material-ui/icons/Check";
//import { Tooltip } from "bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
const iconComponent = (props) => {
  return <ExpandMoreIcon className={props.className} />;
};

const DropdownWithLabelTemplate = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(props.options);
  }, [props]);

  const handleDisable = (optionName) => {
    // const disabled = (props.tick?.length >0 && props.tick?.some( animation=> animation !== undefined && animation.animationType === options.value  ) && !animationCombination[options.value]?.includes(item) )? true: false;
    // setDisabled(prevState=>{ prevState === true ? true :disabled});
  };

  const getValue = () => {
    if (
      options &&
      options.items &&
      options.items.length > 0 &&
      options.value &&
      options.items.indexOf(options.value) !== -1
    ) {
      return options.value;
    }
    return "";
  };

  return (
    <FormControl
      // className="drop-down-with-label"
      className="tempInputField "
      variant="standard"
      fullWidth={true}
    >
      <InputLabel
        htmlFor={options.label}
        id="demo-simple-select-standard-label"
      >
        {options.label}
      </InputLabel>
      {options.name === "campaign" ? (
        <Tooltip
          title={options?.value?.length >= 10 ? getValue() : ""}
          placement="top"
        >
          <Select
            labelId="demo-simple-select-standard-label "
            //id="demo-simple-select-standard"
            value={getValue()}
            onOpen={options.onOpen}
            //openText="Open232"
            onChange={options.handleChange}
            disabled={options.disabled}
            label={options.label}
            name={options.name}
            id={options.name}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              PaperProps: {
                style: {
                  maxWidth: props.menuWidth,
                },
              },
              getContentAnchorEl: null,
            }}
            IconComponent={iconComponent}
          >
            {options.items &&
              options.items.length > 0 &&
              options.items.map((item, index) => {
                {
                  /* const disabled = (props.tick?.length >0 && props.tick?.some( animation=> animation !== undefined && animation.animationType === options.value  ) && !animationCombination[options.value]?.includes(item) )? true: false; */
                }
                return (
                  <MenuItem value={item} id={item} key={item}>
                    {/*   <div className="menuItemAnimation"> */}
                    {item}{" "}
                    {props.tick?.some(
                      (animation) =>
                        animation !== undefined &&
                        animation.animationType === item
                    ) ? (
                      <CheckIcon style={{ color: "#00b10a", fontSize: 15 }} />
                    ) : (
                      ""
                    )}
                    {/*   </div> */}
                  </MenuItem>
                );
              })}
          </Select>
        </Tooltip>
      ) : (
        <Select
          labelId="demo-simple-select-standard-label "
          //id="demo-simple-select-standard"
          value={getValue()}
          onOpen={options.onOpen}
          onChange={options.handleChange}
          disabled={options.disabled}
          label={options.label}
          name={options.name}
          id={options.name}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxWidth: props.menuWidth,
              },
            },
            getContentAnchorEl: null,
          }}
          IconComponent={iconComponent}
        >
          {options.items &&
            options.items.length > 0 &&
            options.items.map((item, index) => {
              {
                /* const disabled = (props.tick?.length >0 && props.tick?.some( animation=> animation !== undefined && animation.animationType === options.value  ) && !animationCombination[options.value]?.includes(item) )? true: false; */
              }
              return (
                <MenuItem
                  value={item}
                  id={item}
                  key={item}
                  className={
                    item === "— Not Brand Specific —" ? "notbranded" : ""
                  }
                >
                  {/*   <div className="menuItemAnimation"> */}
                  {item}{" "}
                  {props.tick?.some(
                    (animation) =>
                      animation !== undefined &&
                      animation.animationType === item
                  ) ? (
                    <CheckIcon style={{ color: "#00b10a", fontSize: 15 }} />
                  ) : (
                    ""
                  )}
                  {/*   </div> */}
                </MenuItem>
              );
            })}
        </Select>
      )}
    </FormControl>
  );
};

export default DropdownWithLabelTemplate;
