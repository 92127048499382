export const fontFamilyItems = [
  {
    fontFamilyItem: "Nunito Sans",
  },
  {
    fontFamilyItem: "Georgia",
  },

  {
    fontFamilyItem: "Tahoma",
  },
  {
    fontFamilyItem: "Times New Roman",
  },
  {
    fontFamilyItem: "Verdana",
  },
];

export const fontWeightItems = [
  {
    fontWeightItem: "Normal",
  },
  {
    fontWeightItem: "Bold",
  },
];
export const fontSizeItems = [
  {
    fontSizeItem: 9,
  },
  {
    fontSizeItem: 10,
  },
  {
    fontSizeItem: 12,
  },
  {
    fontSizeItem: 14,
  },
  {
    fontSizeItem: 18,
  },
  {
    fontSizeItem: 24,
  },
  {
    fontSizeItem: 29,
  },
  {
    fontSizeItem: 32,
  },
  {
    fontSizeItem: 50,
  },
];
export const letterSpacingItems = [
  {
    letterSpacingItem: 0.2,
  },
  {
    letterSpacingItem: 1,
  },
  {
    letterSpacingItem: 2,
  },
  {
    letterSpacingItem: 3,
  },
  {
    letterSpacingItem: 4,
  },
];
export const lineHeightItems = [
  {
    lineHeightItem: 1,
  },
  {
    lineHeightItem: 1.25,
  },
  {
    lineHeightItem: 2,
  },
  {
    lineHeightItem: 3,
  },
  {
    lineHeightItem: 4,
  },
  {
    lineHeightItem: 5,
  },
];
export const borderStyles = [
  "Solid",
  "Dotted",
  "Dashed",
  "Double",
  "Groove",
  "Ridge",
  "Inset",
  "Outset",
  "None",
  "Hidden",
];
export const bannerSizes = [
  "Button 120x60",
  "Banner 468x60",
  "Banner 728x90 (Leaderboard)",
  "Leaderboard 768x90 (Tablet)",
  "Leaderboard 320x50 (Mobile)",
  "MPU 250x250",
  "MPU 300x250",
  "MPU 180x150",
  "Skyscraper 160x600 (Wide)",
  "Skyscraper 300x600",
];
export const templateSizes = [
  "Button 120x60",
  "Banner 468x60",
  "Banner 728x90 (Leaderboard)",
  "Leaderboard 768x90 (Tablet)",
  "Leaderboard 320x50 (Mobile)",
  "MPU 250x250",
  "MPU 300x250",
  "MPU 180x150",
  "Skyscraper 160x600 (Wide)",
  "Skyscraper 300x600",
];
