import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Divider,
  Button,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import searchIcon from "../../images/search.svg";
import { NoTemplateBannerFoundThumbnail } from "../../images/NobannerOrTemplateFoundBg.png";

import { FormStyle } from "../../styles/pages/ContentStudio/WithStylesObjs";
import "../../styles/pages/ContentStudio/DynDialogue.scss";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import DropDownWithLabel from "../../components/DropDownWithLabel.js";
import {
  getBrandsAndCampaigns,
  getNewBrandsAndcampaigns,
  searchBanners,
  getRecentlyViewedBannerCards,
  getCognitoSession,
  getCognitoIndentityCredentials,
} from "../../services/apis";
import DropDownWithSearch from "../../Uitls/DropDownWithSearch";
import { toast } from "react-toastify";
import ValueContext from "../../ContextAPi/appContext";
import { isoCountryTableName, awsRegion } from "../../Uitls/Util";
import errComponentToRender from "../../Uitls/apiErrorHandling";
// import { InputAdornment } from "@material-ui/core";

import GotoRecentlyViewed from "../../components/BannerManager/gotoRecentlyViewed";
import MultipleRvcvModal from "../../components/BannerManager/MultipleRvcvModal";
import backArrowIcon from "../../images/backArrowico.svg";

var dynamo = require("dynamodb");

// banner data goes here
function BannerForms(props) {
  const initialFvalues = {
    brand: "",
    campaign: "",
    market: "",
    bannerNameInput: "",
    canReset: false,
  };

  const appData = useContext(ValueContext);
  const { t } = useTranslation();
  const { classes } = props;
  const [values, setValues] = useState(initialFvalues);
  // const [errorMsg, setErrorMsg] = useState("");
  const [recentlyViewed, setgetRecentlyViewed] = useState([]);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [selectMultipleBanner, setSelectMultipleBanner] = useState([]);
  const [hide3dotIcon, setHide3dotIcon] = useState(true);

  const [genericInfo, setGenericInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
  });
  const [dropdownInfo, setDropdownInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
    // bannerNameInput:''
  });

  const [loading, setLoading] = useState(false);
  const [resultHeader, setResultHeader] = useState({
    cardsCount: 0,
    searched: false,
    // resultTitle: (function(){  var count = this.cardsCount;
    //   return `Recently Viewed(${count})`})()
    resultTitle: `Recently Viewed (0)`,
  });
  const [responseData, setResponseData] = useState([]);
  const [search, setSearch] = useState("recent");
  const [bannerCount, setBannerCount] = useState(0);
  const [generateRVCV, setGenerateRVCV] = useState(false);
  const [disableProceedbut, setDisableProceedbut] = useState(false);
  const [openRVCvmodal, setOpenRVCvmodal] = useState(false);

  const [arrcheckboxSelection, setArrcheckboxSelection] = useState([]);
  React.useEffect(() => {
    setGenericInfo({ ...genericInfo, campaign: "" });
  }, [genericInfo.brand]);

  React.useEffect(() => {
    getRecentlyViewedBanner();
    appData.setFlagEditFlow(false);
    appData.setFlagEditBanner(false);
    fetchBrandsAndCampaigns();
    fetchMarketData();
  }, []);

  const generateRvfile = () => {
    setGenerateRVCV(true);
    setHide3dotIcon(false);
    let filterAnimatedBanner = [...responseData];
    let filterBanners = filterAnimatedBanner.filter(
      (element) => element.isAnimated === true
    );
    setResponseData(filterBanners);
  };

  const handleChangeField = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
      canReset: true,
    });
    // setBannerNameInput(event.target.value);
  };

  const handleReset = () => {
    setHide3dotIcon(true);
    setValues({
      ...initialFvalues,
    });
    setGenericInfo({
      brand: "",
      campaign: "",
      market: "",
    }); //now
    setSelectedMarket(""); //now
    // setBannerNameInput("");
    setDropdownInfo({ brand: "", campaign: "", market: "" });
    setResponseData([]);
    setSearch("recent");
    // setgetRecentlyViewed([]);
    setResultHeader({
      cardsCount: bannerCount,
      searched: false,
      resultTitle: `Recently Viewed (${bannerCount})`,
    });
    getRecentlyViewedBanner();
    // setDisableProceedbut(false);
    setGenerateRVCV(false);
  };
  // React.useEffect(() => {
  //   fetchBrandsAndCampaigns();
  //   fetchMarketData();
  // }, []);

  const fetchBrandsAndCampaigns = () => {
    //appData.banner;
    getNewBrandsAndcampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        toast.error(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          setBrandsAndCampaigns(data);
        }
      }
    });
  };

  const getBrandsToPopulate = () => {
    if (!brandsAndCampaigns || brandsAndCampaigns.length <= 0) {
      return [];
    }
    return [...brandsAndCampaigns].map((object) => {
      return object.brandName;
    });
  };
  //Returns Campaign list to populate in Drop down
  const getCampaignListToPopulate = (Brand) => {
    if (Brand) {
      const campaigns = brandsAndCampaigns.filter((object) => {
        return object.brandName === Brand;
      })[0].campaigns;

      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }
    return [];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      canReset: true,
    });

    setGenericInfo({ ...genericInfo, [name]: value });
    if (brandsAndCampaigns) {
      brandsAndCampaigns.map((brand) => {
        if (brand.brandName === value) {
          // appData.setBrandId(brand.brandId);
          setDropdownInfo({ ...dropdownInfo, brand: brand.brandId });
        }
        brand.campaigns.map((campaign) => {
          if (campaign.campaignName === value)
            // appData.setCampaignId(campaign.campaignId);
            setDropdownInfo({ ...dropdownInfo, campaign: campaign.campaignId });
        });
      });
    }
  };
  // const fetchMarketData = () => {
  //   updateDynamicDBConfig(dynamo);
  //   var params = {
  //     TableName: isoCountryTableName,
  //   };
  //   getDyanamoDBInstance(dynamo).scan(params, (error, data) => {
  //     if (data) {
  //       let marketData = data.Items;
  //       if (marketData && marketData.length > 0) {
  //         marketData = [...marketData].map((market) => {
  //           let object = {
  //             code: market.countryCode["S"], //Object.values(market.countryCode)[0],
  //             shortName: market.ShortName["S"], //market.ShortName)[0],
  //             name: market.Name["S"],
  //           };
  //           object["displayName"] = `${object.code} - ${object.name}`;
  //           return object;
  //         });
  //         if (marketData && marketData.length > 0) {
  //           const sortCountryCode = marketData.sort(function (a, b) {
  //             if (a.code.toLowerCase() < b.code.toLowerCase()) {
  //               return -1;
  //             }
  //             if (a.code.toLowerCase() > b.code.toLowerCase()) {
  //               return 1;
  //             }
  //             return 0;
  //           });
  //           setMarketDataSource(sortCountryCode);
  //         }
  //       }
  //     }
  //   });
  // };

  const fetchMarketData = () => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setMarketDataSource(marketData);
                }
              }
            }
          });
        });
      }
    });
  };
  const handleMarketChange = (market) => {
    // const { name, value } = event.target;
    if (market) {
      setSelectedMarket(market);
      setValues({
        ...values,
        market: market.code,
        canReset: true,
      });
      // setGenericInfo({ ...genericInfo, 'market': market.code })
      setDropdownInfo({ ...dropdownInfo, market: market.code });
    }
  };
  const categoriesErr = (value) => {
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  };
  const getSearchedBanners = (e) => {
    // e.stopPropagation();
    if (
      values.brand !== "" ||
      values.campaign !== "" ||
      values.market !== "" ||
      (values.bannerNameInput !== "" && values.bannerNameInput.trim() !== "")
    ) {
      const options = {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "en-US,en;q=0.9",
          "Access-Control-Allow-Origin": "*",
        },
      };
      setResponseData([]);
      //  setLoading(true);
      setResultHeader({
        cardsCount: 0,
        searched: true,
        resultTitle: `Search Results...`,
      });
      searchBanners(
        "BANNER",
        dropdownInfo,
        encodeURIComponent(values.bannerNameInput),
        async (response, err, errorCode) => {
          const data = await response.data;
          // setLoading(false);
          if (err) {
            const errCoponent = errComponentToRender(errorCode);
            categoriesErr(errCoponent);
          } else {
            setResponseData(data);
            setSearch("search");
            // appData.setCardData({
            //   cards:data,
            //   search:"search"
            // }
            // );
            // setgetRecentlyViewed([]);
            setResultHeader({
              cardsCount: response.data.length,
              searched: true,
              resultTitle: `Search Results (${response.data.length})`,
            });
          }
        }
      );
    } else {
      const errCoponent = errComponentToRender(602);
      categoriesErr(errCoponent);
    }
  };

  const getRecentlyViewedBanner = () => {
    // setLoading(true);
    getRecentlyViewedBannerCards("Banner", (response, err, errCode) => {
      //  setLoading(false);
      if (err) {
        const errCoponent = errComponentToRender(errCode);
      } else {
        //parse and save
        const data = response.data;
        console.log("data+++++", data);
        setBannerCount(response.data.length);
        // for (let i = 0; i <= data.length; i++) {
        //   props.setIsAnimatedBanner(data[i].isAnimated);
        // }
        setResultHeader({
          cardsCount: response.data.length,
          searched: false,
          resultTitle: `Recently Viewed (${response.data.length})`,
        });
        if (data && data.length > 0) {
          setgetRecentlyViewed(data);
          setSearch("recent");
        } else {
          setgetRecentlyViewed([]);
          setSearch("recent");
        }
      }
    });
  };
  const handleEnterKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.key === "Enter") {
      getSearchedBanners();
    }
  };
  const removeSearchresultCard = (deleteId) => {
    let filteredSearchResults = responseData.filter(
      (cardId) => cardId.id !== deleteId
    );
    setResponseData(filteredSearchResults);
    setResultHeader({
      cardsCount: filteredSearchResults.length,
      searched: true,
      resultTitle: `Search Results (${filteredSearchResults.length})`,
    });
  };

  const openGenerateRVFileModal = (e) => {
    e.preventDefault();
    setOpenRVCvmodal(true);
  };

  const getDatafromChildforRVCV = (e) => {};
  const backArrowclick = (e) => {
    setHide3dotIcon(true);
    setGenerateRVCV(false);
    getSearchedBanners();
  };

  return (
    <>
      <form className="bannerForms">
        <div className="container-fluid">
          <div className="row mb-4 bannerSearch">
            <div className="searchCol align-items-center">
              <Row>
                <Col className="col-md-12 banner-nameCol">
                  <div className="d-flex banner-searchRow">
                    <img
                      src={searchIcon}
                      alt=""
                      className="searchIcon"
                      onClick={getSearchedBanners}
                    />
                    <input
                      value={values.bannerNameInput}
                      id="global-search-module"
                      type="text"
                      name="bannerNameInput"
                      placeholder={t("bannerManager.enterBannerName")}
                      className="globalSearchInputNav"
                      onChange={handleChangeField}
                      autoComplete="off"
                      onKeyPress={handleEnterKeypress}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="row align-items-center mb-4 dropwdownRow">
            <div className="col-md-3 brandField">
              <DropDownWithLabel
                options={{
                  value: genericInfo.brand,
                  name: "brand",
                  label: t("bannerManager.brandName"),
                  items: getBrandsToPopulate(),
                  handleChange: handleChange,
                }}
                style={{ marginTop: "10px" }}
                className="custonScrollBar"
                id="branfieldBanner"
              />
            </div>
            <div className="col-md-3 marketField">
              <DropDownWithSearch
                options={{
                  value: selectedMarket,
                  name: "market",
                  label: t("bannerManager.market"),
                  items: marketDataSource,
                  handleChange: handleMarketChange,
                }}
                className="custonScrollBar"
              />
            </div>
            <div className="col-md-4 campaignField">
              <DropDownWithLabel
                options={{
                  value: genericInfo.campaign || "",
                  name: "campaign",
                  label: t("bannerManager.campaign"),
                  items: getCampaignListToPopulate(genericInfo.brand),
                  handleChange: handleChange,
                }}
              />
            </div>
            <div className="col-md-1 searchAndReset">
              <Button onClick={getSearchedBanners} className="searchBtn">
                {t("bannerForms.search")}
              </Button>
            </div>
            <div className="col-md-1  resetBtn">
              <button
                type="button"
                className={`btn btn-link ${
                  values.canReset ? classes.ResetKey : classes.Unabled
                }`}
                onClick={values.canReset ? handleReset : undefined}
              >
                {t("bannerForms.reset")}
              </button>
            </div>
            <div className="bannerDivider">
              <Divider />
            </div>
          </div>
          <div className="row generateRVCVrow">
            <div className="col-md-10 recentlyViewText">
              {generateRVCV ? (
                <div>
                  {responseData.length > 0 && disableProceedbut ? (
                    <div>
                      <img
                        src={backArrowIcon}
                        alt="Arrow"
                        className="img-fluid backArrImage"
                        onClick={(e) => backArrowclick(e)}
                      />
                      <span style={{ paddingLeft: "11px" }}>
                        {t("Select Banners to Generate RV File")}
                      </span>
                    </div>
                  ) : (
                    t("Select Banners to Generate RV File")
                  )}
                </div>
              ) : (
                resultHeader.resultTitle
              )}
            </div>
            {search === "search" && (
              <div className="col-md-2 rvcvfileDiv">
                {resultHeader.cardsCount > 0 && generateRVCV ? (
                  <div className="resetBtn">
                    <button
                      onClick={openGenerateRVFileModal}
                      className="btn btn-link"
                      disabled={!disableProceedbut}
                    >
                      {t("Proceed")}
                    </button>
                  </div>
                ) : resultHeader.cardsCount > 0 ? (
                  <div onClick={generateRvfile}>{t("Generate RV File")}</div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          <div className="recentlyViewDiv">
            <div className="row recentlyCardsRow recentlyVeiwedcrollBar ">
              {responseData.length === 0 && search === "search" ? (
                <div className="searchEmpty">
                  <div className="noBannerFound">
                    {t("bannerForms.nobannerFound")}
                  </div>
                  <div className="tryKeyword">
                    {t("bannerForms.tryusingKeyword")}
                  </div>
                </div>
              ) : (
                <GotoRecentlyViewed
                  responseData={responseData}
                  search={search}
                  getRecentlyViewedBanner={getRecentlyViewedBanner}
                  removeSearchresultCard={removeSearchresultCard}
                  setLoading={setLoading}
                  generateRVCV={generateRVCV}
                  disableProceedbut={disableProceedbut}
                  setDisableProceedbut={setDisableProceedbut}
                  selectMultipleBanner={selectMultipleBanner}
                  setSelectMultipleBanner={setSelectMultipleBanner}
                  arrcheckboxSelection={arrcheckboxSelection}
                  setArrcheckboxSelection={setArrcheckboxSelection}
                  hide3dotIcon={hide3dotIcon}
                />
              )}
              {recentlyViewed.length === 0 && search === "recent" ? (
                <div className="searchEmpty ">
                  <div className="noBannerFound">
                    {t("bannerForms.nobannerFound")}
                  </div>
                  <div className="tryKeyword">
                    {t("bannerForms.tryusingKeyword")}
                  </div>
                </div>
              ) : (
                search === "recent" && (
                  <GotoRecentlyViewed
                    responseData={recentlyViewed}
                    setLoading={setLoading}
                    search={search}
                    getRecentlyViewedBanner={getRecentlyViewedBanner}
                    generateRVCV={generateRVCV}
                    disableProceedbut={disableProceedbut}
                    setDisableProceedbut={setDisableProceedbut}
                    selectMultipleBanner={selectMultipleBanner}
                    setSelectMultipleBanner={setSelectMultipleBanner}
                    arrcheckboxSelection={arrcheckboxSelection}
                    setArrcheckboxSelection={setArrcheckboxSelection}
                    hide3dotIcon={hide3dotIcon}
                  />
                )
              )}
            </div>
          </div>
        </div>
        {loading && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress size="30px" className="customLoader" />{" "}
          </>
        )}
      </form>
      {openRVCvmodal && (
        <MultipleRvcvModal
          openRVCvmodal={openRVCvmodal}
          setOpenRVCvmodal={setOpenRVCvmodal}
          selectMultipleBanner={selectMultipleBanner}
        />
      )}
    </>
  );
}

export default withStyles(FormStyle)(BannerForms);
