import React from "react";

import "../../styles/components/ToolBarLeftPanel.scss";
import { useTranslation } from "react-i18next";

import ClearText from "../../images/ClearText.svg";

import warning from "../../images/warning.svg";

import {
  RECTANGLE,
  CIRCLE,
  TRIANGLE,
  POLYGON,
  HEXAGON,
} from "../../Uitls/ComponentType";
import Hexagon from "../../images/Shapes/Hexagon.svg";
import Polygon from "../../images/Shapes/Polygon.svg";
import Circle from "../../images/Shapes/Circle.svg";
import Triangle from "../../images/Shapes/Triangle.svg";
import Rectangle from "../../images/Shapes/Rectangle.svg";

const ShapesWindow = (props) => {
  const { handleCloseElement, getLayerDetails } = props;
  const { t } = useTranslation();

  const shapesDetails = [
    { name: "Rectangle", id: RECTANGLE, image: Rectangle },
    { name: "Circle", id: CIRCLE, image: Circle },
    { name: "Triangle", id: TRIANGLE, image: Triangle },
    { name: "Polygon", id: POLYGON, image: Polygon },
    { name: "Hexagon", id: HEXAGON, image: Hexagon },
  ];

  return (
    <div className="parentContainerShapes">
      <div className="shapesContainer">
        <div className="headingrow">
          <div>
            <span>{t("SHAPE")}</span>
            {/* <img alt="" src={warning} className="img-fluid warningIcon" /> */}
          </div>
          <img
            src={ClearText}
            alt=""
            className="clearTextIcon clearText"
            onClick={() => handleCloseElement("Shapes")}
          />
        </div>
        <div className="shapes">
          {shapesDetails.map((shape) => (
            <div className="shapesHolder">
              <div
                className={`imageBckrnd ${
                  shape.name === "Hexagon"
                    ? "Hexagon"
                    : shape.name === "Polygon"
                    ? "Polygon"
                    : shape.name === "Circle"
                    ? "Circle"
                    : ""
                }`}
              >
                <img
                  src={shape.image}
                  alt={shape.name}
                  className="img-fluid"
                  draggable={true}
                  onDragStart={(e) => {
                    getLayerDetails(e.target.id, shape.id);
                  }}
                />
              </div>

              <span className="ShapestExt">{shape.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShapesWindow;
