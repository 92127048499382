import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { getSession } from "./index";

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  const [isUserAuthentic, SetIsUserAuthentic] = useState(false);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    getSession()
      .then((sesion) => {
        if (mounted) {
          SetIsUserAuthentic(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (mounted) {
          SetIsUserAuthentic(false);
          setIsLoading(false);
        }
      });

    return () => (mounted = false);
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isUserAuthentic && !isloading) {
          return <Component {...props} />;
        } else if (!isUserAuthentic && !isloading) {
          return <Redirect to={{ pathname: "/" }} />;
        }
      }}
    />
  );
}

export default PrivateRoute;
