import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
} from "react";
import { DebounceInput } from "react-debounce-input";
import InfoIcon from "../../images/iconinfo.svg";
import DownwardArrow from "../../images/DownwardArrow.svg";
import SideArrow from "../../images/SideArrow.svg";
import axios from "axios";
import WebFont from "webfontloader";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ColorPicker from "../ColorPicker";
import reactCSS from "reactcss";
import { useTranslation } from "react-i18next";
import "../../styles/components/RightPanel.scss";
import Switch from "@material-ui/core/Switch";
//import toggleIcon from "../../images/toggleIcon.svg";
import ImageUpload from "../../images/ImageUpload.png";
import { TextField } from "@material-ui/core";
import AV from "../../images/EditorAnimation/AV.svg";
import LineHeight from "../../images/EditorAnimation/LineHeight.svg";
import {
  useDisplaycolorpicker,
  useDisplaycolorpickerBorder,
  useDisplaycolorpickerFontColor,
  useDisplaycolorpickerBgColor,
  useDisplaycolorpickerScrollColor,
  useDisplaycolorpickerTextBg,
  useColorpickerFontColorISI,
  useColorpickerTextBgColorISI,
} from "./DisplaycolorPicker.js";
import { browseImages } from "../../services/apis";
import { isValidHttpUrl, checkURL } from "../../Uitls/Util";
//import { SettingsApplications } from "@material-ui/icons";
import {
  // fontFamilyItems,
  // fontWeightItems,
  fontSizeItems,
  letterSpacingItems,
  lineHeightItems,
  borderStyles,
  bannerSizes,
} from "../../Uitls/RightPanelToolBarItems";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import GlobalSetting from "./GlobalSetting";
import Transform from "./Transform.js";
import ScrollBarArrow from "./ScrollBar.js";
import TextAndButton from "./ButtonSettings";
import ButtonAndShape from "./ButtonAndShape";
import ImageComp from "./ImageSettings";
import ImageBorder from "./ImageBorder";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@material-ui/icons/Remove";
import ValueContext from "../../ContextAPi/appContext";
import InfotabSection from "../../components/InfoTabSection";
import { toast } from "react-toastify";
import { AspectRatio, KeyboardReturnRounded } from "@material-ui/icons";
import { validateYupSchema } from "formik";

import { getValidInput } from "../../Uitls/Util";

const AntTabs = withStyles({
  root: {},
  indicator: {
    display: "none",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const RightPanel = ({
  isAspectRatioLocked,
  setIsAspectRatioLocked,
  handleCompAspectRatio,
  bannerId,
  isInfoPanel,
  myCanvasRef,
  dimensionsData,
  selectedComponent,
  setSelectedComponent,
  layerCompData,
  setLayerCompData,
  layerIndex,
  createFroalaInstance,
  froalaFontAlignments,
  froalaNumberList,
  froalaFontStyles,
  froalaIndex,
  imageOnCanvas,
  layerId,
  canvasDimension,
  setCanvasDimension,
  setFroalatoolbarTag,
  showAssettype,

  //backgroundColor,
  //setBackgroundColor,
  checkedAspect,
  // ToggleAspectLock,
  expanded,
  setExpanded,
  handleChange,
  toggleScrollbar,
  setCheckedScroll,
  checkedScroll,
  renderTheFontLink,
  showBannertype,
  toggleAnimatedBanner,
  setSelectedPreviousId,
  getEditSaveAssets,
  setShowBannerName,
  setundoFlag,
  canvasUpdated,
  setCanvasUpdated,
  previousCanvasDimension,
  setPreviousCanvasDimension,
  bgColorCanvas,
  setBgColorCanvas,
  canvasBgChange,
  setCanvasBgChange,
  setOpenTimelinePopup,
  scrollBarExpand,
  genericInfo,
  setGenericInfo,
  playPause,
  setClickedTimes,
  updateSelctedFontstyle,
  selectedFontId,
  setSelectedFontId,
  damsID,
  setMLRstatus,
  MLRstatus,
  multiSelectedComps,
  setMultiSelectedComps,
  multiSelect,
  setMultiSelect,
  setUpdatedValue,
  updatedValue,
  updateMultiCompProperties,
}) => {
  const [fontWeightSelected, setFontWeightSelected] = useState("");
  const [fontFamilyItems, setFontFamilyItems] = useState([
    "Arial",
    "Georgia",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ]);
  const [fontWeightItems, setFontWeightItems] = useState([]);

  const appData = useContext(ValueContext);
  // let locked = selectedComponent?.componentStyles?.lockBoolean;
  let locked = false;
  if (
    selectedComponent?.componentType !== undefined &&
    layerCompData?.length > 0 &&
    selectedComponent?.componentType !== "default"
  )
    locked =
      layerCompData[
        layerCompData?.findIndex(
          (selComp) => selComp?.id === selectedComponent?.id
        )
      ]?.componentStyles?.lockBoolean;
  let hideFlag =
    layerCompData[
      layerCompData?.findIndex(
        (selComp) => selComp?.id === selectedComponent?.id
      )
    ]?.componentStyles?.hideBoolean;
  const defaultTabValue =
    window.localStorage.getItem("rightPanelTabValue") || 0;
  const [propertyTabValue, setPropertyTabValue] = useState(
    parseInt(defaultTabValue)
  );

  const handlePropertyTab = (event, newValue) => {
    setPropertyTabValue(newValue);
  };

  const { t } = useTranslation();

  // const [updatedValue, setUpdatedValue] = useState({
  //   componentStyles: { height: null, width: null, top: null, left: null },
  // });
  const [checkBoxRepeat, setCheckBoxRepeat] = useState(undefined);
  const [lineHeightProp, setLineHeightProp] = useState("");
  const [fontSize, setFontSize] = useState("");
  const [fontFamily, setFontFamily] = useState("Nunito Sans");
  const [fontWeight, setFontWeight] = useState("");
  const [characterSpacing, setCharacterSpacing] = useState("");
  const [borderWidth, setBorderWidth] = useState(1);
  const [borderStyle, setBorderStyle] = useState("solid");
  const [targetLink, setTargetLink] = useState("https://");
  const [cornerCount, setCornerCount] = useState("");

  const [scrollbarArrow, setScrollbarArrow] = useState({
    thumbWidth: 0,
    thumbHeight: 0,
    thumbColor: "#000000",
    thumbRadius: 2,
    trackWidth: 0,
    trackHeight: 0,
    trackColor: "#000000",
    trackRadius: 2,
    arrowWidth: 0,
    arrowHeight: 0,
  });
  const [scrollDimensionData, setScrollDimensionData] = useState({
    dimensions: {
      dimension: ["Width", "Height"],
      track: ["Width"],
      width: "true",
      height: "true",
      color: "true",
      radius: "true",
    },
  });
  const convertRgbaStrToObj = (val) => {
    let obj = { r: "", g: "", b: "", a: "" };
    let newVal = !val.startsWith("rgb") ? val[2] : val;
    // if (!val.startsWith("rgb"))

    val = newVal
      .substr(
        newVal.indexOf("(") + 1,
        newVal.indexOf(")") - newVal.indexOf("(") - 1
      )
      .split(",");
    obj.r = val[0].replace(/\D/g, "");
    obj.g = val[1].replace(/\D/g, "");
    obj.b = val[2].replace(/\D/g, "");
    obj.a = val[3].replace(/\D/g, "");
    return obj;
  };
  function convertHex(hexCode, opacity = 1) {
    if (!hexCode) return;
    var hex = hexCode.replace("#", "");

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    var r = parseInt(hex.substring(0, 2), 16),
      g = parseInt(hex.substring(2, 4), 16),
      b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;
    }

    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  }

  useEffect(() => {
    // if (document.getElementById("fontSizeIp"))
    //   document.getElementById("fontSizeIp").focus();
    // console.log("id isi", id);
    let defaultValues = selectedComponent?.componentStyles;

    setCharacterSpacing(defaultValues?.letterSpacing);
    // setFontSize(defaultValues?.fontSize);
    setFontSize(defaultValues?.datafontSize);
    setFontFamily(defaultValues?.fontFamily);
    setFontWeight(defaultValues?.fontWeight);
    setLineHeightProp(defaultValues?.lineHeight);
    let rgbColor1 = defaultValues?.color?.includes("rgb")
      ? defaultValues?.color
      : convertHex(defaultValues?.color, 100);
    setTextColor(rgbColor1);

    let rgbColor = defaultValues?.backgroundColor?.includes("rgb")
      ? defaultValues?.backgroundColor
      : convertHex(defaultValues?.backgroundColor, 100);
    setButtonColor(rgbColor);
    let rgbTextBgColor = defaultValues?.textBgColor?.includes("rgb")
      ? defaultValues?.textBgColor
      : convertHex(defaultValues?.textBgColor, 100);
    setTextBgColor(rgbTextBgColor);
    let rgbScrollColor = defaultValues?.thumbColor?.includes("rgb")
      ? defaultValues?.thumbColor
      : convertHex(defaultValues?.thumbColor, 100);
    setScrollColor(rgbScrollColor);

    let rgbFontColorISI = defaultValues?.expandClose?.fontColorISI?.includes(
      "rgb"
    )
      ? defaultValues?.expandClose?.fontColorISI
      : convertHex(defaultValues?.expandClose?.fontColorISI, 100);
    setFontColorISI(rgbFontColorISI);

    let rgbTextBgColorISI =
      defaultValues?.expandClose?.textBgColorISI?.includes("rgb")
        ? defaultValues?.expandClose?.textBgColorISI
        : convertHex(defaultValues?.expandClose?.textBgColorISI, 100);
    setTextBgColorISI(rgbTextBgColorISI);
    if (defaultValues?.border) {
      let borderValue = defaultValues?.border?.split(" ");
      let borderColorValue = defaultValues?.border?.includes("rgb")
        ? defaultValues?.border?.substr(defaultValues?.border?.indexOf("rgb"))
        : convertHex(borderValue[2], 100);
      let rgbColor2 =
        borderColorValue.startsWith("rgb") &&
        typeof borderColorValue === "string"
          ? convertRgbaStrToObj(borderColorValue)
          : borderColorValue;

      setBorderColor(rgbColor2);
      let borderValueForPolygon = borderValue[1].substring(
        0,
        borderValue[1].indexOf("p")
      );
      let borderValueForComp = 0;

      if (selectedComponent?.textComponentType) {
        borderValueForComp =
          selectedComponent?.textComponentType?.addContent === "polygonShape" ||
          selectedComponent?.textComponentType?.addContent === "hexagonShape"
            ? borderValueForPolygon
            : borderValue[1].match(/\d+/);
      } else {
        borderValue[1].match(/\d+/);
      }

      setBorderWidth(borderValueForComp);
      setBorderStyle(borderValue[0]);
      setRadiusTRBL({
        borderTopLeftRadius: defaultValues.borderTopLeftRadius,
        borderTopRightRadius: defaultValues.borderTopRightRadius,
        borderBottomLeftRadius: defaultValues.borderBottomLeftRadius,
        borderBottomRightRadius: defaultValues.borderBottomRightRadius,
      });
      setPaddingTRBL({
        paddingTop: defaultValues.paddingTop,
        paddingRight: defaultValues.paddingRight,
        paddingBottom: defaultValues.paddingBottom,
        paddingLeft: defaultValues.paddingLeft,
      });
    }

    setURLValue("");
    setTargetLink(defaultValues?.targetLink);
    setCheckBoxRepeat(defaultValues?.repeat);
    if (
      (selectedComponent?.componentType === "Button" ||
        selectedComponent?.componentType === "Text") &&
      createFroalaInstance?.current.length > 0
    ) {
      if (
        createFroalaInstance?.current[0] &&
        !createFroalaInstance?.current[0]?.editorInitialized
      ) {
        if (document.getElementById(selectedComponent.id)) {
          document.getElementById(selectedComponent.id).click();
        }
      }
    }
  }, [selectedComponent?.componentStyles, JSON.stringify(layerCompData)]);

  useEffect(() => {
    if (layerId.type === "Image") {
      setURLValue("");
      setTargetLink("https://");
    }

    setCheckBoxRepeat(false);
  }, [layerId.type]);

  // useEffect(() => {
  //   console.log("fontFamily=====>", fontFamily);
  //   WebFont.load({
  //     google: {
  //       families: [
  //         `${fontFamily}:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=block`,
  //       ],
  //     },
  //   });
  // }, [fontFamily]);
  useEffect(() => {
    let rgbBgColor = canvasDimension?.backgroundColor?.includes("rgb")
      ? canvasDimension?.backgroundColor
      : convertHex(canvasDimension?.backgroundColor, 100);
    setBgColorCanvas(rgbBgColor);
    setCanvasDimension({ ...canvasDimension, backgroundColor: rgbBgColor });
    // appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
    //api call to get the entire ggogle font family list
    axios
      .get(
        "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCN_tBjV5pdxM0hKlSq_D9cirBBIsTgDX0"
      )
      .then(function (response) {
        // handle success
        let localFontFamilies = [
          { family: "Arial" },
          { family: "Georgia" },
          { family: "Tahoma" },
          { family: "Times New Roman" },
          { family: "Verdana" },
        ];
        setFontFamilyItems(
          [...localFontFamilies, ...response.data.items].sort((a, b) => {
            let fa = a.family.toLowerCase(),
              fb = b.family.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          })
        );
        // let tempFontWeihtIItems = response.data.items[0].variants;
        setFontWeightItems(["normal", "bold"]);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  const [URLValue, setURLValue] = useState("");
  const {
    refFontColorISI,
    colorPickerFontColorISI,
    setColorPickerFontColorISI,
  } = useColorpickerFontColorISI(false);
  const {
    refTextBgColorISI,
    colorPickerTextBgColorISI,
    setColorPickerTextBgColorISI,
  } = useColorpickerTextBgColorISI(false);
  const { refButtonColor, displayColorPicker, setDisplayColorPicker } =
    useDisplaycolorpicker(false);

  const { refBorder, displayColorPickerBorder, setDisplayColorPickerBorder } =
    useDisplaycolorpickerBorder(false);
  const {
    refFontColor,
    displayColorPickerFontColor,
    setDisplayColorPickerFontColor,
  } = useDisplaycolorpickerFontColor(false);

  const {
    refScrollColor,
    displayColorPickerScrollColor,
    setDisplayColorPickerScrollColor,
  } = useDisplaycolorpickerScrollColor(false);

  const {
    refBgColor,
    displayColorPickerBgColor,
    setDisplayColorPickerBgColor,
  } = useDisplaycolorpickerBgColor(false);
  const { refTextBg, displaycolorpickerTextBg, setDisplaycolorpickerTextBg } =
    useDisplaycolorpickerTextBg(false);

  useEffect(() => {
    // ;
    let tempSelect = JSON.parse(JSON.stringify(selectedComponent));
    if (
      Object.values(selectedComponent).length === 0
      // ||
      // Object.values(updatedValue).length === 0
    )
      return;
    // let inputs = ["height", "width", "top", "left"];
    // if (
    //   tempSelect.componentType !== "Shape" &&
    //   tempSelect.componentType !== "default" &&
    //   tempSelect !== "compdeleted"
    // ) {
    //   inputs.forEach((input) => {
    //     if (
    //       tempSelect?.componentStyles[input] === 0 &&
    //       updatedValue?.componentStyles[input] === ""
    //     )
    //       tempSelect.componentStyles[input] = "";
    //   });
    // }
    if (multiSelectedComps.length === 0) {
      setUpdatedValue(tempSelect);
    }
    setLocalRightProps(JSON.parse(JSON.stringify(selectedComponent)));
  }, [selectedComponent]);

  const handleClickFontColorISI = () => {
    setColorPickerFontColorISI(!colorPickerFontColorISI);
  };
  const handleClicktextBgColorISI = () => {
    setColorPickerTextBgColorISI(!colorPickerTextBgColorISI);
  };
  const handleClickSwatchFontCOlor = () => {
    /*   let selectedText2 = createFroalaInstance?.current[froalaIndex]
      ?.getEditor()
      ?.selection?.text();
    setSelectedText(selectedText2); */
    setDisplayColorPickerFontColor(!displayColorPickerFontColor);
  };
  const handleTextBgColor = () => {
    setDisplaycolorpickerTextBg(!displaycolorpickerTextBg);
  };
  const handleClickSwatch = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClickSwatchBorder = () => {
    setDisplayColorPickerBorder(!displayColorPickerBorder);
  };
  const handleClickSwatchScroll = () => {
    setDisplayColorPickerScrollColor(!displayColorPickerScrollColor);
  };

  const handleClickSwatchBgColor = (from) => {
    if (from === "fromGlobal") setCanvasBgChange(true);
    setDisplayColorPickerBgColor(!displayColorPickerBgColor);
  };

  const changeTargetLink = (e, blur) => {
    setTargetLink(e.target.value);
    if (blur !== "blur") return;
    let tempSlected = { ...selectedComponent };

    if (tempSlected.componentStyles) {
      tempSlected.componentStyles.targetLink = e.target.value;
      /*   if (tempSlected.componentStyles.color === "#000000") {
        tempSlected.componentStyles.color = "#0645AD";
      } else if (e.target.value === "" || e.target.value === "http://") {
        tempSlected.componentStyles.color = "#000000";
      } */

      /*  if (tempSlected.componentStyles.color !== "#00000") {
        tempSlected.componentStyles.color = "#0645AD";
      } */
      setSelectedComponent(tempSlected);
    }
  };

  const changeBorderStyle = (e) => {
    if (Object.keys(selectedComponent).length > 0);
    let tempSlected = { ...selectedComponent };
    tempSlected.componentStyles.border = `${e.target.value} ${Number(
      borderWidth
    )}px rgba(${borderColor?.r},${borderColor?.g},${borderColor?.b},${
      borderColor?.a
    })`;
    setSelectedComponent(tempSlected);
    setBorderStyle(e.target.value);
  };
  const changeBorderWidth = (e, blur) => {
    let tempSlected = { ...selectedComponent };

    if (tempSlected?.componentStyles?.border) {
      let val = getValidInput(e, blur);
      if (val === -1) return;
      // val = val ? parseInt(val) : 0;
      // let val = Math.sign(e.target.value) === 1 ? parseInt(e.target.value) : 0;
      // v

      setBorderWidth(val);
      // if (blur !== "blur") return;
      tempSlected.componentStyles.border = `${borderStyle} ${
        Number(val) || 0
      }px rgba(${borderColor?.r},${borderColor?.g},${borderColor?.b},${
        borderColor?.a
      })`;
      setSelectedComponent(tempSlected);
      if (tempSlected?.textComponentType?.addContent === "triangleShape") {
        changeStrokeWidth(val);
      } else if (
        tempSlected?.textComponentType?.addContent === "polygonShape"
      ) {
        let polyBorderVal = e.target.value;
        tempSlected.componentStyles.border = `${borderStyle} ${Number(
          polyBorderVal || 0
        )}px rgba(${borderColor?.r},${borderColor?.g},${borderColor?.b},${
          borderColor?.a
        } )`;
        setSelectedComponent(tempSlected);

        setBorderWidth(polyBorderVal);
        changePentagonStrokeWidth();
      } else if (
        selectedComponent?.textComponentType?.addContent === "hexagonShape"
      ) {
        let hexBorderVal = e.target.value;
        tempSlected.componentStyles.border = `${borderStyle} ${Number(
          hexBorderVal || 0
        )}px rgba(${borderColor?.r},${borderColor?.g},${borderColor?.b},${
          borderColor?.a
        } )`;
        setSelectedComponent(tempSlected);

        setBorderWidth(hexBorderVal);
        changeHexagonStrokeWidth();
      }
      if (blur === "blur") {
        layerCompData[layerCompData.length - 1].eachDrag = "no";
      } else {
        layerCompData[layerCompData.length - 1].eachDrag = "yes";
      }
    }
  };

  const handleDimensions = (e, blur) => {
    console.log("e++", e.target.value);
    let retVal = getValidInput(e, blur);

    if (retVal === -1) return;

    if (blur === "blur" && (retVal === "-" || retVal === "-0")) {
      retVal = 0;
    }
    // setCanvasUpdated(true);
    let name = e.target.name;
    if (blur === "blur") {
      if (!!Number(retVal) || retVal === "") {
        retVal = Number(retVal);
      }
      layerCompData[layerCompData.length - 1].eachDrag = "no";
    } else {
      layerCompData[layerCompData.length - 1].eachDrag = "yes";
    }
    let dimensionValue = {
      ...updatedValue,
      componentStyles: {
        ...updatedValue.componentStyles,
        [name]: retVal,
      },
    };

    setUpdatedValue(dimensionValue);
    // if (multiSelectedComps.length > 0) {
    //     updateMultiCompProperties(retVal,e.target.name,isAspectRatioLocked);
    // }

    let tempSelected = { ...selectedComponent };
    if((name=="width"||name=="height")&&isAspectRatioLocked){
      handleCompAspectRatio(tempSelected,name,retVal);
    }
    tempSelected.componentStyles[name] = retVal;
    setSelectedComponent(tempSelected);
  };

  const handleBlur = (e) => {
    let name = e.target.name;

    let dimensionValue = {
      ...updatedValue,
      componentStyles: {
        ...updatedValue.componentStyles,
        [name]: e.target.value === "" ? 0 : e.target.value,

        //e.target.value,
      },
    };

    setUpdatedValue(dimensionValue);
    if (selectedComponent) {
      let tempSelected = { ...selectedComponent };
      tempSelected.componentStyles.width =
        dimensionValue?.componentStyles?.width;
      tempSelected.componentStyles.height =
        dimensionValue?.componentStyles?.height;
      tempSelected.componentStyles.left = dimensionValue?.componentStyles?.left;
      tempSelected.componentStyles.top = dimensionValue?.componentStyles?.top;

      setSelectedComponent(tempSelected);
    }
  };
  const handleCornerCount = (e) => {
    setCornerCount(e.target.value);
  };
  const [paddingTRBL, setPaddingTRBL] = useState({
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  });
  const [radiusTRBL, setRadiusTRBL] = useState({
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  });
  const [commonPadding, setCommonPadding] = useState(true);
  const [commonRadius, setCommonRadius] = useState(true);

  const handlePadding = (sides) => {
    if (sides === "common") setCommonPadding(true);
    else setCommonPadding(false);
  };
  const handleRadius = (sides) => {
    if (sides === "common") setCommonRadius(true);
    else setCommonRadius(false);
  };
  const changeRadius = (e, blur) => {
    const { name, value } = e.target;
    let val = getValidInput(e, blur);
    if (val === -1) return;
    let tempSelected = Object.assign({}, selectedComponent);
    if (tempSelected?.componentStyles) {
      if (name === "borderTopLeftRadius" && commonRadius) {
        setRadiusTRBL({
          borderTopLeftRadius: val,
          borderTopRightRadius: val,
          borderBottomLeftRadius: val,
          borderBottomRightRadius: val,
        });
        // if (blur !== "blur") return;
        if (blur === "blur") {
          layerCompData[layerCompData.length - 1].eachDrag = "no";
        } else {
          layerCompData[layerCompData.length - 1].eachDrag = "yes";
        }
        tempSelected.componentStyles.borderTopLeftRadius = val;
        tempSelected.componentStyles.borderTopRightRadius = val;
        tempSelected.componentStyles.borderBottomLeftRadius = val;
        tempSelected.componentStyles.borderBottomRightRadius = val;

        setSelectedComponent(tempSelected);
      } else {
        setRadiusTRBL({ ...radiusTRBL, [name]: val });
        // if (blur !== "blur") return;
        if (blur === "blur") {
          layerCompData[layerCompData.length - 1].eachDrag = "no";
        } else {
          layerCompData[layerCompData.length - 1].eachDrag = "yes";
        }

        tempSelected.componentStyles[name] = val;
        setSelectedComponent(tempSelected);
      }
    }
  };

  const changePadding = (e, blur) => {
    const { name, value } = e.target;
    let val = getValidInput(e, blur);
    if (val === -1) return;

    let tempSelected = Object.assign({}, selectedComponent);

    if (name === "paddingTop" && commonPadding) {
      setPaddingTRBL({
        paddingTop: val,
        paddingRight: val,
        paddingBottom: val,
        paddingLeft: val,
      });
      // if (blur !== "blur") return;
      if (blur === "blur") {
        layerCompData[layerCompData.length - 1].eachDrag = "no";
      } else {
        layerCompData[layerCompData.length - 1].eachDrag = "yes";
      }
      tempSelected.componentStyles.paddingTop = val;
      tempSelected.componentStyles.paddingRight = val;
      tempSelected.componentStyles.paddingBottom = val;
      tempSelected.componentStyles.paddingLeft = val;

      setSelectedComponent(tempSelected);
    } else {
      setPaddingTRBL({ ...paddingTRBL, [name]: val });
      // if (blur !== "blur") return;
      if (blur === "blur") {
        layerCompData[layerCompData.length - 1].eachDrag = "no";
      } else {
        layerCompData[layerCompData.length - 1].eachDrag = "yes";
      }
      tempSelected.componentStyles[name] = val;
      setSelectedComponent(tempSelected);
    }
  };
  const [uppercaseClicked, setUppercaseClicked] = useState(false);

  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("api called");
    }, 500);

    return () => clearTimeout(timer);
  }, [fontSize]);
  const [localRightProps, setLocalRightProps] = useState({});

  const fontFamilyConfig = (value) => {
    if (selectedComponent) {
      let tempSelect = { ...selectedComponent };
      tempSelect.componentStyles.fontFamily = value;
      setSelectedComponent(tempSelect);
      let tempLayerCompData = [...layerCompData];
      tempLayerCompData[
        layerCompData.findIndex(
          (selComp) => selComp.id === selectedComponent.id
        )
      ].componentStyles.fontFamily = tempSelect.componentStyles.fontFamily;
      setLayerCompData(tempLayerCompData);
    } else {
    }
    setundoFlag(true);
  };
  const handleFontFamily = (e) => {
    let eventData = e.target.value;
    let tempSelect = Object.assign(selectedComponent);
    let tempLayerCompData = JSON.parse(JSON.stringify(layerCompData));

    tempSelect.componentStyles.fontFamily = e.target.value;
    if (
      ["Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana"].includes(
        e.target.value
      )
    ) {
      setFontFamily(e.target.value);
      fontFamilyConfig(e.target.value);
      setFontWeightItems(["normal", "bold"]);
      // if (froalaIndex >= 0) fontWeightConfig("normal");
      // setFontWeight("normal");
      setSelectedComponent(tempSelect);
    } else {
      let fontWeight = fontFamilyItems.filter(
        (item) => item.family === eventData
      )[0].variants;
      fontWeight = [
        "normal",
        "bold",
        ...fontWeight.filter((variant) => /^\d+$/.test(variant)),
      ];
      let familiesToLoadWithWeight = `${e.target.value}:wght@`;
      fontWeight.forEach((weight) => {
        familiesToLoadWithWeight = familiesToLoadWithWeight.concat(
          `0,${weight};`
        );
      });
      setFontWeightItems(fontWeight);
      WebFont.load({
        google: {
          families: [familiesToLoadWithWeight.concat("&display=swap")],
        },
        active: function () {
          setFontFamily(eventData);
          fontFamilyConfig(eventData);

          // if (froalaIndex >= 0) fontWeightConfig("normal");
          // setFontWeight("normal");
          setSelectedComponent(tempSelect);
          tempLayerCompData[
            layerCompData.findIndex(
              (selComp) => selComp.id === selectedComponent.id
            )
          ].componentStyles.fontFamily = tempSelect.componentStyles.fontFamily;
          setLayerCompData(tempLayerCompData);
        },
      });
    }
  };

  const handleTextPropsOnchange = (value, compPropname) => {
    if (selectedComponent) {
      let tempSelected = { ...selectedComponent };
      if (compPropname === "lineHeight") {
        tempSelected.componentStyles.lineHeight = value;
        setLineHeightProp(value);
      } else if (compPropname === "fontSize") {
        tempSelected.componentStyles.fontSize = value;
        setFontSize(value);
      } else if (compPropname === "fontWeight") {
        setFontWeight(value);

        tempSelected.componentStyles.fontWeight = value;
        tempSelected.componentStyles.fontWeightBold = value;
      } else if (compPropname === "letterSpacing") {
        tempSelected.componentStyles.letterSpacing = value;
        setCharacterSpacing(value);
      }
      setLocalRightProps(tempSelected);
      let tempLayercompData = [...layerCompData];
      tempLayercompData.forEach((entry, index) => {
        if (entry.id === tempSelected.id) {
          tempLayercompData[index].componentStyles[compPropname] = value;
        }
      });
      setLayerCompData(tempLayercompData);
    }
  };

  const fontAlignmentonClick = (type) => {
    //  const tempSlected = { ...selectedComponent };
    const tempSelected = { ...selectedComponent };

    const tempLayercompData = [...layerCompData];
    switch (type) {
      case "leftAlign":
        tempSelected.componentStyles.textAlign = "left";
        tempLayercompData.forEach((entry, index) => {
          if (entry.id === tempSelected.id) {
            tempLayercompData[index].componentStyles.textAlign = "left";
          }
        });
        break;
      case "centerAlign":
        tempSelected.componentStyles.textAlign = "center";
        tempLayercompData.forEach((entry, index) => {
          if (entry.id === tempSelected.id) {
            tempLayercompData[index].componentStyles.textAlign = "center";
          }
        });
        break;

      case "rightAlign":
        tempSelected.componentStyles.textAlign = "right";
        tempLayercompData.forEach((entry, index) => {
          if (entry.id === tempSelected.id) {
            tempLayercompData[index].componentStyles.textAlign = "right";
          }
        });

        break;
      case "numberList":
        if (
          createFroalaInstance &&
          createFroalaInstance.current &&
          createFroalaInstance.current[froalaIndex] &&
          createFroalaInstance.current[froalaIndex].getEditor() &&
          froalaIndex !== undefined
        ) {
          createFroalaInstance.current[froalaIndex].editor.edit.on();
          createFroalaInstance.current[froalaIndex].editor.events.focus();
          //setFroalatoolbarTag(false);

          createFroalaInstance.current[froalaIndex]
            .getEditor()
            .commands.selectAll();
          // createFroalaInstance.current[froalaIndex].getEditor().toolbar.hide();
          // createFroalaInstance.current[froalaIndex].editor.edit.on();
          // createFroalaInstance.current[froalaIndex].editor.events.focus();
          createFroalaInstance.current[froalaIndex]
            .getEditor()
            .lists.format("OL");
          let oldText =
            createFroalaInstance.current[froalaIndex]?.editor?.html.get();

          if (tempLayercompData[froalaIndex]?.froalaText) {
            tempLayercompData[froalaIndex].froalaText = oldText;
          }

          setLayerCompData(tempLayercompData);
        }
        createFroalaInstance.current[froalaIndex].getEditor().selection.clear();

        break;
      case "bullets":
        // setClickedTimes({ clicks: 2, index: froalaIndex });
        if (
          createFroalaInstance &&
          createFroalaInstance.current &&
          createFroalaInstance.current[froalaIndex] &&
          createFroalaInstance.current[froalaIndex].getEditor() &&
          froalaIndex !== undefined
        ) {
          createFroalaInstance.current[froalaIndex].editor.edit.on();
          createFroalaInstance.current[froalaIndex].editor.events.focus();
          createFroalaInstance.current[froalaIndex]
            .getEditor()
            .commands.selectAll();
          // createFroalaInstance.current[froalaIndex].editor.edit.on();
          // createFroalaInstance.current[froalaIndex].editor.events.focus();
          createFroalaInstance.current[froalaIndex]
            .getEditor()
            .lists.format("UL");
          // createFroalaInstance.current[froalaIndex]
          //   .getEditor()
          //   .format.applyStyle("list-style-type", "disc");
          let oldText =
            createFroalaInstance.current[froalaIndex]?.editor?.html.get();

          if (tempLayercompData[froalaIndex]?.froalaText) {
            tempLayercompData[froalaIndex].froalaText = oldText;
          }

          setLayerCompData(tempLayercompData);
        }
        createFroalaInstance.current[froalaIndex].getEditor().selection.clear();

        break;
      default:
        break;
    }
    setSelectedComponent(tempSelected);

    setLayerCompData(tempLayercompData);
  };
  const listOnclick = (type) => {
    //  const tempSlected = { ...selectedComponent };
    const tempSelected = { ...selectedComponent };

    const tempLayercompData = [...layerCompData];
    switch (type) {
      case "numberList":
        if (
          createFroalaInstance &&
          createFroalaInstance.current &&
          createFroalaInstance.current[froalaIndex] &&
          createFroalaInstance.current[froalaIndex].getEditor() &&
          froalaIndex !== undefined
        ) {
          createFroalaInstance.current[froalaIndex].editor.edit.on();
          createFroalaInstance.current[froalaIndex].editor.events.focus();
          //setFroalatoolbarTag(false);

          createFroalaInstance.current[froalaIndex]
            .getEditor()
            .commands.selectAll();
          // createFroalaInstance.current[froalaIndex].getEditor().toolbar.hide();
          // createFroalaInstance.current[froalaIndex].editor.edit.on();
          // createFroalaInstance.current[froalaIndex].editor.events.focus();
          createFroalaInstance.current[froalaIndex]
            .getEditor()
            .lists.format("OL");
          let oldText =
            createFroalaInstance.current[froalaIndex]?.editor?.html.get();

          if (tempLayercompData[froalaIndex]?.froalaText) {
            tempLayercompData[froalaIndex].froalaText = oldText;
          }

          setLayerCompData(tempLayercompData);
        }
        createFroalaInstance.current[froalaIndex].getEditor().selection.clear();

        break;
      case "bullets":
        // setClickedTimes({ clicks: 2, index: froalaIndex });
        if (
          createFroalaInstance &&
          createFroalaInstance.current &&
          createFroalaInstance.current[froalaIndex] &&
          createFroalaInstance.current[froalaIndex].getEditor() &&
          froalaIndex !== undefined
        ) {
          createFroalaInstance.current[froalaIndex].editor.edit.on();
          createFroalaInstance.current[froalaIndex].editor.events.focus();
          createFroalaInstance.current[froalaIndex]
            .getEditor()
            .commands.selectAll();
          // createFroalaInstance.current[froalaIndex].editor.edit.on();
          // createFroalaInstance.current[froalaIndex].editor.events.focus();
          createFroalaInstance.current[froalaIndex]
            .getEditor()
            .lists.format("UL");
          // createFroalaInstance.current[froalaIndex]
          //   .getEditor()
          //   .format.applyStyle("list-style-type", "disc");
          let oldText =
            createFroalaInstance.current[froalaIndex]?.editor?.html.get();

          if (tempLayercompData[froalaIndex]?.froalaText) {
            tempLayercompData[froalaIndex].froalaText = oldText;
          }

          setLayerCompData(tempLayercompData);
        }
        createFroalaInstance.current[froalaIndex].getEditor().selection.clear();

        break;
      default:
        break;
    }
    setSelectedComponent(tempSelected);

    setLayerCompData(tempLayercompData);
  };
  const fontStylesonClick = (type) => {
    //let oldWeight = selectedComponent?.componentStyles?.fontWeight;
    if (selectedComponent) {
      let tempSelected = { ...selectedComponent };
      let tempLayercompData = [...layerCompData];
      //let tempSelected = JSON.parse(JSON.stringify(selectedComponent));

      switch (type) {
        case "bold":
          let clickBold = !tempSelected.componentStyles.boldClick;

          tempSelected.componentStyles.boldClick = clickBold;
          if (clickBold === true) {
            tempSelected.componentStyles.fontWeightBold = "bold";

            tempSelected.componentStyles.fontWeight = "bold";
          } else {
            tempSelected.componentStyles.fontWeightBold = "normal";
            tempSelected.componentStyles.fontWeight = "normal";
            tempSelected.componentStyles.boldClick = false;
          }
          tempLayercompData.forEach((entry, index) => {
            if (entry.id === tempSelected.id) {
              tempLayercompData[index].componentStyles.fontWeightBold =
                tempSelected.componentStyles.fontWeightBold;
              tempLayercompData[index].componentStyles.fontWeight =
                tempSelected.componentStyles.fontWeight;
            }
          });

          break;
        case "italic":
          let clickitalic = !tempSelected.componentStyles.italicClick;
          tempSelected.componentStyles.italicClick = clickitalic;
          if (clickitalic) {
            tempSelected.componentStyles.fontStyle = "italic";
          } else {
            tempSelected.componentStyles.fontStyle = "unset";
          }
          tempLayercompData.forEach((entry, index) => {
            if (entry.id === tempSelected.id) {
              tempLayercompData[index].componentStyles.fontStyle =
                tempSelected.componentStyles.fontStyle;
            }
          });
          break;
        case "underline":
          let clickUnderline = !tempSelected.componentStyles.underlineClick;

          tempSelected.componentStyles.underlineClick = clickUnderline;
          if (clickUnderline === true) {
            return (tempSelected.componentStyles.textDecUnderline =
              "underline");
          } else {
            tempSelected.componentStyles.textDecUnderline = "unset";
          }
          tempLayercompData.forEach((entry, index) => {
            if (entry.id === tempSelected.id) {
              tempLayercompData[index].componentStyles.textDecUnderline =
                tempSelected.componentStyles.textDecUnderline;
            }
          });
          break;
        case "uppercase":
          let clickUppercase = !tempSelected.componentStyles.uppercaseClicked;
          tempSelected.componentStyles.uppercaseClicked = clickUppercase;

          if (clickUppercase === true) {
            return (tempSelected.componentStyles.textTransform = "uppercase");
          } else {
            tempSelected.componentStyles.textTransform = "unset";
          }
          tempLayercompData.forEach((entry, index) => {
            if (entry.id === tempSelected.id) {
              tempLayercompData[index].componentStyles.textTransform =
                tempSelected.componentStyles.textTransform;
            }
          });
          break;
        case "strikeout":
          let clickStrikeThrough =
            !tempSelected.componentStyles.strikeThroughClicked;

          tempSelected.componentStyles.strikeThroughClicked =
            clickStrikeThrough;
          if (clickStrikeThrough === true) {
            return (tempSelected.componentStyles.textDecoration =
              "line-through");
          } else {
            tempSelected.componentStyles.textDecoration = "unset";
          }
          tempLayercompData.forEach((entry, index) => {
            if (entry.id === tempSelected.id) {
              tempLayercompData[index].componentStyles.textDecoration =
                tempSelected.componentStyles.textDecoration;
            }
          });
          break;
        default:
          break;
      }
      setSelectedComponent(tempSelected);
      // tempLayercompData.forEach((entry, index) => {
      //   if (entry.id === tempSelected.id) {
      //     tempLayercompData[index] = { ...tempSelected };
      //   }
      // });
      setLayerCompData(tempLayercompData);
    }
  };
  //2776
  const [textColor, setTextColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });
  const [textBgColorISI, setTextBgColorISI] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });
  const [fontColorISI, setFontColorISI] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });
  const [buttonColor, setButtonColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });
  const [borderColor, setBorderColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });

  const [textBgColor, setTextBgColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });
  const [scrollColor, setScrollColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });
  const styles = reactCSS({
    default: {
      buttonColor: {
        width: "30px",
        height: "14px",
        // position: "absolute",
        marginLeft: "10px",
        borderRadius: "3px",
        border: "solid 1px #e1e1e1",
        background:
          typeof buttonColor === "string"
            ? buttonColor
            : `rgba(${buttonColor?.r}, ${buttonColor?.g}, ${buttonColor?.b}, ${buttonColor?.a})`,
      },
      borderColor: {
        width: "30px",
        height: "14px",
        // position: "absolute",
        marginLeft: "10px",
        borderRadius: "3px",
        border: "solid 1px #e1e1e1",
        background:
          typeof borderColor === "string"
            ? borderColor
            : `rgba(${borderColor?.r}, ${borderColor?.g}, ${borderColor?.b}, ${borderColor?.a})`,
      },
      scrollColor: {
        width: "30px",
        height: "14px",
        // position: "absolute",
        marginLeft: "10px",
        borderRadius: "3px",
        border: "solid 1px #e1e1e1",
        background:
          typeof scrollColor === "string"
            ? scrollColor
            : `rgba(${scrollColor?.r}, ${scrollColor?.g}, ${scrollColor?.b}, ${scrollColor?.a})`,
      },
      textColor: {
        width: "30px",
        height: "14px",
        // position: "absolute",
        marginLeft: "10px",
        borderRadius: "3px",
        border: "solid 1px #e1e1e1",
        background:
          typeof textColor === "string"
            ? textColor
            : `rgba(${textColor?.r}, ${textColor?.g}, ${textColor?.b}, ${textColor?.a})`,
      },
      textBgColorISI: {
        width: "30px",
        height: "14px",
        // position: "absolute",
        marginLeft: "10px",
        borderRadius: "3px",
        border: "solid 1px #e1e1e1",
        background:
          typeof textBgColorISI === "string"
            ? textBgColorISI
            : `rgba(${textBgColorISI?.r}, ${textBgColorISI?.g}, ${textBgColorISI?.b}, ${textBgColorISI?.a})`,
      },
      fontColorISI: {
        width: "30px",
        height: "14px",
        // position: "absolute",
        marginLeft: "10px",
        borderRadius: "3px",
        border: "solid 1px #e1e1e1",
        background:
          typeof fontColorISI === "string"
            ? fontColorISI
            : `rgba(${fontColorISI?.r}, ${fontColorISI?.g}, ${fontColorISI?.b}, ${fontColorISI?.a})`,
      },
      textBgColor: {
        width: "30px",
        height: "14px",
        // position: "absolute",
        marginLeft: "10px",
        borderRadius: "3px",
        border: "solid 1px #e1e1e1",
        background:
          typeof textBgColor === "string"
            ? textBgColor
            : `rgba(${textBgColor?.r}, ${textBgColor?.g}, ${textBgColor?.b}, ${textBgColor?.a})`,
      },
      backgroundColor: {
        width: "30px",
        height: "14px",
        //   position: "absolute",
        marginLeft: "10px",
        borderRadius: "3px",
        border: "solid 1px #e1e1e1",
        background:
          typeof canvasDimension?.backgroundColor === "string"
            ? canvasDimension?.backgroundColor
            : `rgb(${canvasDimension?.backgroundColor?.r}, ${canvasDimension?.backgroundColor?.g}, ${canvasDimension?.backgroundColor?.b}, ${canvasDimension?.backgroundColor?.a})`,
      },
    },
  });

  // //2776 canvas undo
  // const [addedCanvasActionsList, setAddedCanvasactionsList] = useState([
  //   {
  //     canvasDimension: {},
  //     canvasBgcolor: "",
  //   },
  // ]);
  // // const [previousCanvasBackGround, setPreviousCanvasBackGround] = useState("");

  // const handleCanvasUndo = () => {};
  // const prepareCanvasUndoArray = () => {
  //   let tempAddedCanvasActionsList = [...addedCanvasActionsList];
  //   tempAddedCanvasActionsList.push({
  //     canvasDimension: canvasDimension,
  //     canvasBgcolor: styles.backgroundColor.background,
  //   });
  //   setAddedCanvasactionsList(tempAddedCanvasActionsList);
  // };
  // useEffect(() => {
  //   if (canvasUpdated) {
  //     prepareCanvasUndoArray();
  //     setCanvasUpdated(false);
  //     console.log(canvasDimension, "canvasDimensonCahnged");
  //   } else {
  //     console.log("when canvas is not edited");
  //     setAddedCanvasactionsList([
  //       {
  //         canvasDimension: canvasDimension,
  //         canvasBgcolor: styles.backgroundColor.background,
  //       },
  //     ]);
  //     // setPreviousCanvasDimension({ ...canvasDimension });
  //     // setPreviousCanvasBackGround({ ...styles.backgroundColor.background });
  //     return;
  //   }
  // }, [canvasDimension]);
  // //2776 canvas undo

  // useImperativeHandle(ref, () => ({
  //   addedCanvasActionsList,
  //   handleCanvasUndo,
  // }));

  const handleClose = () => {
    setDisplayColorPicker(displayColorPicker);
  };
  const hideColorPicker = () => {
    if (displayColorPickerFontColor) {
      setDisplayColorPickerFontColor(false);
    } else if (displayColorPicker) {
      setDisplayColorPicker(false);
      if (displayColorPickerFontColor) {
        setDisplayColorPickerFontColor(false);
      }
    } else if (displayColorPickerBorder) {
      setDisplayColorPickerBorder(false);
    } else if (displayColorPickerScrollColor) {
      setDisplayColorPickerScrollColor(false);
    } else if (displayColorPickerBgColor) {
      setDisplayColorPickerBgColor(false);
    } else if (displaycolorpickerTextBg) {
      setDisplaycolorpickerTextBg(false);
    } else if (colorPickerFontColorISI) {
      setColorPickerFontColorISI(false);
    } else if (colorPickerTextBgColorISI) {
      setColorPickerTextBgColorISI(false);
    }
  };

  const replaceFontColor = (fontColor, origin) => {
    let fontColorN =
      origin != "fromOnComplete"
        ? `rgba(${fontColor?.r}, ${fontColor?.g}, ${fontColor?.b}, ${fontColor?.a})`
        : fontColor;
    if (selectedComponent) {
      let tempSelected = { ...selectedComponent };
      tempSelected.componentStyles.color = fontColorN;

      setSelectedComponent(tempSelected);

      let tempLayercompData = [...layerCompData];
      tempLayercompData.forEach((entry, index) => {
        if (entry.id === tempSelected.id) {
          tempLayercompData[index].componentStyles.color = fontColorN;
        }
      });
      if (origin === "fromOnComplete") return tempSelected;
    } else {
    }
  };
  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
      marginLeft: "10px",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
      background: isAspectRatioLocked ? "white" : "",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);
  // const [checkedAspect, setCheckedAspect] = React.useState(true);
  const ToggleAspectLock = (e) => {
    setIsAspectRatioLocked(!isAspectRatioLocked);
    // let tempSlected = { ...selectedComponent };
    // tempSlected.componentStyles.aspectLock = e.target.checked;
    // setSelectedComponent(tempSlected);
  };
  const collectColorOnChangeComplete = (e) => {
    if (displayColorPickerBgColor && canvasBgChange) {
      setCanvasUpdated(true);
      setCanvasDimension({
        ...canvasDimension,
        backgroundColor: myCanvasRef.current,
        canvasChanged: true,
      });
      appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
      return;
    }
    let tempLyaer = [...layerCompData];
    if (displayColorPickerFontColor) {
      let tempSelected = replaceFontColor(
        selectedComponent.componentStyles.color,
        "fromOnComplete"
      );
      tempLyaer[
        tempLyaer.findIndex((comp) => comp.id === tempSelected.id)
      ].componentStyles.color = tempSelected?.componentStyles?.color;
    }
    tempLyaer[tempLyaer.length - 1].eachDrag = "no";
    setLayerCompData(tempLyaer);
  };

  const handleTextColorChange = (e) => {
    if (displayColorPickerFontColor) {
      let fontColor = e.rgb;
      replaceFontColor(fontColor);
      setTextColor(fontColor);
    } else if (displayColorPicker) {
      let buttonColor = e.rgb;
      if (selectedComponent?.componentStyles?.backgroundColor) {
        let tempSlected = { ...selectedComponent };
        tempSlected.componentStyles.backgroundColor = `rgba(${buttonColor?.r}, ${buttonColor?.g}, ${buttonColor?.b}, ${buttonColor?.a})`;
        setSelectedComponent(tempSlected);
        setButtonColor(buttonColor);
        if (
          selectedComponent?.textComponentType?.addContent === "triangleShape"
        ) {
          changeTriangleBgColor();
        } else if (
          selectedComponent?.textComponentType?.addContent === "polygonShape"
        ) {
          changePentgonBgColor();
        } else if (
          selectedComponent?.textComponentType?.addContent === "hexagonShape"
        ) {
          changeHexagonBgColor();
        }
      } else {
        setButtonColor({ r: "241", g: "112", b: "19" });
      }
    } else if (displayColorPickerBorder) {
      let borderColor = e.rgb;
      if (selectedComponent?.componentStyles?.border) {
        let tempSlected = { ...selectedComponent };
        tempSlected.componentStyles.border = `${borderStyle} ${Number(
          borderWidth
        )}px rgba(${borderColor?.r},${borderColor?.g},${borderColor?.b},${
          borderColor?.a
        })`;
        setSelectedComponent(tempSlected);

        let selectedCompType = selectedComponent?.textComponentType?.addContent;
        if (selectedCompType === "triangleShape") {
          changeTriangleBorderColor();
        } else if (selectedCompType === "polygonShape") {
          changepentagonBorderColor();
        } else if (selectedCompType === "hexagonShape") {
          changeHexagonBorderColor();
        }
        setBorderColor(borderColor);
      } else {
        setBorderColor({ r: "241", g: "112", b: "19" });
      }
    } else if (displayColorPickerScrollColor) {
      let scrollColor = e.rgb;
      if (scrollbarArrow.thumbColor) {
        let tempSlected = { ...selectedComponent };
        tempSlected.componentStyles.thumbColor = `rgba(${scrollColor?.r}, ${scrollColor?.g}, ${scrollColor?.b}, ${scrollColor?.a})`;
        setSelectedComponent(tempSlected);
        setScrollColor(scrollColor);
        let parentScrollBar = document.getElementById(tempSlected.id);
        if (parentScrollBar.getElementsByClassName("rc-scrollbars-track-v")[0])
          parentScrollBar.getElementsByClassName(
            "rc-scrollbars-track-v"
          )[0].style.backgroundColor = `rgba(${scrollColor?.r}, ${scrollColor?.g}, ${scrollColor?.b}, ${scrollColor?.a})`;
      } else {
        setScrollColor({ r: "241", g: "112", b: "19" });
      }
    } else if (displayColorPickerBgColor) {
      //2776 canvasUndo
      if (canvasBgChange) setCanvasUpdated(false);

      let bgColor = e.rgb;
      // setBgColorCanvas(bgColor);
      appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
      myCanvasRef.current = bgColor;

      //setBackgroundColor(bgColor);
      setCanvasDimension({
        ...canvasDimension,
        backgroundColor: bgColor,
        canvasChanged: false,
      });
    } else if (displaycolorpickerTextBg) {
      let textBackgroundColor = e.rgb;
      // ;
      // if (
      //   `rgba(${textBackgroundColor?.r},${textBackgroundColor?.g},${textBackgroundColor?.b},${textBackgroundColor?.a})` ===
      //   selectedComponent?.componentStyles?.textBgColor
      // )
      if (e.rgb.a === 0) setundoFlag(true);
      //textBackgroundColor.a = 1;
      if (selectedComponent?.componentStyles?.textBgColor) {
        let tempSlected = { ...selectedComponent };
        tempSlected.componentStyles.textBgColor = `rgba(${textBackgroundColor?.r},${textBackgroundColor?.g},${textBackgroundColor?.b},${textBackgroundColor?.a})`;
        setSelectedComponent(tempSlected);
        setTextBgColor(textBackgroundColor);
      } else {
        setTextBgColor({ r: "241", g: "112", b: "19" });
      }
    } else if (colorPickerTextBgColorISI) {
      let textBackgroundColorISI = e.rgb;

      if (e.rgb.a === 0) setundoFlag(true);
      //textBackgroundColor.a = 1;
      if (selectedComponent?.componentStyles?.expandClose?.textBgColorISI) {
        let tempSlected = { ...selectedComponent };
        tempSlected.componentStyles.expandClose.textBgColorISI = `rgba(${textBackgroundColorISI?.r},${textBackgroundColorISI?.g},${textBackgroundColorISI?.b},${textBackgroundColorISI?.a})`;
        setSelectedComponent(tempSlected);
        setTextBgColorISI(textBackgroundColorISI);
      } else {
        setTextBgColorISI({ r: "241", g: "112", b: "19" });
      }
    } else if (colorPickerFontColorISI) {
      let fontColISI = e.rgb;

      if (e.rgb.a === 0) setundoFlag(true);
      //textBackgroundColor.a = 1;
      if (selectedComponent?.componentStyles?.expandClose?.fontColorISI) {
        let tempSlected = { ...selectedComponent };
        tempSlected.componentStyles.expandClose.fontColorISI = `rgba(${fontColISI?.r},${fontColISI?.g},${fontColISI?.b},${fontColISI?.a})`;
        setSelectedComponent(tempSlected);
        setFontColorISI(fontColISI);
      } else {
        setFontColorISI({ r: "241", g: "112", b: "19" });
      }
    }

    /*let tempSelected = { ...selectedComponent };
    tempSelected.componentStyles.color = e.rgb;
    setSelectedComponent(tempSelected); */
    // let tempElemLayers = [...layerCompData];
    if (layerCompData[layerCompData.length - 1]) {
      layerCompData[layerCompData.length - 1].eachDrag = "yes";
    }
  };
  const handleBgColorChange = (e) => {
    let bgColor = e.rgb;
    // setBgColorCanvas(bgColor);
    //setBackgroundColor(bgColor);
    setCanvasDimension({
      ...canvasDimension,
      backgroundColor: bgColor,
      canvasChanged: false,
    });
    appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
  };

  const handleURL = (e) => {
    setURLValue(e.target.value);
  };
  const URLUpdate = () => {
    if (isValidHttpUrl(URLValue)) {
      if (selectedComponent?.componentType === "Image") {
        let updatedValue = { ...selectedComponent };
        updatedValue.componentStyles.background = URLValue;
        setSelectedComponent(updatedValue);
      } else if (layerId.type === "Image") {
        if (URLValue !== "" && checkURL(URLValue))
          imageOnCanvas(
            undefined,
            URLValue,
            20,
            30,
            URLValue.substr(URLValue.lastIndexOf("/") + 1)
          );
      }
    }
  };

  function browseAndSelect(e) {
    let files = e.target.files;
    let fileName = files[0]?.name.replace(/[\(\)']+/g, "").replace(/\s/g, "");

    if (files[0].type !== "image/png" && files[0].type !== "image/jpeg") {
      return;
    }

    const myRenamedFile = new File([files[0]], fileName);

    let reader = new FileReader();
    reader.readAsDataURL(myRenamedFile);

    reader.onload = (e) => {
      const formData = new FormData();
      formData.append("fileName", myRenamedFile);

      browseImages(formData, (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          toast.error(<div className="notificationText">{errorCode}</div>);
        } else {
          imageOnCanvas(undefined, response.data.discription, 20, 30, fileName);
        }
      });
    };
  }

  function handleImage(e) {
    if (selectedComponent?.componentType === "Image") {
      let valueImage = { ...selectedComponent };
      selectedComponent.componentStyles[e.target.name] =
        e.target.checked === true ? "" : "no-repeat";
      valueImage.componentStyles.repeat = e.target.checked;
      setSelectedComponent(valueImage);
      setCheckBoxRepeat(e.target.checked);
    }
  }

  const Delete = () => {
    if (selectedComponent.id) {
      let tempElemLayers = [...layerCompData];
      let filetredLayers = tempElemLayers.filter(
        (layer) => layer.id !== selectedComponent.id
      );
      setLayerCompData(filetredLayers);
      setSelectedComponent({});
    }
  };

  const showComponentType = !hideFlag
    ? selectedComponent?.componentType === "Text"
      ? t("TRANSFORM")
      : selectedComponent?.componentType === "Button"
      ? t("editorRightPanel.propertiesTabcontent.buttonSetting")
      : selectedComponent?.componentType === "Image"
      ? t("editorRightPanel.propertiesTabcontent.imageSetting")
      : selectedComponent?.componentType === "Shape"
      ? t("editorRightPanel.propertiesTabcontent.shapeSetting")
      : selectedComponent?.componentType === "Image" || layerId.type === "Image"
      ? "IMAGE SETTING"
      : t("BANNER SIZE")
    : t("BANNER SIZE");

  // const handleBannerSize = (e) => {
  //   setCanvasUpdated(true);
  //   const bannerSize = e.target.value;
  //   let bannerWidthAandHeight = bannerSize.split("X");
  //   setCanvasDimension({
  //     ...canvasDimension,
  //     width: parseInt(bannerWidthAandHeight[0]),
  //     height: parseInt(bannerWidthAandHeight[1]),
  //   });
  //   appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
  // };

  const handleBannerSize = (e) => {
    setCanvasUpdated(true);

    let bannerSize = e.target.value;
    const match = bannerSize.match(/\d{2,4}x\d{2,4}/);
    let bannerWidthAandHeight = match[0].split("x");
    setCanvasDimension({
      ...canvasDimension,
      width: parseInt(bannerWidthAandHeight[0]),
      height: parseInt(bannerWidthAandHeight[1]),
    });

    appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
  };

  function rgb2hex(rgb) {
    rgb = rgb.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    return rgb && rgb.length === 4
      ? "#" +
          ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2)
      : "";
  }
  const changeTriangleBgColor = () => {
    let rgbaValue = selectedComponent?.componentStyles?.backgroundColor;
    let removeHashTag = rgb2hex(`${rgbaValue}`).substring(1);
    let currentImage = selectedComponent.triImage;
    let currentIndex =
      currentImage.indexOf("fill%3D%22%23") + "fill%3D%22%23".length;
    const endIndex = currentImage.indexOf("%", currentIndex);
    let bgColWord = currentImage.substring(currentIndex, endIndex);
    let replacedBGColor = currentImage.replace(
      `fill%3D%22%23${bgColWord}`,
      `fill%3D%22%23${removeHashTag}`
    );
    if (selectedComponent?.textComponentType?.addContent === "triangleShape") {
      let tempSelected = { ...selectedComponent };
      tempSelected.triImage = replacedBGColor;
      setSelectedComponent(tempSelected);
      let data = [...layerCompData];
      if (layerIndex !== -1 && layerIndex !== undefined) {
        data[layerIndex].triImage = replacedBGColor;
        setLayerCompData(data);
      } else {
        data[data.length - 1].triImage = replacedBGColor;
        setLayerCompData(data);
      }
    }
  };
  const changeTriangleBorderColor = () => {
    let strokeColor = selectedComponent?.componentStyles?.border;
    let extractColor = strokeColor
      .substring(strokeColor.indexOf("(") + 1, strokeColor.length - 1)
      .split(", ");
    let extractedRgb = `rgba(${extractColor[0]},${extractColor[1]},${extractColor[2]},${extractColor[3]})`;
    let removeHashTagForBorder = rgb2hex(`${extractedRgb}`).substring(1);
    let currentImageTriBorderColor = selectedComponent.triImage;
    let currentIndexTriBorderColor =
      currentImageTriBorderColor.indexOf("stroke%3D%22%23") +
      "stroke%3D%22%23".length;
    const endIndexTriBorderColor = currentImageTriBorderColor.indexOf(
      "%",
      currentIndexTriBorderColor
    );
    let strokeColWord = currentImageTriBorderColor.substring(
      currentIndexTriBorderColor,
      endIndexTriBorderColor
    );
    let replacedTriBorderColor = currentImageTriBorderColor.replace(
      `stroke%3D%22%23${strokeColWord}`,
      `stroke%3D%22%23${removeHashTagForBorder}`
    );
    if (selectedComponent?.textComponentType?.addContent === "triangleShape") {
      let tempSelected = { ...selectedComponent };
      tempSelected.triImage = replacedTriBorderColor;
      setSelectedComponent(tempSelected);
      let data = [...layerCompData];
      if (layerIndex !== -1 && layerIndex !== undefined) {
        data[layerIndex].triImage = replacedTriBorderColor;
        setLayerCompData(data);
      } else {
        data[data.length - 1].triImage = replacedTriBorderColor;
        setLayerCompData(data);
      }
    }
  };
  const changeStrokeWidth = (valueBorder) => {
    let strokeColorWid = selectedComponent?.componentStyles?.border;
    let strokeWid = strokeColorWid.substring(0, strokeColorWid.indexOf("p"));
    strokeWid = strokeWid.split(" ").reverse().join(" ");
    let currentImageTriBorderWid = selectedComponent.triImage;
    let currentIndexTriBorderWid =
      currentImageTriBorderWid.indexOf("stroke-width%3D%22") +
      "stroke-width%3D%22".length;
    const endIndexTriBorderWid = currentImageTriBorderWid.indexOf(
      "%",
      currentIndexTriBorderWid
    );
    let word = currentImageTriBorderWid.substring(
      currentIndexTriBorderWid,
      endIndexTriBorderWid
    );
    let replacedStrokeWidTri = currentImageTriBorderWid.replace(
      `stroke-width%3D%22${parseInt(word)}`,
      `stroke-width%3D%22${parseInt(strokeWid)}`
    );

    if (selectedComponent?.textComponentType?.addContent === "triangleShape") {
      let tempSelected = { ...selectedComponent };
      tempSelected.triImage = replacedStrokeWidTri;
      setSelectedComponent(tempSelected);
      let data = [...layerCompData];
      if (layerIndex !== -1 && layerIndex !== undefined) {
        data[layerIndex].triImage = replacedStrokeWidTri;
        setLayerCompData(data);
      } else {
        data[data.length - 1].triImage = replacedStrokeWidTri;
        setLayerCompData(data);
      }
    }
  };
  const changePentgonBgColor = () => {
    let rgbaValuePent = selectedComponent?.componentStyles?.backgroundColor;
    let removeHashTagPent = rgb2hex(`${rgbaValuePent}`).substring(1);
    let currentImagePent = selectedComponent.pentImage;
    let currentIndexPent =
      currentImagePent.indexOf("fill%3D%22%23") + "fill%3D%22%23".length;
    const endIndexPent = currentImagePent.indexOf("%", currentIndexPent);
    let bgColWordPent = currentImagePent.substring(
      currentIndexPent,
      endIndexPent
    );
    let replacedPentagonBGColor = currentImagePent.replace(
      `fill%3D%22%23${bgColWordPent}`,
      `fill%3D%22%23${removeHashTagPent}`
    );
    if (selectedComponent?.textComponentType?.addContent === "polygonShape") {
      let tempSelected = { ...selectedComponent };
      tempSelected.pentImage = replacedPentagonBGColor;
      setSelectedComponent(tempSelected);
      let data = [...layerCompData];
      if (layerIndex !== -1 && layerIndex !== undefined) {
        data[layerIndex].pentImage = replacedPentagonBGColor;
        setLayerCompData(data);
      } else {
        data[data.length - 1].pentImage = replacedPentagonBGColor;
        setLayerCompData(data);
      }
    }
  };
  const changepentagonBorderColor = () => {
    let strokeBordColor = selectedComponent?.componentStyles?.border;
    let extractColorPent = strokeBordColor
      .substring(strokeBordColor.indexOf("(") + 1, strokeBordColor.length - 1)
      .split(", ");
    let extractedRgbPent = `rgba(${extractColorPent[0]},${extractColorPent[1]},${extractColorPent[2]},${extractColorPent[3]})`;
    let removeHashTagForBorder = rgb2hex(`${extractedRgbPent}`).substring(1);
    let currentImagePentBorderColor = selectedComponent.pentImage;
    let currentIndexPentBorderColor =
      currentImagePentBorderColor.indexOf("stroke%3D%22%23") +
      "stroke%3D%22%23".length;
    const endIndexPentBorderColor = currentImagePentBorderColor.indexOf(
      "%",
      currentIndexPentBorderColor
    );
    let strokePentColWord = currentImagePentBorderColor.substring(
      currentIndexPentBorderColor,
      endIndexPentBorderColor
    );
    let replacedPentBorderColor = currentImagePentBorderColor.replace(
      `stroke%3D%22%23${strokePentColWord}`,
      `stroke%3D%22%23${removeHashTagForBorder}`
    );
    if (selectedComponent?.textComponentType?.addContent === "polygonShape") {
      let tempSelected = { ...selectedComponent };
      tempSelected.pentImage = replacedPentBorderColor;
      setSelectedComponent(tempSelected);
      let data = [...layerCompData];
      if (layerIndex !== -1 && layerIndex !== undefined) {
        data[layerIndex].pentImage = replacedPentBorderColor;
        setLayerCompData(data);
      } else {
        data[data.length - 1].pentImage = replacedPentBorderColor;
        setLayerCompData(data);
      }
    }
  };
  const changePentagonStrokeWidth = () => {
    let strokeColorPentWid = selectedComponent?.componentStyles?.border;
    let strokeWidPent = strokeColorPentWid.substring(
      0,
      strokeColorPentWid.indexOf("p")
    );
    let currentImagePolygon = selectedComponent.pentImage;
    let currentIndexPolygon =
      currentImagePolygon.indexOf("stroke-width%3D%22") +
      "stroke-width%3D%22".length;
    const endIndexPolygon = currentImagePolygon.indexOf(
      "%",
      currentIndexPolygon
    );
    let wordPolygon = currentImagePolygon.substring(
      currentIndexPolygon,
      endIndexPolygon
    );
    let convertoNum = (wordPolygon) => +wordPolygon.replace(/[^.\d]/g, "");
    let convertoNum2 = (strokeWidPent) => +strokeWidPent.replace(/[^.\d]/g, "");

    let replacedStrokeWidPolygon = currentImagePolygon.replace(
      `stroke-width%3D%22${convertoNum(wordPolygon)}`,
      `stroke-width%3D%22${convertoNum2(strokeWidPent)}`
    );
    if (selectedComponent?.textComponentType?.addContent === "polygonShape") {
      let tempSelected = { ...selectedComponent };
      tempSelected.pentImage = replacedStrokeWidPolygon;
      setSelectedComponent(tempSelected);
      let data = [...layerCompData];
      if (layerIndex !== -1 && layerIndex !== undefined) {
        data[layerIndex].pentImage = replacedStrokeWidPolygon;
        setLayerCompData(data);
      } else {
        data[data.length - 1].pentImage = replacedStrokeWidPolygon;
        setLayerCompData(data);
      }
    }
  };
  const changeHexagonBgColor = () => {
    let rgbaValueHex = selectedComponent?.componentStyles?.backgroundColor;
    let removeHashTagHex = rgb2hex(`${rgbaValueHex}`).substring(1);
    let currentImageHexBgCol = selectedComponent.hexImage;
    let currentIndexHexBgCol =
      currentImageHexBgCol.indexOf("fill%3D%22%23") + "fill%3D%22%23".length;
    const endIndexHexBgCol = currentImageHexBgCol.indexOf(
      "%",
      currentIndexHexBgCol
    );
    let hexBgColWord = currentImageHexBgCol.substring(
      currentIndexHexBgCol,
      endIndexHexBgCol
    );
    let replacedHexagonBGColor = currentImageHexBgCol.replace(
      `fill%3D%22%23${hexBgColWord}`,
      `fill%3D%22%23${removeHashTagHex}`
    );
    if (selectedComponent?.textComponentType?.addContent === "hexagonShape") {
      let tempSelected = { ...selectedComponent };
      tempSelected.hexImage = replacedHexagonBGColor;
      setSelectedComponent(tempSelected);
      let data = [...layerCompData];
      if (layerIndex !== -1 && layerIndex !== undefined) {
        data[layerIndex].hexImage = replacedHexagonBGColor;
        setLayerCompData(data);
      } else {
        data[data.length - 1].hexImage = replacedHexagonBGColor;
        setLayerCompData(data);
      }
    }
  };
  const changeHexagonBorderColor = () => {
    let strokeColorHexBord = selectedComponent?.componentStyles?.border;
    let extractColorHexBord = strokeColorHexBord
      .substring(
        strokeColorHexBord.indexOf("(") + 1,
        strokeColorHexBord.length - 1
      )
      .split(", ");
    let extractedRgbHexbord = `rgba(${extractColorHexBord[0]},${extractColorHexBord[1]},${extractColorHexBord[2]},${extractColorHexBord[3]})`;
    let removeHashTagForHexBorder = rgb2hex(`${extractedRgbHexbord}`).substring(
      1
    );
    let currentImageHexBordcolor = selectedComponent.hexImage;
    let currentIndexHexBordcolor =
      currentImageHexBordcolor.indexOf("stroke%3D%22%23") +
      "stroke%3D%22%23".length;
    const endIndexHexBordCol = currentImageHexBordcolor.indexOf(
      "%",
      currentIndexHexBordcolor
    );
    let strokeColHexWord = currentImageHexBordcolor.substring(
      currentIndexHexBordcolor,
      endIndexHexBordCol
    );
    let replacedHexBorderColor = currentImageHexBordcolor.replace(
      `stroke%3D%22%23${strokeColHexWord}`,
      `stroke%3D%22%23${removeHashTagForHexBorder}`
    );
    if (selectedComponent?.textComponentType?.addContent === "hexagonShape") {
      let tempSelected = { ...selectedComponent };
      tempSelected.hexImage = replacedHexBorderColor;
      setSelectedComponent(tempSelected);
      let data = [...layerCompData];
      if (layerIndex !== -1 && layerIndex !== undefined) {
        data[layerIndex].hexImage = replacedHexBorderColor;
        setLayerCompData(data);
      } else {
        data[data.length - 1].hexImage = replacedHexBorderColor;
        setLayerCompData(data);
      }
    }
  };
  const changeHexagonStrokeWidth = () => {
    let strokeColorHexWid = selectedComponent?.componentStyles?.border;
    let strokeWidHexCol = strokeColorHexWid.substring(
      0,
      strokeColorHexWid.indexOf("p")
    );
    let currentImageHexStrokeWid = selectedComponent.hexImage;
    let currentIndexHexStrokeWid =
      currentImageHexStrokeWid.indexOf("stroke-width%3D%22") +
      "stroke-width%3D%22".length;
    const endIndexHexStrokeWid = currentImageHexStrokeWid.indexOf(
      "%",
      currentIndexHexStrokeWid
    );
    let hexStrokeword = currentImageHexStrokeWid.substring(
      currentIndexHexStrokeWid,
      endIndexHexStrokeWid
    );
    let convertoNum = (hexStrokeword) => +hexStrokeword.replace(/[^.\d]/g, "");
    let convertoNum2 = (strokeWidHexCol) =>
      +strokeWidHexCol.replace(/[^.\d]/g, "");
    let replacedStrokeWidHex = currentImageHexStrokeWid.replace(
      `stroke-width%3D%22${convertoNum(hexStrokeword)}`,
      `stroke-width%3D%22${convertoNum2(strokeWidHexCol)}`
    );
    if (selectedComponent?.textComponentType?.addContent === "hexagonShape") {
      let tempSelected = { ...selectedComponent };
      tempSelected.hexImage = replacedStrokeWidHex;
      setSelectedComponent(tempSelected);
      let data = [...layerCompData];
      if (layerIndex !== -1 && layerIndex !== undefined) {
        data[layerIndex].hexImage = replacedStrokeWidHex;
        setLayerCompData(data);
      } else {
        data[data.length - 1].hexImage = replacedStrokeWidHex;
        setLayerCompData(data);
      }
    }
  };
  const ENUM_STATES = {
    Text: (
      <div>
        <Transform
          isAspectRatioLocked={isAspectRatioLocked}
          setIsAspectRatioLocked={setIsAspectRatioLocked}
          showComponentType={showComponentType}
          styles={styles}
          bgColorCanvas={bgColorCanvas}
          handleDimensions={handleDimensions}
          handleBannerSize={handleBannerSize}
          canvasDimension={canvasDimension}
          bannerSizes={bannerSizes}
          handleClickSwatchBgColor={handleClickSwatchBgColor}
          handleBgColorChange={handleBgColorChange}
          refBgColor={refBgColor}
          displayColorPickerBgColor={displayColorPickerBgColor}
          updatedValue={updatedValue}
          dimensionsData={dimensionsData}
          checkedAspect={checkedAspect}
          ToggleAspectLock={ToggleAspectLock}
          selectedComponent={selectedComponent}
          setSelectedComponent={setSelectedComponent}
          handleBlur={handleBlur}
          layerCompData={layerCompData}
          setUpdatedValue={setUpdatedValue}
          toggleAnimatedBanner={toggleAnimatedBanner}
        ></Transform>
        <TextAndButton
          selectedFontId={selectedFontId}
          setSelectedFontId={setSelectedFontId}
          updateSelctedFontstyle={updateSelctedFontstyle}
          createFroalaInstance={createFroalaInstance}
          froalaIndex={froalaIndex}
          fontStylesonClick={fontStylesonClick}
          fontAlignmentonClick={fontAlignmentonClick}
          listOnclick={listOnclick}
          setLayerCompData={setLayerCompData}
          handleTextPropsOnchange={handleTextPropsOnchange}
          localRightProps={localRightProps}
          setLocalRightProps={setLocalRightProps}
          selectedComponent={selectedComponent}
          setSelectedComponent={setSelectedComponent}
          handleChange={handleChange}
          expanded={expanded}
          textColor={textColor}
          styles={styles}
          handleTextColorChange={handleTextColorChange}
          ColorPicker={ColorPicker}
          handleFontFamily={handleFontFamily}
          refFontColorISI={refFontColorISI}
          colorPickerFontColorISI={colorPickerFontColorISI}
          colorPickerTextBgColorISI={colorPickerTextBgColorISI}
          refTextBgColorISI={refTextBgColorISI}
          handleClickFontColorISI={handleClickFontColorISI}
          handleClicktextBgColorISI={handleClicktextBgColorISI}
          refFontColor={refFontColor}
          displayColorPickerFontColor={displayColorPickerFontColor}
          fontFamily={fontFamily}
          fontFamilyItems={fontFamilyItems}
          fontSize={fontSize}
          fontSizeItems={fontSizeItems}
          fontWeight={fontWeight}
          fontWeightItems={fontWeightItems}
          AV={AV}
          characterSpacing={characterSpacing}
          letterSpacingItems={letterSpacingItems}
          LineHeight={LineHeight}
          handleClickSwatchFontCOlor={handleClickSwatchFontCOlor}
          lineHeightProp={lineHeightProp}
          lineHeightItems={lineHeightItems}
          froalaFontAlignments={froalaFontAlignments}
          froalaNumberList={froalaNumberList}
          froalaFontStyles={froalaFontStyles}
          setDisplayColorPickerFontColor={setDisplayColorPickerFontColor}
          layerCompData={layerCompData}
          toggleScrollbar={toggleScrollbar}
          handleTextBgColor={handleTextBgColor}
          refTextBg={refTextBg}
          displaycolorpickerTextBg={displaycolorpickerTextBg}
          setFontSize={setFontSize}
          setLineHeightProp={setLineHeightProp}
          setCharacterSpacing={setCharacterSpacing}
          // selectedId={selectedId}
          // setSelectedId={setSelectedId}
        />
        {selectedComponent.componentStyles?.toggleScroll && (
          <ScrollBarArrow
            layerCompData={layerCompData}
            setLayerCompData={setLayerCompData}
            handleChange={handleChange}
            expanded={expanded}
            refScrollColor={refScrollColor}
            displayColorPickerScrollColor={displayColorPickerScrollColor}
            setDisplayColorPickerScrollColor={setDisplayColorPickerScrollColor}
            ColorPicker={ColorPicker}
            styles={styles}
            handleClickSwatchScroll={handleClickSwatchScroll}
            scrollColor={scrollColor}
            scrollbarArrow={scrollbarArrow}
            setScrollbarArrow={setScrollbarArrow}
            setScrollDimensionData={setScrollDimensionData}
            scrollDimensionData={scrollDimensionData}
            selectedComponent={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refFontColorISI={refFontColorISI}
            colorPickerFontColorISI={colorPickerFontColorISI}
            colorPickerTextBgColorISI={colorPickerTextBgColorISI}
            refTextBgColorISI={refTextBgColorISI}
            handleClickFontColorISI={handleClickFontColorISI}
            handleClicktextBgColorISI={handleClicktextBgColorISI}
            locked={locked}
            scrollBarExpand={scrollBarExpand}
            froalaIndex={froalaIndex}
          />
        )}
      </div>
    ),
    Button: (
      <>
        <Transform
          isAspectRatioLocked={isAspectRatioLocked}
          setIsAspectRatioLocked={setIsAspectRatioLocked}
          showComponentType={showComponentType}
          styles={styles}
          bgColorCanvas={bgColorCanvas}
          handleDimensions={handleDimensions}
          handleBannerSize={handleBannerSize}
          canvasDimension={canvasDimension}
          bannerSizes={bannerSizes}
          handleClickSwatchBgColor={handleClickSwatchBgColor}
          handleBgColorChange={handleBgColorChange}
          refBgColor={refBgColor}
          displayColorPickerBgColor={displayColorPickerBgColor}
          updatedValue={updatedValue}
          dimensionsData={dimensionsData}
          checkedAspect={checkedAspect}
          ToggleAspectLock={ToggleAspectLock}
          selectedComponent={selectedComponent}
          setSelectedComponent={setSelectedComponent}
          layerCompData={layerCompData}
          setUpdatedValue={setUpdatedValue}
          toggleAnimatedBanner={toggleAnimatedBanner}
        ></Transform>
        <ButtonAndShape
          commonPadding={commonPadding}
          handleChange={handleChange}
          expanded={expanded}
          selectedComponent={selectedComponent}
          styles={styles}
          handleClickSwatch={handleClickSwatch}
          displayColorPicker={displayColorPicker}
          ColorPicker={ColorPicker}
          handleClose={handleClose}
          buttonColor={buttonColor}
          setDisplayColorPicker={setDisplayColorPicker}
          handleClickSwatchBorder={handleClickSwatchBorder}
          refBorder={refBorder}
          borderColor={borderColor}
          setDisplayColorPickerBorder={setDisplayColorPickerBorder}
          changeBorderStyle={changeBorderStyle}
          borderStyle={borderStyle}
          borderStyles={borderStyles}
          borderWidth={borderWidth}
          setBorderWidth={setBorderWidth}
          changeBorderWidth={changeBorderWidth}
          displayColorPickerBorder={displayColorPickerBorder}
          refButtonColor={refButtonColor}
          paddingTRBL={paddingTRBL}
          setPaddingTRBL={setPaddingTRBL}
          radiusTRBL={radiusTRBL}
          setRadiusTRBL={setRadiusTRBL}
          //  commonPadding={commonPadding}
          setCommonPadding={setCommonPadding}
          commonRadius={commonRadius}
          setCommonRadius={setCommonRadius}
          handlePadding={handlePadding}
          handleRadius={handleRadius}
          changeRadius={changeRadius}
          changePadding={changePadding}
          targetLink={targetLink}
          changeTargetLink={changeTargetLink}
          cornerCount={cornerCount}
          setCornerCount={setCornerCount}
          handleCornerCount={handleCornerCount}
          layerCompData={layerCompData}
        ></ButtonAndShape>
        <TextAndButton
          selectedFontId={selectedFontId}
          setSelectedFontId={setSelectedFontId}
          updateSelctedFontstyle={updateSelctedFontstyle}
          createFroalaInstance={createFroalaInstance}
          froalaIndex={froalaIndex}
          fontStylesonClick={fontStylesonClick}
          fontAlignmentonClick={fontAlignmentonClick}
          listOnclick={listOnclick}
          setLayerCompData={setLayerCompData}
          handleTextPropsOnchange={handleTextPropsOnchange}
          localRightProps={localRightProps}
          setLocalRightProps={setLocalRightProps}
          selectedComponent={selectedComponent}
          setSelectedComponent={setSelectedComponent}
          handleChange={handleChange}
          expanded={expanded}
          textColor={textColor}
          styles={styles}
          handleTextColorChange={handleTextColorChange}
          ColorPicker={ColorPicker}
          handleFontFamily={handleFontFamily}
          refFontColorISI={refFontColorISI}
          colorPickerFontColorISI={colorPickerFontColorISI}
          colorPickerTextBgColorISI={colorPickerTextBgColorISI}
          refTextBgColorISI={refTextBgColorISI}
          handleClickFontColorISI={handleClickFontColorISI}
          handleClicktextBgColorISI={handleClicktextBgColorISI}
          refFontColor={refFontColor}
          displayColorPickerFontColor={displayColorPickerFontColor}
          fontFamily={fontFamily}
          fontFamilyItems={fontFamilyItems}
          fontSize={fontSize}
          fontSizeItems={fontSizeItems}
          fontWeight={fontWeight}
          fontWeightItems={fontWeightItems}
          AV={AV}
          characterSpacing={characterSpacing}
          letterSpacingItems={letterSpacingItems}
          LineHeight={LineHeight}
          handleClickSwatchFontCOlor={handleClickSwatchFontCOlor}
          lineHeightProp={lineHeightProp}
          lineHeightItems={lineHeightItems}
          froalaFontAlignments={froalaFontAlignments}
          froalaNumberList={froalaNumberList}
          froalaFontStyles={froalaFontStyles}
          borderColor={borderColor}
          setDisplayColorPickerBorder={setDisplayColorPickerBorder}
          setDisplayColorPickerFontColor={setDisplayColorPickerFontColor}
          layerCompData={layerCompData}
          setCheckedScroll={setCheckedScroll}
          checkedScroll={checkedScroll}
          handleTextBgColor={handleTextBgColor}
          setFontSize={setFontSize}
          //  fontSizeConfig={fontSizeConfig}
          setLineHeightProp={setLineHeightProp}
          setCharacterSpacing={setCharacterSpacing}
        />
      </>
    ),
    Image: (
      <div>
        <Transform
          isAspectRatioLocked={isAspectRatioLocked}
          setIsAspectRatioLocked={setIsAspectRatioLocked}
          showComponentType={showComponentType}
          styles={styles}
          bgColorCanvas={bgColorCanvas}
          handleDimensions={handleDimensions}
          handleBannerSize={handleBannerSize}
          canvasDimension={canvasDimension}
          bannerSizes={bannerSizes}
          handleClickSwatchBgColor={handleClickSwatchBgColor}
          handleBgColorChange={handleBgColorChange}
          refBgColor={refBgColor}
          displayColorPickerBgColor={displayColorPickerBgColor}
          updatedValue={updatedValue}
          dimensionsData={dimensionsData}
          checkedAspect={checkedAspect}
          ToggleAspectLock={ToggleAspectLock}
          selectedComponent={selectedComponent}
          setSelectedComponent={setSelectedComponent}
          handleBlur={handleBlur}
          layerCompData={layerCompData}
          setUpdatedValue={setUpdatedValue}
          toggleAnimatedBanner={toggleAnimatedBanner}
        ></Transform>
        <ImageBorder
          setSelectedComponent={setSelectedComponent}
          borderWidth={borderWidth}
          setBorderWidth={setBorderWidth}
          handleChange={handleChange}
          expanded={expanded}
          selectedComponent={selectedComponent}
          styles={styles}
          handleClickSwatch={handleClickSwatch}
          displayColorPicker={displayColorPicker}
          ColorPicker={ColorPicker}
          handleClose={handleClose}
          buttonColor={buttonColor}
          setDisplayColorPicker={setDisplayColorPicker}
          handleClickSwatchBorder={handleClickSwatchBorder}
          refBorder={refBorder}
          borderColor={borderColor}
          setDisplayColorPickerBorder={setDisplayColorPickerBorder}
          changeBorderStyle={changeBorderStyle}
          borderStyle={borderStyle}
          borderStyles={borderStyles}
          changeBorderWidth={changeBorderWidth}
          displayColorPickerBorder={displayColorPickerBorder}
          refButtonColor={refButtonColor}
          paddingTRBL={paddingTRBL}
          setPaddingTRBL={setPaddingTRBL}
          radiusTRBL={radiusTRBL}
          setRadiusTRBL={setRadiusTRBL}
          commonPadding={commonPadding}
          setCommonPadding={setCommonPadding}
          commonRadius={commonRadius}
          setCommonRadius={setCommonRadius}
          handlePadding={handlePadding}
          handleRadius={handleRadius}
          changeRadius={changeRadius}
          changePadding={changePadding}
          layerCompData={layerCompData}
        ></ImageBorder>
        <ImageComp
          handleChange={handleChange}
          expanded={expanded}
          layerId={layerId}
          selectedComponent={selectedComponent}
          Delete={Delete}
          ImageUpload={ImageUpload}
          handleImage={handleImage}
          URLValue={URLValue}
          handleURL={handleURL}
          browseAndSelect={browseAndSelect}
          targetLink={targetLink}
          changeTargetLink={changeTargetLink}
          checkBoxRepeat={checkBoxRepeat}
          URLUpdate={URLUpdate}
        />
      </div>
    ),
    Shape: (
      <>
        <Transform
          isAspectRatioLocked={isAspectRatioLocked}
          setIsAspectRatioLocked={setIsAspectRatioLocked}
          showComponentType={showComponentType}
          styles={styles}
          bgColorCanvas={bgColorCanvas}
          handleDimensions={handleDimensions}
          handleBannerSize={handleBannerSize}
          canvasDimension={canvasDimension}
          bannerSizes={bannerSizes}
          handleClickSwatchBgColor={handleClickSwatchBgColor}
          handleBgColorChange={handleBgColorChange}
          refBgColor={refBgColor}
          displayColorPickerBgColor={displayColorPickerBgColor}
          updatedValue={updatedValue}
          dimensionsData={dimensionsData}
          ToggleAspectLock={ToggleAspectLock}
          checkedAspect={checkedAspect}
          selectedComponent={selectedComponent}
          setSelectedComponent={setSelectedComponent}
          handleBlur={handleBlur}
          layerCompData={layerCompData}
          setUpdatedValue={setUpdatedValue}
          toggleAnimatedBanner={toggleAnimatedBanner}
        ></Transform>
        <ButtonAndShape
          handleChange={handleChange}
          expanded={expanded}
          selectedComponent={selectedComponent}
          styles={styles}
          handleClickSwatch={handleClickSwatch}
          displayColorPicker={displayColorPicker}
          ColorPicker={ColorPicker}
          handleClose={handleClose}
          buttonColor={buttonColor}
          setDisplayColorPicker={setDisplayColorPicker}
          handleClickSwatchBorder={handleClickSwatchBorder}
          refBorder={refBorder}
          borderColor={borderColor}
          setDisplayColorPickerBorder={setDisplayColorPickerBorder}
          changeBorderStyle={changeBorderStyle}
          borderStyle={borderStyle}
          borderStyles={borderStyles}
          borderWidth={borderWidth}
          setBorderWidth={setBorderWidth}
          changeBorderWidth={changeBorderWidth}
          displayColorPickerBorder={displayColorPickerBorder}
          refButtonColor={refButtonColor}
          paddingTRBL={paddingTRBL}
          setPaddingTRBL={setPaddingTRBL}
          radiusTRBL={radiusTRBL}
          setRadiusTRBL={setRadiusTRBL}
          commonPadding={commonPadding}
          setCommonPadding={setCommonPadding}
          commonRadius={commonRadius}
          setCommonRadius={setCommonRadius}
          handlePadding={handlePadding}
          handleRadius={handleRadius}
          changeRadius={changeRadius}
          changePadding={changePadding}
          cornerCount={cornerCount}
          setCornerCount={setCornerCount}
          handleCornerCount={handleCornerCount}
          layerCompData={layerCompData}
        ></ButtonAndShape>
      </>
    ),
    default: (
      <GlobalSetting
        multiSelectedComps={multiSelectedComps}
        isAspectRatioLocked={isAspectRatioLocked}
        setIsAspectRatioLocked={setIsAspectRatioLocked}
        handleChange={handleChange}
        expanded={expanded}
        showAssettype={showAssettype}
        showBannertype={showBannertype}
        genericInfo={genericInfo}
        setGenericInfo={setGenericInfo}
        showComponentType={showComponentType}
        styles={styles}
        bgColorCanvas={canvasDimension?.backgroundColor}
        handleDimensions={handleDimensions}
        handleBannerSize={handleBannerSize}
        canvasDimension={canvasDimension}
        setCanvasDimension={setCanvasDimension}
        bannerSizes={bannerSizes}
        handleClickSwatchBgColor={handleClickSwatchBgColor}
        handleBgColorChange={handleBgColorChange}
        refBgColor={refBgColor}
        displayColorPickerBgColor={displayColorPickerBgColor}
        setDisplayColorPickerBgColor={setDisplayColorPickerBgColor}
        setCanvasUpdated={setCanvasUpdated}
        toggleAnimatedBanner={toggleAnimatedBanner}
        setOpenTimelinePopup={setOpenTimelinePopup}
        playPause={playPause}
        updatedValue={updatedValue}
        dimensionsData={dimensionsData}
        checkedAspect={checkedAspect}
        ToggleAspectLock={ToggleAspectLock}
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        handleBlur={handleBlur}
        layerCompData={layerCompData}
        setUpdatedValue={setUpdatedValue}
      />
    ),
  };
  const colorPickerForComponents = displayColorPickerFontColor
    ? textColor
    : displayColorPicker
    ? buttonColor
    : displayColorPickerBorder
    ? borderColor
    : displayColorPickerScrollColor
    ? scrollColor
    : displayColorPickerBgColor
    ? canvasDimension.backgroundColor
    : displaycolorpickerTextBg
    ? textBgColor
    : colorPickerFontColorISI
    ? fontColorISI
    : colorPickerTextBgColorISI
    ? textBgColorISI
    : "";
  const colorReferencesForComp = displayColorPickerFontColor
    ? refFontColor
    : displayColorPicker
    ? refButtonColor
    : displayColorPickerBorder
    ? refBorder
    : displayColorPickerScrollColor
    ? refScrollColor
    : displayColorPickerBgColor
    ? refBgColor
    : displaycolorpickerTextBg
    ? refTextBg
    : colorPickerTextBgColorISI
    ? refTextBgColorISI
    : colorPickerFontColorISI
    ? refFontColorISI
    : "";
  const activeTab = isInfoPanel === true ? 1 : 0;

  const showComponentTypeText = () => {
    if (showComponentType === "TEXT" || showComponentType === "BUTTON ") {
      return "TRANSFORM";
    } else {
      return showComponentType;
    }
  };
  return (
    <>
      <div
        className="rightPanelEditor"
        style={{ pointerEvents: !playPause.animationCompleted && "none" }}
      >
        <div className="rightPanelEditorscreen">
          {/* <div
            // value={propertyTabValue}
            // variant="fullWidth"
            // onChange={handlePropertyTab}
            className="antTabsRightContainer d-flex"
          ></div> */}
          {!isInfoPanel && (
            <>
              <div className="propertyInfoPanel">
                {t("editorRightPanel.tabs.properties")}{" "}
              </div>
              <div className="properties-tabpane">
                {" "}
                {showComponentType === "BANNER SIZE" ? (
                  ""
                ) : (
                  <div className="d-flex">
                    <div className="textSetting d-flex">
                      {/* {showComponentType === "Image" ||
                    showComponentType === "Shape"
                      ? showComponentType
                      : "TRANSFORM"} */}
                      {showComponentTypeText()}
                      {/* <img
                      src={InfoIcon}
                      alt="Infoicon"
                      className="image-fluid pl-1"
                    /> */}
                    </div>
                    <div className="aspectLock d-flex">
                      {t("editorRightPanel.propertiesTabcontent.aspectLock")}
                      <AntSwitch
                        checked={isAspectRatioLocked}
                        onChange={(e) => {
                          ToggleAspectLock(e);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="rhsScroll">
                  {!hideFlag &&
                    layerCompData &&
                    ENUM_STATES[selectedComponent?.componentType]}
                  {/* {!hideFlag &&
                    layerId.type === "Image" &&
                    ENUM_STATES[layerId.type]} */}

                  {!hideFlag &&
                  (selectedComponent?.componentType === "Text" ||
                    selectedComponent?.componentType === "Button" ||
                    selectedComponent?.componentType === "Image" ||
                    selectedComponent?.componentType === "Shape") ? (
                    <div>
                      <div className="textTransform"></div>

                      {(selectedComponent?.componentType === "Button" ||
                        selectedComponent?.componentType === "Shape") && <></>}
                      {(selectedComponent?.componentType === "Text" ||
                        selectedComponent?.componentType === "Button") && <></>}
                      <>
                        {/*  <hr className="hrtag" /> */}
                        {selectedComponent?.componentType !== "Shape" && (
                          <div className="accordion2 customAccordIcons1">
                            <Accordion
                              // expanded={expanded === "panel4"}
                              // className="accordion"
                              onChange={handleChange("panel4")}
                            >
                              <AccordionSummary
                                expandIcon={
                                  expanded.panel4 === true ? (
                                    <img
                                      src={DownwardArrow}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={SideArrow}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  )
                                }
                                aria-controls="panel4a-content"
                                id="panel4a-header"
                              >
                                <Typography className="accord2-summary">
                                  <div className="textProps">
                                    <div className="text">
                                      {t(
                                        "editorRightPanel.propertiesTabcontent.link"
                                      )}
                                      {/* <img
                                        src={InfoIcon}
                                        alt=""
                                        className="img-fluid pl-1 infoIcon2"
                                      /> */}
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionSummary>

                              <AccordionDetails>
                                <div>
                                  <TextField
                                    // element={TextField}
                                    // debounceTimeout={500}
                                    disabled={
                                      locked || canvasDimension.checkGloballink
                                    }
                                    className="custonScrollBar textFieldCustom3 linkButton"
                                    id="standard-basic"
                                    defaultValue="https://"
                                    value={targetLink}
                                    onChange={(e) => changeTargetLink(e)}
                                    onBlur={(e) => changeTargetLink(e, "blur")}
                                    //  onBlur={changeTargetLinkOnBlur}
                                    inputProps={{
                                      style: {
                                        width: "100%",
                                        marginTop: "-14px",
                                        marginBottom: "-15px",
                                        fontSize: "12px",
                                        color: "#000000",
                                        fontFamily: "Open Sans",
                                      },
                                    }}
                                  />
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <hr className="hr" />
                          </div>
                        )}
                      </>
                    </div>
                  ) : (
                    (hideFlag ||
                      selectedComponent?.componentType !== "default") &&
                    ENUM_STATES["default"]
                  )}
                </div>
              </div>
            </>
          )}
          {isInfoPanel && (
            <>
              <div className="propertyInfoPanel">{t("DETAILS")} </div>
              <div className="info-tabpane">
                <InfotabSection
                  bannerId={bannerId}
                  setMLRstatus={setMLRstatus}
                  MLRstatus={MLRstatus}
                  damsID={damsID}
                  showAssettype={showAssettype}
                  showBannertype={showBannertype}
                  getEditSaveAssets={getEditSaveAssets}
                  setShowBannerName={setShowBannerName}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        {!locked &&
        (displayColorPickerFontColor ||
          displayColorPicker ||
          displayColorPickerBorder ||
          displayColorPickerScrollColor ||
          displayColorPickerBgColor ||
          displaycolorpickerTextBg ||
          colorPickerFontColorISI ||
          colorPickerTextBgColorISI) ? (
          <div ref={colorReferencesForComp}>
            <ColorPicker
              id="textColor"
              hideColorPicker={hideColorPicker}
              handleColorChange={handleTextColorChange}
              name="textColor"
              color={colorPickerForComponents || ""}
              displayColorPicker={displayColorPicker}
              displayColorPickerBgColor={displayColorPickerBgColor}
              displayColorPickerBorder={displayColorPickerBorder}
              displayColorPickerFontColor={displayColorPickerFontColor}
              displayColorPickerScrollColor={displayColorPickerScrollColor}
              displaycolorpickerTextBg={displaycolorpickerTextBg}
              colorPickerTextBgColorISI={colorPickerTextBgColorISI}
              colorPickerFontColorISI={colorPickerFontColorISI}
              collectColorOnChangeComplete={collectColorOnChangeComplete}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default RightPanel;
