import React, { useState } from "react";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBack";

import ClaimAccordion from "./ClaimAccordion";
import ModuleTextElementAccordion from "./ModuleTextAccordion";
import AssetAccordion from "./AssetAccordion";
import "./VeevaCardDetails.scss";

// import { useTranslation } from "react-i18next";

function CradDataAccordions(props) {
  let claims = props?.cardDetails?.data?.claims;
  let moduleTextElement = props?.cardDetails?.data?.moduleTextElement;
  let assets = props?.cardDetails?.data?.assets;
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const assetId = props.cardDetails.data.assetId;
  let counter = 0;

  return (
    <div className="cardDetailsTab2 custonScrollBar">
      <ClaimAccordion
        props={props}
        assetId={assetId}
        expanded={expanded}
        handleChange={handleChange}
        claims={claims}
        sendAtomDetails2Canvas={props.sendAtomDetails2Canvas}
      />
      <ModuleTextElementAccordion
        props={props}
        assetId={assetId}
        expanded={expanded}
        handleChange={handleChange}
        moduleTextElement={moduleTextElement}
        sendAtomDetails2Canvas={props.sendAtomDetails2Canvas}
      />
      <AssetAccordion
        props={props}
        assetId={assetId}
        expanded={expanded}
        handleChange={handleChange}
        assets={assets}
        sendAtomDetails2Canvas={props.sendAtomDetails2Canvas}
      />
    </div>
  );
}

export default CradDataAccordions;
