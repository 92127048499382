import React, { useState, useEffect, useContext, useRef } from "react";
import {
  TextField,
  Divider,
  Button,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import DynCards from "./DynCards";
import { FormStyle } from "../../styles/pages/ContentStudio/WithStylesObjs";
import {
  getCoreClaimCategories,
  searchModules,
  getRecentlyViewed,
  getCoreClaimCategoriesforVeeva,
} from "../../services/apis";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import "../../styles/pages/ContentStudio/DynDialogue.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DropDownWithLabel from "../../components/DropDownWithLabel.js";
import { toast } from "react-toastify";
import ValueContext from "../../ContextAPi/appContext";
import { useTranslation } from "react-i18next";
import { ControlCameraOutlined } from "@material-ui/icons";

import CrossIcon from "../../images/CrossIcon.svg";

import CircularProgressBar from "../../Uitls/CircularProgressBar";
function ModalForms(props) {
  const initialFvalues = {
    moduleNameArray: "",
    assetNameArray: "",
    brandName: "",
    claimCategoryArray: "",
    coreClaimArray: "",
    cardTitle: [],
    errors: {},
    canReset: false,
  };
  const { t } = useTranslation();
  const {
    classes,
    cautionBackbtn,
    setCautionBackbtn,
    selectedCardCaution,
    setSelectedCardCaution,
    linkedModulesType,
    values,
    setValues,
    backButtonClicked,
    setBackButtonClicked,
  } = props;
  const appData = useContext(ValueContext); //uncomment
  const [prevSelectedCards, setPrevSelectedCards] = useState([]);
  //   const appData = {genericInfo:{ //comment  (for esting on refresh)
  // brand: "Lynparza",
  // campaign: "Lynparza: Quality of Life (QoL)",
  // channelType: "Banner",
  // market: "US"}}

  const childRef = useRef();
  const [resultHeader, setResultHeader] = useState({
    cardsCount: 0,
    searched: false,
    // resultTitle: (function(){  var count = this.cardsCount;
    //   return `Recently Viewed(${count})`})()
    resultTitle: `Recently Viewed(0)`,
  });

  //const [values, setValues] = useState(initialFvalues);
  const [coreClaimCategories, setcoreClaimCategories] = useState([]);
  const [catList, setCatList] = useState([]);

  const [recentlyViewed, setgetRecentlyViewed] = useState([]);

  const [isloading, setIsloading] = useState(false);

  // const [formDataRequest, setFormDataRequest] = useState({
  //   brandName: "",
  //   moduleNameArray: [],
  //   assetNameArray: [],
  //   claimCategoryArray: [],
  //   coreClaimArray: [],
  // });

  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
      canReset: true,
    });
  };

  const handleDropSelect = () => {
    if (values.claimCategoryArray === "") {
      const errCoponent = errComponentToRender(601);
      categoriesErr(errCoponent);
    }
  };
  const categoriesErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  useEffect(() => {
    // if( props.brandId && (!coreClaimCategories || coreClaimCategories.length <= 0)){
    // fetchcoreClaimCategories("b7145024-0974-11eb-adc1-0242ac120002")  //(props.brandId);
    fetchcoreClaimCategories(appData.brandId); //(props.brandId);
    if (props.responseData.length < 0) getRecentlyViewedModules();

    // let intialValues = {
    //   moduleNameArray: "",
    //   assetNameArray: "",
    //   brandName: "",`
    //   claimCategoryArray: "",
    //   coreClaimArray: "",
    //   cardTitle: [],
    //   errors: {},
    //   canReset: true,
    // };
    // if (appData?.genericInfo?.searchedFields) {
    //   intialValues.brandName = appData?.genericInfo?.searchedFields.brandName;
    //   intialValues.moduleNameArray =
    //     appData?.genericInfo?.searchedFields.moduleNameArray;

    //   setValues(intialValues);
    //   handleSearchSubmit();
    // }

    // props.setSearchORRecent(false)

    // }
  }, []);

  useEffect(() => {
    if (props.responseData.length > 0 && !resultHeader.searched)
      setResultHeader({
        cardsCount: props.responseData.length,
        searched: true,
        resultTitle: `Search Results (${props.responseData.length})`,
      });
  }, [props.responseData]);

  const fetchcoreClaimCategories = (brandId) => {
    var catAry = [];

    if (linkedModulesType === "nextModule") {
      getCoreClaimCategories((response, err, errorCode) => {
        if (err) {
          const errCoponent = errComponentToRender(errorCode);
          console.log(err);
          //setShowErrorMsg(errCoponent);
          categoriesErr(errCoponent);
        } else {
          catAry = response.data.map((option) => option.claimCategory);
          setcoreClaimCategories(response.data);
          setCatList(catAry);
          //coreclaimcategories dropdown object--todo
        }
      }, brandId);
    } else {
      getCoreClaimCategoriesforVeeva((response, err, errorCode) => {
        if (err) {
          const errVeevaCoponent = errComponentToRender(errorCode);
          console.log(err);
          categoriesErr(errVeevaCoponent);
        } else {
          catAry = response.data.map((option) => option.label);
          setcoreClaimCategories(response.data);
          setCatList(catAry);
        }
      });
    }
  };

  const getRecentlyViewedModules = () => {
    setLoading(true);
    getRecentlyViewed("Module", (response, err, errCode) => {
      setLoading(false);
      if (err) {
        const errCoponent = errComponentToRender(errCode);
      } else {
        //parse and save
        const data = response.data;
        setResultHeader({
          cardsCount: response.data.length,
          searched: false,
          resultTitle: `Recently Viewed (${response.data.length})`,
        });
        if (data && data.length > 0) {
          setgetRecentlyViewed(data);

          appData.setCardData({
            cards: response.data,
            search: "recent",
          });
        }
      }
    });
  };
  const getCoreClaims = () => {
    var claims = [];
    if (values.claimCategoryArray) {
      coreClaimCategories.forEach((coreClaimcategory) => {
        if (coreClaimcategory.claimCategory === values.claimCategoryArray) {
          // claims = coreClaimcategory.claims.length >0 ? coreClaimcategory.claims : [{claim:"Core Claim - Not Applicable", id:"0"}];
          claims =
            coreClaimcategory.claims.length > 0
              ? coreClaimcategory.claims.map((claim) => claim.claim)
              : ["Core Claim - Not Applicable"];
        }
      });
    }

    return claims;
  };

  const handleSearchSubmit = (origin, searchedValues) => {
    let localValues = { ...values };

    if (origin !== "useEffect") setCautionBackbtn(false);

    props.setToggleCards([]);
    props.setCantExistCards([]);
    props.setConflictsNumber(0);

    if (
      localValues.moduleNameArray !== "" ||
      localValues.assetNameArray !== "" ||
      localValues.coreClaimArray !== "" ||
      localValues.claimCategoryArray !== ""
    ) {
      // if (!props.veevaModulesLinked)
      // appData.updateGenericInfo({
      //   ...appData.genericInfo,
      //   linkedData: { ...appData.genericInfo.linkedData },
      // });
      if (origin !== "useEffect") {
        props.setActiveCard({ moduleId: [] });
      }
      var formDataObj =
        linkedModulesType === "nextModule"
          ? {
              brandName: "",
              moduleNameArray: "", //[],
              assetNameArray: "", //[],
              claimCategoryArray: "", // [],
              coreClaimArray: "", // [],
            }
          : {
              moduleNameArray: "",
              claimCategoryArray: "",
              brandName: "",
            };
      if (linkedModulesType === "nextModule") {
        // if (!Array.isArray(localValues["moduleNameArray"])) {
        for (let x in localValues) {
          for (let y in formDataObj) {
            if (x === y) {
              switch (x) {
                case "brandName":
                  formDataObj[y] = appData?.genericInfo?.brand;
                  break;
                case "coreClaimArray":
                  formDataObj[y] = localValues[x]
                    ? Array.isArray(localValues[x])
                      ? localValues[x]
                      : [localValues[x]]
                    : [];
                  break;
                case "claimCategoryArray":
                  formDataObj[y] = localValues[x]
                    ? Array.isArray(localValues[x])
                      ? localValues[x]
                      : [localValues[x]]
                    : [];
                  break;
                default:
                  formDataObj[y] = localValues[x]
                    ? Array.isArray(localValues[x])
                      ? localValues[x]
                      : localValues[x].split(",")
                    : [];
                  break;
              }
            }
          }
        }
      } else {
        for (let x in localValues) {
          for (let y in formDataObj) {
            if (x === y) {
              formDataObj[y] =
                x === "moduleNameArray"
                  ? localValues[x]
                  : localValues[x]
                  ? localValues[x].split(",")
                  : [];
            }
          }
        }

        formDataObj.claimCategory = formDataObj.claimCategoryArray;
        delete formDataObj.claimCategoryArray;
        if (formDataObj.claimCategory[0] === "Efficacy")
          formDataObj.claimCategory[0] = "efficacy__c";
        else if (formDataObj.claimCategory[0] === "Safety")
          formDataObj.claimCategory[0] = "safety__c";

        formDataObj.name = formDataObj.moduleNameArray;
        delete formDataObj.moduleNameArray;

        formDataObj.campaign =
          appData?.genericInfo !== undefined
            ? appData?.genericInfo?.campaign
            : "";
        formDataObj.brandName =
          appData?.genericInfo !== undefined ? appData?.genericInfo?.brand : "";
      }
      if (childRef.current) childRef.current.checkSearchFlag();
      setLoading(true);
      searchModules(
        JSON.stringify(formDataObj),

        async (response, err, errorCode) => {
          setLoading(false);
          if (err) {
            const errCoponent = errComponentToRender(errorCode);
            console.log(errCoponent);
          } else {
            let dataS = await response;
            let tempcantExistCards = [];
            if (appData.activeCards.length > 0 && origin === "useEffect") {
              let prevScards = [];
              response.data.forEach((el, index) => {
                if (
                  (appData.activeCards.findIndex(
                    (card) => card.id === el.id && card.isParent
                  ) !==
                    -1) !==
                  false
                )
                  prevScards.push(el.id);
              });

              // props.setActiveCard({
              //   moduleId: prevScards,
              // });

              response.data.forEach((temp) => {
                if (
                  prevScards.indexOf(temp.id) > -1 &&
                  temp?.cannotExistRuleList?.length > 0
                ) {
                  tempcantExistCards.push(...temp?.cannotExistRuleList);
                }
              });

              let conflictsNumber = 0;
              //Business Rule for conflicts checking .

              prevScards.forEach((cardNumber, index) => {
                if (tempcantExistCards.indexOf(cardNumber) > -1)
                  conflictsNumber = conflictsNumber + 1;
              });
              props.setConflictsNumber(conflictsNumber);
            }

            // currentClass[id].classList.toggle(classes.selectedRoot);
            props.setCantExistCards(tempcantExistCards);
            setgetRecentlyViewed([]);

            setResultHeader({
              cardsCount: response.data.length,
              searched: true,
              resultTitle: `Search Results (${response.data.length})`,
            });
            appData.setCardData({
              cards: response.data,
              search: "search",
            });
            props.setResponseData(response.data);
            // if (!appData?.veevaModulesLinked) {
            if (!appData?.genericInfo?.linkedData?.totalCards) {
              appData.updateGenericInfo({
                ...appData?.genericInfo,
                linkedData: {
                  ...appData?.genericInfo?.linkedData,
                  totalCards: response?.data,
                  currentLink: false,
                },
                searchedFields:
                  linkedModulesType === "nextModule" ? formDataObj : values,
                activeCards: appData?.genericInfo?.activeCards,
              });
            }
            // }
          }
        },
        linkedModulesType
      );
    } else {
      const errCoponent = errComponentToRender(602);
      categoriesErr(errCoponent);
      // alert("Please provide atleast one input");
    }
    // if (origin === "useEffect")
    appData.updateGenericInfo({
      ...appData?.genericInfo,
      searchedFields:
        linkedModulesType === "nextModule" ? formDataObj : localValues,
    });
  };

  const handleReset = () => {
    props.setToggleCards([]);
    props.setActiveCard({ moduleId: [] });
    setValues(initialFvalues);
    props.setCantExistCards([]);
    props.setResponseData([]);
    setgetRecentlyViewed([]);
    if (!resultHeader.searched) {
      setResultHeader({
        cardsCount: 0,
        searched: false,
        resultTitle: `Recently Viewed(0)`,
      });
    } else {
      setResultHeader({
        cardsCount: 0,
        searched: false,
        resultTitle: `Search Results(0)`,
      });
    }
    setCautionBackbtn(false);
    props.setConflictsNumber(0);
  };
  const iconComponent = (props) => {
    return <ExpandMoreIcon className="module-modal" />;
  };
  const handleEnterKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };

  useEffect(() => {
    if (linkedModulesType === "veevaModule") return;
    if (backButtonClicked) {
      setBackButtonClicked(false);
      return;
    }
    // let searchedFields = { ...appData?.genericInfo?.linkedData?.values };

    if (JSON.stringify(values) === JSON.stringify(initialFvalues)) return;
    let searchedFields = JSON.parse(JSON.stringify(values));
    let isNull = true;
    for (let val in searchedFields) {
      if (
        (val === "assetNameArray" ||
          val === "claimCategoryArray" ||
          val === "coreClaimArray" ||
          val === "moduleNameArray") &&
        searchedFields[val]?.length
      )
        isNull = false;
    }
    if (!isNull) {
      searchedFields.canReset = true;
      if (Array.isArray(searchedFields["moduleNameArray"])) {
        for (let field in searchedFields) {
          if (
            field === "assetNameArray" ||
            field === "claimCategoryArray" ||
            field === "coreClaimArray" ||
            field === "moduleNameArray"
          ) {
            searchedFields[field] = searchedFields[field]?.[0];
          }
        }
      }
      if (
        appData?.genericInfo?.linkedData?.activeCards?.moduleId?.length &&
        !appData?.genericInfo?.linkedData?.totalCards
      )
        setTimeout(() => {
          handleSearchSubmit("useEffect", searchedFields);
        }, 10);
    } else searchedFields.canReset = false;
    setValues({ ...searchedFields });
    setLoading(false);
  }, []);
  useEffect(() => {
    if (linkedModulesType === "nextModule") return;
    let searchedFields = { ...appData?.genericInfo?.linkedData?.values };
    if (backButtonClicked) {
      setBackButtonClicked(false);
      return;
    }
    let isNull = true;
    for (let val in searchedFields) {
      if (
        (val === "assetNameArray" ||
          val === "claimCategoryArray" ||
          val === "coreClaimArray" ||
          val === "moduleNameArray") &&
        searchedFields[val] !== "" &&
        searchedFields[val]?.[0] !== null &&
        searchedFields[val]?.length > 0
      )
        isNull = false;
    }
    if (!isNull) {
      searchedFields.canReset = true;

      for (let field in searchedFields) {
        if (
          typeof searchedFields[field] !== "string" &&
          (field === "assetNameArray" ||
            field === "claimCategoryArray" ||
            field === "coreClaimArray" ||
            field === "moduleNameArray")
        ) {
          if (
            field === "moduleNameArray" &&
            typeof searchedFields[field]?.[0] !== "string" &&
            typeof searchedFields[field] !== "string"
          ) {
            //bcs modulename array is comming as array[array]
            searchedFields[field] = searchedFields[field]?.[0]?.[0];
          } else {
            searchedFields[field] = searchedFields[field]?.[0];
          }
        }
      }
      if (
        appData?.genericInfo?.linkedData?.activeCards &&
        !appData?.genericInfo?.linkedData?.totalCards
      )
        setTimeout(() => {
          handleSearchSubmit("useEffect", searchedFields);
        }, 10);
    } else searchedFields.canReset = false;
    setValues({ ...values, canReset: searchedFields.canReset });
    setLoading(false);
  }, []);
  return (
    <form className={classes.root}>
      <div
        className="container-fluid"
        style={{ paddingLeft: "30px", paddingRight: "36px" }}
      >
        <>
          {/* <div class="modal-backdrop fade show"> </div> */}
          <CircularProgressBar isloading={loading} />

          {/* <CircularProgress size="30px" className="customLoader" />{" "} */}
        </>
        {linkedModulesType === "nextModule" ? (
          <>
            <div className="row mb-4">
              <div className="col-md-4   modalform-fields">
                <TextField
                  variant="outlined"
                  label={t("modulesLeftContainer.moduleIdandName")}
                  name="moduleNameArray"
                  value={values.moduleNameArray}
                  onChange={handleChange}
                  onKeyUp={handleEnterKeypress}
                  fullWidth={true}
                ></TextField>
              </div>
              <div className="col-md-4  modalform-fields">
                <TextField
                  variant="outlined"
                  label={t("modulesLeftContainer.assetName")}
                  name="assetNameArray"
                  value={values.assetNameArray}
                  onChange={handleChange}
                  fullWidth={true}
                  onKeyUp={handleEnterKeypress}
                ></TextField>
              </div>
              <div className="col-md-4 modalform-fields">
                {/* <TextField
              variant="outlined"
              select
              label="Core Claim categories"
              name="claimCategoryArray"
              value={values.claimCategoryArray}
              onChange={handleChange}
              fullWidth={true}
            >
              {coreClaimCategories.map((option) => (
                <MenuItem
                  key={option.claimCategory}
                  value={option.claimCategory}
                  className='custonScrollBar'
                >
                  {option.claimCategory}
                </MenuItem>
              ))}
            </TextField> */}
                <DropDownWithLabel
                  options={{
                    value: values.claimCategoryArray, //t("uploadAssetLabel.brand"),
                    name: "claimCategoryArray",
                    label: t("modulesLeftContainer.coreClaimCategories"),
                    items: catList,
                    handleChange: handleChange,
                    IconComponent: iconComponent,
                  }}
                />
                {/* <FormControl variant="outlined" className="coreCatClaim">
              <InputLabel >Core claim Categories</InputLabel>
              <Select
                variant="outlined"
                label="Core Claim categories"
                name="claimCategoryArray"
                className="module-modal"
                value={values.claimCategoryArray}
                onChange={handleChange}
                fullWidth={true}
                IconComponent={iconComponent}

              >
                {coreClaimCategories.map((option) => (

                  <MenuItem
                    key={option.claimCategoryId}
                    value={option.claimCategory}
                    className='custonScrollBar'
                  >
                    {option.claimCategory}
                  </MenuItem>
                )

                )

                }
              </Select>
            </FormControl> */}

                {/* <CustomizedSelects
                            data={coreClaimCategories.map((coreClaimCategory) => {
                                return {id : coreClaimCategory.claimCategoryId, name : coreClaimCategory.claimCategory};
                            })}
                            default="Select Core Claim Category(s)"
                            handleSelection={onCategorySelect}
                            /> */}
              </div>
            </div>
            <div className="row align-items-center mb-4  modalform-fields">
              <div className="col-md-10">
                {/* <FormControl variant="outlined" className="coreClaimFC">
              <InputLabel >Core claim</InputLabel>
              <Select
                variant="outlined"
                // select
                // native

                onOpen={handleDropSelect}
                label="Core claim"
                name="coreClaimArray"
                value={values.coreClaimArray}
                onChange={handleChange}
                fullWidth={true}
                IconComponent={iconComponent}
              >
                {getCoreClaims() !== [] &&
                  getCoreClaims().map((option) => (
                    <MenuItem key={option.claim} value={option.claim}>
                      {option.claim}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>  */}
                <DropDownWithLabel
                  options={{
                    value: values.coreClaimArray, //t("uploadAssetLabel.brand"),
                    name: "coreClaimArray",
                    label: t("modulesLeftContainer.coreClaim"),
                    items: getCoreClaims(),
                    onOpen: handleDropSelect,
                    handleChange: handleChange,
                    IconComponent: iconComponent,
                  }}
                />
              </div>

              <div className="col-md-2 search-Reset">
                <Button className="btnWithBorder" onClick={handleSearchSubmit}>
                  {t("modulesLeftContainer.search")}
                </Button>
                <Button
                  className={`btn resetButtton  ${
                    values.canReset ? classes.ResetKey : classes.Unabled
                  }`}
                  onClick={values.canReset && handleReset}
                  //   style={{outline:'none'}}
                >
                  {t("modulesLeftContainer.reset")}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row mb-4">
              <div className="col-md-6  modalform-fields paddingClass">
                <TextField
                  variant="outlined"
                  label={t("modulesLeftContainer.moduleIdandName")}
                  name="moduleNameArray"
                  value={values.moduleNameArray}
                  onChange={handleChange}
                  onKeyUp={handleEnterKeypress}
                  fullWidth={true}
                ></TextField>
              </div>

              <div
                className={`${
                  window.screen.availWidth === 1792 ? "col-md-4" : "col-md-3"
                } modalform-fields paddingClass`}
              >
                <DropDownWithLabel
                  options={{
                    value: values.claimCategoryArray, //t("uploadAssetLabel.brand"),
                    name: "claimCategoryArray",
                    label: t("modulesLeftContainer.coreClaimCategories"),
                    items: catList,
                    handleChange: handleChange,
                    IconComponent: iconComponent,
                  }}
                />
              </div>
              <div
                className={`${
                  window.screen.availWidth === 1792 ? "col-md-2" : "col-md-3"
                } search-Reset`}
              >
                <Button className="btnWithBorder" onClick={handleSearchSubmit}>
                  {t("modulesLeftContainer.search")}
                </Button>
                <Button
                  className={`btn resetButtton  ${
                    values.canReset ? classes.ResetKey : classes.Unabled
                  }`}
                  onClick={values.canReset && handleReset}
                  //   style={{outline:'none'}}
                >
                  {t("modulesLeftContainer.reset")}
                </Button>
              </div>
            </div>
          </>
        )}
        <div className={classes.InlineDevide}>
          {resultHeader.resultTitle}
          <Divider variant="inset" />
        </div>

        {}
        <div className="selectModuleCardsList custonScrollBar ">
          <div className="cautionbtn">
            {cautionBackbtn && resultHeader.searched === true ? (
              <div className="row backButnCaution ">
                <div className="col-md-12 cautionbckbtn">
                  <span className="warningBckbtn">
                    {t(
                      "Deselecting previously chosen modules will result in loss of associated business rules selected previously"
                    )}
                  </span>
                  <img
                    src={CrossIcon}
                    alt="CrossIcon"
                    className="infobckbtn"
                    onClick={() => {
                      //  setSelectedCardCaution(false);
                      setCautionBackbtn(false);
                    }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row card-list selctcard  selectModuleCardsList2  ">
            {props.responseData?.map((data, index) => (
              <div
                className={
                  "col-xl-3 col-lg-3 col-md-4" + " " + classes.ResultContainer
                }
              >
                <DynCards
                  ref={childRef}
                  activeCard={props.activeCard}
                  handleSelect={props.HandleSelect}
                  cantExistCards={props.cantExistCards}
                  classFlag={resultHeader.searched}
                  recentSearch={"search"}
                  data={data}
                  index={index}
                  cannotExist={props.cannotExist}
                  prevSelectedCards={prevSelectedCards}
                  linkedModulesType={linkedModulesType}
                />
                {/* <DynCards  data={data} /> */}
              </div>
            ))}
            {recentlyViewed?.map((data, index) => (
              <div
                className={
                  "col-xl-3 col-lg-3 col-md-4" + " " + classes.ResultContainer
                }
              >
                <DynCards
                  ref={childRef}
                  setLoading={setLoading}
                  data={data}
                  handleSelect={props.HandleSelect}
                  classFlag={resultHeader.searched}
                  recentSearch={"recent"}
                  index={index}
                  loading={loading}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </form>
  );
}

export default withStyles(FormStyle)(ModalForms);
