import React, { useEffect } from "react";
import {
  Card,
  Typography,
  CardContent,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import parse from "html-react-parser";
import {
  getModuleDetailsById,
  getModulesBusinessRules,
  sendSaveModuleIdHistory,
} from "../../services/apis";
import BusinessModal from "../../components/BusinessModal";
import { CardStyles } from "../../styles/pages/ContentStudio/WithStylesObjs";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import "../../styles/components/CantExistMenu.scss";
import { useTranslation } from "react-i18next";
import CantExistIcon from "../../images/icon_info_red.svg";
import ValueContext from "../../ContextAPi/appContext";
const DynCards = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [isModal, setIsModal] = React.useState(false);
  const [moduleDetails, setModuleDetails] = React.useState();
  const [bModules, setbModules] = React.useState(undefined);
  const [visible, setVisible] = React.useState(false);
  const { classes } = props;
  const [loadModuleInfo, setLoadModuleInfo] = React.useState(false);
  const [lastSelected, setLastSelected] = React.useState(false);
  let testPassed = "";
  useEffect(() => {
    if (props.activeCard.moduleId.length > 0) {
      testPassed = props.activeCard.moduleId.some(
        (card) => props.data.id === card
      );
      setLastSelected(testPassed);
    }

    // console.log(
    //   "testPasses",

    //   testPassed,

    //   props.data.id,

    //   props.activeCard.moduleId
    // );

    setLastSelected(
      props.activeCard.moduleId.every((card) => props.data.id === card.id)
    );
  }, []);

  React.useImperativeHandle(ref, () => ({
    checkSearchFlag() {
      const currentClass = document.getElementsByClassName(
        classes.selectedRoot
      );
      if (currentClass.length) {
        for (let item of currentClass) {
          item.classList.toggle(classes.selectedRoot);
        }
      }
    },
  }));
  const getTacticBusinessRuleModules = (assetId) => {
    if (assetId) {
      getModulesBusinessRules(assetId, (response, err, errorCode) => {
        if (err) {
        } else {
          setbModules(response.data);
        }
      });
    }
  };

  const handleBuinessOpenModal = (e) => {
    // props.setLoading(true);
    // e.stoppropagation();
    setLoadModuleInfo(true);
    //  setLoadModuleInfo(true);
    if (props.linkedModulesType === "veevaModule") {
      window.open(props.data.contentModuleUiUrl);
      setLoadModuleInfo(false);
    } else {
      getModuleDetailsById(props.data.id, (response, err, errCode) => {
        // setTimeout(() => {
        //setLoadModuleInfo(true);
        // }, 100);
        if (err) {
          const errCoponent = ErrComponentToRender(errCode);
          setLoadModuleInfo(false);
        } else {
          setModuleDetails(response.data);
          //setModuleDetails(true);
          // console.log(response.data, "props.data.id");
          getTacticBusinessRuleModules(response.data.assetId);
          setLoadModuleInfo(false);
        }
      });
      if (loadModuleInfo === false) {
        setIsModal(!isModal);

        sendSaveModuleIdHistory(
          { contentType: "Module", contentId: [props.data.id] },
          (response, err, errorCode) => {
            if (err) {
              const errCoponent = ErrComponentToRender(errorCode);
            } else {
            }
          }
        );
      }
    }
  };
  const [cantexistModules, setCantexistModules] = React.useState([]);
  const appData = React.useContext(ValueContext);
  const handleCantExist = (getText, id) => {
    if (getText === "onmousehover") {
      setVisible(true);
      let cantExistModuleId = [];
      appData.cardData.cards.forEach((item) => {
        let result = item.cannotExistRuleList?.indexOf(id);
        if (result > -1) {
          cantExistModuleId.push(item.moduleCode);
        }
      });
      setCantexistModules(cantExistModuleId);
    } else {
      setVisible(false);
      setCantexistModules([]);
    }
  };
  let catValue = "";
  let claimValue = "";
  let cantExistRule =
    props?.cantExistCards?.indexOf(props.data.id) > -1 ? "outLine-red " : "";

  if (props?.data.hasOwnProperty("coreCategories")) {
    catValue =
      props?.data?.coreCategories?.length > 0
        ? props?.data?.coreCategories[0]
        : props?.data?.moduleCoreCategories?.length > 0
        ? props?.data?.moduleCoreCategories[0]?.coreCategory
        : "";
    if (
      props?.data.hasOwnProperty("moduleClaims") &&
      props?.data.moduleClaims.length > 0
    )
      claimValue = props?.data?.claim || props?.data?.moduleClaims[0]?.claim;
    claimValue = props?.data?.claim;
  }
  //console.log(props.data.id, props.activeCard);
  function testPass() {
    if (props.activeCard?.moduleId?.length > 0) {
      testPassed = props.activeCard.moduleId.some(
        (card) => props.data.id === card
      );
      return testPassed;
    }
  }

  //${classes.selectedRoot}
  // let catValue = props.recentSearch === "search" ? props.data.coreCategories[0] : (props.data.moduleCoreCategories.length > 0 ? props.data.moduleCoreCategories[0]?.coreCategory : "")
  // let claimValue = props.recentSearch === "search" ? props.data.claim : (props.data.moduleClaims.length > 0 ? props.data.moduleClaims[0]?.claim : "")
  return (
    <div
      className={`  ${classes.root} ${
        testPass() ? classes.selectedRoot : ""
      }   `}
    >
      {loadModuleInfo && (
        <>
          <div class="modal-backdrop fade show"> </div>
          <CircularProgress size="50px" className="customLoader" />{" "}
        </>
      )}
      <Card
        variant="outlined"
        style={{ cursor: "pointer" }}
        className={cantExistRule}
      >
        <CardContent
          onClick={() =>
            props.handleSelect(props.index, props.data.id, classes)
          }
        >
          <div className="row moduleCodeDiv" style={{ marginTop: "5px" }}>
            {props.linkedModulesType === "nextModule" ? (
              <div className="col-md-5 module-code mb-3">
                {props.data.moduleCode}
              </div>
            ) : (
              <div className="col-md-5 module-code mb-3"></div>
            )}
            {props?.cantExistCards?.indexOf(props.data.id) > -1 ? (
              <div
                className="col-md-7 cantExistText"
                style={{ textAlign: "right" }}
              >
                Cannot exist
                <img
                  src={CantExistIcon}
                  alt=""
                  className="img-fluid"
                  onMouseEnter={(e) =>
                    handleCantExist("onmousehover", props.data.id)
                  }
                  onMouseLeave={(e) =>
                    handleCantExist("onmouseleave", props.data.id)
                  }
                />
                <div>
                  {visible ? (
                    <>
                      <div className="cantExist">
                        {cantexistModules.map((item, id) => {
                          return (
                            <div className={`cantExist--option`}>{item}</div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </div>{" "}
              </div>
            ) : (
              ""
            )}
          </div>
          {props.linkedModulesType === "nextModule" && (
            <>
              {" "}
              <Typography className="camp-Name">
                {t("modulesLeftContainer.campaignName")}
              </Typography>
              <p className="camp-details"> {props.data.campaign}</p>
            </>
          )}
          <div
            className={
              props.linkedModulesType === "nextModule" ? "" : "veevaModDiv"
            }
          >
            <Typography color="textSecondary" className="camp-Name module-Name">
              {t("modulesLeftContainer.moduleName")}
            </Typography>
            <p className=" primary-claim boldClass">
              {" "}
              {props.linkedModulesType === "nextModule"
                ? props.data.name
                : props.data.moduleName}
            </p>
            <Typography>
              <div className="cardsYellowbutton">
                {props.linkedModulesType === "nextModule"
                  ? catValue
                  : props.data.coreCategory}
              </div>
            </Typography>
            <Typography
              color="textSecondary"
              className="camp-Name module-Name mt-1"
            >
              {t("modulesLeftContainer.primaryClaim")}
            </Typography>

            <div>
              {" "}
              <div className="primary-claim primary-claimMinheigth">
                {props.linkedModulesType === "nextModule"
                  ? claimValue && parse(claimValue)
                  : props.data.claim}
              </div>
            </div>
          </div>
          <div
            className={
              props.linkedModulesType === "nextModule"
                ? "text-center modalViewBtn"
                : "text-center modalViewBtnveeva"
            }
          >
            <div
              className="btn viewBtn "
              onClick={(e) => {
                //  setLoadModuleInfo(true);
                handleBuinessOpenModal(e);
                e.stopPropagation();
              }}
            >
              {t("modulesLeftContainer.view")}
            </div>
          </div>
        </CardContent>
      </Card>
      {isModal && (
        <BusinessModal
          modalStatus={isModal}
          handleBuinessOpenModal={handleBuinessOpenModal}
          moduleDetails={moduleDetails}
          bModules={bModules}
          appData={appData}
        />
      )}
    </div>
  );
});

export default withStyles(CardStyles)(DynCards);
