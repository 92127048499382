import React from "react";
import { useTranslation } from "react-i18next";
import Checkmark from '../images/Checkmark.png';
import '../styles/pages/Customtoastify.scss';

export const PasswordChangeSuccess = (props) => {
  const { t } = useTranslation();
  const { assetname } = props;
  return (
    <>    
    <div>      
        <p className="notificationText" style={{color:'#222222'}}>
          <img src={Checkmark} alt='Otp verfication' />
          {t("resetPassword.success", {
            assetnNameDisplay: assetname,
          })}
        </p>
        <div className="notificationText2">
          <p> {t("resetPassword.successMessage")}</p>   
        </div>  
          <button  className='close-button' style={{outline:'none'}}>Close</button> 
      </div>
    </>
  );
};

export const PasswordChangeFailure = (props) => {
  const { t } = useTranslation();
  const {  assetname } = props;
  return (
    <>
      <p className="notificationText">
        {t("resetPassword.failure", {
          assetnNameDisplay: assetname,
        })}
      </p>
    </>
  );
};


