import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./Uitls/PrivateRoute";
import Login from "./Pages/Login/Login";
import Signup from "./Pages/Login/Signup";
import ForgotPassword from "./Pages/Login/ForgotPassword";
//import Dashboard from "./Pages/Dashboard/Dashboard";
import SearchAndViewAsset from "./Pages/ContentStudio/SearchAndViewAsset";
import EditorAnimation from "./Pages/ContentStudio/EditorAnimation";
import EditorMainPage from "./Pages/EditorMainPage";
import FroalaInstanceSample from "./Pages/ContentStudio/FroalaInstanceSample";
import BannerManager from "./Pages/Banners/BannerManager";
import LandingPage from "./Pages/TemplateManager/LandingPage";
import Notfound from "./Pages/UrlNotfound";

// import { addProcessIds, clearProcessIds } from "./Uitls/polling";
import { getInProgressIds, getSession } from "./Uitls";
import {
  PasswordChangeSuccess,
  PasswordChangeFailure,
} from "./components/toastify";
import "./App.scss";
import "./common.scss";
import ErrComponentToRender from "./Uitls/apiErrorHandling";
import { routes } from "./Uitls/Util";
import { ValueProvider } from "./ContextAPi/appContext";

export const notify = (id, status, type, assetname) => {
  if (type === "success") {
    toast.success(
      <PasswordChangeSuccess
        id={id}
        status="Success"
        assetname="Password Change Successful"
      />
    );
  } else if (type === "error") {
    toast.error(
      <PasswordChangeFailure
        id={id}
        status="Failure"
        assetname="Incorrect OTP entered"
      />,
      { autoClose: 2000 }
    );
  }
};

export const apiAuthError = (errorCode) => {
  getSession()
    .then(() => {
      const errCoponent = ErrComponentToRender(errorCode);
      toast.error(errCoponent);
    })
    .catch((err) => {});
};

function App() {
  const [googleFontsLink, setGoogleFontsLink] = useState("");
  const [banner, setBanner] = useState(true);
  // const [assetType, setAssetType] = useState("Banner"); //DeleteNavigate

  const [genericInfo, setGenricInfo] = useState({});
  const [brandId, setBrandId] = useState("");
  const [campaignId, setCampaignId] = useState("");
  //const[campaign, setCampaign]= useState('');
  const [marketCode, setMarketCode] = useState("");
  const [assetInfo, setAssetInfo] = useState({
    assetId: "",
    assetName: "",
  });
  const [cardData, setCardData] = useState({
    cards: "",
    search: "recent",
  });

  const [moduleCardData, setModuleCardData] = useState({
    cards: "",
    search: "recent",
  });
  const [bannersEditCardData, setBannersEditCardData] = useState({
    cards: "",
  });

  const [flagEditBanner, setFlagEditBanner] = useState(false);
  const [flagEditFlow, setFlagEditFlow] = useState(false);
  const [activeCards, setActiveCards] = useState([]);
  const [veevaModulesLinked, setVeevaModulesLinked] = useState(false);

  const updateGenericInfo = (modalValues) => {
    setGenricInfo({ modalValues });
  };
  // const updateBrandId=(id)=>{
  //   setBrandId(id);
  // }
  // const [dimensionObj, setDimensionObj] =useState(
  //   {"xn":0,"yn":0,"width":0,"height":0,"top":0,"right":0,"bottom":0,"left":0,
  // targetId:''}
  // )
  const [customTempSize, setCustomTempSize] = useState(false);
  const [dimensionObj, setDimensionObj] = useState({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    targetId: "",
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  return (
    //<AppContextProvider>
    <ValueProvider
      value={{
        // assetType: assetType,
        // setAssetType: setAssetType,// DeleteNavigate
        genericInfo: genericInfo.modalValues,
        brandId: brandId,
        cardData: cardData,
        setCardData: setCardData,
        updateGenericInfo: updateGenericInfo,
        setBrandId: setBrandId,
        campaignId: campaignId,
        setCampaignId: setCampaignId,
        marketCode: marketCode,
        setMarketCode: setMarketCode,
        assetInfo: assetInfo,
        setAssetInfo: setAssetInfo,
        dimensionObj: dimensionObj,
        setDimensionObj: setDimensionObj,
        bannersEditCardData: bannersEditCardData,
        setBannersEditCardData: setBannersEditCardData,
        flagEditBanner: flagEditBanner,
        setFlagEditBanner: setFlagEditBanner,
        flagEditFlow: flagEditFlow,
        setFlagEditFlow: setFlagEditFlow,
        moduleCardData: moduleCardData,
        setModuleCardData: setModuleCardData,
        activeCards: activeCards,
        setActiveCards: setActiveCards,
        googleFontsLink: googleFontsLink,
        setGoogleFontsLink: setGoogleFontsLink,
        veevaModulesLinked: veevaModulesLinked,
        setVeevaModulesLinked: setVeevaModulesLinked,
        banner: banner,
        setBanner: setBanner,
        customTempSize: customTempSize,
        setCustomTempSize: setCustomTempSize,
      }}
    >
      <div className="App">
        <StylesProvider injectFirst>
          <ToastContainer />
          <BrowserRouter
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}
          >
            <Switch>
              <Route path="/" component={Login} exact />
              {/* <PrivateRoute path="/dashboard" component={Dashboard} /> */}
              <Route path="/signup" component={Signup} />
              <Route path="/forgot-password" component={ForgotPassword} />
              {/* <PrivateRoute path="/editor-old" component={Editor} /> */}
              <PrivateRoute
                path="/editor-animation"
                component={EditorAnimation}
              />
              <PrivateRoute
                path="/banner-editor/:bannerId"
                component={EditorMainPage}
              />
              <PrivateRoute
                path="/froala-editor"
                component={FroalaInstanceSample}
              />
              <PrivateRoute
                path="/searchViewAsset"
                component={SearchAndViewAsset}
              />
              <PrivateRoute path="/landingPage" component={LandingPage} />
              <PrivateRoute path="/bannerManager" component={BannerManager} />
              <PrivateRoute component={Notfound} />
            </Switch>
          </BrowserRouter>
        </StylesProvider>
      </div>
      {/* //</AppContextProvider> */}
    </ValueProvider>
  );
}
export default withRouter(App);
