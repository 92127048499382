import React from "react";
import CardsForLeftContainer from "./CardsForLeftContainer";

export default React.memo(
  function GoToCardsLeftConatiner(props) {
    return (
      <>
        {props.activeCards.map((card, index) => {
          return (
            <CardsForLeftContainer
              openTimeLine={props.openTimeLine}
              key={index}
              data={card}
              index={index}
              expandClaims={props.expandClaims}
              linkedModulesType={props.linkedModulesType}
              onclickBlueArrow={props.onclickBlueArrow}
            />
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.activeCards === nextProps.activeCards) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component
  }
);
