/* eslint-disable no-use-before-define */
import React, { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { Popper } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import "./InfoDetailsDialog.scss";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Listbox } from "@material-ui/core";

const CustomDropDownInfoModal = (props) => {
  const autocompleteRef = useRef(null);
  //const classes = useStyles();
  setTimeout(async () => {
    const close = await document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];

    if (close) {
      close.addEventListener("click", () => {
        const handleChnage = props?.options?.handleChange;
        if (handleChnage) {
          handleChnage("clearIcon");
        }
      });
    }
  }, 0);

  const getValue = () => {
    const options = props.options;
    if (options && options.items && options.items.length > 0 && options.value) {
      // ((options.items.indexOf(options.value) !== -1) || ))
      return options.value;
    }
    return "";
  };

  const useStyles = makeStyles((theme) => ({
    popupIndicator: {
      "& span": {
        "& svg": {
          "& path": {
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')",
          },
        },
      },
    },
    popper: {
      marginLeft: "-9px",
      marginTop: "1px",
      width: "150px !important",
    },
    focusedOption: {
      borderRadius: "4px",
      border: "solid 1px #d5d5d5",
      autoSelect: "none",
      backgroundColor: "#fff",
      paddingLeft: "12px",
    },
    inputRoot: {
      paddingRight: "25px !important",
      height: "32px",
      paddingLeft: "0 !important",
    },
  }));
  const classes = useStyles();
  const handleFocus = (event) => {
    // event.target.blur();
    // // event.target.focus();
  };
  return (
    <Autocomplete
      ref={autocompleteRef}
      disableClearable
      id="search-and-select"
      style={{
        width: "150px",
        marginTop: "-6px",
      }}
      ListboxProps={{
        style: {
          height: "128px",
          paddingLeft: "0 !important",
          boxShadow: "inset 0 0 3px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff !important",
        },
      }}
      options={props.options.items}
      value={getValue()}
      onChange={(event, value) => {
        props.options.handleChange(value, "custom");
      }}
      getOptionSelected={(option) => {
        return props.options.name === "market"
          ? option.displayName === props.options.value?.displayName
          : option === props.options.value?.displayName;
      }}
      getOptionLabel={(option) => option.displayName || ""}
      closeIcon={""}
      className="custonScrollBar newclass"
      closeText=""
      openText=""
      clearText=""
      autoHighlight
      classes={{
        clearIndicatorDirty: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
        focused: classes.focusedOption,
        listbox: classes.listBox,
        inputRoot: classes.inputRoot,
        popper: classes.popper,
      }}
      renderOption={(option) => {
        return (
          <React.Fragment>
            <span
              style={{
                fontSize: "12px",
                fontFamily: "Nunito Sans",
                // maxHeight: "32px",
                color: "#231f20",
                fontWeight: 600,
                display: "block",
                textOverflow: "ellipsis",
                backgroundColor: "#ffff !important",
                width: "150px",
              }}
            >
              {props.options.name === "market" ? option.displayName : option}
            </span>
          </React.Fragment>
        );
      }}
      renderInput={(params, option) => (
        <Tooltip
          title={
            props?.options?.value?.displayName?.length > 18
              ? props?.options?.value?.displayName
              : ""
          }
          placement="top"
        >
          <TextField
            {...params}
            // label={props.options.label}
            // onFocus={handleFocus} // Blur the in
            value={props?.optins?.value.displayName}
            variant="standard"
            inputProps={{
              ...params.inputProps,

              style: {
                fontSize: "12px",
                paddingRight: "10px",
                marginTop: "-5px",
                height: "32px",
                fontFamily: "Nunito Sans",
                color: "#231f20",
                fontWeight: 600,
              },
            }}
            className={"custom-autocomplete-input"}
            id="custom-autoComplete-Id"
          />
        </Tooltip>
      )}
    />
  );
};

export default CustomDropDownInfoModal;
