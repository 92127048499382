import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useTranslation } from "react-i18next";
import "../../styles/components/Editor.scss";
import "../../styles/components/EditorModal.scss";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";

export default function ExportModal(props) {
  const { checkExportType, setCheckExportType } = props;
  const { setRvcvTypes, rvcvTypes } = props;
  const [exportType, setExportType] = React.useState("PDF");
  const handleChangeExporttype = (event) => {
    setCheckExportType({
      ...checkExportType,
      [event.target.name]: event.target.checked,
    });
    if (event.target.name === "RVCVFILE" && !event.target.checked) {
      setRvcvTypes({
        ...rvcvTypes,
        value2X: false,
        value3X: false,
      });
    }
  };
  const handleChangeRvcvtype = (event) => {
    let name = event.target.name;
    if (name === "value2X") {
      setRvcvTypes({
        ...rvcvTypes,
        value2X: event.target.checked,
        value3X: false,
      });
    } else if (name === "value3X") {
      setRvcvTypes({
        ...rvcvTypes,
        value2X: false,
        value3X: event.target.checked,
      });
    } else {
    }
  };
  // const handleChange = (event) => {
  //   setExportType(event.target.value);
  // };

  const { t } = useTranslation();

  React.useEffect(() => {}, []);

  const handleCloseExport = () => {
    props.setExport(false);
    props.exportType(exportType);
  };
  const unCheck = () => {
    setCheckExportType({
      ...checkExportType,
      PDF: false,
      HTML: false,
      PNG: false,
      JPG: false,
      RVCVFILE: false,
    });
    setRvcvTypes({
      ...rvcvTypes,
      value2X: false,
      value3X: false,
    });
  };

  function validateExportModal() {
    return (
      checkExportType.PDF === true ||
      checkExportType.HTML === true ||
      checkExportType.PNG === true ||
      checkExportType.JPG === true ||
      checkExportType.RVCVFILE === true
      // ||
      //  checkExportType.RVCVFILE === true
    );
  }

  return (
    <>
      <Dialog
        disableBackdropClick
        open={props.showBasedOn}
        onClose={() => props.setExport(false)}
        // className=
        // {
        //   props.toggleAnimatedBanner
        //     ? "basedOnModal exportAsModal "
        //     : //: "exportAnimatedBanner"

        //       "basedOnModal exportAsModal exportAsModal2"
        // }
        className="exportAnimatedBanner"
        aria-labelledby="form-dialog-title"
        overlaystyle={{ backgroundColor: "transparent" }}
      >
        <DialogTitle id="form-dialog-title">
          <div className="baseOnModalHeading exportheading">
            {t("exportLabels.exportAs")}
          </div>
        </DialogTitle>
        <p className="exportAsSubTitle">{t("exportLabels.exportAsSubTitle")}</p>
        <DialogContent className="customScrollBar">
          <div className="row">
            <FormControl component="fieldset">
              <FormGroup
                aria-label="format"
                name="format"
                className="exportRadioGroup"
                // style={{
                //   height: props.toggleAnimatedBanner ? "430px" : "350px ",
                // }}
              >
                <FormControlLabel
                  value="PDF"
                  control={
                    <Checkbox
                      checked={checkExportType?.PDF}
                      onChange={handleChangeExporttype}
                      name="PDF"
                    />
                  }
                  label={t("exportLabels.pdf")}
                />
                <FormControlLabel
                  value="PNG"
                  control={
                    <Checkbox
                      checked={checkExportType?.PNG}
                      onChange={handleChangeExporttype}
                      name="PNG"
                    />
                  }
                  label={t("exportLabels.png")}
                />
                <FormControlLabel
                  value="JPG"
                  control={
                    <Checkbox
                      checked={checkExportType?.JPG}
                      onChange={handleChangeExporttype}
                      name="JPG"
                    />
                  }
                  label={t("exportLabels.jpeg")}
                />
                {/*     <FormControlLabel value="GIF" control={<Radio />} label="GIF" />  */}
                <FormControlLabel
                  value="HTML"
                  control={
                    <Checkbox
                      checked={checkExportType?.HTML}
                      onChange={handleChangeExporttype}
                      name="HTML"
                    />
                  }
                  label={t("exportLabels.html")}
                />
                {props.toggleAnimatedBanner && (
                  <FormControlLabel
                    value="RV/CV"
                    control={
                      <Checkbox
                        checked={checkExportType?.RVCVFILE}
                        onChange={handleChangeExporttype}
                        name="RVCVFILE"
                      />
                    }
                    label={t("exportLabels.rvCvfile")}
                  />
                )}
              </FormGroup>
              {props.toggleAnimatedBanner && (
                <RadioGroup
                  aria-label="rvcv"
                  // defaultValue={rvcvTypes}
                  name="radio-buttons-group"
                  className={
                    checkExportType?.RVCVFILE
                      ? "exportRadiobuttonChecked"
                      : "exportRadiobutton"
                  }
                  id="rvcvRadio"
                  value={rvcvTypes}
                  row
                >
                  <FormControlLabel
                    value="value2X"
                    control={
                      <Radio
                        checked={rvcvTypes?.value2X}
                        onChange={(e) => {
                          handleChangeRvcvtype(e);
                        }}
                      />
                    }
                    label="2X"
                    name="value2X"
                    disabled={!checkExportType?.RVCVFILE}
                  />
                  <FormControlLabel
                    value="value3X"
                    control={
                      <Radio
                        checked={rvcvTypes?.value3X}
                        onChange={(e) => {
                          handleChangeRvcvtype(e);
                        }}
                      />
                    }
                    label="3X"
                    name="value3X"
                    disabled={!checkExportType?.RVCVFILE}
                  />
                </RadioGroup>
              )}
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="modalActions">
          <Button
            onClick={(event) => {
              props.setExport(false);
              props.setLoading(false);
              unCheck();
              setCheckExportType({
                ...checkExportType,
                PDF: false,
                HTML: false,
                PNG: false,
                JPG: false,
                RVCVFILE: false,
              });
              setRvcvTypes({
                ...rvcvTypes,
                value2X: false,
                value3X: false,
              });
            }}
            color="primary"
            className="cancelBtnexport "
            //style={{ right: "162px !important" }}
          >
            {t("exportLabels.cancel")}
          </Button>
          <Button
            onClick={handleCloseExport}
            type="submit"
            className="btn btn-primary"
            autoFocus
            disabled={!validateExportModal()}
          >
            {t("exportLabels.export")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
