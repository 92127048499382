import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { AWS } from "dynamodb";
import Pool from "./Userpools";
import { useHistory, Link } from "react-router-dom";

import { getAssetsStatus } from "../services/apis";
import { clearProcessIds } from "./polling";
export const getSession = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    //console.log(user);
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          reject();
        } else {
          resolve(session);
        }
      });
    } else {
      reject();
    }
  });

export const authenticate = async (Username, Password) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        window.localStorage.setItem("RVCV-email", Username);
        window.localStorage.setItem("userToken", data.accessToken.jwtToken);
        window.localStorage.setItem(
          "currentUserName",
          data.idToken.payload.given_name || ""
        );

        window.localStorage.setItem(
          "currentUserFamilyName",
          data.idToken.payload.family_name
        );
        resolve(data);
      },

      onFailure: (err) => {
        reject(err);
      },

      newPasswordRequired: (data) => {
        resolve(data);
      },
    });
  });

export const logout = (canLogout) => {
  if (canLogout) return;
  const user = Pool.getCurrentUser();
  if (user) {
    window.localStorage.removeItem("userToken");
    window.localStorage.removeItem("currentUserName");
    user.signOut();
    const creds = AWS.config.credentials;
    if (creds) {
      creds.clearCachedId();
    }
    clearProcessIds();
  }
};

export const getInProgressIds = () => {
  return getAssetsStatus((response, err, errorCode) => {
    if (err) {
      console.log(err);
      //  // const errCoponent = errComponentToRender(errorCode);
      //   setErrData(errCoponent);
      //   setShowErrs(true);
      // return [];
    } else {
      return response.data;
      //return [];
    }
  });

  //return ["7c2a0a6b-70a8-4efa-bebd-b84d7496d5d0"];

  // return [
  //   "037b99ec-2fd1-415e-aff1-73eb3e49b893",
  //   "28aaa687-96a4-498c-8767-92793107b805",
  //   "3a0ae283-b474-4e2b-866b-0f37cf9dc296",
  //   "3dae749b-9fd0-4a49-8017-eaa18a153b69",
  //   "4ce143da-cb3a-4a6e-b800-b25300a45998",
  //   "50588fb9-638d-4957-8865-1dae99dffbfb",
  //   "5523e508-50b9-4e10-a419-5dfee00cc1d2",
  //   "5d588d34-5215-4a72-b5ea-30a70be2f3b9",
  //   "72c92306-3ae2-41e8-a74e-37cb761927e5",
  //   "7c2a0a6b-70a8-4efa-bebd-b84d7496d5d0",
  //   "8aa802e6-7c2d-4c7c-b7aa-45858a499728",
  //   "8ffec0f2-8f46-4042-b452-a81b000ad451",
  //   "95bdac5a-f16c-42b1-b2e7-9bc99a77c9f5",
  //   "bb01373d-ac27-4614-af99-e6b2667d89ac",
  // ];
};
