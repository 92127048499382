import React, { useState, useContext } from "react";
import "../../styles/components/ToolBarLeftPanel.scss";
import { useTranslation } from "react-i18next";
import ClearText from "../../images/ClearText.svg";
import forwardArrow2 from "../../images/ForwardArrow2.svg";
import Group2813 from "../../images/Group 2813.svg";
import NextModule from "../../images/NextModule.svg";
import VeevaModule from "../../images/VeevaModule.svg";
import "../../styles/components/Modules.scss";
import DynDialogue from "../../Pages/ContentStudio/DynDialogue";
import ValueContext from "../../ContextAPi/appContext";
import { getModuleDetailsByModuleId } from "../../services/apis";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import CardDetails from "../Editor/CardDetails";
import { toast } from "react-toastify";
import GoToCardsLeftConatiner from "../Editor/GoToCardsLeftConatiner";
import BussinessRulesDialogue from "../../Pages/ContentStudio/BussinessRulesDialogue";
import {
  getAssetdetails,
  putGotoEditorModules,
  searchModules,
  cardInfoForcardIds,
} from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";

import { CircularProgress } from "@material-ui/core";
import CustomModal from "../../components/CustomModal";
import { setNestedObjectValues } from "formik";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import { getVeevaRuleset } from "../../services/apis";
import { ClearAll } from "@material-ui/icons";
import VeevaCardDetails from "../Editor/VeevaCardDetails";
import VeevaRuleSets from "../../Pages/ContentStudio/VeevaRuleSets";
import { banner } from "../../Uitls/constants";
const AntTabs = withStyles({
  root: {
    // width:'328px',
    color: "#000000",
  },
  indicator: {
    backgroundColor: "#2e80c0",
    width: "20px",
    height: "3px",
    // display:"none"
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 68,
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const ModuleWindow = (props) => {
  const appData = useContext(ValueContext);
  const {
    linkedModulesType,
    setLinkedModulesType,
    loadingModules,
    setLoadingModules,
    setVeevaModuleCardData,
    veevaModuleCardData,
    openTimeLine,
    setModules,
    modules,
    getEditSaveAssets,
    bannerId,
  } = props;
  const [atomsList, getAtomsList] = useState([]);
  const [openBussinessRules, setOpenBussinessRules] = useState(false);
  const [moduleIdArray, setModuleIdArray] = useState([]);
  const [openVeevaruleset, setOpenveevaruleset] = useState(false);
  const categoriesErr = (value) => {
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  };

  const { classes } = props;
  const { handleCloseElement, sendAtomDetails2Canvas } = props;
  const [open, setOpen] = useState(undefined);
  const { t } = useTranslation();
  const [cardDetails, setCardDetails] = useState({
    cardDetails: false,
    data: "",
  });
  const [activeCard, setActiveCard] = useState({ moduleId: [] });
  const [toggleCards, setToggleCards] = useState([]);
  const [cautionBackbtn, setCautionBackbtn] = useState(false);
  const [selectedCardCaution, setSelectedCardCaution] = useState(false);

  const [cantExistCards, setCantExistCards] = useState([]);
  const [moduleAndgridTab, setModuleAndgridTab] = useState(0);
  const [openveevaModule, setOpenveevaModule] = useState(false);
  const initialFvalues = {
    moduleNameArray: "",
    assetNameArray: "",
    brandName: "",
    claimCategoryArray: "",
    coreClaimArray: "",
    cardTitle: [],
    errors: {},
    canReset: false,
  };
  const [values, setValues] = useState(initialFvalues);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const handleVeevarulesetmodal = () => {
    setOpenveevaruleset(true);
  };
  const closeVeevarulesetmodal = () => {
    // setOpenveevaruleset(false);
  };
  const [moduleIdsonedit, setModuleIdsonedit] = useState([]);
  const handleOpenModal = (type) => {
    setLinkedModulesType(type);
    setOpen(true);
    if (appData?.genericInfo?.linkedData?.totalCards) {
      setResponseData(appData.genericInfo.linkedData.totalCards);
    }
    // const moduleId = moduleIdsonedit?.map((module) => module.moduleId);
    //  console.log("moduleId", moduleId);
    // if (moduleId?.length > 0) {
    //   const result = { moduleId };
    //   setActiveCard(result);
    // } else {

    // const filteredModules = modules.filter(
    //   (module) => module.isParent === true
    // );
    // const moduleId = filteredModules.map((module) => module.moduleId);
    //const result = { moduleId };
    setActiveCard(modules);
    // }

    setValues(appData?.genericInfo?.linkedData?.values);
  };
  const [responseData, setResponseData] = useState([]);
  const [veevaContentData, setVeevaContentData] = useState([]);

  const handleCloseModal = (setActiveCard) => {
    // setActiveCard({ moduleId: [] });
    setOpen(false);
    setOpenBussinessRules(false);
    setCautionBackbtn(false);
    //closeBtnClear
    if (!appData?.veevaModulesLinked) {
      setValues({
        ...initialFvalues,
      });
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        searchedFields: initialFvalues,
      });
    } else setValues(appData.genericInfo?.linkedData?.values);
    // setOpenveevaModule(false);
  };
  const handleBackButton = () => {
    // let tempCards = { moduleId: [...appData.cardData.cards] };
    // setActiveCard({ moduleId: [] });
    // setCantExistCards([]);
    // setToggleCards([]);
    // appData.setActiveCards([]);
    setBackButtonClicked(true);
  };
  const handleBackbtnVeeva = () => {
    setBackButtonClicked(true);
    setOpenVeevaContentModal(false);
    setOpen(true);

    // setToggleCards([]);
    // setActiveCard({ moduleId: [] });
    setVeevaRules([]);
    // setVeevaSelectedModuleId({ id: [] });
    setToggleCards(activeCard.moduleId);
    // setSelectedCardCaution(true);
  };
  const sendModuleid = (moduleBusinessRules) => {
    const moduleId = moduleBusinessRules?.map((module) => module.moduleId);
    setModules({ moduleId });

    let searchExpression = {
      brandName: appData?.genericInfo?.brand,
      moduleNameArray: appData?.genericInfo?.searchedFields?.moduleNameArray,
      assetNameArray: appData?.genericInfo?.searchedFields?.assetNameArray,
      claimCategoryArray:
        appData?.genericInfo?.searchedFields?.claimCategoryArray,
      coreClaimArray: appData?.genericInfo?.searchedFields?.coreClaimArray,
    };
    let linkedModules = { linkedModules: linkedModulesType };
    let moduleType = "module";
    let finalObj = {
      searchExpression,
      moduleBusinessRules,
      linkedModules,
      moduleType,
    };

    let moduleInfo = { assetId: appData.assetInfo.assetId, finalObj };
    putGotoEditorModules(moduleInfo, (response, err, errorCode) => {
      if (err) {
        // const errCoponent = errComponentToRender(errorCode);
        console.log(err);
      } else {
        // console.log("SelectedCardsSavedOnProceed", response);
        let putResp = response.data;
        var formDataObj = [];
        if (response.data.length) {
          for (let i = 0; i < response.data.length; i++) {
            formDataObj.push(response.data[i].moduleId);
          }

          appData.setFlagEditBanner(true);

          cardInfoForcardIds(
            JSON.stringify(formDataObj),
            async (response, err, errorCode) => {
              const dataSearch = await response.data;
              if (err) {
                const errCoponent = ErrComponentToRender(errorCode);

                appData.setCardData({
                  cards: [],
                  search: "search",
                });
              } else {
                // setcardsEditFLow(dataSearch);

                //dataSearch.sort((a, b) => a.id - b.id);
                //putResp.sort((a, b) => a.id - b.id);

                dataSearch.forEach((moduleRef, index) => {
                  let obj = putResp.find(
                    (o) => o.code === moduleRef.moduleCode
                  );
                  if (moduleRef.record_index) {
                    moduleRef.record_index = obj?.record_index;
                    moduleRef.isParent = obj?.isParent;
                  } else {
                    return "";
                  }
                });
                dataSearch.sort((a, b) =>
                  a.record_index > b.record_index ? 1 : -1
                );

                appData.setActiveCards(dataSearch);
                appData.setVeevaModulesLinked(true);
                appData.updateGenericInfo({
                  ...appData.genericInfo,
                  linkedData: {
                    ...appData?.genericInfo?.linkedData,
                    activeCards: {
                      moduleId: putResp.map((elem) => elem.moduleId),
                    },
                    values: appData?.genericInfo?.searchedFields,
                    totalCards: appData?.cardData?.cards,
                    currentLink: true,
                  },
                });
              }
            }
          );

          // searchModules(
          //   JSON.stringify(formDataObj),
          //   async (response, err, errorCode) => {
          //     const dataSearch = await response.data;
          //     if (err) {
          //       const errCoponent = ErrComponentToRender(errorCode);

          //       appData.setCardData({
          //         cards: [],
          //         search: "search",
          //       });
          //     } else {
          //       // setcardsEditFLow(dataSearch);

          //       //dataSearch.sort((a, b) => a.id - b.id);
          //       //putResp.sort((a, b) => a.id - b.id);

          //       dataSearch.forEach((moduleRef, index) => {
          //         let obj = putResp.find(
          //           (o) => o.code === moduleRef.moduleCode
          //         );
          //         if (moduleRef.record_index) {
          //           moduleRef.record_index = obj?.record_index;
          //           moduleRef.isParent = obj?.isParent;
          //         } else {
          //           return "";
          //         }
          //       });
          //       dataSearch.sort((a, b) =>
          //         a.record_index > b.record_index ? 1 : -1
          //       );

          //       appData.setActiveCards(dataSearch);
          //       appData.setVeevaModulesLinked(true);
          //       appData.updateGenericInfo({
          //         ...appData.genericInfo,
          //         linkedData: {
          //           ...appData?.genericInfo?.linkedData,
          //           activeCards: {
          //             moduleId: putResp.map((elem) => elem.moduleId),
          //           },
          //           values: appData?.genericInfo?.searchedFields,
          //           totalCards: appData?.cardData?.cards,
          //         },
          //       });
          //     }
          //   },

          //   linkedModulesType
          // );
          // let tempActiveCards = [];
          // appData.cardData.cards.forEach((card) => {
          //   activeCard.moduleId.forEach((id) => {
          //     if (card.id === id) tempActiveCards = [...tempActiveCards, card];
          //   });
        } else appData.setActiveCards([]);
      }
      setActiveCard({ moduleId: [] });
      setCantExistCards([]);
      setToggleCards([]);
    });
    handleCloseModal(setActiveCard);
  };
  const handleProceedbtn = () => {
    if (props.genericInfo === undefined) {
      toast.error(
        <div className="notificationText">
          {t("modulesLeftContainer.selectModuleToast")}
        </div>
      );
    } else {
      return sendModuleid();
    }
  };
  const [onclickBlueArrow, setOnClickBlueArrow] = useState(false);
  const expandClaims = (cardId) => {
    setLoadingModules(true);
    setOnClickBlueArrow(true);
    getModuleDetailsByModuleId(
      cardId,
      (response, err, errorCode) => {
        if (err) {
          const errCoponent = errComponentToRender(errorCode);
          categoriesErr(errCoponent);
          setLoadingModules(false);
        } else {
          setCardDetails({
            cardDetails: true,
            data: response.data,
          });
          setLoadingModules(false);
        }
      },
      linkedModulesType
    );
  };

  const handleBussinessRulesModal = () => {
    setOpenBussinessRules(true);
    setOpen(false);
  };
  const closeBussinessRulesModal = () => {
    setOpenBussinessRules(false);
  };
  const handleModuleandgridtabs = (event, newValue) => {
    setModuleAndgridTab(newValue);
  };
  const [openVeevaContentModal, setOpenVeevaContentModal] = useState(false);

  const handleVeevaContentModal = () => {
    getVeevaRulesset(activeCard.moduleId, "veevaContentModal");
  };

  const [veevaRules, setVeevaRules] = useState([]);
  const [veevaSelectedModuleId, setVeevaSelectedModuleId] = useState({
    id: [],
  });
  const [veevaLoader, setVeevaLoader] = useState(false);
  const getVeevaRulesset = (selectedModules, type) => {
    setVeevaLoader(true);
    getVeevaRuleset(selectedModules, (response, err, errCode) => {
      if (err) {
        setVeevaLoader(false);
      } else {
        setVeevaLoader(false);
        if (type === "veevaContentModal") {
          setOpenVeevaContentModal(true);
          setOpenBussinessRules(false);
          setOpen(false);
          // Work aorund code for Mustuselist and optional list
          // const veevaRulesData = [];
          // response.data.forEach((item) => {
          //   let allModuleData = [];

          //   allModuleData.push({
          //     parentModuleId: item.id,
          //   });
          //   if (item.mustUseList.length > 0) {
          //     item.mustUseList.forEach((element) => {
          //       element.status = true;
          //       allModuleData.push({
          //         id: element.id,
          //         type: "mustUseList",
          //         parentModuleId: item.id,
          //       });
          //     });
          //   }
          //   if (item.optionalList.length > 0) {
          //     item.optionalList.forEach((element) => {
          //       element.status = true;
          //       allModuleData.push({
          //         id: element.id,
          //         type: "optionalList",
          //         parentModuleId: item.id,
          //       });
          //     });
          //   }
          //   veevaRulesData.push.apply(veevaRulesData, allModuleData);
          // });

          //  defaultCheckingModules(veevaRulesData);
        } else {
          setOpenveevaruleset(true);
        }
        setVeevaRules(response.data);
        const selectedModule = response.data[0];
        setVeevaSelectedModuleId(selectedModule);
      }
    });
  };
  const closeVeevaContentModal = () => {
    setOpenVeevaContentModal(false);
    if (!appData?.veevaModulesLinked) {
      setValues({
        ...initialFvalues,
      });
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        searchedFields: initialFvalues,
      });
    } else setValues(appData.genericInfo?.linkedData?.values);
  };
  const clearAllVals = () => {
    setToggleCards([]);
    setActiveCard({ moduleId: [] });
    setValues(initialFvalues);
    setCantExistCards([]);
    // setValues({
    //   ...initialFvalues,
    // });
    setResponseData([]);
    // setgetRecentlyViewed([]);
    // if (!resultHeader.searched) {
    // setResultHeader({
    //   cardsCount: 0,
    //   searched: false,
    //   resultTitle: `Recently Viewed(0)`,
    // });
    // } else {
    // setResultHeader({
    //   cardsCount: 0,
    //   searched: false,
    //   resultTitle: `Search Results(0)`,
    // });
    // }
    setCautionBackbtn(false);
    // setConflictsNumber(0);
  };
  return (
    <>
      {/* {!loadingModules && ( */}
      <div
        //  className="moduleWindow"
        className={openTimeLine ? "moduleWindowOpentimeline" : "moduleWindow"}
      >
        <div className="moduleWindowHeader">
          <div className="titleText">
            <p>
              {/* {t(
                cardDetails.cardDetails
                  ? "MODULE"
                  : "toolbarLeftPanel.moduleTitle2"
              )} */}
              {t(cardDetails.cardDetails ? "" : "MODULE")}
            </p>
          </div>
          {!cardDetails.cardDetails && (
            <div className="closeIconModules">
              {/* <img
                src={forwardArrow2}
                alt="forwardArrow2"
                className="moduleArrow img-fluid"
              /> */}
              <img
                src={ClearText}
                alt=""
                className={
                  cardDetails.cardDetails
                    ? "closeImage closeImageModule"
                    : "closeImage "
                }
                onClick={() => handleCloseElement("Module")}
              />
            </div>
          )}
        </div>

        {appData?.activeCards?.length > 0 ? (
          <div
            //className="module-tabpane"
            className={openTimeLine ? "module-tabpane" : "module-tabpane2"}
          >
            {cardDetails.cardDetails ? (
              linkedModulesType !== "veevaModule" ? (
                <div>
                  <CardDetails
                    setOnClickBlueArrow={setOnClickBlueArrow}
                    cardDetails={cardDetails}
                    setCardDetails={setCardDetails}
                    getLayerDetails={props.getLayerDetails}
                    handleVeevarulesetmodal={handleVeevarulesetmodal}
                    closeVeevarulesetmodal={closeVeevarulesetmodal}
                    openVeevaruleset={openVeevaruleset}
                    setOpenveevaruleset={setOpenveevaruleset}
                    getVeevaRulesset={getVeevaRulesset}
                    sendAtomDetails2Canvas={sendAtomDetails2Canvas}
                  />
                </div>
              ) : (
                <VeevaCardDetails
                  cardDetails={cardDetails}
                  setCardDetails={setCardDetails}
                  handleVeevarulesetmodal={handleVeevarulesetmodal}
                  closeVeevarulesetmodal={closeVeevarulesetmodal}
                  openVeevaruleset={openVeevaruleset}
                  setOpenveevaruleset={setOpenveevaruleset}
                  getVeevaRulesset={getVeevaRulesset}
                  sendAtomDetails2Canvas={sendAtomDetails2Canvas}
                />
              )
            ) : (
              <div className="atoms-Tab">
                <ul
                  // className="atoms-Conatiner custonScrollBar "
                  className={
                    !openTimeLine
                      ? "atoms-Conatinertimelineopen custonScrollBar"
                      : "atoms-Conatiner custonScrollBar"
                  }
                >
                  <li className="text-left leftText">
                    <button
                      className="btn btn-link pr-0"
                      color="primary"
                      onClick={() => {
                        // handleOpenModal("nextModule");
                        handleOpenModal(linkedModulesType);
                        // clearAllVals();
                        setCautionBackbtn(true);
                      }}
                    >
                      {t("Edit Module List")}
                    </button>
                  </li>
                  <li
                    className={
                      props.openTimeLine
                        ? "gotoCardstimelineopen customScrollBar"
                        : "gotoCards customScrollBar"
                    }
                  >
                    {
                      <GoToCardsLeftConatiner
                        onclickBlueArrow={onclickBlueArrow}
                        openTimeLine={openTimeLine}
                        activeCards={appData.activeCards}
                        expandClaims={expandClaims}
                        veevaContentData={veevaContentData}
                        linkedModulesType={linkedModulesType}
                        setVeevaModuleCardData={setVeevaModuleCardData}
                        veevaModuleCardData={veevaModuleCardData}
                      />
                    }
                  </li>
                </ul>
              </div>
              // <div className="module-tabpane custonScrollBar">
              //   <div className="moduleSelection text-center">
              //     <div className="group-2813">
              //       <img src={Group2813} alt="" className="img-fluid" />
              //     </div>
              //     <div className="mod-sel">
              //       <h6 className="selBtn" onClick={handleOpenModal}>
              //         {" "}
              //         {t("modulesLeftContainer.selectmodule")}
              //       </h6>
              //       <div className="mod-start">
              //         <h6>{t("modulesLeftContainer.toGetStarted")}</h6>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            )}
          </div>
        ) : (
          <div
            style={{
              marginTop: openTimeLine ? "35px" : "0px",
              paddingTop: "10px",
            }}
          >
            <AntTabs
              value={moduleAndgridTab}
              variant="fullWidth"
              onChange={handleModuleandgridtabs}
              className="modgridTabs"
            >
              <AntTab label={t("Modules")} />
              <AntTab label={t("Grids")} />
            </AntTabs>

            <TabPanel
              value={moduleAndgridTab}
              index={0}
              //className={sequentialClick || tilesClick ? "brandTab" : ""}
            >
              <div className="importMod">{t("IMPORT MODULES")}</div>
              <div className="row">
                <div
                  className="col-md-4 nextMod"
                  onClick={() => {
                    handleOpenModal("nextModule");
                    clearAllVals();
                  }}
                >
                  <img src={NextModule} alt="NEXTmodules" />
                  <p>NEXT</p>
                </div>
                <div
                  className="col-md-4 nextMod"
                  onClick={() => {
                    handleOpenModal("veevaModule");
                    setOpenveevaModule(true);
                    setOpen(true);
                    clearAllVals();
                  }}
                >
                  <img src={VeevaModule} alt="veevaModules" />
                  <p>VEEVA</p>
                </div>
              </div>
            </TabPanel>
          </div>
          // <div className="module-tabpane custonScrollBar">
          //   <div className="moduleSelection text-center">
          //     <div className="group-2813">
          //       <img src={Group2813} alt="" className="img-fluid" />
          //     </div>
          //     <div className="mod-sel">
          //       <h6 className="selBtn" onClick={handleOpenModal}>
          //         {" "}
          //         {t("modulesLeftContainer.selectmodule")}
          //       </h6>
          //       <div className="mod-start">
          //         <h6>{t("modulesLeftContainer.toGetStarted")}</h6>
          //       </div>
          //     </div>
          //   </div>
          // </div>
        )}
      </div>
      {/* )} */}
      {open && (
        <DynDialogue
          values={values}
          setValues={setValues}
          className={openBussinessRules ? "hide-modal" : ""}
          // style={{ display:"none"}}
          genericInfo={appData?.genericInfo}
          open={open}
          handleClose={handleCloseModal}
          getAtomsList={getAtomsList}
          setOpen={setOpen}
          handleBussinessRulesModal={handleBussinessRulesModal}
          handleVeevaContentModal={handleVeevaContentModal}
          openVeevaContentModal={openVeevaContentModal}
          setOpenVeevaContentModal={setOpenVeevaContentModal}
          //  openBussinessRules={openBussinessRules}
          //setOpenBussinessRules={setOpenBussinessRules}
          cantExistCards={cantExistCards}
          setCantExistCards={setCantExistCards}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          toggleCards={toggleCards}
          setToggleCards={setToggleCards}
          setResponseData={setResponseData}
          responseData={responseData}
          cautionBackbtn={cautionBackbtn}
          setCautionBackbtn={setCautionBackbtn}
          setSelectedCardCaution={setSelectedCardCaution}
          selectedCardCaution={selectedCardCaution}
          linkedModulesType={linkedModulesType}
          veevaLoader={veevaLoader}
          veevaContentData={veevaContentData}
          backButtonClicked={backButtonClicked}
          setBackButtonClicked={setBackButtonClicked}
        />
      )}
      {openVeevaruleset && (
        <VeevaRuleSets
          responseData={responseData}
          setResponseData={setResponseData}
          veevaLoader={veevaLoader}
          closeVeevarulesetmodal={closeVeevarulesetmodal}
          handleVeevarulesetmodal={handleVeevarulesetmodal}
          openVeevaruleset={openVeevaruleset}
          setOpenveevaruleset={setOpenveevaruleset}
          veevaSelectedModuleId={veevaSelectedModuleId}
          cardDetails={cardDetails}
        />
      )}
      {!open && (
        <CustomModal
          genericInfo={appData?.genericInfo}
          responseData={responseData}
          setResponseData={setResponseData}
          displayModal={
            linkedModulesType === "nextModule"
              ? openBussinessRules
              : openVeevaContentModal
          }
          hideModal={
            linkedModulesType === "nextModule"
              ? closeBussinessRulesModal
              : closeVeevaContentModal
          }
          title={""}
          size="xl"
          dilogClassName="alertModalSmall"
          keyboard={false}
          open={
            linkedModulesType === "nextModule"
              ? handleBussinessRulesModal
              : handleVeevaContentModal
          }
          classes={classes}
          close={
            linkedModulesType === "nextModule"
              ? closeBussinessRulesModal
              : closeVeevaContentModal
          }
          setModules={setModules}
          modules={modules}
          modalOpenStatus={openBussinessRules}
          setOpenBussinessRules={setOpenBussinessRules}
          activeCards={activeCard}
          setOpen={setOpen}
          handleProceedbtn={handleProceedbtn}
          sendModuleid={sendModuleid}
          handleBackButton={handleBackButton}
          cautionBackbtn={cautionBackbtn}
          linkedModulesType={linkedModulesType}
          closeVeevaContentModal={closeVeevaContentModal}
          handleBackbtnVeeva={handleBackbtnVeeva}
          setVeevaRules={setVeevaRules}
          veevaRules={veevaRules}
          veevaSelectedModuleId={veevaSelectedModuleId}
          setVeevaSelectedModuleId={setVeevaSelectedModuleId}
          veevaLoader={veevaLoader}
          setVeevaLoader={setVeevaLoader}
          setOpenVeevaContentModal={setOpenVeevaContentModal}
          values={values}
          setVeevaContentData={setVeevaContentData}
          veevaContentData={veevaContentData}
          setActiveCard={setActiveCard}
          setCantExistCards={setCantExistCards}
          setToggleCards={setToggleCards}
          setVeevaModuleCardData={setVeevaModuleCardData}
          veevaModuleCardData={veevaModuleCardData}
          setValues={setValues}
          initialFvalues={initialFvalues}
          setLoadingModules={setLoadingModules}
        ></CustomModal>
      )}
    </>
  );
};

export default ModuleWindow;
