import React from "react";
import "../../styles/components/RightPanel.scss";

export default function Image({
  layerId,
  selectedComponent,
  Delete,
  ImageUpload,
  handleImage,
  URLValue,
  browseAndSelect,
  handleURL,

  URLUpdate,
  handleChange,
  expanded,
}) {
  return (
    <>
      {/*    <hr className="hrtag" /> */}
      {/*       <div className="accordion2">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel1" ? (
                <RemoveIcon className="accordIcons2" />
              ) : (
                <AddIcon className="accordIcons2" />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="accord2-summary">
              <div className="textProps">
                <div className="text">
                  {t("editorRightPanel.propertiesTabcontent.image")}
                </div>
              </div>
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <div className="image-sub-block">
              <div className="image-checkOptions ">
                <div className="image-text">Image URL</div>
                <TextField
                  className="standard-basic"
                  id="image-link"
                  value={URLValue}
                  onChange={handleURL}
                  autoComplete="off"
                  placeholder="Enter URL"
                  size="small"
                  // style={{ marginLeft: "-5px" }}
                  inputProps={{
                    style: {
                      // width: "80px",
                      marginTop: "-14px",
                      marginBottom: "-15px",
                      fontSize: "11px",
                      color: "#928989",
                      textAlign: "left",
                    },
                  }}
                />
                <button
                  className={
                    URLValue == ""
                      ? " btn btn-link bnt-disabled"
                      : "btn ml-2 pl-0 pr-0"
                  }
                  disabled={URLValue == "" ? true : false}
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "rgb(111, 189, 245)",
                  }}
                  onClick={URLUpdate}
                >
                  Upload
                </button>
              </div>
              {layerId.type !== "Image" &&
              selectedComponent?.componentType.background !== "" ? (
                <div className="image-Delete">
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      background: `url(${selectedComponent?.componentStyles.background}) no-repeat center center / cover`,
                      // backgroundSize: "",
                    }}
                    className="image-thumbnail"
                  ></div>
                  <div className="delete-image">
                    <p>{selectedComponent?.componentStyles.filename}</p>
                    <button onClick={Delete} className="btn btn-link-red pl-0">
                      Delete
                    </button>
                  </div>
                  <div></div>
                </div>
              ) : (
                <div className="Image-upload">
                  <img
                    alt="img-uplaod"
                    src={ImageUpload}
                    className="toggleIcon img-fluid upload-Image"
                  />
                  <label htmlFor="file-input">
                    <p className="Image-text">Click here to add image</p>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ opacity: 0 }}
                      onChange={(e) => {
                        browseAndSelect(e);
                      }}
                    />
                  </label>
                </div>
              )}
              <div>
                <div className="image-checkOptions">

              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div> */}
    </>
  );
}
