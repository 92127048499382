import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import App from "./App";
import "./i18n";
// import 'froala-editor/css/froala_style.min.css';
// import 'froala-editor/css/froala_editor.pkgd.min.css';

import "froala-editor/css/froala_editor.pkgd.min.css";

import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
//import "font-awesome/css/font-awesome.css";
window.$pagesInModule = 0;
// window.$moduleName = 'Untitled';
window.$moduleId = "6c3e7428-676e-4d0e-8ce8-b3fa1e742d65"; //'49b0c664-2644-4014-a410-27b4144ef42e';
// window.$moduleNumber = '#321'
ReactDOM.render(
  <Suspense fallback={<div>Loading</div>}>
    <BrowserRouter>
      {/* <AppContextProvider> */}
      <App />
      {/* </AppContextProvider> */}
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
