import React, { useEffect, useState } from 'react';
import '../styles/components/ModuleSpecificInfo.scss';
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const ModuleSpecificInfo = (props) => {
    const { t } = useTranslation();
    const [module, setModule] = useState(undefined)
    const [pageJsx, setPageJsx] = useState(undefined)

        useEffect(() => {
            if(props.module){
                if(module && module.id && module.id !== props.module.id){
                    setPageJsx(undefined)
                }
                setModule(props.module)
            }
        }, [props])

        useEffect(() => {
            if(props.setJsx){
                props.setJsx(pageJsx)
            }
        }, [pageJsx])



    const getCoreClaimCategories = ()=>{
        if(!module){
            return ''
        }
        const coreClaimCategories = module.moduleCoreCategories
        if(!coreClaimCategories || coreClaimCategories.length <= 0){
            return ''
        }
        const categories = coreClaimCategories.map((category) => {
            return category.coreCategory
        })
        return categories.toString()
    }

    const getCoreClaim = ()=>{

        if(!module){
            return
        }
        const coreClaims = module.moduleClaims
        if(!coreClaims || coreClaims.length <= 0){
            return ''
        }
        let claims = coreClaims.filter((claim) => {
            return claim.type === 'Core'
        })
        claims = claims.map((claim) => {
            return claim.claim
        })
        return claims.toString()
    }

    const getRecommendationOnModuleUsage = () => {
        if(!module){
            return ''
        }
        const addInfo = module.additionalInformations
        if(addInfo && addInfo.length > 0){
            const info = addInfo[0]
            if(info && info.information){
                const recommendationOnModuleUsage = info.information.moduleUsageRecommendation
                if(recommendationOnModuleUsage){
                    //return recommendationOnModuleUsage.replace(/(<([^>]+)>)/ig,"").replace(/[[\]]/g,'')
                    return parse(recommendationOnModuleUsage)
                }
            }
        }
        return ''
    }

    const getGenericBusinessRules = () => {
        if(!module){
            return ''
        }
        const addInfo = module.additionalInformations
        if(addInfo && addInfo.length > 0){
            const info = addInfo[0]
            if(info && info.information){
                const genericBusinessRules = info.information.genericBusinessRules
                if(genericBusinessRules){
                    //return recommendationOnModuleUsage.replace(/(<([^>]+)>)/ig,"").replace(/[[\]]/g,'')
                    return parse(genericBusinessRules)
                }
            }
        }
        return ''
    }

    const getPageNumber = () => {
        // let pageNumber = window.$pagesInModule
        // if(!module){
        //     return 0
        // }
        // const addInfo = module.additionalInformations
        // if(addInfo && addInfo.length > 0){
        //     const info = addInfo[0].information
        //     if(info && info.pageNumber){
        //         pageNumber =  info.pageNumber
        //     }
        // }
        // return pageNumber

        if(!module){
            return ''
        }
        if(module.pageNumber){
            return module.pageNumber
        }

    }

    const getAudience = () => {
        if(!module){
            return
        }
        const assetDetails = module.assetDetails
        if(assetDetails){
            const audience  = assetDetails.audience
            if(audience && audience.length > 0){
                return audience.toString().replace(/,[s]*/g, ", ");
            }
        }

    }

    const getDosage = () => {

        if(!module){
            return
        }
        const addInfo = module.additionalInformations
        if(addInfo && addInfo.length > 0){
            const desiredAddInfo = addInfo[0]
            if(desiredAddInfo){
                const information = desiredAddInfo.information
                if(information && information.dosage){
                    return information.dosage
                }
            }
        }
            const assetDetails = module.assetDetails
            if(assetDetails && assetDetails.dosing){
                return assetDetails.dosing
            }
    }

    const getModuleRating = () => {
            if(!module){
                return ''
            }
            const addInfo = module.additionalInformations
            if(addInfo && addInfo.length > 0){
                const desiredAddInfo = addInfo[0]
                if(desiredAddInfo){
                    const information = desiredAddInfo.information
                    if(information && information.moduleRating){
                        return information.moduleRating
                    }
                }
            }
            return ''
    }

    const renderData = () => {
        
        if(!module){
            return (
                <div></div>
            )
        }
        const jsx  = (
            <div>
                <table className='module-specific table'>
                    <tbody>
                        <tr className = 'header'>
                            <td >{t("moduleSpecificInfo.moduleInfo")}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.name")}</td>
                            <td className = 'text-black'>{module.name}</td>
                        </tr>
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.id")}</td>
                            <td className = 'text-black'>{module.moduleCode}</td>
                        </tr>
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.pageNo")}</td>
                            <td className = 'text-black'>{getPageNumber()}</td>
                        </tr>
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.coreClaimCategory")}</td>
                            <td className = 'text-black'>{getCoreClaimCategories()}</td>
                        </tr>
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.coreClaim")}</td>
                            <td className = 'text-black'>{getCoreClaim()}</td>
                        </tr>
                        {/* <tr>
                            <td className = 'tbl-row-txt-bold'>Core claim</td>
                            <td className = 'text-black'>{getCoreClaim()}</td>
                        </tr> */}
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.audience")}</td>
                            <td className = 'text-black'>{getAudience()}</td>
                        </tr>
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.dosage")}</td>
                            <td className = 'text-black'>{getDosage()}</td>
                        </tr>
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.moduleRating")}</td>
                            <td className = 'text-black'>{getModuleRating()}</td>
                        </tr>
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.genericBusinessRules")}</td>
                            <td className = 'text-black'>{getGenericBusinessRules()}</td>
                        </tr>
                        <tr>
                            <td className = 'tbl-row-txt-bold'>{t("moduleSpecificInfo.recommendationOnModuleUsage")}</td>
                            <td className = 'text-black'>{getRecommendationOnModuleUsage()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
            if(!pageJsx){
                setPageJsx(jsx)
            }
        return jsx
    }


    return (
        <div>
            {renderData()}
        </div>
    )
    
}

export default ModuleSpecificInfo