export function scroll() {
  let isSyncingLeftScroll = false;
  let isSyncingRightScroll = false;
  let leftDiv = document.getElementById("leftDiv");
  let rightDiv = document.getElementById("scrollDiv");

  leftDiv.onscroll = function () {
    if (!isSyncingLeftScroll) {
      isSyncingRightScroll = true;
      rightDiv.scrollTop = this.scrollTop;
    }
    isSyncingLeftScroll = false;
  };

  rightDiv.onscroll = function () {
    if (!isSyncingRightScroll) {
      isSyncingLeftScroll = true;
      leftDiv.scrollTop = this.scrollTop;
    }
    isSyncingRightScroll = false;
  };
  // var scrollBarContainer = document.getElementById("scroll");
  // var anchor = document.getElementById("anchor");
  // var container = document.getElementById("parentID");
  // var content = document.querySelectorAll("#scrollDiv");
  // var elem = document.querySelectorAll("#element-Name");
  // var containerHeight = container.offsetHeight;
  // var contentHeight = 0;
  // var EleContentHeight = 0;
  // console.log(content);
  // for (var i = 0; i < content.length; i++) {
  //   contentHeight += content[i].scrollHeight;
  //   EleContentHeight += content[i].scrollHeight;
  // }
  // anchor.style.height =
  //   Math.floor((containerHeight / contentHeight) * containerHeight) + "px";
  // var flag_mouseDown = false;
  // var oldMousePosition = 0;
  // var oldAnchorPosition = 0;
  // scrollBarContainer.addEventListener("mousedown", function (e) {
  //   if (e.target.nodeName === "DIV") {
  //     var distance = e.clientY - container.offsetTop - anchor.offsetHeight / 2;
  //     if (distance < 0) {
  //       distance = 0;
  //     } else if (distance + anchor.offsetHeight > containerHeight) {
  //       distance = containerHeight - anchor.offsetHeight;
  //     }
  //     anchor.style.top = distance + "px";
  //     moveContent();
  //   } else if (e.target.nodeName === "SPAN") {
  //     flag_mouseDown = true;
  //     oldMousePosition = e.clientY;
  //     oldAnchorPosition = anchor.offsetTop;
  //   }
  // });
  // scrollBarContainer.addEventListener("mouseup", function (e) {
  //   flag_mouseDown = false;
  //   oldMousePosition = e.clientY;
  //   oldAnchorPosition = anchor.offsetTop;
  // });
  // scrollBarContainer.addEventListener("mousemove", function (e) {
  //   if (!flag_mouseDown) {
  //     return;
  //   }
  //   var distance = e.clientY - oldMousePosition;
  //   var newPosition = oldAnchorPosition + distance;
  //   if (newPosition < 0) {
  //     newPosition = 0;
  //   } else if (newPosition + anchor.offsetHeight > containerHeight) {
  //     newPosition = containerHeight - anchor.offsetHeight;
  //   }
  //   anchor.style.top = newPosition + "px";
  //   moveContent();
  // });
  // var moveContent = function () {
  //   var postion = anchor.offsetTop / (containerHeight - anchor.offsetHeight);
  //   var newPosition = 0 - postion * (contentHeight - containerHeight) + "px";
  //   console.log(
  //     anchor.offsetTop,
  //     container.offsetTop,
  //     containerHeight,
  //     anchor.offsetHeight
  //   );
  //   console.log(postion, contentHeight, containerHeight);
  //   for (var i = 0; i < content.length; i++) {
  //     content[i].style.marginTop = newPosition;
  //     elem[i].style.marginTop = newPosition;
  //   }
  // };
}
