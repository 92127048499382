import React from "react";
import "../../styles/pages/Login.scss";
import bannerImage from "../../images/banner1.svg";
import nextLogo from "../../images/NEXT-Banner.svg";
import { useTranslation } from "react-i18next";

export default function BackgroundContent(props) {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="banner-text" id="bannerTxt">
          <h1 className="homePageTitle">
            <img src={nextLogo} alt="" width="300" className="img-fluid" />
          </h1>

          <div className="banner01">
            <img src={bannerImage} alt="Banner" className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
}
