import React from "react";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import "../../styles/components/Editor.scss";
import "../../styles/components/EditorModal.scss";
import "../../styles/components/Preview.scss";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Refresh from "../../images/Refresh.svg";

export default function PreviewModal(props) {
  const { t } = useTranslation();

  React.useEffect(() => {}, []);

  const handleCloseExport = () => {
    props.setPreview(false);
  };
  const refreshIframe = () => {
    document.getElementById("FrameID").src += "";
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        open={props.showPreview}
        onClose={() => props.setPreview(false)}
        className="preview exportAsModal"
        aria-labelledby="form-dialog-title"
        overlaystyle={{ backgroundColor: "transparent" }}
      >
        <DialogTitle id="form-dialog-title" id="customized-dialog-title">
          <div className="baseOnModalHeading">
            {" "}
            {t("previewLabel.Heading")} : {props.previewURl.fileName}
          </div>
          <div className="refreshButtonpreview">
            <IconButton aria-label="close" onClick={refreshIframe}>
              <img
                style={{ width: "12px", height: "24px" }}
                src={Refresh}
                alt=""
                className="img-fluid"
              />
            </IconButton>
            <IconButton aria-label="close" onClick={handleCloseExport}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            overflow: "hidden",
          }}
        >
          <iframe
            id="FrameID"
            className="preview-iframe customScrollBar"
            frameBorder="0"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            src={props.previewURl.discription}
          ></iframe>
        </DialogContent>
      </Dialog>
    </>
  );
}
