import React, { useState } from "react";
import { Form, Formik, ErrorMessage } from 'formik';
import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "../../Uitls/Userpools";
import * as yup from "yup";
const ForgotPassword = () => {
    const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
    const [email, setEmail] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [showForgotErrorMsg, setShowForgotErrorMsg] = useState(false);
    const [showErrorMsg, setshowErrorMsg] = useState(false);

    const validationSchema= yup.object().shape({
        email: yup.string().email('Invalid email address').required('Email address is required')
    });

    const validationSchemaReset = yup.object().shape({
        verificationCode: yup.string().required('Verification code is required'),
        resetPassword: yup.string().required('Password is required'),
        reResetPassword: yup.string()
        .required('Confirm password is required')
        .oneOf([yup.ref('resetPassword'), null], 'Passwords must match')
    });
    
    const getUser = (value) => {
      console.log(value);
      setEmail(value);
      const getUserEmail = value;
      return new CognitoUser(
        {
        Username: getUserEmail.toLowerCase(),
        Pool
      });
    };   

    return(
        <div className="container-fluid loginBg">
            <div className="row align-itmes-center">
            <div className="col-md-3 offset-md-9">
                <div className="signInBox">
             
              {stage === 1 && (
              <Formik
                initialValues={{ email: '' }}
                validationSchema = {validationSchema}
                onSubmit ={(values) => {
                    const user = values.email
                  //  console.log(values.email);
                  //  setEmail(values.email);
                    getUser(user).forgotPassword({
                      onSuccess: data => {
                        console.log("onSuccess:", data);
                      },
                      onFailure: err => {
                        console.error("onFailure:", err);
                        setErrorMsg(err.message);
                        setShowForgotErrorMsg(true);
                        setTimeout(() => {
                          setShowForgotErrorMsg(false);
                          setErrorMsg("");
                        }, 5000);

                      },
                      inputVerificationCode: data => {
                        console.log("Input code:", data);
                        setStage(2);
                      }
                    });

                  }}
              >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                status,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  { showForgotErrorMsg ? <div className="alert alert-warning alert-text" role="alert">{errorMsg}</div> :""}
                  <h1 className="appLogoText col-md-12 mb-3">Forgot Password</h1>
                  <div className="form-group text-left position-relative mb-4">
                  <label htmlFor="userName" className={'loginLabel' + (errors.email && touched.email ? ' label-error' : '')}>Email:</label>
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={'form-control loginInput' + (errors.email && touched.email ? ' is-invalid' : '')}
                    id="userName"
                  />
                  <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-block">Reset password</button>
                  </div>
                 
                </Form>
                )}
              </Formik>
            )}

            {stage === 2 && (
                <>
                <p>Step 2</p>
                <div className="alert alert-info text-break">
                    We have sent a reset password email to <span className="font-weight-bold">{email}</span>. Please enter the verification code you have received. 
                </div>

                <Formik
                initialValues={{ verificationCode: '', resetPassword:'', reResetPassword:'' }}
                validationSchema = {validationSchemaReset}
                onSubmit ={(values) => {                
                    getUser(email).confirmPassword(values.verificationCode, values.resetPassword, {
                      onSuccess: data => {
                        console.log("onSuccess:", data);
                      },
                      onFailure: err => {
                        console.error("onFailure:", err);
                        setErrorMsg(err.message);
                        setshowErrorMsg(true);
                        setTimeout(() => {
                          setshowErrorMsg(false);
                          setErrorMsg("")
                        }, 5000);
                      }
                    });
                  }}
              >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                status,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  { showErrorMsg ? <div className="alert alert-warning alert-text" role="alert">{errorMsg}</div> :""}
                  
                  <div className="form-group text-left position-relative mb-4">
                  <label htmlFor="userName" className={'loginLabel' + (errors.verificationCode && touched.verificationCode ? ' label-error' : '')}>Verification Code:</label>
                    <input
                        type="text"
                        name="verificationCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.verificationCode}
                        className={'form-control loginInput' + (errors.verificationCode && touched.verificationCode ? ' is-invalid' : '')}
                        id="userName"
                    />
                    <ErrorMessage name="verificationCode" component="div" className="invalid-feedback" />
                  </div>

                  <div className="form-group text-left position-relative mb-4">
                    <label htmlFor="userName" className={'loginLabel' + (errors.resetPassword && touched.resetPassword ? ' label-error' : '')}>Password:</label>
                    <input
                        type="password"
                        name="resetPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.resetPassword}
                        className={'form-control loginInput' + (errors.resetPassword && touched.resetPassword ? ' is-invalid' : '')}
                        id="resetPassword"
                    />
                    <ErrorMessage name="resetPassword" component="div" className="invalid-feedback" />
                  </div>

                  <div className="form-group text-left position-relative mb-4">
                    <label htmlFor="reResetPassword" className={'loginLabel' + (errors.reResetPassword && touched.reResetPassword ? ' label-error' : '')}>Confirm Password:</label>
                    <input
                        type="password"
                        name="reResetPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reResetPassword}
                        className={'form-control loginInput' + (errors.reResetPassword && touched.reResetPassword ? ' is-invalid' : '')}
                        id="reResetPassword"
                    />
                    <ErrorMessage name="reResetPassword" component="div" className="invalid-feedback" />
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-block">Change password</button>
                  </div>
                 
                </Form>
                )}
              </Formik>
                </>
            )}
                </div>
            </div>
        </div>
      </div>
    );
}
export default ForgotPassword