import React, { useEffect, useState, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import parseHtml from "html-react-parser";
import CheckBox from "../../Uitls/CheckBox";
import { LocalizedBusinessrulesModules } from "../../services/apis";
import bluedot from "../../images/bluedot.svg";
import leftArrow from "../../images/left.svg";
import rightArrow from "../../images/right.svg";
import InfoIcon from "../../images/infoIconbackbtn.svg";
import "../../styles/pages/LocalizedModulesCss.scss";
import { blue } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import ValueContext from "../../ContextAPi/appContext";
const BussinessRulesDialogue = ({
  modules,
  setModules,
  selectedModuleIds,
  handleModal,
  handleProceed,
  prevSelectedData,
  setOpenBussinessRules,
  activeCards,
  setOpen,
  handleProceedbtn,
  sendModuleid,
  handleBackButton,
  cautionBackbtn,
  linkedModulesType,
  setValues,
  initialFvalues,
}) => {
  const { t } = useTranslation();
  const [selectedModule, setSelectedModule] = useState({ moduleId: [] });

  const [businessRules, setBusinessRules] = useState([]);

  const [BIndex, setBIndex] = useState(0);

  const [deselectedAssociatedModules, setDeselectedAssociatedModules] =
    useState([]);

  const [selectedTabPanel, setSelectedTabPanel] = useState("mustUse");
  const [deselectedModule, setDeselectedModule] = useState(0);
  // const deselectedModule = deselectedAssociatedModules.filter(
  //   (module) => module.type === "mustExist"
  // ).length;
  //   {
  //   must: true,
  //   precedes: false,
  //   succeeds: false,
  //   related: false,
  // }

  const appData = useContext(ValueContext);

  useEffect(() => {
    // if (prevSelectedData) {
    //   setSelectedModule(prevSelectedData.selectedModule);
    //   setBusinessRules(prevSelectedData.businessRules);
    //   setDeselectedAssociatedModules(
    //     prevSelectedData.deselectedAssociatedModules
    //   );
    //   setSelectedTabPanel(prevSelectedData.selectedTabPanel);
    //   const bussinessRulesData = [];
    //   prevSelectedData.businessRules.forEach((item) => {
    //     let allModuleData = [];
    //     allModuleData.push({
    //       parentModuleId: item.moduleId,
    //     });
    //     if (item.mustExistWith.length > 0) {
    //       item.mustExistWith.forEach((element) => {
    //         allModuleData.push({
    //           moduleId: element.moduleId,
    //           type: "mustExist",
    //           parentModuleId: item.moduleId,
    //         });
    //       });
    //     }
    //     if (item.precedes.length > 0) {
    //       item.precedes.forEach((element) => {
    //         allModuleData.push({
    //           moduleId: element.moduleId,
    //           type: "preceeds",
    //           parentModuleId: item.moduleId,
    //         });
    //       });
    //     }
    //     if (item.relatedTo.length > 0) {
    //       item.relatedTo.forEach((element) => {
    //         allModuleData.push({
    //           moduleId: element.moduleId,
    //           type: "related",
    //           parentModuleId: item.moduleId,
    //         });
    //       });
    //     }
    //     if (item.succeeds.length > 0) {
    //       item.succeeds.forEach((element) => {
    //         allModuleData.push({
    //           moduleId: element.moduleId,
    //           type: "succeeds",
    //           parentModuleId: item.moduleId,
    //         });
    //       });
    //     }
    //     bussinessRulesData.push.apply(bussinessRulesData, allModuleData);
    //   });
    // } else {
    //   fetchAssociatedModules();
    // }
    fetchAssociatedModules();
  }, []);

  const fetchAssociatedModules = () => {
    // const moduleId = modules
    //   .filter((module) => module.isParent === true)
    //   .map((module) => module.moduleId);
    const selectedModules = selectedModuleIds || { moduleId: [] };

    LocalizedBusinessrulesModules(selectedModules, (response, err, errCode) => {
      if (err) {
      } else {
        // Object.keys(response.data).forEach((item) => {
        //   console.log(item);
        //   item.mustExistWith.forEach((must) => {
        //     must.status = true;
        //   });
        //   item.precedes.forEach((precedes) => {
        //     precedes.status = true;
        //   });
        // });

        const bussinessRulesData = [];

        response.data.forEach((item) => {
          let allModuleData = [];
          allModuleData.push({
            parentModuleId: item.moduleId,
          });
          if (item.mustExistWith.length > 0) {
            item.mustExistWith.forEach((element) => {
              element.status = true;
              allModuleData.push({
                moduleId: element.moduleId,
                type: "mustExist",
                parentModuleId: item.moduleId,
              });
            });
          }
          if (item.precedes.length > 0) {
            item.precedes.forEach((element) => {
              element.status = true;
              allModuleData.push({
                moduleId: element.moduleId,
                type: "preceeds",
                parentModuleId: item.moduleId,
              });
            });
          }
          if (item.relatedTo.length > 0) {
            item.relatedTo.forEach((element) => {
              element.status = false;
              allModuleData.push({
                moduleId: element.moduleId,
                type: "related",
                parentModuleId: item.moduleId,
              });
            });
          }
          if (item.succeeds.length > 0) {
            item.succeeds.forEach((element) => {
              element.status = true;
              allModuleData.push({
                moduleId: element.moduleId,
                type: "succeeds",
                parentModuleId: item.moduleId,
              });
            });
          }
          bussinessRulesData.push.apply(bussinessRulesData, allModuleData);
        });

        defaultCheckingModules(bussinessRulesData);
        setBusinessRules(response.data);
        const selectedModule = response.data[0];
        setSelectedModule(selectedModule);
      }
    });
  };
  const defaultCheckingModules = (bussinessRulesData) => {
    // setDeselectedAssociatedModules(bussinessRulesData);
  };

  const setSelectedModuleHandler = (moduleId) => {
    const result = businessRules.find(function (element) {
      if (element.moduleId === moduleId) {
        return true;
      } else {
        return false;
      }
    });

    setSelectedModule(result);
  };

  const getLeftSideBRuleModules = (parentModule, ruleType) => {
    let finalModules = [];

    switch (ruleType) {
      case "mustExist":
        finalModules = parentModule?.mustExistWith;
        break;
      case "preceeds":
        finalModules = parentModule?.precedes;
        if (finalModules?.length > 0) {
          const mustExistWith = parentModule?.mustExistWith;
          finalModules = [...finalModules].filter((module) => {
            return (
              mustExistWith?.findIndex((mustExist) => {
                return mustExist?.moduleId === module?.moduleId;
              }) === -1
            );
          });
        }
        break;
      case "succeeds":
        finalModules = parentModule.succeeds;
        if (finalModules?.length > 0) {
          const otherModules = parentModule?.mustExistWith.concat(
            parentModule?.precedes
          );
          finalModules = [...finalModules].filter((module) => {
            return (
              otherModules?.findIndex((mustExist) => {
                return mustExist?.moduleId === module?.moduleId;
              }) === -1
            );
          });
        }
        break;
      case "related":
        finalModules = parentModule?.relatedTo;
        if (finalModules?.length > 0) {
          const otherModules = parentModule?.mustExistWith
            .concat(parentModule?.precedes)
            .concat(parentModule?.succeeds);
          finalModules = [...finalModules].filter((module) => {
            return (
              otherModules?.findIndex((mustExist) => {
                return mustExist?.moduleId === module?.moduleId;
              }) === -1
            );
          });
        }

        break;
      default:
        break;
    }
    return finalModules;
  };

  const onNextModuleButtonClick = () => {
    const curentSelectedModuleId = selectedModule.moduleId;
    const index = businessRules.findIndex((module) => {
      return module.moduleId === curentSelectedModuleId;
    });

    if (index === businessRules.length - 1) {
      setSelectedModule(businessRules[businessRules.length - 1]);
      return;
    }
    setBIndex(index + 1);
    setSelectedModule(businessRules[index + 1]);
  };
  const onPreviousModuleButtonClick = () => {
    const curentSelectedModuleId = selectedModule?.moduleId;

    const index = businessRules.findIndex((module) => {
      return module.moduleId === curentSelectedModuleId;
    });

    if (index === 0) {
      setSelectedModule(businessRules[0]);
      return;
    }
    setBIndex(index - 1);
    setSelectedModule(businessRules[index - 1]);
  };
  const togglePanel = (type) => {
    if (type === selectedTabPanel) {
      setSelectedTabPanel("");
    } else {
      setSelectedTabPanel(type);
    }
    // let tempSelected = { ...selectedTabPanel };
    // tempSelected[type] = !tempSelected[type];
    // setSelectedTabPanel(tempSelected);
  };

  const handleCheckBoxChange = (moduleId, type, event) => {
    const isChecked = event.target.checked;

    const modules = [...businessRules];
    let x = 0;
    const index = modules.forEach((module) => {
      module[type].forEach((item, mIndex) => {
        if (
          module.moduleId === selectedModule.moduleId &&
          item.moduleId === moduleId
        ) {
          //  module[type][mIndex].item.status = event.target.checked;
          item.status = event.target.checked;

          return;
        }
      });
    });
    let c = 0;
    if (type === "mustExistWith") {
      let x =
        isChecked !== true
          ? (c = deselectedModule + 1)
          : deselectedModule > 0
          ? (c = deselectedModule - 1)
          : (c = deselectedModule);
      setDeselectedModule(c);
    }
    setBusinessRules(modules);
    // if (isChecked && isDeSelected(moduleId, type)) {
    //   const index = deselectedAssociatedModules.findIndex((module) => {
    //     return (
    //       module.parentModuleId === selectedModule.moduleId &&
    //       module.moduleId === moduleId &&
    //       module.type === type
    //     );
    //   });
    //   const modules = [...deselectedAssociatedModules];
    //   modules.splice(index, 1);
    //   setDeselectedAssociatedModules(modules);
    // } else if (!isChecked && !isDeSelected(moduleId, type)) {
    //   setDeselectedAssociatedModules(
    //     [...deselectedAssociatedModules].concat({
    //       parentModuleId: selectedModule.moduleId,
    //       type: type,
    //       moduleId: moduleId,
    //     })
    //   );
    // }
  };

  // const renderComplianceIssueMessage = () => {
  //   const totalDeselected = totalMustExistDeselectedModules();
  //   if (totalDeselected > 0) {
  //     return `${totalDeselected} Must Exist ${
  //       totalDeselected > 1 ? "Modules are" : "Module is"
  //     } de-selected.
  //     This could result in compliance issues.`;
  //   }
  // };

  const handleGotoEditor = () => {
    let moduleBusinessRules = [];

    businessRules.forEach((bModule) => {
      let newObject = {
        moduleId: bModule.moduleId,
        moduleCode: bModule.moduleCode,
        moduleName: bModule.moduleName,
        campaignName: bModule.campaignName,
      };
      // const moduleId = businessRules
      //   .filter((module) => module.isParent === true)
      //   .map((module) => module.moduleId);
      // setModules(moduleId);
      let precedes = [];
      if (bModule.precedes.length > 0)
        bModule.precedes.forEach((pModule) => {
          if (pModule.status) {
            let Pobject = {
              moduleId: pModule.moduleId,
              moduleName: pModule.moduleName,
              moduleCode: pModule.moduleCode,
            };
            precedes.push(Pobject);
          }
        });
      newObject.precedes = precedes;

      //RelatedTo
      let relatedTo = [];
      if (bModule.relatedTo.length > 0)
        bModule.relatedTo.forEach((pModule) => {
          if (pModule.status) {
            let Pobject = {
              moduleId: pModule.moduleId,
              moduleName: pModule.moduleName,
              moduleCode: pModule.moduleCode,
            };
            relatedTo.push(Pobject);
          }
        });
      newObject.relatedTo = relatedTo;

      //succeeds
      let succeeds = [];
      if (bModule.succeeds.length > 0)
        bModule.succeeds.forEach((pModule) => {
          if (pModule.status) {
            let Pobject = {
              moduleId: pModule.moduleId,
              moduleName: pModule.moduleName,
              moduleCode: pModule.moduleCode,
            };
            succeeds.push(Pobject);
          }
        });
      newObject.succeeds = succeeds;

      //MustExistWidth
      let mustExistWith = [];
      if (bModule.mustExistWith.length > 0)
        bModule.mustExistWith.forEach((pModule) => {
          if (pModule.status) {
            let Pobject = {
              moduleId: pModule.moduleId,
              moduleName: pModule.moduleName,
              moduleCode: pModule.moduleCode,
            };
            mustExistWith.push(Pobject);
          }
        });
      newObject.mustExistWith = mustExistWith;

      //inCompatibleWith
      let inCompatibleWith = [];
      if (bModule.inCompatibleWith.length > 0)
        bModule.inCompatibleWith.forEach((pModule) => {
          if (pModule.status) {
            let Pobject = {
              moduleId: pModule.moduleId,
              moduleName: pModule.moduleName,
              moduleCode: pModule.moduleCode,
            };
            inCompatibleWith.push(Pobject);
          }
        });
      newObject.inCompatibleWith = inCompatibleWith;

      moduleBusinessRules.push(newObject);
    });

    sendModuleid(moduleBusinessRules);
    // console.log("moduleBusinessRules", moduleBusinessRules);
  };

  // const checkModuleIndex = (position) => {
  //   const index = businessRules.findIndex((module) => {
  //     return module.moduleId === selectedModule?.moduleId;
  //   });

  //   return position === "first"
  //     ? index > 0
  //       ? false
  //       : true
  //     : index === businessRules.length - 1
  //     ? true
  //     : false;
  // };

  const isNextButtonDisabled = businessRules.length <= 1;
  // const totalMustExistDeselectedModules = () => {
  //   return deselectedAssociatedModules.filter((module) => {
  //     return module.type === "mustExist";
  //   }).length;
  // };
  const isDeSelected = (moduleId, type) => {
    const index = deselectedAssociatedModules.findIndex((module) => {
      return (
        module.parentModuleId === selectedModule.moduleId &&
        module.moduleId === moduleId &&
        type === module.type
      );
    });
    return index > -1;
  };
  const handleClose = () => {
    setSelectedTabPanel("mustUse");
    setDeselectedAssociatedModules([]);
    setOpenBussinessRules(false);
    setValues({
      ...initialFvalues,
    });
    appData.updateGenericInfo({
      ...appData?.genericInfo,
      searchedFields: initialFvalues,
    });
  };
  // console.log(businessRules, "deselectedAssociatedModules");
  return (
    <div>
      <div className="row mb-4 mt-4">
        <div className="col-md-12 associatedModuleTitle">
          Select Associated Modules
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 selectedModuleIdColumn moduleIdsScrollbar">
          {BIndex > -1 &&
            businessRules.map((item, businessIndex) => {
              return (
                <>
                  <div
                    className={
                      selectedModule?.moduleId === item?.moduleId
                        ? "associatedModuleActiveBrdr"
                        : ""
                    }
                    key={businessIndex + "businessRules"}
                    // className="associatedModuleActiveBrdr"
                    // style={{ backgroundColor: moduleClick ? "#eef5fa" : "" }}
                    // onClick={() => {
                    //   setModuleClick(!moduleClick);
                    // }}
                  >
                    <button
                      onClick={() => {
                        setSelectedModuleHandler(item.moduleId);
                        setBIndex(businessIndex);
                        // setSelectedTabPanel({
                        //   must: true,
                        //   precedes: false,
                        //   succeeds: false,
                        //   related: false,
                        // });
                      }}
                      className={`btn btn-link  associatedModuleIdTitle ${
                        selectedModule?.moduleId === item?.moduleId
                          ? "associatedModuleActive"
                          : ""
                      }`}
                    >
                      {" "}
                      <img
                        src={bluedot}
                        className="img-fluid bluedotImage"
                        alt=""
                      />
                      {item?.moduleCode && <b>{item?.moduleCode}</b>}
                    </button>
                  </div>

                  {selectedModule.moduleId === item.moduleId && (
                    <ul className="associatedBsModuleTitle">
                      {getLeftSideBRuleModules(item, "mustExist")?.map((sm) => (
                        <li
                          className={`${
                            isDeSelected(sm.moduleId, "mustExist")
                              ? "deselected-business-rule-module-code"
                              : ""
                          }`}
                        >
                          {sm.moduleCode}
                        </li>
                      ))}
                      {getLeftSideBRuleModules(item, "preceeds").map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                      {getLeftSideBRuleModules(item, "succeeds").map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                      {getLeftSideBRuleModules(item, "related").map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                    </ul>
                  )}
                </>
              );
            })}
        </div>
        <div
          className="col-md-10"
          style={{
            paddingRight: "7px",

            left: "4px",
            borderLeft: "solid 1px #e1e1e1",
          }}
        >
          <div className="row align-items-center mb-3 moduleNameDiv">
            {/* <div className="col-md-1 ">
              <img
                src={leftArrow}
                className="img-fluid leftArrowBsrules"
                onClick={onPreviousModuleButtonClick}
                alt=""
              />
            </div> */}
            <div className="col-md-3">
              <span className="associatedBsModuleSubtitle">Campaign Name</span>
              <p className="associatedBsModuleSubText">
                {selectedModule?.campaignName}
              </p>
            </div>
            <div className="col-md-3">
              <span className="associatedBsModuleSubtitle">Module Name</span>
              <p className="associatedBsModuleSubText ">
                {selectedModule?.moduleName}
              </p>
            </div>
            <div className="col-md-3">
              <button
                onClick={onPreviousModuleButtonClick}
                // disabled={BIndex <= 0 ? true : false}
                disabled={BIndex <= 0}
                className={
                  BIndex < 0
                    ? "btn   text-uppercase btnNextModule btn-lg "
                    : "btn btn-outline  text-uppercase btnNextModule btn-lg "
                }
              >
                Previous Module
              </button>
            </div>
            <div className="col-md-2 text-right">
              <button
                onClick={onNextModuleButtonClick}
                disabled={
                  BIndex === businessRules.length - 1
                  //checkModuleIndex("last")
                }
                className="btn btn-outline text-uppercase btnNextModule btn-lg"
              >
                Next Module
              </button>
              {/* <img
                src={rightArrow}
                className="img-fluid rightArrowBsrules"
                alt=""
                onClick={onNextModuleButtonClick}
                disabled={isNextButtonDisabled}
              /> */}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 associatedModuleTitle2 mb-2">
              Business Rules
            </div>
          </div>
          <div className=" localizedAccordion customScrollBar">
            {selectedModule?.mustExistWith?.length >= 0 && (
              <Accordion defaultActiveKey="0">
                {selectedModule?.mustExistWith &&
                  selectedModule?.mustExistWith?.length >= 0 && (
                    <div className="bsrulesAccordion">
                      <Card.Header>
                        <Accordion.Toggle
                          eventKey="0"
                          variant="link"
                          className="associatedModuleTitle"
                          onClick={() => {
                            togglePanel("mustUse");
                          }}
                        >
                          <span className="bsruleaccord">
                            {selectedTabPanel === "mustUse" &&
                            selectedModule?.mustExistWith?.length > 0
                              ? "-"
                              : "+"}{" "}
                          </span>{" "}
                          Must Exist With (
                          {`${selectedModule?.mustExistWith.length} Module${
                            selectedModule?.mustExistWith.length > 1 ? "s" : ""
                          }`}
                          )
                        </Accordion.Toggle>
                      </Card.Header>

                      {selectedModule?.mustExistWith?.length > 0 && (
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="row">
                            {/* <div className="col-md-4 mb-4">
                      <div className="d-flex align-items-center">
                        <div className="moduleCardBusinessRulesCheckbox">
                          <CheckBox
                            name="checkbox"
                            // name={sm.moduleId}
                            isChecked={
                              true //!isDeSelected(sm.moduleId, "mustExist")
                            }
                            // handleChange={(event) =>
                            //   handleCheckBoxChange(
                            //     sm.moduleId,
                            //     "mustExist",
                            //     event
                            //   )
                            // }
                          />
                        </div>
                      </div>
                    </div> */}
                            {selectedModule &&
                              selectedModule.mustExistWith.length > 0 &&
                              selectedModule.mustExistWith.map(
                                (sm, selIndex) => {
                                  return (
                                    <div
                                      className="col-md-4 mb-4"
                                      key={selIndex + "selectedModule"}
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="moduleCardBusinessRulesCheckbox">
                                          <CheckBox
                                            name={sm.moduleId}
                                            isChecked={
                                              sm.status
                                              // !isDeSelected(
                                              //   sm.moduleId,
                                              //   "mustExist"
                                              // )
                                            }
                                            handleChange={(event) =>
                                              handleCheckBoxChange(
                                                sm.moduleId,
                                                "mustExistWith",
                                                event
                                              )
                                            }
                                          />
                                        </div>
                                        <div
                                          className={`${
                                            isDeSelected(
                                              sm.moduleId,
                                              "mustExist"
                                            )
                                              ? "moduleCardDeselectedBusinessRules"
                                              : "moduleCardBusinessRules"
                                          }`}
                                        >
                                          <p className="bsRulesCardCode">
                                            {sm.moduleCode}
                                          </p>
                                          <p className="bsRulesCardModuleName">
                                            Module Name{" "}
                                            <span>{sm.moduleName}</span>
                                          </p>
                                          <p className="bsRulesCardClaim">
                                            Promotional Claim{" "}
                                            <span>
                                              {parseHtml(sm.primaryClaim || "")}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </Card.Body>
                        </Accordion.Collapse>
                      )}
                    </div>
                  )}
                {selectedModule.precedes &&
                  selectedModule.precedes.length >= 0 && (
                    <div className="bsrulesAccordion">
                      <Card.Header>
                        <Accordion.Toggle
                          eventKey="1"
                          variant="link"
                          className="associatedModuleTitle"
                          key={"dncjsdncjsdnc098"}
                          onClick={() => {
                            togglePanel("precedes");
                          }}
                        >
                          <span className="bsruleaccord">
                            {" "}
                            {selectedTabPanel === "precedes" &&
                            selectedModule.precedes.length > 0
                              ? "-"
                              : "+"}{" "}
                          </span>{" "}
                          Precedes (
                          {`${selectedModule.precedes.length} Module${
                            selectedModule.precedes.length > 1 ? "s" : ""
                          }`}
                          )
                        </Accordion.Toggle>
                      </Card.Header>
                      {selectedModule.precedes.length > 0 && (
                        <Accordion.Collapse eventKey="1">
                          <Card.Body className="row">
                            {selectedModule.precedes &&
                              selectedModule?.precedes?.length > 0 &&
                              selectedModule.precedes.map((sm) => (
                                <div className="col-md-4 mb-4">
                                  <div className="d-flex align-items-center">
                                    <div className="moduleCardBusinessRulesCheckbox">
                                      <CheckBox
                                        name={sm.moduleId}
                                        isChecked={
                                          sm.status
                                          // !isDeSelected(sm.moduleId, "precedes")
                                        }
                                        handleChange={(event) =>
                                          handleCheckBoxChange(
                                            sm.moduleId,
                                            "precedes",
                                            event
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="moduleCardBusinessRules">
                                      <p className="bsRulesCardCode">
                                        {sm.moduleCode}
                                      </p>
                                      <p className="bsRulesCardModuleName">
                                        Module Name <span>{sm.moduleName}</span>
                                      </p>
                                      <p className="bsRulesCardClaim">
                                        Promotional Claim{" "}
                                        <span>
                                          {parseHtml(sm.primaryClaim || "")}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </Card.Body>
                        </Accordion.Collapse>
                      )}
                    </div>
                  )}
                {selectedModule.succeeds &&
                  selectedModule.succeeds.length >= 0 && (
                    <div className="bsrulesAccordion">
                      <Card.Header key={"cccdncjsddcdcdnc098"}>
                        <Accordion.Toggle
                          eventKey="3"
                          key={"dncjsddcdcdnc098"}
                          variant="link"
                          className="associatedModuleTitle"
                          onClick={() => {
                            togglePanel("succeeds");
                          }}
                        >
                          <span className="bsruleaccord">
                            {" "}
                            {selectedTabPanel === "succeeds" &&
                            selectedModule?.succeeds?.length > 0
                              ? "-"
                              : "+"}{" "}
                          </span>{" "}
                          Succeeds (
                          {`${selectedModule.succeeds.length} Module${
                            selectedModule.succeeds.length > 1 ? "s" : ""
                          }`}
                          )
                        </Accordion.Toggle>
                      </Card.Header>
                      {selectedModule.succeeds.length > 0 && (
                        <Accordion.Collapse eventKey="3">
                          <Card.Body className="row">
                            {selectedModule.succeeds &&
                              selectedModule?.succeeds?.length > 0 &&
                              selectedModule.succeeds.map((sm) => (
                                <div className="col-md-4 mb-4">
                                  <div className="d-flex align-items-center">
                                    <div className="moduleCardBusinessRulesCheckbox">
                                      <CheckBox
                                        name={sm.moduleId}
                                        isChecked={
                                          sm.status

                                          // !isDeSelected(sm.moduleId, "succeeds")
                                        }
                                        handleChange={(event) =>
                                          handleCheckBoxChange(
                                            sm.moduleId,
                                            "succeeds",
                                            event
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="moduleCardBusinessRules">
                                      <p className="bsRulesCardCode">
                                        {sm.moduleCode}
                                      </p>
                                      <p className="bsRulesCardModuleName">
                                        Module Name <span>{sm.moduleName}</span>
                                      </p>
                                      <p className="bsRulesCardClaim">
                                        Promotional Claim{" "}
                                        <span>
                                          {parseHtml(sm.primaryClaim || "")}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </Card.Body>
                        </Accordion.Collapse>
                      )}
                    </div>
                  )}
                {selectedModule.relatedTo &&
                  selectedModule.relatedTo.length >= 0 && (
                    <div>
                      <Card.Header key={"ccdncjpoosdnc098"}>
                        <Accordion.Toggle
                          eventKey="4"
                          variant="link"
                          key={"dncjpoosdnc098"}
                          className="associatedModuleTitle"
                          onClick={() => {
                            togglePanel("related");
                          }}
                        >
                          <span className="bsruleaccord">
                            {" "}
                            {selectedTabPanel === "related" &&
                            selectedModule?.relatedTo?.length > 0
                              ? "-"
                              : "+"}{" "}
                          </span>
                          Related Modules (
                          {`${selectedModule.relatedTo.length} Module${
                            selectedModule.relatedTo.length > 1 ? "s" : ""
                          }`}
                          )
                        </Accordion.Toggle>
                      </Card.Header>
                      {selectedModule.relatedTo.length > 0 && (
                        <Accordion.Collapse eventKey="4">
                          <Card.Body className="row">
                            {selectedModule.relatedTo &&
                              selectedModule?.relatedTo?.length > 0 &&
                              selectedModule.relatedTo.map((sm) => (
                                <div className="col-md-4 mb-4">
                                  <div className="d-flex align-items-center">
                                    <div className="moduleCardBusinessRulesCheckbox">
                                      <CheckBox
                                        name={sm.moduleId}
                                        isChecked={
                                          sm.status
                                          //!isDeSelected(sm.moduleId, "related")
                                        }
                                        handleChange={(event) =>
                                          handleCheckBoxChange(
                                            sm.moduleId,
                                            "relatedTo",
                                            event
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="moduleCardBusinessRules">
                                      <p className="bsRulesCardCode">
                                        {sm.moduleCode}
                                      </p>
                                      <p className="bsRulesCardModuleName">
                                        Module Name <span>{sm.moduleName}</span>
                                      </p>
                                      <p className="bsRulesCardClaim">
                                        Promotional Claim{" "}
                                        <span>
                                          {parseHtml(sm.primaryClaim || "")}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </Card.Body>
                        </Accordion.Collapse>
                      )}
                    </div>
                  )}
              </Accordion>
            )}
          </div>
        </div>
      </div>
      {/* {cautionBackbtn ? (
        <div className="row backButnCaution ">
          <div className="col-md-12 cautionbckbtn">
            <img src={InfoIcon} alt="infoicon" className="infobckbtn" />
            <span className="warningBckbtn">
              {t(
                "Press back again to go back, deselecting previously chosen modules will result in loss of associated business rules selected previously."
              )}
            </span>
          </div>
        </div>
      ) : (
        ""
      )} */}
      <div className="row bsRulesBtnRow align-items-center">
        <div className="col-md-2 text-left ">
          <button
            onClick={handleClose}
            className="btn btn-link  bsRulecloseBtn"
          >
            CLOSE
          </button>
        </div>
        <div className="col-md-7 text-right must-exist-Module">
          {deselectedModule > 0
            ? `${deselectedModule} Must Exist With Module is de-selected. This could result in compliance issues.`
            : ""}
        </div>
        <div className="col-md-1 text-right ">
          <button
            className="btn btn-link bsRuleBAckBtn"
            onClick={() => {
              setOpen(true);
              handleBackButton();
            }}
          >
            BACK
          </button>
        </div>
        <div className="col-md-2 text-right">
          <button
            className="btn btn-primary btnBsRulesProceed"
            onClick={() => {
              // handleProceed();
              handleGotoEditor();
            }}
          >
            GO TO EDITOR
          </button>
        </div>
      </div>
    </div>
  );
};

export default BussinessRulesDialogue;
