import React, { useEffect, useState } from "react";
import "../../styles/components/ToolBarLeftPanel.scss";
import DragIcon from "../../images/drag_icon.svg";
import Lock from "../../images/Locklock.svg";
import UnLock from "../../images/LockUnlock.svg";
import hideIcon from "../../images/Visibility off.svg";
import { toast } from "react-toastify";
import EyeIcon from "../../images/Visibility.svg";
import Tooltip from "@material-ui/core/Tooltip";
import "../../styles/components/AnimationTimeLine.scss";
import { useTranslation } from "react-i18next";
// import { DebounceInput } from "react-debounce-input";
import { arrayMove } from "react-sortable-hoc";
// import Popover from "@material-ui/core/Popover";
// import Typography from "@material-ui/core/Typography";

import ButtonIcon from "../../images/ButtonNewIcon.svg";
import ContainerIcon from "../../images/container_icon.svg";
import ImageIcon from "../../images/ImageClicked.svg";
import Timage from "../../images/T.svg";
import dragImage from "../../images/layer.svg";
import selectedragImage from "../../images/SelectedLaayer.svg";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
//import { toast } from "react-toastify";

const Item = SortableElement(
  ({
    item,
    selec,
    toggle,
    toggleInput,
    indexValue,
    handleTextChange,
    setToggle,
    updateLayerNameToDefault,
    layerName,
    toggleLockComp,
    toggleHideComp,
    setSelectedComponent,
    toggleAnimatedBanner,
    setMultiSelectedComps,
    layerCompData,
    items,
    handleCtrlClick
  }) => {
    return (
      <>
        <div
          //  className="SortableItem"
          onClick={(e) => {
            setSelectedComponent(item);
            handleCtrlClick(e,item)
          }}
          key={item.id}
          className={`layers ${(item.id === selec?.id ||  item.multiSelected) && "addHeadingSelected"}`}
        >
          <div className="layer-dragImage1 col-md-1">
            {" "}
            <img
              // src={DragIcon}
              src={item.id === selec?.id ? selectedragImage : dragImage}
              alt="drag"
              draggable={false}
              className="img-fluid1 dragIcon"
            />
          </div>
          <div
            className="layer-textImage2 col-md-2" //draggable={false}
          >
            <img
              src={
                item.componentType === "Text"
                  ? Timage
                  : item.componentType === "Button"
                  ? ButtonIcon
                  : item.componentType === "Image"
                  ? ImageIcon
                  : ContainerIcon
              }
              alt="heading"
              draggable={false}
              className="img-fluid1 iconSize"
            />

            {/* <div className="layer-headline1 col-5"></div> */}
          </div>
          <Tooltip
            title={
              item?.componentStyles?.layerName?.length > 10
                ? item?.componentStyles?.layerName
                : ""
            }
            placement="top"
          >
            <div className="text-icon1 col-md-5">
              {toggle ? (
                <div
                  className="ellipseHidden"
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    toggleInput(item.id);
                  }}
                >
                  {item?.componentStyles?.layerName ? (
                    item?.componentStyles?.layerName
                  ) : (
                    <span>{"Enter layer name"}</span>
                  )}
                </div>
              ) : indexValue === item?.id ? (
                <input
                  className="inputElem"
                  // element="input"
                  // debounceTimeout={500}
                  style={{ width: "75px" }}
                  type="text"
                  value={layerName}
                  onChange={(e) => {
                    handleTextChange(e, item.id);
                  }}
                  onBlur={(e) => {
                    setToggle(true);
                    updateLayerNameToDefault(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setToggle(true);
                      updateLayerNameToDefault(e);
                    }
                  }}
                  autoFocus
                />
              ) : (
                <div
                  className="ellipseHidden"
                  onDoubleClick={() => {
                    toggleInput(item.id);
                  }}
                >
                  {item?.componentStyles?.layerName}
                  {/* {item?.componentStyles?.layerName ? (
            item?.componentStyles?.layerName
          ) : (
            <span>{t("Enter layer name")}</span>
          )} */}
                </div>
              )}
            </div>
          </Tooltip>

          <div className="lock-icon1 col-md-1 ">
            <Tooltip
              title={
                item?.componentStyles?.lockBoolean === false
                  ? "Lock Layer "
                  : "UnLock Layer"
              }
              placement="top"
            >
              <img
                src={
                  item?.componentStyles?.lockBoolean === false ? UnLock : Lock
                }
                alt="LockIcon"
                className={
                  item.id === selec?.id ? " img-fluid1 iconSize" : " d-none"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  item.id === selec.id && toggleLockComp(item);
                }}

                // draggable={false}
              />
            </Tooltip>
          </div>

          <div className={"eye-icon1 col-md-1 "}>
            <Tooltip
              title={
                item?.componentStyles?.hideBoolean === false
                  ? "Hide Layer "
                  : "Show Layer"
              }
              placement="top"
            >
              <img
                src={
                  item?.componentStyles?.hideBoolean === false
                    ? EyeIcon
                    : hideIcon
                }
                alt="heading"
                className={
                  item.id === selec?.id ? "img-fluid1 eyeIconSize" : "d-none"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  item.id === selec.id && toggleHideComp(item);
                }}
                // draggable={false}
              />
            </Tooltip>
          </div>
        </div>
      </>
    );
  }
);

const ItemContainer = SortableContainer(
  ({
    items,
    selec,
    toggle,
    toggleInput,
    indexValue,
    handleTextChange,
    setToggle,
    updateLayerNameToDefault,
    layerName,
    toggleHideComp,
    toggleLockComp,
    setSelectedComponent,
    toggleAnimatedBanner,
    setMultiSelectedComps,
    multiSelectedComps,
    setLayerCompData,
    layerCompData,
    handleCtrlClick
  }) => (
    <div>
      {items.map((item, index) => {
        return (
          <Item
            items={items}
            className="SortableList"
            key={`item-${item.id}`}
            item={item}
            index={index}
            selec={{ ...selec }}
            toggle={toggle}
            toggleInput={toggleInput}
            indexValue={indexValue}
            handleTextChange={handleTextChange}
            setToggle={setToggle}
            updateLayerNameToDefault={updateLayerNameToDefault}
            layerName={layerName}
            toggleHideComp={toggleHideComp}
            toggleLockComp={toggleLockComp}
            setSelectedComponent={setSelectedComponent}
            toggleAnimatedBanner={toggleAnimatedBanner}
            setMultiSelectedComps={setMultiSelectedComps}
            multiSelectedComps={multiSelectedComps}
            setLayerCompData={setLayerCompData}
            layerCompData={layerCompData}
            handleCtrlClick={handleCtrlClick}
          />
        );
      })}
    </div>
  )
);

export default function ElementLayers({
  layerCompData,
  selectedComponent,
  setSelectedComponent,
  setLayerCompData,
  toggleLockComp,
  toggleHideComp,
  toggleAnimatedBanner,
  playPause,
  setMultiSelectedComps,
  handleCtrlClick
}) {
  const [toggle, setToggle] = useState(true);
  const [indexValue, setIndexValue] = useState(undefined);
  const chartCount = 13; //To Show layer tooltip only when it exceeds 13 characters

  const { t } = useTranslation();
  const toggleInput = (index) => {
    setToggle(false);
    setIndexValue(index);
    setLayerName(selectedComponent.componentStyles.layerName);
  };
  const handleTextChange = (e, id) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(e.target.value)) return;

    setLayerName(e.target.value);

    // console.log(id, "id");
    // let tempSelected = [...layerCompData];

    // let x = tempSelected.findIndex((selComp) => selComp.id === id);
    // tempSelected[x].componentStyles.layerName = e.target.value;
    // setLayerCompData(tempSelected);

    // let tempSelected = { ...selectedComponent };

    // tempSelected.componentStyles.layerName = e.target.value;

    // setSelectedComponent(tempSelected);
  };
  const updateLayerNameToDefault = (e) => {
    let tempSelected = { ...selectedComponent };
    if (layerName === "" || layerName?.trim() === "") {
      tempSelected.componentStyles.layerName = selectedComponent.componentStyles
        .layerNameSaved
        ? selectedComponent.componentStyles.layerNameSaved
        : selectedComponent.componentStyles.layerNameUpdated;
      setLayerName(tempSelected.componentStyles.layerName);
    } else if (e.target.value.length > 200) {
      toast.error(
        <div className="notificationText">
          {t("Layer name can not be more than 200 characters")}
        </div>
      );
      setLayerName(tempSelected.componentStyles.layerName);
    } else {
      tempSelected.componentStyles.layerName = e.target.value;
    }
    setSelectedComponent(tempSelected);
  };
  const [layerName, setLayerName] = useState(
    selectedComponent?.componentStyles?.layerName
  );

  const onReorder = ({ oldIndex, newIndex }) => {
    let swappedLayerCompData = arrayMove(layerCompData, oldIndex, newIndex);

    swappedLayerCompData.forEach((layerObj, index) => {
      swappedLayerCompData[index].componentStyles.zIndex =
        swappedLayerCompData.length - index;
    });
    setLayerCompData(swappedLayerCompData);
  };

  return (
    <div
      className="list-layers custonVertScrollBar"
      id="leftDiv"
      // style={{
      //   height: "189px",
      //   overflowY: "scroll",
      // }}
      style={{ overflowY: "scroll", overflowX: "hidden" }}
    >
      <div
        className="element-name"
        style={{ pointerEvents: !playPause.animationCompleted && "none" }}
      >
        <ItemContainer
          items={layerCompData}
          onSortEnd={onReorder}
          distance={1}
          selec={{ ...selectedComponent }}
          toggle={toggle}
          toggleInput={toggleInput}
          indexValue={indexValue}
          handleTextChange={handleTextChange}
          setToggle={setToggle}
          updateLayerNameToDefault={updateLayerNameToDefault}
          layerName={layerName}
          toggleHideComp={toggleHideComp}
          toggleLockComp={toggleLockComp}
          setSelectedComponent={setSelectedComponent}
          toggleAnimatedBanner={toggleAnimatedBanner}
          setMultiSelectedComps={setMultiSelectedComps}
          // multiSelectedComps={multiSelectedComps}
          // setLayerCompData={setLayerCompData}
          handleCtrlClick={handleCtrlClick}
        />
      </div>
    </div>
  );
}
