import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/components/ContextMenuZindex.scss";

const ContextMenuZindex = ({
  outerRef,
  updateZindexValue,
  selectedComponent,
  deleteComponentOnCanvas,
  layerCompData,
  isVisible,
  setVisibility,
  copyCanvasComponent,
  pasteComponentOnCanvas,
  setShowPaste,
  toggleLockComp,
  toggleHideComp,
  playPause,
  multiSelectedComps,
  mm,
}) => {
  const { t } = useTranslation();
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const handleContextmenuForZindex = (e, type) => {
    updateZindexValue(e, type);
  };
  const applyZindex = [
    {
      bringZindex: `${t("contextMenuZindex.bringFront")}`,
      type: "Front",
    },
    {
      bringZindex: `${t("contextMenuZindex.bringForward")}`,
      type: "Forward",
    },
    {
      bringZindex: `${t("contextMenuZindex.bringBack")}`,
      type: "Back",
    },
    {
      bringZindex: `${t("contextMenuZindex.bringBackward")}`,
      type: "Backward",
    },
  ];
  useEffect(() => {
    const parent = outerRef?.current;
    if (!parent) {
      return;
    }
    const showContextMenu = (event) => {
      if (event.srcElement.className === "dragContentCanvas centerPanel")
        return;
      let Id = event.target.closest(".cs-container").id;
      let Obj = layerCompData.findIndex((selComp) => selComp.id === Id);
      if (layerCompData[Obj]?.componentStyles.hideBoolean) return;
      event.preventDefault();
      if (layerCompData.length !== 0) {
        setVisibility(true);
        setX(event.clientX);
        setY(event.clientY);
        setShowPaste(false);
      }
    };

    const closeContextmenu = () => {
      setVisibility(false);
    };

    let x = parent?.addEventListener("contextmenu", showContextMenu);

    window.addEventListener("click", closeContextmenu);

    return function cleanup() {
      parent.removeEventListener("contextmenu", showContextMenu);
      window.removeEventListener("click", closeContextmenu);
    };
  });
  window.onscroll = function (e) {
    setVisibility(false);
  };

  const style = {
    top: y,
    left: x,
  };
  return isVisible &&
    playPause.animationCompleted &&
    (multiSelectedComps.length > 0 ||
      selectedComponent.componentType !== "default") ? (
    <div>
      <div className="contextMenu" style={style}>
        <div className="contextMenuText" onClick={copyCanvasComponent}>
          {t("contextMenuZindex.copy")}
        </div>
        <div
          className="contextMenuText"
          onClick={() => pasteComponentOnCanvas(mm)}
        >
          {t("contextMenuZindex.paste")}
        </div>
        <div
          className={
            //"contextMenuText"
            `${
              selectedComponent?.componentStyles?.lockBoolean ||
              multiSelectedComps.length > 0
                ? "contextMenuTextLocked"
                : "contextMenuText"
            }`
          }
          onClick={(e) =>
            !selectedComponent?.componentStyles?.lockBoolean &&
            multiSelectedComps.length < 1 &&
            deleteComponentOnCanvas()
          }
        >{`${t("contextMenuZindex.delete")}`}</div>
        <hr />
        <div
          // className="contextMenuText"
          className={`${"contextMenuText"}`}
          onClick={() => toggleLockComp(selectedComponent)}
        >
          {selectedComponent?.componentStyles?.lockBoolean === true
            ? "UnLock"
            : t("contextMenuZindex.lock")}
        </div>
        <div
          // className="contextMenuText"
          className={`${
            selectedComponent?.componentStyles?.lockBoolean ||
            multiSelectedComps.length > 0
              ? "contextMenuTextLocked"
              : "contextMenuText"
          }`}
          onClick={() => {
            multiSelectedComps.length < 1 &&
              !selectedComponent?.componentStyles?.lockBoolean &&
              toggleHideComp(selectedComponent);
          }}
        >
          {selectedComponent?.componentStyles?.hideBoolean === true
            ? "UnHide"
            : t("contextMenuZindex.hide")}
        </div>

        <hr />
        {applyZindex.map((applyZindexToComponent, id) => {
          return (
            <div
              className={`${
                selectedComponent?.componentStyles?.lockBoolean ||
                multiSelectedComps.length > 0
                  ? "contextMenuTextLocked"
                  : "contextMenuText"
              }`}
              onClick={(e) =>
                !selectedComponent?.componentStyles?.lockBoolean &&
                playPause.animationCompleted &&
                handleContextmenuForZindex(e, applyZindexToComponent.type)
              }
            >
              {applyZindexToComponent.bringZindex}{" "}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default ContextMenuZindex;
