import React, { useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "../../styles/pages/cardDetails.scss";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import parse from "html-react-parser";

import { useTranslation } from "react-i18next";

function CardDetails(props) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const RData = ["moduleClaims", "moduleVisuals", "moduleChannels"];
  const atomsData = ["claimVariants", "supportingTextComponents"];
  const assetId = props.cardDetails.data.assetId;
  let counter = 0;

  /*  function drag(ev, type) {
    ev.dataTransfer.setData("id", ev.target.id);
    ev.dataTransfer.setData("type", type);
  } */
  const getTaglineCount = () => {
    let tempCount = props.cardDetails.data["moduleChannels"].filter(
      (channel) => channel.textType === "Tagline"
    );
    return tempCount.length;
  };

  return (
    <div className="cardDetailsTab ">
      <div className="arrowAndCodeHeadercard">
        <ArrowBackIosIcon
          className="backwordBlueArrow"
          onClick={() =>
            props.setCardDetails(
              {
                ...props.cardDetails,
                cardDetails: false,
              },
              props.setOnClickBlueArrow(false)
            )
          }
        />
        <div className="moduleCode d-flex">
          {props.cardDetails.data.moduleCode}
          {/* <div className="viewbrules">{t("View Business Rules")} </div> */}
        </div>
      </div>
      <div className="cardDetailsTab2 custonScrollBar">
        <div className="claimDetails " style={{ paddingRight: "18px" }}>
          {RData.map(
            (data) =>
              props.cardDetails.data[data]?.length > 0 &&
              props.cardDetails.data[data]?.map(
                (claim, index) =>
                  (claim?.type === "Primary" || claim?.type === "Supporting") &&
                  (data === "moduleClaims"
                    ? claim?.supportingTextComponents?.length > 0
                    : claim?.visualVariants?.length > 0 &&
                      claim?.supportingTextComponents?.length > 0) && (
                    <div className="accordion leftcard">
                      <Accordion
                        expanded={expanded === `panel${claim?.type}${index}`}
                        className="accordionLeftpane"
                        onChange={handleChange(`panel${claim?.type}${index}`)}
                      >
                        <AccordionSummary
                          expandIcon={
                            expanded === `panel${claim?.type}${index}` ? (
                              <RemoveIcon className="accordionIcons" />
                            ) : (
                              <AddIcon className="accordionIcons" />
                            )
                          }
                          aria-controls={`panel${claim?.type}${index}a-content`}
                          id={`panel${claim?.type}${index}a-header`}
                        >
                          <div className="claimName nextmod">
                            <div
                            // style={{
                            //   marginTop:
                            //     claim?.type === "Supporting" &&
                            //     claim?.supportingTextComponents[0]
                            //       ?.primaryComponentType !== "Visual"
                            //       ? "4px"
                            //       : "",
                            // }}
                            >
                              {claim?.supportingTextComponents[0]
                                ?.primaryComponentType === "Visual"
                                ? claim?.visualVariants?.length > 0 &&
                                  claim?.type.concat(
                                    " ",
                                    claim?.supportingTextComponents[0]
                                      ?.primaryComponentType
                                  )
                                : claim?.supportingTextComponents?.length > 0 &&
                                  (claim?.type === "Primary"
                                    ? "Promotional"
                                    : claim?.type
                                  ).concat(
                                    " ",
                                    claim?.supportingTextComponents[0]
                                      ?.primaryComponentType
                                  )}
                            </div>
                            {/* {claim?.type === "Supporting" &&
                              claim?.supportingTextComponents[0]
                                ?.primaryComponentType !== "Visual" &&
                              props.cardDetails.data.moduleGroups[0]?.groupClaims?.map(
                                (groupClaim) =>
                                  groupClaim?.moduleClaimId === claim?.id && (
                                    <div className="colorCode">
                                      <div
                                        className={
                                          props.cardDetails.data.moduleGroups[0]
                                            ?.requirementType === "Mandatory"
                                            ? "relationDependancy relationColorMandatory"
                                            : props.cardDetails.data
                                                .moduleGroups[0]
                                                ?.requirementType === "Optional"
                                            ? "relationDependancy relationColorOptional"
                                            : ""
                                        }
                                      ></div>
                                      <span className="clrCodeText">
                                        {props.cardDetails.data.moduleGroups[0]
                                          ?.requirementType === "Mandatory"
                                          ? "Mandatory"
                                          : "Optional"}
                                      </span>
                                    </div>
                                  )
                              )} */}
                          </div>
                          {claim?.supportingTextComponents[0]
                            ?.primaryComponentType === "Visual"
                            ? claim?.visualVariants?.length > 0 && (
                                <span className="claimNameCount">{`(${claim?.visualVariants?.length} items)`}</span>
                              )
                            : claim?.supportingTextComponents?.length > 0 && (
                                <span className="claimNameCount">{`(${
                                  claim?.supportingTextComponents?.length +
                                  (claim?.claimVariants
                                    ? claim?.claimVariants?.length
                                    : 0) +
                                  (claim?.hasOwnProperty("claim") ? 1 : 0)
                                } items)`}</span>
                              )}
                        </AccordionSummary>
                        <AccordionDetails
                          className={
                            claim?.supportingTextComponents[0]
                              ?.primaryComponentType === "Visual"
                              ? "accordDetails  accordVisual "
                              : "accordDetails accordVisual  "
                          }
                        >
                          {claim?.supportingTextComponents[0]
                            ?.primaryComponentType === "Visual" ? (
                            claim?.visualVariants?.length > 0 &&
                            claim?.visualVariants.map((variant, index) => (
                              /* <img  id={'supImage'+index} className="supportingVisuals" data-assetId={assetId} data-atomId={variant.sourceType === "Atom" ? variant.sourceId :""} src={variant.visualUrl}  onDoubleClick={ */
                              <img
                                alt="supimg"
                                id={"supImage" + counter++}
                                className="supportingVisuals"
                                data-assetid={assetId}
                                data-atomid={
                                  variant.sourceType === "Atom"
                                    ? variant.sourceId
                                    : ""
                                }
                                src={variant.visualUrl}
                                onDoubleClick={(e) =>
                                  props.sendAtomDetails2Canvas(
                                    e.target.parentNode.id || e.target.id,
                                    "AtomImage"
                                  )
                                }
                                draggable={true}
                                onDragStart={(e) =>
                                  props.sendAtomDetails2Canvas({
                                    id: e.target.parentNode.id || e.target.id,
                                    type: "Image",
                                    data: variant.visualUrl,
                                  })
                                }
                              />
                            ))
                          ) : (
                            <div>
                              <div className="accDetails2 ">
                                <div className="typeHeading">
                                  {t("modulesLeftContainer.claimVariant")}
                                </div>
                                {claim?.type === "Supporting" &&
                                  claim?.supportingTextComponents[0]
                                    ?.primaryComponentType !== "Visual" &&
                                  props.cardDetails.data.moduleGroups[0]?.groupClaims?.map(
                                    (groupClaim) =>
                                      groupClaim?.moduleClaimId ===
                                        claim?.id && (
                                        <div className="colorCode">
                                          <div
                                            className={
                                              props.cardDetails.data
                                                .moduleGroups[0]
                                                ?.requirementType ===
                                              "Mandatory"
                                                ? "relationDependancy relationColorMandatory"
                                                : props.cardDetails.data
                                                    .moduleGroups[0]
                                                    ?.requirementType ===
                                                  "Optional"
                                                ? "relationDependancy relationColorOptional"
                                                : ""
                                            }
                                          ></div>
                                          <span className="clrCodeText">
                                            {props.cardDetails.data
                                              .moduleGroups[0]
                                              ?.requirementType === "Mandatory"
                                              ? "Mandatory"
                                              : "Optional"}
                                          </span>
                                        </div>
                                      )
                                  )}
                                {/* <div id={supText.componentType+index} className="typeText" data-assetId={assetId}  onDoubleClick={ */}
                                <div
                                  id={"text" + counter++}
                                  className="typeText"
                                  data-assetid={assetId}
                                  onDoubleClick={(e) =>
                                    props.sendAtomDetails2Canvas(
                                      e.target.parentNode.id || e.target.id,
                                      "atomText"
                                    )
                                  }
                                  draggable={true}
                                  onDragStart={(e) =>
                                    props.sendAtomDetails2Canvas({
                                      id: e.target.parentNode.id || e.target.id,
                                      type: "atomText",
                                      data: claim?.claim,
                                    })
                                  }
                                >
                                  {claim?.claim && parse(claim?.claim)}
                                </div>
                              </div>
                              {atomsData.map(
                                (atomData) =>
                                  claim[atomData]?.length > 0 &&
                                  claim[atomData]?.map((supText, index) => (
                                    <div className="accDetails2">
                                      <div className="typeHeading">
                                        {atomData === "claimVariants"
                                          ? "Claim Variant ".concat(index + 2)
                                          : supText?.componentType}
                                      </div>

                                      {/* <div id={supText.componentType+index} className="typeText" data-assetId={assetId}  onDoubleClick={ */}
                                      <div
                                        id={"text" + counter++}
                                        className="typeText"
                                        data-assetid={assetId}
                                        onDoubleClick={(e) =>
                                          props.sendAtomDetails2Canvas(
                                            e.target.parentNode.id ||
                                              e.target.id,
                                            "AtomText"
                                          )
                                        }
                                        draggable={true}
                                        onDragStart={(e) =>
                                          props.sendAtomDetails2Canvas({
                                            id:
                                              e.target.parentNode.id ||
                                              e.target.id,
                                            type: "atomText",
                                            data:
                                              atomData === "claimVariants"
                                                ? supText?.moduleClaim
                                                : supText?.text,
                                          })
                                        }
                                      >
                                        {atomData === "claimVariants"
                                          ? supText?.moduleClaim &&
                                            parse(supText?.moduleClaim)
                                          : supText?.text &&
                                            parse(supText?.text)}
                                      </div>
                                    </div>
                                  ))
                              )}
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )
              )
          )}
          <div className="accordion leftcard">
            {getTaglineCount() > 0 ? (
              <Accordion
                expanded={expanded === `panel1`}
                className="accordionLeftpane"
                onChange={handleChange(`panel1`)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === `panel1` ? (
                      <RemoveIcon className="accordionIcons" />
                    ) : (
                      <AddIcon className="accordionIcons" />
                    )
                  }
                  aria-controls={`panel1a-content`}
                  id={`panel1a-header`}
                >
                  <div className="claimName">
                    <div>{t("Taglines")}</div>
                  </div>
                  <span className="claimNameCount">{`(${getTaglineCount()} items)`}</span>
                </AccordionSummary>

                <AccordionDetails className="accordDetails accordVisual">
                  {RData.map(
                    (data) =>
                      props.cardDetails.data[data]?.length > 0 &&
                      props.cardDetails.data[data]
                        ?.filter((channel) => channel.textType === "Tagline")
                        .map(
                          (channel, index) =>
                            channel?.textType === "Tagline" &&
                            data === "moduleChannels" && (
                              <div>
                                <div className="accDetails2 ">
                                  <div className="typeHeading">
                                    {t(`Tagline ${index + 1}`)}
                                  </div>
                                  {/* <div id={supText.componentType+index} className="typeText" data-assetId={assetId}  onDoubleClick={ */}
                                  <div
                                    id={"text" + counter++}
                                    className="typeText"
                                    data-assetid={assetId}
                                    draggable={true}
                                    onDragStart={(e) =>
                                      props.sendAtomDetails2Canvas({
                                        id:
                                          e.target.parentNode.id || e.target.id,
                                        type: "atomText",
                                        data: channel?.text,
                                      })
                                    }
                                  >
                                    {channel?.text && parse(channel?.text)}
                                  </div>
                                </div>
                              </div>
                            )
                        )
                  )}
                </AccordionDetails>
              </Accordion>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardDetails;
