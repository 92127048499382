import React, { useEffect, useState, useContext } from "react";
//import Accordion from "react-bootstrap/Accordion";

import {
  getVeevaRuleset,
  putVeevaModuleList,
  getVeevaCardsInleftcont,
} from "../../services/apis";
import bluedot from "../../images/bluedot.svg";
import { CircularProgress } from "@material-ui/core";
import "../../styles/pages/LocalizedModulesCss.scss";
import { useTranslation } from "react-i18next";
import ValueContext from "../../ContextAPi/appContext";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";

const VeevaContentModal = ({
  selectedModuleIds,
  handleModal,
  handleProceed,
  prevSelectedData,
  setOpenBussinessRules,
  activeCards,
  setOpen,
  handleProceedbtn,
  sendModuleid,
  handleBackButton,
  linkedModulesType,
  closeVeevaContentModal,
  handleBackbtnVeeva,
  setVeevaRules,
  veevaRules,
  veevaSelectedModuleId,
  setVeevaSelectedModuleId,
  veevaLoader,
  setVeevaLoader,

  setOpenVeevaContentModal,
  values,
  setVeevaContentData,
  setActiveCard,
  setCantExistCards,
  setToggleCards,
  veevaContentData,
  setVeevaModuleCardData,
  veevaModuleCardData,
  handlesetveevamoduleCard,
  setLoadingModules,
  responseData,
  setResponseData,
  genericInfo,
  modules,
  setModules,
}) => {
  const { t } = useTranslation();
  //const selectedModule, setSelectedModule] = useState({ moduleId: [] });

  const [BIndex, setBIndex] = useState(0);

  const [deselectedAssociatedModules, setDeselectedAssociatedModules] =
    useState([]);

  const [selectedTabPanel, setSelectedTabPanel] = useState("mustUse");
  const [deselectedModule, setDeselectedModule] = useState(0);
  // const deselectedModule = deselectedAssociatedModules.filter(
  //   (module) => module.type === "mustExist"
  // ).length;
  //   {
  //   must: true,
  //   precedes: false,
  //   succeeds: false,
  //   related: false,
  // }
  const [veevaCOntentloader, setVeevaCOntentloader] = useState(false);
  const appData = useContext(ValueContext);
  const [expandedVeeva, setExpandedVeeva] = useState({
    panel1: true,
    panel2: false,
  });

  const handleChangeVeeva = (panel) => (event, isExpanded) => {
    //setExpanded(isExpanded ? panel : false);

    switch (panel) {
      case "panel1":
        setExpandedVeeva({ ...expandedVeeva, panel1: !expandedVeeva.panel1 });
        break;
      case "panel2":
        setExpandedVeeva({ ...expandedVeeva, panel2: !expandedVeeva.panel2 });
        break;

      default:
        break;
    }
  };

  const setSelectedModuleHandler = (id) => {
    const result = veevaRules.find(function (element) {
      if (element.id === id) {
        return true;
      } else {
        return false;
      }
    });

    setVeevaSelectedModuleId(result);
  };

  const onNextModuleButtonClick = () => {
    const curentSelectedModuleId = veevaSelectedModuleId.id;
    const index = veevaRules.findIndex((veevaRule) => {
      return veevaRule.id === curentSelectedModuleId;
    });

    if (index === veevaRules.length - 1) {
      setVeevaSelectedModuleId(veevaRules[veevaRules.length - 1]);
      return;
    }
    setBIndex(index + 1);
    setVeevaSelectedModuleId(veevaRules[index + 1]);
  };
  const onPreviousModuleButtonClick = () => {
    const curentSelectedModuleId = veevaSelectedModuleId?.id;

    const index = veevaRules.findIndex((veevaRule) => {
      return veevaRule.id === curentSelectedModuleId;
    });

    if (index === 0) {
      setVeevaSelectedModuleId(veevaRules[0]);
      return;
    }
    setBIndex(index - 1);
    setVeevaSelectedModuleId(veevaRules[index - 1]);
  };
  const togglePanelveevaContent = (type) => {
    if (type === selectedTabPanel) {
      setSelectedTabPanel("");
    } else {
      setSelectedTabPanel(type);
    }
    // let tempSelected = { ...selectedTabPanel };
    // tempSelected[type] = !tempSelected[type];
    // setSelectedTabPanel(tempSelected);
  };

  const isNextButtonDisabled = veevaRules.length <= 1;
  const isDeSelected = (moduleId, type) => {
    const index = deselectedAssociatedModules.findIndex((module) => {
      return (
        module.parentModuleId === veevaSelectedModuleId.moduleId &&
        module.moduleId === moduleId &&
        type === module.type
      );
    });
    return index > -1;
  };
  const handleClose = () => {
    setSelectedTabPanel("mustUse");
    setDeselectedAssociatedModules([]);
    setOpenBussinessRules(false);
  };
  // console.log(businessRules, "deselectedAssociatedModules");
  const handleGotoEditorveeva = () => {
    //let moduleVeevaRules = [];
    let veevaArr = [];
    veevaRules.forEach((vModule) => {
      let newObject = {
        id: vModule.id,
        moduleName: vModule.moduleName,
      };
      veevaArr.push(newObject);
    });
    let assetId = appData.assetInfo.assetId;

    let veevaPayload = {
      searchExpression: {
        brandName: appData?.genericInfo?.brand,
        claimCategory: values?.claimCategoryArray,
        name: values?.moduleNameArray,
        campaign: appData?.genericInfo?.brand,
      },
      linkedModules: {
        linkedModules: linkedModulesType,
      },
      moduleType: "contentmodule",
      contentModules: [...veevaArr],
    };
    setVeevaCOntentloader(true);
    // setVeevaLoader(true);
    setLoadingModules(true);
    putVeevaModuleList(
      assetId,
      veevaPayload,
      async (response, err, errorCode) => {
        if (err) {
          // const errCoponent = errComponentToRender(errorCode);
          console.log(err);
          setVeevaCOntentloader(false);
        } else {
          let putResp = response.data;
          getVeevaModuleCardinfo(putResp);
          setVeevaContentData(putResp);
          // setVeevaCOntentloader(false);
          setOpenVeevaContentModal(false);
          appData.setVeevaModulesLinked(true);
          appData.updateGenericInfo({
            ...appData.genericInfo,
            linkedData: {
              ...appData?.genericInfo?.linkedData,
              activeCards: {
                moduleId: putResp.map((elem) => elem.moduleId),
              },
              values: appData?.genericInfo?.searchedFields,
              totalCards: appData?.cardData?.cards,
            },
          });
          let moduleId = putResp.map((elem) => elem.moduleId);

          setModules({ moduleId });
        }
      }
    );
  };
  const getVeevaModuleCardinfo = (idsArr) => {
    let veevaIds = [];
    idsArr.forEach((vModule) => {
      let arrOfids = vModule?.moduleId;
      veevaIds.push(arrOfids);
    });

    getVeevaCardsInleftcont(veevaIds, async (response, err, errorCode) => {
      setVeevaCOntentloader(true);
      if (err) {
        // const errCoponent = errComponentToRender(errorCode);
        console.log(err);
        setVeevaCOntentloader(false);
        setLoadingModules(false);
      } else {
        const data = await response.data;
        appData.setActiveCards(data);
        setVeevaModuleCardData(data);
        setVeevaCOntentloader(false);
        // setVeevaLoader(false);
        setLoadingModules(false);
      }
    });
    //setOpenVeevaContentModal(false);
  };
  console.log("genericInfo+++", genericInfo);

  return (
    <div>
      <div className="row mb-4 mt-4">
        <div className="col-md-12 associatedModuleTitle rulesetModuleTitle">
          {t("Applicable Rulesets")}
        </div>
      </div>
      {veevaCOntentloader && (
        <>
          <div className="modal-backdrop fade show"> </div>
          <CircularProgress size="30px" className="customLoader" />{" "}
        </>
      )}
      <div className="row">
        <div className="col-md-2 selectedModuleIdColumn moduleIdsScrollbar">
          {BIndex > -1 &&
            veevaRules.map((item, veevaIndex) => {
              return (
                <>
                  <div
                    className={
                      veevaSelectedModuleId?.id === item?.id
                        ? "associatedModuleActiveBrdr"
                        : ""
                    }
                    key={veevaIndex + "businessRules"}
                  >
                    <button
                      onClick={() => {
                        setSelectedModuleHandler(item.id);
                        setBIndex(veevaIndex);
                      }}
                      className={`btn btn-link  associatedModuleIdTitle ${
                        veevaSelectedModuleId?.id === item?.id
                          ? "associatedveevaModActive"
                          : "associatedbrdr"
                      }`}
                      style={{ fontWeight: "normal" }}
                    >
                      {" "}
                      {/* <img
                        src={bluedot}
                        className="img-fluid bluedotImage"
                        alt=""
                      /> */}
                      {item?.moduleName}
                    </button>
                  </div>
                </>
              );
            })}
        </div>
        <div
          className="col-md-10"
          style={{
            paddingRight: "7px",
            left: "4px",
            borderLeft: "solid 1px #e1e1e1",
          }}
        >
          <div className="row align-items-center mb-3 moduleNameDiv">
            <div className="col-md-3" style={{ marginLeft: "8px" }}>
              <span className="associatedBsModuleSubtitle">Module Name</span>
              <p className="associatedBsModuleSubText ">
                {veevaSelectedModuleId?.moduleName}
              </p>
            </div>
            <div className="col-md-3">
              <span className="associatedBsModuleSubtitle">Campaign Name</span>
              <p className="associatedBsModuleSubText">
                {genericInfo?.campaign}
              </p>
            </div>
            <div className="col-md-3">
              <button
                onClick={onPreviousModuleButtonClick}
                //disabled={BIndex <= 0 ? true : false}
                disabled={BIndex <= 0}
                className={
                  BIndex <= 0
                    ? "btn   text-uppercase btnNextModule btn-lg "
                    : "btn btn-outline  text-uppercase btnNextModule btn-lg "
                }
              >
                Previous Module
              </button>
            </div>
            <div className="col-md-2 text-right">
              <button
                onClick={onNextModuleButtonClick}
                disabled={
                  BIndex === veevaRules.length - 1
                  //checkModuleIndex("last")
                }
                style={{ marginLeft: "-50px" }}
                className="btn btn-outline text-uppercase btnNextModule btn-lg"
              >
                Next Module
              </button>
            </div>
          </div>

          <div className=" localizedAccordionveeva customScrollBar ">
            <div className="accordion2 customAccordIconsveeva">
              {veevaSelectedModuleId?.mustUseList?.length >= 0 && (
                <Accordion
                  // expanded={expandedVeeva}
                  // className="accordion"
                  defaultExpanded={
                    veevaSelectedModuleId?.mustUseList?.length > 0
                      ? true
                      : false
                  }
                  onChange={handleChangeVeeva("panel1")}
                >
                  {veevaSelectedModuleId?.mustUseList &&
                    veevaSelectedModuleId?.mustUseList?.length >= 0 && (
                      <AccordionSummary
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                      >
                        <span className="mustUse">
                          Must Use ({veevaSelectedModuleId?.mustUseList?.length}
                          )
                        </span>
                      </AccordionSummary>
                    )}
                  {/* <div className="accordionscroll scrollCOntent"> */}
                  {veevaSelectedModuleId?.mustUseList &&
                    veevaSelectedModuleId?.mustUseList?.length > 0 && (
                      <AccordionDetails>
                        <div className="veevatb ">
                          <table className="veevatable">
                            <tr>
                              <th style={{ width: "15%" }}>Name</th>
                              <th style={{ width: "55%" }}>Label</th>
                              <th style={{ width: "30%" }}>
                                Content Module Ruleset
                              </th>
                            </tr>
                            {veevaSelectedModuleId.mustUseList &&
                              veevaSelectedModuleId.mustUseList.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.name}</td>
                                    <td>{item.label}</td>
                                    <td>{item.contentModuleRuleSet}</td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </AccordionDetails>
                    )}
                </Accordion>
              )}
              {veevaSelectedModuleId?.optionalList?.length >= 0 && (
                <Accordion onChange={handleChangeVeeva("panel2")}>
                  <AccordionSummary
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    <span className="mustUse">
                      Optional ({veevaSelectedModuleId?.optionalList?.length})
                    </span>
                  </AccordionSummary>
                  {veevaSelectedModuleId?.optionalList &&
                    veevaSelectedModuleId?.optionalList?.length > 0 && (
                      // <div className="accordionscroll scrollCOntent">
                      <AccordionDetails>
                        <div className="veevatb">
                          <table className="veevatable">
                            <tr>
                              <th style={{ width: "15%" }}>Name</th>
                              <th style={{ width: "55%" }}>Label</th>
                              <th style={{ width: "30%" }}>
                                Content Module Ruleset
                              </th>
                            </tr>
                            {veevaSelectedModuleId.optionalList &&
                              veevaSelectedModuleId.optionalList.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.name}</td>
                                    <td>{item.label}</td>
                                    <td>{item.contentModuleRuleSet}</td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </AccordionDetails>
                      // </div>
                    )}
                </Accordion>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {cautionBackbtn ? (
        <div className="row backButnCaution ">
          <div className="col-md-12 cautionbckbtn">
            <img src={InfoIcon} alt="infoicon" className="infobckbtn" />
            <span className="warningBckbtn">
              {t(
                "Press back again to go back, deselecting previously chosen modules will result in loss of associated business rules selected previously."
              )}
            </span>
          </div>
        </div>
      ) : (
        ""
      )} */}
      <div className="row bsRulesBtnRow align-items-center">
        <div className="col-md-2 text-left ">
          <button
            onClick={closeVeevaContentModal}
            className="btn btn-link  bsRulecloseBtn"
          >
            CLOSE
          </button>
        </div>
        <div className="col-md-7 text-right must-exist-Module">
          {/* {deselectedModule > 0
            ? `${deselectedModule} Must Exist With Module is de-selected. This could result in compliance issues.`
            : ""} */}
        </div>
        <div className="col-md-1 text-right ">
          <button
            className="btn btn-link bsRuleBAckBtn"
            onClick={() => {
              //setOpen(true);
              handleBackbtnVeeva();
            }}
          >
            BACK
          </button>
        </div>
        <div className="col-md-2 text-right">
          <button
            className="btn btn-primary btnBsRulesProceed"
            onClick={() => {
              handleGotoEditorveeva();
            }}
          >
            GO TO EDITOR
          </button>
        </div>
      </div>
    </div>
  );
};

export default VeevaContentModal;
