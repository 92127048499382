import React, { useState, useContext, useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "../../styles/components/ToolBarLeftPanel.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import microsoft from "../../images/teamwork.jfif";
import Grid from "../../images/Grid.svg";
import Single from "../../images/Single.svg";
import SingleBlue from "../../images/SingleBlue.svg";
import gridBlue from "../../images/gridBlue.svg";
import ClearText from "../../images/ClearText.svg";
import { IMAGE } from "../../Uitls/ComponentType";
const AntTabs = withStyles({
  root: {
    // width:'328px',
    color: "#000000",
    //width: "67px",
  },
  indicator: {
    backgroundColor: "#034ea2",
    bottom: "10px",

    // display:"none"
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 68,
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const ImageLibrary = ({
  linkImages,
  getLayerDetails,
  showToast,
  showToastForUplaod,
  handleClearToast,
  handleClearLinkToast,
  tab1Value,
  handleTab1,
  showErrToast,
  handleClearLinkErrToast,
  showErrToastUpload,
}) => {
  const { t } = useTranslation();
  const [sequentialClick, setSequentialClick] = useState(true);
  const [tilesClick, setTilesClick] = useState(false);
  const [updatedImageCount, setUpdatedImageCount] = useState(0);
  //const defaultTabValue = window.localStorage.getItem("leftPanelTabValue") || 0;

  // useEffect(() => {
  //   if (tab1Value === 0) {
  //     let uploadImageCount = linkImages.filter(
  //       (element) => element.imageType === "Upload"
  //     );
  //     setUpdatedImageCount(uploadImageCount.length);
  //   } else if (tab1Value === 1) {
  //     let uploadImageCount2 = linkImages.filter(
  //       (element) => element.imageType === "Link"
  //     );
  //     setUpdatedImageCount(uploadImageCount2.length);
  //   } else {
  //     setUpdatedImageCount(0);
  //   }
  // });
  const handleClickSequential = () => {
    setSequentialClick(true);
    setTilesClick(false);
  };
  const handleClickTiles = () => {
    setTilesClick(true);
    setSequentialClick(false);
  };
  const uploadTabarraylength = linkImages.filter(
    (element) => element.imageType === "Upload"
  ).length;
  const linksTabarraylength = linkImages.filter(
    (element) => element.imageType === "Link"
  ).length;

  return (
    <>
      <div className="imageLibrary pt-4">
        {/*   <div className="container"> */}
        <div className="row">
          <div className="col-8 libraryCol">
            {t("toolbarLeftPanel.library")}
          </div>
          {/* <img
            src={sequentialClick ? SingleBlue : Single}
            className="col-2 singleImages"
            onClick={handleClickSequential}
            alt=""
          ></img>
          <img
            src={tilesClick ? gridBlue : Grid}
            className="col-2 gridImages"
            onClick={handleClickTiles}
            alt=""
          ></img> */}
        </div>
        <AntTabs
          value={tab1Value}
          variant="fullWidth"
          onChange={handleTab1}
          className="imageTabs"
        >
          <AntTab label={`Uploaded(${uploadTabarraylength})`} />
          <AntTab label={`Links(${linksTabarraylength})`} />
        </AntTabs>
        {/*    <TabPanel
          value={tab1Value}
          index={0}
          className={sequentialClick || tilesClick ? "brandTab" : ""}
        ></TabPanel> */}
        <TabPanel
          value={tab1Value}
          index={0}
          //  className={sequentialClick || tilesClick ? "brandTab" : ""}
          // className="brandTab"
        >
          <div>
            {linkImages
              .filter((element) => element.imageType === "Upload")
              .map((linkImage, index) => {
                return (
                  <div className="imagePattern">
                    <img
                      src={linkImage.s3Url}
                      className="brandTabImages img-fluid "
                      alt=""
                      onDragStart={(e) => {
                        e.dataTransfer.setData("url", linkImage.s3Url);
                        getLayerDetails(e.target.id, IMAGE, linkImage.s3Url);
                        // getLayerDetails(e.target.id, IMAGE, linkImage);
                      }}
                      onDrag={(e) => {
                        e.dataTransfer.setData("url", linkImage.s3Url);
                      }}
                    />
                  </div>
                );
              })}

            {/* <div className="row ">
              {linkImages
                .filter((element) => element.imageType === "Upload")
                .map((linkImage, index) => {
                  return (
                    <div className="col-4 libraryCol">
                      <div className="imagePattern">
                        <img
                          src={linkImage.s3Url}
                          className="brandTabImages img-fluid "
                          alt=""
                          onDragStart={(e) => {
                            e.dataTransfer.setData("url", linkImage.s3Url);
                            getLayerDetails(
                              e.target.id,
                              IMAGE,
                              linkImage.s3Url
                            );
                            // getLayerDetails(e.target.id, IMAGE, linkImage);
                          }}
                          onDrag={(e) => {}}
                        />
                      </div>
                    </div>
                  );
                })}
            </div> */}
          </div>
        </TabPanel>
        <TabPanel
          value={tab1Value}
          index={1}
          // className={sequentialClick || tilesClick ? "brandTab" : ""}
          //  className="brandTab"
        >
          <div>
            {linkImages
              .filter((element) => element.imageType === "Link")
              .map((linkImage, index) => {
                return (
                  <div className="imagePattern">
                    <img
                      src={linkImage.s3Url}
                      className="brandTabImages img-fluid "
                      alt=""
                      draggable={true}
                      onDragStart={(e) => {
                        e.dataTransfer.setData("url", linkImage.s3Url);
                        // getLayerDetails(e.target.id, IMAGE, linkImage);
                        e.dataTransfer.setData("url", linkImage.s3Url);
                        getLayerDetails(e.target.id, IMAGE, linkImage.s3Url);
                      }}
                      onDrag={(e) => {}}
                    />
                  </div>
                );
              })}

            {/* <div className="row ">
              {linkImages
                .filter((element) => element.imageType === "Link")
                .map((linkImage, index) => {
                  return (
                    <div className="col-4 libraryCol">
                      <div className="imagePattern">
                        <img
                          src={linkImage.s3Url}
                          className="brandTabImages img-fluid "
                          alt=""
                          draggable={true}
                          onDragStart={(e) => {
                            e.dataTransfer.setData("url", linkImage.s3Url);
                            e.dataTransfer.setData("url", linkImage.s3Url);
                            // getLayerDetails(e.target.id, IMAGE, linkImage);
                            getLayerDetails(
                              e.target.id,
                              IMAGE,
                              linkImage.s3Url
                            );
                          }}
                          onDrag={(e) => {}}
                        />
                      </div>
                    </div>
                  );
                })}
            </div> */}
          </div>
        </TabPanel>
        {showToast && showToast ? (
          <div className="showToastUpload" id="toastAppear">
            <p>{t("imageWindowTabs.linkToastMsg")}</p>
            <img
              src={ClearText}
              alt=""
              className="clearTextIcon clearText closeToast"
              onClick={handleClearLinkToast}
            />
          </div>
        ) : showErrToast && showErrToast ? (
          <div className="showerrToast" id="errToast">
            <p>{t("imageWindowTabs.linkToastErrMsg")}</p>
            <img
              src={ClearText}
              alt=""
              className="clearTexterr clearText closeToast"
              onClick={handleClearLinkErrToast}
            />
          </div>
        ) : (
          ""
        )}
        {showToastForUplaod && showToastForUplaod ? (
          <div className="showToastUpload" id="toastAppearUpload">
            <p>{t("imageWindowTabs.uploadToastmsg")}</p>
            <img
              src={ClearText}
              alt=""
              className="clearTextIcon clearText closetoastUpload ml-4"
              onClick={handleClearToast}
            />
          </div>
        ) : showErrToastUpload && showErrToastUpload ? (
          <div className="showerrToast" id="errToast">
            <p>{t("imageWindowTabs.linkToastErrMsg")}</p>
            <img
              src={ClearText}
              alt=""
              className="clearTexterr clearText closeToast"
              onClick={handleClearLinkErrToast}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ImageLibrary;
