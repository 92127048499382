import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import BusinessRulesSpecificInfo from "../components/BusinessRulesSpecificInfo";
import ModuleSpecificInfo from "../components/ModuleSpecificInfo";
import { Container, Row, Col } from "react-bootstrap";

export default function BusinessModal({
  modalStatus,
  handleBuinessOpenModal,
  moduleDetails,
  bModules,
}) {
  // const [moduleSpecificJsx, setModuleSpecificJsx] = useState(undefined);
  // const [businessSpecificJsx, setBusinessSpecificJsx] = useState(undefined);
  const handleClose = () => {
    handleBuinessOpenModal(false);
  };

  const renderModuleSpecificInfo = () => {
    if (!moduleDetails || Object.keys(moduleDetails).length <= 0) {
      return <div></div>;
    }
    const jsx = (
      <div>
        {moduleDetails && Object.keys(moduleDetails).length > 0 && (
          <div className="module-specific-info">
            <ModuleSpecificInfo
              module={moduleDetails}
              //setJsx={setModuleSpecificJsx}
            />
            {moduleDetails && moduleDetails.moduleBusinessRules && (
              <BusinessRulesSpecificInfo
                module={moduleDetails}
                bModules={bModules}
                // setJsx={setBusinessSpecificJsx}
              />
            )}
          </div>
        )}
      </div>
    );
    return jsx;
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        open={modalStatus}
        onClose={handleClose}
        className="buisness-modal"
        overlayStyle={{ backgroundColor: "transparent" }}
      >
        <DialogTitle>
          <div className="buisness-ModalHeading">
            Module-specific information
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="custonScrollBar">
          <Container>
            <Row>
              <Col>
                <div className="bodyContentBg  ">
                  {renderModuleSpecificInfo()}
                </div>
              </Col>
            </Row>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}
