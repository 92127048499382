import React, { useEffect, useState } from "react";
//import edetailIcon from "../../images/E-Detail_Manager.svg";
import Logout from "../../components/Logout";
//import templateMangerIcon from "../../images/templateManager_icon.svg";
import { banner, template } from "../../Uitls/constants";
import templateactive from "../../images/templateActive.svg";
import bannerActive from "../../images/BannerActive.svg";
import bannerInActive from "../../images/Bannerinactive.svg";

import templateInactive from "../../images/templateinactive.svg";

import "./TemplateM.scss";

const TemplateLeftPanel = ({
  banner,
  setBanner,
  toggleLandingPage,
  appData,
}) => {
  // useEffect(() => {
  //
  //   console.log("appData.assetInfo.assetType", appData.assetInfo.assetType);
  //   setBanner(
  //     appData.assetType === "Banner"
  //       ? true
  //       : appData.assetType === "Template"
  //       ? false
  //       : banner
  //   );
  // }, []);//DeleteNavigate
  return (
    <div className="TemplateLeftPanel">
      <ul className="templateList mt-4">
        <li
          onClick={() => toggleLandingPage("banner")}
          className={banner ? "active" : ""}
        >
          <div className="d-flex">
            <img
              src={banner ? bannerActive : bannerInActive}
              alt=""
              className="managerIcons mr-2"
            />
            <span>Banner Manager</span>
          </div>
        </li>

        <li
          onClick={() => toggleLandingPage("template")}
          className={!banner ? "active" : ""}
        >
          <div className="d-flex">
            <img
              src={!banner ? templateactive : templateInactive}
              alt=""
              className="managerIcons mr-2"
            />
            <span>Template Manager</span>
          </div>
        </li>
      </ul>
      <Logout />
    </div>
  );
};

export default TemplateLeftPanel;
