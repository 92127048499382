import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";

// const { t } = useTranslation();

export const getDyanamoDBInstance = (dynamo) => {
  return new dynamo.AWS.DynamoDB({ apiVersion: "2012-08-10" });
};

export const isoCountryTableName = "imc-iso-countrycode";
export const awsRegion = "ap-south-1";
// const dynamoDBConfig = {
//   region: "ap-south-1",
//   // accessKeyId default can be used while using the downloadable version of DynamoDB.
//   // For security reasons, do not store AWS Credentials in your files. Use Amazon Cognito instead.
//   accessKeyId: "",
//   // secretAccessKey default can be used while using the downloadable version of DynamoDB.
//   // For security reasons, do not store AWS Credentials in your files. Use Amazon Cognito instead.
//   secretAccessKey: "",
// };
// export const updateDynamicDBConfig = (dynamo) => {
//   dynamo.AWS.config.update(dynamoDBConfig);
// };

export const routes = {
  signup: "/signup",

  forgotPassword: "/forgot-password",
};

export const validateUrl = (url) => {
  //((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
};

export const htmlToString = (html) => {
  if (html) {
    return html.replace(/(<([^>]+)>)/gi, "").replace(/[[\]]/g, "");
  }
  return "";
};

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

Array.prototype.unique = function () {
  return Array.from(new Set(this));
};

export function checkURL(url) {
  return url.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
}

export const OutsideClickHandler = (ref, callback, when, compId, setEdited) => {
  const savedCallBack = useRef(callback);

  useEffect(() => {
    savedCallBack.current = callback;
  }, []);

  function handler(e) {
    if (when && e.target.id === compId) return;
    if (ref.current && !ref.current.contains(e.target)) {
      if (e.target.name !== "undoIcon") {
        savedCallBack.current();
      }
    }
  }

  useEffect(() => {
    if (when) {
      document.addEventListener("click", handler);
      return () => document.removeEventListener("click", handler);
    }
  }, [when]);
};

function hasDecimal(num) {
  return !!(num % 1);
}
const chopTheDecimalPlaceTo2 = (value) => {
  let x = value.toString();
  if (x.length > x.indexOf(".") + 3) return x.substring(0, x.indexOf(".") + 3);
  return Number(value);
};
export const getValidInput = (e, name, blur) => {
  let decimalRequiredFields = [
    "left",
    "top",
    "fontSize",
    "letterSpacing",
    "lineHeight",
  ];
  let maxLength3Digitfields = ["fontSize", "letterSpacing", "lineHeight"];
  let maxValue =
    name === "WidthBanner" ? 1920 : name === "HeightBanner" ? 1080 : 9999;
  if (maxLength3Digitfields.includes(e.target.name)) {
    maxValue = 999;
  }
  let minValue = -9999;
  const strToNum = (val) => {
    if (decimalRequiredFields.includes(name)) {
      return val[val.length - 1] === "." ? val : Number(val);
    }
    return parseInt(val);
  };

  const isNotANumber = (val) => {
    if (decimalRequiredFields.includes(name)) {
      return isNaN(val) ? (val[0] === "-" ? false : true) : false;
    }

    return isNaN(val) || val < 0;
  };

  let value = e.target.value;
  if (value === " ") return -1;
  if (parseInt(value) > maxValue) {
    value = maxValue;
    // toast.error(
    //   <div className="notificationText">
    //     Value can not be more than {maxLength} digits
    //   </div>
    // );
    // return -1;
  } else if (parseInt(value) < minValue) {
    value = minValue;
  }

  value = value.toString();

  if (
    e.target.name == "top" ||
    e.target.name == "left" ||
    e.target.name == "rotation" ||
    e.target.name === "translateX" ||
    e.target.name === "translateY" ||
    e.target.name === "transformOriginX" ||
    e.target.name === "transformOriginY" ||
    e.target.name === "transformOriginZ"
  ) {
    if (isNaN(value) && value != "-") {
      return -1;
    }
    if (value != "" && value[0] !== "-" && isNaN(value[0])) {
      return -1;
    }
    if (value.includes(".")) {
      return -1;
    }
  } else if (
    !(
      e.target.name == "top" ||
      e.target.name == "left" ||
      e.target.name == "rotation"
    ) &&
    (isNotANumber(value) || value[0] === "-")
  ) {
    return -1;
  } // Allow negative values for left, top and rotation
  let nulVal = blur === "blur" ? 0 : "";

  return value[0] !== "-"
    ? value !== ""
      ? hasDecimal(strToNum(value))
        ? chopTheDecimalPlaceTo2(value)
        : strToNum(value)
      : nulVal
    : value; // if the string is "-" return as it is for x and Y
};
export const getValidInputRHS = (e, name, blur) => {
  let decimalRequiredFields = [
    "left",
    "top",
    "fontSize",
    "letterSpacing",
    "lineHeight",
  ];
  let maxLength3Digitfields = ["fontSize", "letterSpacing", "lineHeight"];
  let maxValue =
    name === "WidthBanner" ? 1920 : name === "HeightBanner" ? 1080 : 9999;
  if (maxLength3Digitfields.includes(e.target.name)) {
    maxValue = 999;
  }
  const strToNum = (val) => {
    if (val[val.length - 1] === ".") {
      return val;
    } else {
      return Number(val);
    }
  };
  const isNotANumber = (val) => {
    if (decimalRequiredFields.includes(name)) {
      return isNaN(val) ? (val[0] === "-" ? false : true) : false;
    }
    return isNaN(val) || val < 0;
  };

  let value = e.target.value;
  if (value === " ") return -1;
  if (parseInt(value) > maxValue) {
    value = maxValue;
    // toast.error(
    //   <div className="notificationText">
    //     Value can not be more than {maxLength} digits
    //   </div>
    // );
    // return -1;
  }

  if (isNotANumber(value)) return -1;
  let nulVal = blur === "blur" ? 0 : "";

  return value[0] !== "-"
    ? value !== ""
      ? hasDecimal(strToNum(value))
        ? chopTheDecimalPlaceTo2(value)
        : strToNum(value)
      : nulVal
    : value;
  // if the string is "-" return as it is for x and Y
};

// export const getValidInput = (e, blur) => {
//   let value = e.target.value;
//   if (value.length > 4 && parseInt(value) > 9999) {
//     toast.error(
//       <div className="notificationText">
//         Value can not be more than 4 digits
//       </div>
//     );
//     return -1;
//   }
//   if (isNaN(value)) return -1;
//   let nulVal = blur === "blur" ? 0 : "";
//   return value !== "" ? parseInt(value) : nulVal;
// };
// export const preventPasteNegative = (e) => {
//   const clipboardData = e.clipboardData || window.clipboardData;
//   const pastedData = parseFloat(clipboardData.getData("text"));

//   if (pastedData < 0) {
//     e.preventDefault();
//   }
// };

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const inlineNameValidation = (name, assetType) => {
  //name = name.trim();

  let retVal = { valid: true };

  let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

  let testCases = [
    "emptyValue",

    "startsWithSpaceTest",

    "shorterLengthTest",

    "biggerLengthTest",

    "formatTest",
  ];

  for (let i = 0; i < testCases.length; i++) {
    switch (testCases[i]) {
      case "startsWithSpaceTest":
        if (/^\s/.test(name) === true)
          retVal = { valid: false, message: "Not allowed" };

        break;
      case "formatTest":
        if (format.test(name) === true) {
          retVal = { valid: false, message: "Special characters not allowed" };
        }
        break;
      case "shorterLengthTest": // if (name.length <= 2) { //
        const withoutSpaces = name.replace(/\s/g, "");
        const lengthWithoutSpaces = withoutSpaces.length;
        if (lengthWithoutSpaces <= 2) {
          retVal = { valid: false, message: "Please use minimum 3 characters" };
        }
        break;

      case "biggerLengthTest":
        if (name.length >= 250) {
          retVal = {
            valid: false,
            message: "Name exceeds maximum length",
          };
        }
        break;

      default:
        retVal = { valid: true };
    }
    console.log(retVal);
    if (retVal.message === "Not allowed") break;
  }
  return retVal;
};
export const nameValidation = (name, assetType) => {
  //name = name.trim();

  let InvalidError = { message: "", valid: true };

  let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

  let testCases = [
    "emptyValue",

    "startsWithSpaceTest",

    "shorterLengthTest",

    "biggerLengthTest",

    "formatTest",
  ];

  for (let i = 0; i < testCases.length; i++) {
    switch (testCases[i]) {
      case "emptyValue":
        if (name.trim() === "") {
          InvalidError.valid = false;
          InvalidError.message = `Please update ${assetType} name. !`;
        }

        break;

      case "startsWithSpaceTest":
        if (/^\s/.test(name) === true) {
          InvalidError.valid = false;

          InvalidError.message = `${assetType} name can't start with spaces. !`;
        }

        break;

      case "formatTest":
        if (format.test(name) === true) {
          InvalidError.valid = false;

          InvalidError.message = ` ${assetType} name with special characters is not allowed. !`;
        }

        break;

      case "shorterLengthTest": // if (name.length <= 2) { //
        const withoutSpaces = name.replace(/\s/g, "");

        const lengthWithoutSpaces = withoutSpaces.length;

        if (lengthWithoutSpaces <= 2) {
          InvalidError.valid = false;

          InvalidError.message = `Entered ${assetType} name is short. ! It should be more than 2 characters.`;
        } // }

        break;

      case "biggerLengthTest":
        if (name.length >= 250) {
          InvalidError.valid = false;

          InvalidError.message = `Entered ${assetType} name is too lengthy. ! It should be less than 250 characters.`;
        }

        break;

      default:
        InvalidError.valid = true;
    }

    if (!InvalidError.valid) break;
  }

  if (!InvalidError.valid) {
    toast.error(<div className="notificationText">{InvalidError.message}</div>);
  }

  return InvalidError.valid;
};

export const showCreatedOntime = (createdTime) => {
  const date = new Date(createdTime);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
};

export function convertTimestamp(timestamp) {
  console.log("timeStamp", timestamp);
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  const originaldate = `${day}/${month}/${year}`;

  const convertedTime = `${formatNumber(date.getHours())}:${formatNumber(
    date.getMinutes()
  )}:${formatNumber(date.getSeconds())} hrs`;
  const convertedTimestamp = `${originaldate}, ${convertedTime}`;
  return convertedTimestamp;
}
export const formatNumber = (num) => {
  return num < 10 ? `0${num}` : num;
};
