export function RenderDOMData(data) {
  let layerCompData = [],
    animationObj = [];

  let sample = [];

  let Id;
  data.forEach((data, index) => {
    // console.log(data.componentType, "sssssss");
    Id = data.componentType + index;
    let shapeType =
      data.components.textComponentType?.addContent === "triangleShape"
        ? "triImage"
        : data.components.textComponentType?.addContent === "polygonShape"
        ? "pentImage"
        : data.components.textComponentType?.addContent === "hexagonShape"
        ? "hexImage"
        : "";
    layerCompData.push({
      id: data.componentType + index,
      componentType: data.componentType,
      froalaText: data.components.froalaText,
      componentStyles: data.components,
      textComponentType: data.components.textComponentType,
      [shapeType]: data.components.src,
    });

    let animationObjectNew = JSON.parse(
      JSON.stringify(data.components.animationObj || [])
    );
    // Object.values(animationObjectNew.animation).forEach((obj) => {
    let obj = animationObjectNew.animation || [];
    if (obj.length > 0 && obj[0].delay !== 0) {
      let tempObj = obj[0];
      if (tempObj) {
        tempObj.delay = 0;

        tempObj.duration = tempObj?.end;
        let firstObject = {
          ...tempObj,
          rotation: 0,
          left: 0,
          top: 0,
          width: tempObj.width,
          height: tempObj.height,
          transform: 1,
          delay: 0,
          duration: 0,
          ease: "NONE",
          end: 0,
          opacity: 1,
        };
        obj.splice(0, 0, firstObject);
        obj[1] = tempObj;
      }
    }
    // });

    obj.forEach((eachAnimation) => {
      const animationData = {
        api: "to",
        atoms: [Id],
        duration: eachAnimation.duration,
        op: {
          delay: eachAnimation.delay,
          opacity: eachAnimation.opacity,
          ease: eachAnimation.ease,
          rotation: eachAnimation.rotation,
          scale: eachAnimation.transform,
          left: eachAnimation.x,
          top: eachAnimation.y,
          transform: `translate3d(${eachAnimation.translateX}px, ${eachAnimation.translateY}px,0px)`,
          width: eachAnimation?.width,
          height: eachAnimation?.height,
          webkitTransformOrigin: `${eachAnimation.transformOriginX}% ${eachAnimation.transformOriginY}% ${eachAnimation.transformOriginZ}px`,
        },
      };
      animationObj.push(animationData);
    });
  });
  // setCanvasDom(layerCompData);
  // setAnimationObj(animationObj);

  return { layerCompData, animationObj };
  //console.log(layerCompData, "layerCompData", animationObj);
}
