import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "../../styles/components/Editor.scss";
import "../../styles/components/EditorModal.scss";
import ValueContext from "../../ContextAPi/appContext";
import Button from "@material-ui/core/Button";
import { deleteBanners } from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import Locksymbol from "../../images/Locksymbol.svg";
import Unlocksymbol from "../../images/UnlockSymbol.svg";

export default function BannerLockUnlockModal({
  setShowLockModal,
  showLockModal,
  handleLockBannerModal,
  assetLockUnlock,
  recentlyViewedData,
  isLocked,
  loading,
}) {
  const appData = useContext(ValueContext);

  const { t } = useTranslation();

  const handleClose = () => {
    setShowLockModal(false);
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        open={showLockModal}
        onClose={handleClose}
        className="lock-modal"
        aria-labelledby="form-dialog-title"
        overlayStyle={{ backgroundColor: "transparent" }}
      >
        {loading && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress size="30px" className="customLoader" />{" "}
          </>
        )}
        <DialogTitle id="form-dialog-title">
          <img
            src={isLocked ? Locksymbol : Unlocksymbol}
            alt=""
            className="img-fluid"
          />
          <div className="lock-modalheading">
            {isLocked ? t("Lock Banner") : t("Unlock Banner")}
          </div>
        </DialogTitle>
        {/* <DialogTitle id="form-dialog-title">
          <div className="delete-modalSubHeading">
            {t("bannerManager.deleteModal.deleteTitle")}
            <span className="DeleteOnly">{"Delete ?"}</span>
          </div>
        </DialogTitle> */}

        <DialogContent>
          <div className="lock-modalContent ">
            {isLocked
              ? t(
                  "Locking the banner will prevent other users from editing it."
                )
              : t("Unlocking the banner will allow other users to edit it.")}
            <span>{t(" Are you sure?")}</span>
          </div>
        </DialogContent>
        <DialogActions className="lock-actions">
          <Button
            onClick={handleClose}
            color="primary"
            className="cance-btn cance"
          >
            {t("CANCEL")}
          </Button>
          <Button
            onClick={() => {
              //deleteBannerCards();
              assetLockUnlock(
                recentlyViewedData?.id,
                isLocked ? "Locked" : "Unlocked",
                recentlyViewedData?.assetType
              );
              handleClose();
            }}
            type="submit"
            className="btn btn-primary"
            autoFocus
          >
            {t("PROCEED")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
