/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styles/components/DropDownWithLabel.scss";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  // Add a new CSS class to target the Autocomplete menu
  menuItemBox: {
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)", // Add your desired box shadow styles here
  },
  // ...
}));
const DropDownWithSearch = (props) => {
  //const classes = useStyles();

  setTimeout(async () => {
    const close = await document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (close) {
      close.addEventListener("click", () => {
        const handleChnage = props?.options?.handleChange;
        if (handleChnage) {
          handleChnage(undefined);
        }
      });
    }
  }, 0);

  const getValue = () => {
    const options = props.options;
    if (options && options.items && options.items.length > 0 && options.value) {
      // ((options.items.indexOf(options.value) !== -1) || ))
      return options.value;
    }
    return "";
  };

  const useStyles = makeStyles((theme) => ({
    popupIndicator: {
      "& span": {
        "& svg": {
          "& path": {
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')",
          },
        },
      },
    },
  }));
  const classes = useStyles();

  return (
    <Autocomplete
      id="search-and-select"
      style={{ width: "100%" }}
      options={props.options.items}
      value={getValue()}
      onChange={(event, value) => {
        props.options.handleChange(value);
      }}
      getOptionSelected={(option) => {
        return option.displayName === props.options.value?.displayName;
      }}
      closeIcon={""}
      closeText=""
      openText=""
      className="drop-down-with-search hideArrow custonScrollBar myAutocomplete"
      autoHighlight
      getOptionLabel={(option) => option.displayName || ""}
      classes={{
        clearIndicatorDirty: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
        paper: classes.menuItemBox,
      }}
      renderOption={(option) => (
        <React.Fragment>
          <span className="display-name">{option.displayName}</span>
        </React.Fragment>
      )}
      renderInput={(params, option) => (
        // <Tooltip
        //   /* title={
        //     props?.options?.value?.displayName === undefined
        //       ? "Market"
        //       : props?.options?.value?.displayName
        //   } */
        //   title={
        //     props?.options?.value?.displayName?.length >= 15
        //       ? props?.options?.value?.displayName
        //       : ""
        //   }
        //   placement="top"
        // >

        <TextField
          {...params}
          label={props.options.label}
          variant="standard"
          inputProps={{
            ...params.inputProps,
            title: "",

            // autoComplete: 'off', // disable autocomplete and autofill
          }}
          className="templateNameTextfield marketFieldTemp"
        />
        // </Tooltip>
      )}
    />
  );
};

export default DropDownWithSearch;
