import { pasteMultiSelectedComps } from "./multiCompSelect";
export function keyPressAction(
  event,
  tempSelect,
  updateCompValues,
  setVerticalLineFor,
  definedObjs
) {
  console.log(event);
  setVerticalLineFor({
    left: false,
    right: false,
    top: false,
    bottom: false,
    leftid: false,
    rightid: false,
    topid: false,
    bottomid: false,
    vMiddle: false,
    hMiddle: false,
    vMiddleid: false,
    hMiddleid: false,
    // leftValue:false,
    rightValue: false,
    bottomValue: false,
    lastSelectedComp: false,
  });
  if (
    event.target.tagName === "INPUT" ||
    event.target.classList.contains("fr-view")
  )
    return;

  if (
    !tempSelect.current.id &&
    definedObjs.multiSelectedComps.length < 1 &&
    definedObjs.copiedComponent.length < 1
  ) {
    return false;
  } else {
    event.preventDefault();

    let tempElemnt = tempSelect.current.componentStyles;

    switch (event.code) {
      case "ArrowLeft":
        if (event.shiftKey) {
          updateCompValues({
            x: tempElemnt.left - 10,
            y: tempElemnt.top,
            id: tempSelect.current.id,
          });
        } else
          updateCompValues({
            x: tempElemnt.left - 1,
            y: tempElemnt.top,
            id: tempSelect.current.id,
          });
        break;
      case "ArrowRight":
        if (event.shiftKey) {
          updateCompValues({
            x: tempElemnt.left + 10,
            y: tempElemnt.top,
            id: tempSelect.current.id,
          });
        } else
          updateCompValues({
            x: tempElemnt.left + 1,
            y: tempElemnt.top,
            id: tempSelect.current.id,
          });
        break;
      case "ArrowUp":
        if (event.shiftKey) {
          updateCompValues({
            x: tempElemnt.left,
            y: tempElemnt.top - 10,
            id: tempSelect.current.id,
          });
        } else {
          updateCompValues({
            x: tempElemnt.left,
            y: tempElemnt.top - 1,
            id: tempSelect.current.id,
          });
        }
        break;
      case "ArrowDown":
        if (event.shiftKey) {
          updateCompValues({
            x: tempElemnt.left,
            y: tempElemnt.top + 10,
            id: tempSelect.current.id,
          });
        } else {
          updateCompValues({
            x: tempElemnt.left,
            y: tempElemnt.top + 1,
            id: tempSelect.current.id,
          });
        }
        break;

      case "KeyC":
        if (event.ctrlKey || event.metaKey) {
          if (definedObjs.multiSelectedComps.length > 0)
            definedObjs.setCopiedComponent(definedObjs.multiSelectedComps);
        }
        break;

      case "KeyV":
        if (event.ctrlKey || event.metaKey) {
          if (definedObjs.copiedComponent.length >= 1)
            pasteMultiSelectedComps(
              definedObjs.count,
              definedObjs.setCount,
              definedObjs.setShowPaste,
              definedObjs.setVisibility,
              definedObjs.layerCompData,
              definedObjs.setLayerCompData,
              definedObjs.copiedComponent,
              definedObjs.countZindex,
              definedObjs.multiSelectedComps,
              definedObjs.setMultiSelectedComps,
              definedObjs.componentType,
              definedObjs.imageOnCanvas,
              definedObjs.setSelectedComponent,
              definedObjs.selectedComponent
            );
        }
        break;

      // case "ControlLeft":
      //   if (event.shiftKey) {
      //     updateCompValues({
      //       x: tempElemnt.left - 10,
      //       y: tempElemnt.top,
      //       id: tempSelect.current.id,
      //     });
      //   } else {
      //     updateCompValues({
      //       x: tempElemnt.left - 1,
      //       y: tempElemnt.top,
      //       id: tempSelect.current.id,
      //     });
      //   }
      //   break;
      // case "ControlRight":
      //   updateCompValues({
      //     x: tempElemnt.left + 10,
      //     y: tempElemnt.top,
      //     id: tempSelect.current.id,
      //   });
      //   break;
      // case "ControlUp":
      //   updateCompValues({
      //     x: tempElemnt.left,
      //     y: tempElemnt.top - 10,
      //     id: tempSelect.current.id,
      //   });
      //   break;
      // case "ControlDown":
      //   updateCompValues({
      //     x: tempElemnt.left,
      //     y: tempElemnt.top + 10,
      //     id: tempSelect.current.id,
      //   });
      //   break;
      default:
        break;
    }
  }
}
