import axios from "axios";
const moduleApiEndPoint = "https://qa-moduleapi.indegene.com"; //"https://dev-moduleapi.indegene.com";
//  ;
// "https://dev-moduleapi.indegene.com";
const storageApiEndPoint = "https://qa-storageapi.indegene.com";
// "https://dev-storageapi.indegene.com";
export const instance = axios.create({
  baseURL: moduleApiEndPoint,
  timeout: 15000,

  // headers: { "X-Custom-Header": "foobar" },
});
//testing
// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const instanceStorage = axios.create({
  baseURL: moduleApiEndPoint,
  timeout: 8000,
  // headers: { "X-Custom-Header": "foobar" },
});

// Add a request interceptor
instanceStorage.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceStorage.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const instanceUpload = axios.create({
  baseURL: moduleApiEndPoint,
  timeout: 60 * 4 * 1000,
});

// Add a request interceptor
instanceUpload.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceStorage.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// export default instance;

export default instance;
