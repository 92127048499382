import React, { useState } from "react";
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Form, Field, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import UserPool from "../../Uitls/Userpools";
import { useHistory } from "react-router-dom";

import "../../styles/pages/Login.scss";

const Signup = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorMsg, setshowErrorMsg] = useState(false);
  const [stage, setStage] = useState(1);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  var regex = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/;

  const validationSchema = yup.object().shape({
    email: yup.string().email("Email is invalid").required("Email is required"),
    password: yup.string().required("Password is required"),
    family_name: yup.string().required("Family name is required"),
    given_name: yup.string().required("Given name is required"),
    phone_number: yup.string().matches(regex, "Phone number is not valid"),
  });

  const userData = {
    Username: username,
    Pool: UserPool,
  };

  const cognitoUser = new CognitoUser(userData);
  let history = useHistory();
  const confirmSignupCode = (event) => {
    event.preventDefault();

    cognitoUser.confirmRegistration(password, true, function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      //console.log("call result: " + result);
      history.push("/");
    });
  };

  return (
    <div className="container-fluid loginBg">
      <div className="row align-itmes-center">
        <div className="col-md-3 offset-md-9">
          <div className="signInBox SignUpBox">
            <div className="loginContainer">
              <h1 className="appLogoText col-md-12 mb-3">Sign up</h1>
              {stage === 1 && (
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    family_name: "",
                    given_name: "",
                    phone_number: "+",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    const attributeList = [];

                    const dataGivenName = {
                      Name: "given_name",
                      Value: values.given_name,
                    };

                    const dataFmilyName = {
                      Name: "family_name",
                      Value: values.family_name,
                    };

                    const dataPhoneNumber = {
                      Name: "phone_number",
                      Value: values.phone_number,
                    };

                    // const dataEmailDomain = {
                    //   Name: "custom:domain",
                    //   Value: "indegene.com"
                    // }

                    const attributeGivenName = new CognitoUserAttribute(
                      dataGivenName
                    );
                    const attributeFamilyName = new CognitoUserAttribute(
                      dataFmilyName
                    );
                    const attributePhoneNumber = new CognitoUserAttribute(
                      dataPhoneNumber
                    );
                    // const attributeEmailDomain = new CognitoUserAttribute(dataEmailDomain);

                    attributeList.push(attributeGivenName);
                    attributeList.push(attributeFamilyName);
                    attributeList.push(attributePhoneNumber);
                    // attributeList.push(attributeEmailDomain);

                    UserPool.signUp(
                      values.email,
                      values.password,
                      attributeList,
                      null,
                      (err, data) => {
                        if (err) {
                          setStage(1);
                          console.error(err);
                          setErrorMsg(err.message);
                          setshowErrorMsg(true);
                          // console.error("Failed to login!", err);
                          setTimeout(() => {
                            setshowErrorMsg(false);
                          }, 10000);
                        } else {
                          setUsername(values.email);
                          setStage(2);
                          console.log(data);
                        }
                      }
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      {showErrorMsg ? (
                        <div
                          className="alert alert-warning alert-text"
                          role="alert"
                        >
                          {errorMsg}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group text-left position-relative mb-4">
                        <label
                          htmlFor="userName"
                          className={
                            "loginLabel " +
                            (errors.email && touched.email ? "label-error" : "")
                          }
                        >
                          Email:
                        </label>
                        <input
                          type="text"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className={
                            "form-control loginInput" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group text-left position-relative mb-4">
                        <label
                          htmlFor="userName"
                          className={
                            "loginLabel " +
                            (errors.password && touched.password
                              ? "label-error"
                              : "")
                          }
                        >
                          Password:
                        </label>
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          className={
                            "form-control loginInput" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group text-left position-relative mb-4">
                        <label
                          htmlFor="userName"
                          className={
                            "loginLabel " +
                            (errors.given_name && touched.given_name
                              ? "label-error"
                              : "")
                          }
                        >
                          Given name:
                        </label>
                        <input
                          type="text"
                          name="given_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.given_name}
                          className={
                            "form-control loginInput" +
                            (errors.given_name && touched.given_name
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="given_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group text-left position-relative mb-4">
                        <label
                          htmlFor="userName"
                          className={
                            "loginLabel " +
                            (errors.family_name && touched.family_name
                              ? "label-error"
                              : "")
                          }
                        >
                          Family name:
                        </label>
                        <input
                          type="text"
                          name="family_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.family_name}
                          className={
                            "form-control loginInput" +
                            (errors.family_name && touched.family_name
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="family_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group text-left position-relative mb-4">
                        <label
                          htmlFor="userName"
                          className={
                            "loginLabel " +
                            (errors.phone_number && touched.phone_number
                              ? "label-error"
                              : "")
                          }
                        >
                          Phone number:
                        </label>
                        <Field
                          type="text"
                          name="phone_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone_number}
                          className={
                            "form-control loginInput" +
                            (errors.phone_number && touched.phone_number
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Submit
                      </button>
                    </Form>
                  )}
                </Formik>
              )}
              {stage === 2 && (
                <div>
                  <p>Enter your verification code </p>
                  <form onSubmit={confirmSignupCode}>
                    <input
                      value={password}
                      className="form-control"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary btn-block mt-4"
                    >
                      Verify account now
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
