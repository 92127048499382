import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import RefreshIcon from "../../images/Refreshimg.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { getbannerandTemplateDetails, getMLRstatus } from "../../services/apis";
import "../../styles/pages/Banners/BannerManager.scss";
import ValueContext from "../../ContextAPi/appContext";
import Button from "@material-ui/core/Button";
import CopyImage from "../../images/CopyImage.svg";

import lockCircled from "../../images/lockCircled.svg";
import { CircularProgress } from "@material-ui/core";

export default function BannerAndTemplateDetailsModal(props) {
  const {
    lockStatus,
    setShowDetails,
    showDetails,
    bannerDetails,
    setBannerDetails,
    recentlyViewedData,
  } = props;
  const appData = useContext(ValueContext);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowDetails(false);
    setBannerDetails({});
  };

  const getLatestMLRStatus = (id) => {
    setLoading(true);

    getMLRstatus(id, async (response, err, errorCode) => {
      if (err) {
        // const errCoponent = errComponentToRender(errorCode);
        console.log(err);
        setLoading(false);
      } else {
        setLoading(false);
        const data = await response.data;
        setBannerDetails({
          ...bannerDetails,
          reviewStatus: data?.reviewStatus || "Not submitted",
        });
      }
    });
  };
  const showCreatedOntime = (createdTime) => {
    const date = new Date(createdTime);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  };

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    const originaldate = `${day}/${month}/${year}`;

    const convertedTime = `${formatNumber(date.getHours())}:${formatNumber(
      date.getMinutes()
    )}:${formatNumber(date.getSeconds())} hrs`;
    const convertedTimestamp = `${originaldate}, ${convertedTime}`;
    return convertedTimestamp;
  }
  const formatNumber = (num) => {
    return num < 10 ? `0${num}` : num;
  };

  const templateLink = (assetId) => {
    let url = window.location.origin;
    const editorLink = `${url}/banner-editor/${assetId}`;

    return editorLink;
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        open={showDetails}
        onClose={handleClose}
        className="details-modal detilsDialog"
        id="details-modal"
        aria-labelledby="form-dialog-title"
        overlayStyle={{ backgroundColor: "transparent" }}
      >
        {loading && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress size="30px" className="customLoader" />{" "}
          </>
        )}
        <div className=" bannerRow customScrollbar">
          <div className="leftBox ">
            <div className="innerLeftbox">
              <DialogTitle id="form-dialog-title" className="bannerInfo">
                <div className="details-modalheading">
                  {t("Template Information")}
                </div>
              </DialogTitle>

              <>
                <Tooltip title={bannerDetails?.assetName} placement="top">
                  <div
                    className="d-flex contentrow"
                    style={{ paddingTop: "10px" }}
                  >
                    <div className="details-modalContent">Name</div>{" "}
                    <div className="content">{bannerDetails?.assetName}</div>{" "}
                  </div>
                </Tooltip>
                <div className="d-flex contentrow">
                  <div className="details-modalContent">Type</div>{" "}
                  <div className="content">
                    {bannerDetails?.isAnimated === true ? "Animated" : "Static"}
                  </div>{" "}
                </div>
                <div className="d-flex contentrow">
                  <div className="details-modalContent">Brand</div>{" "}
                  <div className="content">{bannerDetails?.brandName}</div>{" "}
                </div>

                <div className="d-flex contentrow">
                  <div className="details-modalContent">Template link</div>{" "}
                  {lockStatus !== "Locked" ? (
                    <div className="contentbannerlink d-flex">
                      <>
                        <img
                          src={CopyImage}
                          alt=""
                          style={{ cursor: "pointer", marginTop: "-18px" }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              templateLink(recentlyViewedData?.id)
                            );
                          }}
                        />
                        <div className="banenrLink">
                          <Tooltip
                            title={templateLink(recentlyViewedData?.id)}
                            placement="top"
                          >
                            <a
                              href={templateLink(recentlyViewedData?.id)}
                              target="blank"
                            >
                              {" "}
                              {templateLink(recentlyViewedData?.id)}
                            </a>
                          </Tooltip>
                        </div>{" "}
                      </>
                    </div>
                  ) : (
                    <div className="contentbannerlink lockedlink">
                      <>
                        <Tooltip
                          title={templateLink(recentlyViewedData?.id)}
                          placement="top"
                        >
                          <div className="banenrLink">
                            {templateLink(recentlyViewedData?.id)}
                          </div>
                        </Tooltip>
                      </>
                      <div className="d-flex">
                        <img src={lockCircled} alt="" />
                        <span className="lockmessage">Template is locked</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex contentrow">
                  <div className="details-modalContent">Market</div>{" "}
                  <div className="content">{bannerDetails?.marketName}</div>{" "}
                </div>
                <div className="d-flex contentrow">
                  <div className="details-modalContent">Size</div>{" "}
                  <div className="content">
                    {bannerDetails?.width} x {bannerDetails?.height}
                  </div>{" "}
                </div>
                <Tooltip title={bannerDetails?.campaign} placement="top">
                  <div className="d-flex contentrow">
                    <div className="details-modalContent">Campaign</div>{" "}
                    <div className="content">
                      {/* {bannerDetails?.brandName === "— Not Brand Specific —"
                        ? "-"
                        :  */}
                      {bannerDetails?.campaign}
                    </div>{" "}
                  </div>
                </Tooltip>
                <div className="d-flex contentrow">
                  <div className="details-modalContent">DAMS ID</div>{" "}
                  <div className="content">
                    {" "}
                    {bannerDetails?.veevaDocNumber === null
                      ? "-"
                      : bannerDetails.veevaDocNumber}
                  </div>{" "}
                </div>
                <div className="d-flex contentrow">
                  <div className="details-modalContent">MLR Status</div>{" "}
                  <div className="content d-flex">
                    <img
                      src={RefreshIcon}
                      alt=""
                      className="img-fluid refreshIcondetails"
                      onClick={(e) =>
                        getLatestMLRStatus(recentlyViewedData?.id)
                      }
                    />
                    {bannerDetails?.reviewStatus === null ||
                    bannerDetails?.reviewStatus === ""
                      ? "Not submitted"
                      : bannerDetails?.reviewStatus}
                  </div>{" "}
                </div>
              </>
              <div className=" contentrow2" style={{ paddingTop: "10px" }}>
                <div className="d-flex contentrowcreatedby">
                  <div className="details-modalContent2">Created by</div>{" "}
                  <div className="content">{bannerDetails?.createdByName}</div>{" "}
                </div>
                <div className="d-flex contentrowcreatedby">
                  <div className="details-modalContent2  ">Created on</div>{" "}
                  <div className="content">
                    {showCreatedOntime(bannerDetails?.createdDateTime)}
                  </div>{" "}
                </div>
                <div className="d-flex contentrowcreatedby">
                  <div className="details-modalContent2">Last modified by</div>{" "}
                  <div className="content">{bannerDetails?.updatedByName}</div>{" "}
                </div>
                <div className="d-flex contentrowcreatedby">
                  <div className="details-modalContent2">Last modified on</div>{" "}
                  <div className="content">
                    {convertTimestamp(bannerDetails?.updatedDateTime)}
                  </div>{" "}
                </div>
              </div>
              {/* <div className="d-flex contentrow">
                <div className="details-modalContent">{t("Type")}</div>{" "}
                <div className="content">Static</div>{" "}
              </div> */}
            </div>{" "}
          </div>
          <div className="rightbox srctemplateInfo">
            <DialogTitle id="form-dialog-title">
              <div className="details-modalheading">
                {t("Other Information")}
              </div>
            </DialogTitle>

            <>
              <div
                className="d-flex contentrowcreatedby"
                style={{ paddingTop: "10px" }}
              >
                <div className="details-modalContent2 tmpDetails">
                  Banner Created
                </div>{" "}
                <div className="content contentTemplate">
                  {bannerDetails?.isBannerCreated === true ? "Yes" : "-"}{" "}
                </div>{" "}
              </div>
              <div className="d-flex contentrowcreatedby">
                <div className="details-modalContent2 tmpDetails">
                  No. of Banner's Created
                </div>{" "}
                <div className="content contentTemplate">
                  {bannerDetails?.bannerCount === null
                    ? "-"
                    : bannerDetails?.bannerCount}
                </div>{" "}
              </div>
            </>
          </div>
        </div>
        <DialogActions className="delete-actionss">
          <Button onClick={handleClose} color="primary" className="no-btn">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
