import React, { useRef, useEffect, useState } from "react";

import "./ElementAnimnPopUp.scss";
import { Divider } from "@material-ui/core";

import backArrow from "../../images/backArrow.svg";
import PopupWindow from "./PopupWindow";
import { useTranslation } from "react-i18next";
import { TextField, InputAdornment } from "@material-ui/core";

import "../../styles/components/RightPanel.scss";
import "../../styles/components/PopupWindow.scss";
import CloseIcon from "../../images/iconclose.svg";
import DeleteKeyFrame from "../../images/DeleteKeyFrame.svg";
import DeleteKeyFrameDisable from "../../images/DeleteKeyFrameDisable.svg";
import InfoIcon from "../../images/iconinfo.svg";
import Paste from "../../images/Paste.svg";
import pasteBlue from "../../images/BluePaste.svg";
import CopyKeyFrame from "../../images/CopyKeyFrame.svg";
import CopyKeyFrameDisable from "../../images/CopyKeyFrameDisable.svg";
import left from "../../images/leftPos.svg";
import top from "../../images/topPos.svg";
import rotation from "../../images/rotation.svg";
import Slider from "@material-ui/core/Slider";
import { toast } from "react-toastify";
import { Delete, SettingsInputHdmiOutlined } from "@material-ui/icons";
import { OutsideClickHandler } from "../../Uitls/Util";

function CustomanimationPopup({
  layerCompData,
  setLayerCompData,
  handleTimelinePopup,
  marker,
  markerTime,
  selectedComponent,
  setSelectedComponent,
  setPopUpOptions,
  popUpOptions,
  animationObject,
  setAnimationObject,
  copiedKeyFrame,
  setCopiedKeyFrame,
  setEdited,
  setundoFlag,
  popUpValuesEdited,
  setPopUpValuesEdited,
  openTimelinePopup,
  setOpenTimelinePopup,
  handleChangeTimelineProps,
  saveAnmObjWithedit,
  elementPopUp,
  setElementPopUp,
  addKeyFrames,
  setMarker,
  setSeconds,
  setMillSeconds,
  showTooltip,
  setShowTooltip,
  clickedClose,
  setClickedClose,
  keyFrameClicked,
  setKeyFrameClicked,
  updateKeyFrames,
  popUpWindowPosn,
  setPopWindowPosn,
  setCopyPropertySelected,
  copyPropertySelected,
  selectedProperties,
  isNewAnimationOpen,
  setIsNewAnimationOpen,
  selectedPropertiesIndex,
}) {
  const animationPopUpRef = useRef(null);
  const buttonRef = useRef(null);
  const { t } = useTranslation();
  //const easingOptions = ["NONE", "EASE IN", "EASE OUT"];
  const easingOptions = ["none", "power1.in", "power1.out", "power1.inOut"];
  const [hoverRotateIcon, setHoverRotateIcon] = useState(false);
  const [hoverScaleIcon, setHoverScaleIcon] = useState(false);
  const [hoverOpacityIcon, setHoverOpacityIcon] = useState(false);
  const [dimensionsWhenOPened, setDimensionsWhenOPened] = useState({});
  const dimensionWhenOPenedRef = React.useRef(dimensionsWhenOPened);

  const propertiesToUpdate = ["width", "height", "left", "top", "rotation"];

  propertiesToUpdate.forEach((property) => {
    if (!popUpOptions[property] && popUpOptions[property] !== 0) {
      popUpOptions[property] = 0;
    }
  });

  const onHoverRotateIcon = () => {
    setHoverRotateIcon(true);
  };
  const onHoverScaleIcon = () => {
    setHoverScaleIcon(true);
  };
  const onLeaveScaleIcon = () => {
    setHoverScaleIcon(false);
  };

  const onHoverOpcaityIcon = () => {
    setHoverOpacityIcon(true);
  };
  const onLeaveOpcaityIcon = () => {
    setHoverOpacityIcon(false);
  };

  const onLeaveRotateicon = () => {
    setHoverRotateIcon(false);
  };

  OutsideClickHandler(
    animationPopUpRef,
    (e) => {
      console.log("outsideclick handler");
      // setOpenTimelinePopup(false);
      setClickedClose(true);
      handleTimelinePopup(e, dimensionWhenOPenedRef.current);
      setEdited(false);
      setOpenTimelinePopup({
        popupWindow: false,
        fromUnRedo: false,
      });
      setIsNewAnimationOpen(false);
    },
    openTimelinePopup,
    selectedComponent.id
  );

  const chanageOpacityOnmouseUp = (e, newValue) => {
    let event1 = {
      target: {
        name: "opacity",
        value: newValue / 100,
      },
    };
    setPopUpValuesEdited(true);
    // saveAnmObjWithedit(event1);
  };
  const handleChangeOpacity = (event, newValue) => {
    // let popUpvalues = { ...popUpOptions };
    // let percentageValue = newValue * 100;
    // setPopUpOptions({ ...popUpvalues, clicked: true });
    let percentageValue = newValue * 100;
    setPopUpOptions((prevState) => ({
      ...prevState,
      opacity: percentageValue,
      clicked: true,
    }));
  };
  // const changeScaleOnMouseUp = (event, newValue) => {
  //   let event1 = {
  //     target: {
  //       name: "transform",
  //       value: newValue / 100,
  //     },
  //   };
  //   setPopUpValuesEdited(true);
  //   // saveAnmObjWithedit(event1);
  // };
  const changeScaleOnMouseUp = (event, newValue) => {
    let event1 = {
      target: {
        name: "transform",
        value: newValue / 10,
      },
    };
    setPopUpValuesEdited(true);
    // saveAnmObjWithedit(event1);
  };
  // const handleChangeScale = (event, newValue) => {
  //   let popUpvalues = { ...popUpOptions };
  //   popUpvalues.scale = newValue;
  //   setPopUpOptions({ ...popUpvalues });
  // };
  const handleChangeScale = (event, newValue) => {
    let percentageValue = newValue * 100;
    setPopUpOptions((prevState) => ({
      ...prevState,
      scale: percentageValue,
    }));
  };
  useEffect(() => {
    document.addEventListener("scroll", handleTimelinePopup, true);
    document
      .getElementById("marker")
      .addEventListener("mousedown", handleTimelinePopup, true);

    return () => {
      document.removeEventListener("scroll", handleTimelinePopup, true);
      document
        .getElementById("marker")
        .removeEventListener("mousedown", handleTimelinePopup, true);
    };
  }, []);

  useEffect(() => {
    setClickedClose(false);
    let tempSelectedComp = JSON.parse(JSON.stringify(selectedComponent));
    let tempPopUpOptions = {};
    let dimensions = {};
    if (
      keyFrameClicked === "plusCustomAnmn" ||
      keyFrameClicked === "Diamond+Icon"
    ) {
      tempPopUpOptions = {
        ...popUpOptions,
        left: selectedComponent?.componentStyles?.left,
        top: selectedComponent?.componentStyles?.top,
        width: selectedComponent?.componentStyles?.width,
        height: selectedComponent?.componentStyles?.height,
        ease: "NONE",
        opacity: 100,
        rotation: 0,
        scale: 100,
        end: selectedComponent?.componentStyles?.end,
        translateX: selectedComponent?.componentStyles?.translateX || 0,
        translateY: selectedComponent?.componentStyles?.translateY || 0,
        transformOriginX:
          selectedComponent?.componentStyles?.transformOriginX || 0,
        transformOriginY:
          selectedComponent?.componentStyles?.transformOriginY || 0,
        transformOriginZ:
          selectedComponent?.componentStyles?.transformOriginZ || 0,
      };
    } else {
      tempPopUpOptions = {
        ...popUpOptions,
        left: animationObject[selectedComponent.id]?.animation[keyFrameClicked]
          ?.x,
        top: animationObject[selectedComponent.id]?.animation[keyFrameClicked]
          ?.y,
        width:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.width,
        height:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.height,
        ease: animationObject[selectedComponent.id]?.animation[keyFrameClicked]
          ?.ease,
        opacity:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.opacity * 100,
        rotation:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.rotation,
        scale:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.transform * 100,
        end: animationObject[selectedComponent.id]?.animation[keyFrameClicked]
          ?.end,
        translateX:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.translateX,
        translateY:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.translateY,
        transformOriginX:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.transformOriginX,
        transformOriginY:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.transformOriginY,
        transformOriginZ:
          animationObject[selectedComponent.id]?.animation[keyFrameClicked]
            ?.transformOriginZ,
      };
    }
    dimensions = {
      left:
        tempPopUpOptions?.left || tempSelectedComp?.componentStyles?.left || 0,
      top: tempPopUpOptions?.top || tempSelectedComp?.componentStyles?.top || 0,
      width:
        tempPopUpOptions?.width ||
        tempSelectedComp?.componentStyles?.width ||
        0,
      height:
        tempPopUpOptions?.height ||
        tempSelectedComp?.componentStyles?.height ||
        0,
      translateX:
        tempPopUpOptions?.translateX ||
        tempSelectedComp?.componentStyles?.translateX ||
        0,
      translateY:
        tempPopUpOptions?.translateY ||
        tempSelectedComp?.componentStyles?.translateY ||
        0,
    };
    dimensionWhenOPenedRef.current = dimensions;
    setDimensionsWhenOPened(dimensions);
    setPopUpOptions(tempPopUpOptions);
  }, []);
  useEffect(() => {
    if (keyFrameClicked === "Diamond+Icon") {
      let left =
        popUpWindowPosn.left -
        document.getElementById("timeline-scale").getBoundingClientRect().left -
        document.getElementById("popUpWindowNew").getBoundingClientRect()
          .width *
          0.25;

      let top =
        popUpWindowPosn.top -
        (document.getElementById("timeline-scale").getBoundingClientRect().top +
          document.getElementById("popUpWindowNew").getBoundingClientRect()
            .height +
          15);
      // let left = boundingRectDetails.left - props.popUpWindowPosn.left;
      setPopWindowPosn({ left: left, top: top });
    } else if (typeof keyFrameClicked !== "string") {
      let left =
        popUpWindowPosn.left -
        document.getElementById("timeline-scale").getBoundingClientRect().left -
        document.getElementById("popUpWindowNew").getBoundingClientRect()
          .width *
          0.25;
      let top =
        popUpWindowPosn.top -
        (document.getElementById("timeline-scale").getBoundingClientRect().top +
          document.getElementById("popUpWindowNew").getBoundingClientRect()
            .height +
          15);
      setPopWindowPosn({ left: left, top: top });
    }
  }, []);

  const copyKeyFrames = () => {
    setCopyPropertySelected(true);
    setCopiedKeyFrame(
      animationObject[selectedProperties.id]?.animation[
        selectedPropertiesIndex.keyIndex
      ]
    );
    setClickedClose(true);
    handleTimelinePopup(dimensionsWhenOPened);
  };
  const pasteKeyFrame = () => {
    if (!selectedProperties.hasOwnProperty("id")) {
      toast.error(
        <div className="notificationText">
          {t("Please select the component")}
        </div>
      );
      return;
    }
    if (copiedKeyFrame === "") {
      toast.error(
        <div className="notificationText">{t("No Key frame is copied")}</div>
      );
      return;
    }
    let tempCopiedKeyFrame = { ...copiedKeyFrame };

    if (!isNewAnimationOpen) {
      let tempLayer = [...layerCompData];
      tempLayer[selectedPropertiesIndex.index].componentStyles.left =
        tempCopiedKeyFrame.x;
      tempLayer[selectedPropertiesIndex.index].componentStyles.top =
        tempCopiedKeyFrame.y;
      tempLayer[selectedPropertiesIndex.index].componentStyles.height =
        tempCopiedKeyFrame.height;
      tempLayer[selectedPropertiesIndex.index].componentStyles.width =
        tempCopiedKeyFrame.width;
      setLayerCompData(tempLayer);
    }

    setSelectedComponent((prevState) => ({
      ...prevState,
      componentStyles: {
        ...prevState.componentStyles,
        left: tempCopiedKeyFrame.x,
        top: tempCopiedKeyFrame.y,
        width: tempCopiedKeyFrame.width,
        height: tempCopiedKeyFrame.height,
        translateX: tempCopiedKeyFrame.translateX || 0,
        translateY: tempCopiedKeyFrame.translateY || 0,
      },
    }));

    let popUpvalues = {
      ...popUpOptions,
      left: tempCopiedKeyFrame.x,
      top: tempCopiedKeyFrame.y,
      width: tempCopiedKeyFrame.width,
      height: tempCopiedKeyFrame.height,
      ease: tempCopiedKeyFrame.ease,
      skewAnimation: tempCopiedKeyFrame.skewAnimation,
      opacity: Math.round(tempCopiedKeyFrame.opacity * 100),
      rotation: tempCopiedKeyFrame.rotation,
      scale: Math.round(tempCopiedKeyFrame.transform * 100),
      translateX: tempCopiedKeyFrame.translateX || 0,
      translateY: tempCopiedKeyFrame.translateY || 0,
      transformOriginX: tempCopiedKeyFrame.transformOriginX || 0,
      transformOriginY: tempCopiedKeyFrame.transformOriginY || 0,
      transformOriginZ: tempCopiedKeyFrame.transformOriginZ || 0,
    };
    setPopUpOptions(popUpvalues);
    setCopyPropertySelected(false);
    setClickedClose(true);
  };
  const deleteKeyFrame = () => {
    layerCompData[selectedPropertiesIndex.index].keyFrames.splice(
      selectedPropertiesIndex.keyIndex,
      1
    );
    animationObject[selectedProperties.id].animation.splice(
      selectedPropertiesIndex.keyIndex,
      1
    );
    handleTimelinePopup(true);
    setClickedClose(true);
    handleTimelinePopup(dimensionsWhenOPened);
  };

  const getPrevKeyFrameValues = () => {
    let popUpvalues = {};

    let selComp = animationObject[selectedComponent.id]?.animation?.filter(
      (elem) => {
        if (
          keyFrameClicked === "plusCustomAnmn" ||
          keyFrameClicked === "Diamond+Icon"
        )
          return elem;

        let n = selectedComponent?.keyFrames[keyFrameClicked] / 45;
        // openTimelinePopup.fromUnRedo
        //   ? selectedComponent?.animationApllied.marker / 45 :
        // markerTime / 45;
        let keyFrameRange =
          parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;
        if (
          keyFrameRange <= elem.end + 0.1 &&
          keyFrameRange >= elem.end - 0.1

          // elem.end >= parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10 &&
          // elem.end - 0.2 <=
          //   parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10
        )
          return elem;
      }
    );
    if (selComp?.length > 0) {
      popUpvalues = {
        ...popUpOptions,
        left: selComp[0].x,
        top: selComp[0].y,
        width: selComp[0].width,
        height: selComp[0].height,
        ease: selComp[0].ease,
        skewAnimation: selComp[0].skewAnimation,
        opacity: Math.round(selComp[0].opacity * 100),
        rotation: selComp[0].rotation,
        scale: Math.round(selComp[0].transform * 100),
      };
    } else if (selectedComponent.componentStyles) {
      popUpvalues = {
        ...popUpOptions,
        left: selectedComponent.componentStyles.left,
        top: selectedComponent.componentStyles.top,
        width: selectedComponent.componentStyles.width,
        height: selectedComponent.componentStyles.height,
        ease: "NONE",
        skewAnimation: false,
        opacity: 100,
        rotation: 0,
        scale: 100,
      };
    }
    setPopUpOptions(popUpvalues);
  };
  // useEffect(() => {
  //   // if (keyFrameClicked !== -1) getPrevKeyFrameValues();
  // }, [selectedComponent]);

  const handlEasingOps = (e) => {
    // let sendEaseOption =
    //   e.target.value === "EASE OUT"
    //     ? "Power1.easeOut"
    //     : e.target.value === "EASE IN"
    //     ? "Power1.easeIn"
    //     : "none";
    setPopUpOptions({
      ...popUpOptions,
      ease: e.target.value,
      // clicked: true,
    });
    let tempElemLayers = [...layerCompData];
    tempElemLayers.forEach((entry, index) => {
      if (entry.id === selectedComponent.id)
        tempElemLayers[index].componentStyles.ease = e.target.value;
    });
    setundoFlag(true);
    setLayerCompData(tempElemLayers);
    // saveAnmObjWithedit(e);
  };
  const toggleSkewCheck = (e) => {
    if (selectedComponent) {
      let tempSlected = { ...selectedComponent };
      tempSlected.componentStyles.skewAnimation = e.target.checked;
      setSelectedComponent(tempSlected);
    }
  };
  //  const handleTextFieldChangeScale = (event) => {
  //   let newValue = parseInt(event.target.value);
  //   newValue = Math.min(400, Math.max(0, newValue));
  //   setPopUpOptions((prevState) => ({
  //     ...prevState,
  //     scale: isNaN(newValue) ? 0 : newValue,
  //   }));
  // };
  const handleTextFieldChangeScale = (event) => {
    let inputValue = event.target.value;

    let xValue = Math.min(10, Math.max(0, inputValue));

    let percentageValue = xValue * 100;
    setPopUpOptions((prevState) => ({
      ...prevState,
      scale: isNaN(percentageValue) ? 100 : percentageValue,
    }));
  };
  // const handleTextFieldChangeOpacity = (event) => {
  //   let newValue = parseInt(event.target.value);
  //   newValue = Math.min(1, Math.max(0, newValue));
  //   setPopUpOptions((prevState) => ({
  //     ...prevState,
  //     opacity: isNaN(newValue) ? 0 : newValue,
  //   }));
  // };
  const handleTextFieldChangeOpacity = (event) => {
    let inputValue = parseFloat(event.target.value);
    //inputValue = Math.min(1, Math.max(0, inputValue));
    inputValue = isNaN(inputValue) ? 0 : Math.min(1, Math.max(0, inputValue));
    inputValue = parseFloat(inputValue.toFixed(1));
    let percentageValue = inputValue * 100;
    setPopUpOptions((prevState) => ({
      ...prevState,
      opacity: percentageValue,
    }));
  };
  const handleKeyDown = (property) => (e) => {
    if (
      [property] == "width" ||
      [property] == "height" ||
      [property] == "top" ||
      [property] == "left" ||
      [property] == "translateX" ||
      [property] == "translateY" ||
      [property] == "transformOriginZ"
    ) {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setPopUpOptions((prevState) => ({
          ...prevState,
          [property]:
            prevState[property] == 9999
              ? prevState[property]
              : ++prevState[property],
        }));
      } else if (e.key === "ArrowDown") {
        if (
          property == "top" ||
          property == "left" ||
          property == "translateX" ||
          property == "translateY" ||
          [property] == "transformOriginZ"
        ) {
          setPopUpOptions((prevState) => ({
            ...prevState,
            [property]:
              prevState[property] == -9999
                ? prevState[property]
                : prevState[property] - 1,
          }));
        } else {
          setPopUpOptions((prevState) => ({
            ...prevState,
            [property]:
              prevState[property] > 0
                ? prevState[property] - 1
                : prevState[property],
          }));
        }
      }
    } else if ([property] == "opacity") {
      // if (e.key === "ArrowUp") {
      //   e.preventDefault();
      //   setPopUpOptions((prevState) => ({
      //     ...prevState,
      //     [property]:
      //       prevState[property] == 100
      //         ? prevState[property]
      //         : ++prevState[property],
      //   }));
      // } else if (e.key === "ArrowDown") {
      //   setPopUpOptions((prevState) => ({
      //     ...prevState,
      //     [property]: prevState[property]
      //       ? prevState[property] - 1
      //       : prevState[property],
      //   }));
      // }
      // const isInputFocused = ["INPUT", "TEXTAREA"].includes(e.target.tagName);
      // if (isInputFocused) return;
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
        const increment =
          e.key === "ArrowUp" ? 0.1 : e.key === "ArrowDown" ? -0.1 : 0;

        if (increment === 0) return;

        setPopUpOptions((prevState) => {
          let currentValue = prevState[property] / 100;
          let newValue = parseFloat((currentValue + increment).toFixed(1));
          newValue = Math.max(0, Math.min(1, newValue));
          let percentageValue = newValue * 100;
          return {
            ...prevState,
            [property]: percentageValue,
          };
        });
      }
    } else if ([property] == "scale") {
      //   if (e.key === "ArrowUp") {
      //     e.preventDefault();
      //     debugger;
      //     setPopUpOptions((prevState) => ({
      //       ...prevState,
      //       [property]:
      //         prevState[property] === 100
      //           ? prevState[property]
      //           : ++prevState[property],
      //     }));
      //   }
      // } else if (e.key === "ArrowDown") {
      //   setPopUpOptions((prevState) => ({
      //     ...prevState,
      //     [property]: prevState[property]
      //       ? prevState[property] - 1
      //       : prevState[property],
      //   }));
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
        const increment = e.key === "ArrowUp" ? 1 : -1;
        if (increment === 0) return;
        setPopUpOptions((prevState) => {
          let currentValue = prevState[property] / 100;
          let newValue = currentValue + increment;
          newValue = Math.min(10, Math.max(0, newValue));
          let percentageValue = newValue * 100;
          return {
            ...prevState,
            [property]: percentageValue,
          };
        });
      }
    } else if ([property] == "rotation") {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setPopUpOptions((prevState) => ({
          ...prevState,
          [property]:
            prevState[property] == 360
              ? prevState[property]
              : ++prevState[property],
        }));
      } else if (e.key === "ArrowDown") {
        setPopUpOptions((prevState) => ({
          ...prevState,
          [property]:
            prevState[property] > -360
              ? prevState[property] - 1
              : prevState[property],
        }));
      }
    } else if (
      property === "transformOriginX" ||
      property === "transformOriginY"
    ) {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setPopUpOptions((prevState) => ({
          ...prevState,
          [property]: (prevState[property] || 0) + 1,
          //  [property]: Math.min(100, prevState[property] + 1),
        }));
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setPopUpOptions((prevState) => ({
          ...prevState,
          [property]: (prevState[property] || 0) - 1,
          //[property]: Math.max(0, prevState[property] - 1),
        }));
      }
    }
  };
  return (
    <div
      ref={animationPopUpRef}
      className={"box-container"}
      style={{
        left: popUpWindowPosn.left,
        top: popUpWindowPosn.top,
      }}
      id="popUpWindowNew"
      // style={{ height: "300px" }}
    >
      <div className="box">
        <div className="boxContent">
          <div className="lhsPart">
            {!selectedComponent?.keyFrames?.length && (
              <img
                src={backArrow}
                onClick={(e) => {
                  setClickedClose(true);
                  handleTimelinePopup(e, dimensionsWhenOPened);
                  if (!selectedComponent?.keyFrames?.length)
                    setElementPopUp(true);
                }}
                className="backToElemAnmPopUp"
              />
            )}
            <div className="elementAnimationText">Custom Animation</div>
          </div>

          <div className="rhsPart">
            <div className="copyDelete">
              {copyPropertySelected ? (
                <img
                  src={pasteBlue}
                  onClick={() => {
                    pasteKeyFrame();
                  }}
                  alt=""
                />
              ) : (
                <img src={Paste} alt="" />
              )}
              {isNewAnimationOpen ? (
                <img src={CopyKeyFrameDisable} alt="" />
              ) : (
                <img
                  onClick={() => {
                    copyKeyFrames();
                  }}
                  src={CopyKeyFrame}
                  alt=""
                />
              )}
              {isNewAnimationOpen ? (
                <img src={DeleteKeyFrameDisable} alt="" />
              ) : (
                <img
                  onClick={() => {
                    deleteKeyFrame();
                  }}
                  src={DeleteKeyFrame}
                  alt=""
                />
              )}

              <img
                src={CloseIcon}
                onClick={(e) => {
                  setClickedClose(true);
                  handleTimelinePopup(e, dimensionsWhenOPened);
                  setIsNewAnimationOpen(false);
                }}
                alt="copy"
                className="img-fluid closeIconn"
              />
            </div>
          </div>
        </div>
        <Divider className="dividerClass" />
        <div className="inputFields">
          <div className="dimen">
            <div className="sizePos">
              <div className="xywh">
                <div clasName="sp">W</div>
                <TextField
                  disabled={selectedComponent?.componentStyles?.lockBoolean}
                  className="textFieldSP"
                  id="width"
                  value={Math.round(popUpOptions.width)}
                  name="width"
                  size="small"
                  onKeyDown={handleKeyDown("width")}
                  autoComplete="off"
                  inputProps={{
                    style: {
                      marginTop: "-20px",

                      marginBottom: "-15px",

                      fontSize: "11px",
                      // color: "#928989",
                    },
                  }}
                  onChange={(e) => handleChangeTimelineProps(e)}
                  onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                />
              </div>
              <div className="xywh">
                <div clasName="sp">
                  <img src={left} />
                </div>
                <TextField
                  // element={TextField}

                  // debounceTimeout={500}

                  disabled={selectedComponent?.componentStyles?.lockBoolean}
                  className="textFieldSP textFieldLTR"
                  id="xCoord"
                  value={
                    popUpOptions.left === "-"
                      ? "-"
                      : Math.round(Number(popUpOptions.left))
                  }
                  name="left"
                  size="small"
                  autoComplete="off"
                  onKeyDown={handleKeyDown("left")}
                  inputProps={{
                    style: {
                      marginTop: "-20px",

                      marginBottom: "-15px",

                      fontSize: "11px",

                      // color: "#928989",
                    },
                  }}
                  onChange={(e) => handleChangeTimelineProps(e)}
                  onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                />
              </div>
            </div>
            <div className="sizePos">
              <div className="xywh" style={{ marginLeft: "5px" }}>
                <div className="sp">H</div>
                <TextField
                  disabled={selectedComponent?.componentStyles?.lockBoolean}
                  className="textFieldSP"
                  id="height"
                  value={Math.round(popUpOptions.height)}
                  name="height"
                  size="small"
                  autoComplete="off"
                  onKeyDown={handleKeyDown("height")}
                  inputProps={{
                    style: {
                      marginTop: "-20px",
                      marginBottom: "-15px",
                      fontSize: "11px",
                    },
                  }}
                  onChange={(e) => handleChangeTimelineProps(e)}
                  onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                />
              </div>
              <div className="xywh">
                <div className="xywh">
                  <div className="sp">
                    <img src={top} />
                  </div>
                  <TextField
                    // element={TextField}

                    // debounceTimeout={500}

                    disabled={selectedComponent?.componentStyles?.lockBoolean}
                    className="textFieldSP textFieldLTR"
                    id="top"
                    value={
                      popUpOptions.top === "-"
                        ? "-"
                        : Math.round(Number(popUpOptions.top))
                    }
                    name="top"
                    size="small"
                    autoComplete="off"
                    onKeyDown={handleKeyDown("top")}
                    inputProps={{
                      style: {
                        marginTop: "-20px",
                        marginBottom: "-15px",
                        fontSize: "11px",
                      },
                    }}
                    onChange={(e) => handleChangeTimelineProps(e)}
                    onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                  />
                </div>
                <div className="xywh" style={{ marginLeft: "33.5px" }}>
                  <div className="sp">
                    <img src={rotation} />
                  </div>
                  <TextField
                    className="textFieldSP textFieldLTR"
                    id="rotation"
                    value={popUpOptions.rotation}
                    name="rotation"
                    size="small"
                    autoComplete="off"
                    onKeyDown={handleKeyDown("rotation")}
                    inputProps={{
                      style: {
                        marginTop: "-20px",
                        marginBottom: "-15px",
                        fontSize: "11px",
                      },
                    }}
                    onChange={(e) => handleChangeTimelineProps(e)}
                    onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span
                            style={{
                              position: "absolute",
                              color: "black",
                              fontSize: "11px",
                              right: `${(() => {
                                const length =
                                  popUpOptions.rotation.toString().length;
                                const position =
                                  length === 4
                                    ? 16
                                    : length === 3
                                    ? 20
                                    : length === 2
                                    ? 26.5
                                    : 32.5;
                                return `${position}px`;
                              })()}`,
                              bottom: "3.5px",
                            }}
                          >
                            &deg;
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="animationEffects">
            <div className="easing">
              <div className="anmnEffProps">{t("timelineMenu.easing")}</div>
              <select
                name="ease"
                className="easingDropDn"
                onChange={(e) => handlEasingOps(e)}
                value={popUpOptions.ease}
              >
                {easingOptions.map((easingOption, id) => {
                  return (
                    <option key={id} value={easingOption}>
                      {easingOption === "power1.in"
                        ? "Ease in"
                        : easingOption === "power1.out"
                        ? "Ease out"
                        : easingOption === "power1.inOut"
                        ? "Ease in out"
                        : "None"}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="easing">
              <div className="anmnEffProps">{t("timelineMenu.opacity")}</div>
              <div className="opacityRHS">
                <TextField
                  disabled={selectedComponent?.componentStyles?.lockBoolean}
                  className="textFieldSP textFieldOS"
                  id="height"
                  value={popUpOptions.opacity / 100}
                  name="height"
                  size="small"
                  autoComplete="off"
                  onKeyDown={handleKeyDown("opacity")}
                  inputProps={{
                    style: {
                      marginTop: "-20px",
                      marginBottom: "-15px",
                      fontSize: "11px",
                    },
                    type: "number",
                    step: "0.1",
                    min: "0",
                    max: "1",
                    pattern: "\\d*\\.?\\d{0,1}",
                  }}
                  onChange={handleTextFieldChangeOpacity}
                  // onChange={(e) => handleChangeTimelineProps(e)}
                  // onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                />
                <div className="slider">
                  {/* <Slider
                    value={
                      isNaN(popUpOptions.opacity)
                        ? 0
                        : Math.round(popUpOptions.opacity)
                    }
                    onChange={handleChangeOpacity}
                    onChangeCommitted={chanageOpacityOnmouseUp}
                    aria-labelledby="continuous-slider"
                    defaultValue={10}
                  /> */}
                  <Slider
                    value={popUpOptions.opacity / 100}
                    onChange={handleChangeOpacity}
                    onChangeCommitted={chanageOpacityOnmouseUp}
                    aria-labelledby="continuous-slider"
                    min={0}
                    max={1}
                    step={0.1}
                  />
                </div>
              </div>
            </div>
            <div className="easing">
              <div className="anmnEffProps">{t("timelineMenu.scale")}</div>
              <div className="opacityRHS">
                <TextField
                  disabled={selectedComponent?.componentStyles?.lockBoolean}
                  className="textFieldSP textFieldOS"
                  id="height"
                  //value={Math.round(popUpOptions.scale)}
                  value={Math.round(popUpOptions.scale / 100)}
                  name="height"
                  size="small"
                  autoComplete="off"
                  onKeyDown={handleKeyDown("scale")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          style={{
                            position: "absolute",
                            color: "black",
                            fontSize: "11px",
                            right: `${(() => {
                              const value = Math.round(
                                popUpOptions.scale / 100
                              ).toString();
                              const length = value.length;
                              const position = 28;
                              return `${position}px`;
                            })()}`,
                            bottom: "3.5px",
                          }}
                        >
                          X
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      marginTop: "-20px",
                      marginBottom: "-15px",
                      fontSize: "11px",
                    },
                  }}
                  onChange={handleTextFieldChangeScale}
                  // onChange={(e) => handleChangeTimelineProps(e)}
                  // onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                />
                <div className="slider">
                  {/* <Slider
                    value={
                      isNaN(popUpOptions.scale)
                        ? 0
                        : Math.round(popUpOptions.scale)
                    }
                    onChange={handleChangeScale}
                    onChangeCommitted={changeScaleOnMouseUp}
                    aria-labelledby="continuous-slider"
                    defaultValue={10}
                    min={0}
                    max={400}
                    step={10}
                  /> */}
                  <Slider
                    value={
                      isNaN(popUpOptions.scale)
                        ? 100
                        : Math.round(popUpOptions.scale / 100)
                    }
                    onChange={handleChangeScale}
                    onChangeCommitted={changeScaleOnMouseUp}
                    aria-labelledby="continuous-slider"
                    defaultValue={1}
                    min={1}
                    max={10}
                    step={1}
                  />
                </div>
              </div>
            </div>
            <div className="easing" style={{ marginBottom: "20px" }}>
              <div className="anmnEffProps" style={{ paddingRight: "30px" }}>
                Translation
              </div>
              <div></div>
              <div className="tr translat">X</div>
              <TextField
                // element={TextField}

                // debounceTimeout={500}

                disabled={selectedComponent?.componentStyles?.lockBoolean}
                className="textFieldSP textFieldtransXY xAndY"
                style={{ marginLeft: "20px" }}
                id="xCoord"
                value={popUpOptions.translateX}
                name="translateX"
                size="small"
                autoComplete="off"
                // onKeyDown={handleKeyDown("left")}
                inputProps={{
                  style: {
                    marginTop: "-20px",

                    marginBottom: "-15px",

                    fontSize: "11px",

                    // color: "#928989",
                  },
                }}
                onChange={(e) => handleChangeTimelineProps(e)}
                onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                onKeyDown={handleKeyDown("translateX")}
              />
              <div
                className="tr "
                style={{
                  marginRight: "20px",
                  marginLeft: "-40px",
                  position: "relative",
                  right: "30px",
                }}
              >
                Y
              </div>
              <TextField
                // element={TextField}

                // debounceTimeout={500}

                disabled={selectedComponent?.componentStyles?.lockBoolean}
                className="textFieldSP textFieldtransXY xAndY"
                style={{
                  marginRight: "0",
                  position: "relative",
                  right: "27px",
                }}
                id="xCoord"
                value={popUpOptions.translateY}
                name="translateY"
                size="small"
                autoComplete="off"
                // onKeyDown={handleKeyDown("left")}
                inputProps={{
                  style: {
                    marginTop: "-20px",

                    marginBottom: "-15px",

                    fontSize: "11px",

                    // color: "#928989",
                  },
                }}
                onChange={(e) => handleChangeTimelineProps(e)}
                onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                onKeyDown={handleKeyDown("translateY")}
              />
            </div>
            <div className="easing" style={{ marginBottom: "20px" }}>
              <div className="anmnEffProps">Transform Origin</div>
              <div className="tr">X</div>
              <TextField
                // element={TextField}

                // debounceTimeout={500}

                disabled={selectedComponent?.componentStyles?.lockBoolean}
                className="textFieldSP textFieldtransXY"
                style={{ marginLeft: "30px" }}
                id="xCoord"
                value={popUpOptions.transformOriginX}
                name="transformOriginX"
                size="small"
                autoComplete="off"
                // onKeyDown={handleKeyDown("left")}

                InputProps={{
                  style: {
                    marginTop: "-5px",
                    height: "20px",
                    //marginBottom: "-15px",
                    fontSize: "11px",
                    // color: "#928989",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        style={{
                          color: " #c5c5c5",
                          fontSize: "11px",
                          bottom: "3.5px",
                        }}
                      >
                        %
                      </span>
                    </InputAdornment>
                  ),
                }} //
                onChange={(e) => handleChangeTimelineProps(e)}
                onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                onKeyDown={handleKeyDown("transformOriginX")}
              />
              <div
                className="tr"
                style={{
                  marginRight: "30px",
                  marginLeft: "30px",
                }}
              >
                Y
              </div>
              <TextField
                // element={TextField}

                // debounceTimeout={500}

                disabled={selectedComponent?.componentStyles?.lockBoolean}
                className="textFieldSP textFieldtransXY"
                style={{ marginRight: "0" }}
                id="xCoord"
                value={popUpOptions.transformOriginY}
                name="transformOriginY"
                size="small"
                autoComplete="off"
                // onKeyDown={handleKeyDown("left")}
                InputProps={{
                  style: {
                    marginTop: "-6px",
                    height: "20px",
                    //marginBottom: "-15px",
                    fontSize: "11px",

                    // color: "#928989",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        style={{
                          color: " #c5c5c5",
                          fontSize: "11px",
                          bottom: "3.5px",
                        }}
                      >
                        %
                      </span>
                    </InputAdornment>
                  ),
                }} //
                onChange={(e) => handleChangeTimelineProps(e)}
                onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                onKeyDown={handleKeyDown("transformOriginY")}
              />
              <div
                className="tr"
                style={{ marginRight: "30px", marginLeft: "30px" }}
              >
                Z
              </div>
              <TextField
                // element={TextField}

                // debounceTimeout={500}

                disabled={selectedComponent?.componentStyles?.lockBoolean}
                className="textFieldSP textFieldtransXY"
                style={{ marginRight: "0" }}
                id="xCoord"
                value={popUpOptions.transformOriginZ}
                name="transformOriginZ"
                size="small"
                autoComplete="off"
                // onKeyDown={handleKeyDown("left")}
                InputProps={{
                  style: {
                    marginTop: "-6px",
                    height: "20px",
                    //marginBottom: "-15px",
                    fontSize: "11px",
                    // color: "#928989",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        style={{
                          color: " #c5c5c5",
                          fontSize: "11px",
                          bottom: "3.5px",
                        }}
                      >
                        px
                      </span>
                    </InputAdornment>
                  ),
                }} //
                onChange={(e) => handleChangeTimelineProps(e)}
                onBlur={(e) => handleChangeTimelineProps(e, "blur")}
                onKeyDown={handleKeyDown("transformOriginZ")}
              />
            </div>
          </div>
          <button
            className="applyButton"
            ref={buttonRef}
            onClick={() => {
              console.log("Button clicked!");
              let x = "diamondIcon";
              if (!selectedComponent?.keyFrames?.length) {
                x = "powerIcon";
                setShowTooltip(true);
                setTimeout(() => {
                  setShowTooltip(false);
                }, 2000);
              }
              keyFrameClicked > -1 ? updateKeyFrames(x) : addKeyFrames(x);
              setOpenTimelinePopup({
                popupWindow: false,
                fromUnRedo: false,
              });
              setIsNewAnimationOpen(false);
            }}
          >
            {`${keyFrameClicked > -1 ? "UPDATE" : "APPLY"}`}
          </button>
        </div>
      </div>
      <div className="arrow-down"></div>
    </div>
  );
}

export default CustomanimationPopup;
