import React, { useRef,useEffect,useState } from 'react';
import './ElementAnimnPopUp.scss';


const CopyPasteContextMenu = ({ isVisible, position, handleOutsideClick,setCopyPasteContextMenuVisibility,copyKeyFrames,pasteKeyFrame,deleteKeyFrame,copyPropertySelected }) => {
  const contextMenuRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
        handleOutsideClick();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleOutsideClick]);




  const handleItemClick = (action) => {
    if (action === 'copy') {
       copyKeyFrames();
    } else if (action === 'paste') {
      pasteKeyFrame();
    }
    else if (action =='delete'){
      deleteKeyFrame();
    }
    setCopyPasteContextMenuVisibility(false);
  };

  return (
    <div className={"context-menu-container"}
    style={{ left: `${position.x+2}px`, top: `${position.y - 103}px` }}>
      {isVisible && (
        <>
        <div ref={contextMenuRef} className="context-menu">
          <div onClick={() => handleItemClick('delete')}>Delete key frame</div>
          <div onClick={() => handleItemClick('copy')}>Copy properties</div>
          <div className={!copyPropertySelected ? "inactive" : ""} onClick={() => handleItemClick('paste')}>Paste properties</div>

        </div>
      </>
      )}
    </div>
  );
};

export default CopyPasteContextMenu;
