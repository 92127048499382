import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Card, Typography, CardContent, withStyles } from "@material-ui/core";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { CardStyles } from "../../styles/pages/ContentStudio/WithStylesObjs";
const CardsForLeftContainer = React.memo((props) => {
  const { t } = useTranslation();

  const { classes, linkedModulesType } = props;

  let catValue = "";
  let claimValue = "";
  if (props.data.hasOwnProperty("coreCategories")) {
    catValue =
      props?.data?.coreCategories?.length > 0
        ? props?.data?.coreCategories[0]
        : props?.data?.moduleCoreCategories?.length > 0
        ? props?.data?.moduleCoreCategories[0]?.coreCategory
        : "";
    if (
      props?.data.hasOwnProperty("moduleClaims") &&
      props?.data.moduleClaims.length > 0
    )
      claimValue = props?.data?.claim || props?.data?.moduleClaims[0]?.claim;
    claimValue = props?.data?.claim;
  }

  //  claimValue =props?.data?.claim||props?.data?.moduleClaims[0]?.claim
  return (
    <div className={`${classes.leftCardStyle} leftModuleCardContainer`}>
      <Card variant="outlined">
        <CardContent>
          {/* {linkedModulesType === "nextModule" && (
            <Typography className="module-code mb-3">
              {props?.data?.moduleCode}
            </Typography>
          )} */}
          <Typography color="textSecondary" className="camp-Name module-Name">
            {t("modulesLeftContainer.moduleName")}
          </Typography>

          <p className="primary-claim boldClass">
            {" "}
            {linkedModulesType === "nextModule"
              ? props.data.name
              : props.data.moduleName}
          </p>

          <Typography className="buttonAndArrow">
            <div className="cardsYellowbutton">
              {linkedModulesType === "nextModule"
                ? catValue
                : props.data.coreCategory}
            </div>
          </Typography>
          <button
            className="bluearrowDiv"
            onClick={() => props.expandClaims(props.data.id)}
          >
            <ArrowForwardIosIcon className="cardBlueArrow" />
          </button>
          <Typography
            color="textSecondary"
            className="camp-Name module-Name"
            style={{ paddingTop: "7px " }}
          >
            {t("modulesLeftContainer.primaryClaim")}
          </Typography>
          <div className="primary-claim claim">
            {linkedModulesType === "nextModule"
              ? claimValue && parse(claimValue)
              : props?.data?.claim}
          </div>
        </CardContent>
      </Card>
    </div>
  );
});

export default withStyles(CardStyles)(CardsForLeftContainer);
