import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import { useTranslation } from "react-i18next";
import StyledList from '../../Pages/pwdInd.styles'

const PasswordStrengthIndicator = ({
  validity: { minChar, number, specialChar, upperCase },
}) => {
  const { t } = useTranslation();
  return (
    <>
    <StyledList>
      <h1 className="forgotTitle mb-3">
        {t("login.resetPassword.passIndicatorLabel")}
      </h1>
      <ul className="pwdIndicatorList" id='pwd'>
        <PasswordStrengthIndicatorItem
          id='minChar'
          isValid={minChar}
          text={t("login.resetPassword.sixCharErr")}
        />
        <PasswordStrengthIndicatorItem
          id='number'
          isValid={number}
          text={t("login.resetPassword.alphanumeric")}
        />
        <PasswordStrengthIndicatorItem
          id='specialChar'
          isValid={specialChar}
          text={t("login.resetPassword.specialCharErr")}
        />
        <PasswordStrengthIndicatorItem
          id='upperCase'
          isValid={upperCase}
          text={t("login.resetPassword.capCharErr")}
        />
      </ul>
      </StyledList>
    </>
  );
};

const PasswordStrengthIndicatorItem = ({ id,isValid, text }) => {
  const highlightClass = isValid ? "active" : isValid !== null ? "normal" : "";
  return (
    <li className={highlightClass} id={id}>
      {" "}
      <ListItemIcon>
        <CheckIcon id='checkIcon'/>
      </ListItemIcon>
      {text}
    </li>
  );
};

export default PasswordStrengthIndicator;
