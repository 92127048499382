import React from "react";

import "../../styles/components/ToolBarLeftPanel.scss";
import { useTranslation } from "react-i18next";

import ClearText from "../../images/ClearText.svg";

import warning from "../../images/warning.svg";

import {
  BUTTON,
  BLUEBUTTONWITHRAD,
  WHITEBUTTON,
  WHITEBUTTONWITHRAD,
  BUTTONWITHOUTBORDER,
} from "../../Uitls/ComponentType";
import Button from "../../images/Buttons/Button.svg";
import BlueButtonWithRad from "../../images/Buttons/BlueButtonWithRad.svg";
import WhiteButton from "../../images/Buttons/WhiteButton.svg";
import ButtonWithoutBorder from "../../images/Buttons/ButtonWithoutBorder.svg";
import WhiteButtonWithRad from "../../images/Buttons/WhiteButtonWithRad.svg";

const ButtonWindow = (props) => {
  const { handleCloseElement, getLayerDetails } = props;
  const { t } = useTranslation();

  const buttonTypes = [
    { name: "Button", id: BUTTON, image: Button },
    { name: "WhiteButton", id: WHITEBUTTON, image: WhiteButton },
    {
      name: "BlueButtonWithRad",
      id: BLUEBUTTONWITHRAD,
      image: BlueButtonWithRad,
    },
    {
      name: "WhiteButtonWithRad",
      id: WHITEBUTTONWITHRAD,
      image: WhiteButtonWithRad,
    },
    {
      name: "ButtonWithoutBorder",
      id: BUTTONWITHOUTBORDER,
      image: ButtonWithoutBorder,
    },
  ];

  return (
    <div className="parentContainerShapes">
      <div className="shapesContainer">
        <div className="headingrow">
          <div>
            <span>{t("BUTTONS")}</span>
            {/* <img alt="" src={warning} className="img-fluid warningIcon" /> */}
          </div>
          <img
            src={ClearText}
            alt=""
            className="clearTextIcon clearText"
            onClick={() => handleCloseElement("Button")}
          />
        </div>
        <div className="shapes">
          {buttonTypes.map((button) => (
            <div className="shapesHolder">
              <div className="buttonMargin">
                <img
                  src={button.image}
                  alt={button.name}
                  className="img-fluid"
                  draggable={true}
                  onDragStart={(e) => {
                    getLayerDetails(e.target.id, button.id);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ButtonWindow;
