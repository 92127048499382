import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import "../styles/components/PasteContextMenu.scss";

const PasteContextMenuZindex = ({
  outerRefPaste,
  selectedComponent,
  layerCompData,
  showPaste,
  setShowPaste,
  pasteComponentOnCanvas,
  setVisibility,
  mm,
  imageOnCanvas,
}) => {
  const { t } = useTranslation();
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const parentPaste = outerRefPaste.current;
    if (!parentPaste) {
      return;
    }

    const showMenu = (event) => {
      event.preventDefault();
      if (layerCompData.length !== 0) {
        setShowPaste(true);
        setX(event.clientX);
        setY(event.clientY);
        setVisibility(false);
      }
    };

    const closeMenu = () => {
      setShowPaste(false);
    };

    parentPaste.addEventListener("contextmenu", showMenu);
    window.addEventListener("click", closeMenu);

    return function cleanup() {
      parentPaste.removeEventListener("contextmenu", showMenu);
      window.removeEventListener("click", closeMenu);
    };
  });
  window.onscroll = function (e) {
    setShowPaste(false);
  };

  const style = {
    top: y,
    left: x,
  };

  return showPaste ? (
    <div>
      <div className="contextMenupaste" style={style}>
        <div
          className="contextMenuTextpaste"
          onClick={() => pasteComponentOnCanvas(mm, imageOnCanvas)}
        >
          {t("contextMenuZindex.paste")}
        </div>
      </div>
    </div>
  ) : null;
};

export default PasteContextMenuZindex;
