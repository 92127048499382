export default function RVCV({
  canvasDimension,
  finalDOMObj,
  animationFinalObjToString,
  layerLink,
  completeObj,
  typeDiff,
}) {
  console.log("completeObj", completeObj);
  return `
  <html>
     <head>
        <meta charset="utf-8">
        ${layerLink}
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="format-detection" content="telephone=no" />
        <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="ad.size" content="width=${canvasDimension?.width},height=${canvasDimension?.height}"/>
        <script src="https://s0.2mdn.net/ads/studio/Enabler.js"></script>
        <script type="text/javascript" src="https://code.jquery.com/jquery-latest.js" ></script>
        <script  type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.20.3/TweenMax.min.js"></script>
        <script type="text/javascript" src="https://baijs.com/tinyscrollbar/js/jquery.tinyscrollbar.js" ></script>
        <style>
           * { 
           -moz-box-sizing: border-box; 
           -webkit-box-sizing: border-box; 
           box-sizing: border-box; 
           } 
           body{
           padding:0;
           margin:0;
           }
           ${finalDOMObj.CssCollect} 
           .customScrollBar {
           padding-bottom:50px;
           }
           .customScrollBar::-webkit-scrollbar {
           background-color: $imc-white-color;
           }
           p{
           margin: 0 0 0px !important;
           }
           .customScrollBar::-webkit-scrollbar {
           width: 6px;
           height: 6px;
           }
           .customScrollBar::-webkit-scrollbar-thumb {
           border-radius: 5px;
           box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
           -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
           background: rgba(0, 0, 0, 0.2);
           }
           .custonScrollBar::-webkit-scrollbar {
           background-color: $imc-white-color;
           }
           .disable .thumb {
           display:none;
           }
        </style>
     </head>
     <body class="customScrollBar">
        ${typeDiff}
        <script>
           var arrObject=${animationFinalObjToString}
           
                    var fps =60
                    var tempGpsa = [];
                    var count = 0;
                    var clearTimer = [];
                    var MaxDuration = [];
                    var collectID = [];
                    let collectscreenShotImages="";
                    var loopAnimation =1
                    var set = true;
                    let maxIndex = -1;
                    let totalDuration = 0;
                    let completeObj = ${completeObj};

                    function scrollBarCheck(){
                      for (let i=0; i <completeObj.length; i++) {
                        scrollBarScreenShot(completeObj[i].id);
                        } 
                    }
                    function enableTxt(item) {
                      var urltestGlobal= $('#canvas-page').data("global");
                      var urltextGlobalCheck=$('#canvas-page').data("link");
                      if(urltextGlobalCheck && urltextGlobalCheck !=="undefined"){
                        window.open(urltestGlobal)
                      }else{
                        var urltest = $(item).data("url");
                        if(urltest!==undefined && urltest !=="https://" ){
                        window.open(urltest);
                        }else{
                          return;
                        }
                      }
                    }
                    
                    function getElements(elem) {
                      let element = [];
                      for (let i = 0; i < elem.length; i++) {
                        element.push(document.getElementById(elem[i]));
                        //console.log("in Test");
                      }
                      return element;
                    }
                    
                    function animationRepeat(AryObj) {
                      debugger
                      var masterTimeLine = new TimelineMax({ repeat:0});
                          gsap.ticker.fps(fps);
                         
                          if (loopAnimation && set===true){
                            Array.prototype.max = function() {
                              return Math.max.apply(null, this);
                            };
                            for (let j = 0; j < AryObj.length; j++) {
                              let obj = AryObj[j];
                              MaxDuration.push((obj.duration||0)+obj.op.delay);
                            }
                          
                            const max = MaxDuration.max();
                            maxIndex = MaxDuration.indexOf(max);          
                                }
                    let i = 0;
                
                      for ( i = 0; i < AryObj.length; i++) {
                        var obj = AryObj[i];
                        var funcName = obj.api;
                        let value = getElements(obj.atoms);
                        if (value[0]) {
                          let  createGPSA = gsap.timeline();
                          if (maxIndex=== i && set===true){
                               let newObj = obj.op;
                              newObj.onComplete = function(){
                                  setTimeout(loopClear,100);
                               }
                   
                               let timeMax = createGPSA.to([value], (obj.duration||0), newObj); 
                   //	console.log(timeMax,"timeMax")
                       totalDuration= timeMax;
                       tempGpsa.push(timeMax); 
                                 set = false;
                          }else{
                               
                             tempGpsa.push(createGPSA.to([value],( obj.duration||0), obj.op));
                             MaxDuration.push(createGPSA.totalDuration())
                          }
                           //  console.log(value[0],"value0000")
                             collectID.push("#"+obj.atoms)
                          }
                      }
           
                      screenshot(Math.round(MaxDuration[maxIndex]));
                    }
                    function loopClear(){
                     
                    }
                    
                  
                 async function screenshot(maxSeconds) {
                      const numScreenshots = Math.floor(maxSeconds);                
                      for (let i = 0; i <= numScreenshots; i++) {                        
                          await new Promise(resolve => setTimeout(resolve, i * 1000));

                          gsap.globalTimeline.play();
                          await new Promise(resolve => setTimeout(resolve, 0));
                          await takeshot(); 
                          gsap.globalTimeline.pause();
                          if (completeObj.length > 0) {
                              scrollBarCheck();
                          } else if (i === numScreenshots) {
                              await new Promise(resolve => setTimeout(resolve, 10));
                              window.status = "ready";
                          }
                      }
                  }
                    async function  takeshot() {
                         await window.md5();
                         
                    }
           
                    async function scrollBarScreenShot(id) {
                      let  currContentTop= 0;      
                      let tinyscrollbar =  $("#"+id+"ISIInfo")
                  let fooVis =$("#"+id+"viewport").height();
                  let overViewHeight = $("#"+id+"overview").height();		 
                  let heightOfOverview = $("#"+id+"overview").height()-fooVis;
                  console.log("196timeMax")
                  await takeshot() 
                  if (heightOfOverview>fooVis){
                    console.log("199timeMax")
                   let topvalue = currContentTop+fooVis ;
                      for (topvalue; topvalue<heightOfOverview; ) {
                   
                       tinyscrollbar.data().plugin_tinyscrollbar.update(topvalue);
                     await new Promise(resolve => setTimeout(resolve, 100));
                      topvalue = topvalue+fooVis ;
                     await  takeshot() ;
                   }
                   topvalue = topvalue-fooVis;
                   if ((topvalue ) < overViewHeight){
                 
                   let remainingTopvalue = (overViewHeight-topvalue-fooVis);
                   console.log(remainingTopvalue,"remainingTopvalue")
                   topvalue = topvalue +remainingTopvalue;
                      tinyscrollbar.data().plugin_tinyscrollbar.update(topvalue);
                    await new Promise(resolve => setTimeout(resolve, 10));
                    await takeshot();
                   }
                   
                  }
                  else {
                    let remainingTopvalue = (overViewHeight-fooVis);
                    console.log(remainingTopvalue,"remainingTopvalue")
                    tinyscrollbar.data().plugin_tinyscrollbar.update(remainingTopvalue);
                   await new Promise(resolve => setTimeout(resolve, 50));
                   await takeshot();
                   }
                   console.log("227remainingTopvalue")
                   window.status= "ready"
                  }
                    
                    function stopAndStart(id) {
                         clearInterval(clearTimer[id]);
                    
                        setTimeout(function () {
                          let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");
                    
                          tinyscrollbar.update(0);
                        }, 1000);
                       }
                    
                    function ControlThumb(id) {
                      var scrollThumbCurrPos = 0;
                         var scrollThumbMaxPos = 119; // top position value of thumb div when collapse
                         var scrollThumbMinPos = 0;
                        let  scrollTrackHeight =0;
                         $("#"+id+"thumb","#"+id+"track","#"+id+"viewport", "#"+id+"overview").bind("click", function () {
                          clearInterval(clearTimer[id]);
                         });
                     
                         if (
                           $("#"+id+"overview").position().top <= parseInt($("#"+id+"viewport").height()) - parseInt($("#"+id+"overview").height())
                         ) {
                          stopAndStart(id);
                         $("#"+id+"scrollbar").addClass(id+"activeScroller");
                         } else {
                           scrollTrackHeight = $("#"+id+"track").height();
                           $("#"+id+"scrollbar").addClass(id+"activeScroller");
                           scrollThumbCurrPos = parseFloat($("#"+id+"thumb").css("top")); //$("#thumb").position().top;
                           var maxOverviewTop =
                             $("#"+id+"overview").height() - $("#"+id+"viewport").height() + 0; //  top position value of overview div when full scroll down by mousewheel or drag but not clicking bottomArrow
                           if (scrollTrackHeight > 100) {
                             scrollThumbMaxPos = 208.5; // top position value of thumb div when expand
                           } else {
                             scrollThumbMaxPos = 50; // top position value of thumb div when collapse
                           }
                           scrollThumbCurrPos = scrollThumbCurrPos - 5;
                           scrollThumbCurrPos = Math.min(scrollThumbCurrPos, scrollThumbMaxPos);
                           if (scrollThumbCurrPos < scrollThumbMaxPos) {
                             var currContentTop = Math.floor($("#"+id+"overview").position().top);
                    
                             if (currContentTop <= 0) {
                               currContentTop = currContentTop - 1;
                    
                               currContentTop = currContentTop * -1;
                             }
                    
                             currContentTop = Math.min(currContentTop, maxOverviewTop);
                    
                             let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");
                    
                             tinyscrollbar.update(currContentTop);
                           }
                         }
                       }
                    function scrollTrap(id) {
                      $("#"+id+'ISIInfo').tinyscrollbar();
                      var scrollTrackHeight = $("#"+id+'track').height();
                      var scrollThumbCurrPos = 0;
                      var scrollThumbMaxPos = 119; // top position value of thumb div when collapse
                      var scrollThumbMinPos = 0;
                    
                      $("#"+id+"arrowBottom").bind("click", function () {
                        scrollTrackHeight = $("#"+id+"track").height();
                    
                        scrollThumbCurrPos = parseFloat($("#"+id+"thumb").css("top")); //$("#thumb").position().top;
                        var maxOverviewTop =
                          $("#"+id+"overview").height() - $("#"+id+"viewport").height() + 0; //  top position value of overview div when full scroll down by mousewheel or drag but not clicking bottomArrow
                        if (scrollTrackHeight > 97) {
                          scrollThumbMaxPos = 533; // top position value of thumb div when expand
                        } else {
                          scrollThumbMaxPos = 45; // top position value of thumb div when collapse
                        }
                        scrollThumbCurrPos = scrollThumbCurrPos + 5;
                        scrollThumbCurrPos = Math.min(scrollThumbCurrPos, scrollThumbMaxPos);
                        //if(scrollThumbCurrPos<=scrollThumbMaxPos){
                        var currContentTop = $("#"+id+"overview").position().top;
                        if (currContentTop <= 0) {
                          currContentTop = currContentTop - 10;
                          currContentTop = currContentTop * -1;
                        }
                        currContentTop = Math.min(currContentTop, maxOverviewTop);
                        //console.log(currContentTop);
                        if (currContentTop < 4635) {
                          var scroll = $("#"+id+"ISIInfo").tinyscrollbar();
                    
                          $(scroll).data().plugin_tinyscrollbar.update(currContentTop);
                          //  $("#ISIInfo").tinyscrollbar_update(currContentTop);
                        }
                        //}
                      });
                      var scrollInterval = null;
                      $("#"+id+"arrowBottom" , "#"+id+"arrowTop").bind("mousedown", function (e) {
                        if ($(e.target).hasClass("bottom_arrow"))
                          scrollInterval = setInterval(function () {
                            $(".bottom_arrow").trigger("click");
                          }, 100);
                        else
                          scrollInterval = setInterval(function () {
                            $(".top_arrow").trigger("click");
                          }, 100);
                      });
                    
                      $("#"+id+"arrowBottom" , "#"+id+"arrowTop").bind("mouseup mousemove", function (e) {
                        clearInterval(scrollInterval);
                      });
                      $("#"+id+"arrowTop").bind("click", function () {
                        scrollTrackHeight = $("#"+id+"track").height();
                        scrollThumbCurrPos = $("#"+id+"thumb").position().top;
                        scrollThumbCurrPos = scrollThumbCurrPos - 5;
                        if (
                          scrollThumbCurrPos < scrollThumbMinPos &&
                          $("#"+id+"thumb").position().top >= scrollThumbMinPos
                        )
                          scrollThumbCurrPos = $("#"+id+"thumb").position().top;
                        if (scrollThumbCurrPos >= scrollThumbMinPos) {
                          var currContentTop = $("#"+id+"overview").position().top;
                    
                          if (currContentTop < 0) {
                            currContentTop = currContentTop + 10;
                            currContentTop = currContentTop * -1;
                            currContentTop = Math.max(currContentTop, 0);
                          } else currContentTop = 0;
                          //console.log(currContentTop);
                          var scroll = $("#"+id+'ISIInfo').tinyscrollbar();
                    
                          $(scroll).data().plugin_tinyscrollbar.update(currContentTop);
                          // $("#ISIInfo").tinyscrollbar_update(currContentTop);
                        }
                      });
                    }
                   async function pageLoadedHandler() {
                          if (arrObject.length) {
                    
                             animationRepeat(arrObject);
                          }else 	{
                            await takeshot();
                            await new Promise(resolve => setTimeout(resolve, 1000));
                            window.status= "ready"
                          }
                    }
                    
                    window.onload = function () {
                      if (Enabler.isPageLoaded()) {
                        pageLoadedHandler();
                      } else {
                        Enabler.addEventListener(
                          studio.events.StudioEvent.PAGE_LOADED,
                          pageLoadedHandler
                        );
                      }
                    };
                  
                    
                    for (let i=0; i <completeObj.length; i++) {
                    $("#"+completeObj[i].id+'ISIInfo').tinyscrollbar({ trackSize: completeObj[i].trackHeight , thumbSize :completeObj[i].thumbHeight, thumbSizeMin:2});
                    }
                 
        </script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.2.4/gsap.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.10.2/plugins/CSSPlugin.min.js"></script>
        <body>
  </html>
  `;
}

//   return `<html><head>
//  <meta charset="utf-8">
//  ${layerLink}
//  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
//  <meta name="format-detection" content="telephone=no" />
//  <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
//  <meta name="ad.size" content="width=${canvasDimension?.width},height=${
//     canvasDimension?.height
//   }"/>
// <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
//  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css">
//  <script src="https://s0.2mdn.net/ads/studio/Enabler.js"></script>
//  <script type="text/javascript" src="https://code.jquery.com/jquery-latest.js" ></script>
//  <script  type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.20.3/TweenMax.min.js"></script>
//  <script type="text/javascript" src="https://baijs.com/tinyscrollbar/js/jquery.tinyscrollbar.js" ></script>

//   <style>

//   ${finalDOMObj.CssCollect}
//   .customScrollBar {
//     padding-bottom:50px;
//   }
//   .customScrollBar::-webkit-scrollbar {
//     background-color: $imc-white-color;
//   }

//   .customScrollBar::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//   }

//   .customScrollBar::-webkit-scrollbar-thumb {
//     border-radius: 5px;
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//     background: rgba(0, 0, 0, 0.2);
//   }

//   .custonScrollBar::-webkit-scrollbar {
//     background-color: $imc-white-color;
//   }
//   #ref_popup{width: 300px; height: 250px; background-color:white; position: absolute; top:10px; left:-1px; display: none; z-index: 10;}

//   </style></head><body class="customScrollBar">

//   <div id="canvas-page">${finalDOMObj.DomObj}
//   <div id="ref_popup">
//   Lorem Ipsum is simply dummy text of the printing
//   and typesetting industry. Lorem Ipsum has been the
//    industry's standard dummy text ever since the 1500s,
//    when an unknown printer took a galley of type and
//    scrambled it to make a type specimen book. It has
//     survived not only five centuries, but also the leap
//      into electronic
//   </div>
//   </div>

//   <div id="photo" style="padding:10px"></div>
//   <script>
//   var arrObject=${animationFinalObjToString}
//   var fps =${canvasDimension.fps}
//   var tempGpsa = [];
//   function enableTxt(item) {
//     var urltest = $(item).data("url");
//     window.open(urltest);
//   }
//   var count = 0;
//   var animationTimes = 3;
//   let collectscreenShotImages="";
//   let tmp=[];
//   function getElements(elem) {
//     let element = [];
//     for (let i = 0; i < elem.length; i++) {
//       element.push(document.getElementById(elem[i]));
//       console.log("in Test");
//     }
//     return element;
//   }
//   let gsapTimeLine = "";

//   function animationRepeat(AryObj) {
//   gsap.ticker.fps(60);

//   gsapTimeLine = gsap.timeline();

//     for (let i = 0; i < AryObj.length; i++) {
//       console.log("here")
//       var obj = AryObj[i];
//       var funcName = obj.api;
//       let value = getElements(obj.atoms);

//       if (value[0]) {
//         let  createGPSA = gsap.timeline()
//            tempGpsa.push(createGPSA.to([value], obj.duration, obj.op));
//         }

//       if (i===0){
//     for (let j = 0 ; j<tempGpsa.length ; j++)
//     tempGpsa[j].pause();

//       }
//       if (i===AryObj.length-1)screenshot()
//     }

//     setTimeout(function () {
//       // autoScroll = setInterval(moveThumb, 90);
//     }, 100);
//     scrollTrap();
//     setTimeout(function () {
//       //  autoScroll = setInterval(ControlThumb, 90);
//     }, 100);
//   }
//   async function  screenshot(){
//   takeshot();
//   for (let i=0; i<40;i++){

//   for (let j = 0 ; j<tempGpsa.length ; j++){
//   tempGpsa[j].play();
//   }
//    await new Promise(resolve => setTimeout(resolve, 100));
//  for (let j = 0 ; j<tempGpsa.length ; j++){
//  tempGpsa[j].pause();
//  }
//   takeshot();

// if (i ===39){
//   var fooVis = $('#viewport').height();

//   var heightOfOverview = $('#overview').height()-fooVis;

//    var currContentTop = 0;
//   // if (fooVis){
//   // for (let i =0; i <4; i++ ){
//   //   takeshot();

//   //   currContentTop= currContentTop+fooVis ;
//   //  let tinyscrollbar = $("#ISIInfo");
//   //        tinyscrollbar.data().plugin_tinyscrollbar.update(currContentTop);
//   //        await new Promise(resolve => setTimeout(resolve, 100));
//   //  }
//   // }
//   let y = document.getElementById('Button2');
//   //y.click();

//   $("#ref_popup").css("display","block");
//   await new Promise(resolve => setTimeout(resolve, 100));
//   takeshot();
// await new Promise(resolve => setTimeout(resolve, 1000));

// let x =await window.md5(collectscreenShotImages);

// window.status= "ready"
// }
//   }
//   }
//   Button2.onclick = function() {
//   $("#ref_popup").css("display","block");

// };
//   function stopAndStart() {
//     clearInterval(autoScroll);
//     setTimeout(function () {
//       let tinyscrollbar = $("#ISIInfo").data("plugin_tinyscrollbar");
//       tinyscrollbar.update(0);
//     }, 1000);
//   }
//   function ControlThumb() {
//     $("#thumb,#track,#viewport").bind("click", function () {
//       clearInterval(autoScroll);
//     });

//     if (
//       $("#overview").position().top <=
//       parseInt($("#viewport").height()) - parseInt($("#overview").height())
//     ) {
//       stopAndStart();
//       $("#scrollbar").addClass("activeScroller");
//     } else {
//       scrollTrackHeight = $("#track").height();
//       $("#scrollbar").addClass("activeScroller");
//       scrollThumbCurrPos = parseFloat($("#thumb").css("top")); //$("#thumb").position().top;
//       var maxOverviewTop =
//         $("#overview").height() - $("#viewport").height() + 0; //  top position value of overview div when full scroll down by mousewheel or drag but not clicking bottomArrow
//       if (scrollTrackHeight > 100) {
//         scrollThumbMaxPos = 208.5; // top position value of thumb div when expand
//       } else {
//         scrollThumbMaxPos = 50; // top position value of thumb div when collapse
//       }
//       scrollThumbCurrPos = scrollThumbCurrPos - 5;
//       scrollThumbCurrPos = Math.min(scrollThumbCurrPos, scrollThumbMaxPos);
//       if (scrollThumbCurrPos < scrollThumbMaxPos) {
//         var currContentTop = Math.floor($("#overview").position().top);

//         if (currContentTop <= 0) {
//           currContentTop = currContentTop - 1;

//           currContentTop = currContentTop * -1;
//         }

//         currContentTop = Math.min(currContentTop, maxOverviewTop);

//         let tinyscrollbar = $("#ISIInfo").data("plugin_tinyscrollbar");

//         tinyscrollbar.update(currContentTop);
//       }
//     }
//   }

//   function takeshot() {
//     let div =
//       document.getElementById('canvas-page');

//     html2canvas(div, { dpi: 144, useCORS: true,
//       imageTimeout:0,
//       allowTaint: true}).then(
//       function (canvas) {
//         var img = canvas.toDataURL("image/png");

//     collectscreenShotImages = collectscreenShotImages + "<div><img   width='${
//       canvasDimension?.width
//     }px' height='${canvasDimension?.height}px' src='"+img+"'/> </div>";
//       })
//   }
//   function scrollTrap() {
//     $("#ISIInfo").tinyscrollbar();
//     var scrollTrackHeight = $("#track").height();
//     var scrollThumbCurrPos = 0;
//     var scrollThumbMaxPos = 119; // top position value of thumb div when collapse
//     var scrollThumbMinPos = 0;

//     $(".bottom_arrow").bind("click", function () {
//       scrollTrackHeight = $("#track").height();

//       scrollThumbCurrPos = parseFloat($("#thumb").css("top")); //$("#thumb").position().top;
//       var maxOverviewTop =
//         $("#overview").height() - $("#viewport").height() + 0; //  top position value of overview div when full scroll down by mousewheel or drag but not clicking bottomArrow
//       if (scrollTrackHeight > 97) {
//         scrollThumbMaxPos = 533; // top position value of thumb div when expand
//       } else {
//         scrollThumbMaxPos = 45; // top position value of thumb div when collapse
//       }
//       scrollThumbCurrPos = scrollThumbCurrPos + 5;
//       scrollThumbCurrPos = Math.min(scrollThumbCurrPos, scrollThumbMaxPos);
//       //if(scrollThumbCurrPos<=scrollThumbMaxPos){
//       var currContentTop = $("#overview").position().top;
//       if (currContentTop <= 0) {
//         currContentTop = currContentTop - 10;
//         currContentTop = currContentTop * -1;
//       }
//       currContentTop = Math.min(currContentTop, maxOverviewTop);
//       //console.log(currContentTop);
//       if (currContentTop < 4635) {
//         var scroll = $("#ISIInfo").tinyscrollbar();

//         $(scroll).data().plugin_tinyscrollbar.update(currContentTop);
//         //  $("#ISIInfo").tinyscrollbar_update(currContentTop);
//       }
//       //}
//     });
//     var scrollInterval = null;
//     $(".bottom_arrow , .top_arrow").bind("mousedown", function (e) {
//       if ($(e.target).hasClass("bottom_arrow"))
//         scrollInterval = setInterval(function () {
//           $(".bottom_arrow").trigger("click");
//         }, 100);
//       else
//         scrollInterval = setInterval(function () {
//           $(".top_arrow").trigger("click");
//         }, 100);
//     });

//     $(".bottom_arrow , .top_arrow").bind("mouseup mousemove", function (e) {
//       clearInterval(scrollInterval);
//     });
//     $(".top_arrow").bind("click", function () {
//       scrollTrackHeight = $("#track").height();
//       scrollThumbCurrPos = $("#thumb").position().top;
//       scrollThumbCurrPos = scrollThumbCurrPos - 5;
//       if (
//         scrollThumbCurrPos < scrollThumbMinPos &&
//         $("#thumb").position().top >= scrollThumbMinPos
//       )
//         scrollThumbCurrPos = $("#thumb").position().top;
//       if (scrollThumbCurrPos >= scrollThumbMinPos) {
//         var currContentTop = $("#overview").position().top;

//         if (currContentTop < 0) {
//           currContentTop = currContentTop + 10;
//           currContentTop = currContentTop * -1;
//           currContentTop = Math.max(currContentTop, 0);
//         } else currContentTop = 0;
//         //console.log(currContentTop);
//         var scroll = $("#ISIInfo").tinyscrollbar();

//         $(scroll).data().plugin_tinyscrollbar.update(currContentTop);
//         // $("#ISIInfo").tinyscrollbar_update(currContentTop);
//       }
//     });
//   }

//   function pageLoadedHandler() {
//     animationRepeat(arrObject);

//   }

//   window.onload = function () {
//     if (Enabler.isPageLoaded()) {
//       pageLoadedHandler();
//     } else {
//       Enabler.addEventListener(
//         studio.events.StudioEvent.PAGE_LOADED,
//         pageLoadedHandler
//       );
//     }
//   };
//   $("#ISIInfo").tinyscrollbar({ trackSize: ${
//     finalDOMObj.trackHeight ? finalDOMObj.trackHeight : 0
//   } });

// </script>
// <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js"></script>
// <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.10.2/TweenLite.min.js"></script>
// <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.2.4/gsap.min.js"></script>

// <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.10.2/plugins/CSSPlugin.min.js"></script>

//   </body>
//   `;
// }
