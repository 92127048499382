import React, { useState, useRef } from "react";
import FroalaEditor from "../../Uitls/CommonFroalaEditor";

const EditorAnimation = (props) => {
  const froalaInstance = useRef([]);
  const [froalIndex, setFroalIndex] = useState(undefined);

  const [componentData, setComponentData] = useState([
    {
      componentType: "Button",
      atomId: "",
      atomURL: "",
      buttonText: "text1",
      components: {
        width: 200,
        height: 50,
        animation: {},
      },
    },
    {
      componentType: "Button",
      atomId: "",
      atomURL: "",
      buttonText: "text2",
      components: {
        width: 200,
        height: 50,
        animation: {},
      },
    },
    {
      componentType: "Button",
      atomId: "",
      atomURL: "",
      buttonText: "text3",
      components: {
        width: 200,
        height: 50,
        animation: {},
      },
    },
  ]);

  const handleTextChange = (text, index) => {
    let data = [...componentData];
    data[index].buttonText = text;
    setComponentData(data);
  };

  const changeFontStyles = () => {
    if (froalaInstance && froalaInstance.current && froalIndex !== undefined) {
      froalaInstance.current[froalIndex].getEditor().commands.bold();
      let oldText = froalaInstance.current[froalIndex].editor.html.get();
      let data = [...componentData];
      data[froalIndex].buttonText = oldText;
      setComponentData(data);
    } else {
      return false;
    }
  };

  return (
    <>
      {componentData.map((items, index) => {
        return (
          <div
            style={{ marginTop: "100px", marginLeft: "100px" }}
            onClick={() => setFroalIndex(index)}
            key={index}
          >
            <FroalaEditor
              forwardedRef={(e) => (froalaInstance.current[index] = e)}
              defaultText={items.buttonText}
              handleModelChange={(e) => {
                handleTextChange(e, index);
              }}
              showToolBar={true}
            />
          </div>
        );
      })}

      <button onClick={(e) => changeFontStyles()}>Apply Bold</button>
    </>
  );
};

export default EditorAnimation;
