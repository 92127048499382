import { objSides } from "../Uitls/ComponentType";
export function TestSidesTouching(setVerticalLineFor, tempLayer, tempSelect) {
  let sides = ["left", "top", "right", "bottom", "vMiddle", "hMiddle"];
  let objSideData = objSides;
  setVerticalLineFor({
    ...objSideData,
  });
  tempLayer.current.forEach((layer) => {
    if (
      tempSelect.current.componentType === "default" ||
      layer.componentStyles.hideBoolean
    )
      return;

    if (layer.id !== tempSelect?.current?.id) {
      sides.forEach((side) => {
        let id = side + "id";
        let end = "end" + id;
        if (
          layer?.componentStyles[side] -
            tempSelect?.current?.componentStyles[side] >=
            -3 &&
          layer?.componentStyles[side] -
            tempSelect?.current?.componentStyles[side] <=
            3
        ) {
          if (objSideData[side] === true) {
            switch (side) {
              case "left":
                objSideData =
                  layer.componentStyles.top <
                  objSideData.leftid.componentStyles.top
                    ? {
                        ...objSideData,
                        [side]: true,
                        [id]: layer,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;
                objSideData =
                  layer.componentStyles.bottom >
                  objSideData.endleftid.componentStyles.bottom
                    ? {
                        ...objSideData,
                        [side]: true,
                        endleftid: layer,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                objSideData =
                  tempSelect.current.componentStyles.bottom >
                  objSideData.endleftid.componentStyles.bottom
                    ? {
                        ...objSideData,
                        [side]: true,
                        endleftid: tempSelect.current,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;
                break;
              case "right":
                objSideData =
                  layer.componentStyles.top <
                  objSideData.rightid.componentStyles.top
                    ? {
                        ...objSideData,
                        [side]: true,
                        [id]: layer,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;
                objSideData =
                  layer.componentStyles.bottom >
                  objSideData.endrightid.componentStyles.bottom
                    ? {
                        ...objSideData,
                        [side]: true,
                        endrightid: layer,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                objSideData =
                  tempSelect.current.componentStyles.bottom >
                  objSideData.endrightid.componentStyles.bottom
                    ? {
                        ...objSideData,
                        [side]: true,
                        endrightid: tempSelect.current,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;
                break;
              case "bottom":
                objSideData =
                  layer.componentStyles.left <
                  objSideData.bottomid.componentStyles.left
                    ? {
                        ...objSideData,
                        [side]: true,
                        [id]: layer,

                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                objSideData =
                  layer.componentStyles.right >
                  objSideData.endbottomid.componentStyles.right
                    ? {
                        ...objSideData,
                        [side]: true,
                        endbottomid: layer,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                objSideData =
                  tempSelect.current.componentStyles.right >
                  objSideData.endbottomid.componentStyles.right
                    ? {
                        ...objSideData,
                        [side]: true,
                        endbottomid: tempSelect.current,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;
                break;
              case "top":
                objSideData =
                  layer.componentStyles.left <
                  objSideData.topid.componentStyles.left
                    ? {
                        ...objSideData,
                        [side]: true,
                        [id]: layer,

                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                objSideData =
                  layer.componentStyles.right >
                  objSideData.endtopid.componentStyles.right
                    ? {
                        ...objSideData,
                        [side]: true,
                        endtopid: layer,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                objSideData =
                  tempSelect.current.componentStyles.right >
                  objSideData.endtopid.componentStyles.right
                    ? {
                        ...objSideData,
                        [side]: true,
                        endtopid: tempSelect.current,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                break;

              case "vMiddle":
                objSideData =
                  layer.componentStyles.top <
                  objSideData.vMiddleid.componentStyles.top
                    ? {
                        ...objSideData,
                        [side]: true,
                        [id]: layer,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;
                objSideData =
                  layer.componentStyles.bottom >
                  objSideData.endvMiddleid.componentStyles.bottom
                    ? {
                        ...objSideData,
                        [side]: true,
                        endvMiddleid: layer,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                objSideData =
                  tempSelect.current.componentStyles.bottom >
                  objSideData.endvMiddleid.componentStyles.bottom
                    ? {
                        ...objSideData,
                        [side]: true,
                        endvMiddleid: tempSelect.current,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;
                break;
              case "hMiddle":
                objSideData =
                  layer.componentStyles.left <
                  objSideData.hMiddleid?.componentStyles?.left
                    ? {
                        ...objSideData,
                        [side]: true,
                        [id]: layer,

                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                objSideData =
                  layer.componentStyles.right >
                  objSideData.endhMiddleid.componentStyles.right
                    ? {
                        ...objSideData,
                        [side]: true,
                        endhMiddleid: layer,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;

                objSideData =
                  tempSelect.current.componentStyles.right >
                  objSideData.endhMiddleid.componentStyles.right
                    ? {
                        ...objSideData,
                        [side]: true,
                        endhMiddleid: tempSelect.current,
                        lastSelectedComp: tempSelect.current,
                      }
                    : objSideData;
                break;

              default:
                break;
            }
          } else {
            objSideData = {
              ...objSideData,
              [side]: true,
              [id]: layer,
              [end]: layer,
              lastSelectedComp: tempSelect.current,
            };
          }
        } else if (!objSideData[side] && !objSideData.id) {
          objSideData = { ...objSideData, [side]: false, [id]: {} };
        }
      });
    }
  });
  setVerticalLineFor({
    ...objSideData,
  });
}
