import { useState, useRef, useEffect } from "react";

export function useDisplaycolorpicker(initialVisible) {
  const [displayColorPicker, setDisplayColorPicker] = useState(initialVisible);
  const refButtonColor = useRef(null);

  const handleClickOutside = (event) => {
    if (
      refButtonColor?.current &&
      !refButtonColor?.current?.contains(event.target)
    ) {
      setDisplayColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { refButtonColor, displayColorPicker, setDisplayColorPicker };
}

export function useDisplaycolorpickerBorder(initialVisible) {
  const [displayColorPickerBorder, setDisplayColorPickerBorder] =
    useState(initialVisible);
  const refBorder = useRef(null);

  const handleClickOutside = (event) => {
    if (refBorder.current && !refBorder.current.contains(event.target)) {
      setDisplayColorPickerBorder(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { refBorder, displayColorPickerBorder, setDisplayColorPickerBorder };
}

export function useDisplaycolorpickerFontColor(initialVisible) {
  const [displayColorPickerFontColor, setDisplayColorPickerFontColor] =
    useState(initialVisible);
  const refFontColor = useRef(null);

  const handleClickOutside = (event) => {
    if (refFontColor.current && !refFontColor.current.contains(event.target)) {
      setDisplayColorPickerFontColor(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return {
    refFontColor,
    displayColorPickerFontColor,
    setDisplayColorPickerFontColor,
  };
}

export function useDisplaycolorpickerBgColor(initialVisible) {
  const [displayColorPickerBgColor, setDisplayColorPickerBgColor] =
    useState(initialVisible);
  const refBgColor = useRef(null);

  const handleClickOutside = (event) => {
    if (refBgColor.current && !refBgColor.current.contains(event.target)) {
      setDisplayColorPickerBgColor(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return {
    refBgColor,
    displayColorPickerBgColor,
    setDisplayColorPickerBgColor,
  };
}
export function useDisplaycolorpickerScrollColor(initialVisible) {
  const [displayColorPickerScrollColor, setDisplayColorPickerScrollColor] =
    useState(initialVisible);
  const refScrollColor = useRef(null);

  const handleClickOutside = (event) => {
    if (
      refScrollColor.current &&
      !refScrollColor.current.contains(event.target)
    ) {
      setDisplayColorPickerScrollColor(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return {
    refScrollColor,
    displayColorPickerScrollColor,
    setDisplayColorPickerScrollColor,
  };
}
export function useDisplaycolorpickerTextBg(initialVisible) {
  const [displaycolorpickerTextBg, setDisplaycolorpickerTextBg] =
    useState(initialVisible);
  const refTextBg = useRef(null);

  const handleClickOutside = (event) => {
    if (refTextBg.current && !refTextBg.current.contains(event.target)) {
      setDisplaycolorpickerTextBg(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return {
    refTextBg,
    displaycolorpickerTextBg,
    setDisplaycolorpickerTextBg,
  };
}
export function useColorpickerFontColorISI(initialVisible) {
  const [colorPickerFontColorISI, setColorPickerFontColorISI] =
    useState(initialVisible);
  const refFontColorISI = useRef(null);

  const handleClickOutside = (event) => {
    if (
      refFontColorISI.current &&
      !refFontColorISI.current.contains(event.target)
    ) {
      setColorPickerFontColorISI(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return {
    refFontColorISI,
    colorPickerFontColorISI,
    setColorPickerFontColorISI,
  };
}
export function useColorpickerTextBgColorISI(initialVisible) {
  const [colorPickerTextBgColorISI, setColorPickerTextBgColorISI] =
    useState(initialVisible);
  const refTextBgColorISI = useRef(null);

  const handleClickOutside = (event) => {
    if (
      refTextBgColorISI.current &&
      !refTextBgColorISI.current.contains(event.target)
    ) {
      setColorPickerTextBgColorISI(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return {
    refTextBgColorISI,
    colorPickerTextBgColorISI,
    setColorPickerTextBgColorISI,
  };
}
