import React from "react";

import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_editor.pkgd.min.css";
import { froalaEditor } from "../Uitls/CommonFroalaConfig";
import "../styles/components/CommonFroalEditorComponent.scss";

const CommonFroalEditorComponent = (props) => {
  const froalaConfig = froalaEditor.config(
    {
      placeHolder: props.placeHolder,
      shouldHaveToolbar: true,
      shouldUpdateImmediatly: props.shouldUpdateImmediatly || false,
      //IMC-3328 to be handled
      contentEditable:
        props.editable && props.editable === "off" ? false : true,
      showToolBar: props.showToolBar,
    },
    (onclick = (event) => {
      if (props.onSupClick) {
        props.onSupClick(event);
      }
    })
  );

  const newEditor = (defaultText) => {
    return (
      <FroalaEditor
        ref={props.forwardedRef}
        className="common-editor"
        tag="textarea"
        config={froalaConfig}
        model={defaultText}
        onModelChange={props.handleModelChange}
      />
    );
  };

  return (
    <div
      className={`${props.className || "froalaContent"}`}
      style={{ paddingRight: props.scrollBarStyleWidth }}
    >
      {props && props.title && (
        <p className="mb-0 box-heading">{props.title}</p>
      )}

      {newEditor(props && props.defaultText ? props.defaultText : "")}
    </div>
  );
};

export default CommonFroalEditorComponent;
