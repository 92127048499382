import React, { useState, useRef, useEffect, useContext } from "react";
import Layers from "./Layers";
import ElementLayers from "./ELementLayers.js";
import { Row, Col } from "react-bootstrap";
import PlayIcon from "../../images/PlayIcon.svg";
import TimelineIcon from "../../images/TimelineIcon.svg";
import dragImage from "../../images/layer.svg";

import PlayDisabled from "../../images/PlayDisabled.svg";

import RewindDisable from "../../images/rewind.svg";
import RewindIcon from "../../images/RewindIcon.svg";
import DiamondIcon from "../../images/DiamondIcon.svg";
import PauseIcon from "../../images/Pauseicon.svg";
import { gsap } from "gsap";
import "../../styles/pages/Animation.scss";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { objSides } from "../../Uitls/ComponentType";
import ValueContext from "../../ContextAPi/appContext";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/styles";
import { object } from "yup";
//import forwardArrow2 from "../../images/ForwardArrow2.svg";
let toggle = true;
//let newTimeLine = "";

const styles = (theme) => ({
  tooltip: {
    width: "110px",
    height: "30px",
    marginTop: "21px",
    fontSize: "12px",
    padding: "8px 12px 10px 12px",
    color: "black",
    background: "#fff",
    transform: "none",
    transition:
      "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "0 3px 12px 0 rgba(35, 31, 32, 0.17)",
    bottom: "-8px",
  },
  arrow: {
    color: "#fff",
  },
});

const CustomTooltip = withStyles(styles)(Tooltip);

export default function AnimationTimeLine({
  setMultiSelectedComponent,
  isAspectRatioLocked,
  showBannertype,
  layerCompData,
  setLayerCompData,
  millSeconds,
  setVerticalLineFor,
  seconds,
  setMillSeconds,
  setSeconds,
  selectedComponent,
  setSelectedComponent,
  animationObject,
  setAnimationObject,
  copiedKeyFrame,
  setCopiedKeyFrame,
  popUpOptions,
  setPopUpOptions,
  toggleLockComp,
  toggleHideComp,
  canvasDimension,
  toggleAnimatedBanner,
  setundoFlag,
  openTimelinePopup,
  setOpenTimelinePopup,
  popUpValuesEdited,
  setPopUpValuesEdited,
  playPause,
  playPauseRef,
  setPlayPause,
  marker,
  setMarker,
  popUpLeft,
  SetPopupLeft,
  refFroala,
  clickedTimes,
  froalaIndex,
  isEditorOn,
  setIsEditorOn,
  bannerChanged,
  setBannerChanged,
  layerChangedOnPause,
  setLayerChangedOnPause,
  setMultiSelectedComps,
  draggerWidth,
  setDraggerWidth,
  multiSelectedComps,
  deleteComponentOnCanvas,
  contextMenuvisbility,
  setContextMenuvisibility,
  setSelectedProperties,
  selectedProperties,
  setSelectedPropertiesIndex,
  selectedPropertiesIndex,
  setCopyPropertySelected,
  copyPropertySelected,
  setCopyPasteContextMenuPosition,
  copyPasteContextMenuPosition,
  setCopyPasteContextMenuVisibility,
  copyPasteContextMenuVisibility,
}) {
  const [isNewAnimationOpen, setIsNewAnimationOpen] = useState(false);
  const { t } = useTranslation();
  const appData = useContext(ValueContext); // to save scale
  const [edited, setEdited] = React.useState(false);
  const newTimeLine = useRef(gsap.timeline());
  //  const [draggerWidth, setDraggerWidth] = useState(675);
  const [draggerWidthInSec, setDraggerWidthInSec] = useState(15);
  const [totalDuration, setTotalDuration] = useState({
    divisions: 30,
    width: 1359,
  });
  const [clickedClose, setClickedClose] = useState(false);
  const [keyFrameClicked, setKeyFrameClicked] = useState(-1);
  const [popUpWindowPosn, setPopWindowPosn] = useState({
    left: "",
    top: "",
  });
  const twentyPlusSeconds = () => {
    let tempduration = {
      ...totalDuration,
      divisions: totalDuration.divisions + 10, // 10 secs
      width: totalDuration.width + 449.5, //width
    };
    // myDurationRef.current = tempduration;
    setTotalDuration(tempduration);
    appData.updateGenericInfo({
      ...appData?.genericInfo,
      canvasScale: tempduration,
    });
    setBannerChanged(true);
    setLayerChangedOnPause(true);
  };
  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => {
    //IMC-3328 fix
    if (refFroala.length > 0) {
      for (let x in refFroala) {
        if (refFroala[x] && refFroala[x].editorInitialized) {
          if (
            !playPauseRef.current.animationCompleted ||
            selectedComponent?.componentStyles?.lockBoolean
          ) {
            refFroala[x].editor.edit.off();
          } else refFroala[x].editor.edit.on();
        }
      }
    }
  }, [playPauseRef.current.playPause, selectedComponent]);

  // useEffect(() => {
  //   console.log(newTimeLine, "newTimeline");
  // }, [playPauseRef.current.playPause]);

  const updateKeyFrames = () => {
    let tempAnimationObj = JSON.parse(JSON.stringify(animationObject));

    tempAnimationObj[selectedComponent.id].animation[keyFrameClicked] = {
      ...tempAnimationObj[selectedComponent.id].animation[keyFrameClicked],
      x: popUpOptions.left,
      y: popUpOptions.top,
      width: popUpOptions.width,
      height: popUpOptions.height,
      rotation: popUpOptions.rotation,
      ease: popUpOptions.ease,
      transform: popUpOptions.scale / 100,
      opacity: popUpOptions.opacity / 100,
      end: popUpOptions.end,
      translateX: popUpOptions.translateX,
      translateY: popUpOptions.translateY,
      transformOriginX: popUpOptions.transformOriginX,
      transformOriginY: popUpOptions.transformOriginY,
      transformOriginZ: popUpOptions.transformOriginZ,
    };
    setAnimationObject(tempAnimationObj);
  };
  function addKeyFrames(origin) {
    // if (origin === "powerIcon") {
    //   marker = 45;
    //   seconds = 1;
    //   millSeconds = 0;
    // }
    if (!selectedComponent.hasOwnProperty("id")) {
      toast.error(
        <div className="notificationText">
          {t("Please select the component")}
        </div>
      );
      return;
    }
    if (!playPause.animationCompleted) {
      {
        toast.error(
          <div className="notificationText">
            {t("Keyframes cannot be added when the animation is in progress!")}
          </div>
        );
        return;
      }
    }
    let elem = document.getElementById("layerCol");
    SetPopupLeft(marker - 140 - elem.scrollLeft);
    let styles =
      layerCompData[
        layerCompData.findIndex(
          (selComp) => selComp.id === selectedComponent.id
        )
      ]?.componentStyles;
    if (styles?.lockBoolean) {
      toast.error(
        <div className="notificationText">{t("Component is Locked!")}</div>
      );

      return;
    }
    if (styles?.hideBoolean) {
      return;
    }
    let n = marker / 45;
    let keyFrameRange = parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;
    let tempAnimationObj = { ...animationObject };

    let clickedNow = true;
    // let clickedNow =
    //   edited || (!openTimelinePopup.popupWindow ? false : popUpOptions.clicked);
    let anmAry = tempAnimationObj[selectedComponent.id].animation;
    let layers = [...layerCompData];
    let [tempSlected] = layers.filter(
      (layer) => selectedComponent.id === layer.id
    );
    if (
      anmAry.findIndex(
        (anm) =>
          keyFrameRange <= anm.end + 0.1 && keyFrameRange >= anm.end - 0.1
      ) !== -1 &&
      selectedComponent?.keyFrames !== []
    ) {
      debugger;
      toast.error(
        <div className="notificationText">
          {t("KeyFrame exists ! please edit the same")}
        </div>
      );
      return;
    }

    if (tempSlected.keyFrames) {
      let constructAnimationObj = { delay: 0, duration: 0, end: 0 };

      let animationObjLenght =
        tempAnimationObj[selectedComponent.id]?.animation.length;

      if (!tempSlected.keyFrames.length) {
        tempAnimationObj[selectedComponent.id].set = {
          left: selectedComponent.componentStyles.left,
          top: selectedComponent.componentStyles.top,
          width: selectedComponent.componentStyles.width,
          height: selectedComponent.componentStyles.height,
          rotation: clickedNow ? popUpOptions.rotation : 0,
          end: clickedNow ? popUpOptions.end : 0,
          ease: clickedNow ? popUpOptions.ease : "NONE",
          transform: clickedNow ? popUpOptions.scale / 100 : 1,
          opacity: clickedNow ? popUpOptions.opacity / 100 : 1,
          translateX: clickedNow ? popUpOptions.translateX : 0,
          translateY: clickedNow ? popUpOptions.translateY : 0,
          transformOriginX: clickedNow
            ? parseInt(popUpOptions.transformOriginX)
            : 0,
          transformOriginY: clickedNow
            ? parseInt(popUpOptions.transformOriginY)
            : 0,
          transformOriginZ: clickedNow
            ? parseInt(popUpOptions.transformOriginZ)
            : 0,
          //skewAnimation: clickedNow ? popUpOptions.skewAnimation : false,
        };

        // setAnimationObject(tempAnimationObj);
      }

      tempSlected.componentStyles.left = popUpOptions.left;
      tempSlected.componentStyles.top = popUpOptions.top;
      tempSlected.componentStyles.height = popUpOptions.height;
      tempSlected.componentStyles.width = popUpOptions.width;

      tempSlected.keyFrames.push(marker);

      tempSlected.keyFrames.sort((a, b) => (a > b ? 1 : -1));
      let firstAnimation =
        tempAnimationObj[selectedComponent.id].animation[
          animationObjLenght - 1
        ];
      constructAnimationObj = {
        delay: firstAnimation
          ? firstAnimation.end
          : seconds + millSeconds / 100,
        duration: firstAnimation
          ? seconds + millSeconds / 100 - firstAnimation.end
          : 0,
        end: seconds + millSeconds / 100,
        x: selectedComponent.componentStyles.left,
        y: selectedComponent.componentStyles.top,
        width: selectedComponent.componentStyles.width,
        height: selectedComponent.componentStyles.height,
        rotation: clickedNow ? popUpOptions.rotation : 0,
        ease: clickedNow ? popUpOptions.ease : "NONE",
        transform: clickedNow ? popUpOptions.scale / 100 : 1,
        opacity: clickedNow ? popUpOptions.opacity / 100 : 1,
        translateX: clickedNow ? popUpOptions.translateX : 0,
        translateY: clickedNow ? popUpOptions.translateY : 0,
        transformOriginX: clickedNow ? popUpOptions.transformOriginX : 0,
        transformOriginY: clickedNow ? popUpOptions.transformOriginY : 0,
        transformOriginZ: clickedNow ? popUpOptions.transformOriginZ : 0,
        //skewAnimation: clickedNow ? popUpOptions.skewAnimation : false,
      };
      anmAry.push(constructAnimationObj);
      anmAry.sort((a, b) => (a.end >= b.end ? 1 : -1));
      if (anmAry.length > 1) {
        let addedIndex = anmAry.findIndex(
          (anm) =>
            keyFrameRange <= anm.end + 0.1 && keyFrameRange >= anm.end - 0.1
        );
        if (addedIndex === 0) {
          anmAry[addedIndex].delay = seconds + millSeconds / 100;
          anmAry[addedIndex].end = seconds + millSeconds / 100;
          anmAry[addedIndex].duration = 0;

          anmAry[addedIndex + 1].delay = seconds + millSeconds / 100;
          anmAry[addedIndex + 1].duration =
            anmAry[addedIndex + 1].end - anmAry[addedIndex + 1].delay;
        } else if (addedIndex === anmAry.length - 1) {
          anmAry[addedIndex].delay = anmAry[addedIndex - 1].end;
          anmAry[addedIndex].duration =
            anmAry[addedIndex].end - anmAry[addedIndex].delay;
        } else if (addedIndex !== -1) {
          anmAry[addedIndex].delay = anmAry[addedIndex - 1].end;
          anmAry[addedIndex + 1].delay = anmAry[addedIndex].end;
          anmAry[addedIndex].duration =
            anmAry[addedIndex].end - anmAry[addedIndex].delay;
          anmAry[addedIndex + 1].duration =
            anmAry[addedIndex + 1].end - anmAry[addedIndex + 1].delay;
        }
      }
      setPopUpOptions({ ...popUpOptions, clicked: false });
      setEdited(false);
      setPopUpValuesEdited(true);
      setAnimationObject(tempAnimationObj);
      // let uniqueNum = tempSlected.keyFrames.unique();
      // tempSlected.keyFrames = uniqueNum;
      //console.log(tempSlected, "sriPada");
      tempSlected.componentStyles.rotation = constructAnimationObj.rotation;
      setLayerCompData(layers);
      setSelectedComponent(tempSlected);
      // setOpenTimelinePopup({
      //   popupWindow: true,
      //   fromUnRedo: false,
      // });
    }
  }
  // const closeTimelinepopup = () => {
  //   ;
  //   setOpenTimelinePopup({
  //     ...openTimelinePopup,
  //     popupWindow: false,
  //   });
  // };
  function pauseAnimation() {
    gsap.globalTimeline.pause();
    setLayerChangedOnPause(false);

    toggle = false;
    setPlayPause({
      playPause: true,
      animationCompleted: true,
    });
    // playPauseRef.current = {
    //   playPause: true,
    //   animationCompleted: true,
    // };
    // console.log(" markeree inpauseAnimation", marker);
    // let markerVal = Number(seconds) + Number(millSeconds) * 0.01;

    // setMarker(markerVal * 44.75);
  }
  // function playAnimation() {
  //   let animationObjectNew = JSON.parse(JSON.stringify(animationObject || []));
  //   let keyFrame = true;

  //   if (Object.keys(animationObjectNew).length === 0) {
  //     alert("Animation is not applied");
  //     return;
  //   }
  //   // console.log("markeroffsetTop", marker.offsetTop);
  //   // let markerBulb = {
  //   //   set: {
  //   //     top: 4,
  //   //     ease: "NONE",
  //   //     left: 0,
  //   //     width: 2,
  //   //     height: 240,
  //   //     opacity: 1,
  //   //     rotation: 0,
  //   //     transform: 1,
  //   //     skewAnimation: false,
  //   //   },
  //   //   timeLine: {
  //   //     id: "slider-TimeLine0",
  //   //     left: 0,
  //   //   },
  //   //   animation: [
  //   //     {
  //   //       delay: 0,
  //   //       duration: 0,
  //   //       end: 0,
  //   //       x: 0,
  //   //       y: 4,
  //   //       width: 2,
  //   //       height: 240,
  //   //       rotation: 0,
  //   //       ease: "NONE",
  //   //       transform: 1,
  //   //       opacity: 1,
  //   //       skewAnimation: false,
  //   //     },
  //   //     {
  //   //       x: "90", //dynamic
  //   //       y: "4",
  //   //       end: 2.0,
  //   //       ease: "NONE",
  //   //       delay: 0,
  //   //       width: 2,
  //   //       height: 240,
  //   //       opacity: 1,
  //   //       duration: 2.0,
  //   //       rotation: "0",
  //   //       transform: 1,
  //   //       skewAnimation: false,
  //   //       // onComplete: () => {
  //   //       //   setMarker(90);
  //   //       // },
  //   //     },
  //   //   ],
  //   // };
  //   // animationObjectNew = { ...animationObjectNew, marker: markerBulb };

  //   Object.keys(animationObjectNew).forEach((key) => {
  //     if (animationObjectNew[key].animation.length) keyFrame = false;
  //   });
  //   if (keyFrame) return;
  //   // console.log(animationObject, "animationObject");
  //   if (toggle === false) {
  //     gsap.globalTimeline.play();
  //     setPlayPause(false);
  //     return;
  //   }
  //   setPlayPause(false);
  //   // console.log(animationObject, "animationObject");
  //   // Object.keys(animationObject).map((id) => {
  //   //   let ele = document.getElementById(id);

  //   //   ele.style.top = animationObject[id].set.top + "px";
  //   //   ele.style.left = animationObject[id].set.left + "px";
  //   //   // ele.style.opacity = 0.1;
  //   //   //console.log(props.setObj, "props.setObj");
  //   //   // console.log(id, "id");
  //   //   // t2 = t2.set(`#${id}`, {
  //   //   //   x: 20,
  //   //   //   y: 6,
  //   //   //   opacity: 0.2,
  //   //   //   duration: 0,
  //   //   // });
  //   // });
  //   //var t3 = gsap.timeline();
  //   // var t1 = "";

  //   //fps min and max check
  //   // if (canvasDimension.fps < 35) {
  //   //   toast.error(
  //   //     <div className="notificationText">
  //   //       {t("FPS should not be less than 35 !")}
  //   //     </div>
  //   //   );
  //   //   return;
  //   // }
  //   // var currentTimeScale = gsap.globalTimeline.timeScale();

  //   // if (currentTimeScale > 0) {
  //   //   gsap.globalTimeline.play();
  //   //   return;
  //   // }
  //   let totalDuration = [];
  //   gsap.ticker.fps(canvasDimension.fps);

  //   let layer = layerCompData.map((component) => {
  //     if (component.componentStyles.hideBoolean === true) return component.id;
  //   });
  //   let arrayAnimObjects = [];
  //   Object.keys(animationObjectNew).forEach((key) =>
  //     arrayAnimObjects.push({
  //       id: key,
  //       details: animationObjectNew[key],
  //     })
  //   );
  //   layer.forEach((id) => {
  //     for (let objId in animationObjectNew) {
  //       if (id !== undefined && objId === id)
  //         animationObjectNew[id].animation.forEach((anmObj) => {
  //           anmObj.opacity = 0;
  //           anmObj.hidden = true;
  //         });
  //     }
  //   });

  //   Object.values(animationObjectNew).forEach((obj, index) => {
  //     if (obj?.animation.length > 0 && obj.animation[0].delay !== 0) {
  //       let tempObj = obj.animation[0];
  //       if (tempObj) {
  //         tempObj.delay = 0;
  //         tempObj.duration = tempObj?.end;
  //         let firstObject = {
  //           ...tempObj,
  //           rotation: 0,
  //           left: 0,
  //           top: 0,
  //           width: tempObj.width,
  //           height: tempObj.height,
  //           transform: 1,
  //           delay: 0,
  //           duration: 0,
  //           ease: "NONE",
  //           end: 0,
  //           opacity: tempObj.hasOwnProperty("hidden") ? 0 : 1,
  //           skewAnimation: false,
  //         };
  //         obj.animation.splice(0, 0, firstObject);
  //         obj.animation[1] = tempObj;
  //       }
  //     }
  //   });

  //   Object.keys(animationObjectNew).map((id, index) => {
  //     let initialSet = animationObjectNew[id]?.animation[0] || {};
  //     // console.log(initialSet, "ididid");
  //     if (Object.keys(initialSet).length > 0)
  //       gsap.set(`#${id}`, {
  //         delay: 0,
  //         duration: 0,
  //         left: 0,
  //         top: 0,
  //         width: initialSet?.width,
  //         height: initialSet?.height,
  //         opacity: initialSet?.opacity,
  //         rotation: initialSet?.rotation,
  //         ease: initialSet?.ease,
  //         scale: initialSet?.transform,
  //         transform: `translate(${initialSet.x}px,${initialSet.y}px)`,
  //         skewAnimation: initialSet?.skewAnimation,
  //       });
  //   });
  //   Object.keys(animationObjectNew).forEach((id, index) => {
  //     animationObjectNew[id].animation
  //       .slice()
  //       .reverse()
  //       .forEach((eachObj, index) => {
  //         totalDuration.push({
  //           id: id,
  //           totalTime: eachObj.delay + eachObj.duration,
  //         });
  //       });
  //   });
  //   const maxValueOfY = totalDuration.reduce(function (prev, current) {
  //     return prev.totalTime > current.totalTime ? prev : current;
  //   });
  //   let timeLine = "";
  //   Object.keys(animationObjectNew).map((id, index) => {
  //     if (maxValueOfY.id !== id)
  //       animationObjectNew[id].animation
  //         .slice()
  //         .reverse()
  //         .map((eachObj, index) => {
  //           gsap.to(`#${id}`, {
  //             delay: eachObj.delay,
  //             duration: eachObj.duration,
  //             left: 0,
  //             top: 0,
  //             width: eachObj.width,
  //             height: eachObj.height,
  //             opacity: eachObj.opacity,
  //             rotation: eachObj.rotation,
  //             ease: eachObj.ease,
  //             scale: eachObj.transform,
  //             transform: `translate(${eachObj.x}px,${eachObj.y}px)`,
  //             skewAnimation: eachObj?.skewAnimation,
  //           });
  //         });
  //     else {
  //       animationObjectNew[id].animation
  //         .slice()
  //         .reverse()
  //         .forEach((eachObj, index) => {
  //           if (eachObj.delay + eachObj.duration !== maxValueOfY.totalTime)
  //             gsap.to(`#${id}`, {
  //               delay: eachObj.delay,
  //               duration: eachObj.duration,
  //               left: 0,
  //               top: 0,
  //               width: eachObj.width,
  //               height: eachObj.height,
  //               opacity: eachObj.opacity,
  //               rotation: eachObj.rotation,
  //               ease: eachObj.ease,
  //               scale: eachObj.transform,
  //               transform: `translate(${eachObj.x}px,${eachObj.y}px)`,
  //               skewAnimation: eachObj?.skewAnimation,
  //             });
  //           else
  //             timeLine = gsap.to(`#${id}`, {
  //               delay: eachObj.delay,
  //               duration: eachObj.duration,
  //               left: 0,
  //               top: 0,
  //               width: eachObj.width,
  //               height: eachObj.height,
  //               opacity: eachObj.opacity,
  //               rotation: eachObj.rotation,
  //               ease: eachObj.ease,
  //               scale: eachObj.transform,
  //               transform: `translate(${eachObj.x}px,${eachObj.y}px)`,
  //               skewAnimation: eachObj?.skewAnimation,
  //               onUpdate: function () {
  //                 checkProgree(timeLine);
  //               },
  //               onComplete: function () {
  //                 setPlayPause(true);
  //                 // setMarker();
  //                 toggle = true;
  //               },
  //             });
  //         });
  //     }
  //   });
  // }

  const returnAnimation = (anmObjectParam) => {
    let retVal = "";
    for (let i = 0; i < Object.values(anmObjectParam).length; i++) {
      //To remove comps from anmtn object which dont have keyframes added
      if (Object.values(anmObjectParam)[i]?.animation?.length === 0) {
        let objToRemove = Object.keys(anmObjectParam)[i];
        delete anmObjectParam[objToRemove];
        i--;
      }
    }

    for (let i = 0; i < Object.values(anmObjectParam).length; i++) {
      let obj = Object.values(anmObjectParam)[i];
      if (obj?.animation?.length === 1 && obj.animation[0].delay === 0) {
        if (
          obj?.animation?.opacity === 1 &&
          obj?.animation?.scale === 1 &&
          obj?.animation?.rotation === 0
        ) {
          if (Object.values(anmObjectParam).length === 1) {
            //Return from animation when there is only one keyframe and only one animation Object
            retVal = "return";
            break;
          }
          //To remove comps from anmtn object when there is only one keyframe and standrd popup inputs

          let objToRemove = Object.keys(anmObjectParam)[i];
          delete anmObjectParam[objToRemove];
          i--;
        }
      }
    }
    if (Object.values(anmObjectParam)?.length === 0) return true; //when there are no components in the editor

    if (retVal === "return") return true;
    return false;
  };
  function playAnimation() {
    scrollToFirstSecond();
    setVerticalLineFor(objSides);
    setSelectedComponent({});
    setContextMenuvisibility(false);
    let animationObjectNew = JSON.parse(JSON.stringify(animationObject || []));

    if (returnAnimation(animationObjectNew)) return;
    // let keyFrame = false;
    // Object.keys(animationObjectNew).forEach((key) => {
    //   if (
    //     animationObjectNew[key].animation.length === 1 &&
    //     animationObjectNew[key].animation[0].delay === 0
    //   )
    //     keyFrame = true;
    //   else if (animationObjectNew[key].animation.length > 1) keyFrame = false;
    // });
    // if (keyFrame) {
    setMarker(0);
    setSeconds(0);
    setMillSeconds(0);
    //   return;
    // }

    if (animationObjectNew)
      if (toggle === false) {
        // console.log(animationObject, "animationObject");
        if (layerChangedOnPause) {
          //if user pauses the animation and edited the canvas,
          setLayerChangedOnPause(false);
          newTimeLine.current.paused(false);
          newTimeLine.current = newTimeLine.current.clear();
          // newTimeLine = gsap.globalTimeline;
          gsap.globalTimeline.restart();

          // gsap.timeline({ defaults: { clearProps: true } });
          toggle = true;
        } else {
          gsap.globalTimeline.resume();
          setPlayPause({
            playPause: false,
            animationCompleted: false,
          });
          // playPauseRef.current = {
          //   playPause: false,
          //   animationCompleted: false,
          // };
          return;
        }
      }

    // console.log("playyy 586", gsap);
    FinalAnimation(animationObjectNew);
  }
  const layerColDivRef = useRef(null);
  const scrollToFirstSecond = () => {
    if (layerColDivRef.current) {
      layerColDivRef.current.scrollLeft = 0;
    }
  };
  const onCompleteAnimation = (animationObjectNew) => {
    setPlayPause({
      playPause: true,
      animationCompleted: true,
    });

    playPauseRef.current = {
      playPause: true,
      animationCompleted: true,
    };

    toggle = true;
    resetAfterAnimation(newTimeLine.current, animationObjectNew);
  };
  function FinalAnimation(animationObjectNew) {
    setPlayPause({
      playPause: false,
      animationCompleted: false,
    });

    newTimeLine.current = gsap.timeline({
      onUpdate: () => {
        checkProgree(newTimeLine.current, () =>
          onCompleteAnimation(animationObjectNew)
        );
      },
      onStart: () => {
        document.getElementById("layerCol").scrollLeft = 0;
      },

      onComplete: () => onCompleteAnimation(animationObjectNew),
    });
    gsap.ticker.fps(canvasDimension.fps);

    let layer = layerCompData.map((component) => {
      if (component?.componentStyles?.hideBoolean === true)
        return component?.id;
    });

    layer.forEach((id) => {
      for (let objId in animationObjectNew) {
        if (id !== undefined && objId === id)
          animationObjectNew[id].animation.forEach((anmObj) => {
            anmObj.opacity = 0;
            anmObj.hidden = true;
          });
      }
    });
    // let arrayAnimObjects = [];

    // Object.keys(animationObjectNew).forEach((key) =>
    //   arrayAnimObjects.push({
    //     id: key,
    //     details: animationObjectNew[key],
    //   })
    // );

    Object.values(animationObjectNew).forEach((obj, index) => {
      if (obj?.animation.length > 0 && obj.animation[0].delay !== 0) {
        let tempObj = obj.animation[0];
        if (tempObj) {
          tempObj.delay = 0;
          tempObj.duration = tempObj?.end;
          let firstObject = {
            ...tempObj,
            rotation: 0,
            left: 0,
            top: 0,
            width: tempObj.width,
            height: tempObj.height,
            transform: 1,
            delay: 0,
            duration: 0,
            ease: "NONE",
            end: 0,
            opacity: tempObj.hasOwnProperty("hidden") ? 0 : 1,
            transformOriginX: 0,
            transformOriginY: 0,
            transformOriginZ: 0,
            // skewAnimation: false,
          };
          obj.animation.splice(0, 0, firstObject);
          obj.animation[1] = tempObj;
        }
      }
    });

    Object.keys(animationObjectNew).map((id, index) => {
      let initialSet = animationObjectNew[id]?.animation[0] || {};
      // console.log(initialSet, "ididid");
      if (Object.keys(initialSet).length > 0)
        gsap.set(
          `#${id}`,
          initialObjectFiltered(animationObjectNew[id], id, animationObjectNew)
        );
    });
    let keyFrameArray = [];
    Object.keys(animationObjectNew).map((id, index) => {
      animationObjectNew[id].animation.slice().map((eachObj, eachObjIndex) => {
        newTimeLine.current.to(
          `#${id}`,
          filteredKeyVals(
            eachObj,
            eachObjIndex, //reversed index
            animationObjectNew[id].animation
          ),
          eachObj.delay
        );
        keyFrameArray.push(eachObj.end);
      });
    });
    let x = keyFrameArray.sort()[keyFrameArray.length - 1];
    newTimeLine.current.set({}, {}, `+=${draggerWidth / 45 - x}`);
  }

  const sendComparedValues = (val, toBesendObject, keyFrame) => {
    if (val === "transform") toBesendObject["scale"] = Number(keyFrame[val]);
    else if (val === "x" || val === "y") {
      toBesendObject[val] = Number(keyFrame[val]);
      toBesendObject.transform = `translate(${Number(
        keyFrame.x + keyFrame.translateX
      )}px,${Number(keyFrame.y + keyFrame.translateY)}px)`;
    } else if (val === "width" || val === "height") {
      keyFrame.backgroundSize = `${keyFrame.width}px ${keyFrame.height}px`;
      keyFrame["width"] = keyFrame.width;
      keyFrame["height"] = keyFrame.height;
    } else toBesendObject[val] = Number(keyFrame[val]);
  };
  const initialObjectFiltered = (
    componentWithAnimation,
    id,
    animationObjectNew
  ) => {
    let toBesendObject = {
      delay: 0,
      duration: 0,
      // left: 0,
      // top: 0,
    };
    let initialVals = [
      "height",
      "width",
      "opacity",
      "transform",
      "rotation",
      "x",
      "y",
    ];
    let keyFrames = componentWithAnimation.animation;

    let defaultKeframe = {
      rotation: 0,
      transform: 1,
      delay: 0,
      duration: 0,
      ease: "NONE",
      end: 0,
      opacity: keyFrames[0].hasOwnProperty("hidden") ? 0 : 1,
      height: keyFrames[0].height,
      width: keyFrames[0].width,
      y: keyFrames[0].y,
      x: keyFrames[0].x,
      transformOriginX: 0,
      transformOriginY: 0,
      transformOriginZ: 0,
    };

    layerCompData.forEach((component) => {
      if (component.id === id) {
        toBesendObject.height = component?.componentStyles?.height;
        toBesendObject.width = component?.componentStyles?.width;
        toBesendObject.x = component?.componentStyles?.left;
        toBesendObject.y = component?.componentStyles?.top;

        let positinsandsize = ["x", "y", "width", "height"];
        positinsandsize.forEach((val) => {
          keyFrames.forEach((keyFrame, index) => {
            if (
              index <
              keyFrames.length - 1
              // &&
              // keyFrame[val] !== keyFrames[index + 1][val]
            ) {
              if (val === "x" || val === "y") {
                defaultKeframe["x"] = component?.componentStyles["left"];
                defaultKeframe["y"] = component?.componentStyles["top"];
              } else defaultKeframe[val] = component?.componentStyles[val];
            }
          });
        });
      }
    });
    initialVals.forEach((val) => {
      // if (Number(keyFrames?.[0]?.[val]) !== Number(keyFrames?.[1]?.[val])) {
      //   sendComparedValues(val, toBesendObject, keyFrames[0]);
      // } else if (
      //   Number(keyFrames?.[0]?.[val]) !== Number(defaultKeframe?.[val])
      // ) {
      sendComparedValues(val, toBesendObject, keyFrames[0]);
      // }
    });

    animationObjectNew[id].set = toBesendObject;
    animationObjectNew[id].set.transform = componentWithAnimation.set.scale;
    delete animationObjectNew[id].set.scale;
    // console.log(toBesendObject, "sss");
    return toBesendObject;
  };
  console.log("anmObjj", animationObject);
  const filteredKeyVals = (eachAnmObj, index, anmObjArray) => {
    let keyValues = [
      "width",
      "height",
      "opacity",
      "rotation",
      "transform",
      "x",
      "y",
      "ease",
      "transformOriginX",
      "transformOriginY",
      "transformOriginZ",
    ];
    let objectTbeSent = {
      duration: eachAnmObj.duration,
      left: 0,
      top: 0,
      // transform: `translate(${eachAnmObj.x}px,${eachAnmObj.y}px)`,
    };
    keyValues.forEach((value) => {
      if (
        index <
        anmObjArray.length - 1
        // &&
        // eachAnmObj[value] !== anmObjArray[index + 1][value]
      ) {
        if (value === "x" || value === "y")
          objectTbeSent.transform = `translate(${
            eachAnmObj.x + eachAnmObj.translateX
          }px,${eachAnmObj.y + eachAnmObj.translateY}px)`;
        else if (value === "transform") {
          objectTbeSent.scale = eachAnmObj[value];
        } else if (value === "width" || value === "height") {
          objectTbeSent.backgroundSize = `${eachAnmObj.width}px ${eachAnmObj.height}px`;
          objectTbeSent["width"] = eachAnmObj.width;
          objectTbeSent["height"] = eachAnmObj.height;
        } else objectTbeSent[value] = eachAnmObj[value];
      } else if (
        index > 0 &&
        index < anmObjArray.length - 1
        // &&
        // eachAnmObj[value] !== anmObjArray[index - 1][value]
      ) {
        if (value === "x" || value === "y")
          objectTbeSent.transform = `translate(${
            eachAnmObj.x + eachAnmObj.translateX
          }px,${eachAnmObj.y + eachAnmObj.translateY}px)`;
        else if (value === "transform") {
          objectTbeSent.scale = eachAnmObj[value];
        } else if (value === "width" || value === "height") {
          objectTbeSent.backgroundSize = `${eachAnmObj.width}px ${eachAnmObj.height}px`;
          objectTbeSent["width"] = eachAnmObj.width;
          objectTbeSent["height"] = eachAnmObj.height;
        } else objectTbeSent[value] = eachAnmObj[value];
      } else if (
        index ===
        anmObjArray.length - 1
        // &&
        // eachAnmObj[value] !== anmObjArray[index - 1][value]
      ) {
        if (value === "x" || value === "y")
          objectTbeSent.transform = `translate(${
            eachAnmObj.x + eachAnmObj.translateX
          }px,${eachAnmObj.y + eachAnmObj.translateY}px)`;
        else if (value === "transform") {
          objectTbeSent.scale = eachAnmObj[value];
        } else if (value === "width" || value === "height") {
          objectTbeSent.backgroundSize = `${eachAnmObj.width}px ${eachAnmObj.height}px`;
          objectTbeSent["width"] = eachAnmObj.width;
          objectTbeSent["height"] = eachAnmObj.height;
        } else if (
          value === "transformOriginX" ||
          value === "transformOriginY" ||
          value === "transformOriginZ"
        ) {
          objectTbeSent.transformOriginX = parseInt(
            eachAnmObj.transformOriginX
          );
          objectTbeSent.transformOriginY = parseInt(
            eachAnmObj.transformOriginY
          );
          objectTbeSent.transformOriginZ = eachAnmObj.transformOriginZ;
        } else objectTbeSent[value] = eachAnmObj[value];
      }
    });

    return objectTbeSent;
  };

  const resetAfterAnimation = (timeline, animationObjectNew) => {
    timeline.time(0).kill();
    let tempLayerCompData = [...layerCompData];
    tempLayerCompData.forEach((comp, LayerCompIndex) => {
      // console.log(comp.id, animationObject[comp.id].set);
      if (animationObjectNew[comp.id]?.animation[0])
        tempLayerCompData[LayerCompIndex].componentStyles = {
          ...comp.componentStyles,
          ...animationObjectNew[comp.id]?.animation[0],
          left: animationObjectNew[comp.id]?.animation[0]?.x,
          top: animationObjectNew[comp.id]?.animation[0]?.y,
        };
    });
    setMarker(0);
    setSeconds(0);
    setMillSeconds(0);
    setLayerCompData(tempLayerCompData);
  };
  function checkProgree(timeLine, animationObjectNew) {
    let precisedTime = timeLine.time();
    if (precisedTime > draggerWidth / 45) {
      timeLine.eventCallback(
        "onComplete",
        onCompleteAnimation(animationObjectNew)
      );
      return;
    }
    let timeInSecsMs = precisedTime.toFixed(2);

    let secs = Math.floor(Number(timeInSecsMs));
    let milliSecs = timeInSecsMs.split(".")[1];
    setSeconds(secs);
    setMillSeconds(milliSecs);
    setMarker(Math.round(timeInSecsMs * 44.95));
    let domVal = 551;
    let elem = document.getElementById("layerCol");
    let markerValue = document
      .getElementById("marker")
      ?.getBoundingClientRect();
    let checkScrollValue =
      domVal - (markerValue.left - elem?.getBoundingClientRect().left);

    if (
      checkScrollValue < 30 &&
      document.getElementById("marker")?.getBoundingClientRect().left > 250
    ) {
      // let x = document.getElementById("layerCol");
      let y = elem.scrollLeft;
      elem.scrollLeft = y + 445;
    }
  }
  const draggerDisplayWidth = (newWidth) => {
    let oneCm = newWidth > 2250 ? 44.97 : 45;
    newWidth /= oneCm;
    if (Number.isInteger(newWidth)) return newWidth;
    // newWidth = newWidth >= 10 ? (newWidth *= 100) : (newWidth *= 10);
    let number = Math.round((newWidth *= 100)) / 100;
    return (Math.round(number * 10) / 10).toFixed(2);
  };
  const validateAction = () => {
    let elem = document.getElementById("layerCol");
    SetPopupLeft(marker - 140 - elem.scrollLeft);
    let styles =
      layerCompData[
        layerCompData.findIndex(
          (selComp) => selComp.id === selectedComponent.id
        )
      ]?.componentStyles;
    if (styles?.lockBoolean) {
      toast.error(
        <div className="notificationText">{t("Component is Locked!")}</div>
      );

      return false;
    }
    if (styles?.hideBoolean) {
      return false;
    }
    let n = marker / 45;
    let keyFrameRange = parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;
    let tempAnimationObj = { ...animationObject };

    let anmAry = tempAnimationObj[selectedComponent.id].animation;

    if (
      anmAry.findIndex(
        (anm) =>
          keyFrameRange <= anm.end + 0.1 && keyFrameRange >= anm.end - 0.1
      ) !== -1
      //  &&
      // selectedComponent?.keyFrames.length !== 0
    ) {
      debugger;
      toast.error(
        <div className="notificationText">
          {t("KeyFrame exists ! please edit the same")}
        </div>
      );
      return false;
    }
    return true;
  };

  const handleTimelinePopup = (e, dimensionsWhenOPened) => {
    if (e?.target?.id) return;

    // if (!selectedComponent.hasOwnProperty("id")) {
    //   toast.error(
    //     <div className="notificationText">{t("No Component selected!")}</div>
    //   );
    //   return;
    // }
    setPopUpValuesEdited(false);
    setTimeout(() => {
      setEdited(false);
    }, 1000);
    let elem = document.getElementById("layerCol");
    SetPopupLeft(marker - 140 - elem.scrollLeft);
    // setOpenTimelinePopup(!openTimelinePopup);
    setOpenTimelinePopup({
      popupWindow: !openTimelinePopup.popupWindow,
      fromUnRedo: false,
    });
    if (dimensionsWhenOPened) {
      setSelectedComponent({
        ...selectedComponent,
        componentStyles: {
          ...selectedComponent.componentStyles,
          ...dimensionsWhenOPened,
        },
      });
      let tempLayerComp = JSON.parse(JSON.stringify(layerCompData));
      tempLayerComp.forEach((comp, LayerCompIndex) => {
        // console.log(comp.id, animationObject[comp.id].set);
        if (comp.id === selectedComponent.id) {
          tempLayerComp[LayerCompIndex].componentStyles = {
            ...comp.componentStyles,
            ...dimensionsWhenOPened,
          };
        }
      });
      setLayerCompData(tempLayerComp);
    }
  };
  const handleMouseEnter = () => {
    if (
      openTimelinePopup.popupWindow !== true &&
      playPause.animationCompleted
    ) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  const setCustomPopupPosition = (e) => {
    setPopWindowPosn({
      left: e.clientX,
      top: e.clientY,
    });
  };

  const handleCompAspectRatioPopup = (
    compSelected,
    popUpvalues,
    name,
    newVal
  ) => {
    let oldWidth = compSelected.componentStyles.width;
    let oldHeight = compSelected.componentStyles.height;
    if (oldHeight == 0) {
      oldHeight = 1;
    }
    if (oldWidth == 0) {
      oldWidth = 1;
    }
    const aspectRatio = oldWidth / oldHeight;
    let newHeight;
    let newWidth;
    if (name === "width") {
      newHeight = newVal / aspectRatio;
      newWidth = newVal;
      compSelected.componentStyles.height = newHeight;
      popUpvalues.height = newHeight;
    }
    if (name === "height") {
      newWidth = newVal * aspectRatio;
      newHeight = newVal;
      compSelected.componentStyles.width = newWidth;
      popUpvalues.width = newWidth;
    }
  };
  const handleCtrlClick = (e, component) => {
    if (multiSelectedComps.length === 0) {
      component.multiSelected = true;
      setMultiSelectedComps([...multiSelectedComps, component]);
      return;
    }
    if (e.ctrlKey) {
      setMultiSelectedComps((prevSelected) => {
        const isSelected = prevSelected.some(
          (comp) => comp.id === component.id
        );
        if (isSelected) {
          setLayerCompData((prevData) =>
            prevData.map((comp) =>
              comp.id === component.id
                ? { ...comp, multiSelected: false }
                : comp
            )
          );
          return prevSelected.filter((comp) => comp.id !== component.id);
        } else {
          component.multiSelected = true;
          return [...prevSelected, component];
        }
      });
      setSelectedComponent({
        componentType: "default",
        componentStyles: { left: null, top: null },
      });
    } else {
      const updatedLayerCompData = layerCompData.map((comp) => {
        if (comp.id !== component.id) {
          return {
            ...comp,
            multiSelected: false,
          };
        }
        return comp;
      });
      setLayerCompData(updatedLayerCompData);
      component.multiSelected = true;
      setMultiSelectedComps([component]);
    }
  };
  return (
    <>
      <div
        className={toggleAnimatedBanner ? "time-line" : "time-lineAnimate "}
        id="time-line"
      >
        <Row className="timeline-lable row">
          <Col className="cold-md-6">
            {" "}
            <span>Timeline</span>
          </Col>
          <Col className="col-md-5">
            <div className="timelinecollapseicon">
              <img
                src={TimelineIcon}
                alt=""
                className="img-fluid timelineicon"
                //onClick={closeTimelinepopup}
              />
            </div>
          </Col>
          <Col className="col-md-1 secs10button">
            {/* <Tooltip title={"Add 10 sec to the timeline"} placement="top"> */}
            {/* <div>
              {" "}
              <button
                disabled={totalDuration.divisions === 90 ? true : false}
                type="button"
                className=" btn-link secbutton"
                onClick={twentyPlusSeconds}
              >
                + 10s
              </button>{" "}
            </div> */}
            {/* </Tooltip> */}
          </Col>
        </Row>
        <Row className="timeLine-layers" id="timeLine-layers">
          <Col
            className={
              toggleAnimatedBanner
                ? "col-3 col-md-3  list-icons"
                : "col-12 col-md-12  list-icons"
            }
          >
            <div className="Animation-Layers" id="Animation-Layers">
              <div className="layerSeconds">
                {/* <b>Layers</b> */}
                {toggleAnimatedBanner && (
                  <span>
                    {seconds}:{millSeconds}.{0}/
                    {draggerDisplayWidth(draggerWidth)}s{/* 00:00.0 / 15s */}
                  </span>
                )}
              </div>

              {toggleAnimatedBanner && (
                <>
                  {selectedComponent?.keyFrames?.length &&
                  layerCompData?.length > 0 ? (
                    // <CustomTooltip
                    //   open={
                    //     showTooltip &&
                    //     selectedComponent?.keyFrames?.length === 1
                    //   }
                    //   onMouseEnter={handleMouseEnter}
                    //   // className="custom-tooltip"
                    //   placement="top-start"
                    //   arrow
                    //   title="Add Keyframe"
                    // >
                    <Tooltip title={"Add Keyframe"} placement="top" arrow>
                      <div
                        onMouseLeave={handleMouseLeave}
                        className="diamondIcon"
                        onClick={(e) => {
                          if (
                            playPause.animationCompleted &&
                            validateAction()
                          ) {
                            setShowTooltip(false);
                            // setKeyFrameClicked(-1);
                            setKeyFrameClicked("Diamond+Icon");
                            setCustomPopupPosition(e);
                            setOpenTimelinePopup({
                              popupWindow: true,
                              fromUnRedo: false,
                            });
                            setIsNewAnimationOpen(true);
                          }
                        }}
                      >
                        <img
                          src={DiamondIcon}
                          alt="key"
                          className="diamond img-fluid"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    // </CustomTooltip>
                    ""
                  )}
                  <div className="rewindIcon">
                    <img
                      src={
                        `${seconds}:${millSeconds}` === "0:0"
                          ? RewindDisable
                          : RewindIcon
                      }
                      // src={RewindIcon}
                      alt="rewind"
                      className="img-fluid d-none"
                    />
                  </div>
                  {playPause.animationCompleted ? (
                    <Tooltip title={"Play"} placement="top" arrow>
                      <div className="playIcon">
                        <img
                          src={PlayDisabled}
                          alt="Play"
                          onClick={() => {
                            playAnimation();
                            setCopyPasteContextMenuVisibility(false);
                            setMultiSelectedComps([]);
                            layerCompData.forEach((comp) => {
                              comp.multiSelected = false;
                            });
                          }}
                          className="img-fluid"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Pause"} placement="top" arrow>
                      <div className="pauseIcon">
                        <img
                          src={PauseIcon}
                          alt="Pause"
                          onClick={pauseAnimation}
                          className="img-fluid"
                        />
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
            {layerCompData?.length !== 0 ? (
              <ElementLayers
                showBannertype={showBannertype}
                layerCompData={layerCompData}
                setLayerCompData={setLayerCompData}
                selectedComponent={selectedComponent}
                setSelectedComponent={setSelectedComponent}
                toggleLockComp={toggleLockComp}
                toggleHideComp={toggleHideComp}
                toggleAnimatedBanner={toggleAnimatedBanner}
                playPause={playPause}
                setMultiSelectedComps={setMultiSelectedComps}
                multiSelectedComps={multiSelectedComps}
                handleCtrlClick={handleCtrlClick}
              />
            ) : (
              <>
                <div
                  //  className="SortableItem"

                  className="addHeadingSelected"
                  style={{ backgroundColor: "#f2f3f3" }}
                >
                  <div className="layer-dragImage1 col-md-1">
                    {" "}
                    <img
                      // src={DragIcon}
                      src={dragImage}
                      alt="drag"
                      draggable={false}
                      className="img-fluid1 dragIcon2"
                    />
                  </div>
                </div>
              </>
            )}
          </Col>
          {toggleAnimatedBanner && (
            <Col className="col-9 col-md-9 pl-1" id="timeline-scale">
              <Layers
                handleCtrlClick={handleCtrlClick}
                isAspectRatioLocked={isAspectRatioLocked}
                handleCompAspectRatioPopup={handleCompAspectRatioPopup}
                layerColDivRef={layerColDivRef}
                totalDuration={totalDuration}
                setTotalDuration={setTotalDuration}
                layerCompData={layerCompData}
                setLayerCompData={setLayerCompData}
                millSeconds={millSeconds}
                seconds={seconds}
                setMillSeconds={setMillSeconds}
                setSeconds={setSeconds}
                selectedComponent={selectedComponent}
                setSelectedComponent={setSelectedComponent}
                marker={marker}
                animationObject={animationObject}
                setAnimationObject={setAnimationObject}
                setMarker={setMarker}
                popUpOptions={popUpOptions}
                setPopUpOptions={setPopUpOptions}
                copiedKeyFrame={copiedKeyFrame}
                setCopiedKeyFrame={setCopiedKeyFrame}
                setEdited={setEdited}
                openTimelinePopup={openTimelinePopup}
                setOpenTimelinePopup={setOpenTimelinePopup}
                toggleAnimatedBanner={toggleAnimatedBanner}
                setundoFlag={setundoFlag}
                popUpValuesEdited={popUpValuesEdited}
                setPopUpValuesEdited={setPopUpValuesEdited}
                playPause={playPause}
                popUpLeft={popUpLeft}
                SetPopupLeft={SetPopupLeft}
                bannerChanged={bannerChanged}
                setBannerChanged={setBannerChanged}
                setLayerChangedOnPause={setLayerChangedOnPause}
                draggerWidth={draggerWidth}
                setDraggerWidth={setDraggerWidth}
                addKeyFrames={addKeyFrames}
                showTooltip={showTooltip}
                setShowTooltip={setShowTooltip}
                handleTimelinePopup={handleTimelinePopup}
                setClickedClose={setClickedClose}
                clickedClose={clickedClose}
                setMultiSelectedComps={setMultiSelectedComps}
                multiSelectedComps={multiSelectedComps}
                keyFrameClicked={keyFrameClicked}
                setKeyFrameClicked={setKeyFrameClicked}
                updateKeyFrames={updateKeyFrames}
                popUpWindowPosn={popUpWindowPosn}
                setPopWindowPosn={setPopWindowPosn}
                setContextMenuvisibility={setContextMenuvisibility}
                contextMenuvisbility={contextMenuvisbility}
                deleteComponentOnCanvas={deleteComponentOnCanvas}
                isNewAnimationOpen={isNewAnimationOpen}
                setIsNewAnimationOpen={setIsNewAnimationOpen}
                copyPasteContextMenuVisibility={copyPasteContextMenuVisibility}
                setCopyPasteContextMenuVisibility={
                  setCopyPasteContextMenuVisibility
                }
                setSelectedProperties={setSelectedProperties}
                selectedProperties={selectedProperties}
                setSelectedPropertiesIndex={setSelectedPropertiesIndex}
                selectedPropertiesIndex={selectedPropertiesIndex}
                setCopyPropertySelected={setCopyPropertySelected}
                copyPropertySelected={copyPropertySelected}
                setCopyPasteContextMenuPosition={
                  setCopyPasteContextMenuPosition
                }
                copyPasteContextMenuPosition={copyPasteContextMenuPosition}
              ></Layers>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}
