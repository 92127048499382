import React, { useState, useContext, useEffect } from "react";
import "../../styles/pages/Banners/BannerManager.scss";
import "../../styles/components/kababMenu.scss";
import kababButton from "../../images/kababButton.svg";
import Approved from "../../images/status_approved.svg";
import lockbanner from "../../images/locbanner.svg";
import unLockedOnCards from "../../images/TopRightCardIcons/unLockedOnCards.svg";

import checkmarkGreen from "../../images/TopRightCardIcons/checkmarkGreen.svg";
import EditIconOnCard from "../../images/TopRightCardIcons/EditIconOnCard.svg";
import kababBlue from "../../images/TopRightCardIcons/kababBlue.svg";
import lockedOnCards from "../../images/TopRightCardIcons/lockedOnCards.svg";
import refreshMLR from "../../images/TopRightCardIcons/refreshMLR.svg";

import {
  getSaveAssets,
  searchModules,
  lockUnlockBanner,
  fileSizeValidation,
  getbannerandTemplateDetails,
  getMLRstatus,
} from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import useVisible from "./useVisible";
import BasedOnModal from "./BasedOnModal.js";
import BannerDeleteModal from "./BannerDeleteModal.js";
import BannerLockUnlockModal from "./BannerLockUnlockModal.js";
import BannerAndTemplateDetailsModal from "./BannerAndTemplateDetailsModal";
import { withRouter } from "react-router-dom";
import ValueContext from "../../ContextAPi/appContext";
import ExportModal from "./ExportModal.js";
import ExportCenterContainer from "./ExportCenterContainer";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { nameValidation } from "../../Uitls/Util.js";
import { banner } from "../../Uitls/constants";

function RecentlyViewedCard(props) {
  const appData = useContext(ValueContext);
  const {
    generateRVCV,
    disableProceedbut,
    setDisableProceedbut,
    selectMultipleBanner,
    setSelectMultipleBanner,
    arrcheckboxSelection,
    setArrcheckboxSelection,
    indexResponseData,
    hide3dotIcon,
    createdByMe,
    lockUnlockBannerAtFE,
  } = props;

  const { t } = useTranslation();
  let url =
    "https://imc-preview.s3.ap-south-1.amazonaws.com/ContentStudio/Banners/dummy-post-horisontal.jpg";

  const { ref, visible, setVisible } = useVisible(false);
  const [showBasedOn, setShowBasedOn] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showLockModal, setShowLockModal] = useState(false);

  const [showDetails, setShowDetails] = useState(false);

  const [showExport, setExport] = useState(false);
  const [type, setType] = useState(false);
  // const [indexValueforlock, setIndexValueforlock] = useState(undefined);
  // const [refDocuments, setRefDocuments] = useState([]);
  const [exportDOMData, setExportDOMData] = useState({
    cards: "",
  });
  // const handleDeleteModal = () => {
  // setShowDelete(true);
  // };
  const [checkExportType, setCheckExportType] = React.useState({
    PDF: false,
    PNG: false,
    JPG: false,
    HTML: false,
    RVCVFILE: false,
  });
  const [bannerDetails, setBannerDetails] = useState({
    assetName: "",
    assetType: "",
    brandName: "",
    bannerLink: "",
    marketName: "",
    width: "",
    height: "",
    campaign: "",
    veevaDocNumber: "",
    reviewStatus: "",
    createdBy: "",
    createdOn: "",
    updatedBy: "",
    updatedDateTime: "",
    isAnimated: false,
    createdByName: "",
    updatedByName: "",
    createdDateTime: "",
    sourceTemplateInfo: {
      assetName: "",
      brandName: "",
      campaign: "",
      marketName: "",
      createdBy: "",
      cratedByName: "",
      createdDateTime: "",
      updatedDateTime: "",
    },
    isBannerCreated: false,
    bannerCount: "",
  });

  const [rvcvTypes, setRvcvTypes] = React.useState({
    value2X: false,
    value3X: false,
  });

  const [latestMLRStatus, setLatestMLRStatus] = useState(
    props.recentlyViewedData?.assetMlr?.filter(
      (assetObj) => assetObj.fileType === "Pdf"
    )[0]?.reviewStatus || "Not Submitted"
  );

  useEffect(() => {
    setLatestMLRStatus(
      props.recentlyViewedData?.assetMlr?.filter(
        (assetObj) => assetObj.fileType === "Pdf"
      )[0]?.reviewStatus || "Not Submitted"
    );
  }, [
    props.recentlyViewedData?.assetMlr?.filter(
      (assetObj) => assetObj.fileType === "Pdf"
    )[0]?.reviewStatus,
  ]);
  //{[props.responseData[i].id]: false})

  const checkMultipleBanner = (event, id) => {
    let maxCheckboxSelection = [...selectMultipleBanner];
    let checkboxSelection = maxCheckboxSelection.filter(
      (element) => element.toggleRVCV === true
    );

    if (checkboxSelection?.length >= 5 && event.target.checked) {
      return toast.error(
        <div className="notificationText">
          {t("Selection of more than 5 banners is not allowed")}
        </div>
      );
    }
    const updatedCheckedState = selectMultipleBanner.map((item, index) => {
      return item.id === id ? { ...item, toggleRVCV: !item.toggleRVCV } : item;
    });
    setSelectMultipleBanner(updatedCheckedState);
  };

  useEffect(() => {
    let newArrayList = props.responseData.map((recentlyViewData) => ({
      id: recentlyViewData.id,
      data: recentlyViewData,
      toggleRVCV: false,
    }));
    setSelectMultipleBanner(newArrayList);
    // console.log(selectMultipleBanner, "updatedCheckedState");
  }, [props.responseData]);

  useEffect(() => {
    let obj = selectMultipleBanner.find((o) => o.toggleRVCV === true) || [];
    if (obj.toggleRVCV) {
      setDisableProceedbut(true);
    } else {
      setDisableProceedbut(false);
    }
  }, [selectMultipleBanner]);

  const getLatestMLRStatus = (id) => {
    props.setLoading(true);
    getMLRstatus(id, async (response, err, errorCode) => {
      if (err) {
        // const errCoponent = errComponentToRender(errorCode);

        props.setLoading(false);
      } else {
        const data = await response.data;
        setLatestMLRStatus(data?.reviewStatus || "Not Submitted");
        props.setLoading(false);
      }
    });
  };
  const exportType = (type) => {
    getSaveAssets(
      props.recentlyViewedData.id,
      async (response, err, errorCode) => {
        const data = await response.data;

        if (err) {
          //  const errCoponent = ErrComponentToRender(errorCode);
          props.setLoading(false);
        } else {
          appData.setAssetInfo({
            assetId: data.id,
            assetName: data.assetName,
          });

          setExportDOMData({
            cards: response.data,
          });
          setType(type);
        }
      }
    );
  };

  const handleKababMenu = (e) => {
    setVisible(!visible);
  };

  const sendSaveBannerHistory = (recentlyViewedData) => {
    localStorage.setItem("assetId", recentlyViewedData.id);
  };

  function getEditSaveAssets(id, cloneStatus) {
    getSaveAssets(id, async (response, err, errorCode) => {
      const data = await response.data;
      //  var cardAry = [];
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
      } else {
        appData.setBannersEditCardData({
          cards: data,
        });

        appData.setAssetInfo({
          assetId: data.id,
          assetName: data.assetName,
        });

        let obj = {};
        obj.brandName = data.brandName;
        obj.campaign = data.campaign;
        obj.market = data.marketName;
        obj.width = data.width;
        obj.height = data.height;
        //  obj.tagName = data.tagName;
        appData.updateGenericInfo({
          ...appData?.genericInfo,
          brand: data.brandName,
          campaign: data.campaign,
          market: data.marketName,
          //tagName: data.tagName,
          width: data.width,
          height: data.height,
        });
        appData.setMarketCode(data.marketCode);

        appData.setBrandId(data.brandId);
        setShowBasedOn(true);
        var formDataObj = {
          brandName: data.brandName,
          moduleNameArray: [],
          assetNameArray: [],
          claimCategoryArray: [],
          coreClaimArray: [],
        };

        if (response.data.modules.length) {
          for (let i = 0; i < data.modules.length; i++) {
            formDataObj.moduleNameArray.push(data.modules[1]?.code);
          }

          const options = {
            headers: {
              "Content-Type": "application/json",
              // Authorization: "Bearer " + token,
              "Accept-Language": "en-US,en;q=0.9",
              "Access-Control-Allow-Origin": "*",
            },
          };

          appData.setFlagEditBanner(true);
          searchModules(
            JSON.stringify(formDataObj),

            async (response, err, errorCode) => {
              const dataSearch = await response.data;
              if (err) {
                appData.setCardData({
                  cards: [],
                  search: cloneStatus ? "" : "search",
                });
              } else {
                appData.setModuleCardData({
                  cards: dataSearch,
                  search: cloneStatus ? "" : "search",
                });
                localStorage.removeItem("assetIdData");
              }
            }
          );
        }
      }
    });
  }

  const handleDeleteBanner = () => {
    setShowDelete(true);
    setVisible(!visible);
  };
  const [isLocked, setIsLocked] = useState(false);
  const handleLockBannerModal = (type) => {
    setShowLockModal(true);
    setVisible(!visible);
    if (type === "Lock") {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  };
  const handleOpenDetailsModal = () => {
    // setShowDetails(true);
    // setVisible(!visible);
    // setBannerDetails(
    //   ...bannerDetails,
    //   props.recentlyViewedData?.assetMlr?.filter(
    //     (assetObj) => assetObj.fileType === "Pdf"
    //   )[0]?.reviewStatus || "Not Submitted"
    // );
    getDetails();
  };
  const getDetails = () => {
    getbannerandTemplateDetails(
      props.recentlyViewedData?.id,
      async (response, err, errorCode) => {
        const data = await response.data;

        if (err) {
          //  const errCoponent = ErrComponentToRender(errorCode);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          setBannerDetails(response?.data);
          setShowDetails(true);
          setVisible(!visible);
        }
      }
    );
  };
  const bannerImages = {
    bannerName: "Banner A",
    img: (
      <img
        src={props.recentlyViewedData.thumbNail}
        alt="Banner "
        className="img-fluid"
      />
    ),
    status: "Approved",
  };
  const assetLockUnlock = (id, lockStatus, assetType) => {
    props.setLoading(true);
    lockUnlockBanner(id, lockStatus, assetType, (response, err, errorCode) => {
      if (err) {
        toast.error(
          <div className="notificationText">
            {t("Failed to send the unlock request")}
          </div>
        );
        props.setLoading(false);
      } else {
        setVisible(false);
        setShowLockModal(false);
        props.setLoading(false);
        lockUnlockBannerAtFE(id, lockStatus);
      }
    });
  };
  // const assetLock = (id) => {
  //   props.setLoading(true);
  //   lockBanners(id, (response, err, errorCode) => {
  //     if (err) {
  //       toast.error(
  //         <div className="notificationText">
  //           {t("Failed to lock the banner")}
  //         </div>
  //       );
  //       props.setLoading(false);
  //     } else {
  //       setVisible(!visible);
  //       // toast.success(
  //       //   <div className="notificationText">
  //       //     {t("Successfully locked the banner")}
  //       //   </div>
  //       // );
  //       setShowLockModal(false);
  //       props.setLoading(false);
  //     }

  //     props.fetchAllTemplates();
  //   });
  // };

  const fileValidation = () => {
    getSaveAssets(
      props.recentlyViewedData.id,
      async (response, err, errorCode) => {
        const data = await response.data;

        if (err) {
          //  const errCoponent = ErrComponentToRender(errorCode);
          props.setLoading(false);
        } else {
          if (data?.attributes?.bannerSize === 200) {
            //File Size Exceed More than 200 KB
            toast.error(
              <div className="notificationText">
                {t("Warning - Banner size exceeds 200 KB!")}
              </div>
              // { hideProgressBar: true, closeButton: false }
            );
            // setLoading(false);
            // return;
          }
        }
      }
    );
  };
  return (
    <>
      <div className="container recentlyViewCard">
        <div className="rvcvfileSelction">
          {generateRVCV && (
            <FormControl component="fieldset">
              <FormGroup
                aria-label="format"
                name="format"
                className="selectMultiplecheckbox"
              >
                <FormControlLabel
                  value="selectRVCV"
                  id="checkForRvcv"
                  control={
                    <Checkbox
                      id={`custom-checkbox-${indexResponseData}`}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={
                        selectMultipleBanner[indexResponseData]?.toggleRVCV
                      }
                      onChange={(e) =>
                        checkMultipleBanner(e, props.recentlyViewedData.id)
                      }
                      name="selectRVCV"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          )}
        </div>
        <div className="bannerRow">
          <Tooltip
            title={
              props.recentlyViewedData?.assetName?.length >= 14
                ? props.recentlyViewedData?.assetName
                : ""
            }
          >
            <h6 className="bannerName">{props.recentlyViewedData.assetName}</h6>
          </Tooltip>

          <div className="bannerTopRight">
            <div className="locked">
              {" "}
              {props.recentlyViewedData.lockStatus === "Locked" ? (
                <img
                  src={lockedOnCards}
                  alt="lockbanner"
                  className="img-fluid"
                  // onClick={() => {
                  //   // assetLockUnlock(props.recentlyViewedData.id, "Locked");
                  //   // handleLockBannerModal()
                  // }}
                />
              ) : (
                // </button>
                ""
              )}
            </div>
            <div className="mlrStatus">
              <Tooltip title={latestMLRStatus}>
                <img
                  src={
                    latestMLRStatus === "Approved for Production"
                      ? checkmarkGreen
                      : EditIconOnCard
                  }
                  alt="mlrStatus"
                />
              </Tooltip>
            </div>
            <div className="refreshMLR">
              <img
                src={refreshMLR}
                alt="refreshMLR"
                onClick={() => getLatestMLRStatus(props.recentlyViewedData.id)}
              />
            </div>
            {hide3dotIcon ? (
              <div className="kababIcon">
                <img
                  src={kababBlue}
                  alt="Kabab"
                  onClick={(e) => handleKababMenu(e)}
                />
                {visible ? (
                  <div className="kababMenu" ref={ref}>
                    {(props.recentlyViewedData.lockStatus !== "Locked" ||
                      props.recentlyViewedData.madeBy === "me") && (
                      <>
                        <div
                          className={`kababMenu--option`}
                          onClick={async () => {
                            // await sendSaveBannerHistory(
                            //   props.recentlyViewedData
                            // );
                            appData.setFlagEditFlow(true);
                            props.history.push({
                              pathname: `/banner-editor/${props.recentlyViewedData.id}`,
                              state: { editClicked: true },
                            });
                          }}
                        >
                          {t("bannerManager.kababMenu.edit")}
                        </div>
                      </>
                    )}
                    <div
                      className="kababMenu--option"
                      onClick={() => {
                        // if (!nameValidation(appData.assetInfo.assetName)) return;

                        setExport(true);
                        setCheckExportType({
                          ...checkExportType,
                          PDF: false,
                          HTML: false,
                          PNG: false,
                          JPG: false,
                          RVCVFILE: false,
                        });
                        setRvcvTypes({
                          ...rvcvTypes,
                          value2X: false,
                          value3X: false,
                        });
                        setVisible(!visible);
                        props.setLoading(true);
                        fileValidation();
                      }}
                    >
                      {t("bannerManager.kababMenu.export")}
                    </div>

                    {props.recentlyViewedData.madeBy === "me" && (
                      <>
                        <div
                          className="kababMenu--option"
                          onClick={() => handleDeleteBanner()}
                        >
                          {t("bannerManager.kababMenu.delete")}
                        </div>
                      </>
                    )}

                    {props?.recentlyViewedData?.madeBy === "me" ? (
                      props?.recentlyViewedData?.lockStatus === "Locked" ? (
                        <div
                          className="kababMenu--option"
                          onClick={() =>
                            //assetUnlock(props.recentlyViewedData.id)
                            handleLockBannerModal("Unlock")
                          }
                        >
                          {t("bannerManager.kababMenu.unlock")}
                        </div>
                      ) : (
                        <>
                          {
                            <div
                              className="kababMenu--option"
                              onClick={() =>
                                //assetLock(props.recentlyViewedData.id)
                                handleLockBannerModal("Lock")
                              }
                            >
                              {t("bannerManager.kababMenu.lock")}
                            </div>
                          }
                        </>
                      )
                    ) : (
                      ""
                    )}

                    <>
                      <div
                        className="kababMenu--option"
                        onClick={() => {
                          handleOpenDetailsModal();
                          //setVisible(false);
                        }}
                        //onClick={handleOpenDetailsModal}
                      >
                        {t("Details")}
                      </div>
                    </>
                  </div>
                ) : null}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="text-center">
          <div className="bannerImagesDiv ">
            <div
              // src={props.recentlyViewedData.thumbNail || url}
              style={{
                height: "208px",
                //props.recentlyViewedData.height >= 50 ?
                "208px": "auto",
                backgroundImage: props?.recentlyViewedData?.thumbNail
                  ? `url(${props.recentlyViewedData.thumbNail})`
                  : `url(${url})`,
              }}
              alt="Banner"
              className="bannerImages img-fluid"
            />
            <div className="middle">
              <div
                className="overlaytext"
                onClick={async () => {
                  await getEditSaveAssets(props.recentlyViewedData.id, true);
                }}
              >
                {t("bannerManager.basedOnTitle")}
              </div>
            </div>
            <div className="export-container" style={{ display: "none" }}>
              {type && (
                <ExportCenterContainer
                  exportDOMData={exportDOMData}
                  setType={setType}
                  type={type}
                  setLoading={props.setLoading}
                  checkExportType={checkExportType}
                  rvcvTypes={rvcvTypes}
                  setRvcvTypes={setRvcvTypes}
                  setCheckExportType={setCheckExportType}
                ></ExportCenterContainer>
              )}
            </div>
          </div>
          {showBasedOn && (
            <BasedOnModal
              showBasedOn={showBasedOn}
              recentlyViewedData={props.recentlyViewedData}
              setShowBasedOn={setShowBasedOn}
            />
          )}
          {showExport && (
            <ExportModal
              showBasedOn={showExport}
              setExport={setExport}
              setLoading={props.setLoading}
              exportType={exportType}
              checkExportType={checkExportType}
              setCheckExportType={setCheckExportType}
              rvcvTypes={rvcvTypes}
              setRvcvTypes={setRvcvTypes}
              genericInfo={props.genericInfo}
              setGenericInfo={props.setGenericInfo}
              toggleAnimatedBanner={props.recentlyViewedData.isAnimated}
            />
          )}
          {showDelete && (
            <BannerDeleteModal
              showDelete={showDelete}
              setShowDelete={setShowDelete}
              searchType={props.search}
              recentlyViewedData={props.recentlyViewedData}
              getRecentlyViewedBanner={props.getRecentlyViewedBanner}
              removeSearchresultCard={props.removeSearchresultCard}
            />
          )}
          {showDetails && (
            <div className="details-mod">
              <BannerAndTemplateDetailsModal
                setLoading={props.setLoading}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
                loading={props.loading}
                lockStatus={props.recentlyViewedData.lockStatus}
                historyprops={props.history}
                // handleOpenDetailsModal={handleOpenDetailsModal}
                bannerDetails={bannerDetails}
                setBannerDetails={setBannerDetails}
                recentlyViewedData={props?.recentlyViewedData}
              />
            </div>
          )}
          {showLockModal && (
            <BannerLockUnlockModal
              setShowLockModal={setShowLockModal}
              showLockModal={showLockModal}
              handleLockBannerModal={handleLockBannerModal}
              assetLockUnlock={assetLockUnlock}
              recentlyViewedData={props.recentlyViewedData}
              isLocked={isLocked}
              loading={props.loading}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(RecentlyViewedCard);
