import React from "react";
import { useTranslation } from "react-i18next";
import "../index.scss";

const NotFound = (props) => {
  const { t } = useTranslation();

  return (
    <div className="centeredContainer">
      {" "}
      {/* Add a container div with a CSS class */}
      <div
        className="translateHeaderBg"
        style={{ height: "200px", width: "350px" }}
      >
        <h1>{t("Page Not Found...")}</h1>
      </div>
    </div>
  );
};

export default NotFound;
