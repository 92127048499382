import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { withStyles, Divider } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ModalForms from "./ModalForms";
import { getAssetdetails } from "../../services/apis";

//import errComponentToRender from "../../Uitls/apiErrorHandling";
import { DialogStyle } from "../../styles/pages/ContentStudio/WithStylesObjs";
import "../../styles/pages/ContentStudio/DynDialogue.scss";
import ValueContext from "../../ContextAPi/appContext";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import Tooltip from "@material-ui/core/Tooltip";
import { CircularProgress } from "@material-ui/core";
function DynDialogue(props) {
  const appData = useContext(ValueContext);
  const { t } = useTranslation();
  const {
    handleBussinessRulesModal,
    activeCard,
    setActiveCard,
    toggleCards,
    setToggleCards,

    sendModuleid,
    cautionBackbtn,
    setResponseData,
    responseData,
    setCantExistCards,
    cantExistCards,
    setSelectedCardCaution,
    selectedCardCaution,
    setCautionBackbtn,
    linkedModulesType,
    handleVeevaContentModal,
    openVeevaContentModal,
    setOpenVeevaContentModal,
    getVeevaRulesset,
    veevaLoader,
    values,
    setValues,
    openBusinessRules,
    backButtonClicked,
    setBackButtonClicked,
  } = props;

  var pFields = [];
  if (props.genericInfo) {
    pFields = [
      props.genericInfo.brand,
      props.genericInfo.campaign,
      props.genericInfo.market,
    ]; //Object.entries(props.genericInfo);
  }

  const [searchFlag, setsearchFlag] = useState(false);
  const [conflictsNumber, setConflictsNumber] = useState(0);

  //  const [toggleCards, setToggleCards] = useState([]);

  // const [openBussinessRules, setOpenBussinessRules] = useState(false);

  const HandleSelect = (id, moduleId, classes) => {
    //  setsearchFlag(false);
    let tempcantExistCards = [];
    var tempActiveCards = JSON.parse(JSON.stringify(activeCard));
    let actFoundCardIndex = tempActiveCards.moduleId.indexOf(moduleId);
    if (actFoundCardIndex > -1) {
      tempActiveCards.moduleId.splice(actFoundCardIndex, 1);
    } else {
      tempActiveCards.moduleId.push(moduleId);
    }

    // const currentClass = document.getElementsByClassName(classes.root);
    // currentClass[id].classList.toggle(classes.selectedRoot);
    // if (currentClass[id].classList.length === 2) {
    //   tempActiveCards.moduleId.push(moduleId);
    // } else if (currentClass[id].classList.length === 1) {
    //   tempActiveCards.moduleId.pop();
    // }

    // Logic to check the module IDs
    //

    //

    let tempToggleCards = [...toggleCards];
    let foundCardIndex = tempToggleCards.indexOf(moduleId);
    if (foundCardIndex > -1) {
      tempToggleCards.splice(foundCardIndex, 1);
    } else {
      tempToggleCards.push(moduleId);
    }

    //console.log(tempcantExistCards, activeCard, "sssssss", tempActiveCards);
    appData &&
      appData.cardData.cards.forEach((temp) => {
        if (
          tempActiveCards.moduleId?.indexOf(temp.id) > -1 &&
          temp?.cannotExistRuleList?.length > 0
        ) {
          tempcantExistCards.push(...temp?.cannotExistRuleList);
        }
      });

    let conflictsNumber = 0;
    //Business Rule for conflicts checking .

    tempActiveCards.moduleId.forEach((cardNumber, index) => {
      if (tempcantExistCards.indexOf(cardNumber) > -1)
        conflictsNumber = conflictsNumber + 1;
    });
    setConflictsNumber(conflictsNumber);
    setToggleCards(tempToggleCards);
    setActiveCard(tempActiveCards);
    // setActiveCard(tempToggleCards);
    setCantExistCards(tempcantExistCards);
    //  tempcantExistCards.push()

    setSelectedCardCaution(true);
    //console.log(tempcantExistCards, "sssssss", tempActiveCards);
  };

  const protectedFieldNames = [
    "Product/Brand",
    "Campaign",
    "Market",
    "Banner Name",
    "Tags",
  ];

  const { classes } = props;

  // };

  return (
    <>
      <Dialog
        //className="selectModalConatiner"
        className={`selectModalConatiner ${classes.root}`}
        open={props.open}
        onClose={() => props.handleClose(setActiveCard)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        {veevaLoader && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress
              size="30px"
              className="customLoadermodules"
            />{" "}
          </>
        )}
        <div className="container no-gutters">
          <div className="row align-items-center align-left">
            <div className="col-md-4 selectModalTitle">
              {t("modulesLeftContainer.selectmodule")}
            </div>
            <div className="col-md-8">
              <ul className="moduleBrandDetails align-li">
                {pFields.map(
                  (valueAry, index) =>
                    protectedFieldNames[index] !== "Banner Name" &&
                    protectedFieldNames[index] !== "Tags" && (
                      <li>
                        <span>{protectedFieldNames[index]}</span>
                        <Tooltip title={valueAry}>
                          <h6 className="overFlow">{valueAry}</h6>
                        </Tooltip>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        </div>

        <DialogContent className="search-cards">
          <ModalForms
            values={values}
            setValues={setValues}
            conflictsNumber={conflictsNumber}
            setConflictsNumber={setConflictsNumber}
            setActiveCard={setActiveCard}
            activeCard={activeCard}
            HandleSelect={HandleSelect}
            cantExistCards={cantExistCards}
            setsearchFlag={setsearchFlag}
            setSearchORRecent={props.setSearchORRecent}
            cannotExist={cantExistCards}
            setCantExistCards={setCantExistCards}
            setToggleCards={setToggleCards}
            setResponseData={setResponseData}
            responseData={responseData}
            selectedCardCaution={selectedCardCaution}
            setCautionBackbtn={setCautionBackbtn}
            cautionBackbtn={cautionBackbtn}
            setSelectedCardCaution={setSelectedCardCaution}
            linkedModulesType={linkedModulesType}
            openBusinessRules={openBusinessRules}
            backButtonClicked={backButtonClicked}
            setBackButtonClicked={setBackButtonClicked}
          />
        </DialogContent>
        <Divider className="action-Divider" />
        <DialogActions className="action-btn">
          <Button
            onClick={() => props.handleClose(setActiveCard)}
            color="primary"
            className="closeBtn"
          >
            {t("modulesLeftContainer.close")}
          </Button>
          {/* <div onClick={()=>props.handleClose(setActiveCard)} className="closeBtn" >
          Close
          </div> */}
          {conflictsNumber > 0 && (
            <div className="conflict-mssg">
              {conflictsNumber} Conflict detected. This can result in compliance
              issues.{" "}
            </div>
          )}
          <Button
            onClick={() => {
              linkedModulesType === "nextModule"
                ? handleBussinessRulesModal()
                : handleVeevaContentModal();
              setCautionBackbtn(false);
            }}
            // onClick={
            //   props.genericInfo === undefined
            //     ? () =>
            //         toast.error(
            //           <div className="notificationText">
            //             {t("modulesLeftContainer.selectModuleToast")}
            //           </div>
            //         )
            //     : sendModuleid
            // }
            type="submit"
            disabled={activeCard.moduleId.length > 0 ? false : true}
            className="btn btn-primary proceed-btn "
            autoFocus
            // onClick={()=>{ props.handleClose(setActiveCard); sendModuleid()}}
            // onClick={()=>{handleBussinessRulesModal(); props.handleClose(setActiveCard)}}
            /*     onClick={ (props.genericInfo === undefined)?()=>toast.error(<div className="notificationText">{t("Please Select Brand, Campaign and Country")}</div>):
              sendModuleid && handleBussinessRulesModal }    */
          >
            {t("modulesLeftContainer.proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(DialogStyle)(DynDialogue);
