import React, { useContext, useState, useEffect, useRef } from "react";
import { DebounceInput } from "react-debounce-input";

import "../styles/pages/Editor.scss";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import Approved from "../images/status_approved.svg";
import checkmarkFilled from "../images/checkmark--filled.svg";

import RefreshIcon from "../images/RefreshIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import ValueContext from "../ContextAPi/appContext";
import { toast } from "react-toastify";
import "../styles/pages/Banners/BannerManager.scss";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import DropDownWithLabel from "./DropDownWithLabel.js";
import {
  getBrandsAndCampaigns,
  // getSaveAssets,
  // searchModules
  getCognitoSession,
  getCognitoIndentityCredentials,
  getMLRstatus,
} from "../services/apis";
import DropDownWithSearch from "../Uitls/DropDownWithSearch";
import InfoBlue from "../images/icon_info_blue.svg";
//Denovo related imports
//MArket Data returnlated imports and varaihle declaration
import { isoCountryTableName, awsRegion } from "../Uitls/Util";
import "../styles/components/Infotab.scss";
import Tooltip from "@material-ui/core/Tooltip";
//import "../styles/components/EditorModal.scss";
import { nameValidation } from "../Uitls/Util.js";
import DropDownWithLabelTemplate from "../components/DropDownWithLabelTemplate.js";
import DropDownWithSearchTemplate from "../components/DropDownWithSearchTemplate.js";

export default function InfotabSection(props) {
  const {
    showBannertype,
    getEditSaveAssets,
    setShowBannerName,
    showAssettype,
    damsID,
    setMLRstatus,
    MLRstatus,
    bannerId,
  } = props;

  const appData = useContext(ValueContext);
  var dynamo = require("dynamodb");
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [marketDataSource, setMarketDataSource] = useState([]);

  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [targetValue, setTargetValue] = useState("");
  const [genericInfo, setGenericInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
    //tagName: "",
    assetName: "",
  });
  useEffect(() => {
    fetchBrandsAndCampaigns();
    fetchMarketData();
    getCampaignListToPopulate(appData?.genericInfo?.brand);
    appData?.genericInfo?.brand &&
      setGenericInfo({
        ...genericInfo,
        campaign: appData?.genericInfo?.campaign,
      });
  }, []);

  useEffect(() => {
    if (props.targetLink) {
      setTargetValue(props.targetLink);
    } else {
      setTargetValue("");
    }
  }, [props.targetLink]);

  React.useEffect(() => {
    setAssetNameEdit(appData.assetInfo?.assetName);
    setGenericInfo({
      ...genericInfo,
      campaign: appData?.genericInfo?.campaign,
    });
  }, [appData?.genericInfo]);

  const returnMLRStatus = (id) => {
    // setLoading(true)
    getMLRstatus(id, async (response, err, errorCode) => {
      if (err) {
        // const errCoponent = errComponentToRender(errorCode);
        console.log(err);
        // setLoading(false)
      } else {
        // setLoading(false)
        const data = await response.data;
        setMLRstatus(data?.reviewStatus);
      }
    });
  };
  useEffect(() => {}, [genericInfo]);

  const fetchBrandsAndCampaigns = () => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        toast.error(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          setBrandsAndCampaigns(data);
        }
      }
    });
  };
  const getCampaignListToPopulate = (Brand) => {
    if (Brand && brandsAndCampaigns.length > 0) {
      const campaigns = brandsAndCampaigns.filter((object) => {
        return object.brandName === Brand;
      })[0]?.campaigns;
      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }
    return [];
  };
  const handleChangeInfoData = (e) => {
    const { name, value } = e.target;

    setGenericInfo({ ...genericInfo, [name]: value });
    if (brandsAndCampaigns) {
      brandsAndCampaigns.map((brand) => {
        if (brand.brandName === value) {
          appData.setBrandId(brand.brandId);
          return;
        }
        brand.campaigns.map((campaign) => {
          if (campaign.campaignName === value)
            appData.setCampaignId(campaign.campaignId);

          return;
        });
      });
    }
    let obj = { ...appData?.genericInfo, [name]: value, rightUpdaed: true };
    appData.updateGenericInfo(obj);
  };

  const fetchMarketData = () => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setMarketDataSource(marketData);
                }
              }
            }
          });
        });
      }
    });
  };

  const handleMarketChange = (market) => {
    if (market) {
      setSelectedMarket(market);
      setGenericInfo({ ...genericInfo, market: market.name });
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        market: market.name,
        rightUpdaed: true,
      });
      appData.setMarketCode(market.code);
    }
  };

  const { t } = useTranslation();

  const [assetNameEdit, setAssetNameEdit] = useState(
    appData.assetInfo.assetName
  );

  useEffect(() => {
    setAssetNameEdit(appData.assetInfo.assetName);
    appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: false });
  }, []);

  // const handleTagChange = (e) => {
  //   const { name, value } = e.target;
  //   let obj = appData?.genericInfo;
  //   //let obj= appData && appData?.genericInfo;
  //   obj["tagName"] = value;
  //   appData.updateGenericInfo({
  //     ...appData?.genericInfo,
  //     [name]: value,
  //     rightUpdaed: true,
  //   });
  // };

  const handleChangeInfoDataBlur = (e) => {
    setAssetNameEdit(assetNameEdit.trim());
    appData.setAssetInfo({
      ...appData.assetInfo,
      assetName: assetNameEdit.trim(),
    });

    // if (e.target.value.length < 3) {
    //   setShowBannerName(appData.assetInfo.assetName);
    //   setAssetNameEdit(appData.assetInfo.assetName);
    //   appData.setAssetInfo({
    //     ...appData.assetInfo,
    //     assetName: appData.assetInfo.assetName,
    //   });
    // toast.error(
    //   <div className="notificationText">
    //     {t("bannerManager.bnrNameMinErr")}
    //   </div>
    // );
    // }
  };

  const handleInputChange = (event) => {
    // if (!nameValidation(event.target.value)) return;

    setAssetNameEdit(event.target.value);

    appData.setAssetInfo({
      ...appData.assetInfo,
      assetName: event.target.value,
    });
    appData.updateGenericInfo({
      ...appData?.genericInfo,
      rightUpdaedName: true,
      rightUpdaed: true,
    });
  };
  const dropdownWidth = useRef(null);

  const getSelectedMarket = () => {
    if (!selectedMarket && appData && appData?.genericInfo?.market) {
      const countryName = appData?.genericInfo?.market;
      const countryCode = appData.marketCode;
      const displayName = `${countryCode} - ${countryName}`;
      return { name: countryName, code: countryCode, displayName: displayName };
    }
    return selectedMarket;
  };
  return (
    <>
      <div className="info-banner23 newBannerModal tempBannerModal clonetemplate">
        <div className="row newbannerDetails" style={{ marginTop: "-6px" }}>
          <Tooltip
            title={assetNameEdit.length >= 20 ? assetNameEdit : ""}
            placement="top"
          >
            <TextField
              autoComplete="off"
              variant="standard"
              label={
                showAssettype === "Banner"
                  ? t("editorRightPanel.infoTabContent.bannerName")
                  : "Template Name"
              }
              name={showAssettype === "Banner" ? "bannerName" : "templateName"}
              // className="bannerNameTextfield"
              className="bannerNameTextfield  templateNameTextfield infotextfield"
              value={assetNameEdit}
              onChange={handleInputChange}
              onBlur={(e) => {
                handleChangeInfoDataBlur(e);
              }}
              fullWidth={true}
              // onBlur={handleChangeInfoDataBlur}
            ></TextField>
          </Tooltip>
          <TextField
            id="standard-basic"
            variant="standard"
            label={showAssettype === "Banner" ? "Banner type" : "Template type"}
            name={showAssettype === "Banner" ? "templateType" : "bannerType"}
            className="bannerNameTextfield templateNameTextfield infotextfield"
            fullWidth={true}
            value={
              appData?.genericInfo?.isAnimated === true ? "Animated" : "Static"
            }
            // onChange={handleChange}
            //onBlur={(e) => handleChangeInfoDataBlur(e)}
            autoComplete="off"
            disabled="true"
          />
          <TextField
            id="standard-basic"
            variant="standard"
            label={showAssettype === "Banner" ? "Banner ID" : "Template ID"}
            name={showAssettype === "Banner" ? "templateID" : "bannerID"}
            className="bannerNameTextfield templateNameTextfield infotextfield"
            fullWidth={true}
            value="PS_US_1"
            // {
            //   appData?.genericInfo?.isAnimated === true ? "Animated" : "Static"
            // }
            // onChange={handleChange}
            //onBlur={(e) => handleChangeInfoDataBlur(e)}
            autoComplete="off"
            disabled="true"
          />
          <TextField
            id="standard-basic"
            variant="standard"
            label={t("MLR Status")}
            name="mlrStatus"
            className={
              MLRstatus === "Approved for Production "
                ? "bannerNameTextfield templateNameTextfield infotextfield notapproved "
                : "bannerNameTextfield templateNameTextfield infotextfield approved "
            }
            fullWidth={true}
            value={MLRstatus}
            // onChange={handleChange}
            //onBlur={(e) => handleChangeInfoDataBlur(e)}

            autoComplete="off"
            disabled="true"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={
                      MLRstatus === "Approved for Production  "
                        ? Approved
                        : checkmarkFilled
                    }
                    alt=""
                    className="img-fluid approvedIcon"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={RefreshIcon}
                    alt=""
                    className="img-fluid refreshIcon"
                    onClick={(e) => returnMLRStatus(bannerId)}
                  />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            id="standard-basic"
            variant="standard"
            label={t("DAMS ID")}
            name="damsId"
            className="bannerNameTextfield templateNameTextfield infotextfield"
            fullWidth={true}
            value={damsID}
            // onChange={handleChange}
            //onBlur={(e) => handleChangeInfoDataBlur(e)}
            autoComplete="off"
            disabled="true"
          />
          <TextField
            variant="standard"
            value={appData?.genericInfo?.brand}
            name="brand"
            label={t("bannerManager.brandName")}
            className="bannerNameTextfield brandName templateNameTextfield infotextfield"
            fullWidth={true}
            onChange={handleChangeInfoData}
            autoComplete="off"
            disabled="true"
          ></TextField>
          <div className="  infotextfield marketFiel">
            <DropDownWithSearchTemplate
              options={{
                value: getSelectedMarket(),
                name: "market",
                label: "Market",
                items: marketDataSource,
                handleChange: handleMarketChange,
              }}
              className=" custonScrollBar "
            />
          </div>
          <div
            className="newInputFieldinfo infotextfield marketFiel"
            ref={dropdownWidth}
          >
            <DropDownWithLabelTemplate
              options={{
                // value: appData?.genericInfo?.campaign, //t("uploadAssetLabel.brand"),
                value: genericInfo.campaign, //t("uploadAssetLabel.brand"),
                name: "campaign",
                label: "Campaign",
                items: getCampaignListToPopulate(appData?.genericInfo?.brand),
                handleChange: handleChangeInfoData,
              }}
              menuWidth={
                dropdownWidth.current?.getBoundingClientRect().width * 0.95 ||
                undefined
              }
            />
          </div>
        </div>
      </div>{" "}
    </>
  );
}
