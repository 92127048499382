import React, { useContext, useEffect, useState } from "react";
import "../../styles/pages/Editor.scss";
//import FroalaEditorComponent from 'react-froala-wysiwyg';
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import ValueContext from "../../ContextAPi/appContext";
import ExportHeader from "../BannerManager/ExportHeader";
import { toast } from "react-toastify";
export default function ExportCenterContainer({
  exportDOMData,
  setType,
  type,
  setLoading,
  checkExportType,
  setCheckExportType,
  rvcvTypes,
  setRvcvTypes,
}) {
  let layerLink = "";
  const appData = useContext(ValueContext);
  const [fontLink, setFontLink] = useState("");
  const [canvasDom, setCanvasDom] = React.useState([]);
  const [animationObj, setAnimationObj] = React.useState([]);

  function reRender(data) {
    let layerCompData = [],
      animationObj = [];

    let sample = [];

    let Id;
    data.forEach((data, index) => {
      Id = data.componentType + index;
      let shapeType =
        data.components.textComponentType?.addContent === "triangleShape"
          ? "triImage"
          : data.components.textComponentType?.addContent === "polygonShape"
          ? "pentImage"
          : data.components.textComponentType?.addContent === "hexagonShape"
          ? "hexImage"
          : "";
      layerCompData.push({
        id: data.componentType + index,
        componentType: data.componentType,
        froalaText: data.components.froalaText,
        componentStyles: data.components,
        textComponentType: data.components.textComponentType,
        [shapeType]: data.components.src,
      });

      let animationObjectNew = JSON.parse(
        JSON.stringify(data.components.animationObj || [])
      );
      // Object.values(animationObjectNew.animation).forEach((obj) => {
      let obj = animationObjectNew.animation || [];
      if (obj.length > 0 && obj[0].delay !== 0) {
        let tempObj = obj[0];
        if (tempObj) {
          tempObj.delay = 0;

          tempObj.duration = tempObj?.end;
          let firstObject = {
            ...tempObj,
            rotation: 0,
            left: 0,
            top: 0,
            width: tempObj.width,
            height: tempObj.height,
            transform: 1,
            delay: 0,
            duration: 0,
            ease: "NONE",
            end: 0,
            opacity: 1,
          };
          obj.splice(0, 0, firstObject);
          obj[1] = tempObj;
        }
      }
      // });

      obj.forEach((eachAnimation) => {
        const animationData = {
          api: "to",
          atoms: [Id],
          duration: eachAnimation.duration,
          op: {
            delay: eachAnimation.delay,
            opacity: eachAnimation.opacity,
            ease: eachAnimation.ease,
            rotation: eachAnimation.rotation,
            scale: eachAnimation.transform,
            left: eachAnimation.x,
            top: eachAnimation.y,
            transform: `translate3d(${eachAnimation.translateX}px, ${eachAnimation.translateY}px,0px)`,
            width: eachAnimation?.width,
            height: eachAnimation?.height,
            webkitTransformOrigin: `(${eachAnimation.transformOriginX}%, ${eachAnimation.transformOriginY}%,${eachAnimation.transformOriginZ}px)`,
          },
        };

        animationObj.push(animationData);
      });
    });
    setCanvasDom(layerCompData);
    setAnimationObj(animationObj);
    //console.log(layerCompData, "layerCompData", animationObj);
  }
  // React.useEffect(() => {
  //   let tempfontLink = "";
  //   let components = exportDOMData.cards?.assetpages[0]?.assetpagecomponent;
  //   if (components.length > 0) {
  //     components.forEach((component) => {
  //       tempfontLink += `${component.components.fontLink} \n`;
  //     });
  //   }
  //   setFontLink(tempfontLink);
  // }, []);

  const renderTheFontLink = (fontFamily) => {
    if (fontFamily.startsWith(" ")) fontFamily = fontFamily.substring(1);
    let tempFont = fontFamily?.split(" ").join("+");
    let url = `https://fonts.googleapis.com/css2?family=${tempFont}:wght@100;200;300;400;500;600;700;800;900&display=swap`;
    var link = document.createElement("link");
    link.href = url;
    link.rel = "stylesheet";
    link.type = "text/css";
    document.getElementsByTagName("head")[0].appendChild(link);
    return `<link rel=${"stylesheet"} href= "${url}">`;
  };
  var stringToHTML = function (str) {
    var dom = document.createElement("div");

    dom.innerHTML = str;

    return dom;
  };
  const renderAppliedFonts = (response, fromExport) => {
    const myFontSpanTags = [];
    // let fontsLink = "";
    // let fontsLink = "renderTheFontLink("Abeezee")";
    let fontsLink = "";

    response.assetpages[0].assetpagecomponent.forEach((assetpageComp) => {
      if (
        assetpageComp.componentType === "Button" ||
        assetpageComp.componentType === "Text"
      )
        fontsLink = renderTheFontLink(assetpageComp.components.fontFamily);

      myFontSpanTags.push(stringToHTML(assetpageComp.components.froalaText));
    });

    if (myFontSpanTags.length < 1) return;
    myFontSpanTags.forEach((myFontSpanTag) => {
      let compFamiliesLength =
        myFontSpanTag.getElementsByTagName("span").length;
      let params = [];
      for (let i = 0; i < compFamiliesLength; i++) {
        params[i] = myFontSpanTag
          .getElementsByTagName("span")
          [i].style.cssText.replaceAll(";", ",");
      }
      let compFamilies = params.map((param) => {
        let jsonStrig = "[{";
        let items = param.split(",");

        // items[0] = items[0].replace(/["/]+/g, "");
        for (let i = 0; i < items.length; i++) {
          items[i] = items[i].includes("font-family")
            ? items[i].replace(/["/]+/g, "")
            : items[i];
          if (items[i].startsWith(" ")) items[i] = items[i].substring(1);
          let current = items[i].split(":");

          jsonStrig += '"' + current[0] + '":"' + current[1] + '",';
        }

        jsonStrig = jsonStrig.substr(0, jsonStrig.length - 1);

        jsonStrig += "}]";
        return JSON.parse(jsonStrig)[0];
      });
      compFamilies.forEach((family) => {
        if (family.hasOwnProperty("font-family")) {
          let fontFamily = family["font-family"];
          if (fontFamily.startsWith(" ")) fontFamily = fontFamily.substring(1);
          let tempFont = fontFamily?.split(" ").join("+");
          let position = fontsLink.lastIndexOf("&");
          fontsLink = [
            "Arial",
            "Georgia",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ].includes(tempFont)
            ? fontsLink
            : [
                fontsLink.slice(0, position + 1),
                `family=${tempFont}:wght@100;200;300;400;500;600;700;800;900&`,
                fontsLink.slice(position),
              ].join("");
        }
        // fontsLink += `${renderTheFontLink(family["font-family"])} \n`;
      });
    });
    return fontsLink;
  };

  React.useEffect(() => {
    if (exportDOMData.cards?.assetpages[0]?.assetpagecomponent?.length > 0) {
      layerLink = renderAppliedFonts(exportDOMData.cards);
      setFontLink(layerLink);
      reRender(exportDOMData.cards?.assetpages[0]?.assetpagecomponent);
    } else {
      setLoading(false);
      toast.error(<div className="notificationText">Banner is Empty!</div>);
    }
  }, [exportDOMData]);

  return (
    <>
      <div
        id="canvas-page-export"
        style={{
          width: exportDOMData.cards.width + "px",
          height: exportDOMData.cards.height + "px",
        }}
      ></div>
      {canvasDom.length > 0 && (
        <ExportHeader
          type={type}
          setType={setType}
          setLoading={setLoading}
          canvasDimension={{
            width: exportDOMData.cards.width,
            height: exportDOMData.cards.height,
            bgColorCanvas: exportDOMData.cards?.attributes?.backgroundColor,
            fps: exportDOMData.cards?.attributes?.fps,
            globalLink: exportDOMData.cards?.attributes?.globalLink,
            checkGloballink: exportDOMData?.cards?.attributes?.checkGloballink,
            checkLoops: exportDOMData?.cards?.attributes?.checkLoops,
            loopAnimation: exportDOMData?.cards?.attributes?.loopAnimation,
            custom: exportDOMData?.cards?.attributes?.custom,
          }}
          canvasDom={canvasDom}
          animationObj={animationObj}
          fontLink={fontLink}
          checkExportType={checkExportType}
          setCheckExportType={setCheckExportType}
          rvcvTypes={rvcvTypes}
          setRvcvTypes={setRvcvTypes}
        />
      )}
    </>
  );
}
