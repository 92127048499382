import { toast } from "react-toastify";
export async function rvcvApiCall(DOMObj, rvcvTypes, canvasDimension) {
  let obj = {
    page: DOMObj.html,
    rvcv: rvcvTypes.value2X === true ? 2 : rvcvTypes.value3X === true ? 3 : 1,
    name: canvasDimension.assetName,
    emailID: localStorage.getItem("RVCV-email"),
    currentUserName: localStorage.getItem("currentUserName"),
  };

  let getstring = await fetch("https://test-imageconvert.indegene.com/RVCV", {
    method: "POST",
    body: JSON.stringify(obj),
  });

  let cv = await getstring;

  if (cv.status === 200) {
    let data = await cv.blob();

    // const blob = new Blob([data], { type: "application/zip" });
    // const downloadUrl = window.URL.createObjectURL(blob);
    // //setLoading(false);
    // const link = document.createElement("a");

    // link.href = downloadUrl;

    // link.setAttribute("download", canvasDimension.assetName + "_RVCV.zip"); //any other extension

    // document.body.appendChild(link);

    // link.click();

    // link.remove();
  }
}

export async function RVCVBulk(wholeObject) {
  try {
    fetch("https://test-imageconvert.indegene.com/Bulk", {
      method: "POST",
      body: JSON.stringify(wholeObject),
    });
  } catch (err) {
    console.log(err);
  }
}
