// import { useState, useEffect } from "react";

export const initBeforeUnLoad = (
  showExitPrompt,
  rightUpdated,
  refBannerChanged
) => {
  window.onbeforeunload = (event) => {
    console.log(
      "ueExitPrompt bannerChanged, refBannerChanged, rghtUpdated",
      showExitPrompt,
      refBannerChanged,
      rightUpdated
    );
    if ((showExitPrompt && refBannerChanged) || rightUpdated === true) {
      console.log("ueExitPrompt inside If");
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};

// Hook
// export default function useExitPrompt(bool, rightUpdated, refreshed) {
//   console.log("refreshed in prompt", refreshed);
//   const [showExitPrompt, setShowExitPrompt] = useState(bool);
//   ;
//   window.onload = function () {
//     initBeforeUnLoad(showExitPrompt, rightUpdated, refreshed);
//   };
//   useEffect(() => {
//     initBeforeUnLoad(showExitPrompt, rightUpdated, refreshed);
//   }, [showExitPrompt]);

//   return [showExitPrompt, setShowExitPrompt];
// }
