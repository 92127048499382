import React, { useState, useContext, createRef, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import warning from "../../images/warning.svg";
import ImgSvg from "../../images/DragZoneImage.svg";
import i18next from "i18next";
import "../../styles/components/ToolBarLeftPanel.scss";
import { useTranslation } from "react-i18next";
import ClearText from "../../images/ClearText.svg";
import forwardArrow2 from "../../images/ForwardArrow2.svg";
import { formatMs, TextField } from "@material-ui/core";
import ImageUpload from "../../images/ImageUpload.png";
import { isValidHttpUrl, checkURL } from "../../Uitls/Util";
import ImageLibrary from "./ImageLibrary";
import { uploadAnImageLink } from "../../services/apis";
import ValueContext from "../../ContextAPi/appContext";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { getSaveAssets, uploadAnImageDragAndDrop } from "../../services/apis";
import { Maximize } from "@material-ui/icons";

const ImageWindow = (props) => {
  const {
    handleCloseElement,
    layerId,
    linkImages,
    getLinkImages,
    imageCount,
    getLayerDetails,
    selectedComponent,
    /*  tab1Value,
    setTab1Value, */
  } = props;
  //const [urlLink, setUrlLink] = useState("");
  const [URLValue, setURLValue] = useState("");
  const dropzoneRef = createRef();
  const [showToast, setShowToast] = useState(false);
  const [showToastForUplaod, setShowToastForUplaod] = useState(false);
  const [tab1Value, setTab1Value] = useState(0);
  const [showErrToast, setShowErrToast] = useState(false);
  const [showErrToastUpload, setShowErrToastUpload] = useState(false);
  const handleTab1 = (event, newValue) => {
    setTab1Value(newValue);
  };
  const { t } = useTranslation();
  const appData = useContext(ValueContext);

  const handleURL = (e) => {
    setURLValue(e.target.value);
  };
  const handleClearToast = () => {
    setShowToastForUplaod(false);
  };
  const handleClearLinkToast = () => {
    setShowToast(false);
  };
  const handleClearLinkErrToast = () => {
    setShowErrToast(false);
  };
  const handleClearUploadErrToast = () => {
    setShowErrToastUpload(false);
  };
  const URLUpdate = (e) => {
    if (isValidHttpUrl(URLValue)) {
      // if (layerId.type === "Image") {
      // if (layerId.type === "Image") {
      if (URLValue !== "" && checkURL(URLValue)) {
        var formData = {};
        formData.assetId = appData?.assetInfo?.assetId;
        formData.imageLink = URLValue;
        uploadAnImageLink(formData, (response, err, errorCode) => {
          if (err) {
            const errCoponent = ErrComponentToRender(errorCode);
            if (
              err.response?.data?.errorCode ===
              "ERR_EXCEED_MAX_UPLOAD_SIZE_500KB"
            ) {
              toast.error(
                <div className="notificationText">
                  {" "}
                  {t("imageWindowTabs.uploadErrmsg")}
                </div>
              );
            } else {
              toast.error(
                <div className="notificationText">{t("apiErrors.err500")}</div>
              );
            }
          } else {
            getLinkImages(appData?.assetInfo?.assetId);

            setURLValue("");
            setTab1Value(1);
            setShowToast(true);

            setTimeout(() => {
              setShowToast(false);
            }, 3000);
          }
        });
      } else {
        setShowErrToast(true);
        setTimeout(() => {
          setShowErrToast(false);
        }, 3000);
        setTab1Value(1);
      }
    }
    // }
  };
  const [showImageOpt, setShowImageOpt] = useState(false);
  const [showFileSize, setShowFileSize] = useState([]);
  const onDropImage = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      setShowErrToastUpload(true);
      setTimeout(() => {
        setShowErrToastUpload(false);
      }, 3000);
      setTab1Value(0);
    } else {
      //let myRenamedFile = [];
      const formData = new FormData();

      accepted.forEach((uploadFile) => {
        formData.append("imageFile", uploadFile);
      });

      uploadAnImageDragAndDrop(
        appData?.assetInfo?.assetId,
        formData,
        (response, err, errorCode) => {
          if (err) {
            const errCoponent = ErrComponentToRender(errorCode);
            if (
              err.response?.data?.errorCode ===
              "ERR_EXCEED_MAX_UPLOAD_SIZE_500KB"
            ) {
              toast.error(
                <div className="notificationText">
                  {" "}
                  {t("imageWindowTabs.uploadErrmsg")}
                </div>
              );
            } else {
              toast.error(
                <div className="notificationText">{t("apiErrors.err500")}</div>
              );
            }
          } else {
            getLinkImages(appData?.assetInfo?.assetId);
            setTab1Value(0);
            setShowToastForUplaod(true);
            setShowImageOpt(true);
            setTimeout(() => {
              setShowImageOpt(false);
            }, 3000);
            setShowFileSize(response.data);
            setTimeout(() => {
              setShowToastForUplaod(false);
            }, 3000);
          }
        }
      );
    }
  };

  return (
    <>
      <div className={showImageOpt ? "imageWindow2" : "imageWindow"}>
        <div className="moduleWindowHeader align-items-center   d-flex justify-content-between">
          <div className="titleText">
            <span>{t("toolbarLeftPanel.imageTitle")}</span>
            {/* <img alt="" src={warning} className="img-fluid pl-2 warningimg" /> */}
          </div>
          <div className="">
            <img
              src={ClearText}
              alt=""
              className="clearTextIcon clearText"
              onClick={() => handleCloseElement("Image")}
            />
          </div>
        </div>
        <div className="brandTab pr-2">
          <div className="imageUrl d-flex pt-2">
            <span>Image URL</span>
          </div>
          <div className="pt-1 addbtn  d-flex justify-content-between">
            <DebounceInput
              element={TextField}
              debounceTimeout={500}
              className="image-urlInput "
              id="standard-basic"
              //  placeholder="Enter URL"
              value={URLValue}
              onChange={(e) => handleURL(e)}
              inputProps={{
                style: {
                  // width: "100px",
                  height: "20px",

                  fontSize: "12px",
                  color: "#928989",
                  fontStyle: "italic",
                },
              }}
            />
            <button //className="btn btn-link  addImageBut"
              className={
                URLValue === ""
                  ? " btn btn-link button-disabled pr-4"
                  : "btn addImageBut pr-4"
              }
              style={{
                fontWeight: "bold",
              }}
              disabled={URLValue === "" ? true : false}
              onClick={URLUpdate}
            >
              ADD
            </button>
          </div>
          <div className="dottedbrdr mt-4">
            <div className="upload-imageDrag ">
              <img
                alt="img-uplaod"
                src={ImgSvg}
                className="toggleIcon2 img-fluid upload-Image2"
              />
              <Dropzone
                ref={dropzoneRef}
                multiple={true}
                accept=".jpeg,.png,.jpg,.gif,.svg"
                onDrop={(accepted, rejected) => onDropImage(accepted, rejected)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={{ marginTop: "-5px" }}>
                    <input {...getInputProps()} />
                    <span className="image-textNew">Drag & drop</span>
                    <span className="image-texthere pl-1">here</span>
                    <div>
                      <span className="image-textNew2"> click here</span>
                      <span className="uploadText pl-1">to upload</span>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          {showImageOpt ? (
            <div className="imageOptimization">
              <span>{t("Image optimised by 60%")}</span>
              <div className="newFilesize">
                New File size :
                {showFileSize &&
                  showFileSize.map((item, index) => {
                    return (
                      <span key={index}>
                        {" "}
                        {item.fileSize} KB{" "}
                        {index === showFileSize.length - 1 ? "" : ","}
                      </span>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <hr className="hrtagimage" /> */}
          <ImageLibrary
            URLValue={URLValue}
            linkImages={linkImages}
            imageCount={imageCount}
            getLinkImages={getLinkImages}
            getLayerDetails={getLayerDetails}
            showToast={showToast}
            showToastForUplaod={showToastForUplaod}
            handleClearToast={handleClearToast}
            handleClearLinkToast={handleClearLinkToast}
            tab1Value={tab1Value}
            setTab1Value={setTab1Value}
            handleTab1={handleTab1}
            showErrToast={showErrToast}
            handleClearLinkErrToast={handleClearLinkErrToast}
            showErrToastUpload={showErrToastUpload}
          />{" "}
        </div>
      </div>
    </>
  );
};

export default ImageWindow;
