export const DialogStyle = {
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1024px",
      minWidth: "1024px",
      //height: "667px",
      scrollbarWidth: "none",
      display: "flex",
      flexDirection: "column",
    },
  },
  protectedFields: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: "12px",
    marginLeft: "23px",

    "& .MuiInput-formControl": {
      height: "25px",
      fontSize: "small",
      fontWeight: "bold",
    },
    "& .MuiInputLabel-animated": {
      fontFamily: "Open Sans",
      letterSpacing: "-0.03px",
      fontSize: "14px",
      color: "#999999",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:focus:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    " & .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiInputBase-input": {
      fontFamily: "Open Sans",
    },
  },
  marketIp: {
    width: "60px",
    marginLeft: "25px",
  },
};

export const FormStyle = {
  root: {
    "& .MuiFormControl-root": {
      // width:"695px"
    },
    "& .MuiSelect-icon": {
      color: "#1C75BB",
    },
    "& .MuiOutlinedInput-input": {
      width: "200px",
      height: "40px",
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      height: "40px",
    },
    "& .MuiDivider-inset": {
      marginTop: "-9px",
      marginLeft: "134px",
    },
    "& .MuiButton-text": {},
    "& .MuiDivider-inset": {
      marginTop: "-9px",
      marginLeft: "134px",
    },
    "& .MuiMenuItem-root": {
      fontSize: "12px",
    },
    input1: {
      height: 100,
    },
  },

  InlineDevide: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    height: " 17px",
    color: "#000000",
    lineHeight: "1.42",
    //marginRight: "-25px",
    marginRight: "-45px",
  },
  // ResultContainer: {
  //   marginTop: "31px",
  // },
  ResetKey: {
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "1.42",
    letterSpacing: "0.72px",
    color: "#3181c1",
    marginLeft: "10px",
  },
  Unabled: {
    cursor: "not-allowed",
    opacity: "0.4",
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "1.42",
    letterSpacing: "0.72px",
    marginLeft: "10px",
    color: "#3181c1",
  },
};

export const CardStyles = {
  root: {
    "& .MuiCard-root": {
      //  maxWidth: "295px",
      //maxHeight: "211px",
      // minHeight: "211px",
      marginBottom: "30px",
    },
  },
  selectedRoot: {
    "& .MuiCard-root": {
      border: "solid 2px #1c75bb",
      borderStyle: "dashed",
      // borderColor:'#1c75bb',
      backgroundColor: "#eef8ff",
    },
  },
  leftCardStyle: {
    "& .MuiCard-root": {
      width: "100%",
      borderStyle: "dashed",
      //width: "295px",
      maxHeight: "152px",
      border: "solid 2px #e1e1e1",
      marginBottom: "20px",
    },
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "15px",
    },
  },
  cannotExist: {
    "& .MuiCard-root": {
      border: "solid 1px #ed6073 ",
      // borderColor:'#1c75bb',
      backgroundColor: "#eef8ff",
    },
  },
};
