import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
import RemoveIcon from "@material-ui/icons/Remove";

import "./VeevaCardDetails.scss";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import parse from "html-react-parser";
function ModuleTextElementAccordion(props) {
  let counter = 0;
  let itemsCout =
    props.moduleTextElement.length -
    props.moduleTextElement.filter(
      (x) => x.textElementContent === "null" || x.textElementContent === null
    ).length;
  return (
    <div className="claimDetails " style={{ paddingRight: "18px" }}>
      <div className="accordion leftcard">
        <Accordion
          expanded={props.expanded === `panel-${2}`}
          className="accordionLeftpane"
          onChange={props.handleChange(`panel-${2}`)}
        >
          <AccordionSummary
            expandIcon={
              props.expanded === `panel-${2}` ? (
                <RemoveIcon fontSize="small" className="accordionIcons" />
              ) : (
                // <p>-</p>
                <AddIcon fontSize="small" className="accordionIcons" />
                // <p>+</p>
              )
            }
            aria-controls={`panelprops.Claims-${2}a-content`}
            id={`panelprops.Claims-${2}a-header`}
            className="accordion-summary"
          >
            <div className="claimName nextmod" style={{ displex: "flex" }}>
              {"Module Text Element"}
              {/* <div className="countItems countItems1">{`(${itemsCout} items)`}</div> */}
            </div>
            <span class="claimNameCount">{`(${itemsCout} items)`}</span>
          </AccordionSummary>
          <AccordionDetails className={"accordDetails  accordVisual "}>
            {props.moduleTextElement.length > 0 &&
              props.moduleTextElement?.map(
                (eachElement, index) =>
                  eachElement?.textElementContent &&
                  eachElement?.textElementContent !== "null" && (
                    <div>
                      <div className="accDetails2 ">
                        <div className="typeHeading">
                          {eachElement.textElementType}
                        </div>

                        {eachElement?.mustUse && (
                          <div className="colorCode">
                            <div
                              className={
                                "relationDependancy relationColorMandatory"
                              }
                            ></div>
                            <span className="clrCodeText">Must have</span>
                          </div>
                        )}
                        {/* <div id={supText.componentType+index} className="typeText" data-assetId={assetId}  onDoubleClick={ */}
                        <div
                          // id={"text" + counter++}
                          className="claim-match-text"
                          // data-assetid={props.assetId}
                          draggable={true}
                          onDragStart={(e) =>
                            props.sendAtomDetails2Canvas({
                              id: e.target.parentNode.id || e.target.id,
                              type: "atomText",
                              data: eachElement?.textElementContent,
                            })
                          }
                        >
                          {parse(eachElement?.textElementContent)}
                        </div>
                      </div>
                    </div>
                  )
              )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default ModuleTextElementAccordion;
