import React, { useState } from "react";
import "../../styles/components/ToolBarLeftPanel.scss";
import { useTranslation } from "react-i18next";
import searchIcon from "../../images/search.svg";
import warning from "../../images/warning.svg";

import ClearText from "../../images/ClearText.svg";

import { ADDHEADING, ADDSUBHEADING, ADDBODY } from "../../Uitls/ComponentType";
import forwardArrow2 from "../../images/ForwardArrow2.svg";
const TextWindow = (props) => {
  const { handleCloseElement, getLayerDetails } = props;
  const [searchInputValue, setSearchInputValue] = useState("");
  const { t } = useTranslation();

  const handleChangeSearch = (event) => {
    setSearchInputValue(event.target.value);
  };

  return (
    <>
      <div className="textWindow">
        <div className="textWindowHeader align-items-center d-flex justify-content-between">
          <div className=" titletextWindow d-flex">
            <span>{t("toolbarLeftPanel.titleText")}</span>
            {/* <img alt="" src={warning} className="img-fluid pl-2" /> */}
          </div>

          <div className="pr-2">
            <img
              src={ClearText}
              alt=""
              className="clearTextIcon clearText"
              onClick={() => handleCloseElement("Text")}
            />
          </div>
        </div>
        {/* <img
          src={forwardArrow2}
          alt="forwardArrow2"
          className="textArrow img-fluid"
        /> */}
        <div className="addTextscroll">
          <div
            id="addHeading"
            className="addHeading mb-3 mr-3"
            draggable={true}
            onDragStart={(e) => {
              getLayerDetails(e.target.id, ADDHEADING);
            }}
          >
            <span>{t("Heading")}</span>
          </div>
          <div
            id="addSubHeading"
            className="addSubHeading  mb-3 mr-3"
            draggable={true}
            onDragStart={(e) => {
              getLayerDetails(e.target.id, ADDSUBHEADING);
            }}
          >
            <span>{t("Sub Heading")}</span>
          </div>
          <div
            id="addBody"
            className="addBody  mb-3 mr-3"
            draggable={true}
            onDragStart={(e) => getLayerDetails(e.target.id, ADDBODY)}
          >
            <span>{t("Body")} </span>
          </div>
          {/* <hr className="horizontalLine" />
          <div className="d-flex searchInput">
            <img src={searchIcon} alt="" className="searchIconText" />
            <input
              value={searchInputValue}
              id="global-search-module"
              type="text"
              name="searchBrandInput"
              placeholder={t("Search")}
              className="searchInputTextWindow"
              onChange={handleChangeSearch}
              autoComplete="off"
            />
          </div>

          <div className="addHeading addHeadingBold mb-3 mr-3">
            {" "}
            <span>{t("toolbarLeftPanel.addheading2")} </span>
          </div>
          <div className="addSubHeading addSubHeadingBold mb-3 mr-3">
            <span>{t("Sub Heading")}</span>
          </div>
          <div className="addBody addBodyNormal mb-3 mr-3">
            {" "}
            <span> {t("Body")}</span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TextWindow;
