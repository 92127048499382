import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import "../styles/components/ContextMenuZindex.scss";

const ContextMenuLayer = ({
  selectedComponent,
  layerCompData,

  contextMenuvisbility,
  setContextMenuvisibility,
  contextMenuPosition,
  handleDeleteallKeyframe,
  handleDeleteLayer,
  layercontextMenuRef,
  playPause,
  multiSelectedComps,
  setSelectedComponent,
  deletionLayer,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const contextMenuDiv = layercontextMenuRef.current;
      if (contextMenuDiv && !contextMenuDiv.contains(event.target)) {
        setContextMenuvisibility(false);
      } else {
        event.stopPropagation();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [layercontextMenuRef, setContextMenuvisibility]);

  return (
    <div>
      <div
        className="context-menulayer"
        //style={style}
        style={{
          top: `${contextMenuPosition.y}px`,
          left: `${contextMenuPosition.x}px`,
        }}
      >
        <div
          className={
            selectedComponent?.keyFrames?.length > 0
              ? "layercontextMenuText "
              : "layercontextMenuText deletekeyframe"
          }
          onClick={handleDeleteallKeyframe}
        >
          {t("Delete all key frame")}
        </div>
        <div
          className="layercontextMenuText deletelayer"
          onClick={(e) => {
            handleDeleteLayer(e);
          }}
        >
          {t("Delete layer")}
        </div>
      </div>
    </div>
  );
};

export default ContextMenuLayer;
