// export const froalaEditor = (function () {
//   return {
//     config: function (options, onSupClick) {
//       return {
//         key: "pe1G2wD1D1A2B1A5B5nDe1YRTYKd1Dd1f1JVVD1VJKKYLMPvC2B1F1G1B4D4E3B1C8E7==",
//         attribution: false,
//         pastePlain: true,
//         toolbarInline: true,

//         keepFormatOnDelete: true,
//         placeholderText: options.placeHolder,
//         pluginsEnabled: [
//           "align",
//           "link",
//           "lists",
//           "fontFamily",
//           "fontSize",
//           "lineHeight",
//           "format",
//           "colors",
//         ],

//         //   toolbarInline: options.shouldHaveToolbar,
//         immediateReactModelUpdate: options.shouldUpdateImmediatly || false,
//         language: "ro",
//         color: options.color,
//         // pastePlain: true,
//         toolbarButtons: [
//           "bold",
//           "italic",
//           "underline",
//           "strikeThrough",
//           "subscript",
//           "superscript",
//           "textColor",
//           "backgroundColor",
//           "fontSize",
//           "fontFamily",
//           "alignLeft",
//           "alignCenter",
//           "alignRight",
//           // "lineHeight",
//           "formatOL",
//           "formatUL",
//           "insertLink",
//           "undo",
//           "redo",
//         ],
//         fontSize: [9, 10, 12, 14, 18, 24, 29, 32, 50],

//         // lineHeights: {
//         //   1: "1",
//         //   1.25: "1.25",
//         //   2: "2",
//         //   3: "3",
//         //   5: "5",
//         // },
//         shortcutsEnabled: [],
//         shortcuts: false,
//         initOnClick: false,
//         height: "100%",
//         events: {
//           initialized: function (e) {
//             var editor = this;
//             // editor.events.focus();
//             if (!options.contentEditable) {
//               editor.edit.off();
//             }
//             //editor.toolbar.show();
//             editor.events.on(
//               "drop",
//               function (e) {
//                 e.preventDefault();
//                 return false;
//               },
//               true
//             );

//             // this.events.on(
//             //   "keydown",
//             //   function (e) {
//             //     if (e.ctrlKey || e.metaKey) {
//             //       if (e.which === 88) {
//             //         e.preventDefault();
//             //         e.stopPropagation();
//             //         e.stopImmediatePropagation();
//             //         return false;
//             //       }
//             //     }
//             //   },
//             //   true
//             // );
//           },
//         },
//       };
//     },
//   };
// })();

export const froalaEditor = (function () {
  return {
    config: function (options, onSupClick) {
      return {
        key: "pe1G2wD1D1A2B1A5B5nDe1YRTYKd1Dd1f1JVVD1VJKKYLMPvC2B1F1G1B4D4E3B1C8E7==",
        attribution: false,
        pastePlain: true,
        toolbarInline: true,

        keepFormatOnDelete: true,
        placeholderText: options.placeHolder,
        pluginsEnabled: [
          "align",
          "link",
          "lists",
          "fontFamily",
          "fontSize",
          "lineHeight",
          "format",
          "colors",
        ],

        //   toolbarInline: options.shouldHaveToolbar,
        immediateReactModelUpdate: options.shouldUpdateImmediatly || false,
        language: "ro",
        color: options.color,
        // pastePlain: true,
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "textColor",
              "backgroundColor",
              "fontSize",
              "fontFamily",
              "insertLink",
              "undo",
              "redo",
            ],
            align: "left",
            margin: 0,
            buttonsVisible: 19,
          },
          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "alignRight",
              "alignJustify",
              "formatOL",
              "formatUL",
              "paragraphFormat",
              "paragraphStyle",
              "lineHeight",
              "outdent",
              "indent",
              "quote",
            ],
            align: "left",
            buttonsVisible: 0,
          },
        },
        fontSize: [9, 10, 12, 14, 18, 24, 29, 32, 50],

        // lineHeights: {
        //   1: "1",
        //   1.25: "1.25",
        //   2: "2",
        //   3: "3",
        //   5: "5",
        // },
        shortcutsEnabled: [],
        shortcuts: false,
        initOnClick: false,
        height: "100%",
        events: {
          initialized: function (e) {
            var editor = this;
            // editor.events.focus();
            if (!options.contentEditable) {
              editor.edit.off();
            }
            //editor.toolbar.show();
            editor.events.on(
              "drop",
              function (e) {
                e.preventDefault();
                return false;
              },
              true
            );

            // this.events.on(
            //   "keydown",
            //   function (e) {
            //     if (e.ctrlKey || e.metaKey) {
            //       if (e.which === 88) {
            //         e.preventDefault();
            //         e.stopPropagation();
            //         e.stopImmediatePropagation();
            //         return false;
            //       }
            //     }
            //   },
            //   true
            // );
          },
        },
      };
    },
  };
})();
