import React, { useState, useEffect, useContext } from "react";
import { Form, Formik } from "formik";
import { Redirect, useHistory } from "react-router-dom";
import { authenticate } from "../../Uitls/index";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@material-ui/core";
import Pool from "../../Uitls/Userpools";
import PasswordStrengthIndicator from "../../components/Login/passwordIndicator";
import BackgroundContent from "../../components/Login/BackgroundContent";
import { getSession } from "../../Uitls";
import indegeneLogo from "../../images/IndegeneLogo.svg";
import "../../styles/pages/Login.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PasswordChangeSuccess } from "../../components/toastify";
// import CancelIcon from '@material-ui/icons/Cancel';

import ValueContext from "../../ContextAPi/appContext";

const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const upperCase = /[A-Z]/;

const Home = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  // const [formErrorMsg,setFormErrorMsg]=useState({
  //   emailError:'Invalid email address',
  //   passwordError:'Entered password does not match'
  // })
  const [formErrorMsg, setFormErrorMsg] = useState(
    "Incorrect username or password"
  );

  const [loginError, setLoginError] = useState(false);
  const [showErrorMsg, setshowErrorMsg] = useState(false);
  const [btnStatus, setBtnStatus] = useState(true);
  const [stage, setStage] = useState(0); // 1 = email stage, 2 = otp stage
  const [email, setEmail] = useState("");
  const [showForgotErrorMsg, setShowForgotErrorMsg] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState({
    minChar: null,
    number: null,
    specialChar: null,
    upperCase: null,
  });
  const [showHidePassword, setShowHidePassword] = useState(false);
  /*  const [showHideLoginPassBtn, setShowHideLoginPassBtn] = useState(false);
  const [showHideLoginPassword, setShowHideLoginPassword] = useState(false); */
  const [showHidePassBtn, setShowHidePassBtn] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const appData = useContext(ValueContext);

  let history = useHistory();
  useEffect(() => {
    getSession()
      .then((session) => {
        // history.push("/dashboard");
        history.push("/landingPage");
      })
      .catch((err) => {
        console.log("");
      });
    console.log(email);
    console.log(passwordValidity);
  }, []);

  const validationSchemaLogin = yup.object().shape({
    email: yup
      .string()
      .email(i18next.t("login.loginFormLabel.emailInvaliderr"))
      .required(i18next.t("login.loginFormLabel.emailerr")),
    password: yup
      .string()
      .required(i18next.t("login.loginFormLabel.passworderr")),
  });
  const { t } = useTranslation();

  const validateLoginPage = (values) => {
    const errors = {};
    if (!values.email) {
      setLoginError(false);
      setBtnStatus(true);
      errors.email = i18next.t("login.loginFormLabel.emailerr");
    } else {
      if (!values.password) {
        setBtnStatus(true);
      } else {
        setBtnStatus(false);
      }
    }
    if (!values.password) {
      setLoginError(false);
      setBtnStatus(true);
      errors.password = i18next.t("login.loginFormLabel.passworderr");
    } else {
      if (!values.email) {
        setBtnStatus(true);
      } else {
        setBtnStatus(false);
      }
    }
    return errors;
  };

  const validationSchemaSendEmail = yup.object().shape({
    resetEmail: yup
      .string()
      .email(i18next.t("login.loginFormLabel.emailInvaliderr"))
      .required(i18next.t("login.loginFormLabel.emailerr")),
  });
  const validateOtp = (values) => {
    console.log(values);
    const errors = {};
    if (!values.verificationCode) {
      errors.verificationCode = i18next.t("login.enterOtp.verfiCodeErr");
    }
    return errors;
  };
  const validate = (values) => {
    const errors = {};
    if (!values.verificationCode) {
      errors.verificationCode = i18next.t("login.resetPassword.verfiCodeErr");
    }
    if (!values.resetPassword) {
      errors.resetPassword = i18next.t("login.resetPassword.newpasswordErr");
      setPasswordValidity({
        minChar: false,
        number: false,
        specialChar: false,
        upperCase: false,
      });
    } else if (values.resetPassword) {
      setPasswordValidity({
        minChar:
          values.resetPassword.length >= 6
            ? true
            : ((errors.resetPassword = i18next.t(
                "login.resetPassword.sixCharErr"
              )),
              false),
        number: isNumberRegx.test(values.resetPassword)
          ? true
          : ((errors.resetPassword = i18next.t(
              "login.resetPassword.alphanumericErr"
            )),
            false),
        specialChar: specialCharacterRegx.test(values.resetPassword)
          ? true
          : ((errors.resetPassword = i18next.t(
              "login.resetPassword.specialCharErr"
            )),
            false),
        upperCase: upperCase.test(values.resetPassword)
          ? true
          : ((errors.resetPassword = i18next.t(
              "login.resetPassword.capCharErr"
            )),
            false),
      });
    }

    return errors;
  };
  const getUser = (value) => {
    setEmail(value);
    const getUserEmail = value;
    return new CognitoUser({
      Username: getUserEmail.toLowerCase(),
      Pool,
    });
  };
  const passwordReset = () => {
    setStage(1);
  };
  const backToSignIn = () => {
    setStage(0);
  };

  return (
    <div className="container-fluid loginBg">
      <div className="row align-itmes-center">
        <div className="col-md-6 col-xl-8">
          <BackgroundContent />
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="signInBox">
            <div className="loginContainer">
              {stage === 0 && (
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={validationSchemaLogin}
                  validate={validateLoginPage}
                  onSubmit={(values) => {
                    authenticate(values.email, values.password)
                      .then((data) => {
                        setLoggedIn(true);
                        //prompt disble
                        appData.updateGenericInfo({
                          ...appData?.genericInfo,
                          rightUpdaed: false,
                        });
                      })
                      .catch((err) => {
                        setErrorMsg(err.message);
                        setshowErrorMsg(true);
                        setLoginError(true);
                        setTimeout(() => {
                          setshowErrorMsg(false);
                          setErrorMsg("");
                        }, 10000);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    status,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="login-form">
                      <div className=" login-title" data-testid="login">
                        {t("login.loginFormLabel.login")}
                      </div>
                      <div className="position-relative">
                        <TextField
                          id="email"
                          name="email"
                          type="text"
                          label={t("login.loginFormLabel.username")}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="mb-3"
                          fullWidth={true}
                          value={values.email}
                          helperText={
                            errors.email && touched.email && errors.email
                          }
                          error={
                            (errors.email && touched.email) || loginError
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="position-relative ">
                        <TextField
                          id="password"
                          name="password"
                          type="password"
                          label={t("login.loginFormLabel.password")}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth={true}
                          type="password"
                          value={values.password}
                          helperText={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                          error={
                            (errors.password && touched.password) || loginError
                              ? true
                              : false
                          }
                        />
                      </div>
                      {loginError ? (
                        <div className="error-text">{errorMsg}</div>
                      ) : (
                        ""
                      )}
                      <div>
                        <label
                          className="rounded-checkbox"
                          htmlFor="savePassword"
                        >
                          {t("login.loginFormLabel.remeber")}
                          <input type="checkbox" id="savePassword" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={btnStatus}
                          // disabled={false}
                        >
                          {t("login.loginFormLabel.login")}
                        </button>
                      </div>
                      <div className="form-group text-center">
                        <button
                          onClick={passwordReset}
                          className="btn btn-link link-text"
                        >
                          {t("login.loginFormLabel.forgot")}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              {stage === 1 && (
                <Formik
                  initialValues={{ resetEmail: "" }}
                  validationSchema={validationSchemaSendEmail}
                  onSubmit={(values) => {
                    const user = values.resetEmail;
                    getUser(user).forgotPassword({
                      onSuccess: (data) => {},
                      onFailure: (err) => {
                        setErrorMsg(err.message);
                        setShowForgotErrorMsg(true);
                        setTimeout(() => {
                          setShowForgotErrorMsg(false);
                          setErrorMsg("");
                        }, 5000);
                      },
                      inputVerificationCode: (data) => {
                        setStage(2);
                      },
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    status,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      {showForgotErrorMsg ? (
                        <div
                          className="alert alert-warning alert-text"
                          role="alert"
                        >
                          {errorMsg}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="login-title">
                        {t("login.forgotPassword.forgotPass")}
                      </div>
                      <p className="forgotText mb-4">
                        {t("login.forgotPassword.forgotText")}
                      </p>
                      <TextField
                        id="resetEmail"
                        label={t("login.loginFormLabel.username")}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-3"
                        fullWidth={true}
                        value={values.resetEmail}
                        helperText={
                          errors.resetEmail &&
                          touched.resetEmail &&
                          errors.resetEmail
                        }
                        error={
                          errors.resetEmail && touched.resetEmail ? true : false
                        }
                      />
                      <div className="form-group">
                        <p className="forgotText2">
                          {t("login.forgotPassword.forgotText2")}
                        </p>
                        <div className="form-group text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {t("login.forgotPassword.resetLabel")}
                          </button>
                        </div>
                        <div className="form-group text-center">
                          <button
                            onClick={backToSignIn}
                            className="m-auto btn btn-link link-text"
                          >
                            {t("login.forgotPassword.backToSignIn")}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              {stage === 2 && (
                <>
                  <div className="invalidOtp-error">
                    {showErrorMsg ? (
                      <div
                        className="alert alert-warning alert-text"
                        role="alert"
                      >
                        {errorMsg}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="resetPassword-Title">
                    {t("login.resetPassword.resetPassLabel")}
                  </div>

                  <div className="pwdStrengthIndicator">
                    {email && (
                      <>
                        <h1 className="forgotTitle mb-3">
                          {t("login.resetPassword.regEmail")}
                        </h1>
                        <p className="emailText">{email}</p>
                      </>
                    )}
                    <PasswordStrengthIndicator validity={passwordValidity} />
                  </div>
                  <Formik
                    initialValues={{
                      verificationCode: "",
                      resetPassword: "",
                    }}
                    validate={validate}
                    // validationSchema={validationSchemaReset}
                    onSubmit={(values) => {
                      getUser(email).confirmPassword(
                        values.verificationCode,
                        values.resetPassword,
                        {
                          onSuccess: (data) => {
                            setStage(0);
                            toast.success(
                              <PasswordChangeSuccess
                                id={null}
                                status="Success"
                                assetname="Password Change Successful"
                              />
                            );
                          },
                          onFailure: (err) => {
                            setErrorMsg(err.message);
                            setshowErrorMsg(true);
                            setTimeout(() => {
                              setshowErrorMsg(false);
                              setErrorMsg("");
                            }, 5000);
                          },
                        }
                      );
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      status,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <Form
                        onSubmit={handleSubmit}
                        className="resetPasswordForm"
                      >
                        <h5 className="newPwdText">
                          {t("login.resetPassword.newPassText")}
                        </h5>
                        <div className="resetPassword-fields">
                          <TextField
                            id="verificationCode"
                            label={t("login.resetPassword.verificationCode")}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="mb-3"
                            fullWidth={true}
                            value={values.verificationCode}
                            helperText={
                              errors.verificationCode &&
                              touched.verificationCode &&
                              errors.verificationCode
                            }
                            error={
                              errors.verificationCode &&
                              touched.verificationCode
                                ? true
                                : false
                            }
                          />
                          <FormControl fullWidth={true}>
                            <TextField
                              type={showHidePassword ? "text" : "password"}
                              id="resetPassword"
                              label={t("login.resetPassword.newPassword")}
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="mb-3"
                              // data-testId={text-input}
                              fullWidth={true}
                              value={values.resetPassword}
                              helperText={
                                errors.resetPassword &&
                                touched.resetPassword &&
                                errors.resetPassword
                              }
                              error={
                                errors.resetPassword && touched.resetPassword
                                  ? true
                                  : false
                              }
                            />
                            <button
                              type="button"
                              className="btn btn-link shoHidePassword"
                              onClick={() =>
                                setShowHidePassword(!showHidePassword)
                              }
                            >
                              {showHidePassword ? "Hide" : "Show"}
                            </button>
                          </FormControl>
                        </div>
                        <div className="otpExpire">
                          <button
                            type="button"
                            className="m-auto btn btn-link link-text resendOtp"
                            onClick={(values) => {
                              const user = email;
                              getUser(user).forgotPassword({
                                onSuccess: (data) => {},
                                onFailure: (err) => {},
                              });
                            }}
                          >
                            {t("login.enterOtp.resendOtp")}
                          </button>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {t("login.resetPassword.changePassword")}
                          </button>
                        </div>
                        <div className="form-group text-center">
                          <button
                            onClick={backToSignIn}
                            className="m-auto btn btn-link link-text"
                          >
                            {t("login.forgotPassword.backToSignIn")}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </div>
            <div className="loginIndegenLogo">
              <img
                src={indegeneLogo}
                alt="Indegene Logo"
                className="indegene-img"
                width="150"
              />
            </div>
          </div>
        </div>
      </div>
      {loggedIn && <Redirect to="/landingPage" />}
    </div>
  );
};

export default Home;
