import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Divider,
  Button,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import searchIcon from "../../images/search.svg";
import { FormStyle } from "../../styles/pages/ContentStudio/WithStylesObjs";
import "../../styles/pages/ContentStudio/DynDialogue.scss";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import DropDownWithLabel from "../../components/DropDownWithLabel.js";
import {
  getBrandsAndCampaigns,
  getCognitoSession,
  getCognitoIndentityCredentials,
  getAllTempltesList,
  searchBanners,
  getNewBrandsAndcampaigns,
} from "../../services/apis";
import DropDownWithSearch from "../../Uitls/DropDownWithSearch";
import { toast } from "react-toastify";
import ValueContext from "../../ContextAPi/appContext";
import { isoCountryTableName, awsRegion } from "../../Uitls/Util";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import { InputAdornment } from "@material-ui/core";
import backArrowIcon from "../../images/backArrowico.svg";

import GoToTeplatesContainer from "../../components/BannerManager/GoToTeplatesContainer";
import "./TemplateM.scss";

import "../../styles/pages/Banners/BannerManager.scss";

import GotoRecentlyViewed from "../../components/BannerManager/gotoRecentlyViewed";
import MultipleRvcvModal from "../../components/BannerManager/MultipleRvcvModal";
import NobannerOrTemplateFoundBg from "../../images/noresultsfound.svg";
import warningBlue from "../../images/warningBlue.png";
var dynamo = require("dynamodb");

// banner data goes here
function TemplateForms(props) {
  const initialFvalues = {
    brand: "",
    campaign: "",
    market: "",
    templateNameInput: "",
    canReset: false,
  };

  const appData = useContext(ValueContext);
  const { t } = useTranslation();
  const {
    classes,
    preDefined,
    setPredefined,
    customTemp,
    setCustomBannerSize,
  } = props;
  const [values, setValues] = useState(initialFvalues);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [templateTypes, setTemplateTypes] = useState(["Static", "Animated"]);

  const [genericInfo, setGenericInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
    isAnimated: "",
  });
  const [dropdownInfo, setDropdownInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
    isAnimated: "",
    // templateNameInput:''
  });

  const [loading, setLoading] = useState(false);
  const [resultHeader, setResultHeader] = useState({
    cardsCount: 0,
    searched: false,
    // resultTitle: (function(){  var count = this.cardsCount;
    //   return `Recently Viewed(${count})`})()
    resultTitle: `Recently Viewed (0)`,
  });
  const [responseData, setResponseData] = useState([]);
  const [search, setSearch] = useState("recent");

  const [createdByMe, setCreatedByMe] = useState({ status: "me", data: [] });

  const [hide3dotIcon, setHide3dotIcon] = useState(true);

  /////Code copied from bannerform.js for Banner flow starts//////
  const [bannerCount, setBannerCount] = useState(0);
  const [generateRVCV, setGenerateRVCV] = useState(false);
  const [disableProceedbut, setDisableProceedbut] = useState(false);
  const [openRVCvmodal, setOpenRVCvmodal] = useState(false);
  const [arrcheckboxSelection, setArrcheckboxSelection] = useState([]);
  const [selectMultipleBanner, setSelectMultipleBanner] = useState([]);

  const generateRvfile = () => {
    setGenerateRVCV(true);
    setHide3dotIcon(false);
    let filterAnimatedBanner = [...responseData];
    let filterBanners = filterAnimatedBanner.filter(
      (element) => element.isAnimated === true
    );
    setResponseData(filterBanners);
  };

  const removeSearchresultCard = (deleteId) => {
    setLoading(true);
    fetchAllTemplates("delete", createdByMe.status);
    // let filteredSearchResults = responseData.filter(
    //   (cardId) => cardId.id !== deleteId
    // );
    // setResponseData(filteredSearchResults);
    // setResultHeader({
    //   cardsCount: filteredSearchResults.length,
    //   searched: true,
    //   resultTitle: `Search Results (${filteredSearchResults.length})`,
    // });
  };
  const openGenerateRVFileModal = (e) => {
    e.preventDefault();
    setOpenRVCvmodal(true);
  };
  const getDatafromChildforRVCV = (e) => {};
  const backArrowclick = (e) => {
    setHide3dotIcon(true);
    setGenerateRVCV(false);
    getSearchedTemplates();
  };

  /////Code copied from bannerform.js for Banner flow ends//////

  React.useEffect(() => {
    setGenericInfo({ ...genericInfo, campaign: "" });
  }, [genericInfo.brand]);

  const fetchAllTemplates = (origin, createdByMeStatus) => {
    setLoading(true);
    setCreatedByMe({ status: "me", data: [] });
    setResponseData([]);
    getAllTempltesList(
      props.banner ? "BANNER" : "TEMPLATE",
      (response, err, errCode) => {
        //  setLoading(false);
        if (err) {
          setLoading(false);
          const errCoponent = errComponentToRender(errCode);
        } else {
          // let banner = props.banner ? "Banner" : "Template";
          // //DeleteNavigate
          setLoading(false);
          const data = response.data;
          // if (banner !== data[0].assetType) return;//DeleteNavigate
          setCreatedByMe({
            ...createdByMe,
            status: origin === "delete" ? createdByMeStatus : "me",
            data: data.filter((template) => template?.madeBy === "me"),
          });
          setResponseData(data.filter((template) => template?.madeBy !== "me"));
          setSearch("");
          // if (props.recentlyViewedData.lockStatus !== "locked") {
          //   setCreatedByMe({
          //     ...createdByMe,
          //     status: origin === "delete" ? createdByMeStatus : "me",
          //     data: data.filter((template) => template?.madeBy === "me"),
          //   });
          // }
        }
      }
    );
  };
  React.useEffect(() => {
    handleReset("useEffect");
    setLoading(true);
    appData.setFlagEditFlow(false);
    appData.setFlagEditBanner(false);
    fetchBrandsAndCampaigns();
    fetchMarketData();
    setCreatedByMe({ status: "me", data: [] });
    setResponseData([]);
    fetchAllTemplates();
  }, [props.banner]);

  const handleChangeField = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
      canReset: true,
    });
    // settemplateNameInput(event.target.value);
  };

  const handleReset = (origin) => {
    setHide3dotIcon(true);
    setValues({
      ...initialFvalues,
    });
    setGenericInfo({
      brand: "",
      campaign: "",
      market: "",
    }); //now
    setSelectedMarket(""); //now
    // settemplateNameInput("");
    setDropdownInfo({ brand: "", campaign: "", market: "", isAnimated: "" });
    setResponseData([]);
    setSearch("recent");
    // setgetRecentlyViewed([]);
    setResultHeader({
      cardsCount: bannerCount,
      searched: false,
      resultTitle: `Recently Viewed (${bannerCount})`,
    });
    if (origin !== "useEffect") fetchAllTemplates();
    setGenerateRVCV(false);
  };

  const fetchBrandsAndCampaigns = () => {
    getNewBrandsAndcampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        toast.error(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          if (appData?.banner) {
            data.shift();
            setBrandsAndCampaigns(data);
          } else {
            setBrandsAndCampaigns(data);
          }
        }
      }
    });
  };

  const getBrandsToPopulate = (origin) => {
    if (!brandsAndCampaigns || brandsAndCampaigns.length <= 0) {
      return [];
    }
    let brandNameArray = [];
    [...brandsAndCampaigns].forEach((object) => {
      if (origin === "basedOnNew") {
        if (object.brandName !== "— Not Brand Specific —")
          brandNameArray = [...brandNameArray, object.brandName];
      } else brandNameArray = [...brandNameArray, object.brandName];
    });
    return brandNameArray;
  };
  //Returns Campaign list to populate in Drop down
  const getCampaignListToPopulate = (brand) => {
    if (brand) {
      const campaigns = brandsAndCampaigns?.filter((object) => {
        return object.brandName === brand;
      })[0].campaigns;

      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns?.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }

    return [];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      canReset: true,
    });

    setGenericInfo({ ...genericInfo, [name]: value });
    if (name === "templateType") {
      setDropdownInfo({
        ...dropdownInfo,
        isAnimated: value === "Animated" ? true : false,
      });
    }
    if (brandsAndCampaigns) {
      brandsAndCampaigns.map((brand) => {
        if (brand.brandName === value) {
          // appData.setBrandId(brand.brandId);
          setDropdownInfo({ ...dropdownInfo, brand: brand.brandId });
        }
        brand.campaigns.map((campaign) => {
          if (campaign.campaignName === value)
            // appData.setCampaignId(campaign.campaignId);
            setDropdownInfo({ ...dropdownInfo, campaign: campaign.campaignId });
        });
      });
    }
  };

  const fetchMarketData = () => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setMarketDataSource(marketData);
                }
              }
            }
          });
        });
      }
    });
  };
  const handleMarketChange = (market) => {
    // const { name, value } = event.target;
    if (market) {
      setSelectedMarket(market);
      setValues({
        ...values,
        market: market.code,
        canReset: true,
      });
      // setGenericInfo({ ...genericInfo, 'market': market.code })
      setDropdownInfo({ ...dropdownInfo, market: market.code });
    }
  };
  const categoriesErr = (value) => {
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  };

  const handleEnterKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.key === "Enter") {
      getSearchedTemplates();
    }
  };
  const getSearchedTemplates = (e) => {
    setLoading(true);
    // e.stopPropagation();
    if (
      values.brand ||
      values.campaign ||
      values.market ||
      values.templateType ||
      (values.templateNameInput && values.templateNameInput.trim())
    ) {
      const options = {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "en-US,en;q=0.9",
          "Access-Control-Allow-Origin": "*",
        },
      };
      setResponseData([]);
      //  setLoading(true);
      setResultHeader({
        cardsCount: 0,
        searched: true,
        resultTitle: `Search Results...`,
      });
      searchBanners(
        props.banner ? "BANNER" : "TEMPLATE",
        dropdownInfo,
        encodeURIComponent(values.templateNameInput),
        async (response, err, errorCode) => {
          const data = await response.data;
          // setLoading(false);
          if (err) {
            setLoading(false);
            const errCoponent = errComponentToRender(errorCode);
            categoriesErr(errCoponent);
          } else {
            setLoading(false);
            setCreatedByMe({
              ...createdByMe,
              status: "me",
              data: data.filter((template) => template?.madeBy === "me"),
            });
            setResponseData(
              data.filter((template) => template?.madeBy !== "me")
            );
            setSearch("search");
            // appData.setCardData({
            //   cards:data,
            //   search:"search"
            // }
            // );
            // setgetRecentlyViewed([]);
          }
        }
      );
    } else {
      const errCoponent = errComponentToRender(602);
      categoriesErr(errCoponent);
      setLoading(false);
    }
  };
  const showSelected = (me) => {
    setCreatedByMe({ ...createdByMe, status: me === "me" ? "me" : false });
  };

  const campaigndropDownWidth = useRef(null);
  const branddropDownWidth = useRef(null);

  const marketdropDownWidth = useRef(null);
  const lockUnlockBannerAtFE = (assetId, lockStatus) => {
    let tempCreatedByMe = JSON.parse(JSON.stringify(createdByMe));
    tempCreatedByMe.data[
      tempCreatedByMe?.data?.findIndex((elem) => elem.id === assetId)
    ].lockStatus = lockStatus;
    setCreatedByMe(tempCreatedByMe);
  };

  return (
    <>
      {loading && (
        <>
          <div className="modal-backdrop fade show"> </div>
          <CircularProgress size="30px" className="customLoader" />{" "}
        </>
      )}
      <form className="bannerManagerSearch">
        <div className="row mb-4 bannerSearch ">
          <Col className="col-md-12 banner-nameCol">
            <div className="d-flex align-items-center templateSearchRow">
              <img
                src={searchIcon}
                alt=""
                className="searchIcon"
                onClick={getSearchedTemplates}
              />
              <input
                value={values.templateNameInput}
                id="global-search-module"
                type="text"
                name="templateNameInput"
                placeholder={
                  props.banner ? "Enter Banner Name" : "Enter Template Name"
                }
                className="globalSearchInputNav"
                onChange={handleChangeField}
                autoComplete="off"
                onKeyPress={handleEnterKeypress}
              />
            </div>
          </Col>
        </div>
        <div className="row align-items-center bannerMngrSrchDevider">
          <div className="col-md-2 pr-2 ipFlds" ref={branddropDownWidth}>
            <DropDownWithLabel
              options={{
                value: genericInfo.brand,
                name: "brand",
                label: t("bannerManager.brandName"),
                items: getBrandsToPopulate(),
                handleChange: handleChange,
              }}
              menuWidth={
                branddropDownWidth.current?.getBoundingClientRect().width *
                  0.95 || undefined
              }
              // style={{ marginTop: "10px" }}
              className="custonScrollBar"
              id="branfieldBanner"
            />
          </div>
          <div className="col-md-2 pr-2 " ref={marketdropDownWidth}>
            <DropDownWithSearch
              options={{
                value: selectedMarket,
                name: "market",
                label: t("bannerManager.market"),
                items: marketDataSource,
                handleChange: handleMarketChange,
              }}
              className="custonScrollBar"
              menuWidth={
                marketdropDownWidth.current?.getBoundingClientRect().width *
                  0.95 || undefined
              }
            />
          </div>
          <div className="col-md-2 pr-2 ipFlds">
            <DropDownWithLabel
              options={{
                value: genericInfo.templateType || "",
                name: "templateType",
                label: props.banner
                  ? "Banner Type"
                  : t("bannerManager.templateType"),
                items: templateTypes,
                handleChange: handleChange,
              }}
            />
          </div>
          <div className="col-md-4 pr-2 ipFlds" ref={campaigndropDownWidth}>
            <DropDownWithLabel
              options={{
                value:
                  genericInfo?.brand === "— Not Brand Specific —"
                    ? "-"
                    : genericInfo.campaign || "",
                name: "campaign",
                label: t("bannerManager.campaign"),
                items: getCampaignListToPopulate(genericInfo.brand),
                handleChange: handleChange,
              }}
              menuWidth={
                campaigndropDownWidth.current?.getBoundingClientRect().width *
                  0.95 || undefined
              }
            />
          </div>
          <div className="col-md-2">
            <div className="d-flex justify-content-between">
              <Button
                className="searchBtn searchBtnFlex"
                onClick={getSearchedTemplates}
              >
                {t("bannerForms.search")}
              </Button>

              <button
                disabled={
                  !Object.entries(values).some(
                    (value) => value[0] !== "canReset" && value[1]
                  ) &&
                  search !== "search" &&
                  !Object.entries(values).some(
                    (value) => value[0] !== "canReset" && value[1]
                  )
                }
                type="button"
                className={`btn btn-link searchBtnReset p-0 ${
                  values.canReset ? classes.ResetKey : classes.Unabled
                }`}
                onClick={values.canReset ? handleReset : undefined}
              >
                {t("RESET")}
              </button>
            </div>
          </div>
        </div>
        {/* <div className="row generateRVCVrow d-none">
          <div className="col-md-10 recentlyViewText">
            {generateRVCV ? (
              <div>
                {responseData.length > 0 && disableProceedbut ? (
                  <div>
                    <img
                      src={backArrowIcon}
                      alt="Arrow"
                      className="img-fluid backArrImage"
                      onClick={(e) => backArrowclick(e)}
                    />
                    <span style={{ paddingLeft: "11px" }}>
                      {t("Select Banners to Generate RV File")}
                    </span>
                  </div>
                ) : (
                  t("Select Banners to Generate RV File")
                )}
              </div>
            ) : (
              resultHeader.resultTitle
            )}
          </div>
          {search === "search" && (
            <div className="col-md-2 rvcvfileDiv">
              {resultHeader.cardsCount > 0 && generateRVCV ? (
                <div className="resetBtn">
                  <button
                    onClick={openGenerateRVFileModal}
                    className="btn btn-link"
                    disabled={!disableProceedbut}
                  >
                    {t("Proceed")}
                  </button>
                </div>
              ) : resultHeader.cardsCount > 0 ? (
                <div onClick={generateRvfile}>{t("Generate RV File")}</div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div> */}
        {console.log(
          "props.banner",
          props.banner,
          createdByMe?.data?.length,
          responseData?.length
        )}
        {props.banner ? (
          <div className="meAndOthers">
            <div className="row generateRVCVrow">
              <div className="col-md-10 recentlyViewText">
                {generateRVCV ? (
                  <div>
                    {responseData.length > 0 && disableProceedbut ? (
                      <div>
                        <img
                          src={backArrowIcon}
                          alt="Arrow"
                          className="img-fluid backArrImage"
                          onClick={(e) => backArrowclick(e)}
                        />
                        <span style={{ paddingLeft: "11px" }}>
                          {t("Select Banners to Generate RV File")}
                        </span>
                      </div>
                    ) : (
                      t("Select Banners to Generate RV File")
                    )}
                  </div>
                ) : (
                  <>
                    <div
                      className={`createdByMe ${
                        createdByMe.status ? "underLine" : ""
                      }`}
                      onClick={() => showSelected("me")}
                    >
                      {`Created by me (${createdByMe?.data?.length})`}
                    </div>
                    <div
                      className={`createdByMe ${
                        !createdByMe.status ? "underLine" : ""
                      }`}
                      onClick={() => showSelected("others")}
                    >
                      {`Created by Others (${responseData?.length})`}
                    </div>
                  </>
                )}
              </div>
              {search === "search" && (
                <div className="col-md-2 rvcvfileDiv">
                  {(createdByMe?.status ? createdByMe?.data : responseData)
                    ?.length && generateRVCV ? (
                    <div className="resetBtn">
                      <button
                        onClick={openGenerateRVFileModal}
                        className="btn btn-link"
                        disabled={!disableProceedbut}
                      >
                        {t("Proceed")}
                      </button>
                    </div>
                  ) : (createdByMe?.status ? createdByMe?.data : responseData)
                      ?.length ? (
                    <div onClick={generateRvfile}>{t("Generate RV File")}</div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="meAndOthers">
            <div
              className={`createdByMe ${createdByMe.status ? "underLine" : ""}`}
              onClick={() => showSelected("me")}
            >
              {`Created by me (${createdByMe?.data?.length})`}
            </div>
            <div
              className={`createdByMe ${
                !createdByMe.status ? "underLine" : ""
              }`}
              onClick={() => showSelected("others")}
            >
              {`Created by Others (${responseData?.length})`}
            </div>
          </div>
        )}

        <div className="TemplateContainerM">
          <div className="recentlyViewDivM">
            <div
              className="row recentlyCardsRowM recentlyVeiwedcrollBar "
              style={{ position: "relative" }}
            >
              {(createdByMe?.status ? createdByMe?.data : responseData)
                ?.length === 0 ? (
                <div className="searchEmptyRowBlk">
                  <div style={{ position: "relative" }}>
                    <img
                      src={NobannerOrTemplateFoundBg}
                      alt="thumbnail"
                      className="emptyBannerImage img-fluid"
                    ></img>
                    <div className="noBannerFoundTextBlk text-center">
                      <div className="noBannerFound align-items-center mt-4">
                        <img
                          src={warningBlue}
                          className="noBannerInfoIcon mr-2"
                          alt=""
                        />
                        {t(
                          `${
                            props.banner
                              ? "bannerForms.noBannerFound"
                              : "bannerForms.noTemplateFound"
                          }`
                        )}
                      </div>
                      <div className="tryKeyword">
                        {search === "search" && (
                          <>{t("bannerForms.tryusingKeyword")}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : !props.banner ? (
                <GoToTeplatesContainer
                  preDefined={preDefined}
                  setPredefined={setPredefined}
                  customTemp={customTemp}
                  setCustomBannerSize={setCustomBannerSize}
                  responseData={
                    createdByMe.status ? createdByMe.data : responseData
                  }
                  search={search}
                  setLoading={setLoading}
                  setResponseData={{
                    createdByMe: createdByMe,
                    setData: createdByMe.status
                      ? setCreatedByMe
                      : setResponseData,
                  }}
                  hide3dotIcon={hide3dotIcon}
                  getBrandsToPopulate={getBrandsToPopulate}
                />
              ) : (
                <GotoRecentlyViewed
                  responseData={
                    createdByMe.status ? createdByMe.data : responseData
                  }
                  createdByMe={createdByMe?.status}
                  search={search}
                  // getRecentlyViewedBanner={getRecentlyViewedBanner}
                  removeSearchresultCard={removeSearchresultCard}
                  setLoading={setLoading}
                  generateRVCV={generateRVCV}
                  disableProceedbut={disableProceedbut}
                  setDisableProceedbut={setDisableProceedbut}
                  selectMultipleBanner={selectMultipleBanner}
                  setSelectMultipleBanner={setSelectMultipleBanner}
                  arrcheckboxSelection={arrcheckboxSelection}
                  setArrcheckboxSelection={setArrcheckboxSelection}
                  hide3dotIcon={hide3dotIcon}
                  fetchAllTemplates={fetchAllTemplates}
                  lockUnlockBannerAtFE={lockUnlockBannerAtFE}
                />
              )}
            </div>
          </div>
        </div>
        {loading && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress size="30px" className="customLoader" />{" "}
          </>
        )}
      </form>
      {openRVCvmodal && (
        <MultipleRvcvModal
          openRVCvmodal={openRVCvmodal}
          setOpenRVCvmodal={setOpenRVCvmodal}
          selectMultipleBanner={selectMultipleBanner}
        />
      )}
    </>
  );
}

export default withStyles(FormStyle)(TemplateForms);
