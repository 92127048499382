import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BussinessRulesDialogue from "../Pages/ContentStudio/BussinessRulesDialogue";
import VeevaContentModal from "../Pages/ContentStudio/VeevaContentModal";
const CustomModal = (props) => {
  const {
    displayModal,

    hideModal,
    size,
    centered,
    dilogClassName,
    keyboard,
    activeCards,
    open,
    classes,
    close,
    modalOpenStatus,
    setOpenBussinessRules,
    setOpen,
    handleProceedbtn,
    sendModuleid,
    handleBackButton,
    cautionBackbtn,
    linkedModulesType,
    handleVeevaContentModal,
    openVeevaContentModal,
    setOpenVeevaContentModal,
    closeVeevaContentModal,
    handleBackbtnVeeva,
    setVeevaRules,
    veevaRules,
    veevaSelectedModuleId,
    setVeevaSelectedModuleId,
    veevaLoader,
    setVeevaLoader,

    values,
    setVeevaContentData,
    setActiveCard,
    setCantExistCards,
    setToggleCards,
    veevaContentData,
    setVeevaModuleCardData,
    veevaModuleCardData,
    handlesetveevamoduleCard,
    setValues,
    initialFvalues,
    setLoadingModules,
    responseData,
    setResponseData,
    setModules,
    modules,
  } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(displayModal);
  }, [displayModal]);

  const setModalHide = () => {
    setShow(false);
    hideModal(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={setModalHide}
        size={size ? size : ""}
        centered={centered ? centered : "false"}
        className={dilogClassName}
        backdrop={props.backdrop || "static"}
        keyboard={keyboard}
      >
        <Modal.Body>
          {" "}
          {linkedModulesType === "nextModule" ? (
            <BussinessRulesDialogue
              selectedModuleIds={activeCards.moduleId}
              modules={modules}
              setModules={setModules}
              open={open}
              classes={classes}
              close={close}
              modalOpenStatus={modalOpenStatus}
              setOpenBussinessRules={setOpenBussinessRules}
              activeCards={activeCards}
              setOpen={setOpen}
              handleProceedbtn={handleProceedbtn}
              sendModuleid={sendModuleid}
              handleBackButton={handleBackButton}
              cautionBackbtn={cautionBackbtn}
              linkedModulesType={linkedModulesType}
              setValues={setValues}
              initialFvalues={initialFvalues}
            />
          ) : (
            <VeevaContentModal
              modules={modules}
              setModules={setModules}
              genericInfo={props.genericInfo}
              responseData={responseData}
              setResponseData={setResponseData}
              linkedModulesType={linkedModulesType}
              closeVeevaContentModal={closeVeevaContentModal}
              handleBackbtnVeeva={handleBackbtnVeeva}
              setVeevaRules={setVeevaRules}
              veevaRules={veevaRules}
              veevaSelectedModuleId={veevaSelectedModuleId}
              setVeevaSelectedModuleId={setVeevaSelectedModuleId}
              veevaLoader={veevaLoader}
              setVeevaLoader={setVeevaLoader}
              sendModuleid={sendModuleid}
              setOpenVeevaContentModal={setOpenVeevaContentModal}
              values={values}
              setVeevaContentData={setVeevaContentData}
              setActiveCard={setActiveCard}
              setCantExistCards={setCantExistCards}
              setToggleCards={setToggleCards}
              veevaContentData={veevaContentData}
              setVeevaModuleCardData={setVeevaModuleCardData}
              veevaModuleCardData={veevaModuleCardData}
              handlesetveevamoduleCard={handlesetveevamoduleCard}
              setLoadingModules={setLoadingModules}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;
