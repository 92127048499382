import React, { useState } from "react";
import "../../styles/components/ToolBarLeftPanel.scss";
import ButtonSvg from "../../images/Buttongrey.svg";
import ImgSvg from "../../images/Imagegrey.svg";
import ModuleSvg from "../../images/Modulesgrey.svg";
import TextSvg from "../../images/Text Grey.svg";
//import TextSvgblue from "../../images/Textblue (2).svg";
import TextSvgblue from "../../images/TextBlue (3).svg";

import ShapesSvg from "../../images/Shapesgrey.svg";
import ButtonBlue from "../../images/Buttonblue.svg";
import ImgBlue from "../../images/Imageblue.svg";
import ModuleBlue from "../../images/ModulesBlue.svg";
import ShapesBlue from "../../images/ShapesBlue.svg";
//import Timeline from "../../images/LeftPanelIcons/Timeline.svg";
import TextWindow from "./TextWindow.js";
import ButtonWindow from "./ButtonWindow.js";

import ShapesWindow from "./ShapesWindow";
import ModuleWindow from "./ModuleWindow";
import { useTranslation } from "react-i18next";
import { TEXT, IMAGE, BUTTON } from "../../Uitls/ComponentType";
import TimelineBlue from "../../images/LeftPanelIcons/Layerblue.svg";
import nounLayersBlack from "../../images/Layergrey.svg";
import ImageWindow from "./ImageWindow";
import ExpandLayers from "./ExpandLayers";
import ElementLayers from "../AnimationTimeLinePanel/ELementLayers";

const SideBarLeftPanel = (props) => {
  const {
    toggleAnimatedBanner,
    showBannertype,
    showAssettype,
    setModules,
    modules,
    getLayerDetails,
    getEditSaveAssets,
    bannerId,
    sendAtomDetails2Canvas,
    openTimeLine,

    selectedComponent,
    setSelectedComponent,
    layerId,
    imageOnCanvas,
    linkImages,
    setLinkImages,
    getLinkImages,
    imageCount,
    setImageCount,
    playPause,
    linkedModulesType,
    setLinkedModulesType,
    loadingModules,
    setLoadingModules,
    setVeevaModuleCardData,
    veevaModuleCardData,
    playPauseRef,
    setOpenTimeLine,
    toggleHideComp,
    toggleLockComp,
    setMultiSelectedComps,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState("");
  const [clickButton, setClickButton] = useState(false);

  const [openElements, setOpenElements] = useState({
    Text: false,
    Button: false,
    Image: false,
    Shapes: false,
    Module: false,
    Layers: false,
  });
  const handleOpenElement = (element) => {
    let tempOpenElements = {};
    for (let elem in openElements) {
      tempOpenElements[elem] = elem === element ? true : false;
    }
    if (toggleAnimatedBanner) {
      setOpenTimeLine(false);
    }
    setOpenElements(tempOpenElements);
    if (element === "Layers" && toggleAnimatedBanner) {
      setOpenTimeLine(!openTimeLine);
      if (openTimeLine) {
        setOpenElements({ ...openElements, Layers: false });
      }
    }
  };
  const handleCloseElement = (element) => {
    setOpenElements({ ...openElements, [element]: false });
  };

  const sidebarIcons = [
    {
      src: (
        <>
          {" "}
          <img
            id="txt-img"
            alt="txt-img"
            src={
              selectedIndex === 0 && openElements.Text ? TextSvgblue : TextSvg
            }
          ></img>
        </>
      ),
      iconType: TEXT,
    },
    {
      src: (
        <img
          id="buttonType"
          alt="buttonType"
          // draggable={true}
          // onDragStart={(e) => {
          //   getLayerDetails(e.target.id, BUTTON);
          // }}
          src={
            selectedIndex === 1 && openElements.Button === true
              ? ButtonBlue
              : ButtonSvg
          }
        ></img>
      ),
      iconType: BUTTON,
    },
    {
      src: (
        <>
          {" "}
          <img
            id="img-img"
            alt="img-img"
            src={selectedIndex === 2 && openElements.Image ? ImgBlue : ImgSvg}
          ></img>
        </>
      ),
      iconType: IMAGE,
      type: (
        <p
          className={
            openElements.Image === true
              ? "sideBarTextonClick"
              : "sideBarTypeNotOnclik"
          }
        >
          Image
        </p>
      ),
    },
    {
      src: (
        <>
          {" "}
          <img
            id="shape-img"
            alt="shape-img"
            src={
              selectedIndex === 3 && openElements.Shapes
                ? ShapesBlue
                : ShapesSvg
            }
          ></img>
          {/* {openElements.Shapes && (
            <ShapesWindow
              handleCloseElement={handleCloseElement}
              getLayerDetails={getLayerDetails}
            />
          )} */}
        </>
      ),
      iconType: "Shapes",
    },
    {
      src: showAssettype === "Banner" && (
        <>
          {" "}
          <img
            id="module-img"
            alt="module-img"
            src={
              selectedIndex === 4 && openElements.Module
                ? ModuleBlue
                : ModuleSvg
            }
          ></img>
          {/* {openElements.Module && (
            <ModuleWindow
              openTimeLine={openTimeLine}
              handleCloseElement={handleCloseElement}
              getLayerDetails={getLayerDetails}
              sendAtomDetails2Canvas={sendAtomDetails2Canvas}
              linkedModulesType={linkedModulesType}
              setLinkedModulesType={setLinkedModulesType}
              loadingModules={loadingModules}
              setLoadingModules={setLoadingModules}
              setVeevaModuleCardData={setVeevaModuleCardData}
              veevaModuleCardData={veevaModuleCardData}
            />
          )} */}
        </>
      ),
      iconType: "Module",
    },
    {
      src: (
        <>
          <img
            id="timeline-img"
            alt="timeline-img"
            className="timelineImg"
            src={
              openTimeLine || openElements.Layers === true
                ? TimelineBlue
                : nounLayersBlack
            }
          ></img>
        </>
      ),
      iconType: "Layers",
    },
  ];
  const openWindow = Object.values(openElements).some((elem) => elem === true);

  const getStyles = (index) => {
    if (index === 5) {
      return index === selectedIndex && openWindow
        ? "iconsActive flex-fill listItems timelinePanelsidebar"
        : "iconsInactive flex-fill listItems timelinePanelsidebar";
    } else {
      return index === selectedIndex && openWindow
        ? "iconsActive flex-fill listItems"
        : "iconsInactive flex-fill listItems";
    }
  };
  const getborderStyles = (index) => {
    return index === selectedIndex && openWindow
      ? "iconsleftpanel "
      : "iconsleftpanelInactive ";
  };

  return (
    <>
      <div
        className="sideBarLeftpanel"
        style={{ pointerEvents: !playPause.animationCompleted && "none" }}
      >
        <ul
          className={
            showAssettype === "Banner"
              ? "d-flex mb-0 listItemSideBar"
              : "d-flex mb-0 listItemSideBartemp"
          }
        >
          {sidebarIcons.map((data, index) => {
            return (
              <>
                <li
                  className={getStyles(index)}
                  key={index}
                  onClick={(e) => {
                    if (playPauseRef.current.animationCompleted) {
                      handleOpenElement(data.iconType);
                      setSelectedIndex(index);
                      if (data.iconType === "Image") {
                        //  getLayerDetails(e.target.id, IMAGE);
                        getLinkImages();
                      }
                    }
                  }}
                >
                  {" "}
                  <div className={getborderStyles(index)}>{data.src}</div>
                </li>
              </>
            );
          })}
        </ul>
        {openElements.Text && (
          <TextWindow
            handleCloseElement={handleCloseElement}
            getLayerDetails={getLayerDetails}
          />
        )}
        {openElements.Button && (
          <ButtonWindow
            handleCloseElement={handleCloseElement}
            getLayerDetails={getLayerDetails}
          />
        )}
        {openElements.Image && (
          <ImageWindow
            handleCloseElement={handleCloseElement}
            selectedComponent={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            layerId={layerId}
            imageOnCanvas={imageOnCanvas}
            linkImages={linkImages}
            setLinkImages={setLinkImages}
            getLinkImages={getLinkImages}
            imageCount={imageCount}
            setImageCount={setImageCount}
            getLayerDetails={getLayerDetails}
          />
        )}
        {openElements.Shapes && (
          <ShapesWindow
            handleCloseElement={handleCloseElement}
            getLayerDetails={getLayerDetails}
          />
        )}
        {openElements.Module && (
          <ModuleWindow
            setModules={setModules}
            modules={modules}
            openTimeLine={openTimeLine}
            handleCloseElement={handleCloseElement}
            getLayerDetails={getLayerDetails}
            sendAtomDetails2Canvas={sendAtomDetails2Canvas}
            linkedModulesType={linkedModulesType}
            setLinkedModulesType={setLinkedModulesType}
            loadingModules={loadingModules}
            setLoadingModules={setLoadingModules}
            setVeevaModuleCardData={setVeevaModuleCardData}
            veevaModuleCardData={veevaModuleCardData}
          />
        )}
        {openElements.Layers && !toggleAnimatedBanner && (
          <ExpandLayers
            showBannertype={showBannertype}
            handleCloseElement={handleCloseElement}
            layerCompData={props.layerCompData}
            setLayerCompData={props.setLayerCompData}
            selectedComponent={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            toggleLockComp={toggleLockComp}
            toggleHideComp={toggleHideComp}
            toggleAnimatedBanner={toggleAnimatedBanner}
            playPause={playPause}
            setMultiSelectedComps={setMultiSelectedComps}
          />
        )}
      </div>
    </>
  );
};

export default SideBarLeftPanel;
