import React, { useState, useEffect, useContext, useRef } from "react";
import { scroll } from "../../Uitls/ScrollDiv";

import Canvas from "./Canvas";
import PopupWindow from "../TimelineComps/PopupWindow";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ValueContext from "../../ContextAPi/appContext";
import { getValidInput } from "../../Uitls/Util";
import Powericon from "../../images/PowerIcon.svg";
import Dragger from "../../images/Dragger.svg";
import { Rnd } from "react-rnd";
import ElementAnmnPopUp from "../TimelineComps/ElementAnmnPopUp";
import CustomanimationPopup from "../TimelineComps/CustomanimationPopup";
import LayerContextmenu from "../AnimationTimeLinePanel/LayerContextmenu";
import CopyPasteContextMenu from "../TimelineComps/CopyPasteContextMenu";
//import { resizeWdith } from "./DragandResize";

// export default function Layers({

const incrementalSecondsCTA = 68; //clientHeight of CTA
function Layers({
  handleCtrlClick,
  handleCompAspectRatioPopup,
  isAspectRatioLocked,
  layerCompData,
  setLayerCompData,
  millSeconds,
  seconds,
  setMillSeconds,
  setSeconds,
  selectedComponent,
  setSelectedComponent,
  marker,
  setMarker,
  animationObject,
  setAnimationObject,
  popUpOptions,
  setPopUpOptions,
  copiedKeyFrame,
  setCopiedKeyFrame,
  setEdited,
  openTimelinePopup,
  setOpenTimelinePopup,
  toggleAnimatedBanner,
  setundoFlag,
  popUpValuesEdited,
  setPopUpValuesEdited,
  playPause,
  popUpLeft,
  SetPopupLeft,
  bannerChanged,
  setBannerChanged,
  setLayerChangedOnPause,
  draggerWidth,
  setDraggerWidth,
  totalDuration,
  setTotalDuration,
  addKeyFrames,
  showTooltip,
  setShowTooltip,
  handleTimelinePopup,
  clickedClose,
  setClickedClose,
  setMultiSelectedComps,
  multiSelectedComps,
  keyFrameClicked,
  setKeyFrameClicked,
  updateKeyFrames,
  popUpWindowPosn,
  setPopWindowPosn,
  contextMenuvisbility,
  setContextMenuvisibility,
  deleteComponentOnCanvas,
  isNewAnimationOpen,
  setIsNewAnimationOpen,
  copyPasteContextMenuVisibility,
  setCopyPasteContextMenuVisibility,
  setSelectedProperties,
  selectedProperties,
  setSelectedPropertiesIndex,
  selectedPropertiesIndex,
  setCopyPropertySelected,
  copyPropertySelected,
  setCopyPasteContextMenuPosition,
  copyPasteContextMenuPosition,
  layerColDivRef,
}) {
  console.log("addKeyFrames", addKeyFrames);
  const { t } = useTranslation();
  const layercontextMenuRef = useRef([]);
  // const [popUpLeft, SetPopupLeft] = React.useState(-140);//moved to editorMainPage //half of Pop up window with is 140
  // const [openTimelinePopup, setOpenTimelinePopup] = useState(false);
  const [keyFrameRightButtonClicked, setKeyFrameRightButtonClicked] =
    useState(false);
  const [elementPopUp, setElementPopUp] = useState(false);
  const [popUpName, setPopUpName] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [layerWidth, setLayerWidth] = useState(675);
  const [isLayerResizing, setIsLayerResizing] = useState(true);
  // const [movedOnMarker, setMovedOnMarker] = useState(false);

  // const[resize]

  const [mouseUpOnTimeline, setMousUpOnTimeline] = useState(true);

  const appData = useContext(ValueContext); // to save scale

  const myDurationRef = React.useRef(totalDuration);

  const validateActionEdit = () => {
    let styles =
      layerCompData[
        layerCompData.findIndex(
          (selComp) => selComp.id === selectedComponent.id
        )
      ]?.componentStyles;
    if (styles?.lockBoolean) {
      toast.error(
        <div className="notificationText">{t("Component is Locked!")}</div>
      );

      return false;
    }
    if (styles?.hideBoolean) {
      return false;
    }
    return true;
  };
  const openCustomAnmnPopup = () => {
    handleTimelinePopup();
    setOpenTimelinePopup({
      popupWindow: true,
      fromUnRedo: false,
    });
    setElementPopUp(false);
    setCopyPasteContextMenuVisibility(false);
  };

  const callOnResizeStop = (e, direction, ref) => {
    const resizedLayerWidth = parseInt(ref.style.width);
    let tempSelect = { ...selectedComponent };
    tempSelect.componentStyles.layerWidth = resizedLayerWidth;
    setSelectedComponent(tempSelect);

    const tempLayerCompData = layerCompData.map((entry) => {
      if (entry.id === selectedComponent.id) {
        return {
          ...entry,
          componentStyles: {
            ...entry.componentStyles,
            layerWidth: resizedLayerWidth,
          },
        };
      }
      return entry;
    });
    setLayerCompData(tempLayerCompData);
  };

  useEffect(() => {
    if (
      !openTimelinePopup.popupWindow &&
      Object.values(selectedComponent).length &&
      selectedComponent.hasOwnProperty("id")
    ) {
      if (
        selectedComponent?.componentStyles[popUpName] !==
        popUpOptions[popUpName]
      ) {
        let event1 = {
          target: {
            name: popUpName,
            value: popUpOptions[popUpName],
          },
        };
        handleChangeTimelineProps(event1, "blur");
      }
    }
  }, [openTimelinePopup.popupWindow]);
  const saveAnmObjWithedit = (e) => {
    let name = e.target.name;

    name = name === "left" ? "x" : name === "top" ? "y" : name;
    let n = marker / 45;
    let keyFrameRange = parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;

    let selectedIndex = animationObject[
      selectedComponent.id
    ]?.animation?.findIndex(
      (elem) =>
        keyFrameRange <= elem.end + 0.1 && keyFrameRange >= elem.end - 0.1
    );
    let tempAnmObj = {
      ...animationObject,
    };
    if (selectedIndex >= 0) {
      setundoFlag(false);
      let tempLayer = [...layerCompData];
      // tempLayer[
      //   tempLayer.findIndex((selComp) => selComp.id === selectedComponent.id)
      // ].componentStyles[name + "edited"] = e.target.value;
      tempLayer.forEach((entry, index) => {
        if (entry.id === selectedComponent.id) {
          tempLayer[index].animationApllied = {
            keyFrameEdited: "true",
            keyFrameadded: "false",
            keyFrameDeleted: "false",
            componentAdded: "false",
            keyFramePasted: "false",
            marker: marker,
          };
        }
      });
      setEdited(true);
      setLayerCompData(tempLayer);
      setPopUpValuesEdited(true);
      tempAnmObj[selectedComponent.id].animation[selectedIndex][name] =
        e.target.value;
      setAnimationObject(tempAnmObj);
    }
  };
  // const getValidInput = (e, blur) => {
  //   let value = e.target.value;
  //   if (value.length > 4 && parseInt(value) > 9999) {
  //     toast.error(
  //       <div className="notificationText">
  //         Value can not be more than 4 digits
  //       </div>
  //     );
  //     return -1;
  //   }
  //   if (e.target.name === "rotation" && value === "-") return value;
  //   if (isNaN(value)) return -1;
  //   let nulVal = blur === "blur" ? 0 : "";
  //   return value !== "" ? parseInt(value) : nulVal;
  // };
  const handleChangeTimelineProps = (e, blur) => {
    let retVal = getValidInput(e, blur);
    if (clickedClose && blur === "blur") {
      retVal = selectedComponent?.componentStyles?.[e.target.name];
    }
    if (retVal === -1) return;
    if (blur === "blur" && (retVal == "-" || retVal == "-0")) {
      retVal = 0;
    }

    let name = e.target.name;

    if (
      name === "left" ||
      name === "top" ||
      name === "width" ||
      name === "height" ||
      name === "translateX" ||
      name === "translateY" ||
      name === "rotation" ||
      name === "transformOriginX" ||
      name === "transformOriginY" ||
      name === "transformOriginZ"
    ) {
      if (blur === "blur") {
        if (!!Number(retVal) || retVal === "") {
          retVal = Number(retVal);
        }
        layerCompData[layerCompData.length - 1].eachDrag = "no";
      } else {
        layerCompData[layerCompData.length - 1].eachDrag = "yes";
      }
      setundoFlag(true);
      // let tempLayer = [...layerCompData];

      // tempLayer.forEach((entry, index) => {
      //   if (entry.id === selectedComponent.id)
      //     // layerCompData[index].componentStyles[name] = retVal;
      //     entry.componentStyles[name] = retVal;
      // });
      // setLayerCompData(tempLayer);
      let tempSelected = { ...selectedComponent };
      let popUpvalues = { ...popUpOptions };
      if ((name == "width" || name == "height") && isAspectRatioLocked) {
        handleCompAspectRatioPopup(tempSelected, popUpvalues, name, retVal);
      }
      popUpvalues[name] = retVal;
      tempSelected.componentStyles[name] = retVal || 0;
      setPopUpOptions({ ...popUpvalues });
      setPopUpName(name);

      setSelectedComponent(tempSelected);
      console.log(tempSelected);
      let tempLayerComp = JSON.parse(JSON.stringify(layerCompData));
      tempLayerComp.forEach((comp, LayerCompIndex) => {
        // console.log(comp.id, animationObject[comp.id].set);
        if (comp.id === selectedComponent.id) {
          tempLayerComp[LayerCompIndex].componentStyles = {
            ...comp.componentStyles,
            ...tempSelected.componentStyles,
          };
        }
      });
      setLayerCompData(tempLayerComp);
      setPopUpValuesEdited(true);
    } else {
      if (!!Number(retVal)) {
        retVal = Number(retVal);
      }
    }
    //  else if (blur !== "blur") return;

    setPopUpName(name);

    // if (
    //   selectedComponent.componentStyles[name] === Number(e.target.value) ||
    //   selectedComponent.componentStyles[name] === e.target.value
    // )
    //   return;

    if (
      name === "left" ||
      name === "top" ||
      name === "width" ||
      name === "height"
    ) {
      // setundoFlag(true);
      // let tempLayer = [...layerCompData];
      // tempLayer.forEach((entry, index) => {
      //   if (entry.id === selectedComponent.id)
      //     // layerCompData[index].componentStyles[name] = retVal;
      //     entry.componentStyles[name] = retVal;
      // });
      // setLayerCompData(tempLayer);
      // setPopUpValuesEdited(true);
    }

    if (name === "skewAnimation") {
      let skewChecked = {
        ...popUpOptions,
        [name]: e.target.checked,
      };
      // setPopUpOptions({ ...skewChecked, clicked: true });
    }

    // if (name === "transformOriginX" || name === "transformOriginY") {
    //   let valueTransform = e.target.value;
    //   let transformToast =
    //     name === "transformOriginX"
    //       ? "Tranform Origin X"
    //       : name === "transformOriginY"
    //       ? "Tranform Origin X"
    //       : "";
    // if (valueTransform > 100 || valueTransform < 0) {
    //   toast.error(
    //     <div className="notificationText">
    //       {transformToast} value should be in a range between 0% to 100% !
    //     </div>
    //   );
    // }
    // if (parseInt(valueTransform) > 100) {
    //   valueTransform = 100;
    //   e.target.value = 100;
    // } else if (parseInt(valueTransform) < 0) {
    //   valueTransform = 0;
    //   e.target.value = 0;
    // }
    // let tempLayer = [...layerCompData];
    // // tempLayer[
    // //   tempLayer.findIndex((selComp) => selComp.id === selectedComponent.id)
    // // ].componentStyles[name + "edited"] = e.target.value;
    // tempLayer.forEach((entry, index) => {
    //   if (entry.id === selectedComponent.id) {
    //     // layerCompData[index].componentStyles[name] = valueRotation;
    //     entry.componentStyles[name] = valueTransform;
    //   }
    // });
    // setLayerCompData(tempLayer);

    // let popUpvalues = {
    //   ...popUpOptions,
    //   [name]: valueTransform,
    // };

    // setPopUpOptions({ ...popUpvalues });
    // }
    if (name === "rotation") {
      //;
      // if (e.target.value > 360) {
      //   toast.error(
      //     <div className="notificationText">
      //       Rotation value can not be more than 360 degree
      //     </div>
      //   );
      // } else
      // if (!!(Number(retVal) % 1)) retVal = parseInt(retVal);
      let valueRotation = e.target.value;
      if (valueRotation < -360 || valueRotation > 360) {
        toast.error(
          <div className="notificationText">
            Rotation value should be in a range between -360 degree to +360
            degree.!
          </div>
        );

        if (parseInt(valueRotation) > 360) {
          valueRotation = 360;
          e.target.value = 360;
        } else if (parseInt(valueRotation) < -360) {
          valueRotation = -360;
          e.target.value = -360;
        }
        let tempLayer = [...layerCompData];
        // tempLayer[
        //   tempLayer.findIndex((selComp) => selComp.id === selectedComponent.id)
        // ].componentStyles[name + "edited"] = e.target.value;
        tempLayer.forEach((entry, index) => {
          if (entry.id === selectedComponent.id) {
            // layerCompData[index].componentStyles[name] = valueRotation;
            entry.componentStyles[name] = valueRotation;
          }
        });
        setLayerCompData(tempLayer);

        let popUpvalues = {
          ...popUpOptions,
          [name]: valueRotation,
        };

        setPopUpOptions({ ...popUpvalues });

        // setLayerCompData(tempLayer);
      } else {
        setundoFlag(true);
        layerCompData.forEach((entry, index) => {
          if (entry.id === selectedComponent.id)
            layerCompData[index].componentStyles[name] = valueRotation;
          //retVal;
        });
        setPopUpValuesEdited(true);
      }
    }
    // saveAnmObjWithedit(e);
  };

  useEffect(() => {
    dragAndCalculate();
  }, []);

  useEffect(() => {
    if (appData?.genericInfo?.canvasScale !== undefined)
      setTotalDuration({ ...appData?.genericInfo?.canvasScale });
    // myDurationRef.current = appData?.genericInfo?.canvasScale;
  }, [appData.updateGenericInfo]);
  useEffect(() => {
    //console.log("setMyrefAfterTwentyPlus", totalDuration);

    myDurationRef.current = totalDuration;
    // console.log("setMyrefAfterTwentyPlus", myDurationRef.current);
  }, [totalDuration]);
  let sLeft = 0;

  function dragAndCalculate() {
    let elem = document.getElementById("layerCol");

    let posLeft = 0;

    function dragTimeTracker() {
      document
        .getElementById("marker")
        .addEventListener("mousedown", mouseDown, false);
    }

    dragTimeTracker();

    function mouseUp(e) {
      e.preventDefault();
      if (!document.getElementById("marker")) return;
      if (!playPause?.playPause) setMarker(sLeft);
      let domVal = 551;
      let checkScrollValue =
        domVal -
        (document.getElementById("marker")?.getBoundingClientRect()?.left -
          document.getElementById("layerCol")?.getBoundingClientRect()?.left);

      if (checkScrollValue < 100) {
        let x = elem.scrollLeft;
        elem.scrollLeft = x + 30;
      }
      document
        .getElementById("marker")
        .addEventListener("mousedown", mouseDown, true);
      document
        .getElementById("timeline-scale")
        .removeEventListener("mousemove", divMove, false);
      // document
      //   .getElementById("timeline-scale")
      //   .removeEventListener("mouseup", mouseUp, true);
    }

    function mouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      posLeft = e.clientX;
      document
        .getElementById("timeline-scale")
        .addEventListener("mousemove", divMove, false);
      document
        //.getElementById("timeline-scale")
        .addEventListener("mouseup", mouseUp, true);
    }
    let scale = document
      .getElementById("timeline-scale")
      .getBoundingClientRect();

    let popUpParent = document
      .getElementById("time-line")
      .getBoundingClientRect();

    function divMove(e) {
      // console.log(e);
      if (openTimelinePopup === true) {
        // setOpenTimelinePopup(!openTimelinePopup);
        setOpenTimelinePopup({
          popupWindow: !openTimelinePopup.popupWindow,
          fromUnRedo: false,
        });
      }
      let valueTime =
        Math.floor(Math.round(e.clientX) - scale.left - 16 + elem.scrollLeft) /
        45;
      let valueLeft = Math.round(e.clientX) - scale.left - 16 + elem.scrollLeft;
      if (valueLeft >= 0 && valueTime <= myDurationRef.current.divisions) {
        sLeft = Math.round(
          Math.round(e.clientX) - scale.left - 16 + elem.scrollLeft
        );

        setMarker(sLeft);

        ////Calculate seconds and Milliseconds
        // console.log(sLeft, elem.scrollLeft, "timeline-scale");
        // sLeft + scale.left - 548 - elem.scrollLeft;
        SetPopupLeft(sLeft - 140 - elem.scrollLeft);
        let xMill = sLeft / 45;
        let secsToSet = 0;
        if ((xMill + 1) % Math.floor(xMill + 1) > 0.9)
          secsToSet = Math.round(xMill);
        else secsToSet = Math.floor(sLeft / 45);
        setSeconds(secsToSet < 0 ? 0 : secsToSet);

        if (xMill) {
          if (xMill < 1) xMill = parseInt((xMill % 1) * 100);
          else xMill = Math.round(parseInt((xMill % parseInt(xMill)) * 100));
          let stepValue = xMill % 10;

          xMill += 10;
          xMill = xMill > 100 ? 0 : xMill - stepValue;
          if (sLeft < myDurationRef.current.width - 10) {
            setMillSeconds(xMill);
          } else setMillSeconds(0);
        } else {
          setMillSeconds(0);
        }
        //setMillSeconds(Math.round(xMill));
      } else if (marker >= 0 && valueLeft < 0) {
        setMarker(0); // This is Hack not  a good fix
        setMillSeconds(0);
        setSeconds(0);
        SetPopupLeft(-140);
      } else if (valueTime > myDurationRef.current.divisions) {
        setMarker(myDurationRef.current.width - 10); // This is Hack not  a good fix
        setMillSeconds(0);
        setSeconds(myDurationRef.current.divisions);
        SetPopupLeft(myDurationRef.current.width - 140);
      }

      let domVal = 556;
      // document.getElementById("layerCol").clientWidth;
      // let leftScrollBar =
      //   document.getElementById("layerCol").getBoundingClientRect().left -
      //   document.getElementById("marker").getBoundingClientRect().left;
      // let checkScrollValue =
      //   domVal -
      //   (document.getElementById("marker")?.getBoundingClientRect().left -
      //     document.getElementById("layerCol")?.getBoundingClientRect().left);
      // console.clear();
      // console.log(domVal - checkScrollValue, "checkScrollValue");

      // if (checkScrollValue < 250) {
      //   let x = elem.scrollLeft;
      //   elem.scrollLeft = x + 30;
      // } else if (checkScrollValue - sLeft > 100) {
      //   elem.scrollTo(0, 0);
      // }
    }
  }

  useEffect(() => {
    layerCompData.map((value, index) => {
      if (!animationObject[value.id]) {
        resizeWdith("slider-TimeLine" + index, "ghostPane" + index);

        let temp;

        temp = Object.assign(animationObject, {
          [value.id]: {
            timeLine: { id: "slider-TimeLine" + index, left: 0 },
            animation: [],
          },
        });

        setAnimationObject(temp);
      }
    });
    if (layerCompData.length > 3) scroll();
    if (selectedComponent === "compdeleted" && layerCompData.length === 0) {
      setTotalDuration({ divisions: 30, width: 1359 });
      setMarker(0);
      setSeconds(0);
      setMillSeconds(0);
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        canvasScale: { divisions: 30, width: 1359 },
        rightUpdaed: true,
      });
      // myDurationRef.current = { divisions: 20, width: 910 };
      // setBannerChanged(true);
      // setLayerChangedOnPause(true);
    }

    // scrollbar initialization
  }, [layerCompData]);

  // useEffect(() => {
  //   // ssb.scrollbar("headPain");
  // }, []);

  function resizeWdith(pane1, ghostpane1) {
    return;
  }

  const selectcomp = { ...selectedComponent };

  const returnPowerIconbgcolor = (compType) => {
    if (compType === "Text") {
      return "#83d89e";
    } else if (compType === "Button") {
      return "#f3cba5";
    } else if (compType === "Image") {
      return "#8dcbf7";
    } else if (compType === "Shape") {
      return "#be9da0";
    }
  };
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [deletionLayer, setDeletionLyer] = useState();
  const handleRightClickonlayer = (event, item) => {
    event.preventDefault();
    setSelectedComponent(item);
    setDeletionLyer(item);

    // var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    // let x = mac ? event.clientX - 250 : event.clientX - 263;
    // let y = mac ? event.clientY - 677 : event.clientY - 455;

    // const valueY = event.target.getBoundingClientRect().top;
    // setContextMenuPosition({
    //   x: event.clientX - event.target.getBoundingClientRect().left,
    //   y: event.clientY - valueY,
    // });

    let x = event.clientX;
    let y = event.clientY;

    const availableSpaceBottom = window.innerHeight - y;
    let adjustedX = x;
    let adjustedY = y;
    if (availableSpaceBottom < 64) {
      adjustedY = y - 64;
    }
    setContextMenuPosition({
      x: adjustedX, //x,
      y: adjustedY, //y,
    });

    setContextMenuvisibility(true);
    setCopyPasteContextMenuVisibility(false);
  };
  const handleHideLayerContextMenu = () => {
    setContextMenuvisibility(false);
  };
  const handleDeleteallKeyframe = () => {
    let tempSelectedkeyframes = { ...selectedComponent };
    tempSelectedkeyframes.keyFrames = [];
    setSelectedComponent(tempSelectedkeyframes);

    // let tempElemLayers = [...layerCompData];
    // tempElemLayers.forEach((entry, index) => {
    //   if (entry.id === selectedComponent.id) {
    //     tempElemLayers[index].keyFrames = [];
    //   }
    // });
    // ;
    // let tempAnmObj = { ...animationObject };
    // delete tempAnmObj[selectedComponent.id];
    //setAnimationObject(tempAnmObj);
    const tempElemLayers = layerCompData.map((entry) => {
      if (entry.id === selectedComponent.id) {
        return {
          ...entry,
          keyFrames: [],
        };
      }
      return entry;
    });

    setAnimationObject((prevAnmObj) => ({
      ...prevAnmObj,
      [selectedComponent.id]: {
        ...prevAnmObj[selectedComponent.id],
        animation: [],
      },
    }));
    setLayerCompData(tempElemLayers);
    setContextMenuvisibility(false);
  };

  const handleDeleteLayer = () => {
    setLayerCompData((prevData) =>
      prevData.filter((item) => item.id !== selectedComponent.id)
    );
    let tempAnmObj = { ...animationObject };
    delete tempAnmObj[selectedComponent.id];
    setAnimationObject(tempAnmObj);
    setSelectedComponent({});

    setMultiSelectedComps([]);
    setContextMenuvisibility(false);
    setMarker(0);
  };
  // const handleResize = (e, direction, ref, delta, position) => {
  //   ;
  //   const newWidth = parseInt(ref.style.width) + delta.width;

  //   const minWidth = 47;
  //   const maxWidth = totalDuration?.width - 9;
  //   if (newWidth < minWidth) {
  //     ref.style.width = minWidth + "px";
  //   } else if (newWidth > maxWidth) {
  //     ref.style.width = maxWidth + "px";
  //   } else {
  //     ref.style.width = newWidth + "px";
  //   }
  // };

  const handlePowerIconClick = (e, id) => {
    playPause?.animationCompleted && setElementPopUp(!elementPopUp);
    let pointerLeft =
      e.clientX -
      document.getElementById(id).getBoundingClientRect().left -
      document.getElementById(id).getBoundingClientRect().width / 2;
    let pointerTop =
      e.clientY -
      document.getElementById(id).getBoundingClientRect().top -
      document.getElementById(id).getBoundingClientRect().width / 2;
    setPopWindowPosn({
      left: e.clientX - pointerLeft,
      top: e.clientY - pointerTop,
    });

    //getBoundingClientRect
  };

  const handleKeyDroIconClick = (e, id) => {
    playPause?.animationCompleted && setElementPopUp(!elementPopUp);
    setPopWindowPosn({
      left: e.clientX,
      top: e.clientY,
    });
  };
  const [startX, setStartX] = useState(0);

  const getLayerStyle = () => {
    const scalewidth = totalDuration?.divisions;
    switch (scalewidth) {
      case 30:
        return "animation30";
      case 40:
        return "animation40";
      case 50:
        return "animation50";
      case 60:
        return "animation60";
      case 70:
        return "animation70";
      case 80:
        return "animation80";
      case 90:
        return "animation90";
      default:
        return "";
    }
  };

  const [layerCompDataPrevLength, setLayerCompDataPrevLength] = useState(0);
  useEffect(() => {
    if (layerCompData.length > layerCompDataPrevLength) {
      setMarker(0);
      setSeconds(0);
      setMillSeconds(0);
    }

    setLayerCompDataPrevLength(layerCompData.length);
  }, [layerCompData, layerCompDataPrevLength]);

  //copy paste key frame functionality

  const handleContextMenu = (event) => {
    event.preventDefault();
    const x = event.clientX;
    const y = event.clientY;
    setCopyPasteContextMenuPosition({ x, y });
    setCopyPasteContextMenuVisibility(true);
    setContextMenuvisibility(false);
    setOpenTimelinePopup({
      popupWindow: false,
      fromUnRedo: false,
    });
  };
  useEffect(() => {}, [selectedProperties]);

  const handleOutsideClick = () => {
    setCopyPasteContextMenuVisibility(false);
  };

  const copyKeyFrames = () => {
    setCopyPropertySelected(true);
    setCopiedKeyFrame(
      animationObject[selectedProperties.id]?.animation[
        selectedPropertiesIndex.keyIndex
      ]
    );
  };
  const pasteKeyFrame = () => {
    if (!selectedProperties.hasOwnProperty("id")) {
      toast.error(
        <div className="notificationText">
          {t("Please select the component")}
        </div>
      );
      return;
    }
    if (copiedKeyFrame === "") {
      toast.error(
        <div className="notificationText">{t("No Key frame is copied")}</div>
      );
      return;
    }

    let tempLayer = [...layerCompData];
    let tempAnmObj = {
      ...animationObject,
    };
    let anmAry = tempAnmObj[selectedProperties.id]?.animation;

    let tempCopiedKeyFrame = { ...copiedKeyFrame };

    anmAry[selectedPropertiesIndex.keyIndex].x = tempCopiedKeyFrame.x;
    anmAry[selectedPropertiesIndex.keyIndex].y = tempCopiedKeyFrame.y;
    anmAry[selectedPropertiesIndex.keyIndex].width = tempCopiedKeyFrame.width;
    anmAry[selectedPropertiesIndex.keyIndex].height = tempCopiedKeyFrame.height;
    anmAry[selectedPropertiesIndex.keyIndex].rotation =
      tempCopiedKeyFrame.rotation;
    anmAry[selectedPropertiesIndex.keyIndex].opacity =
      tempCopiedKeyFrame.opacity;
    anmAry[selectedPropertiesIndex.keyIndex].transform =
      tempCopiedKeyFrame.transform;
    anmAry[selectedPropertiesIndex.keyIndex].translateX =
      tempCopiedKeyFrame.translateX;
    anmAry[selectedPropertiesIndex.keyIndex].translateY =
      tempCopiedKeyFrame.translateY;

    tempLayer[selectedPropertiesIndex.index].componentStyles.left =
      tempCopiedKeyFrame.x;
    tempLayer[selectedPropertiesIndex.index].componentStyles.top =
      tempCopiedKeyFrame.y;
    tempLayer[selectedPropertiesIndex.index].componentStyles.height =
      tempCopiedKeyFrame.height;
    tempLayer[selectedPropertiesIndex.index].componentStyles.width =
      tempCopiedKeyFrame.width;

    setSelectedComponent((prevState) => ({
      ...prevState,
      componentStyles: {
        ...prevState.componentStyles,
        left: tempCopiedKeyFrame.x,
        top: tempCopiedKeyFrame.y,
        width: tempCopiedKeyFrame.width,
        height: tempCopiedKeyFrame.height,
        translateX: tempCopiedKeyFrame.translateX || 0,
        translateY: tempCopiedKeyFrame.translateY || 0,
        transformOriginX: tempCopiedKeyFrame.transformOriginX || 0,
        transformOriginY: tempCopiedKeyFrame.transformOriginY || 0,
        transformOriginZ: tempCopiedKeyFrame.transformOriginY || 0,
      },
    }));

    let popUpvalues = {
      ...popUpOptions,
      left: tempCopiedKeyFrame.x,
      top: tempCopiedKeyFrame.y,
      width: tempCopiedKeyFrame.width,
      height: tempCopiedKeyFrame.height,
      ease: tempCopiedKeyFrame.ease,
      skewAnimation: tempCopiedKeyFrame.skewAnimation,
      opacity: Math.round(tempCopiedKeyFrame.opacity * 100),
      rotation: tempCopiedKeyFrame.rotation,
      scale: Math.round(tempCopiedKeyFrame.transform * 100),
      translateX: tempCopiedKeyFrame.translateX || 0,
      translateY: tempCopiedKeyFrame.translateY || 0,
      transformOriginX: tempCopiedKeyFrame.transformOriginX || 0,
      transformOriginY: tempCopiedKeyFrame.transformOriginY || 0,
      transformOriginZ: tempCopiedKeyFrame.transformOriginY || 0,
    };
    setPopUpOptions(popUpvalues);
    setAnimationObject(tempAnmObj);
    setLayerCompData(tempLayer);
    setCopyPropertySelected(false);
  };

  const deleteKeyFrame = () => {
    layerCompData[selectedPropertiesIndex.index].keyFrames.splice(
      selectedPropertiesIndex.keyIndex,
      1
    );
    animationObject[selectedProperties.id].animation.splice(
      selectedPropertiesIndex.keyIndex,
      1
    );
    handleTimelinePopup(true);
  };

  const handleSelectedComponentKeyframe = (item, keyIndex) => {
    if (
      keyFrameClicked !== "plusCustomAnmn" &&
      keyFrameClicked !== "Diamond+Icon"
    ) {
      const tempSelectedComponent = item;
      tempSelectedComponent.componentStyles.left =
        animationObject[item?.id]?.animation[keyIndex]?.x;
      tempSelectedComponent.componentStyles.top =
        animationObject[item?.id]?.animation[keyIndex]?.y;
      tempSelectedComponent.componentStyles.translateX =
        animationObject[item?.id]?.animation[keyIndex]?.translateX;
      tempSelectedComponent.componentStyles.translateY =
        animationObject[item?.id]?.animation[keyIndex]?.translateY;
      setSelectedComponent(tempSelectedComponent);
    }
  };
  return (
    <>
      <div
        className="layerColdiv custonHorizontalScrollBar"
        onClick={handleOutsideClick}
        ref={layerColDivRef}
      >
        <div className="layerCol " id="layerCol">
          <div
            // ref={ref}
            className="marker"
            id="marker"
            style={{ left: marker, marginTop: 4 }}
            onMouseMove={() => {
              if (!mouseUpOnTimeline) {
                // setMovedOnMarker(true);
                setIsResizing(true);
              }
            }}
          >
            <div
              className="marker-head"
              // onClick={playPause?.animationCompleted && handleTimelinePopup}
            ></div>

            <div className="marker-tail"></div>
          </div>{" "}
          <div className="scale-IncrementalButton">
            <Canvas
              totalDuration={totalDuration}
              draggerWidth={draggerWidth}
              setDraggerWidth={setDraggerWidth}
              isResizing={isResizing}
              setIsResizing={setIsResizing}
              // movedOnMarker={movedOnMarker}
              // setMovedOnMarker={setMovedOnMarker}
              setMousUpOnTimeline={setMousUpOnTimeline}
              appData={appData}
              layerCompData={layerCompData}
              playPause={playPause}
            />
          </div>
          <div
            className="animationScroll"
            id="parentID"
            style={{
              width: totalDuration.width + 15,
              //width: totalDuration.width + 68,
            }}
          >
            {/* <div id="scroll">
            <span id="anchor"></span>
          </div> */}
            <div
              className="animate-row custonVertScrollBar"
              id="scrollDiv"
              draggable={false}
              style={{ overflowY: "scroll", overflowX: "hidden" }}
            >
              {layerCompData.length !== 0 ? (
                <>
                  {layerCompData?.map((item, index) => {
                    return (
                      <div
                        className={` col-md-12 animation-timelineContent  pd-0 container contextmenu ${getLayerStyle()}`}
                        key={item.id}
                        onClick={(e) => {
                          if (
                            playPause.animationCompleted &&
                            validateActionEdit()
                          ) {
                            // setMultiSelectedComps([]);
                            setSelectedComponent(item);
                            handleCtrlClick(e, item);
                          }
                        }}
                      >
                        {item?.keyFrames?.map((key, keyIndex) => {
                          return (
                            <>
                              <div
                                style={{ left: key + 5 + "px" }}
                                className="key-drops"
                                id="key-drops"
                                onContextMenu={(e) => {
                                  if (
                                    playPause.animationCompleted &&
                                    validateActionEdit()
                                  ) {
                                    handleContextMenu(e);
                                    setSelectedPropertiesIndex({
                                      index: index,
                                      keyIndex: keyIndex,
                                    });
                                    setSelectedProperties(item);
                                    setMultiSelectedComps([]);
                                  }
                                }}
                                onClick={(e) => {
                                  if (
                                    playPause.animationCompleted &&
                                    validateActionEdit() &&
                                    !e.ctrlKey
                                  ) {
                                    setKeyFrameClicked(keyIndex);
                                    handleTimelinePopup();
                                    handleKeyDroIconClick(e, "key-drops");
                                    handleOutsideClick();
                                    setSelectedProperties(item);
                                    setSelectedPropertiesIndex({
                                      index: index,
                                      keyIndex: keyIndex,
                                    });
                                    setIsNewAnimationOpen(false);
                                    handleSelectedComponentKeyframe(
                                      item,
                                      keyIndex
                                    );
                                  }
                                }}
                              ></div>
                            </>
                          );
                        })}
                        {item.id === selectcomp?.id ? (
                          <Rnd
                            style={{
                              position: "relative",

                              // cursor: getCursorStyle(),
                            }}
                            enableResizing={{
                              top: false,
                              right: true,
                              bottom: false,
                              left: true, //isLayerResizing,
                              topRight: false,
                              bottomRight: false,
                              bottomLeft: false,
                              topLeft: false,
                            }}
                            onResizeStop={(
                              e,
                              direction,
                              ref,
                              delta,
                              position
                            ) =>
                              callOnResizeStop(
                                e,
                                direction,
                                ref,
                                delta,
                                position
                              )
                            }
                            default={{
                              width: `${item?.componentStyles?.layerWidth}px`,
                              // width: `${draggerWidth}`,
                              height: 30,
                              x: 0,
                              y: 0,
                            }}
                            // onResize={(e, direction, ref, delta, position) =>
                            //   handleResize(e, direction, ref, delta, position)
                            // }
                            minWidth={47}
                            //  maxWidth={totalDuration}
                            minHeight={30}
                            draggablehandle={`.${"resize-timeLine"}, .${"dragHandle"}`}
                            resizeHandleStyles={{
                              right: {
                                //  background: "#83d89e",
                                zIndex: "99999",
                                cursor: "ew-resize",
                              },
                              left: {
                                cursor: "ew-resize",
                                zIndex: "99999",
                              },
                            }}
                            bounds="parent"
                            disableDragging
                          >
                            <div
                              id={"slider-TimeLine" + index}
                              className="resize-timeLine slider-TimeLine"
                              onContextMenu={(e) => {
                                handleRightClickonlayer(e, item);
                              }}
                              ref={layercontextMenuRef}
                              style={{
                                backgroundColor: item?.componentStyles?.BGColor,
                                position: "relative",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              {item?.id === selectcomp.id && (
                                <>
                                  {" "}
                                  <div
                                    // className="resize-timeLine dragHandle"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,

                                      // width: "15px",
                                      height: "100%",
                                      cursor: "ew-resize",
                                      zIndex: "99999",
                                    }}
                                  ></div>
                                  <img
                                    src={Dragger}
                                    alt=""
                                    className="img-fluid draggerIcon"
                                  />
                                  {!selectcomp?.keyFrames?.length && (
                                    <div
                                      id={"slider-powericon" + index}
                                      className=" slider-TimeLine powerIcon1"
                                      style={{
                                        backgroundColor: returnPowerIconbgcolor(
                                          item?.componentType
                                        ),
                                      }}
                                    >
                                      <img
                                        src={Powericon}
                                        alt=""
                                        className="img-fluid "
                                        id="powerIconCircle"
                                        onClick={(e) =>
                                          handlePowerIconClick(
                                            e,
                                            "powerIconCircle"
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                  {!selectcomp?.keyFrames?.length && (
                                    <div
                                      id={"slider-powericon" + index}
                                      className="powerIcon2"
                                      style={{
                                        backgroundColor: returnPowerIconbgcolor(
                                          item?.componentType
                                        ),
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={Powericon}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                  )}
                                  <img
                                    src={Dragger}
                                    alt=""
                                    className="img-fluid draggerIcon2"
                                  />
                                </>
                              )}
                            </div>
                          </Rnd>
                        ) : (
                          <div
                            style={{
                              width: `${item?.componentStyles.layerWidth}px`,
                            }}
                          >
                            <div
                              id={"slider-TimeLine" + index}
                              className="resize-timeLine slider-TimeLine"
                              style={{
                                backgroundColor: item?.componentStyles?.BGColor,
                                position: "relative",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <div
                                id={"slider-powericon" + index}
                                className=" slider-TimeLine NopowerIcon1"
                                style={{
                                  backgroundColor: returnPowerIconbgcolor(
                                    item?.componentType
                                  ),
                                }}
                              ></div>
                              <div
                                id={"slider-powericon" + index}
                                className="NopowerIcon2"
                                style={{
                                  backgroundColor: returnPowerIconbgcolor(
                                    item?.componentType
                                  ),
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div
                  className={`col-md-12 animation-timelineContent pd-0 container ${getLayerStyle()}`}
                  // key={index}
                  //style={{maxWidth}}
                >
                  {" "}
                </div>
              )}
            </div>

            {/* </div> */}
          </div>
        </div>
      </div>
      {elementPopUp && selectedComponent?.keyFrames?.length < 1 && (
        <ElementAnmnPopUp
          elementPopUp={elementPopUp}
          setElementPopUp={setElementPopUp}
          selectedComponent={selectedComponent}
          openTimelinePopup={openTimelinePopup}
          setOpenTimelinePopup={setOpenTimelinePopup}
          handleTimelinePopup={handleTimelinePopup}
          openCustomAnmnPopup={openCustomAnmnPopup}
          setKeyFrameClicked={setKeyFrameClicked}
          popUpWindowPosn={popUpWindowPosn}
          setPopWindowPosn={setPopWindowPosn}
          setIsNewAnimationOpen={setIsNewAnimationOpen}
        />
      )}
      {openTimelinePopup.popupWindow && (
        <CustomanimationPopup
          layerCompData={layerCompData}
          setLayerCompData={setLayerCompData}
          handleTimelinePopup={handleTimelinePopup}
          marker={popUpLeft}
          markerTime={marker}
          selectedComponent={selectedComponent}
          SetPopupLeft={SetPopupLeft}
          setSelectedComponent={setSelectedComponent}
          popUpOptions={popUpOptions}
          setPopUpOptions={setPopUpOptions}
          animationObject={animationObject}
          setAnimationObject={setAnimationObject}
          copiedKeyFrame={copiedKeyFrame}
          setCopiedKeyFrame={setCopiedKeyFrame}
          setEdited={setEdited}
          setundoFlag={setundoFlag}
          popUpValuesEdited={popUpValuesEdited}
          setPopUpValuesEdited={setPopUpValuesEdited}
          openTimelinePopup={openTimelinePopup}
          setOpenTimelinePopup={setOpenTimelinePopup}
          handleChangeTimelineProps={handleChangeTimelineProps}
          saveAnmObjWithedit={saveAnmObjWithedit}
          elementPopUp={elementPopUp}
          setElementPopUp={setElementPopUp}
          addKeyFrames={addKeyFrames}
          setMarker={setMarker}
          setSeconds={setSeconds}
          setMillSeconds={setMillSeconds}
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          clickedClose={clickedClose}
          setClickedClose={setClickedClose}
          keyFrameClicked={keyFrameClicked}
          setKeyFrameClicked={setKeyFrameClicked}
          updateKeyFrames={updateKeyFrames}
          popUpWindowPosn={popUpWindowPosn}
          setPopWindowPosn={setPopWindowPosn}
          setCopyPropertySelected={setCopyPropertySelected}
          copyPropertySelected={copyPropertySelected}
          selectedProperties={selectedProperties}
          isNewAnimationOpen={isNewAnimationOpen}
          setIsNewAnimationOpen={setIsNewAnimationOpen}
          selectedPropertiesIndex={selectedPropertiesIndex}
        />
      )}
      {contextMenuvisbility &&
        (playPause.animationCompleted &&
        (multiSelectedComps.length > 0 ||
          selectedComponent?.componentType !== "default") ? (
          <LayerContextmenu
            contextMenuvisbility={contextMenuvisbility}
            setContextMenuvisibility={setContextMenuvisibility}
            contextMenuPosition={contextMenuPosition}
            handleHideLayerContextMenu={handleHideLayerContextMenu}
            handleDeleteallKeyframe={handleDeleteallKeyframe}
            handleDeleteLayer={deleteComponentOnCanvas}
            layercontextMenuRef={layercontextMenuRef}
            playPause={playPause}
            multiSelectedComps={multiSelectedComps}
            setContextMenuPosition={setContextMenuPosition}
            selectedComponent={selectedComponent}
            deletionLayer={deletionLayer}
            setSelectedComponent={setSelectedComponent}
          />
        ) : null)}
      {copyPasteContextMenuVisibility && (
        <CopyPasteContextMenu
          isVisible={copyPasteContextMenuVisibility}
          position={copyPasteContextMenuPosition}
          handleOutsideClick={handleOutsideClick}
          setCopyPasteContextMenuVisibility={setCopyPasteContextMenuVisibility}
          copyKeyFrames={copyKeyFrames}
          pasteKeyFrame={pasteKeyFrame}
          deleteKeyFrame={deleteKeyFrame}
          copyPropertySelected={copyPropertySelected}
        />
      )}
    </>
  );
}

// const forwardRefDiv = forwardRef(Layers);
// export default forwardRefDiv;

export default Layers;
