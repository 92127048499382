import axios from "axios";
const contentApiEndPoint = "https://qa-contentapi.indegene.com"; //"https://qa-contentapi.indegene.com";
/// "https://dev-contentapi.indegene.com";

const storageApiEndPoint = "https://qa-storageapi.indegene.com";
// "https://dev-storageapi.indegene.com";

export const instanceCS = axios.create({
  baseURL: contentApiEndPoint,
  timeout: 15000,

  // headers: { "X-Custom-Header": "foobar" },a
});

// Add a request interceptor
instanceCS.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceCS.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const instanceCSStorage = axios.create({
  baseURL: storageApiEndPoint,
  timeout: 8000,
  // headers: { "X-Custom-Header": "foobar" },
});

// Add a request interceptor
instanceCSStorage.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceCSStorage.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const instanceCSUpload = axios.create({
  baseURL: contentApiEndPoint,
  timeout: 60 * 4 * 1000,
});
//testing
// Add a request interceptor
instanceCSUpload.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceCSStorage.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// export default instanceCS;

export default instanceCS;
