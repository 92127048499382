// import axios from "./index";
import axios from "axios";

import {
  instance,
  instanceStorage,
  instanceUpload,
} from "../services/axiosConfig";
import { instanceCS } from "../services/axiosConfigCS";
// import { instanceNode } from "../services/nodeapi";

import { poolData, indentityPoolId } from "../Uitls/Userpools";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { awsRegion } from "../Uitls/Util";
/* export const saveAdditionalInfo = (moduleId, payload) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/additionalinformation`,
    payload,
    {}
  );
};
 */
/* export const deleteCTAs = (moduleId, payload, cb) => {
  instance
    .delete(`/api/modulems/modules/${moduleId}/cta/bulk`, { data: payload })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
}; */
/* export const deleteClaimGroups = (moduleId, payload, cb) => {
  instance
    .delete(`/api/modulems/modules/${moduleId}/groups/bulk`, { data: payload })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
}; */
/* export const deleteChannels = (moduleId, payload, cb) => {
  instance
    .delete(`/api/modulems/modules/${moduleId}/channels/bulk`, {
      data: payload,
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
 */
/* export const createChannels = (moduleId, payload, cb) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/channels/bulk`,
    payload,
    {}
  );
 */
// .then((response) => {
//   cb(response);
// })
// .catch((err) => {
//   const errorCode = err.response && err.response.status;
//   cb("", err, errorCode);
// });
/* };

export const createCTAs = (moduleId, payload, cb) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/cta/bulk`,
    payload,
    {}
  ); */
// .then((response) => {
//   cb(response);
// })
// .catch((err) => {
//   const errorCode = err.response && err.response.status;
//   cb("", err, errorCode);
// });
/* };
export const saveGroupsWithClaims = (moduleId, payload, cb) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/groups/bulk`,
    
    payload,
    {}
  );
  
};
 */

export const getModuleDetailsById = (moduleId, cb) => {
  instance
    .get(`/api/modulems/modules/${moduleId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
//api/modulems/contentstudio/module/atoms?moduleId=376b82be-55f4-4204-9ce7-805e0b5f471b,284db554-b4df-4c6e-883f-9a811b536109,2380e7eb-5ccb-4418-80a9-01c7bc0c2744
export const getBrandsAndCampaigns = (cb) => {
  instance
    .get("/api/modulems/assets/brandcampaigns", {
      headers: { Some: "Something" },
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getRecentlyViewed = (type, cb) => {
  instanceCS
    .get(`/api/contentms/content/recent/getRecentViews?contentType=${type}`, {
      headers: { Some: "Something" },
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

// export const searchModules = (formData, options, cb) => {
//   instanceUpload
//     .post("api/modulems/contentstudio/modules/search", formData, options)
//     .then((response) => {
//       cb(response);
//       console.log(response);
//     })
//     .catch((err) => {
//       const errorCode = err.response && err.response.status;
//       // console.log(errorCode);
//       cb("", err, errorCode);
//     });
// };

//4dd6be19-c57c-4403-a02c-00a0eb381d0a
export const getAtomsFromProceed = (assetID, cb) => {
  instance
    .get(`api/modulems/contentstudio/module/atoms?moduleId=${assetID}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

//GET CORE CLAIM CATEGORIES
export const getCoreClaimCategories = (cb, brandId) => {
  instance
    //.get(`/api/storagems/assets/coreclaimscategories?brand=${brand}`)
    .get(`/api/modulems/brands/${brandId}/categoryclaims`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb("", err, err.response && err.response.status);
    });
};

// GET CORE CLAIM CATEGORIES VEEVA MODULE
export const getCoreClaimCategoriesforVeeva = (cb) => {
  instance
    .get("/api/modulems/contentstudio/contentmodules/categories")
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb("", err, err.response && err.response.status);
    });
};

//GET Module ID deyails
export const getModuleDetailsByModuleId = (ModuleId, cb, type) => {
  // let url = "/api/modulems/modules/";
  let url =
    type === "veevaModule"
      ? "/api/modulems/contentstudio/contentmodules"
      : "/api/modulems/modules";

  instance
    //.get(`/api/storagems/assets/coreclaimscategories?brand=${brand}`)
    .get(`${url}/${ModuleId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb("", err, err.response && err.response.status);
    });
};

export const sendSaveModuleIdHistory = (ids, cb, options) => {
  // console.log("REquest IDs", ids);
  instanceCS
    // .post("http://imccontentapi.ap-south-1.elasticbeanstalk.com/api/contentms/modulehistory/saveModuleIdHistory", ids,options )
    .post("/api/contentms/content/recent/saveContentHistory", ids, options)
    .then((response) => {
      cb(response);
      // console.log("Response IDs", response.data);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getDeConstStatus = (ids, cb) => {
  // console.log("REquest IDs", ids);
  instance
    .post("/api/modulems/assets/status", ids)
    .then((response) => {
      cb(response);
      // console.log("Response IDs", response.data);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getAssetsStatus = (cb) => {
  return instance
    .get("api/modulems/assets/status")
    .then((response) => {
      cb(response);
      console.log("Pending", response.data);
      return response.data;
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const uploadSuppVisual = (formData, options, cb) => {
  instanceStorage
    .post("/api/storagems/assets", formData, options)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
//multipart/form-data

const opt = {
  headers: {
    "Content-Type": "multipart/form-data",
    // Authorization: "Bearer " + token,
    "Accept-Language": "en-US,en;q=0.9",
    "Access-Control-Allow-Origin": "*",
  },
};

export const browseImages = (formData, cb) => {
  // console.log(formData)
  instanceCS
    .post("/api/contentms/banner/bannerImage", formData, opt)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
// export const getModulesBusinessRules = (cb) => {
//   return instance
//     .get("api/modulems/assets/738928d1-d6ab-41a5-99d1-33c35e8aa3fe/modules")
//     .then((response) => {
//       cb(response);
//       return response.data;
//     })
//     .catch((err) => {
//       const errorCode = err.response && err.response.status;
//       cb("", err, errorCode);
//     });
// };

export const getModulesBusinessRules = (assetId, cb) => {
  return instance
    .get(`/api/modulems/assets/${assetId}/modules`)
    .then((response) => {
      cb(response);
      return response.data;
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

// export const saveBusinessRules = (moduleId, payload) => {
//   return instance
//   .put(`/api/modulems/modules/${moduleId}/businessrules/bulk`,
//   payload)

// }

// export const saveBusinessRuleNotes = (moduleId, payload) => {
//   return instance
//   .put(`/api/modulems/modules/${moduleId}/businessrulenotes/bulk`,
//   payload)

// }

// export const createModuleDetails = (payLoad, cb) => {
//   instance
//     .post("/api/modulems/modules", payLoad)
//     .then((response) => {
//       cb(response);
//     })
//     .catch((err) => {
//       const errorCode = err.response && err.response.status;
//       cb("", err, errorCode);
//     });
// };

// export const saveClaims = (moduleId, payload, cb) => {
//   instance
//     .put(`/api/modulems/modules/${moduleId}/claimfamily`, payload)
//     .then((response) => {
//       cb(response);
//     })
//     .catch((err) => {
//       const errorCode = err.response && err.response.status;
//       cb("", err, errorCode);
//     });
// };

// export const deleteClaims = (moduleId, payload, cb) => {
//   instance
//     .delete(`/api/modulems/modules/${moduleId}/claims/bulk`, { data: payload })
//     .then((response) => {
//       cb(response);
//     })
//     .catch((err) => {
//       const errorCode = err.response && err.response.status;
//       cb("", err, errorCode);
//     });
// };

const options = {
  headers: {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + token,
    "Accept-Language": "en-US,en;q=0.9",
    "Access-Control-Allow-Origin": "*",
  },
};
export const cardInfoForcardIds = (formData, cb) => {
  instanceUpload
    .post("api/modulems/contentstudio/modules/claimcategory", formData, options)
    .then((response) => {
      cb(response);
      console.log(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      console.log(errorCode);
      cb("", err, errorCode);
    });
};
export const searchModules = (formData, cb, linkedModulesType) => {
  let moduleInUrl =
    linkedModulesType === "nextModule" ? "modules" : "contentmodules";
  instanceUpload
    .post(`api/modulems/contentstudio/${moduleInUrl}/search`, formData, options)
    .then((response) => {
      cb(response);
      console.log(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      console.log(errorCode);
      cb("", err, errorCode);
    });
};

export const getAssetdetails = (moduleInfo, cb) => {
  const assetType =
    moduleInfo.assetType === "TEMPLATE"
      ? "api/contentms/assets?assetType=TEMPLATE"
      : "api/contentms/assets?assetType=BANNER";
  instanceCS
    //.post("/api/contentms/assets?assetType=BANNER", moduleInfo)
    .post(`${assetType}`, moduleInfo)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const modifyAsset = (assetId, moduleInfo, cb) => {
  instanceCS
    .patch(`/api/contentms/assets/${assetId}`, moduleInfo)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
/*    &assetName=${bannerNameInput} */
export const searchBanners = (type, dropdownInfo, bannerNameInput, cb) => {
  // console.log('dropdownINfor',dropdownInfo);
  instanceCS
    .post(
      `api/contentms/assets?brandId=${dropdownInfo.brand}&campaignId=${dropdownInfo.campaign}&marketCode=${dropdownInfo.market}&assetType=${type}&assetName=${bannerNameInput}&isAnimated=${dropdownInfo.isAnimated}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getRecentlyViewedBannerCards = (type, cb) => {
  instanceCS
    .get(`/api/contentms/content/recent/getRecentViews?contentType=${type}`, {
      headers: { Some: "Something" },
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

//http://imccontentapi.ap-south-1.elasticbeanstalk.com/api/contentms/filespackage/createBannerZipFile
export const sendExport = (exportObj, cb) => {
  instanceCS
    .post(`/api/contentms/filespackage/createBannerZipFile`, exportObj)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

//http://imccontentapi.ap-south-1.elasticbeanstalk.com/api/contentms/filespackage/getBannerZipFile
// getBannerZipFile?fileName=Banner-name&fileId=55
// http://imccontentapi.ap-south-1.elasticbeanstalk.com/api/contentms/filespackage/getBannerZipFile
export const getExport = (url, filename, cb) => {
  instanceCS
    .get(
      `/api/contentms/filespackage/getBannerZipFile?fileName=${filename}'&fileId=${url}`,
      { headers: { Some: "Something" }, responseType: "blob" }
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const putSaveAssets = (assetId, payload, cb) => {
  return instanceCS
    .put(
      `api/contentms/assets/${assetId}/assetpages/assetpagecomponent`,

      payload,
      {}
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getSaveAssets = (assetId, cb) => {
  return instanceCS
    .get(`api/contentms/assets/${assetId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const imagePatch = (assetId, imageurl, cb) => {
  return instanceCS
    .patch(`/api/contentms/assets/${assetId}/patchURL`, imageurl)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

///api/contentms/banner/export/2c9e801a772a889f01772af8424d0014/?exportType=jpg

export const exportBannerType = (assetId, payload, type, cb) => {
  return instanceCS
    .post(
      `/api/contentms/banner/export/${assetId}/?exportType=${type}`,
      payload,
      {}
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const cloneBanner = (assetId, formData, cb) => {
  return instanceCS
    .post(`/api/contentms/assets/${assetId}/cloneAsset `, formData)
    .then((response) => {
      console.log(response, "CBresponse");
      cb(response);
      // console.log("Response IDs", response.data);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const basedOnBanner = (assetId, formData, cb) => {
  return instanceCS
    .post(
      `/api/contentms/assets/${assetId}/cloneAsset?assetType=BANNER `,
      formData
    )
    .then((response) => {
      console.log(response, "CBresponse");
      cb(response);
      // console.log("Response IDs", response.data);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const deleteBanners = (assetId, cb) => {
  return instanceCS
    .delete(`api/contentms/assets/${assetId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

// export const getImage = (html, cb) => {
//   return  instanceNode
//     .post(`http://localhost:5000`, html )
//     .then((response) => {
//       console.log(response,'CBresponse')
//       cb(response);
//       // console.log("Response IDs", response.data);
//     })
//     .catch((err) => {
//       const errorCode = err.response && err.response.status;
//      cb("", err, errorCode);
//     });
// };

export const getCognitoSession = (cb) => {
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.getSession(function (err, session) {
      if (!err) {
        cb(session);
        return;
      }
      cb(undefined);
    });
  } else {
    cb(undefined);
  }
};

export const getCognitoIndentityCredentials = (session, AWS, cb) => {
  AWS.config.region = awsRegion;
  let tokenKey = `cognito-idp.${AWS.config.region}.amazonaws.com/${poolData.UserPoolId}`;
  let logins = {};
  logins[tokenKey] = session.getIdToken().getJwtToken();
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: indentityPoolId,
    Logins: logins,
  });
  AWS.config.credentials.get((err) => {
    if (!err) {
      cb(AWS.config.credentials);
      return;
    }
    cb(undefined);
  });
};

export const preview = (obj, cb) => {
  instanceCS
    .post(`api/contentms/banner/bannerPage`, obj)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getloggedOut = (cb) => {
  instanceCS
    .get(`api/contentms/validate/token`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const uploadAnImageLink = (formData, cb) => {
  instanceCS
    .post(
      `api/contentms/banner/${formData.assetId}/images/upload?imageUrl=${formData.imageLink}&imageType=Link`,
      formData.imageLink
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const uploadAnImageDragAndDrop = (assetId, formData, cb) => {
  instanceCS
    .post(
      `api/contentms/banner/${assetId}/images/upload?imageType=Upload`,
      formData
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

//imccontentapi.ap-south-1.elasticbeanstalk.com/api/contentms/filespackage/createBannerZipFile
export const fileSizeValidation = async (exportObj, cb) => {
  let x = await instanceCS
    .post(`/api/contentms/filespackage/validate/zip/filesize`, exportObj)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

//http://imccontentapi.ap-south-1.elasticbeanstalk.com/api/contentms/filespackage/createBannerZipFile
export const fileSizeValidationAsync = async (exportObj) => {
  const token = window.localStorage.getItem("userToken");
  let x = await axios({
    method: "POST",
    url: `https://dev-contentapi.indegene.com/api/contentms/filespackage/validate/zip/filesize`,
    headers: { authorization: token },
    data: exportObj,
  });
  return x.json();
};

export const lockUnlockBanner = (bannerid, lockStatus, assetType, cb) => {
  instanceCS
    .post(
      `/api/contentms/banners/${bannerid}?action=${lockStatus}&assetType=${assetType}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const RVCVFile = (obj, name, cb) => {
  instanceCS
    .post(`/api/contentms/filespackage/export/rvcv?bannerName=${name}`, obj, {
      responseType: "blob",
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

// export const exportAsJpeg = async (exportObj, cb) => {
//   const token = window.localStorage.getItem("userToken");
//   let request = await axios({
//     method: "POST",
//     url: `https://test-imageconvert.indegene.com/`,
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token,
//     },
//     data: exportObj,
//     responseType: "json",
//   });
//   return request
//     .then((res) => {
//       return res;
//     })
//     .catch((error) => {
//       console.log(error);
//       return error;
//     });
// };
export const veevaIntegration = (formData, type, assetId, cb) => {
  // console.log(formData)
  instanceCS
    .post(`/api/contentms/banner/${assetId}/veeva/${type}/publish`, formData)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const LocalizedBusinessrulesModules = (payload, cb) => {
  instance
    .post("api/modulems/contentstudio/modules/businessrules", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const putGotoEditorModules = (payLoad, cb) => {
  return instanceCS
    .put(
      `/api/contentms/assetmodule/${payLoad.assetId}`,

      payLoad.finalObj
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getVeevaRuleset = (payload, cb) => {
  instance
    .post("api/modulems/contentstudio/contentmodules/ruleset", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const putVeevaModuleList = (assetId, payload, cb) => {
  instanceCS
    .put(`api/contentms/assetmodule/${assetId}`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getVeevaCardsInleftcont = (payload, cb) => {
  instance
    .post("api/modulems/contentstudio/contentmodules/cardinfo", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getAllTempltesList = (type, cb) => {
  instanceCS
    .get(`/api/contentms/assets/?assetType=${type}`, {
      headers: { Some: "Something" },
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getVeevaApprovedStatus = (assetId, cb) => {
  return instanceCS
    .put(`/api/contentms/banner/${assetId}/mlr/status`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getMLRstatus = (assetId, cb) => {
  instanceCS
    .patch(`/api/contentms/banner/${assetId}/synch/mlrstatus`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getbannerandTemplateDetails = (assetId, cb) => {
  instanceCS
    .get(`api/contentms/assets/${assetId}/details`, {
      headers: { Some: "Something" },
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getNewBrandsAndcampaigns = (cb) => {
  instanceCS
    .get("/api/contentms/assets/brand-campaigns", {
      headers: { Some: "Something" },
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
