import React, { useState, useContext } from "react";
import "../../styles/pages/Banners/BannerManager.scss";
import Drawer from "../../components/Dashboard/Drawer.js";
import BannerForms from "./BannerForms.js";
import { useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";
import NewBannerModal from "../../components/BannerManager/NewBannerModal";
import BannerHeader from "../../components/BannerManager/BannerHeader";
import ValueContext from "../../ContextAPi/appContext";
import { useTranslation } from "react-i18next";

export default function BannerManager(props) {
  const appData = useContext(ValueContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [showNewBanner, setShowNewBanner] = useState(false);

  const [isAnimatedBanner, setIsAnimatedBanner] = useState(false);
  // const navigateToEditor = () => push('./editor');
  const openNewBannerModal = (isRedirectFlag = false, genericInfo = {}) => {
    setShowNewBanner(!showNewBanner);
    if (isRedirectFlag === true) {
      appData.setFlagEditBanner(false);
      appData.setBannersEditCardData({
        cards: "",
      });
      //history.push("/banner-editor");
    }
  };
  return (
    <>
      {/* <BannerHeader />
       <Drawer /> */}
      <div className="banner-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 banner-manager">
              <div className="bannerTitleRow">
                <div className="bannerManagerTitle">
                  {t("bannerManager.bannerManager")}
                </div>
                <div
                  type="button"
                  className="newBanner"
                  onClick={openNewBannerModal}
                >
                  {t("bannerManager.newBanner")}
                </div>
              </div>
              {showNewBanner && (
                <NewBannerModal
                  openNewBannerModal={openNewBannerModal}
                  showNewBanner={showNewBanner}
                />
              )}
              <div>
                <BannerForms />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
