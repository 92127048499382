import React, { useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import "../../styles/components/Editor.scss";
import "../../styles/components/EditorModal.scss";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import { nameValidation } from "../../Uitls/Util.js";

import DropDownWithLabel from "../DropDownWithLabel.js";
import {
  getBrandsAndCampaigns,
  cloneBanner,
  getCognitoSession,
  getCognitoIndentityCredentials,
} from "../../services/apis";
import DropDownWithSearch from "../../Uitls/DropDownWithSearch";
import { toast } from "react-toastify";
//Denovo related imports
//MArket Data returnlated imports and varaihle declaration
import { isoCountryTableName, awsRegion } from "../../Uitls/Util";
import ValueContext from "../../ContextAPi/appContext";
import Button from "@material-ui/core/Button";
var dynamo = require("dynamodb");

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialog: {
    width: "362px",
    height: "426px",
  },
}));
export default function BasedOnModal(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { recentlyViewedData } = props;
  const appData = useContext(ValueContext);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [helperText, setHelperText] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);

  const [genericInfo, setGenericInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
    bannerNameInput: "",
    //tagName: "",
  });
  const handleFormReset = () => {
    setGenericInfo({
      brand: "",
      campaign: "",
      market: "",
      bannerNameInput: "",
      //tagName: "",
    });
    setSelectedMarket("");
  };

  React.useEffect(() => {
    setGenericInfo({ ...genericInfo, campaign: "" });
  }, [genericInfo.brand]);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    fetchBrandsAndCampaigns();
    fetchMarketData();
    getCampaignListToPopulate(
      appData?.genericInfo?.brand || recentlyViewedData?.brand
    );
    appData?.genericInfo?.brand &&
      setGenericInfo({
        ...genericInfo,
        campaign: appData?.genericInfo?.campaign,
      });
  }, []);
  //Fetch Brands and campaigns
  const fetchBrandsAndCampaigns = () => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        toast.error(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          setBrandsAndCampaigns(data);
        }
      }
    });
  };

  //Returns Campaign list to populate in Drop down
  const getCampaignListToPopulate = (Brand) => {
    if (Brand && brandsAndCampaigns.length > 0) {
      const campaigns = brandsAndCampaigns.filter((object) => {
        return object.brandName === Brand;
      })[0]?.campaigns;
      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }
    return [];
  };

  function validateForm() {
    return (
      genericInfo.bannerNameInput?.length > 2 &&
      appData?.genericInfo?.brand?.length > 0 &&
      appData?.genericInfo?.campaign?.length > 0
      //&&
      //appData?.genericInfo?.tagName?.length > 0
    );
  }
  const handleChangeInfoDataBlur = (e) => {
    if (e.target.value.length < 3 && e.target.value.length !== 0) {
      toast.error(
        <div className="notificationText">
          {t("bannerManager.bnrNameMinErr")}
        </div>
      );
    }
  };
  const handleChangeInfoData = (e) => {
    const { name, value } = e.target;
    setGenericInfo({ ...genericInfo, [name]: value });
    if (name === "bannerNameInput") {
      if (e.target.value.length > 255) {
        toast.error(
          <div className="notificationText">
            {t("bannerManager.bnrNameErr")}
          </div>
        );
      }
    }
    if (brandsAndCampaigns) {
      brandsAndCampaigns.map((brand) => {
        if (brand.brandName === value) {
          appData.setBrandId(brand.brandId);
        }
        brand.campaigns.map((campaign) => {
          if (campaign.campaignName === value)
            appData.setCampaignId(campaign.campaignId);
        });
      });
    }
    let obj = { ...appData?.genericInfo, [name]: value };
    appData.updateGenericInfo(obj);
    setErrorMsg(false);
    setHelperText("");
  };

  // const fetchMarketData = () => {
  //   updateDynamicDBConfig(dynamo)
  //   var params = {
  //     TableName: isoCountryTableName
  //   };
  //   getDyanamoDBInstance(dynamo).scan(params, (error, data) => {
  //     if (data) {
  //       let marketData = data.Items
  //       if (marketData && marketData.length > 0) {
  //         marketData = [...marketData].map((market) => {
  //           let object = {
  //             code: market.countryCode['S'],
  //             shortName: market.ShortName['S'],
  //             name: market.Name['S']
  //           }
  //           object['displayName'] = `${object.code} - ${object.name}`
  //           return object
  //         })
  //         if (marketData && marketData.length > 0) {
  //           const sortCountryCode=marketData.sort(function(a,b){
  //             if(a.code.toLowerCase() < b.code.toLowerCase()) {
  //               return -1;
  //             }
  //             if (a.code.toLowerCase() > b.code.toLowerCase()) {
  //               return 1;
  //             }
  //             return 0;
  //           })
  //           setMarketDataSource(sortCountryCode)
  //         }
  //       }
  //     }
  //   })
  // }

  const fetchMarketData = () => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setMarketDataSource(marketData);
                }
              }
            }
          });
        });
      }
    });
  };

  const handleMarketChange = (market) => {
    if (market) {
      setSelectedMarket(market);
      setGenericInfo({ ...genericInfo, market: market.name });
      appData.setMarketCode(market.code);
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        market: market.name,
      });
    }
  };

  const getSelectedMarket = () => {
    if (!selectedMarket && appData && appData?.genericInfo?.market) {
      const countryName = appData?.genericInfo?.market;
      const countryCode = appData.marketCode;
      const displayName = `${countryCode} - ${countryName}`;
      return { name: countryName, code: countryCode, displayName: displayName };
    }
    return selectedMarket;
  };

  const categoriesErr = (value) => {
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  };
  const cloneBannerData = () => {
    if (!nameValidation(genericInfo.bannerNameInput, "Banner")) return;

    const formData = new FormData();
    formData.append("brandName", appData?.genericInfo?.brand || "");
    formData.append("name", appData?.genericInfo?.bannerNameInput);
    formData.append("campaign", appData?.genericInfo?.campaign);
    formData.append("brandId", appData?.brandId);
    formData.append("campaignId", appData?.campaignId);
    formData.append("marketName", appData?.genericInfo?.market);
    formData.append("marketCode", appData?.marketCode);

    cloneBanner(
      appData.assetInfo.assetId,
      formData,
      (response, err, errorCode, errorMessage) => {
        if (err) {
          const errComponent = ErrComponentToRender(errorCode);
          if (err.response.data?.errorCode === "ERR_CS_ASSETNAME_EXISTS") {
            toast.error(
              <div className="notificationText">
                {t("apiErrors.errBannerExist")}
              </div>
            );
          } else {
            toast.error(
              <div className="notificationText">{t("apiErrors.err500")}</div>
            );
          }
        } else {
          const obj = {
            brand: response.data.brandName,
            campaign: response.data.campaign,
            market: response.data.marketName,
            bannerNameInput: response.data.assetName,
          };
          appData.updateGenericInfo({ ...appData?.genericInfo, ...obj });
          setSelectedMarket(obj.market);
          appData.setAssetInfo({
            assetId: "",
            assetName: obj.bannerNameInput,
          });

          localStorage.removeItem("assetId");
          localStorage.setItem("assetId", response.data.id);
          history.push({
            pathname: `/banner-editor/${response?.data?.id}`,
          });
        }
      }
    );
  };
  // const handleTagChange = (e) => {
  //   const { name, value } = e.target;
  //   let obj = appData?.genericInfo;
  //   obj["tagName"] = value;
  //   // appData.updateGenericInfo(obj);
  //   appData.updateGenericInfo({ ...appData?.genericInfo, ...obj });
  // };
  const dropdownWidth = useRef(null);

  return (
    <>
      <Dialog
        disableBackdropClick
        open={props.showBasedOn}
        onClose={() => props.setShowBasedOn(false)}
        className="basedOnModal"
        aria-labelledby="form-dialog-title"
        overlaystyle={{ backgroundColor: "transparent" }}
      >
        <DialogTitle id="form-dialog-title">
          <div className="baseOnModalHeading">
            {t("bannerManager.basedOnTitle")}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row newbannerDetails bsdon" ref={dropdownWidth}>
            <TextField
              variant="outlined"
              label={t("bannerManager.bannerName")}
              name="bannerNameInput"
              className="bannerNameTextfield"
              fullWidth={true}
              value={genericInfo.bannerNameInput}
              onChange={handleChangeInfoData}
              onBlur={handleChangeInfoDataBlur}
              autoComplete="off"
              helperText={helperText}
              error={errorMsg}
            ></TextField>
            <TextField
              variant="outlined"
              value={appData?.genericInfo?.brand}
              name="brand"
              label={t("bannerManager.brandName")}
              className="bannerNameTextfield brandName"
              fullWidth={true}
              onChange={handleChangeInfoData}
              autoComplete="off"
              disabled="true"
            ></TextField>
            {getSelectedMarket() && marketDataSource.length > 0 && (
              <DropDownWithSearch
                options={{
                  value: getSelectedMarket(),
                  name: "market",
                  label: t("bannerManager.market"),
                  items: marketDataSource,
                  handleChange: handleMarketChange,
                }}
                className="custonScrollBar"
              />
            )}
            <div className="campaignIP campaignfield">
              <DropDownWithLabel
                options={{
                  value: appData?.genericInfo?.campaign,
                  name: "campaign",
                  label: t("bannerManager.campaign"),
                  items: getCampaignListToPopulate(appData?.genericInfo?.brand),
                  handleChange: handleChangeInfoData,
                }}
                menuWidth={
                  dropdownWidth.current?.getBoundingClientRect().width * 0.95 ||
                  undefined
                }
              />
            </div>
            {/* <TextField
              variant="outlined"
              label={t("bannerManager.tagName")}
              name="tagName"
              className="tagNameTextfield"
              value={appData?.genericInfo?.tagName}
              onChange={handleTagChange}
              fullWidth={true}
              autoComplete="off"
            ></TextField> */}
          </div>
        </DialogContent>
        <DialogActions className="modalActions">
          <Button
            onClick={() => {
              props.setShowBasedOn(false);
              handleFormReset();
            }}
            color="primary"
            className="cancelBtn"
          >
            {t("bannerManager.cancel")}
          </Button>
          <Button
            onClick={cloneBannerData}
            type="submit"
            className="btn btn-primary"
            disabled={!validateForm()}
            autoFocus
          >
            {t("bannerManager.proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
