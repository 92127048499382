import React from "react";
import TemplatesContainer from "../BannerManager/TemplatesContainer";
import "../../styles/pages/Banners/BannerManager.scss";

export default React.memo(function GoToTeplatesContainer(props) {
  const { preDefined, setPredefined, customTemp, setCustomBannerSize } = props;
  return (
    <>
      {props.responseData.map((recentlyViewedData, index) => {
        return (
          <div className="col-md-3 recentlyCardsCol">
            <TemplatesContainer
              preDefined={preDefined}
              setPredefined={setPredefined}
              customTemp={customTemp}
              setCustomBannerSize={setCustomBannerSize}
              recentlyViewedData={recentlyViewedData}
              search={props.search}
              responseData={props.responseData}
              setResponseData={props.setResponseData}
              // getRecentlyViewedBanner={props.getRecentlyViewedBanner}
              // removeSearchresultCard={props.removeSearchresultCard}
              setLoading={props.setLoading}
              // generateRVCV={props.generateRVCV}
              // disableProceedbut={props.disableProceedbut}
              // setDisableProceedbut={props.setDisableProceedbut}
              // selectMultipleBanner={props.selectMultipleBanner}
              // setSelectMultipleBanner={props.setSelectMultipleBanner}
              // arrcheckboxSelection={props.arrcheckboxSelection}
              // setArrcheckboxSelection={props.setArrcheckboxSelection}
              // indexResponseData={index}
              // responseData={props.responseData}
              // genericInfo={props.genericInfo}
              // setGenericInfo={props.setGenericInfo}
              // isAnimatedBanner={props.isAnimatedBanner}
              hide3dotIcon={props.hide3dotIcon}
              getBrandsToPopulate={props.getBrandsToPopulate}
            />
          </div>
        );
      })}
    </>
  );
});

// export default GotoRecentlyViewed
