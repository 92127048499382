import styled from "styled-components";

const forgotTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #3e3e47;
`;
export default forgotTitle;

// function mousemove(e) {
//     const rect = el.getBoundingClientRect();
//     let parentswidth = rect.left - el.offsetLeft;
//     let parentsHeight = rect.top - el.offsetTop;

//     let prevHeight = el.offsetHeight;
//     let prevWidth = el.offsetWidth;
//     if (false) {
//       if (currentResizer.classList.contains("south")) {
//         el.style.height = rect.height - (prevY - e.clientY) + "px";
//       }
//       if (currentResizer.classList.contains("north")) {
//         el.style.height = rect.height + (prevY - e.clientY) + "px";
//         el.style.top =
//           rect.top - (prevY - e.clientY) - parentsHeight + "px";
//       }
//       if (currentResizer.classList.contains("east")) {
//         el.style.width = rect.width + (prevX - e.clientX) + "px";
//         el.style.left =
//           rect.left - (prevX - e.clientX) - parentswidth + "px";
//       }
//       if (currentResizer.classList.contains("west")) {
//         el.style.width = rect.width - (prevX - e.clientX) + "px";
//       }

//       if (currentResizer.classList.contains("se")) {
//         el.style.width = rect.width - (prevX - e.clientX) + "px";
//         el.style.height = rect.height - (prevY - e.clientY) + "px";
//       } else if (currentResizer.classList.contains("sw")) {
//         el.style.width = rect.width + (prevX - e.clientX) + "px";
//         el.style.height = rect.height - (prevY - e.clientY) + "px";
//         el.style.left =
//           rect.left - (prevX - e.clientX) - parentswidth + "px";
//       } else if (currentResizer.classList.contains("ne")) {
//         el.style.width = rect.width - (prevX - e.clientX) + "px";
//         el.style.height = rect.height + (prevY - e.clientY) + "px";
//         el.style.top =
//           rect.top - (prevY - e.clientY) - parentsHeight + "px";
//       } else if (currentResizer.classList.contains("nw")) {
//         el.style.width = rect.width + (prevX - e.clientX) + "px";
//         el.style.height = rect.height + (prevY - e.clientY) + "px";
//         el.style.top =
//           rect.top - (prevY - e.clientY) - parentsHeight + "px";
//         el.style.left =
//           rect.left - (prevX - e.clientX) - parentswidth + "px";
//       }
//     } else {
//       if (currentResizer.classList.contains("south")) {
//         el.style.height = rect.height - (prevY - e.clientY) + "px";
//       }
//       if (currentResizer.classList.contains("north")) {
//         el.style.height = rect.height + (prevY - e.clientY) + "px";
//         el.style.top =
//           rect.top - (prevY - e.clientY) - parentsHeight + "px";
//       }
//       if (currentResizer.classList.contains("east")) {
//         ;
//         el.style.width = rect.width + (prevX - e.clientX) + "px";
//         el.style.height = (prevHeight / prevWidth) * el.offsetWidth + "px";
//         el.style.left =
//           rect.left - (prevX - e.clientX) - parentswidth + "px";
//       }
//       if (currentResizer.classList.contains("west")) {
//         el.style.width = rect.width - (prevX - e.clientX) + "px";
//         el.style.height = (prevHeight / prevWidth) * el.offsetWidth + "px";
//       }

//       if (currentResizer.classList.contains("se")) {
//         el.style.width = rect.width - (prevX - e.clientX) + "px";
//         el.style.height = (prevHeight / prevWidth) * el.offsetWidth + "px";
//       } else if (currentResizer.classList.contains("sw")) {
//         el.style.width = rect.width + (prevX - e.clientX) + "px";
//         el.style.height = (prevHeight / prevWidth) * el.offsetWidth + "px";
//         el.style.left =
//           rect.left - (prevX - e.clientX) - parentswidth + "px";
//       } else if (currentResizer.classList.contains("ne")) {
//         el.style.width = rect.width - (prevX - e.clientX) + "px";
//         el.style.height = (prevHeight / prevWidth) * el.offsetWidth + "px";
//         el.style.top =
//           rect.top - (prevY - e.clientY) - parentsHeight + "px";
//       } else if (currentResizer.classList.contains("nw")) {
//         el.style.width = rect.width + (prevX - e.clientX) + "px";
//         el.style.height = (prevHeight / prevWidth) * el.offsetWidth + "px";
//         el.style.top =
//           rect.top - (prevY - e.clientY) - parentsHeight + "px";
//         el.style.left =
//           rect.left - (prevX - e.clientX) - parentswidth + "px";
//       }
//     }
//     prevX = e.clientX;
//     prevY = e.clientY;
//   }
