import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useTranslation } from "react-i18next";
import "../../styles/components/Editor.scss";
import "../../styles/components/EditorModal.scss";
import ValueContext from "../../ContextAPi/appContext";
import Button from "@material-ui/core/Button";
import { deleteBanners } from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import DeleteBox from "../../images/Delete2.svg";

export default function BannerDeleteModal({
  setShowDelete,
  responseData,
  setResponseData,
  showDelete,
  recentlyViewedData,
  getRecentlyViewedBanner,
  searchType,
  removeSearchresultCard,
  card,
}) {
  const appData = useContext(ValueContext);

  const { t } = useTranslation();

  const handleClose = () => {
    setShowDelete(false);
  };

  const deleteBannerCards = () => {
    deleteBanners(recentlyViewedData.id, async (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
      } else {
        appData.setAssetInfo({
          assetId: "",
        });
        if (card === "TEMPLATE") {
          setResponseData.setData(
            setResponseData?.createdByMe?.status !== "me"
              ? responseData.filter(
                  (cardId) => cardId.id !== recentlyViewedData.id
                )
              : {
                  ...setResponseData?.createdByMe,
                  data: responseData.filter(
                    (cardId) => cardId.id !== recentlyViewedData.id
                  ),
                }
          );
          handleClose();
          return;
        }

        await removeSearchresultCard(recentlyViewedData.id);
        // } else {
        //   getRecentlyViewedBanner();
        // }
        handleClose();
      }
    });
  };

  return (
    <Dialog
      disableBackdropClick
      open={showDelete}
      onClose={handleClose}
      className="delete-modalTemp delete-modal"
      aria-labelledby="form-dialog-title"
      overlayStyle={{ backgroundColor: "transparent" }}
    >
      <DialogTitle id="form-dialog-title">
        <div className="delete-modalheading">
          <img className="deleteBoxTemp" src={DeleteBox} alt="deleteBox"></img>
          <span className="deleteBoxTextTemp">{"Delete"}</span>
        </div>
      </DialogTitle>
      <DialogTitle id="form-dialog-title">
        <div className="delete-modalSubHeading">
          Are you sure you want to
          <span className="DeleteOnly">{" Delete ?"}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="delete-modalContent">
          {t(
            card === "TEMPLATE"
              ? "bannerManager.deleteModal.subTitle1"
              : "bannerManager.deleteModal.subTitle"
          )}
        </div>
      </DialogContent>
      <DialogActions className="delete-actionsTemp">
        <Button onClick={handleClose} color="primary" className="no-btn">
          CANCEL
        </Button>
        <Button
          onClick={() => {
            deleteBannerCards();
          }}
          type="submit"
          className="btn btn-primary"
          autoFocus
        >
          PROCEED
        </Button>
      </DialogActions>
    </Dialog>
  );
}
