import React from "react";

import "../../styles/pages/ContentStudio/SearchAndViewAsset.scss";

export default function SearchAndViewAsset() {
  // const [open, setOpen] = useState(false);
  // const tempObj={
  //   brand:["Brand","Trucity"],
  //   channelType:["Channel Type","e-Detail"],
  //   campaign:["Campaign", "REWIND Data Outcomes"],
  //   market:["Market", "US"]
  // }

  // const handleClickOpen = () => {
  //     setOpen(true);
  //   };
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  return <div></div>;
  // return(
  //   <div className="LeftTab">
  //     <Button className="SMButton" variant="outlined" color="primary" onClick={handleClickOpen}>
  //       <div>
  //         <p className="FL">Select Module</p>
  //         <p className="SL">To Get Stsrted</p>
  //       </div>
  //     </Button>
  //     <DynDialogue open ={open} protectedValues={tempObj}  handleClose={handleClose}/>
  //   </div>
  // );
}
