import React from "react";
import { ChromePicker } from "react-color";
import "../styles/components/ColorPicker.scss";
import ClearText from "../images/ClearText.svg";
export default function ColorPicker(props) {
  const {
    handleColorChange,
    hideColorPicker,
    color,
    displayColorPicker,
    displayColorPickerBgColor,
    displayColorPickerBorder,
    displayColorPickerFontColor,
    displayColorPickerScrollColor,
    displaycolorpickerTextBg,
    collectColorOnChangeComplete,
  } = props;

  const showColorPicker =
    displayColorPickerFontColor || displaycolorpickerTextBg
      ? "displayTextcolor"
      : displayColorPicker || displayColorPickerBorder
      ? "displayButtoncolor"
      : displayColorPickerScrollColor
      ? "displayScrollColor"
      : "displayColor";

  React.useEffect(() => {
    let Element =
      document.getElementsByClassName("saturation-white")[0]?.children[1]
        .children[0];
    let hueComp =
      document.getElementsByClassName("flexbox-fix")[0].children[1]?.children[0]
        .children[0].children[0].children[1].children[0];
    let alphaComp =
      document.getElementsByClassName("flexbox-fix")[0].children[1]
        ?.children[1];
    if (Element) {
      // Element.addEventListener("mousedown", collectColorOnChangeComplete);
      Element.addEventListener("mouseup", collectColorOnChangeComplete);
    }
    if (hueComp) {
      // hueComp.addEventListener("mousedown", collectColorOnChangeComplete);
      hueComp.addEventListener("mouseup", collectColorOnChangeComplete);
    }
    if (alphaComp) {
      // alphaComp.addEventListener("mousedown", collectColorOnChangeComplete);
      alphaComp.addEventListener("mouseup", collectColorOnChangeComplete);
    }
    return () => {
      // Element.removeEventListener("mousedown", collectColorOnChangeComplete);
      Element.removeEventListener("mouseup", collectColorOnChangeComplete);
      // alphaComp.removeEventListener("mousedown", collectColorOnChangeComplete);
      alphaComp.removeEventListener("mouseup", collectColorOnChangeComplete);
      // hueComp.removeEventListener("mousedown", collectColorOnChangeComplete);
      hueComp.removeEventListener("mouseup", collectColorOnChangeComplete);
    };
  }, []);

  return (
    <>
      <div className={showColorPicker}>
        <div className="colorPicker">
          <h5 style={{ paddingTop: "-10%" }}>Fill Color</h5>
          {/*  <div className="cover" onClick={handleClose} /> */}
          <div className="closePicker">
            <img
              src={ClearText}
              alt=""
              className="clearTextIcon clearText"
              onClick={hideColorPicker}
            />
          </div>
        </div>
        <ChromePicker
          className="customChrome-picker"
          color={color}
          onChange={handleColorChange}
        />
      </div>
    </>
  );
}
