export const enJson = {
  uploadAssetText: {
    dragAndDrop: "Drag and Drop a File",
    dragAndDropReplace: "Drag and Drop a File to replace",
    or: "OR",
    uploadFiles: "Uploaded File(s)",
    remove: "Remove",
    imc: "IMC",
  },
  btnLabel: {
    skip: "SKIP",
    browse: "Browse",
  },
  resetPassword: {
    success: "Password Changed Successfuly",
    successMessage: "Please login again with your new credentials",
    failure: "Incorrect OTP entered",
  },
  dashBoard: {
    navLinksLabel: {
      dashboard: "Dashboard",
      moduleManager: "Module Manager",
      contentStudio: "Content Studio",
      logout: "Logout",
      banner: "Banner",
    },
    collapseContent: {
      authorAsset: "Author Asset",
      searchAsset: "Search & View Asset",
      templateManager: "Template Manager",
    },
    bannerCollapseContent: {
      templateManager: "Template Manager",
      bannerManager: "Banner Manager",
    },
    dashboardContent: {
      content: "Content",
      studio: "Studio",
    },
  },
  login: {
    bannertext: {
      content: "Content",
      studio: "Studio",
      contentStudioText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque ligula lectus, tincidunt a lorem bibendum,laoreet blandit diam. Quisque sit amet mollis turpis.",
    },
    loginFormLabel: {
      username: "Email address",
      password: "Password",
      remeber: "Remember Password",
      forgot: "Forgot Password?",
      login: "Login",
      emailerr: "Email is required",
      emailInvaliderr: "Invalid email address",
      passworderr: "Password is required",
    },
    forgotPassword: {
      forgotPass: "Forgot Password",
      forgotText:
        "Don't worry, you are just two steps away from accessing your account.",
      forgotText2: "We will send you an OTP to your registered email id",
      backToSignIn: "Back to Sign In",
      resetLabel: "SEND OTP",
      passworderr: "Password is required",
    },
    enterOtp: {
      otpTitle: "Enter OTP",
      otpText: "Enter the OTP that has been sent to your email id",
      otpLabel: "OTP",
      resetPasswordLabel: "Reset Password",
      otpExpire: "OTP Expires in ",
      resendOtp: "Resend OTP",
      verfiCodeErr: "OTP is required",
    },
    resetPassword: {
      regEmail: "Registered Email id",
      resetPassLabel: "Enter OTP",
      verificationCode: "OTP",
      newPassText: "Enter the OTP that has been sent to your email id",
      newPassword: "New Password",
      confirmPassword: "Confirm Password",
      changePassword: "Reset password",
      verfiCodeErr: "OTP is required",
      newpasswordErr: "New password is required",
      confpasswordErr: "Confirm password is required",
      confpasswordMatchErr: "Confirm password does not match",
      passIndicatorLabel: "Your password should have",
      sixChar: "At least 6 characters",
      alphanumeric: "One alphanumeric",
      specialChar: "One special character",
      capChar: "One capital character",
      sixCharErr: "At least 6 characters required",
      alphanumericErr: "One alphanumeric required",
      specialCharErr: "One special character required",
      capCharErr: "One capital character required",
    },
    signUp: {
      signup: "Sign up",
      email: "Email:",
      password: "Password:",
      givenName: "Given name:",
      familyName: "Family name:",
      phoneNumber: "Phone number:",
      submit: "Submit",
      verifyAccountNow: "Verify account now",
    },
  },
  generalText: {
    loginAgain: "Login again",
  },
  apiErrors: {
    err401: "Client failed to authenticate with the server",
    err500: "500 - A generic error occurred on the server",
    err404: "404 Not Found — The requested resource does not exist",
    err403:
      "401 - You are not authorized to access this page, Please login again",
    err601: "Please select Core Claim categories",
    err602: "Please provide atleast one input",
    err603: "Banner Name cannot be blank",
    errDefault: "Something went wrong, Please login again",
    errBannerExist: "Banner Name exists. Please enter a different banner name",
    errSaveBannerExist:
      "Cannot save Banner.. Banner with given name already exists. Please enter a different banner name.",
    errAssetSpace: "Asset Name can't start with spaces",
    errBannerSpace: "Banner Name can't start with spaces",
    errBannerBlank: "Please provide Banner name",
    errBannerUpdate: "Please update Banner name",
    errBasedOnBannerBlank: "Banner Name cannot be blank",
    errlayerNameEmpty: "Component layer name can not be empty",
    errlayernameSpaces: "Component layer name can not start with spaces",
    errTempExist:
      "Template Name exists. Please enter a different template name",
    errTemplateSpace: "Template Name can't start with spaces",
    errTemplateBlank: "Please provide Template name",
  },
  rightContainer: {
    title: "Title Goes Here",
    accordion: {
      appearance: "Appearance",
      targetLink: "Target Link",
      lineColor: "Line Color",
      zoom: "Zoom",
      pieColor: "Pie Color",
    },
    interactions: {
      event: "Event",
      destination: "Destination",
      events: {
        none: "None",
        click: "Click",
        doubleClick: "Double Click",
        tap: "Tap",
        rightClick: "Right Click",
      },
      destinations: {
        gotoPage: "Go to page",
        gotoUrl: "Go to URL",
      },
    },
  },
  channelTypeSelect: {
    eDetail: "e-Detail",
    emailRte: "Email - RTE/VAE",
    banner: "Banner",
    socialMediaAdv: "Social Media Advertisements",
    print: "Print",
    alert: "Alert",
  },
  editorModal: {
    GoToEditor: "Go to Editor",
  },
  editorleftPanel: {
    tabs: {
      format: "Format",
      module: "Module",
      elements: "Elements",
      structure: "Structure",
    },
    iconTitles: {
      text: "Text",
      headings: "Headings",
      buttons: "Buttons",
      divider: "Divider",
      spacer: "Spacer",
      social: "Social",
      navigation: "Navigation",
      htmlCode: "HTML Code",
      table: "Table",
      container: "Container",
    },
  },
  bannerManager: {
    bannerManager: "Banner Manager",
    enterBannerName: "Enter Banner Name",
    newBanner: "+ New Banner",
    newBannerTitle: "New Banner",
    basedOnTitle: "Based On",
    brandName: "Brand",
    bannerName: "Banner Name",
    campaign: "Campaign",
    bannerSize: "Banner Size",
    bannerType: "Banner Type",
    templateType: "Template Type",
    templateManager: "Template Manager",
    newTemplate: "CREATE NEW TEMPLATE",
    tagName: "Tags",
    market: "Market",
    cancel: "CANCEL",
    proceed: "Proceed",
    generateRVFile: "Generate File",
    generateRVCVfile: "Generate RV File",
    selectSize: "Select Size of screenshot to be generated",
    bnrNameErr:
      "Entered banner name is too lengthy it should be less than 250 characters",
    tempNameErr:
      "Entered Template name is too lengthy it should be less than 250 characters",
    tempNameMinErr:
      "Entered Template name is short it should be more than 2 characters",
    bnrNameMinErr:
      "Entered banner name is short it should be more than 2 characters",
    kababMenu: {
      delete: "Delete",
      export: "Export",
      edit: "Edit",
      lock: "Lock",
      unlock: "Unlock",
    },
    deleteModal: {
      deleteTitle: "Are you sure you want to Delete ?",
      subTitle: "Note : You will no longer be able to use this banner.",

      no: "No",
      yes: "Yes",
      subTitle1: "Note : You will no longer be able to use this template.",
    },
  },
  bannerForms: {
    search: "Search",
    reset: "Reset",
    recentlyViewed: "Recently Viewed",
    searchResults: "Search Results",
    noBannerFound: "No Banner Found",
    noTemplateFound: "No Template Found",
    tryusingKeyword: "Try using another Keyword / parameter",
  },
  exportLabels: {
    html: "HTML",
    jpeg: "JPEG",
    png: "PNG",
    pdf: "PDF",
    exportAsSubTitle: "Select format to be exported",
    exportAs: "Export As",
    cancel: "CANCEL",
    export: "Export",
    noElementDragged: "Please drag and drop something on the canvas",
    rvCvfile: "RV/CV file",
  },

  editorRightPanel: {
    tabs: {
      properties: "PROPERTIES",
      info: "INFO",
    },
    infoTabContent: {
      template: "Template",
      mlrStatus: "MLR Status",
      brand: "Brand",
      market: "Market",
      tagName: "Tags",
      bannerName: "Banner Name",
      height: "Height",
      width: "Width",
      bannerType: "Banner Type",
    },
    propertiesTabcontent: {
      columns: "Columns",
      padding: "Padding",
      borders: "Border",
      size: "Size",
      radius: "Radius",
      fill: "Fill",
      buttonFill: "Button Fill",
      border: "Border",
      outline: "Outline",
      bgImage11: "BG Image",
      bgColor: "Background Color",
      bgImage: "Background Image",
      buttonColor: "Button Color",
      targetLink: "Target Link",
      addlink: "Add Link",
      enterUrl: "Enter URL",
      fontColor: "Font Color",
      textSetting: "TEXT SETTING",
      transform: "TRANSFORM",
      aspectLock: "Aspect Lock",
      text: "TEXT",
      button: "BUTTON",
      shape: "SHAPE",
      link: "LINK",
      enableScroll: "Enable Scroll",
      enableAutoScroll: "Enable Auto-Scroll",
      expandAndClose: "ADD EXPAND / CLOSE BUTTON",
      image: "IMAGE",
      globalSetting: "GLOBAL ",
      buttonSetting: "BUTTON ",
      imageSetting: "IMAGE ",
      bannerSize: "BANNER SIZE",
      backgroundColor: "BACKGROUND COLOR",
      shapeSetting: "SHAPE ",
      shapeFill: " Fill",
      cornerCount: "Corner Count",
      scrollBar: "SCROLL BAR",
    },
  },

  editorHeader: {
    new: "New",
    open: "Open",
    save: "Save",
    export: "Export",
    code: "Code",
    anchor: "Anchor",
    others: "Others",
    undo: "Undo",
    redo: "Redo",
    preview: "Preview",
    publish: "Publish",
  },
  modulesLeftContainer: {
    moduleName: "Module Name",
    primaryClaim: "Promotional Claim", //"Primary Claim",
    campaignName: "Campaign Name",
    change: "Change",
    view: "View",
    selectmodule: "Select Module",
    toGetStarted: "To Get Started",
    proceed: "Proceed",
    close: "Close",
    selectModuleToast: "Please Select Brand, Campaign and Country",
    moduleIdandName: "Module ID/Module Name",
    assetName: "Asset Name",
    coreClaimCategories: "Core Claim Categories",
    coreClaim: "Core Claim",
    search: "Search",
    reset: "RESET",
    claimVariant: "Claim Variant 1",
  },
  moduleSpecificInfo: {
    moduleInfo: "Module-information",
    name: "Name",
    id: "ID",
    pageNo: "Page number in master asset",
    coreClaimCategory: "Core claim category",
    coreClaim: "Core claim",
    audience: "Audience",
    dosage: "Dosage",
    moduleRating: "Module Rating",
    genericBusinessRules: "Generic business rules",
    recommendationOnModuleUsage: "Recommendation on module usage",
  },
  bussinessRulesSpecificInfo: {
    mustExistWith: "Must Exist With:",
    cannotExistWith: "Cannot Exist With:",
    otherBussinessrule: "Other business rules",
    precedes: "Precedes:",
    succeeds: "Succeeds:",
    relatedModules: "Related modules:",
    businessRules: "Business rules",
  },
  animation: {
    animation: "Animation",
    keyframe: "KeyFrame",
    play: "Play",
    headline: "Headline",
    button: "Button",
    subHeadline: "Sub Headline ",
    description: "Description",
  },

  animationLabel: {
    animationType: "Animation type",
    properties: "Properties",
    startTime: "Start Time",
    duration: "Duration",
    opacity: "Opacity",
    opacityTo: "Opacity To",
    delay: "Delay",
    axialRotation: "Rotation",
    yAxis: "Y-axis",
    xAxis: "X-axis",
    left: "Left",
    scale: "Scale",
    top: "Top",
    right: "Right",
    bottom: "Bottom",
    startsFrom: "Starts From",
    zIndex: "Z-index",
  },
  previewLabel: {
    previewClose: "Close",
    Heading: "Preview",
  },
  contextMenuZindex: {
    bringFront: "Bring to Front",
    bringBack: "Send to Back",
    bringForward: "Bring Forward",
    bringBackward: "Send Backward",
    delete: "Delete",
    copy: "Copy",
    paste: "Paste",
    lock: "Lock",
    unlock: "Unlock",
    hide: "Hide",
    unhide: "Unhide",
  },

  toolbarLeftPanel: {
    titleText: "ADD TEXT",
    addHeading: "Add heading",
    addSubHeading: "Add sub heading",
    body: "Body",
    addheading2: "Heading",
    searchPlaceholder: "Search by Brand",
    suggested: "SUGGESTED",
    shapeTitle: "SHAPES",
    rectangle: "Rectangle",
    circle: "Circle",
    triangle: "Triangle",
    polygon: "Polygon",
    hexagon: "Hexagon",
    timeline: "Timeline",
    moduleTitle: "MODULES",
    moduleTitle2: "IMPORT CONTENT ",
    imageTitle: "IMAGE",
    library: "Library",
  },
  timelineMenu: {
    titleText: "Custom Animation",
    easing: "Easing",
    x: "X",
    y: "Y",
    width: "WIDTH",
    height: "HEIGHT",
    opacity: "Opacity",
    scale: "Scale",
    rotate: "Rotate",
    rotateText: " Enter a number to rotate the layer",
    zoom: "Drag the slider to change the value for scale",
    opacityInfo: "Drag the slider to change the value of opacity",
  },
  imageWindowTabs: {
    brand: "Brand",
    org: "Org",
    uploaded: "Uploaded",
    links: "Links",
    uploadErrmsg:
      "Image size exceeds 500 KB please try to upload the smaller one",
    uploadToastmsg: "Image Uploaded successfully",
    linkToastMsg: "Image added successfully from link",
    linkToastErrMsg:
      "Please upload valid images. Only .jpeg/.jpg/.png/.gif/.svg are allowed",
    scrollToastErrMsg:
      "Please upload valid images. Only .jpeg/.jpg/.png/.gif/.svg are allowed",
  },
};
