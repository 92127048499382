import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { withStyles, Divider } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

//import errComponentToRender from "../../Uitls/apiErrorHandling";
import { DialogStyle } from "../../styles/pages/ContentStudio/WithStylesObjs";
import "../../styles/pages/ContentStudio/DynDialogue.scss";
import ValueContext from "../../ContextAPi/appContext";

import { useTranslation } from "react-i18next";

import { CircularProgress } from "@material-ui/core";
function VeevaRuleSet(props) {
  const appData = useContext(ValueContext);
  const { t } = useTranslation();
  const {
    closeVeevarulesetmodal,
    openVeevaruleset,
    setOpenveevaruleset,
    veevaSelectedModuleId,
    veevaLoader,
    cardDetails,
    responseData,
  } = props;

  const { classes } = props;

  // };
  const [expandedVeeva, setExpandedVeeva] = useState({
    panel1: true,
    panel2: false,
  });
  const handleChangeVeeva = (panel) => (event, isExpanded) => {
    //setExpanded(isExpanded ? panel : false);

    switch (panel) {
      case "panel1":
        setExpandedVeeva({ ...expandedVeeva, panel1: !expandedVeeva.panel1 });
        break;
      case "panel2":
        setExpandedVeeva({ ...expandedVeeva, panel2: !expandedVeeva.panel2 });
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Dialog
        //className="selectModalConatiner"
        className={`selectModalConatiner ${classes.root}`}
        open={openVeevaruleset}
        onClose={closeVeevarulesetmodal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        {veevaLoader && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress
              size="30px"
              className="customLoadermodules"
            />{" "}
          </>
        )}
        <div className="container no-gutters">
          <div className="row align-items-center align-left">
            <div className="col-md-11 selectModalTitle">
              {cardDetails?.data?.id} - Rule Sets
            </div>
            <div
              className="col-md-1 closeveevaruleset"
              onClick={() => setOpenveevaruleset(false)}
            >
              CLOSE
            </div>
          </div>
        </div>

        <DialogContent className="search-cards veevamustUse">
          <div className=" localizedAccordionveeva customScrollBar veevarulesAccordion2">
            <div className="accordion2 customAccordIconsveeva">
              {veevaSelectedModuleId?.mustUseList?.length > 0 && (
                <Accordion
                  // expanded={expandedVeeva}
                  // className="accordion"
                  defaultExpanded={true}
                  onChange={handleChangeVeeva("panel1")}
                >
                  {veevaSelectedModuleId?.mustUseList &&
                    veevaSelectedModuleId?.mustUseList?.length > 0 && (
                      <AccordionSummary
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                      >
                        <span className="mustUse">
                          Must Use ({veevaSelectedModuleId?.mustUseList?.length}
                          )
                        </span>
                      </AccordionSummary>
                    )}
                  {/* <div className="accordionscroll scrollCOntent"> */}
                  <AccordionDetails>
                    <div className="veevatb ">
                      <table className="veevatable">
                        <tr>
                          <th style={{ width: "15%" }}>Name</th>
                          <th style={{ width: "55%" }}>Label</th>
                          <th style={{ width: "30%" }}>
                            Content Module Ruleset
                          </th>
                        </tr>
                        {veevaSelectedModuleId.mustUseList &&
                          veevaSelectedModuleId.mustUseList.map((item) => {
                            return (
                              <tr>
                                <td>{item.name}</td>
                                <td>{item.label}</td>
                                <td>{item.contentModuleRuleSet}</td>
                              </tr>
                            );
                          })}
                      </table>
                    </div>
                  </AccordionDetails>
                  {/* </div> */}
                </Accordion>
              )}
              {veevaSelectedModuleId?.optionalList?.length > 0 && (
                <Accordion onChange={handleChangeVeeva("panel2")}>
                  <AccordionSummary
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    <span className="mustUse">
                      Optional ({veevaSelectedModuleId?.optionalList?.length})
                    </span>
                  </AccordionSummary>
                  {veevaSelectedModuleId?.optionalList &&
                    veevaSelectedModuleId?.optionalList?.length > 0 && (
                      // <div className="accordionscroll scrollCOntent">
                      <AccordionDetails>
                        <div className="veevatb">
                          <table className="veevatable">
                            <tr>
                              <th style={{ width: "15%" }}>Name</th>
                              <th style={{ width: "55%" }}>Label</th>
                              <th style={{ width: "30%" }}>
                                Content Module Ruleset
                              </th>
                            </tr>
                            {veevaSelectedModuleId.optionalList &&
                              veevaSelectedModuleId.optionalList.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.name}</td>
                                    <td>{item.label}</td>
                                    <td>{item.contentModuleRuleSet}</td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </AccordionDetails>
                      // </div>
                    )}
                </Accordion>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withStyles(DialogStyle)(VeevaRuleSet);
