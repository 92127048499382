export const renderTheFontLink = (fontFamily) => {
  if (fontFamily.startsWith(" ")) fontFamily = fontFamily.substring(1);
  let tempFont = fontFamily?.split(" ").join("+");
  let url = `https://fonts.googleapis.com/css2?family=${tempFont}:wght@100;200;300;400;500;600;700;800;900&display=swap`;
  var link = document.createElement("link");
  link.href = url;
  link.rel = "stylesheet";
  link.type = "text/css";
  document.getElementsByTagName("head")[0].appendChild(link);
  return `<link rel=${"stylesheet"} href= ${url}>`;
};

var stringToHTML = function (str) {
  var dom = document.createElement("div");
  dom.innerHTML = str;
  return dom;
};

export const renderAppliedFonts = (response, fromExport) => {
  const myFontSpanTags = [];
  let fontsLink = "";

  response.data.assetpages[0].assetpagecomponent.forEach((assetpageComp) => {
    if (
      assetpageComp.componentType === "Button" ||
      assetpageComp.componentType === "Text"
    )
      renderTheFontLink(assetpageComp.components.fontFamily);
    // myFontSpanTags.push(stringToHTML(assetpageComp.components.froalaText));
  });
  return;
  if (myFontSpanTags.length < 1) return;
  myFontSpanTags.forEach((myFontSpanTag) => {
    let compFamiliesLength = myFontSpanTag.getElementsByTagName("span").length;
    let params = [];
    for (let i = 0; i < compFamiliesLength; i++) {
      params[i] = myFontSpanTag
        .getElementsByTagName("span")
        [i].style.cssText.replaceAll(";", ",");
    }
    let compFamilies = params.map((param) => {
      let jsonStrig = "[{";
      let items = param.split(",");

      // items[0] = items[0].replace(/["/]+/g, "");
      for (let i = 0; i < items.length; i++) {
        items[i] = items[i].includes("font-family")
          ? items[i].replace(/["/]+/g, "")
          : items[i];
        if (items[i].startsWith(" ")) items[i] = items[i].substring(1);
        let current = items[i].split(":");

        jsonStrig += '"' + current[0] + '":"' + current[1] + '",';
      }

      jsonStrig = jsonStrig.substr(0, jsonStrig.length - 1);

      jsonStrig += "}]";
      return JSON.parse(jsonStrig)[0];
    });
    compFamilies.forEach((family) => {
      if (family.hasOwnProperty("font-family"))
        fontsLink += `${renderTheFontLink(family["font-family"])} \n`;
    });
  });
  return fontsLink;
};

export const getRelativeCoordinates = (event) => {
  return {
    x: event.clientX - event.currentTarget.getBoundingClientRect().left,
    y: event.clientY - event.currentTarget.getBoundingClientRect().top,
  };
};
