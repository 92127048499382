import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import "./VeevaCardDetails.scss";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import parse from "html-react-parser";
function ClaimAccordion(props) {
  let counter = 0;
  let itemsCout =
    props.claims.length -
    props.claims.filter(
      (x) => x?.claimMatchText == "null" || x?.claimMatchText === null
    ).length;

  return (
    <div className="claimDetails " style={{ paddingRight: "18px" }}>
      <div className="accordion leftcard">
        <Accordion
          expanded={props.expanded === `panel-${1}`}
          className="accordionLeftpane"
          onChange={props.handleChange(`panel-${1}`)}
          disableGutters={true}
        >
          <AccordionSummary
            expandIcon={
              props.expanded === `panel-${1}` ? (
                <RemoveIcon fontSize="small" className="accordionIcons" />
              ) : (
                // <p>-</p>
                <AddIcon fontSize="small" className="accordionIcons" />
                // <p>+</p>
              )
            }
            aria-controls={`panelprops.Claims-${1}a-content`}
            id={`panelprops.Claims-${1}a-header`}
            className="accordion-summary"
          >
            <div className="claimName nextmod" style={{ displex: "flex" }}>
              {"Claim"}
              {/* <div className="countItems countItems1">{`(${itemsCout} items)`}</div> */}
            </div>
            <span class="claimNameCount">{`(${itemsCout} items)`}</span>
          </AccordionSummary>
          <AccordionDetails className={"accordDetails  accordVisual "}>
            {props.claims.length > 0 &&
              props.claims?.map(
                (claimMatchText, index) =>
                  claimMatchText?.claimMatchText &&
                  claimMatchText?.claimMatchText !== "null" && (
                    <div>
                      <div className="accDetails2 ">
                        <div className="typeHeading">
                          {`Claim Match Text${index + 1}`}
                        </div>
                        {claimMatchText?.mustUse && (
                          <div className="colorCode">
                            <div
                              className={
                                "relationDependancy relationColorMandatory"
                              }
                            ></div>
                            <span className="clrCodeText">Must have</span>
                          </div>
                        )}
                        {/* <div id={supText.componentType+index} className="typeText" data-assetId={assetId}  onDoubleClick={ */}
                        <div
                          /// id={"text" + counter++}
                          className="claim-match-text"
                          // data-assetid={props.assetId}
                          draggable={true}
                          onDragStart={(e) =>
                            props.sendAtomDetails2Canvas({
                              id: e.target.parentNode.id || e.target.id,
                              type: "atomText",
                              data: claimMatchText?.claimMatchText,
                            })
                          }
                        >
                          {parse(claimMatchText?.claimMatchText)}
                          {claimMatchText?.references && (
                            <div className="typeHeading mt-2">
                              {`Reference(s)`}
                            </div>
                          )}
                          {claimMatchText?.references?.map((item) => (
                            <div
                              className="claim-match-text mb-1"
                              draggable={true}
                              onDragStart={(e) =>
                                props.sendAtomDetails2Canvas({
                                  id: e.target.parentNode.id || e.target.id,
                                  type: "atomText",
                                  data: item,
                                })
                              }
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
              )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default ClaimAccordion;
