import React from "react";
import "./VeevaCardDetails.scss";
import CradDataAccordions from "./CradDataAccordions";
import goBackLeftArrow from "../../images/goBackLeftArrow.svg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

function VeevaCardDetails({
  handleVeevarulesetmodal,
  cardDetails,
  setCardDetails,
  getVeevaRulesset,
  customDrag,
  sendAtomDetails2Canvas,
}) {
  const viewRuleSets = () => {
    getVeevaRulesset([cardDetails.data.id], "ViewVeevaRuleset");
  };
  return (
    <div>
      {/* <div className="indexHeading">
        <div className="col-md-3 backNModuleNum">
          <div className="backArrow">
            <img
              src={goBackLeftArrow}
              alt=""
              className="gobackLeftArrow"
              onClick={() =>
                setCardDetails({
                  ...cardDetails,
                  cardDetails: false,
                })
              }
            ></img>
          </div>
          <div className="moduleName">{cardDetails?.data?.moduleName}</div>
        </div> */}
      <div className="cardDetailsTab ">
        <div className="arrowAndCodeHeadercard">
          <ArrowBackIosIcon
            className="backwordBlueArrow"
            onClick={() =>
              setCardDetails({
                ...cardDetails,
                cardDetails: false,
              })
            }
          />
          <div className="moduleCode d-flex">
            {cardDetails?.data?.moduleName}
          </div>
          <button
            onClick={() => viewRuleSets()}
            className="btn btn-link viewRuleset"
          >
            View Rulesets
          </button>
        </div>
      </div>
      <div>
        <CradDataAccordions
          cardDetails={cardDetails}
          sendAtomDetails2Canvas={sendAtomDetails2Canvas}
        />
      </div>
    </div>
  );
}

export default VeevaCardDetails;
