import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { DebounceInput } from "react-debounce-input";

import { useTranslation } from "react-i18next";
import "../../styles/components/RightPanel.scss";
import { TextField, InputAdornment } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import { toast } from "react-toastify";
import left from "../../images/leftPos.svg";
import top from "../../images/topPos.svg";
export default function Transform({
  isAspectRatioLocked,
  setIsAspectRatioLocked,
  showComponentType,
  handleDimensions,
  updatedValue,
  dimensionsData,
  checkedAspect,
  handleBlur,
  selectedComponent,

  setSelectedComponent,
  layerCompData,
  setUpdatedValue,
  toggleAnimatedBanner,
}) {
  // let locked = selectedComponent?.componentStyles?.lockBoolean;
  let locked = false;
  if (
    selectedComponent?.componentType !== undefined &&
    layerCompData.length > 0 &&
    selectedComponent?.componentType !== "default"
  )
    locked =
      layerCompData[
        layerCompData.findIndex(
          (selComp) => selComp.id === selectedComponent.id
        )
      ]?.componentStyles?.lockBoolean;

  const { t } = useTranslation();
  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
      background: isAspectRatioLocked ? "white" : "",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);
  // const [checkedAspect, setCheckedAspect] = React.useState(true);
  const ToggleAspectLock = (e) => {
    setIsAspectRatioLocked(!isAspectRatioLocked);
    // let tempSlected = { ...selectedComponent };
    // tempSlected.componentStyles.aspectLock = e.target.checked;
    // setSelectedComponent(tempSlected);
  };

  const handleChangeCompOpacity = (e, newValue) => {
    let updatedOpacity = { ...updatedValue };
    updatedOpacity.componentStyles.compOpacity = newValue * 100;
    setUpdatedValue(updatedOpacity);

    let tempSelected = { ...selectedComponent };
    tempSelected.componentStyles.compOpacity =
      updatedOpacity?.componentStyles?.compOpacity;
    setSelectedComponent(tempSelected);
  };
  const handleInputOpacity = (e, newValue) => {
    let updatedOpacity = { ...updatedValue };

    let inputValue = parseFloat(e.target.value);
    //inputValue = Math.min(1, Math.max(0, inputValue));
    inputValue = isNaN(inputValue) ? 0 : Math.min(1, Math.max(0, inputValue));
    inputValue = parseFloat(inputValue.toFixed(1));
    let percentageValue = inputValue * 100;
    setUpdatedValue((prevState) => ({
      ...prevState,
      componentStyles: {
        ...prevState.componentStyles,
        compOpacity: percentageValue,
      },
    }));

    let tempSelected = { ...selectedComponent };
    tempSelected.componentStyles.compOpacity = percentageValue;
    setSelectedComponent(tempSelected);
  };
  const handleBlurOpacity = () => {
    let updatedOpacity = { ...updatedValue };
    if (updatedValue.componentStyles.compOpacity < 0) {
      updatedOpacity.componentStyles.compOpacity = 0;
      setUpdatedValue(updatedOpacity);
    } else if (updatedValue.componentStyles.compOpacity > 1) {
      updatedOpacity.componentStyles.compOpacity = 1;
      setUpdatedValue(updatedOpacity);
    }
    let tempSelected = { ...selectedComponent };
    tempSelected.componentStyles.compOpacity =
      updatedOpacity?.componentStyles?.compOpacity;
    setSelectedComponent(tempSelected);
  };

  const validateNumbers = (e) => {
    if (isNaN(e.target.value)) return false;
  };

  const handleArrowKeyPress = (property) => (e) => {
    // if (updatedValue.componentStyles[property] == "-") {
    //   updatedValue.componentStyles[property] = "0";
    //   return;
    // }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      if (updatedValue.componentStyles[property] == "-") {
        updatedValue.componentStyles[property] = "0";
        return;
      }

      setUpdatedValue((prevState) => ({
        ...prevState,
        componentStyles: {
          ...prevState.componentStyles,
          [property]:
            updatedValue.componentStyles[property] == 9999
              ? updatedValue.componentStyles[property]
              : ++updatedValue.componentStyles[property],
        },
      }));
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (updatedValue.componentStyles[property] == "-") {
        updatedValue.componentStyles[property] = "0";
        return;
      }

      if (property == "top" || property == "left") {
        setUpdatedValue((prevState) => ({
          ...prevState,
          componentStyles: {
            ...prevState.componentStyles,
            [property]:
              updatedValue.componentStyles[property] == -9999
                ? updatedValue.componentStyles[property]
                : --updatedValue.componentStyles[property],
          },
        }));
      } else {
        setUpdatedValue((prevState) => ({
          ...prevState,
          componentStyles: {
            ...prevState.componentStyles,
            [property]:
              updatedValue.componentStyles[property] > 0
                ? --updatedValue.componentStyles[property]
                : updatedValue.componentStyles[property],
          },
        }));
      }
    }
  };

  const handleArrowKeyPressOpacity = (e, type) => {
    // if (e.key === "ArrowUp") {
    //   e.preventDefault();
    //   setUpdatedValue((prevState) => ({
    //     ...prevState,
    //     componentStyles: {
    //       ...prevState.componentStyles,
    //       compOpacity:
    //         updatedValue.componentStyles.compOpacity == 100
    //           ? updatedValue.componentStyles.compOpacity
    //           : ++updatedValue.componentStyles.compOpacity,
    //     },
    //   }));
    // } else if (e.key === "ArrowDown") {
    //   e.preventDefault();
    //   setUpdatedValue((prevState) => ({
    //     ...prevState,
    //     componentStyles: {
    //       ...prevState.componentStyles,
    //       compOpacity: updatedValue.componentStyles.compOpacity
    //         ? --updatedValue.componentStyles.compOpacity
    //         : updatedValue.componentStyles.compOpacity,
    //     },
    //   }));
    // }

    if (e.key !== "ArrowUp" && e.key !== "ArrowDown") return;
    e.preventDefault();
    const increment =
      e.key === "ArrowUp" ? 0.1 : e.key === "ArrowDown" ? -0.1 : 0;
    if (increment === 0) return;
    let tempSelect = { ...selectedComponent };

    let currentValue = tempSelect.componentStyles.compOpacity / 100;
    let newValue = parseFloat((currentValue + increment).toFixed(1));
    newValue = Math.max(0, Math.min(1, newValue));
    let percentageValue = newValue * 100;
    tempSelect.componentStyles.compOpacity = percentageValue;

    setUpdatedValue((prevState) => ({
      ...prevState,
      componentStyles: {
        ...prevState.componentStyles,
        compOpacity: percentageValue,
      },
    }));

    setSelectedComponent(tempSelect);
  };

  return (
    <div className="textTransform">
      {/* <div className="textSetting">{showComponentType}</div> */}
      <div className="transformProps">
        <div className="transformText">
          {showComponentType === "Image" || showComponentType === "Shape"
            ? "Transform"
            : showComponentType === "BANNER SIZE"
            ? "Transform"
            : ""}
        </div>
        {/* <div className="aspectLock">
          {t("editorRightPanel.propertiesTabcontent.aspectLock")}
          <AntSwitch
            checked={isAspectRatioLocked}
            onChange={(e) => {
            ToggleAspectLock(e);
            }}
          />
        </div> */}
      </div>
      <div>
        <div className="properties-textfield">
          <div className="dimensionFieldsWH">
            <div className="widthAndHeight">
              {dimensionsData?.dimensions?.width === "true" &&
              dimensionsData?.dimensions?.xPosition === "true" ? (
                <>
                  {dimensionsData?.dimensions?.dimension?.map(
                    (dimensions, id) => {
                      return (
                        <div key={id} className="height-width">
                          <p>{dimensions}</p>
                          <TextField
                            // debounceTimeout={500}
                            // element={TextField}
                            // type="number"
                            autoComplete="off"
                            title=""
                            disabled={locked}
                            className="standard-basic"
                            id={`${dimensions}Dimension`}
                            // onKeyDown={validateNumbers}
                            value={
                              `${dimensions}Dimension` === "WDimension"
                                ? Math.round(
                                    updatedValue?.componentStyles?.width
                                  )
                                : Math.round(
                                    updatedValue?.componentStyles?.height
                                  )
                            }
                            // onClick={() => document.inputDiv.blur()}
                            onChange={(e) => handleDimensions(e)}
                            onKeyDown={
                              `${dimensions}Dimension` === "WDimension"
                                ? handleArrowKeyPress("width")
                                : handleArrowKeyPress("height")
                            }
                            onBlur={(e) => handleDimensions(e, "blur")}
                            name={
                              `${dimensions}Dimension` === "WDimension"
                                ? "width"
                                : "height"
                            }
                            size="small"
                            inputProps={{
                              style: {
                                width: "40px",
                                position: "relative",
                                bottom: "6px",
                                height: "20px",
                                // marginTop: "-14px",
                                marginBottom: "-15px",
                                fontSize: "11px",
                                color: "black",
                                textAlign: "center",
                              },
                            }}
                          />
                          {/* <TextField
                          title=""
                          disabled={locked}
                          className="standard-basic"
                          id={`${dimensions}Dimension`}
                          value={
                            `${dimensions}Dimension` === "WDimension"
                              ? isNaN(theUpdatedValue?.componentStyles?.width)
                                ? 0
                                : Math.round(
                                    theUpdatedValue?.componentStyles?.width
                                  )
                              : isNaN(theUpdatedValue?.componentStyles?.height)
                              ? 0
                              : Math.round(
                                  theUpdatedValue?.componentStyles?.height
                                )
                          }
                          // onChange={(e) => debounced(e.target, e.target.value)}
                          // onChange={handleDimensions}
                          name={
                            `${dimensions}Dimension` === "WDimension"
                              ? "width"
                              : "height"
                          }
                          size="small"
                          inputProps={{
                            style: {
                              width: "40px",
                              position: "relative",
                              bottom: "6px",
                              height: "20px",
                              // marginTop: "-14px",
                              marginBottom: "-15px",
                              fontSize: "11px",
                              color: "black",
                              textAlign: "center",
                            },
                          }}
                        /> */}
                        </div>
                      );
                    }
                  )}
                </>
              ) : null}
            </div>
          </div>
          <div className="dimensionFieldsXY">
            <div className="positions">
              {dimensionsData.dimensions.height === "true" &&
              dimensionsData.dimensions.yPosition === "true" ? (
                <>
                  {dimensionsData.dimensions.positions.map((dimensions, id) => {
                    return (
                      <div className="xy-positionValues" key={id}>
                        {dimensions == "X" ? (
                          <img src={left} />
                        ) : (
                          <img src={top} />
                        )}
                        <TextField
                          // debounceTimeout={500}
                          // element={TextField}
                          autoComplete="off"
                          title=""
                          disabled={locked}
                          className="standard-basic"
                          // type="number"
                          id={`${dimensions}Position`}
                          value={
                            `${dimensions}Position` === "XPosition"
                              ? updatedValue?.componentStyles?.left !== "-"
                                ? Math.round(
                                    updatedValue?.componentStyles?.left
                                  )
                                : updatedValue?.componentStyles?.left
                              : updatedValue?.componentStyles?.top !== "-"
                              ? Math.round(updatedValue?.componentStyles?.top)
                              : updatedValue?.componentStyles?.top
                          }
                          // onBlur={handleBlur}
                          onChange={(e) => handleDimensions(e)}
                          onKeyDown={
                            // (e) => handleDimensions(e)
                            `${dimensions}Position` === "XPosition"
                              ? handleArrowKeyPress("left")
                              : handleArrowKeyPress("top")
                          }
                          onBlur={(e) => handleDimensions(e, "blur")}
                          name={
                            `${dimensions}Dimension` === "XDimension"
                              ? "left"
                              : "top"
                          }
                          size="small"
                          inputProps={{
                            style: {
                              width: "40px",
                              position: "relative",
                              bottom: "6px",
                              height: "20px",
                              marginBottom: "-15px",
                              fontSize: "11px",
                              color: "black",
                              textAlign: "center",
                            },
                          }}
                        />

                        {/* <TextField
                          title=""
                          disabled={locked}
                          className="standard-basic"
                          type="number"
                          id={`${dimensions}Position`}
                          value={
                            `${dimensions}Position` === "XPosition"
                              ? updatedValue?.componentStyles?.left
                              : updatedValue?.componentStyles?.top
                          }
                          onBlur={handleBlur}
                          onChange={handleDimensions}
                          name={
                            `${dimensions}Dimension` === "XDimension"
                              ? "left"
                              : "top"
                          }
                          size="small"
                          inputProps={{
                            style: {
                              width: "40px",
                              position: "relative",
                              bottom: "6px",
                              height: "20px",
                              marginBottom: "-15px",
                              fontSize: "11px",
                              color: "black",
                              textAlign: "center",
                            },
                          }}
                        /> */}
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
          {(selectedComponent?.componentType === "Image" ||
            selectedComponent?.componentType === "Shape") && (
            <div>
              {!toggleAnimatedBanner ? (
                <div className="opacity-values">
                  <p>Opacity</p>
                  <TextField
                    //element={TextField}
                    //debounceTimeout={500}
                    title=""
                    disabled={locked}
                    className="standard-basic"
                    // type="number"
                    id="opacity"
                    autoComplete="off"
                    value={
                      selectedComponent?.componentStyles?.compOpacity / 100
                    }
                    //onBlur={handleBlurOpacity}
                    onChange={(e) => handleInputOpacity(e)}
                    onKeyDown={(e) => handleArrowKeyPressOpacity(e, "opacity")}
                    name="opacity"
                    size="small"
                    inputProps={{
                      style: {
                        width: "40px",
                        position: "relative",
                        bottom: "6px",
                        height: "20px",
                        marginBottom: "-15px",
                        fontSize: "11px",
                        color: "black",
                        textAlign: "center",
                      },
                      type: "number",
                      step: "0.1",
                      min: "0",
                      max: "1",
                      pattern: "\\d*\\.?\\d{0,1}",
                    }}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment
                    //       position="end"
                    //       className="opacityPercentage2"
                    //     >
                    //       %
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                  <Slider
                    //value={updatedValue?.componentStyles?.compOpacity}
                    value={
                      // isNaN(updatedValue?.componentStyles?.compOpacity)
                      //   ? 0
                      //   : Math.round(updatedValue?.componentStyles?.compOpacity)
                      selectedComponent?.componentStyles?.compOpacity / 100
                    }
                    onChange={handleChangeCompOpacity}
                    aria-labelledby="continuous-slider"
                    //defaultValue={50}
                    min={0}
                    max={1}
                    step={0.1}
                  />
                </div>
              ) : null}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
