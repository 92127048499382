import React from 'react'
import { useTranslation } from "react-i18next";


function CoreClaimError() {
    const { t } = useTranslation();
    return <p className="notificationText">{t("apiErrors.err601")}</p>;
}

export default CoreClaimError
