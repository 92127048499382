import React from "react";

const CheckBox = (props) => {
  const {
    label,
    isChecked,
    handleChange,
    labelClassName,
    checkboxClassName,
    name,
  } = props;
  return (
    <>
      <input
        id={label}
        type="checkbox"
        checked={isChecked}
        name={name}
        onChange={handleChange}
        value={label}
        className={checkboxClassName}
      />
      <label htmlFor={label} className={labelClassName}>
        {label}
      </label>
    </>
  );
};

export default CheckBox;
