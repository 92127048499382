import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dashboardIcon from "../../images/dashboard.svg";
import dashboardIconTrans from "../../images/dashboardIconTrans.svg";
import moduleManagerIcon from "../../images/moduleManager.svg";
import moduleManagerIconTrans from "../../images/moduleManagerIconTrans.svg";
import bannerIcon from "../../images/bannerIcon.svg";
import bannerIconTrans from "../../images/bannerIconTrans.svg";
import leftArrowIcon from "../../images/leftArrowIcon.svg";
import logoutIcon from "../../images/logoutIcon.svg";
import logoutIconTrans from "../../images/logoutIconTrans.svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../../styles/pages/Dashboard.scss";
import UserInfo from "./userDetails";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import { logout } from "../../Uitls";
import ValueContext from "../../ContextAPi/appContext";

import RouterPrompt from "../../Pages/ExitPrompt/RouterPrompt";

function Drawer(props) {
  const [logoutClicked, setLogoutClicked] = useState("no");
  const history = useHistory();
  const onLogout = (saveSuccess) => {
    let when =
      (props.bannerChanged === true &&
        props.refBannerChanged.current === true) ||
      appData?.genericInfo?.rightUpdaed === true;
    let stayIn = saveSuccess !== "success" && when;
    if (stayIn) setShowPrompt(true);
    logout(stayIn);
    if (!stayIn) history.push("/");
    else setLogoutClicked("yes");
    // else history.push("");
  };
  const appData = useContext(ValueContext);
  const [showPrompt, setShowPrompt] = useState(false);

  const { t } = useTranslation();
  const ref = useRef();
  const [isModal, setIsModal] = useState(false);
  const [navColor, setNavColor] = useState(false);
  const [navColorMm, setNavColorMm] = useState(false);
  const [navColorDash, setNavColorDash] = useState(false);
  const [navColorCs, setNavColorCs] = useState(false);
  const [navColorTranslate, setNavColortranslate] = useState(false);
  //const [isRedirect, setIsRedirect] = useState(false);
  const handleOpenModal = (isRedirectFlag = false, genericInfo = {}) => {
    setIsModal(!isModal);
    if (isRedirectFlag === true) {
      history.push("/banner-editor");
    }
  };

  const [isMenu, setIsMenu] = useState(false);

  const handleRightArrowClick = () => {
    // if (appData?.genericInfo?.hasOwnProperty("showPrompt"))
    //   if (appData?.genericInfo?.showPrompt === false)
    //     setShowPrompt(appData?.genericInfo?.showPrompt);
    if (
      Object.keys(props).length !== 0 &&
      !props.playPauseRef.current.animationCompleted
    )
      return;
    setIsMenu(!isMenu);
    setOpenBanner(false);
    setLogoutClicked("no");
  };
  const handleLeftArrowClick = () => {
    setIsMenu(!isMenu);
  };

  // const [open, setOpen] = React.useState(false);
  const [openBanner, setOpenBanner] = React.useState(false);
  // const handleClickSub = () => {
  //   setOpen(!open);
  // };
  const handleClickBanner = (event) => {
    setOpenBanner(!openBanner);
  };
  useOnClickOutside(ref, () => setIsMenu(false));
  const handleChangeColorDashBoard = () => {
    setNavColorDash(!navColorDash);
  };
  const handleChangeColor = () => {
    setNavColor(!navColor);
  };
  const handleChangeColorMm = () => {
    setNavColorMm(!navColorMm);
  };
  const handleChangeColorCs = () => {
    setNavColorCs(!navColorCs);
  };
  const handleChangeColorTranslate = () => {
    setNavColortranslate(!navColorTranslate);
  };
  return (
    <>
      {props?.origin === "EditorMainPage" ? (
        <RouterPrompt
          when={
            props.bannerChanged === true ||
            props.refBannerChanged.current === true ||
            appData?.genericInfo?.rightUpdaed === true
          }
          title="Leave this page"
          cancelText="Cancel"
          okText="Confirm"
          onOK={() => true}
          onCancel={() => true}
          showPrompt={showPrompt}
          setShowPrompt={setShowPrompt}
          setBannerChanged={props.setBannerChanged}
          setRefreshed={props.setRefreshed}
          genericInfo={appData?.genericInfo}
          setGenericInfo={appData.updateGenericInfo}
          onLogout={onLogout}
          bannerChanged={props.refBannerChanged}
          refreshed={props.refreshed}
          logoutClicked={logoutClicked}
          setLogoutClicked={setLogoutClicked}
          // refBannerChanged={props.refBannerChanged}
        />
      ) : (
        ""
      )}
      {isMenu === true ? (
        <div className="dashboardLinks" ref={ref}>
          <ul>
            <li
              onMouseOver={handleChangeColorDashBoard}
              onMouseOut={handleChangeColorDashBoard}
            >
              <Link to="/dashboard">
                {navColorDash === false ? (
                  <img
                    src={dashboardIconTrans}
                    alt={t("dashBoard.navLinksLabel.dashboard")}
                  />
                ) : (
                  <img
                    src={dashboardIcon}
                    alt={t("dashBoard.navLinksLabel.dashboard")}
                  />
                )}
                {t("dashBoard.navLinksLabel.dashboard")}
              </Link>
            </li>
            <li
              onMouseOver={handleChangeColorMm}
              onMouseOut={handleChangeColorMm}
            >
              <Link to="#" className="module-manager">
                {navColorMm === false ? (
                  <img
                    src={moduleManagerIconTrans}
                    alt={t("dashBoard.navLinksLabel.moduleManager")}
                  />
                ) : (
                  <img
                    src={moduleManagerIcon}
                    alt={t("dashBoard.navLinksLabel.moduleManager")}
                  />
                )}

                {t("dashBoard.navLinksLabel.moduleManager")}
              </Link>
            </li>
            <li
              onMouseOver={handleChangeColorCs}
              onMouseOut={handleChangeColorCs}
            >
              <Link
                to={history.location.pathname}
                name="BannerManagerCollapse"
                onClick={(e) => handleClickBanner(e)}
              >
                {navColorCs === false ? (
                  <img
                    src={bannerIconTrans}
                    alt={t("dashBoard.navLinksLabel.banner")}
                  />
                ) : (
                  <img
                    src={bannerIcon}
                    alt={t("dashBoard.navLinksLabel.banner")}
                  />
                )}
                {t("dashBoard.navLinksLabel.banner")}
              </Link>
              <Collapse in={openBanner} timeout="auto" unmountOnExit>
                <List
                  style={{ position: "relative", top: "13px", right: "7px" }}
                >
                  <li>
                    <Link to="landingPage">
                      <p className="menu-item">
                        {t("dashBoard.bannerCollapseContent.bannerManager")}
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/landingPage">
                      <p className="menu-item">
                        {t("dashBoard.bannerCollapseContent.templateManager")}
                      </p>
                    </Link>
                  </li>
                </List>
              </Collapse>
            </li>
            <li>
              <div className="d-flex align-items-center">
                <div
                  className="d-inline"
                  onMouseOver={handleChangeColor}
                  onMouseOut={handleChangeColor}
                >
                  {navColor === false ? (
                    <img src={logoutIconTrans} alt="Log Out" className="" />
                  ) : (
                    <img src={logoutIcon} alt="Log Out" className="" />
                  )}
                </div>
                <div className="d-inline logout-button">
                  <button
                    onClick={onLogout}
                    className={
                      openBanner
                        ? "log-but2 btn btn-link"
                        : "log-but btn btn-link"
                    }
                  >
                    <span className="userNameInfo">
                      <UserInfo />
                    </span>{" "}
                    <span
                      className="userDesignation"
                      onMouseOver={handleChangeColor}
                      onMouseOut={handleChangeColor}
                    >
                      {t("dashBoard.navLinksLabel.logout")}
                    </span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div className="left-arrow">
            <img
              src={leftArrowIcon}
              className="leftArrowIcon"
              onClick={handleLeftArrowClick}
              alt="Hide Navigation Menu"
            />
          </div>
        </div>
      ) : (
        <div className="floatingMenu">
          <ArrowForwardIosIcon
            className="right-arrow"
            style={{ height: "143px", width: "10px" }}
            onClick={
              // props.playPauseRef.current.animationCompleted &&
              handleRightArrowClick
            }
          ></ArrowForwardIosIcon>
        </div>
      )}
      {/* {!openBanner && (
        <RouterPrompt
          when={
            props.bannerChanged === true ||
            appData?.genericInfo?.rightUpdaed === true
          }
          title="Leave this page"
          cancelText="Cancel"
          okText="Confirm"
          onOK={() => true}
          onCancel={() => false}
        />
      )} */}
    </>
  );
}

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
export default Drawer;
