import React, { useContext } from "react";
import ValueContext from "../../ContextAPi/appContext";
import DropDownWithLabel from "../DropDownWithLabel.js";
import { useTranslation } from "react-i18next";
import "../../styles/components/Editor.scss";
import "../../styles/components/EditorModal.scss";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { TextField } from "@material-ui/core";
import { getValidInput } from "../../Uitls/Util";

function TextFieldInputClone({
  genericInfo,
  iscustomtempSize,
  assetType,
  canvasDimension,
  setCanvasDimension,
  setCanvasUpdated,
}) {
  //const appData = useContext(ValueContext);
  const { t } = useTranslation();

  const dimensions = ["width", "height"];
  const symbolsArr = ["e", "E", "+", "-", "."];
  return (
    <>
      <div
        className={
          canvasDimension?.custom
            ? "height-widthcustomeditor heightWidthTemplate   d-flex"
            : "d-flex heightWidthTemplate "
        }
      >
        {dimensions.map((dimensions, id) => {
          return (
            <div key={id} className=" d-flex">
              <p
                className={
                  dimensions === "height" ? "heightField d-flex" : "d-flex"
                }
              >
                {dimensions === "width" ? "W" : "H"}
              </p>
              <TextField
                // debounceTimeout={500}
                // element={TextField}
                // type="number"
                disabled="true"
                className="standard-basic"
                id={`${dimensions}Dimension`}
                // onKeyDown={validateNumbers}
                value={genericInfo[dimensions]}
                title={
                  `${dimensions}Dimension` === "widthDimension"
                    ? "Width"
                    : "Height"
                  // assetType === "banner"
                  //   ? `${dimensions}Dimension` === "WidthDimension"
                  //     ? "WidthBanner"
                  //     : "HeightBanner"
                  //   : `${dimensions}Dimension` === "WidthDimension"
                  //   ? "WidthTemplate"
                  //   : "HeightTemplate"
                }
                // onPaste={(e) => preventPasteNegative(e)}
                name={
                  `${dimensions}Dimension` === "widthDimension"
                    ? "Width"
                    : "Height"
                }
                size="small"
                inputProps={{
                  style: {
                    width: "49px",
                    position: "relative",
                    bottom: "6px",
                    height: "20px",
                    // marginTop: "-14px",
                    marginBottom: "-15px",
                    fontSize: "11px",
                    color: "#928989",
                    textAlign: "center",
                  },
                }}
              />
              {`${dimensions}Dimension` === "widthDimension" ? (
                <div className="xlabel">X</div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default TextFieldInputClone;
