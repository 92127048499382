import React from "react";
import "../styles/components/GuidLines.scss";

export default function GuidLines({ verticalLineFor }) {
  return (
    <>
      {verticalLineFor.top && (
        <div
          className="top-Align"
          style={{
            width:
              Math.max(
                verticalLineFor?.lastSelectedComp?.componentStyles?.right,
                verticalLineFor?.endtopid?.componentStyles?.right
              ) -
              Math.min(
                verticalLineFor?.lastSelectedComp?.componentStyles?.left,
                verticalLineFor?.topid?.componentStyles?.left
              ) +
              "px",
            height: "1px",
            left:
              Math.min(
                verticalLineFor?.lastSelectedComp?.componentStyles?.left,
                verticalLineFor?.topid?.componentStyles?.left
              ) + "px",
            //  left: selectedComponent?.componentStyles?.top,
            position: "absolute",
            top: verticalLineFor?.topid?.componentStyles?.top + "px",
            background: "red",
            display: `${verticalLineFor.top ? "block" : "none"}`,
          }}
        ></div>
      )}
      <div
        className="hMiddle-Align"
        style={{
          width:
            Math.max(
              verticalLineFor?.lastSelectedComp?.componentStyles?.right,
              verticalLineFor?.endhMiddleid?.componentStyles?.right
            ) -
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.left,
              verticalLineFor?.hMiddleid?.componentStyles?.left
            ) +
            "px",
          height: "1px",
          //  left: selectedComponent?.componentStyles?.top,
          position: "absolute",
          left:
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.left,
              verticalLineFor?.hMiddleid?.componentStyles?.left
            ) + "px",
          top: verticalLineFor?.hMiddleid?.componentStyles?.hMiddle + "px",
          background: "green",
          display: `${verticalLineFor.hMiddle ? "block" : "none"}`,
        }}
      ></div>

      <div
        className="bottom-Align"
        style={{
          width:
            Math.max(
              verticalLineFor?.lastSelectedComp?.componentStyles?.right,
              verticalLineFor?.endbottomid?.componentStyles?.right
            ) -
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.left,
              verticalLineFor?.bottomid?.componentStyles?.left
            ) +
            "px",

          height: "1px",
          left:
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.left,
              verticalLineFor?.bottomid?.componentStyles?.left
            ) + "px",
          position: "absolute",
          top: verticalLineFor?.bottomid?.componentStyles?.bottom + "px",
          background: "red",
          display: `${verticalLineFor.bottom ? "block" : "none"}`,
        }}
      ></div>

      <div
        className="vMiddle-Align"
        style={{
          width: "1px",
          height:
            Math.max(
              verticalLineFor?.lastSelectedComp?.componentStyles?.bottom,
              verticalLineFor?.endvMiddleid?.componentStyles?.bottom
            ) -
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.top,
              verticalLineFor?.vMiddleid?.componentStyles?.top
            ) +
            "px",
          left: verticalLineFor?.vMiddleid?.componentStyles?.vMiddle + "px",
          top:
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.top,
              verticalLineFor?.vMiddleid?.componentStyles?.top
            ) + "px",
          position: "absolute",
          background: "green",
          display: `${verticalLineFor.vMiddle ? "block" : "none"}`,
        }}
      ></div>

      <div
        className="left-Align"
        style={{
          width: "1px",
          height:
            Math.max(
              verticalLineFor?.lastSelectedComp?.componentStyles?.bottom,
              verticalLineFor?.endleftid?.componentStyles?.bottom
            ) -
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.top,
              verticalLineFor?.leftid?.componentStyles?.top
            ) +
            "px",
          //  left: selectedComponent?.componentStyles?.top,
          position: "absolute",
          top:
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.top,
              verticalLineFor?.leftid?.componentStyles?.top
            ) + "px",
          left: verticalLineFor?.leftid?.componentStyles?.left + "px",
          background: "red",
          zIndex: 777,
          display: `${verticalLineFor.left ? "block" : "none"}`,
        }}
      ></div>

      <div
        className="right-Align"
        style={{
          width: "1px",
          height:
            Math.max(
              verticalLineFor?.lastSelectedComp?.componentStyles?.bottom,
              verticalLineFor?.endrightid?.componentStyles?.bottom
            ) -
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.top,
              verticalLineFor?.rightid?.componentStyles?.top
            ) +
            "px",
          //  left: selectedComponent?.componentStyles?.top,
          position: "absolute",
          top:
            Math.min(
              verticalLineFor?.lastSelectedComp?.componentStyles?.top,
              verticalLineFor?.rightid?.componentStyles?.top
            ) + "px",
          left: verticalLineFor?.rightid?.componentStyles?.right + "px",
          background: "red",
          zIndex: 777,
          display: `${verticalLineFor.right ? "block" : "none"}`,
        }}
      ></div>
    </>
  );
}
