import React, { useRef, useEffect } from "react";
import "./ElementAnimnPopUp.scss";
import CloseIcon from "../../images/iconclose.svg";
import { OutsideClickHandler } from "../../Uitls/Util";
import { Divider } from "@material-ui/core";
import preset3 from "../../images/preset3.svg";
import preset2 from "../../images/preset2.svg";
import PlusIcon from "../../images/PlusIcon.svg";

import preset1 from "../../images/preset1.svg";

function ElementAnmnPopUp(props) {
  const divRef = useRef(null);

  OutsideClickHandler(
    divRef,
    () => {
      props.setElementPopUp(false);
    },
    props.elementPopUp,
    props.selectedComponent.id
  );
  useEffect(() => {
    let left =
      props.popUpWindowPosn.left -
      document.getElementById("timeline-scale").getBoundingClientRect().left -
      (document.getElementById("popUpWindowNew").getBoundingClientRect().width *
        25) /
        100;
    let top =
      props.popUpWindowPosn.top -
      (document.getElementById("timeline-scale").getBoundingClientRect().top +
        document.getElementById("popUpWindowNew").getBoundingClientRect()
          .height +
        16);
    // let left = boundingRectDetails.left - props.popUpWindowPosn.left;
    props.setPopWindowPosn({ left: left, top: top });
  }, []);
  const trimSelectedCompId = props.selectedComponent.id.slice(0, -1);
  return (
    <div
      ref={divRef}
      className="box-container"
      id="popUpWindowNew"
      style={{
        left: props.popUpWindowPosn.left,
        top: props.popUpWindowPosn.top,
      }}
    >
      <div className="box">
        <div className="boxContent">
          <div className="elementAnimationText">
            {trimSelectedCompId} Animation
          </div>
          <div className="CloseIconDiv">
            <img
              src={CloseIcon}
              alt="copy"
              className="img-fluid closeIconn"
              onClick={() => props.setElementPopUp(false)}
            />
          </div>
        </div>
        <Divider className="dividerClass" />
        <div className="customAnimation">
          <div
            className="divContainer"
            onClick={() => {
              // props.setKeyFrameClicked(-1);
              props.setKeyFrameClicked("plusCustomAnmn");
              props.openCustomAnmnPopup();
              props.setIsNewAnimationOpen(true);
            }}
          >
            <img src={PlusIcon} className="img-fluid plusIcon" />
            <div>Custom Animation</div>
          </div>
        </div>

        <div className="presetContainer">
          <img src={preset1} className="img-fluid presetOptions" />
          <img src={preset2} className="img-fluid presetOptions" />
          <img src={preset3} className="img-fluid presetOptions" />
        </div>
      </div>
      <div className="arrow-down" id="arrow-down"></div>
    </div>
  );
}

export default ElementAnmnPopUp;
