import React, { useEffect, useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#1c75bb",
  },
}));

const CircularProgressBar = (props) => {
  const classes = useStyles();
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    setIsloading(props.isloading || false);
  }, [props]);
  return (
    <Backdrop className={classes.backdrop} open={isloading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CircularProgressBar;
