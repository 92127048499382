import React, { useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import "../../styles/components/Editor.scss";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import "../../styles/pages/TemplateManager.scss";
import { CircularProgress } from "@material-ui/core";

import DropDownWithLabelTemplate from "../../components/DropDownWithLabelTemplate.js";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import TextFieldInputClone from "../../components/CommonComponents/TextFieldInputClone";

//import BannerSizeWithcustomTemp from "../BannerManager/BannerSizeWithcustomTemp";
import {
  getNewBrandsAndcampaigns,
  getAssetdetails,
  getCognitoSession,
  getCognitoIndentityCredentials,
  cloneBanner,
} from "../../services/apis";
import DropDownWithSearchTemplate from "../../components/DropDownWithSearchTemplate";
import { toast } from "react-toastify";
//Denovo related imports
//MArket Data returnlated imports and varaihle declaration
import { isoCountryTableName, awsRegion } from "../../Uitls/Util";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import { nameValidation } from "../../Uitls/Util.js";

import ValueContext from "../../ContextAPi/appContext";
import Button from "@material-ui/core/Button";
//import { PowerInputSharp } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { templateSizes } from "../../Uitls/RightPanelToolBarItems";
var dynamo = require("dynamodb");

export default function CloneTemplateModal(props) {
  const history = useHistory();
  const {
    setShowCloneTemplate,
    showCloneTemplate,
    recentlyViewedData,
    genericInfoNew,
    // preDefined,
    // setPredefined,
    // customTemp,
    // setCustomBannerSize,
    iscustomtempSize,
  } = props;
  const appData = useContext(ValueContext);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  //  const [bannerNameInput,setBannerNameInput]=useState('');
  //   const [tags,setTags]=useState('');
  const [cloneApiDone, setCloneApiDone] = useState(false);
  // const [customTemp, setCustomTempSize] = React.useState(false);
  const [genericInfo, setGenericInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
    templateNameInput: "",
    //tagName: "",
    templateSize: "",
    Width: 0,
    Height: 0,
    templateType: "",
    campaignId: "",
  });
  const [helperText, setHelperText] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const closeCloneTempModal = (e) => {
    setShowCloneTemplate(false);
    setCloneloader(false);
  };
  const handleFormReset = () => {
    setGenericInfo({
      brand: "",
      campaign: "",
      market: "",
      templateNameInput: "",
      //tagName: "",
      templateSize: "",
      Width: 0,
      Height: 0,
      templateType: "",
    });
    setSelectedMarket("");
  };
  const { t } = useTranslation();
  const dropdownWidth = useRef(null);

  React.useEffect(() => {
    fetchBrandsAndCampaigns();
    fetchMarketData();
    getCampaignListToPopulate(
      appData?.genericInfo?.brand || recentlyViewedData?.brand
    );
    setGenericInfo({
      ...genericInfo,
      campaign: appData?.genericInfo?.campaign,
      brand: appData.genericInfo.brand,
    });
  }, []);
  //Fetch Brands and campaigns
  const fetchBrandsAndCampaigns = () => {
    getNewBrandsAndcampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        toast.error(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          setBrandsAndCampaigns(data);
        }
      }
    });
  };

  //Returns Campaign list to populate in Drop down
  const getCampaignListToPopulate = (Brand) => {
    if (Brand && brandsAndCampaigns.length > 0) {
      const campaigns = brandsAndCampaigns.filter((object) => {
        return object.brandName === Brand;
      })[0]?.campaigns;
      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }
    return [];
  };

  function validateForm() {
    return (
      //genericInfo.templateNameInput?.length > 2 &&
      genericInfo.templateNameInput?.trim().length > 2 &&
      appData?.genericInfo?.market?.length > 0 &&
      appData?.genericInfo?.campaign?.length > 0
      //&&
      //appData?.genericInfo?.tagName?.length > 0
    );
  }
  const handleChangeInfoDataBlur = (e) => {
    if (e.target.value.length < 3 && e.target.value.length !== 0) {
      toast.error(
        <div className="notificationText">
          {t(
            "Entered template name is short it should be more than 2 characters"
          )}
        </div>
      );
    }
  };
  const handleChangeInfoData = (e) => {
    const { name, value } = e.target;
    setGenericInfo({ ...genericInfo, [name]: value });
    if (name === "templateNameInput") {
      if (e.target.value.length > 255) {
        toast.error(
          <div className="notificationText">
            {t(
              "Entered template name is too lengthy it should be less than 255 characters"
            )}
          </div>
        );
      }
    }
    if (brandsAndCampaigns) {
      brandsAndCampaigns.map((brand) => {
        if (brand.brandName === value) {
          appData.setBrandId(brand.brandId);
        }
        brand.campaigns.map((campaign) => {
          if (campaign.campaignName === value)
            appData.setCampaignId(campaign.campaignId);
        });
      });
    }
    let obj = { ...appData?.genericInfo, [name]: value };
    appData.updateGenericInfo(obj);
    setErrorMsg(false);
    setHelperText("");
  };

  const handleMarketChange = (market) => {
    if (market) {
      setSelectedMarket(market);
      setGenericInfo({ ...genericInfo, market: market.name });
      appData.setMarketCode(market.code);
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        market: market.name,
      });
    }
  };

  const categoriesErr = (value) => {
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  };

  const getBrandsToPopulate = () => {
    if (!brandsAndCampaigns || brandsAndCampaigns.length <= 0) {
      return [];
    }
    return [...brandsAndCampaigns].map((object) => {
      return object.brandName;
    });
  };
  //Returns Campaign list to populate in Drop down

  const handleChange = (e) => {
    const { name, value } = e.target;

    setGenericInfo({ ...genericInfo, [name]: value });
    if (name === "templateNameInput") {
      if (e.target.value.length > 255) {
        toast.error(
          <div className="notificationText">
            {t("bannerManager.tempNameErr")}
          </div>
        );
      }
    }

    if (brandsAndCampaigns) {
      brandsAndCampaigns.map((brand) => {
        if (brand.brandName === value) {
          appData.setBrandId(brand.brandId);
        }
        brand.campaigns.map((campaign) => {
          if (campaign.campaignName === value)
            appData.setCampaignId(campaign.campaignId);
        });
      });
    }
  };

  const fetchMarketData = () => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setMarketDataSource(marketData);
                }
              }
            }
          });
        });
      }
    });
  };
  const [cloneloader, setCloneloader] = useState(false);

  const cloneTemplateData = () => {
    if (!nameValidation(genericInfo.templateNameInput, "Template")) return;
    setCloneloader(true);
    const formData = new FormData();
    formData.append("brandName", genericInfo?.brand || "");
    formData.append("name", genericInfo?.templateNameInput);
    formData.append("campaign", genericInfo?.campaign);
    formData.append("brandId", appData?.brandId);
    formData.append("marketCode", appData?.marketCode);

    formData.append(
      "campaignId",
      genericInfo?.brand === "— Not Brand Specific —"
        ? "-"
        : genericInfo?.campaignId
    );
    formData.append("marketName", appData?.genericInfo?.market);

    cloneBanner(
      appData.assetInfo.assetId,
      formData,
      (response, err, errorCode, errorMessage) => {
        if (err) {
          const errComponent = ErrComponentToRender(errorCode);
          if (err.response.data?.errorCode === "ERR_CS_ASSETNAME_EXISTS") {
            toast.error(
              <div className="notificationText">
                {t(
                  "Template Name exists. Please enter a different template name"
                )}
              </div>
            );
          } else {
            toast.error(
              <div className="notificationText">{t("apiErrors.err500")}</div>
            );
          }
          setCloneloader(false);
        } else {
          const obj = {
            brand: response.data.brandName,
            campaign: response.data.campaign,
            market: response.data.marketName,
            templateNameInput: response.data.assetName,
          };
          appData.updateGenericInfo({ ...appData?.genericInfo, ...obj });
          setSelectedMarket(obj.market);
          appData.setAssetInfo({
            assetId: "",
            assetName: obj.templateNameInput,
          });

          localStorage.removeItem("assetId");
          localStorage.setItem("assetId", response.data.id);
          history.push(`/banner-editor/${response?.data?.id}`);

          setCloneloader(false);
        }
        setCloneloader(false);
      }
    );
  };

  const getSelectedMarket = () => {
    if (!selectedMarket && appData && appData?.genericInfo?.market) {
      const countryName = appData?.genericInfo?.market;
      const countryCode = appData.marketCode;
      const displayName = `${countryCode} - ${countryName}`;
      return { name: countryName, code: countryCode, displayName: displayName };
    }
    return selectedMarket;
  };
  React.useEffect(() => {
    if (!genericInfo?.brand) return;
    setGenericInfo({
      ...genericInfo,
      campaign: genericInfo?.brand === "— Not Brand Specific —" ? "-" : "",
    });
  }, [appData?.genericInfo.brand]);
  return (
    <>
      <Dialog
        disableBackdropClick
        open={showCloneTemplate}
        onClose={closeCloneTempModal}
        className="newBannerModal tempBannerModal clonetemplate"
        aria-labelledby="form-dialog-title"
        overlaystyle={{ backgroundColor: "transparent" }}
      >
        {cloneloader && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress
              size="30px"
              className="customLoadermodules"
            />{" "}
          </>
        )}
        <DialogTitle id="form-dialog-title">
          <div
            className="baseOnModalHeading"
            style={{ fontFamily: "Nunito Sans" }}
          >
            {t("Clone Template")}
          </div>
        </DialogTitle>
        <DialogContent className=" tempContent customTempScrollBar ">
          <div className="row newbannerDetails">
            <TextField
              id="standard-basic"
              variant="standard"
              label={t("Template Name")}
              name="templateNameInput"
              className="bannerNameTextfield templateNameTextfield"
              fullWidth={true}
              value={genericInfo.templateNameInput}
              onChange={handleChange}
              onBlur={(e) => handleChangeInfoDataBlur(e)}
              autoComplete="off"
            />
            {appData?.genericInfo?.brand === "— Not Brand Specific —" ? (
              <div className="newInputFieldTemp brandNm" ref={dropdownWidth}>
                <DropDownWithLabelTemplate
                  options={{
                    value: genericInfo?.brand,
                    name: "brand",
                    label: t("bannerManager.brandName"),
                    items: getBrandsToPopulate(),
                    handleChange: handleChange,
                  }}
                  menuWidth={
                    dropdownWidth.current?.getBoundingClientRect().width *
                      0.95 || undefined
                  }
                />
              </div>
            ) : (
              <TextField
                variant="standard"
                value={genericInfo?.brand}
                name="brand"
                label={t("bannerManager.brandName")}
                className="bannerNameTextfield  templateNameTextfield "
                fullWidth={true}
                onChange={handleChangeInfoData}
                autoComplete="off"
                disabled="true"
              ></TextField>
            )}

            {/* {getSelectedMarket() && marketDataSource.length > 0 && ( */}

            <DropDownWithSearchTemplate
              options={{
                value: getSelectedMarket(),
                name: "market",
                label: t("bannerManager.market"),
                items: marketDataSource,
                handleChange: handleMarketChange,
              }}
              className="custonScrollBar"
            />

            {/* )} */}
            <div className="newInputFieldTemp" ref={dropdownWidth}>
              <DropDownWithLabelTemplate
                options={{
                  value: genericInfo?.campaign,
                  name: "campaign",
                  label: t("bannerManager.campaign"),
                  items: getCampaignListToPopulate(genericInfo?.brand),
                  handleChange: handleChangeInfoData,
                }}
                menuWidth={
                  dropdownWidth.current?.getBoundingClientRect().width * 0.95 ||
                  undefined
                }
              />
            </div>
            <div className="tempSizes">Template Size</div>
            <FormControl
              // id="rvcvMultiplefile"
              className="tempradioButns cloneradioDisabled"
            >
              <RadioGroup
                aria-label="rvcv"
                name="radio-buttons-group"
                className="rvcvRadiobuttonChecked rvcvMultipleradio"
                row
              >
                <FormControlLabel
                  name="preDefined"
                  control={
                    <Radio
                      checked={iscustomtempSize === false ? true : false}
                      disabled={true}
                    />
                  }
                  label="Pre Defined"
                  className="tempSizeText"
                />
                <FormControlLabel
                  name="customTemp"
                  control={<Radio checked={iscustomtempSize} disabled={true} />}
                  label="Custom"
                  className="tempSizeText"
                />
              </RadioGroup>
            </FormControl>
            {iscustomtempSize === false && (
              <TextField
                id="standard-basic"
                variant="standard"
                label={t("Choose Size")}
                name="templateSize"
                className="bannerNameTextfield templateNameTextfield tempfiels"
                fullWidth={true}
                value={`${genericInfoNew.width}X${genericInfoNew.height}`}
                //onChange={handleChange}
                //onBlur={(e) => handleChangeInfoDataBlur(e)}
                autoComplete="off"
                disabled="true"
              />
            )}

            {iscustomtempSize && (
              <TextFieldInputClone
                iscustomtempSize={iscustomtempSize}
                // setCustomSize={setCustomTempSize}
                //  handleDimensions={handleDimensions}
                genericInfo={genericInfoNew}
              />
            )}

            {/* <DropDownWithLabelTemplate
                options={{
                  value: genericInfo.templateType,
                  name: "templateType",
                  label: t("Template Type"),
                  items: templateType,
                  handleChange: handleChange,
                  className: "testBannersize",
                }}
              /> */}
            <TextField
              id="standard-basic"
              variant="standard"
              label={t("Template Type")}
              name="templateType"
              className="bannerNameTextfield templateNameTextfield"
              fullWidth={true}
              value={genericInfoNew.isAnimated === true ? "Animated" : "Static"}
              // onChange={handleChange}
              //onBlur={(e) => handleChangeInfoDataBlur(e)}
              autoComplete="off"
              disabled="true"
            />
          </div>
        </DialogContent>
        <DialogActions className="modalActions">
          <Button
            onClick={() => {
              closeCloneTempModal();
              handleFormReset();
            }}
            color="primary"
            className="cancelBtntemp"
          >
            {t("bannerManager.cancel")}
          </Button>
          <Button
            onClick={cloneTemplateData}
            type="submit"
            className="btn btn-primary"
            style={{ fontFamily: "Nunito Sans" }}
            disabled={!validateForm()}
            // disabled={false}
            autoFocus
          >
            {t("bannerManager.proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
